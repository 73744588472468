import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import "./ModuleCard.css";
// import {
//   Button,
// } from "@material-ui/core";
import { Link } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
// import tickSrc from "./assets/tick.svg";
import starSrc from "./assets/star.svg";

let ModuleCard = ({ title, description, percentageCompleted, config, variant, moduleId, selfCertifyFlag }) => {

  let footerBlk = (vari)=>{
    switch (vari) {
      case 0: //continue prepare
        return <div style={{ width: 120, color:'#c6922a' }}>
        {/* <LinearProgress 
        variant="determinate" 
        value={ percentageCompleted||0 } 
        color='inherit'
        classes={{ root: 'progress-root', bar: 'progress-bar' }} /> */}

        <LinearProgress variant="determinate" value={percentageCompleted} />
      </div>
      case 1: //Ready to self certify
      return <div className='button-text'>
          {selfCertifyFlag === 1 ?
             <Link className='button-label' to={`/application/selfcertify/${moduleId}`} >Self Certify</Link>
             :
             null
          }    
      </div>
      case 2: //Certified
        return <div></div>  
      case 3: //Available for prepare
       return <div></div>
      default:
        return null;
    }    
  } 

  let footerRight = (varia) =>{
    switch (varia) {
      case 0:
        return <div className='button-text'>
          <Link className='button-label' to={`/application/prepare/${moduleId}`} >Prepare</Link>
        </div>
      case 1:
        return <div className='button-text'>
            <Link className='button-label' to={`/application/prepare/${moduleId}`} >Prepare</Link>
          </div>
      case 2:
        return <div className='button-text'>
            <Link className='button-label' to={`/application/prepare/${moduleId}`} >Prepare</Link>
        </div>
      case 3:
        return <div className='button-text'>
          <Link className='button-label' to={`/application/prepare/${moduleId}`} >Prepare</Link>
        </div>
      default:
        break
    }
  }

  return <div className="module-card">
    {/* {percentageCompleted===100?<div className='status-icon'>
      <img src={starSrc}/>
    </div>:null} */}

    {variant===2?<div className='status-icon'>
      <img src={starSrc}/>
    </div>:null}

    <div className="-card-body">
      <div className="subtitle" style={{ marginBottom: 6 , marginRight:'10px'}}>{title}</div>
      <h6 style={{ height: 50 }}>{description}</h6>
      <div className="cFoot">
        {footerBlk(variant)}
        {footerRight(variant)}
      </div>
    </div>
  </div>
};

export default ModuleCard;