import React, {
    //  useState,
      useEffect } from 'react';
import { Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Pencil from "../../../components/images/Tools-img/pencil.png";
import './style.css';
import EventModal from "./modal";

const useStyles = makeStyles({
    underline: {
        '&:after': {
            borderBottom: '2px solid #ffba36'
        },
    }
});

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    const egInput = ['Isha herself', 'E.g. Other actors', 'E.g. Props & sets design'];

    useEffect(() => {
        if (!engagementData || !engagementData['ESI_Slide_45'] || engagementData['ESI_Slide_45'] === undefined || engagementData['ESI_Slide_45'] === null || Object.keys(engagementData['ESI_Slide_45']).length === 0) {
            let temp = {};
            for (let i = 0; i < 6; i++) {
                temp[i] = {};
                if (i < 3) temp[i][`input`] = egInput[i];
                else temp[i][`input`] = '';
                temp[i][`textBox`] = '';
            }
            manageToolData({ toolId: 'ESI_Slide_45', content: temp })
        }
    }, [])


    // let titleSize = '20px'
    let descriptionSize = '10px';
    let headingSize = '16px';
    let subtTitleSize = '12px';
    if (isFullScreen) {
        // titleSize = '30px';
        headingSize = '24px';
        subtTitleSize = '18px'
        descriptionSize = '14px';
    }

    const classes = useStyles();


    const postTextBox = () => {
        if(!engagementData || !engagementData['ESI_Slide_45'] || engagementData['ESI_Slide_45'] === undefined || engagementData['ESI_Slide_45'] === null || Object.keys(engagementData['ESI_Slide_45']).length === 0)
            return null;
        return Object.keys(engagementData && engagementData['ESI_Slide_45']).map(i => {
            return <div key={i} className='ESI_Slide_45_textbox'>
                <div className='work-sans' style={{ height: '15%', width: '100%', textAlign: 'center', fontSize: descriptionSize }}>Entity: {parseInt(i) + 1}</div>
                <div style={{ height: '25%', fontSize: descriptionSize, width: '100%', textAlign: 'center' }}>
                    <Input
                        classes={{ underline: classes.underline }}
                        className={'work-sans'}
                        placeholder="Fill Here."
                        style={{ fontSize: descriptionSize, height: '16px' }}
                        value={engagementData['ESI_Slide_45'][i].input || ''}
                        onChange={(event) => {
                            engagementData['ESI_Slide_45'][i] = { input: event.currentTarget.value, textBox: engagementData['ESI_Slide_45'][i].textBox }
                            manageToolData({ toolId: 'ESI_Slide_45', content: engagementData['ESI_Slide_45'] })
                        }}
                    />
                </div>
                <textarea id={engagementData['ESI_Slide_45'][i].textBox} placeholder='Fill their contribution here…'
                    className={'ISE_Growing_Deep_textBox tct-scrollable work-sans'}
                    style={{ width: '100%', height: '57%', display: 'flex', fontSize: descriptionSize, position: 'relative' }}
                    value={engagementData['ESI_Slide_45'][i].textBox || ''}
                    onChange={(event) => {
                        engagementData['ESI_Slide_45'][i] = { textBox: event.currentTarget.value, input: engagementData['ESI_Slide_45'][i].input }
                        manageToolData({ toolId: 'ESI_Slide_45', content: engagementData['ESI_Slide_45'] })
                    }}
                />
            </div>
        })
    }

    const addTextBox = () => {
        let len = Object.keys(engagementData['ESI_Slide_45']).length;
        manageToolData({
            toolId: 'ESI_Slide_45',
            content: {
                ...engagementData['ESI_Slide_45'],
                [len]: {
                    input: '',
                    textBox: ''
                }
            }
        });

    }


    return (
        <div style={{ width, height }}>
            <div className='ESI_Slide_illumine_logo'>
                <img src='https://pedgog2.s3.ap-south-1.amazonaws.com/Becoming+future+ready+contributors/04-Enlightened+Self+interest/Illuminelogo.png'
                    style={{ width: '100%', height: '100%' }}
                    alt='illumine-logo'
                />
            </div>
            <div className='work-sans' style={{ width: '95%', height: '20%', fontSize: headingSize, fontWeight: 'bold', padding: '1.5%' }}>
                {data.title}
            </div>
            <div style={{ width: '100%', height: '80%', display: 'flex' }}>
                <div style={{ width: '75%', height: '100%', backgroundColor: '#70bdd2' }}>
                    <div style={{ width: '100%', height: '15%', display: 'flex', padding: '1.5%' }}>
                        <div style={{ width: '13%', height: '100%', padding: '0 2%' }}>
                            <img src={Pencil} style={{ width: '100%', height: '150%', position: 'relative', top: '-55%' }} alt='pencil' />
                        </div>
                        <div className='work-sans' style={{ width: '85%', height: '100%', fontSize: subtTitleSize, fontWeight: 'bold' }}>
                            {data.subTitle}
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', height: '85%', }}>
                        <div className='ESI_Slide_45_textbox_container tct-scrollable'>
                            {postTextBox()}
                        </div>
                        <div className='ESI_Slide_45_add_container'>
                            <div className='button-click-effect' style={{ fontSize: '32px', fontWeight: 'bold' }}
                                onClick={addTextBox}>&#43;
                            </div>
                            <div style={{ fontSize: subtTitleSize, textAlign: 'center', fontWeight: 'bold' }} className='work sans'>Add more entities</div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '25%', height: '100%', display: 'flex', alignItems: 'center' }}>
                    <EventModal popUpImg={data.popUpImg} />
                </div>
            </div>
        </div >
    );
}