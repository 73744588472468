import React, { useEffect } from "react";
import {
 Button, Dialog, FormControl, IconButton,
 InputLabel, Select, TextField
} from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { useState } from "react"
import { superActivateResponse, TeacherUpdateAResponse } from "../utils"


let PieceCard = ({ open, toolId, bulkActionTargets, toolKit, sessionId, responses, engagementId }) => {
 let [updates, setUpdates] = useState(null);
 let [class_, setClass] = useState("");
 let onCloseDialog = () => {
  superActivateResponse({
   //response field not needed since rid is null
   sessionId, engagementId,
   rid: Object.keys(updates).length === 1 ? Object.keys(updates)[0] : null,
   response: null,
   isEditable: false
  })
 }
 useEffect(() => {
  let u = {};
  bulkActionTargets.forEach(rid => {
   u[rid] = { ...responses?.[rid] }
  });
  setUpdates(u);

  if(bulkActionTargets.length==1){
   setClass(responses?.[bulkActionTargets[0]]?.class||"")
  }

 }, [bulkActionTargets]);

 let headerText = (length_) => {
  switch (length_) {
   case 1:
    if (responses?.[bulkActionTargets[0]]) {
     return "Edit a response"
    }
    return "Add a new response"
   default:
    return "Bulk Actions"
  }
 }

 let onSave = () => {
  Object.keys(updates).forEach(rid => {
   TeacherUpdateAResponse({
    rid,
    u: {
     ...updates[rid], class: class_ != "" ? class_ : null, sessionId, toolId, fid: "f001",
     user_name: responses?.[rid]?.user_name ? responses?.[rid]?.user_name : "Facilitator", engagementId
    },
    sessionId, engagementId, fid: "f001",
    wasClassUpdated: class_ != "" || !(updates[rid].class === class_)
   })
  });
  onCloseDialog();
 }

 return <Dialog
  classes={{ 'container': 'piece-card-backdrop' }}
  maxWidth="lg"
  open={open}
  onClose={() => onCloseDialog()}
  container={document.getElementById("frame-id")}>
  <div style={{ padding: '16px 40px' }}>
   <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
     <span>{headerText(bulkActionTargets.length)}</span>
    </div>
    <div>
     <IconButton onClick={() => onCloseDialog()}><Close /></IconButton>
    </div>
   </div>
   <div style={{ display: "flex", width: 480, margin: "0px -12px" }}>
    {(Object.keys(updates || {})).map(rid => {
     return <FormControl key={`form-${rid}`} style={{ margin: 12 }} fullWidth>
      <TextField
       fullWidth={bulkActionTargets.length > 1 ? false : true}
       value={updates?.[rid].editedText || updates?.[rid].text}
       variant="outlined"
       label={updates?.[rid]?.user_name || 'Facilitator'}
       placeholder='Write a Text'
       disabled={bulkActionTargets.length > 1 ? true : false}
       multiline
       onChange={(ev) => {
        setUpdates({
         ...updates, [rid]: {
          ...updates[rid],
          editedText: ev.target.value
         }
        })
       }} />
     </FormControl>
    })}
   </div>

   <div>
    <FormControl fullWidth>
     <InputLabel id="select-field">Add a tag</InputLabel>
     <Select fullWidth
      native
      style={{ fontSize: "0.9rem " }}
      labelId="select-field"
      id="response-select"
      value={class_ || ""}
      onChange={(ev) => {
       setClass(ev.target.value)
      }}
     ><option aria-label="None" value="" />
      {toolKit && Object.keys(toolKit?.classes)
       .sort((a, b) => {
        return toolKit.classes[a].order - toolKit.classes[b].order
       })
       .map((cid, j) => {
        let c = toolKit.classes[cid];
        return <option key={`select-${cid}`} value={cid}>{c.label}</option>
       })}
     </Select>
    </FormControl>
   </div>


   <div style={{ display: "flex", marginTop: 12 }}>
    <div style={{ padding: 8 }}><Button variant="contained" onClick={onSave}>Add to board</Button></div>
    {/* <div style={{ padding: 8 }}><Button variant="outlined">Delete</Button></div> */}
   </div>
  </div>
 </Dialog>
}

export default PieceCard;