import React from 'react';


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let descriptionSize = '10px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '14px';
    }

    const top = ['44%', '41%', '35.5%', '76%', '79.5%', '64%'];
    const right = ['50.5%', '27%', '4%'];
    const textBoxHeight = ['11%', '14%', '20%', '18%', '14.5%', '30%']

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((textBoxId, i) => <textarea key={textBoxId} id={textBoxId} placeholder='Fill more…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ fontSize: descriptionSize, position: 'absolute', width: '18.5%', top: top[i], right: right[Math.floor(i % (data.textBoxId.length / 2))], height: textBoxHeight[i] }}
                        value={engagementData[textBoxId] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: textBoxId, content: event.currentTarget.value })
                        }}
                    />)
                }

            </div>
        </div>
    );
}