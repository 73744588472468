import React, { useState } from 'react';
import LeftNav from "../LeftNav";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import './style.css';
import { uploadProject } from '../../containers/application/redux_actions'
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
    },
}));

export default () => {

    const [data, setData] = useState({
        projectTitle: '',
        moduleName: '',
        studentName: '',
        description: '',
    });

    const [file, setFile] = useState('');
    const [poster, setPoster] = useState('');

    const [upload, setUpload] = useState('');

    const [error, setError] = useState({
        titleError: false,
        moduleError: false,
        studentError: false,
        descriptionError: false,
    });

    const auth = useSelector(state => state.auth);
    console.log(auth);
    const handleChange = (field, event) => {
        setData({ ...data, [field]: event.target.value });
        console.log(data)
    }

    const handleFileChange = (event, type) => {
        if (type === 'pdf') {
            if (event.target.files[0].type !== 'application/pdf')
                setFile({ name: 'Please upload PDF only.' })
            else {
                setFile(event.target.files[0]);
                console.log(file)
                console.log(event.target.files[0])
            }
        }
        if (type === 'img') {
            if (String(event.target.files[0]).includes('image/'))
                setPoster({ name: 'Please upload Image only.' })
            else {
                setPoster(event.target.files[0]);
                console.log(event.target.files[0])
            }
        }

    }

    const handleSubmit = () => {

        if (handleValidation()) {
            setUpload('uploading')
            var formdata = new FormData()
            formdata.append("file", file);
            formdata.append("poster", poster);
            formdata.append("title", data.projectTitle);
            formdata.append("college", auth.user.organizationid);
            formdata.append("module", data.moduleName);
            formdata.append("coach", auth.user.id);
            formdata.append("students", data.studentName.split(','));
            formdata.append("description", data.description);
            console.log(formdata);
            uploadProject({ user_token: auth.user_token, formdata })
                .then((response) => {
                    if (response) setUpload(<div style={{ color: 'green', fontWeight: 'bold' }}>Upload Success! you can see your project in project dashboard.</div>)
                    else setUpload(<div style={{ color: 'red', fontWeight: 'bold' }}>Upload Failed.</div>)
                })
                .catch((err) => { setUpload("Upload Failed :("); console.log(err) })
        }
    }

    const handleValidation = () => {
        let flag = true;
        let errors = {
            titleError: false,
            moduleError: false,
            studentError: false,
            descriptionError: false,
        };
        if (!data.projectTitle) {
            errors.titleError = 'Please Enter the Title'
            flag = false
        }
        if (!data.description) {
            errors.descriptionError = 'Please Enter the Description';
            flag = false
        }

        if (!data.moduleName) {
            errors.moduleError = 'Please Select the Module';
            flag = false
        }
        if (!data.studentName) {
            errors.studentError = 'Please Enter Student Name';
            flag = false
        }
        if (!file.size) {
            setFile({ name: 'Please upload Project PDF file.' })
            flag = false
        }
        if (!poster.size) {
            setPoster({ name: 'Please upload Image file.' })
            flag = false
        }
        setError(errors)
        if (flag) return true
        return false
    }

    const classes = useStyles();

    return (
        <div className='dashboard-cntr'>
            <LeftNav />
            <div className="with-left-nav wrapper prepare-pg">
                <h1>Upload Project</h1>
                <p>Please fill in the details to upload a project</p>
                <div className='d-flex width-100' style={{ margin: '3% 0%' }}>
                    <div className='width-33' >
                        <TextField
                            label="Project Title"
                            placeholder="Project Title or Heading"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={error.titleError ? true : false}
                            helperText={error.titleError}
                            style={{ margin: '5% 0%' }}
                            onChange={(event) => handleChange('projectTitle', event)}
                            variant="outlined"
                        />

                        <FormControl variant="outlined" fullWidth style={{ margin: '5% 0%' }} error={error.moduleError ? true : false}>
                            <InputLabel id="moduleName-select-label">Project Category</InputLabel>
                            <Select
                                labelId="moduleName-select-label"
                                id="moduleName-select"
                                value={data['moduleName'] || "Select"}
                                onChange={(event) => handleChange('moduleName', event)}
                                label="Project Category"
                            >
                                <MenuItem value="Select" disabled>
                                    <em>Select</em>
                                </MenuItem>
                                <MenuItem value='Building a Contibutor Profile'>Building a Contibutor Profile</MenuItem>
                                <MenuItem value='Building a solution to a citizen challenge'>Building a solution to a citizen challenge</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className='width-33' style={{ margin: '0 5%' }}>
                        <TextField
                            label="Add Student/Students"
                            placeholder="You can add more students by pressing ' , ' after every student name"
                            multiline
                            rowsMax={3}
                            inputProps={{
                                className: 'tct-scrollable-darkgray'
                            }}
                            error={error.studentError ? true : false}
                            helperText={error.studentError}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ margin: '5% 0%' }}
                            onChange={(event) => handleChange('studentName', event)}
                            variant="outlined"
                        />
                        <TextField
                            label="Add Description for the Project"
                            placeholder="Write Description (Max 200 Characters)"
                            multiline
                            rows={5}
                            inputProps={{
                                className: 'tct-scrollable-darkgray'
                            }}
                            fullWidth
                            margin="normal"
                            error={error.descriptionError ? true : false}
                            helperText={error.descriptionError}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ margin: '5% 0%' }}
                            onChange={(event) => handleChange('description', event)}
                            variant="outlined"
                        />
                        <Button variant="contained" style={{ backgroundColor: '#ffba36' }} fullWidth onClick={handleSubmit}>
                            Upload
                         </Button>
                    </div>
                    <div className='width-33'>
                        <input
                            hidden
                            id="contained-button-file"
                            multiple
                            type="file"
                            accept='application/pdf'
                            onChange={(event) => handleFileChange(event, 'pdf')}
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" style={{ margin: '5% 0%' }} component="span" startIcon={<AttachFileIcon fontSize='large' />}>
                                Attach/Upload Project File
                            </Button>
                        </label>
                        {
                            file ? <div>{file.name}</div> : <div style={{ fontSize: '12px', color: 'gray' }}>(in pdf format)</div>
                        }
                        <input
                            accept="image/*"
                            hidden
                            id="contained-button-file-cover"
                            multiple
                            type="file"
                            onChange={(event) => handleFileChange(event, 'img')}
                        />
                        <label htmlFor="contained-button-file-cover">
                            <Button variant="contained" style={{ margin: '5% 0%' }} component="span" startIcon={<AttachFileIcon fontSize='large' />}>
                                Attach/Upload Cover Image
                            </Button>
                        </label>
                        {
                            poster ? <div>{poster.name}</div> : null
                        }
                        <div style={{ fontSize: '12px', color: 'gray' }}>(Thumbnail for Your Project to Display in Dashboard Suggested Resolution 270x150)</div>
                        {
                            upload === 'uploading' ? <Backdrop className={classes.backdrop} open={true}>
                                <CircularProgress classes={{ root: 'circular-progress-color' }} size={70} />
                            </Backdrop> : upload ? upload : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}