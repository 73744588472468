import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';

import Eye_Icon from "../../../components/images/Tools-img/Eye_Icon.png";
import Chat_Icon from "../../../components/images/Tools-img/Chat_Icon.png";

import './style.css';



export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let titleSize = '18px'
    let descriptionSize = '10.5px';
    if (isFullScreen) {
        titleSize = '26px';
        descriptionSize = '16px';
    }

    return (
        <div style={{ width, height, padding: '1%' }}>
            <div className='ESI_Slide_illumine_logo'>
                <img src='https://pedgog2.s3.ap-south-1.amazonaws.com/Becoming+future+ready+contributors/04-Enlightened+Self+interest/Illuminelogo.png'
                    style={{ width: '100%', height: '100%' }}
                    alt='illumine-logo'
                />
            </div>
            <div style={{ width: '90%', height: '10%' }}>
                <div className='work-sans' style={{ fontSize: titleSize, fontWeight: 'bold', color: '#e64708' }}>{data.title}</div>
            </div>
            <div style={{ width: '100%', height: '90%', display: 'flex' }}>
                <div style={{ width: '23%', height: '100%', display: 'flex', alignItems: 'center' }}>
                    <div className={`work-sans ESI_Slide_11_textBox`} style={{ border: '3px solid #e64708' }}>
                        <div style={{ width: '100%', height: '20%', fontWeight: 'bold', fontSize: descriptionSize }}>
                            <span style={{ color: '#e64708' }}>Group 1:</span>
                            {data.textBoxQuestions[0]}
                        </div>
                        <div className={`work-sans`} style={{ height: '80%', display: 'flex', fontSize: descriptionSize }}>
                            <TextEditor id={data.textBoxId[0]} initialData={engagementData[data.textBoxId[0]] || null}
                                manageToolData={manageToolData} placeholder='Discuss & fill in their goals and concerns…' hideToolbar={true}
                                wrapperClass='text-editor-WIAC_diff_in_the_lives'
                            />
                        </div>
                    </div>
                </div>
                <div style={{ width: '23%', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-around', margin: '0 2%' }}>
                    <div className={`work-sans ESI_Slide_11_textBox`} style={{ border: '3px solid #31849b' }}>
                        <div style={{ width: '100%', height: '30%', fontWeight: 'bold', fontSize: descriptionSize }}>
                            <span style={{ color: '#e64708' }}>Group 2:</span>
                            {data.textBoxQuestions[1]}
                        </div>
                        <div className={`work-sans`} style={{ height: '70%', display: 'flex', fontSize: descriptionSize }}>
                            <TextEditor id={data.textBoxId[1]} initialData={engagementData[data.textBoxId[1]] || null}
                                manageToolData={manageToolData} placeholder='Discuss & fill in their goals and concerns…' hideToolbar={true}
                                wrapperClass='text-editor-WIAC_diff_in_the_lives'
                            />
                        </div>
                    </div>
                    <div className={`work-sans ESI_Slide_11_textBox`} style={{ border: '3px solid #31849b' }}>
                        <div style={{ width: '100%', height: '20%', fontWeight: 'bold', fontSize: descriptionSize }}>
                            <span style={{ color: '#e64708' }}>Group 3:</span>
                            {data.textBoxQuestions[2]}
                        </div>
                        <div className={`work-sans`} style={{ height: '80%', display: 'flex', fontSize: descriptionSize }}>
                            <TextEditor id={data.textBoxId[2]} initialData={engagementData[data.textBoxId[2]] || null}
                                manageToolData={manageToolData} placeholder='Discuss & fill in their goals and concerns…' hideToolbar={true}
                                wrapperClass='text-editor-WIAC_diff_in_the_lives'
                            />
                        </div>
                    </div>
                </div>
                <div style={{ width: '23%', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <div className={`work-sans ESI_Slide_11_textBox`} style={{ border: '3px solid #31849b' }}>
                        <div style={{ width: '100%', height: '30%', fontWeight: 'bold', fontSize: descriptionSize }}>
                            <span style={{ color: '#e64708' }}>Group 4:</span>
                            {data.textBoxQuestions[3]}
                        </div>
                        <div className={`work-sans`} style={{ height: '70%', display: 'flex', fontSize: descriptionSize }}>
                            <TextEditor id={data.textBoxId[3]} initialData={engagementData[data.textBoxId[3]] || null}
                                manageToolData={manageToolData} placeholder='Discuss & fill in their goals and concerns…' hideToolbar={true}
                                wrapperClass='text-editor-WIAC_diff_in_the_lives'
                            />
                        </div>
                    </div>
                    <div className={`work-sans ESI_Slide_11_textBox`} style={{ border: '3px solid #31849b' }}>
                        <div style={{ width: '100%', height: '30%', fontWeight: 'bold', fontSize: descriptionSize }}>
                            <span style={{ color: '#e64708' }}>Group 5:</span>
                            {data.textBoxQuestions[4]}
                        </div>
                        <div className={`work-sans`} style={{ height: '70%', display: 'flex', fontSize: descriptionSize }}>
                            <TextEditor id={data.textBoxId[4]} initialData={engagementData[data.textBoxId[4]] || null}
                                manageToolData={manageToolData} placeholder='Discuss & fill in their goals and concerns…' hideToolbar={true}
                                wrapperClass='text-editor-WIAC_diff_in_the_lives'
                            />
                        </div>
                    </div>
                </div>
                <div style={{ width: '23%', height: '100%', margin: '0 2%' }}>
                    <div className='ESI_card_slide_11'>
                        <div style={{ width: '20%', height: '10%', position: 'absolute' }}>
                            <img src={Eye_Icon} className='ESI_Eye_Icon' />
                        </div>
                        <div className='scrollable' style={{ width: '100%', height: '65%', fontSize: descriptionSize, overflowY: 'auto', fontWeight: 'bold', marginTop: '10%' }}>
                            {data.cardText[0].boldText}
                        </div>
                        <div className='scrollable' style={{ width: '100%', height: '25%', fontSize: descriptionSize, overflowY: 'auto' }}>
                            {data.cardText[0].lightText}
                        </div>
                    </div>
                    <div className='ESI_card_slide_11'>
                        <div style={{ width: '20%', height: '10%', position: 'absolute' }}>
                            <img src={Chat_Icon} className='ESI_Eye_Icon' />
                        </div>
                        <div className='scrollable' style={{ width: '100%', height: '65%', fontSize: descriptionSize, overflowY: 'auto', fontWeight: 'bold', marginTop: '10%' }}>
                            {data.cardText[1].boldText}
                        </div>
                        <div className='scrollable' style={{ width: '100%', height: '25%', fontSize: descriptionSize, overflowY: 'auto' }}>
                            {data.cardText[1].lightText}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}