import React
  // ,{ useState } 
  from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import "./MyCapsules.css";
import Button from '@material-ui/core/Button';
// import ConductDialog from '../../App_ConductDashboard/conductDialog';
import { useDispatch } from 'react-redux';
import { createSession } from '../../../containers/application/redux_actions';

const acpStyle = {
  backgroundColor: "#7cba00",
  padding: "8px 16px",
  color: "#fff",
  fontSize: ".8rem",
  fontWeight: 500
}

function Capsule(props) {
  // const [visible, setVisible] = useState(false)
  const { enumerate, user_token, coach_id, prepare, conduct } = props;
  const {
    // heading,
    title,
    description,
    // icon,
    // route,
    // ready,
    // icon_bg,
    excerpt,
    uniqueAboutIt,
    mainFilePath,
    id
  } = props.cardData;
  let _Route = props => <Link to={`/application/unit/${id}`} {...props} />

  const dispatch = useDispatch();

  return (
    <div className='capsule-cntr'>
      {enumerate > 0
        ? <div className="card-enumeration">{enumerate}</div>
        : null}
      <div className='capsule-blk'>
        <div className='capsule-left'>
          <div className="ch-sub-title">{title}</div>
          <div className="ch-title">{description}</div>
          <div className="ch-description">{uniqueAboutIt}</div>
          <div className="ch-description">{excerpt}</div>
          <div className='action-cntr'>
            {conduct ? <Link to={`/application/conduct/${id}`}><Button style={acpStyle} onClick={() => dispatch(createSession({ user_token, moduleId: id, coach_id, sessionName: new Date(), sessionType: "pre" }))}>Run Session</Button></Link> : ""}
            {/* {conduct ? <ConductDialog
              moduleId={id}
              title={title}
              description={description}
              user_token={user_token}
              coach_id={coach_id}
              testType="pre"
              buttonText={props.buttonText}
            /> : ""} */}
            {prepare ? <Button component={_Route} classes={{ root: "action-btn-capsule", label: "btn-text" }}>{props.buttonText}</Button> : ""}
          </div>
        </div>
        <div
          className='capsule-right'
          style={{
            // backgroundColor: icon_bg
          }}>
          <img
            alt="home-img"
            src={mainFilePath
              ? mainFilePath
              : "http://43.229.227.26:81/acsportal/explore1.jpg"}
            title="Illumine Digital" /></div>
      </div>
    </div>
  );
}

Capsule.propTypes = {
  cardData: PropTypes.object.isRequired,
  enumerate: PropTypes.number
};

export default Capsule;