import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import "./style.css";
import Close_Button from "../../../components/images/Tools-img/Close_Button.png";
import VideoSlide from "../../../components/VideoSlide/index"

export default function TransitionsModal({ imgUrl, vidUrl, poster, width, height, isFullScreen }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>

            <img src={imgUrl} className='button-click-effect' style={{ width: '100%', height: '100%' }} onClick={() => handleOpen()} alt='icon' />

            <Modal
                className='modal'
                open={open}
                onClose={handleClose}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div style={{ border: 'none', outline: 'none', position: 'relative' }}>
                        <VideoSlide vidUrl={vidUrl} imgUrl={poster} dimensions={{ slideHeight: '100%', slideWidth: '100%' }} popupWidth={width} popupHeight={height} />
                        <img src={Close_Button} className="close-button-2" onClick={handleClose} />
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}