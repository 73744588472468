import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import { connect } from "react-redux";

import './style.css';
import LeftNav from "../../../components/LeftNav";
import Config from "../../../config"
import Feedbacks from './feedbacks';
import AnalyticChart from './analyticChart';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const getMonths = (upto = 6) => {
    const months = [];
    for (let i = 1; i <= upto; i++) {
        months.push({
            text: moment().subtract(i, 'M').startOf('month').local().format("Do MMM 'YY"),
            value: moment().subtract(i, 'M').startOf('month').local().toDate(),
        });
    }
    return months;
};

const INITIAL_STATE = { graphData: {}, suggestions: [] };

const CoachingAnalyticsDashboard = ({ auth }) => {
    const classes = useStyles();
    const [months,] = useState(getMonths());
    const [selectedMonth, setSelectedMonth] = useState(months[0].value);
    const [feedbacks, setFeedbacks] = useState(INITIAL_STATE);
    const onMonthChange = ({ target: { value } }) => setSelectedMonth(value);

    const getAnalytics = () => {
        fetch(`${Config.API_URL}coaching/analytics/${auth?.user.id}?month=${moment(selectedMonth).format('YYYY-MM-DD')}`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: "bearer " + auth.user_token,
                accept: "application/json",
            },
        })
        .then((response) => response.json())
        .then((response) => {
            if(Object.keys(response).length > 0 && !response.code){
                return setFeedbacks(response);
            }
            setFeedbacks(INITIAL_STATE);
        })
        .catch((err) => {
            setFeedbacks(INITIAL_STATE);
        });
    };

    useEffect(() => {
        getAnalytics();
    }, [selectedMonth]);

    return (<div className={"dashboard-cntr"}>
        <LeftNav />
        <div className="with-left-nav wrapper prepare-dashboard">
            <div className="">
                <h2>Coaching Analytics</h2>
                <h5 className="mt-2">MY COACHING AUDIT REPORT</h5>
                <span className="d-block grey font-12 mt-1">Based on class conducted on</span>
                <FormControl variant="outlined" className={`${classes.formControl}`}>
                    <InputLabel id="demo-simple-select-outlined-label">Month</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={onMonthChange}
                        value={selectedMonth}
                        label="Month"
                    >
                        {
                            months.map(month => {
                                return (<MenuItem key={month.value} value={month.value}>{month.text}</MenuItem>)
                            })
                        }
                    </Select>
                </FormControl>
            </div>
            {
                Object.keys(feedbacks.graphData).length > 0
                ?   (<><div className="graph-wrapper">
                        <AnalyticChart data={feedbacks.graphData} />
                    </div>
                    <div className="improvement-feedbacks">
                        <Feedbacks feedbacks={feedbacks.suggestions} data={feedbacks.graphData} />
                    </div></>)
                : (<div className="no-record-wrapper"><span>No record found</span></div>)
            }

        </div>
    </div>);
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(CoachingAnalyticsDashboard);
