import React from 'react';

import Modal_Component from '../../../Tools/Modal_Component';
import Play_Arrow_Red from '../../../components/images/Play_Arrow_Red.svg';

import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {data.textBoxId.length === 1 &&
                    <textarea id={engagementData[data.textBoxId[0]]}
                        className={`SS_Case_Study_1-outer-textbox roboto tct-scrollable`}
                        style={{ height: '50%', top: '31%', fontSize: !isFullScreen ? '12px' : '16px' }}
                        value={engagementData[data.textBoxId[0]] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: data.textBoxId[0], content: event.currentTarget.value })
                        }}
                        placeholder='&#9679; Fill in…' />
                }
                {data.textBoxId.length === 2 &&
                    <div>
                        <textarea id={engagementData[data.textBoxId[0]]}
                            className={`SS_Case_Study_1-outer-textbox roboto tct-scrollable`}
                            value={engagementData[data.textBoxId[0]] || ''}
                            readOnly
                            style={{ height: '13%', top: '23.5%', fontSize: !isFullScreen ? '12px' : '16px' }}
                            onChange={(event) => {
                                manageToolData({ toolId: data.textBoxId[0], content: event.currentTarget.value })
                            }}
                            placeholder='&#9679; Fill in…' />
                        <textarea id={engagementData[data.textBoxId[1]]}
                            className={`SS_Case_Study_1-outer-textbox roboto tct-scrollable`}
                            style={{ height: '36%', top: '47%', fontSize: !isFullScreen ? '12px' : '16px' }}
                            value={engagementData[data.textBoxId[1]] || ''}
                            onChange={(event) => {
                                manageToolData({ toolId: data.textBoxId[1], content: event.currentTarget.value })
                            }}
                            placeholder='&#9679; Fill in…' />
                    </div>
                }
                {data.textBoxId.length === 3 && !data.display &&
                    <div>
                        <textarea id={engagementData[data.textBoxId[0]]}
                            className={`SS_Case_Study_1-outer-textbox roboto tct-scrollable`}
                            value={engagementData[data.textBoxId[0]] || ''}
                            readOnly
                            style={{ height: '13%', top: '23.5%', fontSize: !isFullScreen ? '12px' : '16px' }}
                            onChange={(event) => {
                                manageToolData({ toolId: data.textBoxId[0], content: event.currentTarget.value })
                            }}
                            placeholder='&#9679; Fill in…' />
                        <textarea id={engagementData[data.textBoxId[1]]}
                            className={`SS_Case_Study_1-outer-textbox roboto tct-scrollable`}
                            value={engagementData[data.textBoxId[1]] || ''}
                            readOnly
                            style={{ height: '13%', top: '43.7%', fontSize: !isFullScreen ? '12px' : '16px' }}
                            onChange={(event) => {
                                manageToolData({ toolId: data.textBoxId[1], content: event.currentTarget.value })
                            }}
                            placeholder='&#9679; Fill in…' />
                        <textarea id={engagementData[data.textBoxId[2]]}
                            className={`SS_Case_Study_1-outer-textbox roboto tct-scrollable`}
                            style={{ height: '25%', top: '68% ', fontSize: !isFullScreen ? '12px' : '16px' }}
                            value={engagementData[data.textBoxId[2]] || ''}
                            onChange={(event) => {
                                manageToolData({ toolId: data.textBoxId[2], content: event.currentTarget.value })
                            }}
                            placeholder='&#9679; Fill in…' />
                    </div>
                }
                {data.textBoxId.length === 3 && data.display &&
                    <div>
                        <textarea id={engagementData[data.textBoxId[0]]}
                            className={`SS_Case_Study_1-outer-textbox roboto tct-scrollable`}
                            value={engagementData[data.textBoxId[0]] || ''}
                            readOnly
                            style={{ height: '13%', top: '8.5%', fontSize: !isFullScreen ? '12px' : '16px' }}
                            onChange={(event) => {
                                manageToolData({ toolId: data.textBoxId[0], content: event.currentTarget.value })
                            }}
                            placeholder='&#9679; Fill in…' />
                        <textarea id={engagementData[data.textBoxId[1]]}
                            className={`SS_Case_Study_1-outer-textbox roboto tct-scrollable`}
                            value={engagementData[data.textBoxId[1]] || ''}
                            readOnly
                            style={{ height: '13%', top: '27.7%', fontSize: !isFullScreen ? '12px' : '16px' }}
                            onChange={(event) => {
                                manageToolData({ toolId: data.textBoxId[1], content: event.currentTarget.value })
                            }}
                            placeholder='&#9679; Fill in…' />
                        <textarea id={engagementData[data.textBoxId[2]]}
                            className={`SS_Case_Study_1-outer-textbox roboto tct-scrollable`}
                            style={{ height: '13%', top: '48.5%', fontSize: !isFullScreen ? '12px' : '16px' }}
                            readOnly
                            value={engagementData[data.textBoxId[2]] || ''}
                            onChange={(event) => {
                                manageToolData({ toolId: data.textBoxId[2], content: event.currentTarget.value })
                            }}
                            placeholder='&#9679; Fill in…' />
                    </div>
                }
                {
                    !data.display && <div className='SS_Case_Study_1-inside-popUp'>
                        <Modal_Component
                            type={data.popUp_content[0].type}
                            icon={Play_Arrow_Red}
                            src={data.popUp_content[0].src}
                            width={width}
                            height={height}
                        />
                    </div>
                }
            </div>
        </div>
    );
}