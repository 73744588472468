import React from 'react'
import './style.css'
import { useState } from 'react'
import { makeStyles, IconButton, Dialog, DialogContent } from '@material-ui/core'
import { Close, FiberManualRecord } from '@material-ui/icons'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
export default ({
    engagementStatus,
    currentEngagement,
    data,
    setSectionId,
    sectionId
}) => {
    const [helper, setHelper] = useState({ isOpen: false })
    let { ideaBoard } = currentEngagement;
    let openHelper = (d) => {
        setHelper({ isOpen: true, ...d })
    }

    console.log("sectionId", sectionId)

    let closeHelper = () => { setHelper({ isOpen: false }) }
    let isLive = engagementStatus[data?.engagement];
    return <div className='' style={{
        backgroundColor: '#F2F2F2', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex', height: '100%'
    }}>
        <div style={{ borderRight: '1px solid #e0dcdc', height: '100%', display: 'flex', alignItems: 'center' }}>
            <div style={{
                color: isLive ? "#BA1F00" : "#E0DCDC", display: "flex", fontSize: 13, fontWeight: 700,
                border: `3px solid ${isLive ? "#BA1F00" : "#E0DCDC"}`,
                padding: "4px 8px", borderRadius: 4, alignItems: "center", margin: "0px 26px"
            }}>LIVE <FiberManualRecord /></div>
        </div>
        <div style={{
            borderRight: (ideaBoard?.case || ideaBoard?.trigger) ? '1px solid #e0dcdc' : undefined,
            lineHeight: '22px', fontSize: 17, whiteSpace: 'nowrap', overflow: 'hidden',
            textOverflow: 'ellipsis', display: 'flex', height: '100%', alignItems: 'center', flexGrow: 1, padding: '0px 28px'
        }}>
            {ideaBoard?.question}
        </div>
        <div style={{ height: '100%', alignItems: 'center', fontSize: 13, display: 'flex', padding: '0px 16px' }}>
            {ideaBoard?.case && <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center',
                justifyContent: 'center', padding: '0px 12px'
            }}><LibraryBooksIcon onClick={() => {
                openHelper({
                    data: ideaBoard?.case,
                    icon: <LibraryBooksIcon />,
                    title: "Case"
                })
            }} /><span>Case</span></div>}
            {ideaBoard?.trigger &&
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px 12px' }}><ScatterPlotIcon onClick={() => {
                    openHelper({
                        data: ideaBoard?.trigger,
                        icon: <ScatterPlotIcon />,
                        title: "Think Triggers"
                    })
                }} /><span>Think Triggers</span></div>
            }
        </div>

        <Dialog
            maxWidth={false}
            className='piece-card-backdrop'
            open={helper.isOpen}
            onClose={closeHelper}
            container={document.getElementById("frame-id")}>
            <DialogContent>
                <div style={{ display: "flex", alignItems: "center", color: "#BA1F00", fontWeight: 700, justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>{helper?.icon}<div style={{ fontSize: 15, marginLeft: 6 }}>{helper?.title}</div></div>
                    <IconButton onClick={closeHelper}><Close /></IconButton>
                </div>
                <div style={{
                    // Image: `url(${helper.data})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    width: '65vw', height: '50vh',
                }}>

                    {helper.title == "Think Triggers" && < div dangerouslySetInnerHTML={{ __html: helper.data }}></div>}
                    <img src={helper.data} style={{
                        // backgroundColor: '#F2F2F2',
                     width: '100%', height: '100%',
                        backgroundRepeat: "no-repeat", backgroundSize: "contain",
                    }}></img>
                </div>
            </DialogContent>
        </Dialog>
    </div >
}