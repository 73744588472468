import React from "react";
import Real_World_Insights from "../../../Live_Session_Tools/Real_World_Insights";
import Slider from "../../../Live_Session_Tools/Slider";
import Slider10 from "../../../Live_Session_Tools/Slider/Slide10.png";

export default ({
    width,
    height,
    engagementData,
    manageToolData,
    isFullScreen,
    data,
    engagementStatus,
    sessionId,
    typPC,
    responses,
    currentEngagement,
    uniqueStudentsName,
}) => {
    // let progessBarValue = (uniqueStudentsName?.length / totalStudents) * 100;
    return (
        <div
            // style={{
            //     width,
            //     height,
            //     // backgroundImage: data.bgImg ?`url(${bgImg})`:`url(${bgSlide2})`,
            //     backgroundImage: `url(${Slider10})`,
            //     // backgroundImage: data.multiLang
            //     //     ? `url(${data.bgImg.hi})`
            //     //     : `url(${data.bgImg.en})`,
            //     backgroundSize: "100% 100%",
            //     backgroundRepeat: "no-repeat",
            //     position: "relative",
            //     // border: "1px solid green",
            // }}
        >
            <Slider
                typPC={typPC}
                data={data}
                engagementData={engagementData}
                manageToolData={manageToolData}
                isFullScreen={isFullScreen}
                responses={responses}
                currentEngagement={currentEngagement}
                uniqueStudentsName={uniqueStudentsName}
                sessionId={sessionId}
                engagementStatus={engagementStatus}
            />
        </div>
    );
};
