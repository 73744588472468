import React from 'react';
import Text_Box2 from '../../../Live_Session_Tools/textbox_2'
// import studentresponse from '../../../Live_Session_Tools/Images/Slide7.png'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId }) => {

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage:'white', width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', overflow: "hidden" }}>
                {/* <div style={{ position: 'absolute', top: '21%', right: '2%', width: '20%', height: '15%' }}>
                    {
                        // (sessionId) &&
                        <Start_N_Stop width={width} height={height}
                            engagementData={engagementData}
                            manageToolData={manageToolData}
                            data={data}
                            isFullScreen={isFullScreen}
                            engagementStatus={engagementStatus}
                            sessionId={sessionId}
                        />
                    }
                </div> */}

                <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%'
                }}>
                    {/* <img src={logo} style={{ width: '390px', height: '78px',position:"relative",right: "50px",bottom: "15px" }} /> */}
                    <Text_Box2 width={'100%'} height={'100%'}
                        data={data}
                        engagementStatus={engagementStatus}
                        sessionId={sessionId}
                        engagementData={engagementData}
                        manageToolData={manageToolData}
                    />
                  
                </div>
            </div>
        </div >
    );
}