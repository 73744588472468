import React from 'react';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let descriptionSize = '11px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '14px';
    }

    let displayText = ' '
    data.displayTextBoxId.forEach((id) => displayText += engagementData[id] ? engagementData[id] + '\n\n\n' : '\n\n\n');
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>

                <textarea placeholder='Fill here…'
                    className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ width: '37%', height: '51%', fontSize: descriptionSize, position: 'absolute', top: '37.5%', right: '54%' }}
                    value={displayText || ''}
                    readOnly
                />
                <textarea id={data.textBoxId} placeholder='Fill here…'
                    className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ width: '40%', height: '51%', fontSize: descriptionSize, position: 'absolute', top: '37.5%', right: '7%' }}
                    value={engagementData[data.textBoxId] || ''}
                    onChange={(event) => {
                        manageToolData({ toolId: data.textBoxId, content: event.currentTarget.value })
                    }}
                />

            </div>
        </div>
    );
}