import React from "react";
import LeftNav from "../LeftNav";
import RatingIframe from "./ratingIframe";

export default function Rating() {
    return (
        <div
            id="resources"
            className="ir-tisa-font"
            style={{ display: "flex", height: "100%" }}
        >
            <LeftNav />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: 1,
                    overflowY: "hidden",
                }}
            >
                <RatingIframe />
            </div>
        </div>
    );
}
