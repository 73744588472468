import React, { useEffect } from "react";

export default ({ src, LMSGetValue, LMSSetValue, sendData, getData, style }) => {
    let API = {}

    API.LMSInitialize = LMSInitialize;
    API.LMSGetValue = LMSGetValue;
    API.LMSSetValue = LMSSetValue;
    API.LMSCommit = LMSCommit;
    API.LMSFinish = LMSFinish;
    API.LMSGetLastError = LMSGetLastError;
    API.LMSGetDiagnostic = LMSGetDiagnostic;
    API.LMSGetErrorString = LMSGetErrorString;


    function LMSInitialize(initializeInput) {
        displayLog("LMSInitialize: " + initializeInput);
        return true;
    }

    function LMSCommit(commitInput) {
        displayLog("LMSCommit: " + commitInput);
        return true;
    }

    function LMSFinish(finishInput) {
        displayLog("LMSFinish: " + finishInput);
        return true;
    }

    function LMSGetLastError() {
        displayLog("LMSGetLastError: ");
        return 0;
    }

    function LMSGetDiagnostic(errorCode) {
        displayLog("LMSGetDiagnostic: " + errorCode);
        return "";
    }

    function LMSGetErrorString(errorCode) {
        displayLog("LMSGetErrorString: " + errorCode);
        return "";
    }

    function displayLog(textToDisplay) {
        console.log(textToDisplay)
    }

    window.API = API

    // useEffect(() => {
    //     window.addEventListener("message", (ev) => {
    //         getData(ev)
    //         const iframes = document.getElementsByTagName("iframe")
    //         for (let frame of iframes) {
    //             const fs = frame.contentDocument.getElementsByTagName("iframe")
    //             for (const f of fs) {
    //                 f.contentWindow.postMessage(JSON.stringify(sendData), "https://tools.pedgog.in")
    //             }
    //         }
    //     });
    // }, [])

    return <>
        <iframe
            src={src}
            style={style}
            id="illumine-scorm-player" />
    </>
}