import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { IFRAME_URL } from '../../utilities/common';


export default ({ open, setOpen, isFullScreen, moduleId }) => {
    let { user_token } = useSelector(state => state.auth)
    let { createdSession } = useSelector(state => state.app)
    let sessionId = createdSession?._id
    let _data = {
        sessionId, token: user_token, moduleId
    }
    const useStyles = makeStyles({
        root: {
            padding: isFullScreen ? '20px 40px 80px 40px' : '20px'

        },
        paper: {
            backgroundColor: '#f5f5f5',
            borderRadius: 10,
            padding: '15px 20px'
        },
        // ... other style rules
    });

    useEffect(() => {
        window.addEventListener("message", (e) => {
            let iframe = document.getElementById('clsAnalytics')
            iframe && iframe.contentWindow && iframe.contentWindow.postMessage(JSON.stringify(_data), "*")
        })
        return () => {
            window.removeEventListener("message", () => { })
        }
    }, [sessionId])





    let src = `${IFRAME_URL}/classAnalytics/${sessionId}`

    const classes = useStyles();
    return (
        <Dialog fullScreen
            open={open}
            classes={classes}
            container={document.getElementById("frame-id")}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ fontSize: 26, fontWeight: 700 }}>In-class Engagement Analytics</div>
                <IconButton onClick={() => { setOpen(false) }}><Close /></IconButton>
            </div>
            <iframe
                id={'clsAnalytics'}
                src={src}
                frameborder="0" width="100%" height="100%" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"
            />
        </Dialog >
    );
}
