import React from "react"
import LeftNav from "../LeftNav"

export default ({ }) => {
    return <div className={"dashboard-cntr"}>
        <LeftNav />
        <iframe
            src="https://datastudio.google.com/embed/reporting/65cfbad3-844b-4ffa-996b-ea0dfb578290/page/5iooC"
            className="w-100 h-100"
        />
    </div>
}