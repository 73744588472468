import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Dialog,
  Button,
  Typography
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  // ChevronLeft as ChevronLeftIcon,
  // ChevronRight as ChevronRightIcon,
  ArrowLeft as ChevronLeftIcon,
  ArrowRight as ChevronRightIcon,
} from '@material-ui/icons';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { addFormValues } from '../../containers/application/redux_actions';
import { convertFromRaw } from 'draft-js';
import './style.css'
import Config from "../../config"

import CancelIcon from '@material-ui/icons/Cancel';
import { mdiConsoleNetwork } from '@mdi/js';
// import { includes } from 'core-js/es/array';
import ClassAnalytics from '../classAnalytics'
import { connectToANode } from '../../utilities/firebase/fb_rtdb';
import LanguageSelection from '../LanguageSelection';
import { prepareLanguages, languages } from '../LanguageSelection/languageList';

import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { useTranslation } from 'react-i18next';


const btnCntr = {
  // height: 40, 
  // width: 214,
  margin: '0px 5px', display: 'flex', borderRadius: 4,
  justifyContent: 'center', alignItems: 'center', backgroundColor: '#efefef',
  cursor: 'pointer',
  background: '#FFBC36 0% 0% no-repeat padding-box'
}

// let lastRoadmap = '';
let lastUnit = '';
let moduleId = '';
export function updUnit(scenario, units, roadmaps, currentRoadmap, currentUnit, updatecurrentUnit, setcurrentScene, type) {
  let j = 0;
  switch (type) {
    case "Next":
      let lt = Object.keys(roadmaps[currentRoadmap]?.LearningTools)
      Object.keys(roadmaps[currentRoadmap]?.LearningTools).map((uId, i) => {
        // console.log("uId", Object.values(scenario).includes(units[uId]?.activeContent?.[0]?.scenarioId))
        // console.log(Object.values(scenario)[0].length)
        if (j === 1) {
          let m = 0;
          while (
            Object.values(scenario)[0]?.length > 0 &&
            units[uId]?.activeContent[m]?.scenarioId &&
            m != units[uId]?.activeContent?.length &&
            !Object.values?.(scenario)[0].includes(units[uId]?.activeContent?.[m]?.scenarioId)
          ) {
            m++;
          }
          if ((!Object.values(scenario)[0]?.length > 0 || Object.values?.(scenario)[0].includes(units[uId]?.activeContent?.[m]?.scenarioId)) || !units[uId]?.activeContent[0]?.scenarioId) {
            setcurrentScene(0);
            updatecurrentUnit(uId);
            j = 0;
          }
        }
        if (currentUnit === uId) {
          j = 1;
        }
      })
      break;
    case "Prev":
      Object.keys(roadmaps[currentRoadmap]?.LearningTools).map((uId, i) => {
        // console.log("m")
        if (j === 1) {
          setcurrentScene(0);
          updatecurrentUnit(Object.keys(roadmaps[currentRoadmap].LearningTools)[i - 2]);
          j = 0;
        }
        if (currentUnit === uId && i > 0) {
          j = 1;
        }
      })
      break;
  }
}

export function fnupdatePercentage(data) {

  if (data) {
    // fetch('http://localhost:3000/v1/progress/createProgressPercentage', {
    fetch(Config.API_URL + 'progress/createProgressPercentage', {
      //fetch('https://beta.pedgog.in:3000/v1/progress/createProgressPercentage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "bearer " + data.token,
        "accept": "application/json"
      },
      body: JSON.stringify({
        userId: data.uid,
        moduleId: data.moduleId,
        progressType: data.typPC,
        roadmapId: data.rrid,
        learningToolsId: data.lid,
        activeContent: data.aContent,
        isLastSlide: data.isLastSlide,
        sessionId: data.sessionId ? data.sessionId : undefined,
        currentSlide: data.cScne
      })
    })
      .then(response => response.json())
      .then(data1 => {
        if (data1.result > 0) {
          data.updatepercomps(data1.result)
        }
      }
      )
      .catch(error => {
        console.log(error);
      })
  }
}

let Navigation = ({ haveCommonElement,
  toggleFullScreen, currentScene, currentUnit, currentRoadmap,
  units, token, roadmaps, typPC, data, navigationEvent, nextButton,
  prevButton, redirect, sessionId, isFullScreen, show, onLanguageChange,
  selectedLang, setSelectedLang
  , selectedLangDescription, setSelectedLangDescription, forNavigator, hideShowNavigator
}) => {
  let aContent = units && units[currentUnit] && units[currentUnit].activeContent && units[currentUnit].activeContent[currentScene]
    ? units[currentUnit].activeContent[currentScene].id
    : '';
  let lid = '';
  moduleId = data.id;

  const { auth, app } = useSelector(state => state)
  let { learningTools } = app || {}
  let otp = app?.otpData?.otp
  let engagements = Object.keys(app?.response || {}).length
  let [classAnalytics, setClassAnalytics] = useState(null)

  const handleExitFullscreen = () => document.exitFullscreen();

  let isScrom = false

  //  learningTools?.[currentUnit]?.activeContent?.[currentScene]?.type === 'scorm' ? true : false

  useEffect(() => {
    if (sessionId) {
      connectToANode({
        refString: `test/live_session/${sessionId}`
        , f: (m) => {
          if (m) {
            let b = m?.enableEngagement
            let submit = Object.keys(b || {}).some(eng => Object.values(m?.[eng] || {}))
            let stop = Object.values(b || {}).every(v => v?.eventName == 'STOP')
            let a = Object.keys(m?.Participants || {})?.length > 0 && submit && stop
            setClassAnalytics(a)
          }
        }
      });
    }
  }, [sessionId, currentScene]);
  const { i18n } = useTranslation();

  const LangSelection = ({ }) => {
    return <>
      {typeof (units?.[currentUnit]?.activeContent?.[currentScene]?.url || {}) == 'object' && <LanguageSelection
        handleChange={(ev) => { i18n.changeLanguage(ev.target.value); setSelectedLang(ev.target.value) }}
        isFullScreen={isFullScreen}
        typPC={typPC}
        forNavigator={forNavigator}
        allLanguages={languages}
        value={selectedLang || 'en'}
        langugeList={Object.keys(units?.[currentUnit]?.activeContent?.[currentScene]?.url || {})}
      />
      }
    </>
  }

  const showNavigator = () => {
    const disableNavigator = learningTools?.[currentUnit]?.activeContent?.[currentScene]?.disableNavigator;
    return (disableNavigator ? false : true);
  }

  let firstPrevDisabled = roadmaps?.[currentRoadmap]?.LearningTools &&
    !Object?.keys(roadmaps?.[currentRoadmap]?.LearningTools)?.indexOf(currentUnit) &&
    (Object?.keys(roadmaps?.[currentRoadmap]?.LearningTools)?.indexOf(currentUnit) === 0 &&
      currentScene === 0)

  // console.log("firstPrevDisabled", firstPrevDisabled)

  const _currentSlide = units?.[currentUnit]?.activeContent?.[currentScene]
  const disabledEndSession = _currentSlide?.disabledEndSession;

  return (
    <div className={`ir-bottom-nav`} style={{}}>
      <div className='d-flex h-100'>
        {isFullScreen ?
          <div className='d-flex align-items-center'>
            <div className='d-flex cursor-pointer h-100' style={{ lineHeight: 15 }} onClick={handleExitFullscreen} >
              <FullscreenExitIcon alt='Illumine' style={{ alignSelf: 'center', marginLeft: "20px" }} />
              <span className='ir-fullscreen-text' style={{ lineHeight: ' 19px' }}>Exit<br />fullscreen</span>
            </div>
          </div>
          :
          <div className='d-flex cursor-pointer h-100' onClick={toggleFullScreen} style={{ background: "#FF3100 0% 0% no-repeat padding-box", borderRadius: '0px 0px 0px 12px' }} >
            <FullscreenIcon alt='Illumine' style={{ alignSelf: 'center', marginLeft: "15px" }} />
            <span style={{
              marginRight: "15px",
              fontSize: "18px",
              lineHeight: "23px",
              letterSpacing: "0.18px",
              alignSelf: "center"
            }}>
              fullscreen
            </span>
          </div>
        }

        {
          !_currentSlide?.dontShowOtp && typPC != "Prepare" && otp && <div
            className='pos-absolute d-flex'
            style={{
              fontSize: "18px",
              lineHeight: "23px",
              letterSpacing: "0.18px",
              color: 'white', fontWeight: 700, top: '1.7vh', left: isFullScreen ? '12vw' : '12vw', gap: 4
            }}>
            <VpnKeyIcon />: {otp || null}
          </div>
        }

      </div>
      <div
        className='h-100 text-center pos-absolute'
        style={{
          width: isFullScreen ? '30%' : '35%',
          right: isFullScreen ? '35%' : '30%'
        }}>
        {true &&
          <div style={{ display: 'flex', justifyContent: 'center', height: "100%", width: '100%' }}>
            <div
              className={`w-50 ir-bottom-nav-button ir-botton-nav-prev-button`}
              // style={ ? { background: 'grey !important' } : {}}
              style={{ background: firstPrevDisabled ? 'grey' : '', userSelect: 'none' }}
              onClick={() => {
                navigationEvent('previous')
              }}>
              <ChevronLeftIcon
                className='ir-botton-nav-button-icon' style={{ fill: firstPrevDisabled ? 'grey' : 'rgb(255, 188, 54)', width: isFullScreen ? "1.5em" : "1.2em", height: isFullScreen ? "1.5em" : "1.2em" }} />
              <span className='ir-botton-nav-prev-button-text' style={{ fontSize: isFullScreen ? '1.25em' : '0.9em' }}>
                {prevButton.text}
              </span>
            </div>
            <div
              className={`w-50 ir-bottom-nav-button ${nextButton.css} justify-content-flex-end ir-botton-nav-next-button`}
              style={{
                cursor: disabledEndSession && nextButton.css === 'ir-bottom-nav-button-close' ? 'not-allowed' : 'pointer', backgroundColor: disabledEndSession ? 'grey' : nextButton.css === 'ir-bottom-nav-button-close' ? '#E4001B' : "rgb(255, 188, 54)", userSelect: 'none'
              }}
              onClick={() => {
                if (!disabledEndSession) navigationEvent('next')
                return

              }}>
              <span className='ir-botton-nav-next-button-text' style={{ color: nextButton.css === 'ir-bottom-nav-button-close' ? 'white' : "black", fontSize: isFullScreen ? '1.25em' : '0.9em' }}>
                {nextButton.text}
              </span>
              {nextButton.css === 'ir-bottom-nav-button-close' ? <CancelIcon style={{ background: disabledEndSession ? 'grey' : '' }} className='ir-botton-nav-button-close-icon' /> : <ChevronRightIcon className='ir-botton-nav-button-icon' style={{ fill: 'rgb(255, 188, 54)', width: isFullScreen ? "1.5em" : "1.2em", height: isFullScreen ? "1.5em" : "1.2em" }} />}
            </div>
          </div>}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <div
          className=' d-flex align-items-center '
          style={{
            fontSize: "16px", lineHeight: "20px", letterSpacing: "0.16px", opacity: "0.75", marginRight: "10px",
            width: '22vw',
            display: 'inline-block',
            whiteSpace: 'nowrap', overflow: 'hidden', wordBreak: 'break-word', textOverflow: 'ellipsis'
          }}>
          {isFullScreen ? `${data.description}` : null}
        </div>
        <div style={{ paddingRight: '20px', display: 'flex', alignItems: 'center', gap: '1vh' }}>
          {typPC == 'Prepare' ?
            !haveCommonElement() ? typeof (units?.[currentUnit]?.activeContent?.[currentScene]?.url || {}) == 'object' && `|` : isFullScreen && typeof (units?.[currentUnit]?.activeContent?.[currentScene]?.url || {}) == 'object' && `|`
            : isFullScreen && typeof (units?.[currentUnit]?.activeContent?.[currentScene]?.url || {}) == 'object' && `|`}
          {typPC == 'Prepare' ?
            !haveCommonElement() ? typeof (units?.[currentUnit]?.activeContent?.[currentScene]?.url || {}) == 'object' && <LangSelection /> : isFullScreen && typeof (units?.[currentUnit]?.activeContent?.[currentScene]?.url || {}) == 'object' && <LangSelection />
            : isFullScreen && typeof (units?.[currentUnit]?.activeContent?.[currentScene]?.url || {}) == 'object' && <LangSelection />}
        </div>
      </div>

      <div style={{ position: 'absolute', top: '1.5vh', right: isFullScreen ? '29vw' : '8vw', }}>
        {data?.showClassAnalytics && classAnalytics ?
          <ClassAnalytics
            isFullScreen={isFullScreen}
            data={data}
            moduleId={moduleId}
          /> : null
        }
      </div>
      {redirect && <Redirect to='/application' />}
    </div >
  )
}

export default Navigation;
