import React, { useState } from 'react';
import Modal_Component from '../../../Tools/Modal_Component';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, language }) => {

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg?.[language] ? data.bgImg[language] : data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div id='modal-id-parent' className='railway-fruit-clickin-popup-button' >
                    <Modal_Component
                        type={data.popUp_content[0].type}
                        icon={data.popUp_content[0].icon?.[language] ? data.popUp_content[0].icon[language] : data.popUp_content[0].icon}
                        src={data.popUp_content[0].src}
                        language={language}
                        width={width - 50}
                        height={height - 50}
                        parentId='modal-id-parent'
                    />
                </div>
            </div>
        </div >
    );
}