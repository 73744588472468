// import React from "react";
// import { makeStyles, withStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";
// import Slider from "@material-ui/core/Slider";
// import { useSelector } from "react-redux";
// import { StylesProvider } from "@material-ui/core/styles";
// import "./style.css";
// import icon from "./ped.svg";
// import Start_N_Stop from "../Start_N_Stop";
// import LinearProgress from "@material-ui/core/LinearProgress";

// export default ({
//   width,
//   height,
//   engagementData,
//   manageToolData,
//   isFullScreen,
//   data,
//   engagementStatus,
// }) => {
//   const useStyles = makeStyles({
//     root: {
//       height: 300,
//     },
//   });

//   const classes = useStyles();
//   const responses = useSelector((state) => state.app.response[data.engagement]);
//   const answers1 = useSelector((state) => state.app.responseCount1);
//   const names = useSelector((state) => state.app.names);
//   const uniqueStudentsName = useSelector(
//     (state) => state.app.uniqueStudentsName[data.engagement]
//   );
//   const [val, setVal] = React.useState(data.marking);
//   const answers = [];
//   const studentslabel = [];
//   // console.log("unique students", uniqueStudentsName);
//   let Obj = {};

//   if (answers1) {
//     Object.values(answers1).map((el) => {
//       Object.values(el).map((ts) => {
//         Object.values(ts).map((fs) => {
//           fs.map((ds) => {
//             answers.push(ds);
//           });
//         });
//       });
//     });
//   }
//   // console.log("names");
//   if (names) {
//     Object.values(names).map((el, i) => {
//       let Obj1 = Object.assign({}, { username: names[i], ans: answers[i] });
//       studentslabel.push(Obj1);
//       Obj[answers[i]] = [];
//     });
//   }

//   Object.keys(Obj).map((ts) => {
//     let qwer = [];
//     studentslabel.map((el) => {
//       if (ts === el.ans) {
//         qwer.push(el.username);
//         Obj[el.ans] = qwer;
//       }
//     });
//   });
//   // console.log("Obj", Obj);

//   let mf = 1;
//   let m = 0;
//   let item;

//   for (let i = 0; i < answers.length; i++) {
//     for (let j = i; j < answers.length; j++) {
//       if (answers[i] == answers[j]) {
//         m++;
//         if (m > mf) {
//           mf = m;
//           item = answers[i];
//         }
//       }
//     }
//     m = 0;
//   }
//   const marks = [
//     {
//       value: 0,
//       label: "Strong Trust Surplus",
//     },
//     {
//       value: 25,
//       label: "Small Trust Surplus",
//     },
//     {
//       value: 50,
//       label: "No Change In Trust",
//     },
//     {
//       value: 75,
//       label: "Small Trust Deficit",
//     },
//     {
//       value: 100,
//       label: "Strong Trust Deficit",
//     },
//   ];
//   const BorderLinearProgress = withStyles((theme) => ({
//     root: {
//       height: 69,
//     },
//     colorPrimary: {
//       backgroundColor:
//         theme.palette.grey[theme.palette.type === "light" ? 200 : 100],
//     },
//     bar: {
//       // backgroundColor: "#1a90ff",
//       backgroundColor: "#FFE39A",
//     },
//   }))(LinearProgress);
//   console.log("answers", answers);
//   return (
//     <div style={{ width, height, paddingBottom: "2%" }}>
//       <div
//         style={{
//           width: "70%",
//           height: "15%",
//           padding: "1% 3% 0% 2%",
//           //  border: "1px solid red"
//         }}
//       >
//         In this situation, assess- How much trust have we built or lost with the
//         citizen? Explain why it is happening.
//       </div>
//       <div
//         style={{
//           display: "flex",
//           height: "75%",
//           // border: "1px solid",
//           padding: "3% 2% 3% 2%",
//         }}
//       >
//         <div
//           style={{
//             width: "65%",
//             // border: "2px solid",
//             borderRadius: "25px",
//             boxShadow: "-2px 0px 15px rgba(0, 0, 0, 0.25)",
//           }}
//         ></div>

//         <div
//           className={classes.root}
//           style={{
//             // marginLeft: "2%",
//             // marginTop: "2%",
//             // border: "2px solid red",
//             boxShadow: "-2px 0px 15px rgba(0, 0, 0, 0.25)",
//             width: "30%",
//             height: "100%",
//             padding: "2%",
//             borderRadius: "30px",
//             marginLeft: "5%",
//           }}
//         >
//           <StylesProvider injectFirst>
//             <Slider
//               orientation="vertical"
//               defaultValue={50}
//               value={item ? val[item] : 50}
//               step={25}
//               aria-labelledby="vertical-slider"
//               marks={marks}
//             />
//           </StylesProvider>
//         </div>
//       </div>

//       <div
//         style={{
//           width: "100%",
//           height: "10%",
//           // border: "1px solid",
//           position: "relative",
//         }}
//       >
//         <div style={{ height: "10%" }}>
//           <BorderLinearProgress variant="determinate" value={100} />
//         </div>
//         <div
//           style={{
//             // height: "30px",
//             position: "absolute",
//             top: "0px",
//             left: "5px",
//             marginTop: "1.3%",
//             width: "50%",
//             // border: "2px solid red",
//           }}
//         >
//           {answers.length} / 21 <span>responded</span>
//         </div>
//         <div
//           style={{
//             borderRadius: "20px",
//             // height: "30px",
//             position: "absolute",
//             top: "0px",
//             right: "5px",
//             marginTop: "1.3%",
//           }}
//         >
//
//         </div>
//       </div>
//     </div>
//   );
// };

import React, { useState } from "react";
import Slider from "@material-ui/core/Slider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "./style1.css";
import Start_N_Stop from "../Start_N_Stop";
import { useSelector } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import Slide10 from "./Slide10.png";
import SliderIcon from "./SliderIcon.png";
import ReactTooltip from "react-tooltip";
import { mdiConsoleNetworkOutline } from "@mdi/js";
import ProgressBar from "../ProgressBar";

export default ({
  width,
  height,
  engagementData,
  manageToolData,
  isFullScreen,
  engagementStatus,
  data,
  currentEngagement, typPC,
  sessionId,
  language,
}) => {
  const responses = useSelector((state) => state.app.response[data.engagement]);
  const answers1 = useSelector((state) => state.app.responseCount1);
  const names = useSelector((state) => state.app.names);
  const studentJoinedList = useSelector((state) => state.app.studentJoinedList);
  const uniqueStudentsName = useSelector(
    (state) => state.app.uniqueStudentsName
  );
  const [val, setVal] = React.useState(undefined);
  const answers = [];
  const studentslabel = [];
  let Obj = {};
  let newPercentageObj = {};
  let totalStudents = Object.values(studentJoinedList)?.length;


  const useStyles = makeStyles((theme) => ({
    root: {
      position: "absolute !important",
      color: "transparent",
      height: `${data.sliderCss["height"]} !important`,
      // border: "2px solid red",
      // height: 15,
      width: 15,
      right: `${data.sliderCss["right"]}`,
      top: `${data.sliderCss["top"]}`,
      bottom: `${data.sliderCss["bottom"]}`,
      left: `${data.sliderCss["left"]}`,
      // border: `${data.sliderCss["border"]}`,
      // border: "1px solid red",
    },

    thumb: {
      height: isFullScreen ? 55 : 40,
      width: isFullScreen ? 45 : 30,
      // backgroundColor: "transparent",
      // border: "2px solid red",
      marginTop: -8,
      marginLeft: 10,
      marginBottom: isFullScreen ? "-30px !important" : "-22px !important",
      boxShadow: "none !important",
    },

    track: {
      height: 8,
      borderRadius: 4,
      // border: "1px solid blue",
    },
  }));

  const classes = useStyles();

  if (answers1) {
    Object.values(answers1).map((el) => {
      Object.values(el).map((ts) => {
        Object.values(ts).map((fs) => {
          fs.map((ds) => {
            answers.push(ds);
          });
        });
      });
    });
  }

  // const result = answers.reduce(
  //   (acc, curr) => ((acc[curr] = (acc[curr] || 0) + 1), acc),
  //   {}
  // );

  if (names) {
    Object.values(names).map((el, i) => {
      let Obj1 = Object.assign({}, { username: names[i], ans: answers[i] });
      studentslabel.push(Obj1);
      Obj[answers[i]] = [];
    });
  }

  Object.keys(Obj).map((ts) => {
    let qwer = [];
    studentslabel.map((el) => {
      if (ts === el.ans) {
        qwer.push(el.username);
        Obj[el.ans] = qwer;
      }
    });
  });
  // console.log("Obj", Obj);

  function findSliderVal(arr) {
    let mf = 1;
    let m = 0;
    let item;

    for (let i = 0; i < arr.length; i++) {
      for (let j = i; j < arr.length; j++) {
        if (arr[i] == arr[j]) {
          m++;
          if (m > mf) {
            mf = m;
            item = arr[i];
          }
        }
      }
      m = 0;
    }

    return item;
  }

  // let newArr = [];
  // Object.keys(result).map((key) => {
  //   // console.log("key", key);
  //   let counter = 0;
  //   let x = 0;
  //   // let newAns;
  //   for (x in answers) {
  //     if (answers[x] == key) {
  //       counter++;
  //       // Object.assign({},{answers[i]:counter})
  //     }
  //     // // newAns = answers[x];
  //   }
  //   let newObj = Object.assign({}, { ans: key, count: counter });
  //   newArr.push(newObj);
  // });

  // console.log("newArr", newArr);

  Object.values(Obj).map((data, i) => {
    let newData = [];
    let el = [...new Set(data)];
    let percentage = (el.length * 100) / totalStudents;
    newPercentageObj[Object.keys(Obj)[i]] = `${percentage}%`;
  });


  // console.log("totalStudents", totalStudents);
  // console.log("newPercentageObj", newPercentageObj);
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      // height: 60,
      height: "100%",
    },
    colorPrimary: {
      backgroundColor: "red",
    },
    // bar: {
    //   // backgroundColor: "#1a90ff",
    //   backgroundColor: Liquidity.colors.main.yellow,
    // },
    barColorPrimary: {
      backgroundColor: "#F5F5F5",
    },
  }))(LinearProgress);

  // console.log("uniqueStudentsName", uniqueStudentsName);
  // console.log("totalStudents", totalStudents);
  // console.log("respo_count",respo_count)
  let progessBarValue = (uniqueStudentsName?.[data.engagement]?.length / totalStudents) * 100;
  return (
    <div
      style={{
        width,
        height,
        // border: "2px solid red",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          // backgroundImage: `url(${
          //   data.bgImg?.[language] ? data.bgImg[language] : data.bgImg
          // })`,
          backgroundImage: `url(${Slide10})`,
          width: "100%",
          height: "85%",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          position: "relative",
          // border: "2px solid",
        }}
      >
        {/* <div style={sliderCss}> */}

        <Slider
          classes={{
            root: classes.root,
            thumb: classes.thumb,
            track: classes.track,
          }}
          className={classes.root}
          orientation="vertical"
          defaultValue={data?.defaultValue || 50}
          value={
            findSliderVal(answers) ? data?.marking[findSliderVal(answers)] : 50
          }
          // getAriaLabel={getAriaLabel}

          // marks={data.marks}
          ThumbComponent={(props) => (
            <div>
              <img
                data-tip
                data-for="Slider"
                {...props}
                src={data.sliderIcon}
              />
              <ReactTooltip id="Slider">
                {Obj &&
                  Obj[findSliderVal(answers)] &&
                  Obj[findSliderVal(answers)]?.map((users) => {
                    return (
                      <ul>
                        <li>{users}</li>
                      </ul>
                    );
                  })}
              </ReactTooltip>
            </div>
          )}
        // valueLabelDisplay="on"
        // ValueLabelComponent={(props) => (
        //   <div style={{ border: "1px solid" }}>
        //     <span data-tip data-for="Slider" {...props}>
        //       {props.value}
        //     </span>
        //     <ReactTooltip id="Slider">10</ReactTooltip>
        //   </div>
        // )}
        />

        {/* </div> */}
      </div>
      <ProgressBar
        typPC={typPC}
        data={data}
        engagementData={engagementData}
        manageToolData={manageToolData}
        isFullScreen={isFullScreen}
        responses={responses}
        currentEngagement={currentEngagement}
        uniqueStudentsName={uniqueStudentsName}
        sessionId={sessionId}
        progessBarValue={progessBarValue}
        // setShow={setShow}
        // answers={newDipLabel}
        totalStudents={totalStudents}
        engagementStatus={engagementStatus}
      />
    </div>
  );
};
