import React from "react";
import Poll_Dipstick from "../../../Live_Session_Tools/Poll_Dipstick";
// import Poll_Dipstick from "../../../Live_Session_Tools/Poll_Dipstick/index-c";
// import Radar_Chart from "../../../Live_Session_Tools/Radar_Chart";

export default ({
  width,
  height,
  engagementData,
  manageToolData,
  isFullScreen,
  data,
  engagementStatus,
  sessionId,
  typPC,
  responses,
  currentEngagement,
  uniqueStudentsName,
}) => {
  // let progessBarValue = (uniqueStudentsName?.length / totalStudents) * 100;
  return (
    <div
      style={{
        width,
        height,
        // // backgroundImage: data.bgImg ?`url(${bgImg})`:`url(${bgSlide2})`,
        backgroundImage: data.multiLang
          ? `url(${data.bgImg.hi})`
          : `url(${data.bgImg.en})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        position: "relative",
        // border: "1px solid green",
      }}
    >
      <Poll_Dipstick
        typPC={typPC}
        data={data}
        engagementData={engagementData}
        manageToolData={manageToolData}
        isFullScreen={isFullScreen}
        responses={responses}
        currentEngagement={currentEngagement}
        uniqueStudentsName={uniqueStudentsName}
        sessionId={sessionId}
        engagementStatus={engagementStatus}
      />
    </div>
  );
};
