import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import OtpInput from "react-otp-input";
import { OutlinedInput } from "@material-ui/core";

export default ({
  type,
  placeHolder,
  field,
  values,
  handleChange,
  handleClickShowPassword,
  handleMouseDownPassword,
  disabled,
  required,
  error,
}) => {
  const useStyles = makeStyles((theme) => ({
    filledRoot: {
      // '& > *': {
      //     margin: theme.spacing(1),
      // },
      // transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      /* background-color: rgba(0, 0, 0, 0.09); */
      // borderRadius: '17px',
      // padding: '8px',
      /* border-top-right-radius: 4px; */
      // border: '2px solid #E0E0E0',
      backgroundColor: "transparent",
      width: "100%",
      fontSize: "1.75rem",
      letterSpacing: placeHolder === "OTP" ? "2.5vw" : 1,
      fontFeatureSettings: "'lnum' 1",
      fontSize: "1.4rem",
      borderRadius: '8px',
      fontWeight: "500",
      // '&$focused': {
      //     backgroundColor: '#fff',
      // }
      "&:hover": {
        // backgroundColor: '#f5f5f5 !important'
      },
    },
    filledRootFocused: {
      // backgroundColor: "#E8FOFE !important",
    },

    formControlRoot: {
      display: "block",
    },
    filledEmailLabel: {
      transform: "translate(20px, 30px) scale(1.15)",
      // color: '#9D9D9D',
    },
    filledEmailLabelShrink: {
      // transform: "translate(15px, 13px) scale(0.85) !important",
      // color: '#BA1F00 !important'
      color: "#666666",
    },
    filledPasswordLabel: {
      transform: "translate(20px, 45px) scale(1.15)",
      // marginTop: "-10%",
      color: "#666666",
    },
    filledPasswordLabelShrink: {
      transform: "translate(17px, 25px) scale(0.85) !important",
      // color: '#BA1F00 !important',
      color: "#666666",
    },
    passwordLabelFocused: {
      // marginTop: "20%",
      // color: "red",
    },
    passwordLabel: {
      // marginLeft: "-1%",
      // paddingLeft: "1%",
      marginTop: values?.[field] ? "4%" : "5%",
    },
    buttonLabel: {},
    buttonRoot: {
      "&:hover": {
        backgroundColor: "#e4001b !important",
        color: "white",
      },
    },
    inputLabel: {
      // marginLeft: "-1%",
      // paddingLeft: "1%",
      borderRadius: '8px',
      fontWeight: "200",
      // width: '75%',
      // textAlign: 'center',
      // height: '100%',
      // verticalAlign: 'middle'
      // marginTop: values?.[field] ? "-4%" : "auto",
      marginTop: "auto",
    },
    inputLabelFocused: {
      // color: "#666666 !important",
      backgroundColor: "transparent",
      marginTop: "-1%",
    },

    filledInput: {
      padding: "20px 10px",
      height: "4vh",
      marginLeft: "2%",
      fontSize: "1rem",
      backgroundColor: "transparent",
    },
  }));

  const classes = useStyles();
  switch (type) {
    case "Password":
      return (
        <FormControl
          classes={{ root: classes.formControlRoot }}
          variant="outlined"
        >
          <InputLabel
            htmlFor="outlined-adornment-password"
            classes={
              {
                //   filled: classes.filledPasswordLabel,
                //   shrink: classes.filledPasswordLabelShrink,
                //   // filled: classes.filledRootFocused,
                //   // shrink: classes.filledEmailLabelShrink,
                //   focused: classes.passwordLabelFocused,
                //   root: classes.passwordLabel,
                // // filled: classes.filledEmailLabel,
                // shrink: classes.filledEmailLabelShrink,
                // // // focused: classes.passwordLabelFocused,
                root: classes.passwordLabel,
              }
            }
          >
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values?.showPassword ? "text" : "password"}
            value={values?.password}
            // disableUnderline
            classes={{
              root: classes.filledRoot,
              focused: classes.filledRootFocused,
            }}
            style={{ margin: "10px 0px" }}
            onChange={(event) => handleChange(event, field)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
      );
    case "otp":
      return (
        <OtpInput
          value={values[field]}
          onChange={(newOtp) => {
            handleChange(newOtp, field);
          }}
          numInputs={6}
          isInputNum
          inputStyle="ir-otp-input"
          // separator={<span style={{ margin: "25px 5px" }} />}
          containerStyle="ir-otp-input-container"
        />
      );
    default:
      return (
        <TextField
          key={"input " + field}
          id={"outlined-required " + field}
          label={placeHolder}
          variant="outlined"
          value={values?.[field] || ""}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: classes.filledRoot,
              focused: classes.filledRootFocused,
              input: classes.filledInput,
            },
          }}
          onChange={(event) => handleChange(event, field)}
          style={{ margin: "10px 0px" }}
          classes={{
            root: classes.formControlRoot,
          }}
          InputLabelProps={{
            classes: {
              filled: classes.filledInputLabel,
              shrink: classes.filledEmailLabelShrink,
              focused: classes.inputLabelFocused,
              root: classes.inputLabel,
            },
          }}
          disabled={disabled}
          required={required}
          error={error?.[field]}
          helperText={error?.[field]}
        />
      );
  }
};
