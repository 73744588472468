import React from 'react';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let descriptionSize = '14px';

    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '21px';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <textarea id={data.textBoxId} placeholder='Fill here…'
                    className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ fontSize: descriptionSize, position: 'absolute', width: '41%', top: '58.5%', right: '7.5%', height: '20%' }}
                    value={engagementData[data.textBoxId] || ''}
                    onChange={(event) => {
                        manageToolData({ toolId: data.textBoxId, content: event.currentTarget.value })
                    }}
                />
            </div>
        </div>
    );
}