import React, { useEffect, useState } from 'react';
import ProjectCard from '../ProjectCard';
import { getProject } from "../../containers/application/redux_actions";
import { useSelector } from 'react-redux'
import './style.css'

export default () => {
  const app = useSelector(state => state.app)
  const auth = useSelector(state => state.auth)
  useEffect(() => {
    getProject({ user_token: auth.user_token });
  }, [])

  // let [projectList, setProjectList] = useState(app.projects)

  // {
  //   title: 'Project Title',
  //   description: 'Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description Project Description ',
  //   module: 'Project - 3',
  //   moduleDescription: "Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
  //   icon: 'http://43.229.227.26:81/acsportal/explore1.jpg',
  //   students: ['ABC', 'XYZ'],
  //   file: { url: 'http://www.africau.edu/images/default/sample.pdf' }
  // }
  // const uniqueModule = new Set();
  // dataArray.map(o => {
  //   uniqueModule.add({
  //     module: o.module,
  //     moduleDescription: o.moduleDescription
  //   })
  // })

  let hashMap = new Map();

  let uniqueModule = app.projects.length > 0 ? app.projects.filter(o => {
    const val = hashMap.has(o.module)
    if (val)
      return false;
    hashMap.set(o.module, o.moduleDescription);
    return true;
  }) : ''

  const populateProjects = (module) => {
    return app.projects.map((el, i) => {
      if (el && el.module === module) {
        let { title, description, students, file } = el;
        return <ProjectCard
          key={i}
          title={title}
          studentName={students}
          description={description}
          icon={file.poster}
          link={file.url}
        />
      }
      return null;
    })
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {
        app.projects.length > 0 && uniqueModule.map((item, i) => <div key={i} style={{ margin: '5% 0', width: '100%', height: '90%' }}>
          <h3 style={{ margin: '2% 0' }}>Project {i + 1} : {item.module}</h3>
          {/* <p style={{ lineHeight: '25px', fontSize: '14px', marginTop: '0.5rem', marginBottom: '2.25rem' }}>{item.moduleDescription}</p> */}
          <p style={{ lineHeight: '25px', fontSize: '14px', marginTop: '0.5rem', marginBottom: '2.25rem' }}>{item.module === 'Building a Contibutor Profile' ? 'See contributor profiles that students have build after interviewing a real-life contributor based on all aspects of a Contributor they went through in the first 6 modules of the program.' : 'See solutions built by students to citizen challenges in their locality/ institution/ student community/ other communities by using the solution thinking method and evaluating it using the value map'}</p>
          <div className="tct-scrollable" style={{ maxHeight: '80%', overflowY: 'auto', overflowX: 'visible' }}>
            <div className="grid-wrapper-project">
              {populateProjects(item.module)}
            </div>
          </div>
        </div>)
      }
    </div>
  )
}


