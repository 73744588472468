import React from 'react'
import { useSelector } from "react-redux"
import VideoSlide from '../../components/VideoSlide'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, language }) => {
    const { metaKey, metaName } = data || {}

    const sessionData = useSelector(state => state.app.createdSession) || {}
    const userMeta = useSelector(state => state.app.user.meta) || {}

    let userDistrict = userMeta?.[metaKey]?.State?.[metaName] || userMeta?.[metaKey]?.State
    let batchDistrict = sessionData?.meta?.District
    let key = userDistrict ? userDistrict : batchDistrict

    let _default = Object.values(data.videoUrl)[0]
    let { url, imgUrl } = data.videoUrl?.[key] || _default

    console.log({ batchDistrict, userDistrict })

    return <VideoSlide
        vidUrl={url} dimensions={{
            slideWidth: width,
            slideHeight: height
        }}
        imgUrl={imgUrl || ''}
    />

}
