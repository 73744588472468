import React from "react";
import {
    Button,
} from "@material-ui/core";
import Icon from '@mdi/react';
import { withStyles } from "@material-ui/styles";

const DownloadButton = withStyles({
    root: {
        padding: "0",
        minWidth: "0",

        "& path": {
            fill: "grey !important"
        },

        "&:hover": {
            backgroundColor: "transparent",            

            "& path": {
                fill: "black !important"
            },

            "& .button-text": {
                // display: "block",
                color: 'black !important'
            }
        },

        "& .button-text": {
            // display: "none",
            color: "grey !important"
        }
    }
})(Button)

export default ({ icon, text, href }) => {
    return <a href={href} style={{ width: "fit-content" }} download target="_blank" rel="noopener noreferrer">
        <DownloadButton>
            <Icon path={icon} size={1.4} />
            <span className="button-text">{text}</span>
        </DownloadButton>
    </a>
}