import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip'

import VideoSlide from '../VideoSlide';

import HelpOutlineTwoToneIcon from '@material-ui/icons/HelpOutlineTwoTone';
// import IntroScreen from '../App_ExploreDashboard/introScreen';

const useStyles = makeStyles((theme) => ({
 appBar: {
  position: 'relative',
 },
 closeIcon: {
  position: 'absolute',
  right: '10px',
  zIndex: '1',
  background: "white"
 },
 title: {
  marginLeft: theme.spacing(2),
  flex: 1,
 },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
 return <Slide direction="down" ref={ref} {...props} />;
});

export default function FullScreenDialog({ toolTip, poster, url }) {
 const classes = useStyles();
 const [open, setOpen] = React.useState(false);

 const handleClickOpen = () => {
  setOpen(true);
 };

 const handleClose = () => {
  setOpen(false);
 };

 const customButton_1 = {
  backgroundColor: '#ffbc36',
  cursor: 'pointer',
  fontSize: '12px'
 }

 return (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
   {
    toolTip ? <Button style={customButton_1} onClick={handleClickOpen}>
     Play video
    </Button> : <div>
     <Tooltip title='Play Tutorial' placement="top" arrow>
      <HelpOutlineTwoToneIcon style={{ color: 'rgb(173 173 173)', fontSize: '34', cursor: 'pointer' }} onClick={handleClickOpen} />
     </Tooltip>
     <div className='last-div-label'>Help</div>
    </div>
   }

   <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
    <div>
     <Tooltip title='Close'>
      <IconButton className={`${classes.closeIcon} videoCloseIcon`} onClick={handleClose}>
       <CloseIcon />
      </IconButton>
     </Tooltip>
    </div>
    <VideoSlide vidUrl={url} dimensions={{ slideHeight: '100%', slideWidth: '100%' }} imgUrl={poster} popupWidth='100%' popupHeight='100%' />
   </Dialog>
  </div>
 );
}
