import React from 'react'
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { IFRAME_URL } from '../../../utilities/common'
import Interaction from "../../../containers/application/Interaction"

export default ({
    sessionId,
    toolId,
    item,
    isFullScreen,
    currentScene,
    currentUnit,
    app,
    data,

    auth,
}) => {
    let { user, user_token } = useSelector(state => state.auth)
    let { otpData } = useSelector(state => state.app)
    let _data = {
        sessionId,
        learningToolId: currentUnit,
        engagementId: data?.engagement,
        otp: otpData?.otp,
        userId: user?.id,
        token: user_token
    }
    // console.log(`http://localhost:3001/ideaBoard/${sessionId}?isFullScreen=true&toolId=${toolId}&learningToolId=${currentUnit}&currentScene=${currentScene}&engagementId=${item?.engagement}&otp=${app?.otpData?.otp}&userId=${auth.user?.id}&token=${auth?.user_token}`)

    let userId = user?.id
    let otp = otpData?.otp

    useEffect(() => {
        window.addEventListener("message", (e) => {
            let iframe = document.getElementById(`pollMcq-${sessionId}-${data?.engagement}`)
            iframe && iframe.contentWindow && iframe.contentWindow.postMessage(JSON.stringify(_data), "*")
            // console.log("_data", _data)
        })

        return () => {
            window.removeEventListener("message", () => { })
        }
    }, [])



    return <iframe
        id={`pollMcq-${sessionId}-${data?.engagement}`}
        src={`${IFRAME_URL}/pollMcq/${sessionId}`}
        // src={`https://pedgog-ideaboard.vercel.app/pollMcq/${sessionId}`}
        // src={`http://localhost:3003/pollMcq/${sessionId}`}
        frameborder="0" width="100%" height="100%" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"
    />
}

