import React, { useState,useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Config from "../../config"
export function saveTakeway(Formdata) {
  console.log('saveTakeway-->>', Formdata)
  if (Formdata) {
      fetch(Config.API_URL + 'session/takeaway', {
      //fetch('http://localhost:5001/v1/' + 'session/takeaway', {
      //  fetch('http://uat.pedgog.in:3000/v1/progress/createProgressPercentage', {
      //fetch('https://beta.pedgog.in:3000/v1/progress/createProgressPercentage', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        "accept": "application/json"
      },
      body: JSON.stringify(Formdata)
    })
      .then(response => response.json())
      .then(data1 => {
        console.log(data1);
      }
      )
  }
}
export default ({ width, height, engagementData, manageToolData, isFullScreen, data,sessionId }) => {
  const [name, setName] = useState("");
  const inputRef = useRef(null);

    const generatePDF=()=>{
      // // var doc= new jsPDF("p","pt","a4");
      // // doc.html(document.querySelector('#content'),{
      // //   callback: function(pdf){
      // //     pdf.save("component.pdf");
      // //   }
      // // })
      
      html2canvas(inputRef.current).then((canvas) => {
        // const imgData = canvas.toDataURL(logo);
        // const imgData1= canvas.toDataURL(stakeholders);
        // const imgData2= canvas.toDataURL(plus);
        const doc = new jsPDF();
        // doc.addImage(imgData, "png", 0, 0);
        // doc.addImage(imgData1, "png", 0, 0);
        // doc.addImage(imgData2, "svg", 0, 0);
        doc.save("two-by-four.pdf");
         var pdf = btoa(doc.output()); 
              console.log(pdf)
              var Formdata = new FormData();
              console.log(data.sessionType)
              console.log(sessionId)
              Formdata.append('engagementId', data.sessionType);
              Formdata.append('sessionId', sessionId)
              Formdata.append('data' , pdf);
        
              saveTakeway(Formdata);
        })}

  return (
    <form onSubmit={generatePDF} id="divToPrint" ref={inputRef}>
      <label>
        Frirst Name:
        <input
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </label>
      <input type="submit" value="Submit" />
    </form>
  );
}