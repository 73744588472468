import React, { useState, useEffect } from "react";
import {
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getModuleDetails, getRoadmapDetails, getLearningToolDetails } from "./../redux_actions";

import "./selfCertify.css";
import LeftNav from "../../../components/LeftNav";
import BackIcon from './assets/back.svg';
import starIcon from './assets/star.svg';
import Config from "../../../config"

const { innerHeight: WinHeight } = window;
// const { innerWidth: WinWidth, innerHeight: WinHeight } = window;

export function fnUpdateCertification(data) {
  if (data) {
    fetch(Config.API_URL + 'progress/UpdateCertification', {
      //fetch('https://beta.pedgog.in:3000/v1/progress/UpdateCertification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "bearer " + data.user_token,
        "accept": "application/json"
      },
      body: JSON.stringify({
        userId: data.user_uid,
        moduleId: data.moduleId
      })
    })
  }
}

let SelfCertify = (props) => {
  let [isComponentLoaded, updateComponentLoadStatus] = useState(false);
  let [beginTest, updateBeginTest] = useState(false);
  let { user_token } = props.auth;
  let { user_uid } = props.auth;

  let moduleId = props.match.params.moduleId;
  let moduleData = props.app.moduleDetails[moduleId];

  const [questionId, setquestionId] = React.useState(0);
  let [question, setQuestion] = useState(moduleData.SelfCertify ? moduleData.SelfCertify.questions[0] : "");
  const [value, setValue] = React.useState(-1);

  const handleChange = (event) => {
    setValue(event.target.value);
    // console.log(questionId + ' ==== ' + event.target.value);
    // let todos = [...this.state.todos];   //creating the copy

    // //adding new data
    // todos.push({
    //     id: questionId,
    //     text: event.target.value,
    //     completed: false
    // });

    // //updating the state value
    // this.setState({todos});
  };

  useEffect(() => {
    props.getModuleDetails({ id: moduleId, token: user_token })
      .then(res => {
        let a = res.payload && res.payload.data.organiser ? res.payload.data.organiser : { roadmaps: {} };
        Object.keys(a.roadmaps).forEach(rdId => {
          props.getRoadmapDetails({ id: rdId, token: user_token })
            .then(res => {
              Object.keys(res.payload.data.LearningTools).forEach(uId => {
                props.getLearningToolDetails({ id: uId, token: user_token })
              })
            })
        })
      });
    updateComponentLoadStatus(true)
  }, [isComponentLoaded]);

  function populateQuestions(el, questionId) {
    if (el) {
      return <div>
        <div className="row">
          <h3 className="col">{el.questionTitle}</h3>
          <div className="col" style={{ marginBottom: '1rem' }}>
            Select Any One
            </div>
          <FormControl component="fieldset">
            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
              {el.answer.map((ell, i) => {
                return <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <FormControlLabel value={'chk' + i + questionId} control={<Radio />} label={ell.answerText} />
                      {/* <FormControlLabel value={'chk'+i} control={<Radio />} label={ell.answerText} /> */}
                    </div>
                  </div>
                </div>
              })}
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    }
  }

  let slideHeight, slideWidth;
  slideHeight = WinHeight - 200 - 37.5;
  slideWidth = slideHeight * 16 / 9;

  return (
    <div className={"dashboard-cntr"}>
      <LeftNav />

      <div className="with-left-nav wrapper prepare-pg" style={{ padding: 0 }}>
        <div className="prepare-bg" style={{ padding: '7px', height: 'auto' }}>
          <p className="module-title" style={{ paddingTop: '0px' }}>
            <a href="javascript:history.back()"> <img src={BackIcon} alt="Illumine" /></a>
              Self Certification | {moduleData ? moduleData.title : ''}
          </p>
        </div>

        <div style={{ height: 'calc(100vh - 50px)' }}>
          {beginTest ? <div style={{ height: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            {questionId === moduleData.SelfCertify.questions.length ?
              fnUpdateCertification({ user_uid, moduleId, user_token })
              : null
            }

            {questionId === moduleData.SelfCertify.questions.length ?
              <div style={{ width: '100%', maxWidth: '700px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '2rem', background: '#fff' }}>
                <div><img src={starIcon} alt="Illumine" /></div>
                <h2 style={{ marginBottom: '1rem', color: '#ffbc36' }}> Congratulations</h2>
                <p style={{ marginBottom: '1rem' }}>You are all prepared & ready to conduct this module</p>
                <div style={{ display: 'flex', width: '100%' }}>

                  <Link style={{
                    backgroundColor: '#ffbc36',
                    borderRadius: 4, margin: '0px 10px', textAlign: 'center', textDecoration: 'none', color: '#292929', padding: '0.5rem',
                    cursor: 'pointer', width: '100%'
                  }} to={`/application/conduct/${moduleId}`} >Conduct this Module</Link>

                  <Link style={{
                    backgroundColor: '#fff',
                    borderRadius: 4, margin: '0px 10px', textAlign: 'center', textDecoration: 'none', color: 'red', padding: '0.5rem',
                    cursor: 'pointer', width: '100%', border: '1px solid red'
                  }} to={`/application/prepare/${moduleId}`}>See how you can Improve Engagement</Link>
                </div>
              </div>
              : <div>
                <div style={{ padding: '2rem 2rem 0.5rem 2rem;', display: 'flex' }}>
                  {populateQuestions(question, questionId)}
                </div>
                <div style={{
                  padding: '8px 0', backgroundColor: 'rgb(226, 226, 226)', display: 'flex',
                  justifyContent: 'space-between', alignItems: 'center',
                  maxWidth: '94%', margin: 'auto'
                }}>
                  {questionId < moduleData.SelfCertify.questions.length && questionId > 0 ?
                    <div onClick={() => { setquestionId(parseInt(questionId) - 1); setQuestion(moduleData.SelfCertify.questions[questionId - 1]); }} style={{
                      backgroundColor: '#ffbc36',
                      borderRadius: 4, margin: '0px 10px', padding: '0.3rem', width: 100, textAlign: 'center',
                      cursor: 'pointer'
                    }}>Previous</div>
                    : <div style={{ borderRadius: 4, margin: '0px 10px', lineHeight: '1.875rem', padding: '0.3rem', width: 100, textAlign: 'center' }}>
                    </div>}

                  {questionId < moduleData.SelfCertify.questions.length ?
                    <div>Question {questionId + 1} of {moduleData.SelfCertify.questions.length}</div>
                    : null}

                  {questionId < moduleData.SelfCertify.questions.length ?
                    <div onClick={() => { setquestionId(parseInt(questionId) + 1); setQuestion(moduleData.SelfCertify.questions[questionId + 1]); }} style={{
                      backgroundColor: '#ffbc36',
                      borderRadius: 4, margin: '0px 10px', padding: '0.3rem', width: 100, textAlign: 'center',
                      cursor: 'pointer'
                    }}>Next</div> : null}
                </div>
              </div>}
          </div>
            : <div style={{ height: 'calc(100vh - 100px)', overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <div style={{ width: '100%' }}>
                <div className='scrollable module-card-body' style={{
                  width: '100%',
                  maxWidth: '700px',
                  margin: '0 auto',
                  padding: '2rem'
                }}>

                  <h1 style={{ marginBottom: '1rem' }}>Take this quick self-check to see if you are ready to conduct the module</h1>

                  <div>{moduleData ? moduleData.SelfCertify.mainDetails : ''}</div>

                  <ul style={{ marginLeft: '1rem' }}>{moduleData.SelfCertify.mainPoints.map((el, i) => {
                    return <li style={{ marginTop: '1rem' }} key={`external-links-` + i}>{el}</li>
                  })}</ul>

                  <div onClick={() => { updateBeginTest(true) }} style={{
                    backgroundColor: '#ffbc36', height: 30,
                    borderRadius: 4, margin: '1rem 0px', lineHeight: '1.875rem', width: 100, textAlign: 'center',
                    cursor: 'pointer'
                  }}>Begin</div>
                </div>
              </div>
              <div style={{
                height: 30, backgroundColor: 'rgb(226, 226, 226)', display: 'flex',
                justifyContent: 'flex-end', alignItems: 'center', marginLeft: 60,
                maxWidth: `calc(${slideWidth}px + 450px)`
              }}>
              </div>
            </div>}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({ auth: state.auth, app: state.app });
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ getRoadmapDetails, getLearningToolDetails, getModuleDetails }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(SelfCertify);
