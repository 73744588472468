import React from 'react';
import Modal_Component from '../../../Tools/Modal_Component';
import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    const right = ['62.75%', '25.3%'];
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.popUp_content.map((item, i) => {
                        return <div key={i} className='SS_Case_Study-PopUp' style={{ right: right[i] }}>
                            <Modal_Component
                                type={item.type}
                                icon={item.icon}
                                src={item.src}
                                width={width}
                                height={height}
                            />
                        </div>
                    })
                }

            </div>
        </div>
    );
}