import React
// , { useState } 
from 'react';
import Zoom from '@material-ui/core/Zoom';
import Button from '@material-ui/core/Button';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import Modal_Component from '../../../Tools/Modal_Component';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let fontSize_1 = isFullScreen ? '14px' : '12px'

    const displayHint = (hint) => {
        manageToolData({ toolId: hint, content: engagementData[hint] ? false : true })
    }
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className='Engaging-deeply-think-yourself-textBox' style={{ top: '48%', right: '53%', fontSize: fontSize_1 }}>
                    <TextEditor id={data.textBoxId[0]} initialData={engagementData[data.textBoxId[0]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...'
                        scrollable='tct-scrollable-darkgray'
                        textEditorClass='UPTC_CS_Square-textEditorClass'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                    />
                </div>
                <Button variant="contained" color="primary" className='Engaging-deeply-think-yourself-finish_button_1' style={{ fontSize: fontSize_1 }}
                    onClick={() => displayHint(data.showHint[0])}>
                    Finish
                </Button>
                <Zoom in={engagementData[data.showHint[0]]}>
                    <div className='Engaging-deeply-think-yourself-Popup' style={{ left: '43%' }}>
                        <Modal_Component
                            type={data.popUp_content[0].type}
                            icon={data.popUp_content[0].icon}
                            src={data.popUp_content[0].src}
                            width={width}
                            height={height}
                        />
                    </div>
                </Zoom>
                <div className='Engaging-deeply-think-yourself-textBox' style={{ top: '48%', right: '11.5%', fontSize: fontSize_1 }}>
                    <TextEditor id={data.textBoxId[1]} initialData={engagementData[data.textBoxId[1]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...'
                        scrollable='tct-scrollable-darkgray'
                        textEditorClass='UPTC_CS_Square-textEditorClass'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                    />
                </div>

                <Button variant="contained" color="primary" className='Engaging-deeply-think-yourself-finish_button_2' style={{ fontSize: fontSize_1 }}
                    onClick={() => displayHint(data.showHint[1])}>
                    Finish
                </Button>
                <Zoom in={engagementData[data.showHint[1]]}>
                    <div className='Engaging-deeply-think-yourself-Popup' style={{ left: '84%' }}>
                        <Modal_Component
                            type={data.popUp_content[1].type}
                            icon={data.popUp_content[1].icon}
                            src={data.popUp_content[1].src}
                            width={width}
                            height={height}
                        />
                    </div>
                </Zoom>
            </div>
        </div >
    );
}