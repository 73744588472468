import React from 'react';
import { Input } from '@material-ui/core'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let descriptionSize = '11px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '14px';
    }
    const inputRight = ['63%', '26.5%'];
    const right = ['59.5%', '23%'];
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.inputId.map((id, i) => <Input className='WIAC_any_role_input'
                        key={id}
                        placeholder="Fill Here."
                        style={{ fontSize: descriptionSize, lineHeight: '12px', width: '21.5%', top: '34.5%', right: inputRight[i] }}
                        value={engagementData[data.inputId[i]] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: data.inputId[i], content: event.target.value })
                        }}
                    />)
                }
                {
                    data.textBoxId.map((id, i) => <textarea key={id} id={data.textBoxId[i]} placeholder='Fill here…'
                        className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ width: '33.5%', height: '39%', fontSize: descriptionSize, position: 'absolute', top: '44%', right: right[i] }}
                        value={engagementData[data.textBoxId[i]] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: data.textBoxId[i], content: event.currentTarget.value })
                        }}
                    />)
                }


            </div>
        </div>
    );
}