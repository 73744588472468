import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { IconButton } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import "./realWorld.css";
import ProgressBar from "../ProgressBar";

Chart.plugins.register(ChartDataLabels);

export default ({  
  engagementData,
  manageToolData,
  isFullScreen,
  data,
  sessionId,
  engagementStatus,
  typPC,
  // responses,
  currentEngagement,
  uniqueStudentsName,  
}) => {
  let { responseCount, names, studentJoinedList, response } = useSelector((state) => state.app)
  let _responseCount = responseCount[data.engagement] || {}
  let _responses = response[data.engagement] || {}
  let _names = (names && names[data.engagement]) || []
  let [show, setShow] = useState(false);

  let answers = [];
  let studentslabel = [];
  let labelObj = {};
  let newObj = {};

  let totalStudents = Object.values(studentJoinedList)?.length;
  let nextOpt = {}

  let engConfig = currentEngagement?.questions?.[0]
  let typeofEng = engConfig?.type;
  let options = Object.values(engConfig?.answers || {}).map((option, i) => {
    Object.assign(nextOpt, { [i]: option })
    return option?.option
  })

  if (typeofEng && _responseCount) {
    Object.values(_responseCount).map((el) => {
      Object.values(el).map((ts) => {
        if (typeofEng == "MultipleCorrect") {
          answers.push(ts);
        } else {
          Object.values(ts).map((fs) => {
            answers.push(fs);
          });
        }
      });
    });
    if (_names) {
      studentslabel = _names.map((el, i) => {
        if (answers[i])
          return Object.assign({}, { username: _names[i], ans: answers[i] });
      });
    }
  }
  //   console.log("studentslabel", studentslabel);

  uniqueStudentsName && uniqueStudentsName.map((el) => {
    newObj[el] = [];
    let ans = [];
    Object.values(studentslabel).map((ts, k) => {
      if (studentslabel[k].ans) {
        if (el == studentslabel[k].username) {
          ans.push(studentslabel[k].ans);
          // console.log("ans", ans);
        }
        newObj[el] = ans[ans.length - 1];
      }
    });
  });
  Object.values(nextOpt).map((ts, i) => {
    let labArr = [];
    if (ts.editable) {
      Object.keys(newObj).map((_k) => {
        // cons
        if (typeofEng == "MultipleCorrect" && newObj[_k]) {
          newObj[_k].map((_ans) => {
            if (!options.includes(_ans)) {
              labArr.push(_k);
            }
          });
        } else if (!options.includes(newObj[_k])) {
          labArr.push(_k);
        }
      });
      labelObj[ts.option] = labArr;
    } else {
      Object.keys(newObj).map((key, k) => {
        if (typeofEng == "MultipleCorrect" && newObj[key]) {
          newObj[key].map((ansk, q) => {
            if (ts.option == ansk) {
              labArr.push(key);
            }
          });
        } else if (ts.option == newObj[key]) {
          labArr.push(key);
        }
      });
      labelObj[ts.option] = labArr;
    }
  });
  // console.log("studentslabel", studentslabel)
  // console.log("labelObj", labelObj);
  const toolTipref = useRef("chartjs-tooltip");

  function Progress_bar(percentage) {
    const Parentdiv = {
      height: "100%",
      width: "100% ",
      backgroundColor: "#F5F5F5",
      // borderRadius: `${br}`,
    };

    const Childdiv = {
      height: "100%",
      width: `${percentage}%`,
      // width: "10%",
      backgroundColor: "#FFE39A",
      // borderRadius: 40,
      textAlign: "right",
    };

    return (
      <div style={Parentdiv}>
        <div style={Childdiv}></div>
      </div>
    );
  }

  let progessBarValue =
    (uniqueStudentsName?.length / totalStudents) * 100;

  const scenario = {
    [data?.engagement]: {
      "studentsLabel": studentslabel,
      "uniqueStu": uniqueStudentsName,
      "totalStu": studentJoinedList
    }
  }
  return (
    <div>
      {options && show && uniqueStudentsName && (
        <div
          className="horizontal-poll"
          style={{
            width: !isFullScreen ? "250px" : "50%",
            height: !isFullScreen ? "280px" : "max-content",
            // justifyContent: "center",
          }}
        >
          <IconButton
            style={{
              position: "absolute",
              top: "-15px",
              right: "10px",
              marginTop: "2%",
              width: "12%",
              padding: "0px !important",
            }}
            onClick={() => {
              setShow(false);
            }}
          >
            <CloseSharpIcon fontSize="small" />
          </IconButton>

          {options.map((op, i) => {
            let eachPBV;
            let barValue;
            if (typeofEng) {
              Object.keys(labelObj).map((keys, k) => {
                if (keys == op) {
                  barValue =
                    (labelObj[keys]?.length /
                      uniqueStudentsName?.length) *
                    100;
                }
              });
              barValue % 1 == 0 ? eachPBV = barValue : eachPBV = barValue?.toFixed(2);

            }

            return (
              <div className="ansContainer" id={op}>
                <div
                  className="inner-ansContainer"
                  id={op}
                  style={{
                    fontSize: !isFullScreen ? "0.8rem" : "1.2rem",
                  }}
                >
                  <div style={{ width: "80%" }}>{op}</div>
                  <div>{`${eachPBV || 0}%`}</div>
                </div>
                <div id={op}>
                  <a data-tip data-for={op}>
                    <div
                      id="op"
                      style={{
                        width: "100%",
                        height: !isFullScreen ? "12px" : "15px",
                        marginTop: "1%",
                      }}
                    >
                      {Progress_bar(eachPBV || 0)}
                    </div>
                  </a>

                  <ReactTooltip id={op} aria-haspopup="true" role="example">
                    <ul>
                      {labelObj[op] && labelObj[op].length > 0 ? (
                        labelObj[op].map((users) => {
                          return <li key={op}>{users}</li>;
                        })
                      ) : (
                        <li key={op}>No User</li>
                      )}
                    </ul>
                  </ReactTooltip>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <ProgressBar
        typPC={typPC}
        data={data}
        engagementData={engagementData}
        manageToolData={manageToolData}
        isFullScreen={isFullScreen}
        responses={_responses}
        currentEngagement={currentEngagement}
        uniqueStudentsName={uniqueStudentsName}
        sessionId={sessionId}
        progessBarValue={progessBarValue}
        setShow={setShow}
        labelObj={labelObj}
        // answers={newDipLabel}
        totalStudents={totalStudents}
        engagementStatus={engagementStatus}
      />
    </div>
  );
};
