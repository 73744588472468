import React, { useEffect, useState, useRef } from 'react'
import './style.css'
import HoverFruits from './hoverFruits';

export default ({ width, height, engagementData, manageToolData, isFullrceen, data }) => {

    const outerFruitDiv = useRef(null)
    const joyFruitDiv = useRef(null)
    const innerFruitDiv = useRef(null)
    const [flag, setFlag] = useState(false)

    useEffect(() => {
        if (outerFruitDiv.current) {
            setFlag(true)
        }
    }, [outerFruitDiv, joyFruitDiv, innerFruitDiv])

    return (
        <div style={{ width, height }} className='d-flex'>
            <div className='w-70 h-100'>
                <div className='tofv2-display-title-text'>{data.heading}</div>
                <div className='tofv2-right-container' style={{ height: '88%' }} >
                    <div className='w-100 d-flex  justify-content-space-between'>
                        <div style={{ marginLeft: '2%' }}>
                            <div className='tofv2-title-text' style={{ color: '#FFBC39' }}>
                                {data.title}
                            </div>

                        </div>
                        <img src={data.toolLogo} className='w-25 h-100' alt='tool-logo' />
                    </div>
                    <div className='w-100 h-90 d-flex' style={{ justifyContent: 'center' }}>
                        <div className='d-flex align-items-center w-25' style={{ position: 'relative' }}>
                            <img src={data.treeImgFull} className='w-100 h-80' alt='tree' />
                            <div ref={outerFruitDiv} className='tofv2-outer-fruit-hover'>
                                {flag && <HoverFruits fruitDiv={outerFruitDiv} fruitType='Outer_fruit' engagementData={engagementData} toolName={data.prevToolName} toolId={data.prevToolId} />}
                            </div>
                            <div ref={joyFruitDiv} className='tofv2-joy-fruit-hover'>
                                {flag && <HoverFruits fruitDiv={joyFruitDiv} fruitType='Joy_of_contribution' engagementData={engagementData} toolName={data.prevToolName} toolId={data.prevToolId} />}
                            </div>
                            <div ref={innerFruitDiv} className='tofv2-inner-fruit-hover'>
                                {flag && <HoverFruits fruitDiv={innerFruitDiv} fruitType='Inner_fruit' engagementData={engagementData} toolName={data.prevToolName} toolId={data.prevToolId} />}
                            </div>
                        </div>
                        {/* <div className='w-85' style={{ margin: '8px' }}>
                            <div className='tofv2-fruit-box tofv2-outer-box-color' style={{ position: 'relative' }}>
                                <div className='tofv2-outer-text-color' >
                                    <div className='tofv2-card-fruit-text soure-serif font-weight-bold'>
                                        Outer Fruit
                                    </div>
                                </div>
                                <div className='w-100 h-90'>
                                    <div className={`tct-scrollable w-100 h-100 tofv2-fruit-box-deck`}>
                                        {engagementData[data.prevToolName] && engagementData[data.prevToolName][data.prevToolId] && engagementData[data.prevToolName][data.prevToolId]['Outer_fruit'] && engagementData[data.prevToolName][data.prevToolId]['Outer_fruit'].map((item, index) =>
                                            <div className='tofv2-edit-text tofv2-outer-text-box-color'>
                                                {item}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='tofv2-fruit-box tofv2-joy-box-color' style={{ position: 'relative' }}>
                                <div className='tofv2-joy-text-color'>
                                    <div className='tofv2-card-fruit-text soure-serif font-weight-bold'>
                                        Joys of Contribution
                                    </div>

                                </div>
                                <div className='w-100 h-90'>
                                    <div className={`tct-scrollable w-100 h-100 tofv2-fruit-box-deck`}>
                                        {
                                            engagementData[data.prevToolName] && engagementData[data.prevToolName][data.prevToolId] && engagementData[data.prevToolName][data.prevToolId]['Joy_of_contribution'] && engagementData[data.prevToolName][data.prevToolId]['Joy_of_contribution'].map((item, index) =>
                                                <div className='tofv2-edit-text tofv2-joy-text-box-color'>
                                                    {item}
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className='tofv2-fruit-box tofv2-inner-box-color' style={{ position: 'relative' }}>
                                <div className='tofv2-inner-text-color'>
                                    <div className='tofv2-card-fruit-text soure-serif font-weight-bold'>
                                        Inner Fruits
                                    </div>
                                </div>
                                <div className='w-100 h-90'>
                                    <div className={`tct-scrollable w-100 h-100 tofv2-fruit-box-deck`}>
                                        {engagementData[data.prevToolName] && engagementData[data.prevToolName][data.prevToolId] && engagementData[data.prevToolName][data.prevToolId]['Inner_fruit'] && engagementData[data.prevToolName][data.prevToolId]['Inner_fruit'].map((item, index) =>
                                            <div className='tofv2-edit-text tofv2-inner-text-box-color'>
                                                {item}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div >
            </div >
            <div className='w-30'>
                <img src={data.discussionImg} className='w-100 h-100' alt='discuss-img' />
            </div>
        </div>
    )
}