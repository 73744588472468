import { Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../auth/redux_actions";
import { Redirect } from 'react-router';
import InputField from "./Input";
import store from "../../../Redux_Store";

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    height: "68px",
    borderRadius: "17px",
    "&:hover": {
      backgroundColor: '#e4001b !important',
      color: 'white',
    },
  },
}));

export default ({ auth, app, type }) => {
  const [redirect, setRedirect] = useState()
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (event, prop) => {
    setValues((oldValue) => ({
      ...oldValue,
      [prop]: event.target.value,
      error: undefined,
    }));
  };
  if (redirect)
    return <Redirect to={redirect} />
  else
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className='ir-login-welcome'>Welcome trainer/coach</div>
        <div>
          <InputField
            type="Text"
            field="email"
            values={values}
            handleChange={handleChange}
            placeHolder="Enter Email Address"
            error={values?.error}
          />
          <InputField
            type="Password"
            field="password"
            values={values}
            handleChange={handleChange}
            handleClickShowPassword={() => {
              setValues((oldValue) => ({
                ...oldValue,
                showPassword: !oldValue.showPassword,
              }));
            }}
            placeHolder="Enter Password"
            error={values?.error}
          />
          {values?.error && <div className="ir-error-msg">{values.error}</div>}

          <Button
            className="login-button-ap"
            onClick={() => {
              loginUser({ email: values.email, password: values.password })
                .then((response) => {
                  let res = response;
                  console.log("res", res)
                  console.log(res?.isAlreadyRegister);
                  if (res?.code)
                    store.dispatch({
                      type: "USER_VALUE_CHANGE",
                      payload: { key: "error", value: "Please check and re-enter OTP" },
                    });

                  else if (!res?.isAlreadyRegister) {
                    setRedirect("/auth/register");
                  }
                  else {
                    store.dispatch({ type: "REDIRECTING", payload: true })
                    if (process.env.NODE_ENV === 'development') {
                      window.location.href = `http://localhost:3000/auto-login/${res.tokens.coachingPlatform?.token}`;
                    }
                    else {
                      let url1 = res.user.defaultIntervention
                      store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'otpTimer', value: 0 } });
                      store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'otp', value: 0 } });
                      window.location.href = `http://${url1}/auto-login/${res.tokens.coachingPlatform?.token}`;
                    }
                    dispatch({ type: "LOGIN_SUCCEEDED", payload: response });
                  }
                })
                .catch((error) => {
                  setValues((oldValue) => ({ ...oldValue, error }));
                });
            }}
            classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}
            style={{ width: '100%', backgroundColor: '#FFBC36', marginTop: '5%', height: '50px' }}
          >
            Login
          </Button>
        </div>

      </div>
    );
};
