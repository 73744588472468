// import { auth } from 'firebase';
import React, { Component } from 'react';
import { connect } from "react-redux";
import MultiPageTemplate from '../../../components/acp/MultiPagePDFReader';

// const isBrowser = () => typeof window !== 'undefined' && window.document !== undefined;
const duringLoad = <div className='loading-screen'>Loading the document</div>

let Book = class Book extends Component {
  render() {
    let { unitId } = this.props.match.params;
    let { units } = this.props.app
    let { user_token, user_uid } = this.props.auth
    let unit = units[unitId];
    if (unit) {
      return serveTemplate({ src: unit.url, docRatio: unit.docRatio, unit, user_token, user_uid })
    }
    return <div>No Such Book exists</div>
  }
}

function serveTemplate({ src, docRatio, unit, user_token, user_uid }) {
  return <div id='_book_meta_cntr' className='_book_meta_cntr'>
    <MultiPageTemplate src={src} duringLoad={duringLoad} docRatio={docRatio} unit={unit} token={user_token} uid={user_uid} />
  </div>
}
const mapStateToProps = state => ({ app: state.app, auth: state.auth });
export default connect(mapStateToProps, null)(Book);