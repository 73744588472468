import React from 'react';
// import { useSelector } from 'react-redux';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId, responses, currentEngagement, uniqueStudentsName }) => {

    // const response = useSelector((state) => state.app.response)
    console.log(responses)
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: 'white', width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                Hello world
                {responses && responses['answer'] && responses['answer']['Deck'] && responses['answer']['Deck']['item'] && responses['answer']['Deck']['item'].map((el, index) =>
                <div key={el.id}>
                    {el.user} : {el.text}
                </div>
            )}
            </div>
        </div >
    );
}