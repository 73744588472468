import React, { useState } from "react"
import { Table, TableBody, TableCell, TableHead, TableRow, TextField, Checkbox, Tooltip } from "@material-ui/core"
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Save as SaveIcon
} from '@material-ui/icons';
import Icon from "@mdi/react";
import { mdiCellphoneCheck, mdiCellphoneRemove, mdiCheck } from "@mdi/js";
import CustomToolTip from './ToolTip'

export default ({ data, showAction, setData, tickField, downloadStatusField, hiddenField, showCheckBox, handleCheckbox, nonEditableField, editData }) => {
    const [edit, setEdit] = useState({ editData });
    const [values, setValues] = useState([...data]);

    const columns = Object.keys(data[0])

    const colorIcon = {
        fill: "#e79200",
        cursor: "pointer"
    }

    const cellStyle = {
        marginRight: "auto"
    }


    const onChange = (row, column, newValue) => {
        console.log(row, column, newValue)
        setValues(value => ({ ...value, [row]: { ...value[row], [column]: newValue } }))
    }

    const getValue = (index, columnIndex, value) => {

        if (downloadStatusField && downloadStatusField.includes(columns[columnIndex])) {
            if (value)
                return <Tooltip title={<CustomToolTip status='registered' />} classes={{ tooltip: 'batch-tooltip batch-tooltip-size_2' }} placement="bottom-start">
                    <Icon path={mdiCellphoneCheck} size={1} color="#3ACA0F" />
                </Tooltip>
            else
                return <Tooltip title={<CustomToolTip status='unregistered' />} classes={{ tooltip: 'batch-tooltip batch-tooltip-size_2' }} placement="bottom-start">
                    <Icon path={mdiCellphoneRemove} size={1} color="red" />
                </Tooltip>
        }

        else if (tickField && tickField.includes(columns[columnIndex]) && value == true) {
            return <Icon path={mdiCheck} size={1} color="#3ACA0F" />
        }

        else if (values[index] && values[index][columns[columnIndex]]) {
            return values[index][columns[columnIndex]]
        }
        else
            return value

    }

    return <Table style={{ margin: "auto", overflowX: "auto" }}>
        <TableHead style={{ backgroundColor: "#DBDBDB", color: "#898989" }}>

            <TableRow>
                {data[0] && columns.map(col => hiddenField && hiddenField.includes(col) ? null : <TableCell key={col} style={cellStyle}>
                    {col}
                </TableCell>)}
                {showAction ? <>
                    <TableCell>Edit</TableCell>
                    {/* <TableCell>Delete</TableCell> */}
                </> : null}
                {showCheckBox ? <>
                    <TableCell padding="checkbox" >

                    </TableCell>
                </> : null}

            </TableRow>
        </TableHead>
        <TableBody>
            {Object.values(data).map((stu, index) => <TableRow key={index} onClick={(event) => { if (showCheckBox) handleCheckbox(event, stu['userId']) }} >

                {Object.values(stu).map((col, columnIndex) => hiddenField && hiddenField.includes(columns[columnIndex]) ? null : < TableCell key={`${col}-${columnIndex}`} style={cellStyle}>

                    {edit[index] && !nonEditableField.includes(columns[columnIndex]) ? <TextField
                        id="standard-basic"
                        // label={columns[columnIndex]}
                        value={values?.[index]?.[columns?.[columnIndex]] || ''}
                        onChange={(event) => onChange(index, columns[columnIndex], event.target.value)}
                    /> : getValue(index, columnIndex, col)}
                </TableCell>)}
                {showAction ? <>
                    <TableCell>
                        {edit[index] ? <SaveIcon
                            style={colorIcon}
                            onClick={() => {
                                setData(index, "save", values)
                                setEdit({ ...edit, [index]: false })
                            }} /> : <EditIcon
                            style={colorIcon}

                            onClick={() => setEdit({ ...edit, [index]: true })} />}
                    </TableCell>
                    {/* <TableCell>
                        <DeleteIcon style={colorIcon} onClick={() => {
                            setData(index)
                        }} />
                    </TableCell> */}
                </> : null}
                {
                    showCheckBox && <TableCell padding="checkbox">
                        <Checkbox
                        // indeterminate={numSelected > 0 && numSelected < rowCount}
                        // checked={rowCount > 0 && numSelected === rowCount}
                        // onChange={onSelectAllClick}
                        />
                    </TableCell>
                }
            </TableRow>)}
        </TableBody>
    </Table >
}