import React,{useState} from 'react';
import {DragDropContext,Droppable,Draggable} from 'react-beautiful-dnd'
import Button from '@material-ui/core/Button';
import _ from "lodash"
import './style.css'
import {v4} from 'uuid'



function Dragdrop() {
  const [question,setQuestion]= useState(" ")
  const [text,setText]=useState('')
  const [dragApp, setDragApp]= useState({
    
    "options":{   
      title:"options",   
      item:[]
    },
    
  })

  const handleQuestion =(e)=>{
    setQuestion(e.target.value)
  }
  
  const handleChange =(e)=>{
    setText(e.target.value)
  }

  const addItem =(e)=>{
    if(text==="") {
      alert("Cannot add empty box")
      return}
    setDragApp(prev=>{
      
      return{
        ...prev,
        options:{
          title:"options",
          item:[{
            id:v4(),
            name:text
          },
            ...prev.options.item
          ]
           
        }
        
      }
      
    })
  
  }
    
  const handleSubmit =(e)=> {
    e.preventDefault();
    console.log(question)
    
// collect to the data base 
    // db.collection('Drag&Drop')
    // .add({
    //     question:question,
    //     option:dragApp
    // })
}
  
const handleDragEnd= ({destination, source}) => {

  console.log("from",source)
  console.log("to",destination)
  if (!destination){
   
    return
  }
  if (destination.index === source.index && destination.droppableId === source.droppableId){
    
    return
  }
  
  
  const itemCopy = {...dragApp[source.droppableId].item[source.index]}


  setDragApp(prev=>{

    prev={...prev}                                                
    
    
    prev[source.droppableId].item.splice(source.index,1)
    
    prev[destination.droppableId].item.splice(destination.index,0,itemCopy)
    return prev

  })
}

  return (
    <div className="mainbord">
      <input  className="input" type="text" value={question} onChange={handleQuestion}></input>
      <div className="textbg" >
      <input  className="input" type="text" value={text} onChange={handleChange}></input>
        <Button onClick={addItem}>
            Add option
          </Button> 

      </div>
      
    <div className="her">
        
      <DragDropContext onDragEnd={handleDragEnd}>
          
        {_.map(dragApp,(data,key) =>{

          return (

              <div key={key} className={"column"}>
                  <h3>{data.title}</h3>
            <Droppable droppableId={key}> 
                {(provided)=>{

                return(
                  <div
                      ref={provided.innerRef}                    
                      {...provided.droppableProps}
                      className={"droppable-column"}
                      >

                   {data.item.map((el ,index) =>{

                   return(



                        <Draggable key={el.id} index={index} draggableId={el.id}>
                          {(provided) => {

                            return(
                              <div
                              className={"ite"}
                              ref={provided.innerRef}
                              {...provided.draggableProps}             
                              {...provided.dragHandleProps} 
                              >
                                  {el.name}
                                                                
                                </div>
                              )
                            }}
                        </Draggable>

                      )
                    })}
                    {provided.placeholder}  
                                                   
                  </div>
              )
            }}
          </Droppable>
          </div>
        )
      })}
     </DragDropContext>
    </div> 
    <Button onClick={handleSubmit}>
            Submit
          </Button> 
    </div>
  );
}

export default Dragdrop