import React, { useEffect, useState } from "react";
import { createSession, getBatchForCoachAndModule, getOTP, storeSelectedBatch, getUser, getAllSessionsByBatch, getAsessmentAgainstBatch, createNewBatch } from "../../application/redux_actions"
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel, TextField, makeStyles } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import ComboBox from "./customCoTrainer";
import ConditonalFilter from "../../singleAuth/Auth/ConditonalFilter";

const useStyles = makeStyles((theme) => ({
 root: {},
 paper: {
  width: '45%',
  height: '96%',
  borderRadius: '24px',
  overflow: 'hidden'
 },
 textFieldClass: {
  fontSize: '14px',
 },
 buttonLabel: {
  fontFamily: 'tablet-gothic !important'
 },
 buttonRoot: {
  '&:hover': {
   backgroundColor: '#e4001b !important',
   color: 'white',
  }
 },
 select: {
  "& .Muipaper-root": {
   color: 'red'
  },
  "& ul": {
   width: '37vw !important',
  },
  "& li": {
   textOverflow: ' clip',
   overflow: 'hidden',
   whiteSpace: 'inherit'
  },
 },
 selectBorder: {
  fontSize: '1rem',
  fontWeight: 400,
  color: '#49454F',
  display: 'flex',
  alignItems: 'center',
  background: '#f1f1f1',
  overflow: 'hidden',
  whiteSpace: 'normal',
  '& .MuiOutlinedInput-notchedOutline': {
   borderColor: 'transparent !important',
  },
  "&:hover .MuiOutlinedInput-root": {
   borderColor: 'black'
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
   borderColor: 'black'
  }
 },
 inputEmpty: {
  fontSize: '1rem',
  fontWeight: 400,
  color: '#49454F',
  display: 'flex',
  alignItems: 'center',
  background: '#FFFFFF',
  overflow: 'hidden',
  whiteSpace: 'normal',
 },
 inputFilled: {
  fontSize: '1rem',
  fontWeight: 400,
  color: '#49454F',
  display: 'flex',
  alignItems: 'center',
  background: '#f1f1f1',
  overflow: 'hidden',
  whiteSpace: 'normal',
  '& .MuiOutlinedInput-notchedOutline': {
   borderColor: 'transparent !important',
  },
  "&:hover .MuiOutlinedInput-root": {
   borderColor: 'black'
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
   borderColor: 'black'
  }
 },
 selectFilled: {
  fontSize: '1rem',
  fontWeight: 400,
  color: '#49454F',
  display: 'flex',
  alignItems: 'center',
  background: '#f1f1f1',
  overflow: 'hidden',
  whiteSpace: 'normal',
 },
 newOutlinedSelect: {
  fontSize: '1rem',
  fontWeight: 400,
  color: '#49454F',
  display: 'flex',
  alignItems: 'center',
  background: '#f1f1f1',
  overflow: 'hidden',
  whiteSpace: 'normal',
 },
 outlinedSelectIcon: {
  width: '2em',
  height: "2em",
  top: 'unset'
 },
 batchInputRoot: {}
}));
const TextFieldEntry = ({ label, batchDetails, setBatchDetails }) => {
 const classes = useStyles();
 return <TextField
  InputLabelProps={{ classes: { root: classes.textFieldClass } }}
  key={label}
  fullWidth
  label={label}
  variant="outlined"
  InputProps={{ classes: { root: classes.batchInputRoot } }}
  value={batchDetails.newBatch}
  onChange={(event) => {
   setBatchDetails((old) => ({
    ...old,
    newBatch: event.target.value
   }))
  }}
 />
}

const BatchDropDown = ({ moduleDetails, label, batchTrainer, sessionForBatch, selectedBatch, setBatchDetails }) => {
 const classes = useStyles();
 let latestSessionForBatch = Object.values(sessionForBatch || {}).slice(-1)[0]
 let { moduleId, sessionDate, sessionName, sessionStartTime, updatedAt, createdAt } = latestSessionForBatch || latestSessionForBatch?.resume || {}
 let { meta } = batchTrainer?.[selectedBatch] || {}
 let description = moduleId?.description || moduleDetails?.[moduleId]?.description || ''
 let options = Object.values(batchTrainer || {}).map((batch) => batch?.batchName).reverse() || []
 let batchName = batchTrainer && selectedBatch && batchTrainer[selectedBatch] && batchTrainer[selectedBatch].batchName

 const handleChange = (event) => {
  Object.values(batchTrainer || {}).find(batch => {
   if (batch.batchName === event.target.value)
    setBatchDetails((old) => ({
     ...old,
     selectedBatch: batch._id
    }))
  })
 };

 return <>
  {batchName ?
   <>
    <FormControl variant="outlined" style={{}} fullWidth>
     <InputLabel id="ir-select-batch-label" >{label}</InputLabel>
     <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      label={label}
      value={batchName}
      displayEmpty
      classes={{ outlined: classes.newOutlinedSelect, }}
      onChange={handleChange}
     >
      {options.map(opt => <MenuItem
       style={{ fontSize: '1rem', fontWeight: 400, color: '#49454F' }}
       classes={{ root: 'ir-tisa-font' }} key={opt} value={opt}>{opt}</MenuItem>)
      }
     </Select>
    </FormControl>
    <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 8, padding: 8, fontSize: 14, background: '#F8F8F8', }}>
     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
      {description ?
       <>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
         <span style={{ color: '#545454' }}>Previous session for above batch</span>
         <span style={{ color: '#8A8A8A ' }}>{description}</span>
        </div>
        <div style={{ display: 'flex', width: '60%', gap: 10, justifyContent: 'space-evenly', color: '#8A8A8A ' }}>
         <span className='helperIcons'><DateRangeIcon />{moment(sessionDate).format('DD/MM/YYYY') || ''}</span>
         <span className='helperIcons'><ScheduleIcon />{moment(updatedAt).format('h:mma') || ''}</span>
        </div>

       </> :
       <CircularProgress style={{ position: "relative", left: '50%' }} classes={{ root: 'circular-progress-color ' }} size={20} />
      }
     </div>
     {description && meta && <div style={{ display: 'flex', flexDirection: "column", padding: '10px 0px', justifyContent: 'space-evenly', }}>
      <div style={{ display: "flex", justifyContent: 'space-between', color: '#545454' }}>
       <span > Batch Details</span>
      </div>
      <>
       {Object.keys(meta || {}).filter(key => key !== "programId").map((key, i) => {
        if (key == 'No of Participants')
         return <span key={`${meta}--${i + 1}`} style={{ color: '#8A8A8A' }} className='helperIcons'>{`Expected Total Participants: ${meta?.[key]} `}
         </span>
        else
         return <span key={`${meta}--${i + 1}`} style={{ color: '#8A8A8A' }} className='helperIcons'>{`${key || ''}: ${meta?.[key]} `}
         </span>
       })}
      </>
     </div>
     }
    </div >
   </>

   : null}


 </>
}

const CoTrainerDropDown = ({ batchDetails, setBatchDetails, red_coTrainersForBatch }) => {
 let options = []
 red_coTrainersForBatch.reduce((result, item, i) => {
  if (item?.id !== batchDetails.coachId)
   if (item?.meta?.delhi_police?.policestation !== 'Guest')
    options.push(result[item?.fullname] = item)
  return result;
 }, {});

 let handleChange = (value) => {
  setBatchDetails((old) => ({
   ...old,
   coTrainer: {
    ...old.coTrainer,
    id: value?.id === 1 || value?._id === 1 ? batchDetails?.coachId : value?.id,
    name: value?.fullname,
   }
  }))
 }
 return <>
  <ComboBox options={options} handleChange={handleChange} />
 </>
}

const MetaSelection = ({ field, batchMeta, setBatchMeta, batchDetails, batchTrainer }) => {
 const classes = useStyles();
 let { fieldType, values, selectedValue, metaKey, fieldName } = field || {}
 let selectedBatch = batchDetails?.selectedBatch
 let { meta } = batchTrainer?.[selectedBatch] || {}
 let handleChange = (e, metaKey) => {
  setBatchMeta((old) => ({ ...old, [fieldName]: e }))
 }

 if (fieldType == 'dropdown') {
  return (<FormControl key={metaKey} variant="outlined" fullWidth>
   <InputLabel id="ir-select-batch-label" style={{ fontSize: '14px' }}>{fieldName}*</InputLabel>
   <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    style={{ cursor: selectedValue ? "no-drop" : 'pointer' }}
    label={fieldName}
    MenuProps={{ classes: { paper: classes.select } }}
    className={batchMeta?.[fieldName] ? classes.selectBorder : ''}
    value={batchMeta?.[fieldName] || ''}
    displayEmpty
    onChange={(ev) => {
     handleChange(ev.target.value, metaKey)
     ev.stopPropagation();
     ev.preventDefault();
    }}
   >
    {values?.map(opt =>
     <MenuItem
      style={{ fontSize: '1rem', fontWeight: 400, color: '#49454F' }}
      // classes={{ root: 'menuStyle' }}
      key={opt} value={opt}>
      {opt}
     </MenuItem>
    )}
   </Select>
  </FormControl >)
 }
 if (fieldType == 'textfield') {
  return <TextField
   key={fieldName}
   style={{ cursor: selectedValue ? "no-drop" : 'pointer' }}
   fullWidth
   InputLabelProps={{ classes: { root: classes.textFieldClass } }}
   label={`${fieldName}*`}
   variant="outlined"
   InputProps={{
    classes: { root: (batchMeta?.[fieldName]) ? classes.inputFilled : classes.inputEmpty }
   }}
   value={batchMeta?.[fieldName] || ''}
   onChange={(ev) => {
    handleChange(ev.target.value, metaKey)
    ev.stopPropagation();
    ev.preventDefault();
   }}
  />
 }
 if (fieldType == 'conditional_filter') {
  return <ConditonalFilter
   batch={true}
   field={field}
   onChange={() => { }}
   forBatch={(key, value) => {
    setBatchMeta((old) => ({ ...old, [key]: value }))
   }}
  />
 }
 else return null
}

const FirstBatch = ({ createBatchForAllModules, batchDetails, setBatchDetails, batchTrainer, batchMeta, setBatchTrainer, setBatchMeta, fields }) => {
 return <div key={'newDiv112'} style={{
  display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between',
  gap: '1rem'
 }}>
  {createBatchForAllModules ?
   <>
    <div style={{ display: 'flex', width: "100%", gap: '1.5rem', justifyContent: 'space-between' }}>
     <TextFieldEntry
      label={'Batch Name'}
      key={'totalStudents'}
      batchDetails={batchDetails}
      setBatchDetails={setBatchDetails}
     />
     <MetaSelection
      field={{
       "fieldName": "No of Participants",
       "fieldType": "textfield",
       "metaKey": "totalStudents",
       "mandatory": true,
       "values": [
        ""
       ]
      }}
      key={'1'}
      batchDetails={batchDetails}
      batchTrainer={batchTrainer}
      batchMeta={batchMeta}
      setBatchTrainer={setBatchTrainer}
      setBatchMeta={setBatchMeta}
     />
    </div>
    {fields && fields.length > 0 && (fields || [])
     .map((field, i) => {
      if (!(field?.metaKey == 'male' || field?.metaKey == 'female'))
       return <MetaSelection
        key={i}
        field={field}
        batchDetails={batchDetails}
        batchTrainer={batchTrainer}
        batchMeta={batchMeta}
        setBatchTrainer={setBatchTrainer}
        setBatchMeta={setBatchMeta}
       />

     })}
    <div style={{ display: 'flex', width: "100%", gap: '1.5rem', justifyContent: 'space-between' }}>
     {fields && fields.length > 0 && (fields || [])
      .map((field, i) => {
       if (field.metaKey == 'male' || field.metaKey == 'female')
        return <MetaSelection
         key={i}
         field={field}
         batchDetails={batchDetails}
         batchTrainer={batchTrainer}
         batchMeta={batchMeta}
         setBatchTrainer={setBatchTrainer}
         setBatchMeta={setBatchMeta}
        />

      })}
    </div>

   </>
   :
   <>
    {fields && fields.length > 0 && (fields || [])
     .map((field, i) => {
      return <MetaSelection
       key={i}
       field={field}
       batchDetails={batchDetails}
       batchTrainer={batchTrainer}
       batchMeta={batchMeta}
       setBatchTrainer={setBatchTrainer}
       setBatchMeta={setBatchMeta}
      />

     })}
    <div style={{ display: 'flex', width: "100%", gap: '1.5rem', justifyContent: 'space-between' }}>
     <TextFieldEntry
      label={'Batch Name'}
      key={'totalStudents'}
      batchDetails={batchDetails}
      setBatchDetails={setBatchDetails}
     />
     <MetaSelection
      field={{
       "fieldName": "No of Participants",
       "fieldType": "textfield",
       "metaKey": "totalStudents",
       "mandatory": true,
       "values": [
        ""
       ]
      }}
      key={'1'}
      batchDetails={batchDetails}
      batchTrainer={batchTrainer}
      batchMeta={batchMeta}
      setBatchTrainer={setBatchTrainer}
      setBatchMeta={setBatchMeta}
     />
    </div>
   </>
  }
 </div >

}

const Sessions =
 ({ batchTrainer, app, sessionForBatch, moduleDetails, selectedBatch, setBatchDetails, }) =>
  Object.keys(batchTrainer || {}).length > 0 ? selectedBatch &&
   <BatchDropDown
    app={app}
    label={'Batch Name'}
    sessionForBatch={sessionForBatch}
    batchTrainer={batchTrainer}
    moduleDetails={moduleDetails || {}}
    selectedBatch={selectedBatch}
    setBatchDetails={setBatchDetails}
   /> :
   <div>Batch can be created only on Module 1.<br />Here, you can run a demo session.</div>


const SessionList = ({ grpModuleIndex, handleCloseInfo, app, moduleId, user_token, coach_id, moduleIndex }) => {
 let { user, moduleDetails, availableProgramsNew, sessionsForBatch } = app || {}
 let red_coTrainersForBatch = app.coTrainersForBatch || {}
 let sessionForBatch = sessionsForBatch
 let { programId, organizationid } = user || {}
 let program = availableProgramsNew[programId] || {}
 let batchD = useSelector(state => state.app.batchDetails)

 let d_date = moment().format('DD/MM/YYYY')

 // const [toConduct, setToConduct] = useState(true)
 const [batchTrainer, setBatchTrainer] = useState({})

 let [allCoTrainers, setAllCoTrainers] = useState()

 let [batchDetails, setBatchDetails] = useState({
  enable: false, newBatch: '',
  selectedBatch: null, coTrainer: '', coachId: coach_id, newBatchId: null
 })

 let [confirm, setConfirm] = useState({ open: false, finalCheckBox: false, runDemo: false })

 let { enable, newBatch, selectedBatch, coTrainer, coachId, newBatchId } = batchDetails

 let { coTrainerAccessCode } = program || {}

 let batchCreationFields = program?.batchCreationFields || {}
 let createBatchForAllModules = program?.batchCreationFields?.createBatchForAllModules || false
 let [batchMeta, setBatchMeta] = useState({ programId })

 let popUpHTml = '<ul><li>Ensure the session is conducted in <strong>full screen</strong> mode.</li><li>Ensure participants have <strong>installed the app</strong> and are ready.</li><li>Ensure <strong>all slides</strong> are covered in the session.</li><li>Ensure <strong>all videos</strong> have been played.</li><li>Ensure all participants <strong>complete the assessment</strong> after the session</li><li>Ensure all participants have given <strong>feedback/ testimonials.</strong></li></ul>'

 useEffect(() => {
  let a = async (batchId) => await getAllSessionsByBatch({ user_token, batchId })
  if (selectedBatch && Object.keys(sessionsForBatch || {}).length === 0) a(selectedBatch)
 }, [selectedBatch])

 useEffect(() => {
  function __set_batch(batch, coach_id, i) {
   setBatchTrainer((old) => ({
    ...old,
    [batch._id]: batch.coachId.includes(coach_id) ? batch : {},
   }))
   setBatchDetails((old) => ({ ...old, selectedBatch: batch._id }))
  }
  !Object.keys(batchTrainer || {}).length > 0 &&
   coach_id && user_token && getBatchForCoachAndModule({ user_token, coachId: coach_id, moduleId }).then((res) => {

    if (res.length > 0) {
     let ab = res.some(batch => batch?.meta?.programId === programId)
     res.forEach((batch, i) => {
      __set_batch(batch, coach_id, i)
     })
    }
   })

 }, [coach_id, user_token])

 useEffect(() => {

  let division = app?.user?.meta?.railways?.division
  let zone = app?.user?.meta?.railways?.zone
  if (user && coTrainerAccessCode && user_token && organizationid && Object.keys(red_coTrainersForBatch || {}).length == 0) {
   getUser(user_token, organizationid, { division, zone, coTrainerAccessCode }).then((res) => setAllCoTrainers(res))

  }
 }, [user_token, app, organizationid, coTrainerAccessCode])

 function checkBatchvalidation() {
  let fields = batchCreationFields?.fields || []
  let a = fields.length > 0 ?
   fields.some(el => el.fieldType == 'conditional_filter') ?
    fields[0]._schema.length + fields.length - 1
    : fields.length : false
  return Object.keys(batchMeta || {}).length - 2 == a
 }
 let createBatch = createBatchForAllModules || (moduleIndex === 0 || (moduleIndex === 0 && grpModuleIndex === 0))

 async function createSess(res) {
  let sessionTime = new Date()
  return !confirm.runDemo ?
   await createSession({
    user_token, moduleId, coach_id: coachId,
    facilitators: [...new Set([coachId, coTrainer.id])],
    sessionType: 'pre',
    sessionName: sessionTime,
    batchId: res ? res._id || newBatchId : selectedBatch,
    meta: selectedBatch ? batchTrainer?.[selectedBatch]?.meta : { ...batchMeta, programId } || {}
   }, () => { })
   : await createSession({
    user_token, moduleId, coach_id: coachId,
    facilitators: [...new Set([coachId, coTrainer.id])],
    sessionType: 'demo',
    sessionName: sessionTime,
    batchId: res ? res._id || newBatchId : selectedBatch,
    meta: batchMeta || {}
   }, () => { console.log('demo session created') })
 }

 return <>
  <div
   style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 2.5rem', fontSize: "20px", }}
   className='ap-conduct-dialog-close-session-button'>
   {enable || (selectedBatch && confirm.open) ?
    <div onClick={() => {
     if (enable && !confirm.open) {
      setBatchDetails((old) => ({
       ...old,
       enable: false,
       selectedBatch: batchD && batchD[batchD.length - 1]?._id
      }))
     }
     if (enable && confirm.open) {
      setConfirm((old) => ({
       ...old,
       open: false
      }))
     }
     if (selectedBatch && !enable) {
      setConfirm((old) => ({
       ...old,
       open: false
      }))
     }
    }}
     style={{ display: 'flex', alignItems: 'center', }}>
     <ArrowBackIosIcon style={{ fontSize: '1rem' }} />back
    </div> :
    <>
     {!app?.isLoading && <div className='titleCss'>
      {((moduleIndex == 0 && (grpModuleIndex || 0 === 0)) ||
       Object.keys(batchTrainer || {}).length > 0) ?
       confirm.open ? 'Conducting a Session' : createBatch ?
        'Creating a Batch' : 'Conducting a Session'
       : 'Run a Demo Session'}
     </div>}
    </>
   }
   {!app?.isLoading && < Icon onClick={handleCloseInfo} path={mdiClose}
    size={1}
    color='black'
   />}
  </div>
  {!confirm.open ?
   <div className="scrollDiv" style={{ height: "max-content", maxHeight: '70vh', overflowY: 'scroll' }}>
    {app.isLoading ?
     <div className='w-100 h-100 flex-center'>
      <CircularProgress classes={{ root: 'circular-progress-color my-1' }} size={50} />
     </div> :
     <div style={{
      overflowY: "auto", height: '100%',
      padding: '1em 1.5em 0rem ',
      display: "flex", flexDirection: "column", gap: '1rem'
     }}>
      {createBatch
       ? <FirstBatch
        createBatchForAllModules={createBatchForAllModules}
        batchDetails={batchDetails}
        setBatchDetails={setBatchDetails}
        batchTrainer={batchTrainer}
        batchMeta={batchMeta}
        setBatchTrainer={setBatchTrainer}
        selectedBatch={selectedBatch}
        setBatchMeta={setBatchMeta}
        fields={batchCreationFields?.fields}
       />
       : <Sessions
        batchTrainer={batchTrainer}
        app={app}
        label={'Batch Name'}
        sessionForBatch={sessionForBatch}
        moduleDetails={moduleDetails || {}}
        selectedBatch={selectedBatch}
        setBatchDetails={setBatchDetails}
       />
      }

      {((moduleIndex === 0 && (grpModuleIndex || 0 === 0)) || Object.keys(batchTrainer || {}).length > 0) && <CoTrainerDropDown
       label={"Co-Trainer's Name"}
       red_coTrainersForBatch={red_coTrainersForBatch}
       batchDetails={batchDetails}
       setBatchDetails={setBatchDetails}
       allCoTrainers={allCoTrainers}
      />}
     </div>}
    {!app.isLoading && <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '0em 1.5em 1rem' }}>
     <a data-tip data-for={"conduct1"}>
      <Button
       className='conduct-button-dialog'
       onClick={async () => {
        setConfirm((old) => ({ open: true, finalCheckBox: false }))
        selectedBatch ? await createSess() :
         await createNewBatch(user_token, {
          batchName: `${d_date}_${newBatch}`,
          coachId: [coachId, coTrainer.id],
          meta: batchMeta
         }).then(res => {
          if (res && res._id) {
           createSess(res)
           return;
          }
         })
       }}
       disabled={
        createBatch
         ? checkBatchvalidation() && coTrainer.id ? false : true
         : selectedBatch && coTrainer.id ? false : true
       }
       style={
        createBatch
         ? checkBatchvalidation() && coTrainer.id ?
          { cursor: 'pointer !important', } :
          { cursor: 'no-drop !important', }
         : selectedBatch && coTrainer.id ?
          { cursor: 'pointer !important', } :
          { cursor: 'no-drop !important', }
       }
      >
       Conduct
      </Button>
     </a>
     {createBatch
      ? checkBatchvalidation() && coTrainer.id ? false :
       <ReactTooltip id={"conduct1"} aria-haspopup="true" role="example">
        <span>Please fill the above details before conducting</span>
       </ReactTooltip>
      : selectedBatch && coTrainer.id ? false : <ReactTooltip id={"conduct1"} aria-haspopup="true" role="example">
       <span>Please fill the above details before conducting</span>
      </ReactTooltip>}
    </div>}
   </div > :

   <div className="scrollDiv" style={{ height: "max-content", maxHeight: '70vh', overflowY: 'scroll', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '1.5rem', padding: '10px 20px' }}>
    <div style={{
     color: '#BA1F00', fontWeight: 500, fontSize: '1rem',
    }}>BEFORE YOU START CONDUCTING A SESSION</div>
    <div
     dangerouslySetInnerHTML={{ __html: popUpHTml }}
     style={{ borderRadius: '8px', fontSize: '1.1rem', display: 'flex', flexDirection: 'column', backgroundColor: '#F8F8F8', padding: '8px 36px' }}>
    </div>
    <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
     <Link
      to={`/application/conduct/${moduleId}`}
      onClick={async (event) => {
       if (newBatch.length > 0) {
        await createNewBatch(user_token, {
         batchName: `${d_date}_${newBatch}`,
         coachId: [coachId, coTrainer.id],
         meta: batchMeta
        }).then(res => {
         if (res && res._id) {
          createSess(res)
          return;
         }
        })
       }
       if (selectedBatch) { createSess() }
       // event.stopPropagation();
       // event.preventDefault();
      }}
      style={{ cursor: 'pointer' }}
     >
      <Button style={{ padding: '10px 16px', borderRadius: '20px', border: '1px solid black' }}>Continue
      </Button>
     </Link>
    </div>
   </div>


  }
 </>

}
export default SessionList
//let figma = "https://www.figma.com/file/N6qMdSuf4zmlDosLRXmgCJ/Pedgog-new-designs?node-id=379-5059&t=SdvicVJlvDerhlm0-0"
