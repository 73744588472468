import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let textBoxId = data.textBoxId ? data.textBoxId : data.prevTextBoxId;

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className={data.textBoxId ? 'Trust-conduct-in-action-textbox' : 'Trust-conduct-in-action-prevtextbox'} style={{ fontSize: isFullScreen ? '18px' : '12px', lineHeight: isFullScreen ? '18px' : '12px' }}>
                    <TextEditor id={textBoxId} initialData={engagementData[textBoxId] || null}
                        manageToolData={manageToolData} placeholder='Fill here...'
                        scrollable='tct-scrollable-darkgray'
                        readOnly={data.prevTextBoxId ? true : false}
                        textEditorClass='UPTC_CS_Square-textEditorClass'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                    />
                </div>
            </div>
        </div >
    );
}