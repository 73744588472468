import { ThemeProvider } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getEngagement } from '../../containers/application/redux_actions'
import Start_N_Stop from '../../Live_Session_Tools/Start_N_Stop/index.js'
import IdeaBoard from './index.js'
import theme from './theme.ideaboard'

export default ({
 // sessionId,
 // toolId,
 sessionId,
 toolId,
 width,
 engagementData,
 manageToolData,
 height,
 data,
 isFullScreen,
 currentScene,
 currentUnit,
 app,
 item,
 auth,
 language,
}) => {
 useEffect(() => {
  getEngagement(user_token, data.engagement);
 }, [data, data.sessionType]);
 const user_token = useSelector((state) => state.auth.user_token);
 const engagementStatus = useSelector((state) => state.app.engagementStatus);
 const currentEngagement = useSelector((state) => state.app.currentEngagment);
 const uniqueStudentsName = useSelector((state) => state.app.uniqueStudentsName[data.engagement]);
 const userId = useSelector((state) => state.auth.user.id);

 return <ThemeProvider theme={theme}>
  <>
   {Object.keys(engagementStatus)?.includes(data.engagement) ?
    <IdeaBoard
     sessionId={app.createdSession._id || app.createdSession.id}
     toolId={item?.toolId}
     item={item}
     width={width}
     height={height}
     engagementData={app.engagementData}
     manageToolData={manageToolData}
     data={item}
     isFullScreen={isFullScreen}
     currentScene={currentScene}
     currentUnit={currentUnit}
     app={app}
     auth={auth}
     engagementStatus={engagementStatus}
     currentEngagement={currentEngagement}
     uniqueStudentsName={uniqueStudentsName}
     userId={userId}
     language={language}
    /> : <div style={{
     width: '100%', height: '100%', backgroundImage: `url(${typeof data?.bgImg == "object" ? data?.bgImg[language] || data?.bgImg["en"] : data?.bgImg})`,
     backgroundRepeat: "round", backgroundSize: "cover", position: 'absolute',
     display: 'flex', justifyContent: 'center',
    }}

    >
     <div style={{ position: 'relative', top: '15vh', height: 'max-content', right: 0, width: '20vw', }}>
      <span>Ready For IdeaBoard?</span>
      <Start_N_Stop

       ideaBoard={true}
       width={width}
       height={height}
       engagementData={engagementData}
       manageToolData={manageToolData}
       data={data}
       isFullScreen={isFullScreen}
       engagementStatus={engagementStatus}
       sessionId={sessionId}
       assignGroup={true}
       startText={"Accept Responses"}
       endText={"Stop Responses"}
      />
     </div>
    </div>}
  </>
 </ThemeProvider>
}