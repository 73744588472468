import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import { Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Number_3 from "../../../components/images/Tools-img/Number_3.png";
import broad_arrow from "../../../components/images/Tools-img/broad_arrow.png";
import arrow from "../../../components/images/Tools-img/arrow.png";

import './style.css';


const useStyles = makeStyles((theme) => ({
    disable: {
        '& .Mui-disabled': {
            color: 'black'
        },
    },
}));

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    const classes = useStyles();
    // let titleSize = '18px'
    let descriptionSize = '10.5px';
    // let questionSize = '12px';
    let headingSize = '16px';
    let questionHeight = '12px';
    if (isFullScreen) {
        // titleSize = '26px';
        headingSize = '24px'
        descriptionSize = '16px';
        // questionSize = '16px';
        questionHeight = '19px'
    }


    return (
        <div style={{ width, height }}>
            <div style={{ height: '10%', width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'center', padding: '0.5rem' }}>
                <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: headingSize, marginRight: '10px' }}>
                    {data.title}
                </div>
                <Input id={data.inputId + '_2'} style={{ width: '30%' }}
                    placeholder="Fill Here."
                    disabled
                    className={classes.disable}
                    value={engagementData[data.displayInputId] || ''}
                />
            </div>
            <div style={{ height: '90%', width: '100%', display: 'flex', background: 'linear-gradient(to right, #70bdd2 0%, #70bdd2 40%, #b1ce8e 40%, #b1ce8e 100%)', position: 'relative' }}>
                <img src={Number_3} style={{ position: 'absolute', width: '4.5%', top: '-5.5%', left: '2%' }} alt='number' />
                <div style={{ width: '41.5%', height: '100%', padding: '0% 1%', borderRight: '1px solid white ' }}>
                    <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: descriptionSize, padding: '0.5rem 0.5rem 0', height: '11%', display: 'flex', alignItems: 'center' }}>{data.leftHeading}</div>
                    <div style={{ display: "flex", flexWrap: 'wrap', height: '87.5%' }}>
                        {
                            data.displayTextBoxId.map((item, i) => <div key={i} style={{ width: '48.5%', height: '32.8%', margin: '0.3%', background: '#ffe69f', border: '3px solid white' }}>
                                <div style={{ height: '30%', lineHeight: questionHeight, padding: '10px' }}>
                                    <div style={{ fontSize: descriptionSize, fontWeight: 'bold', color: '#215968', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ color: 'black', fontSize: headingSize, fontWeight: 'normal', marginRight: '2%' }}>&#9634;</div>
                                        {data.values[i]}</div>
                                </div>
                                <div style={{ display: 'flex', width: '100%', height: '70%' }}>
                                    <TextEditor id={data.displayTextBoxId[i]} initialData={engagementData[data.displayTextBoxId[i]] || null}
                                        manageToolData={manageToolData} hideToolbar={true} placeholder='&#9679; Fill here...'
                                        readOnly={true}
                                        wrapperClass='text-editor-value-thinking-price'
                                        textEditorClass={isFullScreen ? 'value-thinking-selected-usergroup-textEditorClass-fullScreen' : 'value-thinking-selected-usergroup-textEditorClass'}
                                    />
                                </div>
                            </div>)
                        }
                    </div>
                </div>
                <div style={{ width: '60%', height: '100%', margin: '0% 1%', paddingLeft: '5%' }}>
                    <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: descriptionSize, padding: '0.5rem 0.5rem 0', height: '11%' }}>{data.rightHeading}</div>
                    <div style={{ display: 'flex', width: '100%', height: '87.5%' }}>
                        <div style={{ width: '45%', height: '100%' }}>
                            <div style={{ width: '100%', height: '100%' }}>
                                {
                                    data.featureTextBoxId.map((item, i) => <div key={i} style={{ height: '32.8%', margin: '0.3%', background: '#cfe1b9', border: '3px solid white', position: 'relative' }}>
                                        <img className={`value-thinking-selected-usergroup-arrow_${i + 1}`} src={arrow} alt='arrow' />
                                        <div style={{ height: '15%', lineHeight: questionHeight, padding: '10px' }}>
                                            <div style={{ fontSize: descriptionSize, fontWeight: '500' }}>
                                                {`${data.box_questions[0]} ${i + 1} :`}</div>
                                        </div>
                                        <div style={{ display: 'flex', width: '100%', height: '85%' }}>
                                            <TextEditor id={item} initialData={engagementData[item] || null}
                                                manageToolData={manageToolData} hideToolbar={true} placeholder='&#9679; Fill here...'
                                                wrapperClass='text-editor-value-thinking-price'
                                                textEditorClass={isFullScreen ? 'value-thinking-selected-usergroup-textEditorClass-fullScreen' : 'value-thinking-selected-usergroup-textEditorClass'}
                                            />
                                        </div>
                                    </div>)
                                }
                            </div>
                        </div>
                        <div style={{ width: '55%', height: '100%' }}>
                            <div style={{ width: '100%', height: '100%', paddingLeft: '20%' }}>
                                {
                                    data.valueTextBoxId.map((item, i) => <div key={i} style={{ height: '32.8%', margin: '0.3%', background: '#ffe69f', border: '3px solid white' }}>
                                        <div style={{ height: '15%', lineHeight: questionHeight, padding: '10px', position: 'relative' }}>
                                            <div style={{ fontSize: descriptionSize, fontWeight: '500' }}>
                                                {data.box_questions[1]}</div>
                                            <img src={broad_arrow} style={{ position: 'absolute', width: '27%', transform: 'rotate(-90deg)', left: '-29%' }} alt='broad_arrow' />
                                        </div>
                                        <div style={{ display: 'flex', width: '100%', height: '85%' }}>
                                            <TextEditor id={item} initialData={engagementData[item] || null}
                                                manageToolData={manageToolData} hideToolbar={true} placeholder='&#9679; Fill here...'
                                                wrapperClass='text-editor-value-thinking-price'
                                                textEditorClass={isFullScreen ? 'value-thinking-selected-usergroup-textEditorClass-fullScreen' : 'value-thinking-selected-usergroup-textEditorClass'}
                                            />
                                        </div>
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}