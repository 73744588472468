import React from "react";
import { useSelector } from "react-redux";
import { RATING_URL } from "../../../utilities/common";

export default function RatingIframe() {
    const auth = useSelector((state) => state.auth);
    const token = auth.user_token;
    let a = RATING_URL;
    // let a = 'http://localhost:3001';
    let src = `${a}/auto-login/${token}`;
    // let src = `${a}/auto-login/${token}/${auth.user_uid}`;
    return (
        <iframe
            id={"analytics-iframe-123"}
            src={src}
            frameborder="0"
            width="100%"
            height="100%"
            allowfullscreen="true"
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
        />
    );
}
