import React, { useState } from "react";
import { checkAccesscode, joinNewProgram, registerUser, verfiyExistingAccessCode } from "../../../containers/auth/redux_actions";
import { ReactComponent as Arrow } from '../assests/arrow_back_ios.svg'
import { One, Two, Three, CheckCircle } from '../assests/number.js'
import InputField from '../../../containers/singleAuth/Auth/Input.js'
import DropDown from '../../../containers/singleAuth/Auth/DropDown.js'
import SeachableDropDown from '../../../containers/singleAuth/Auth/SeachableDropDown.js'
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { Button, TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import InfoIcon from '@material-ui/icons/Info';
import store from "../../../Redux_Store";
import ConditonalFilter from "../../../containers/singleAuth/Auth/ConditonalFilter.js";
import { sendDataToBigQuery } from "../../../containers/application/redux_actions.js";

let AddNewProgram = ({ page, setAccessCodeData, accesscodeData, setPage, enableRegister, registerStatus, user, token }) => {
    const [error, setError] = useState(null);
    const [values, setValues] = useState({ phone: user.phone });
    let [showInfo, setShowInfo] = useState(false)
    let userId = user?.id || user?._id

    const handleChange = (event, field) => {
        setValues((oldValue) => ({ ...oldValue, [field]: event.target.value }));
    };

    const verifyAccessCode = () => {
        verfiyExistingAccessCode(values?.accesscode, userId)
            .then((data) => { setAccessCodeData(data) })
            .catch(err => { setError({ accesscode: err }) });
    }


    const handleFieldChange = (event, metaKey, value) => {
        setValues((oldValue) => ({
            ...oldValue,
            meta: {
                ...oldValue?.meta,
                [metaKey]: value,
            },
        }));
        enableRegister(true);
    };
    const getField = (field) => {
        if (registerStatus && field.mandatory && !values?.meta?.[field.metaKey]) {
            enableRegister(false);
        }
        switch (field.fieldType) {
            case "dropdown":
                return (
                    <DropDown
                        options={field.values}
                        label={field.fieldName}
                        // placeholder="Please Select"
                        value={values?.meta?.[field.metaKey] || ""}
                        onChange={(event) =>
                            handleFieldChange(event, field.metaKey, event.target.value)
                        }
                    />
                );
            case "searchable_dropdown":
                return (
                    <SeachableDropDown
                        options={field.values}
                        getOptionLabel={(option) => option}
                        onChange={(event, value) =>
                            handleFieldChange(event, field.metaKey, value)
                        }
                        label={field.fieldName}
                    />
                );
            case "conditional_filter":
                return (
                    <ConditonalFilter
                        enableRegister={enableRegister}
                        field={field}
                        onChange={(event, key) => {
                            handleFieldChange(event, event, key)
                        }}
                        forBatch={() => { }}
                    />
                );
            case "fixed":
                if (!values?.meta?.[field.metaKey])
                    handleFieldChange(undefined, field.metaKey, field.values[0]);
            default:
                return (
                    <InputField
                        type="Text"
                        field={field.metaKey}
                        values={
                            field.fieldType == "fixed"
                                ? { [field.metaKey]: field.values[0] }
                                : values?.meta
                        }
                        disabled={field.fieldType == "fixed"}
                        placeHolder={field.fieldName}
                        handleChange={(event) =>
                            handleFieldChange(event, field.metaKey, event.target.value)
                        }
                    />
                );
        }
    };

    console.log("values", values)


    return <>
        <div onClick={() => { accesscodeData?.accessCode ? setAccessCodeData(null) : setPage('default') }}
            style={{ cursor: 'pointer', fontSize: '1rem' }}>
            <ArrowBackIosOutlinedIcon
                style={{ marginRight: "1.5%", width: "0.7em", fontSize: '0.9rem', cursor: 'pointer', }} />Back
        </div>
        <div className='registerDiv' style={{
            padding: '0px'
        }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '3vh 6vw', borderBottom: ' 1px solid #DBDBDB ', fontSize: '0.8rem' }}>
                <div className='navNames' style={{}}
                >
                    {accesscodeData?.accessCode ? <CheckCircle /> : <One fill={'black'} />}
                    Get Access
                </div>
                <Arrow />
                <div className='navNames' style={{
                    color:
                        accesscodeData?.accessCode ? 'black' :
                            '#9B9B9B'
                }}>
                    <Three fill={accesscodeData?.accessCode ? 'black' : '#9B9B9B'} />
                    Enter Details
                </div >
            </div>
            {accesscodeData?.accessCode && <div style={{
                display: 'flex', flexDirection: 'column', padding: "2vh 3vw"
            }}>
                <div style={{ fontSize: "0.9rem", }}>Registering you for -</div>
                <div style={{ fontWeight: 500, fontSize: "1.5rem", }}>
                    {accesscodeData.title}
                </div>
                <div style={{ fontSize: "0.9rem", }}>
                    {accesscodeData.subTitle}
                </div>
            </div>}
            {!accesscodeData?.accessCode ?
                <div style={{ padding: '5vh 5vw' }}>
                    <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500, fontSize: '1.6rem', }}>Add your program access code <InfoIcon onClick={() => { setShowInfo(!showInfo) }} style={{ marginLeft: '1vw' }} />
                    </div>
                    {showInfo && <div style={{ width: '100%', fontSize: '0.9rem', color: '#49454F', fontWeight: 400, textAlign: 'left', margin: '10px 0px', backgroundColor: '#FFE4DF', borderRadius: '8px', padding: 8 }}>
                        Access code is generated based on what program is you going to join. Remember that the access code is different for different programs. Please make sure you enter the correct access code which you can ask for your Session coach.
                    </div>}
                    <div style={{ marginBottom: '20px' }}>
                        <TextField
                            helperText={error?.['accesscode'] ? error?.['accesscode'] : ''}
                            fullWidth
                            placeHolder="Access code"
                            type="Text"
                            field="accesscode"
                            variant="outlined"
                            required
                            values={values}
                            onChange={(ev) => handleChange(ev, 'accesscode')}
                            error={error?.['accesscode'] ? true : false}
                        />

                        <div style={{ fontSize: '0.75rem', fontWeight: 400, textAlign: 'right', width: "100%" }}>
                            Don't have an access code? Click HELP
                        </div>
                    </div>
                    <Button onClick={verifyAccessCode} style={{ width: 'max-content', border: '1px solid' }}>Verify Code</Button>
                </div>
                :
                <div className="scrollDiv" style={{
                    padding: '0vh 6vw 15px',
                    maxHeight: '60vh', overflow: 'auto',
                }}>
                    {accesscodeData.fields.map((field, index) => (
                        <div style={{}} key={index}>
                            {getField(field)}
                        </div>
                    ))}
                    <Button
                        className="login-button-ap"
                        disabled={!registerStatus}
                        style={{ backgroundColor: '#FFBC36', padding: '16px', width: '100%', borderRadius: '20px', margin: '10px 0px', }}
                        onClick={async () => {
                            if (values?.accesscode == 'CSCWORKSHOP' || values?.accesscode == 'cscworkshop') {
                                let body = {
                                    user: userId,
                                    key: null,
                                    designation: values?.meta?.designation,
                                    type: 'programJoined',
                                }
                                const res = await sendDataToBigQuery(body);
                                if (!res.success) {
                                    setError({ accesscode: res.message })
                                    setAccessCodeData(null)
                                    return;
                                }
                            }
                            joinNewProgram({
                                accesscode: accesscodeData?.accessCode,
                                userId,
                                fields: { [accesscodeData.meta_name]: values.meta }
                            }, token, accesscodeData?.landingPage)
                        }}

                    >
                        Register
                    </Button>
                    {/* <div style={{ fontSize: '1rem', color: "#ba1f00", textAlign: "center" }}>{error}</div> */}
                </div>
            }
        </div>
    </>
}

export default AddNewProgram;