import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';

import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let descriptionSize = '13px';

    let textbox_1_right = '59%';
    let textbox_1_top = '74.5%';

    if (isFullScreen) {
        descriptionSize = '16px';
        textbox_1_right = '60.5%';
        textbox_1_top = '73.5%';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className={`ESI_Slide_22_textBox work-sans`} style={{ fontSize: descriptionSize, top: textbox_1_top, right: textbox_1_right }}>
                    <TextEditor id={data.textBoxId[0]} initialData={engagementData[data.textBoxId[0]] || null}
                        manageToolData={manageToolData} hideToolbar={true}
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
            </div>
        </div>
    );
}