import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getPrepareModulesAttempt, getModuleDetails, getTagsAttempt, getUserAttempt, getRoadmapDetails, getLearningToolDetails, getOrganisationByParentId, getContinueConductModules, getMyDetail, getPrograms } from "../../containers/application/redux_actions";
import { getOrgNew, getOrganizationAttempt } from "../../containers/auth/redux_actions";
import CircularProgress from '@material-ui/core/CircularProgress';
import NoModuleAvailable from "../NoModuleAvailable";
import "./ExploreForms.css";
import IRModuleDashboard from "../../containers/IndianRailways/ModuleDashboard";
import { isBPUT, isIR } from "../../utilities/common";
import store from "../../Redux_Store";

class Explore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chk: 0, showSearchBar: false, searchText: '',
            childOpen: true,
            activeClass: "",
            flag: 0, chkflag: 0,
        };
    }

    async componentDidMount() {

        const { getPrepareModulesAttempt, getModuleDetails, getTagsAttempt, getUserAttempt, getOrganizationAttempt, getPrograms } = this.props;

        let userid = this.props.auth.user_uid;
        let token = this.props.auth.user_token;
        let orgid = this.props.auth.user.organizationid
        console.log("this.props.auth", this.props.auth.user)

        if (Object.keys(this.props.auth.orgDetails).length === 0) {
            await getOrgNew(orgid, token)
        }

        let parentOrgId = await this.props.auth.orgDetails.id

        if (this.props.auth.orgDetails && parentOrgId && Object.keys(this.props.auth.organisationList).length === 0) {
            await getOrganisationByParentId({ parentOrgId: parentOrgId, user_token: token })
        }

        // this.setState({ flag: this.props.auth.prepareFlag });
        // if (this.state.flag === "0" || this.state.flag === 0) {
        //   await getUserAttempt({ userid, token })
        // }
        // let _location = 'gtu.pedgog.in';
        // let _location = 'ap-coaching.pedgog.in';
        // let _location = 'bput.pedgog.in';
        // let _location = 'railways.pedgog.in';
        // let _location = 'delhipolice.pedgog.in';
        // let _location = 'preprodfacilitation.pedgog.in';
        // let _location = 'dakkarmayogi.pedgog.in';
        // let _location = 'coaching2.pedgog.in';
        // let _location = 'csc.pedgog.in';
        // let _location = 'swapnil';
        // let _location = 'facilitation.pedgog.in';
        // let _location = "zilla.pedgog.in"
        // let _location = "haryanakarmayogi.pedgog.in"

        let _location = window.location.host

        // let _location = "localhost:3000"
        await getTagsAttempt({ token, allowedHost: _location })
            .then(res2 => {
                getMyDetail(token, userid)
                    .then(Res =>
                        getPrograms({ token })
                            .then(res1 => {
                                this.setState({ chk: 1 });
                                getPrepareModulesAttempt({ userid, token })
                                    .then(result => {
                                        this.setState({ chk: 1 });
                                        let { availableModule } = this.props.app;
                                        let availableModules = availableModule;
                                        availableModules.forEach(module => {
                                            getModuleDetails({ id: module.moduleId, token })
                                        })
                                    })
                            })
                    )
            })
    }

    render() {
        let { availableModule, user, moduleDetails, availablePrograms, availableProgramsNew, availableTags } = this.props.app;
        let { programId } = user || {}
        let { redirecting } = this.props.auth;
        // let flg = "0";
        if (this.props.app.availableModule && programId) {
            if (this.state.chk === 0) {
                return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress classes={{ root: 'circular-progress-color' }} size={70} />
                </div>
            }
            else {
                let menuItems = [];
                function populateHeads() {
                    availableTags.map((program, j) => {
                        let ds = []
                        let programId = program.programId || program._id
                        programId && availableModule.map(module => {
                            let md = moduleDetails[module.moduleId]
                            if (md?.programs?.length > 0) {
                                md.programs.forEach(pId => {
                                    let tagchk = 0;
                                    if (pId === programId) {
                                        tagchk = 1;
                                    }
                                    if (tagchk === 1) {
                                        ds.push(md);
                                    }
                                })
                            }
                        })
                        menuItems.push({ dataSet: ds })
                    })

                    return null;
                }
                populateHeads();
                if (redirecting) store.dispatch({ type: "REDIRECTING", payload: false })
                return (
                    <div className='w-100 h-100'>
                        {(isIR || isBPUT) ?
                            <IRModuleDashboard
                                app={this.props.app}
                                auth={this.props.auth}
                                menuItems={menuItems}
                            />
                            :
                            null
                        }
                    </div>
                );
            }

        }
        else {
            return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress classes={{ root: 'circular-progress-color' }} size={70} />
            </div>
        }
    }
}
// {login_flag ? test : test1}
Explore.propTypes = {
    getPrepareModulesAttempt: PropTypes.func.isRequired,
    getModuleDetails: PropTypes.func.isRequired,
    getPrograms: PropTypes.func.isRequired,
    getTagsAttempt: PropTypes.func.isRequired,
    getUserAttempt: PropTypes.func.isRequired,
    authMsg: PropTypes.string
};

const mapStateToProps = state => ({
    auth: state.auth,
    app: state.app,
});

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getPrepareModulesAttempt,
        getModuleDetails,
        getPrograms,
        getTagsAttempt,
        getUserAttempt,
        getRoadmapDetails,
        getLearningToolDetails,
        getOrganizationAttempt,
        getContinueConductModules
    },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    matchDispatchToProps,
)(Explore);
