import React from "react";
import Start_N_Stop from "../../../Live_Session_Tools/Start_N_Stop";
// import Case_Response from "../../../Live_Session_Tools/Case_Response";
import Case_Response from "../../../Live_Session_Tools/Case_Response/indexCopy";
// import Radar_Chart from "../../../Live_Session_Tools/Radar_Chart";

export default ({
    width,
    height,
    engagementData,
    manageToolData,
    isFullScreen,
    data,
    engagementStatus,
    sessionId,
    typPC,
    responses,
    currentEngagement,
    uniqueStudentsName,
}) => {
    
    // let progessBarValue = (uniqueStudentsName?.length / totalStudents) * 100;
    return (
        <div
            style={{
                width,
                height,
                // backgroundImage: data.bgImg ?`url(${bgImg})`:`url(${bgSlide2})`,
                backgroundImage: data.bgImg
                    ? `url(${data?.bgImg?.en})`
                    : null,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                position: "relative",
                // border: "1px solid green",
            }}
        >
            <Case_Response
                typPC={typPC}
                data={data}
                engagementData={engagementData}
                manageToolData={manageToolData}
                isFullScreen={isFullScreen}
                // responses={responses}
                currentEngagement={currentEngagement}
                uniqueStudentsName={uniqueStudentsName}
                sessionId={sessionId}
                engagementStatus={engagementStatus}
            />
        </div>
    );
};
