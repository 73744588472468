import React, { useState } from 'react';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import Close from '@material-ui/icons/Close';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import './feedbacks.css';

const getColorClass = (skillLevel) => {
    switch (skillLevel) {
        case 1:
            return 'red';
        case 2:
            return 'yellow';
        case 3:
            return 'green';
        default: break
    }
};

const RenderImprovements = ({ improvements, data, skillCode }) => {
    const [collapsed, setCollapsed] = useState(true);

    const collapse = () => {
        setCollapsed(!collapsed)
    };

    const skillLevel = data[skillCode].score;

    const improvement = improvements.find(improvement => improvement.skillLevel === skillLevel);

    return (<div>
        <a className="how-to-improve" href="javascript::void(0)" onClick={collapse}>
            How to imporve
            <span className="icon">
                {collapsed ? (<ArrowDropDown />) : (<ArrowDropUp />)}
            </span>
        </a>
        {
            !collapsed ? (<div className="improvements">
                <span className="close">
                    <Close onClick={collapse} />
                </span>
                <div className="wrapper">
                    <span className={`best-practices ${getColorClass(improvement.skillLevel)}`}>
                        <span className="icons">
                            <span className="from"></span>
                            <span className="arrow"><ArrowRightAlt /></span>
                            <span className="to"></span>
                        </span>
                        <span className="text">Best Practices</span>
                    </span>
                    <ul className="suggestions">
                        {
                            improvement.steps.map((step, index) => (<li key={index}>{step}</li>))
                        }
                    </ul>
                </div>
            </div>) : null
        }
    </div>);
};

const Feedbacks = ({ feedbacks, data }) => {

    const renderFeedback = (feedback) => {
        const skillLevel = data[feedback.skillCode].score;

        return (<div className="feedback-wrapper" key={feedback.skillName}>
            <hr />
            <h4 className={`feedback-title ${getColorClass(skillLevel)}`}>{feedback.skillName}</h4>
            <div className="feedback-image-wrapper">
                <p className="grey">{feedback.description}</p>
                <img className="img img-fluid" src={feedback.images[skillLevel]} />
            </div>
            {
                feedback.improvements.length > 0 && skillLevel < 3 ? (<RenderImprovements improvements={feedback.improvements} skillCode={feedback.skillCode} data={data} />) : null
            }
        </div>);
    };

    return (<div className="container">
        <p>Interpreting my coaching audit report</p>
        {
            feedbacks.map(feedback => renderFeedback(feedback))
        }
    </div>);
};

export default Feedbacks;