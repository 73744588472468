import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LeftNav from "../../../components/LeftNav";
// import PrepareDashboard from "../../../components/App_PrepareDashboard";
import TabViewPrepare from '../TabView';


import "./channelDashboard.css";
import Dashboard_Header from "../../../components/Dashboard_Header";

class channelDashboard extends Component {
  render() {

    return (
      <div className={"dashboard-cntr"}>
        <LeftNav />
        <div className="with-left-nav wrapper prepare-dashboard">
          <Dashboard_Header
            heading='Prepare Zone'
            subheading='Which module do you want to prepare for?'
            description='Continuously improve the quality of the learning & coaching delivery experience of a module. Take up any module to prepare how to conduct, improve class engagement, deepen appreciation of the concepts & ideas.'
          />
          <TabViewPrepare
            tabData={[
              { label: "My Modules", component: "PrepareDashboard" },
              { label: "My Orientation & Development Units", component: "PrepareUnits" }
            ]}
          />
        </div>
      </div>
    );
  }
}
channelDashboard.propTypes = {
  authMsg: PropTypes.string
};

const mapStateToProps = state => ({ auth: state.auth });

export default connect(mapStateToProps, null)(channelDashboard);