import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import "./style.css";


import Close_Button from "../../../components/images/Tools-img/Close_Button.png";
let slideImg;
export default function TransitionsModal({ challenges, isFullScreen }) {
    const [open, setOpen] = React.useState(false);
    let textSize = '10px';
    if (isFullScreen) {
        textSize = '16px';
    }

    const handleOpen = (slide) => {
        setOpen(true);
        slideImg = slide;
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                {
                    challenges.map((ele, index) => {
                        return (
                            <div key={`img-${index}`} className='button-click-effect ESI_Slide_26_icon' onClick={() => handleOpen(ele.fullImg)}>
                                <div style={{ width: '100%', height: '90%' }}>
                                    <img src={ele.icon} style={{ width: '100%', height: '100%' }} alt='icon' />
                                </div>
                                <div style={{ width: '100%', height: '10%' }}>
                                    <p className='work-sans' style={{ fontSize: textSize }}>{ele.title}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <Modal
                className="modal"
                open={open}
                onClose={handleClose}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div style={{ border: 'none', outline: 'none' }}>
                        <div>
                            <img src={slideImg} style={{ width: '1000px' }} />
                            <img src={Close_Button} className="close-button" onClick={handleClose} />
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
