import React, { useState,  useRef } from "react";
import { useSelector } from "react-redux";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { IconButton } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import "./dynamic.css";
import ProgressBar from "../ProgressBar";
import _ from "lodash";

// import { easeLinear } from "d3";

Chart.plugins.register(ChartDataLabels);

export default ({  
  engagementData,
  manageToolData,
  isFullScreen,
  data,
  sessionId,
  engagementStatus,
  typPC,  
}) => {  
  const uniqueStudentsName = useSelector(
    (state) => state.app.uniqueStudentsName
  );
  const respo_count = useSelector(
    (state) => state.app.responseCount[data.engagement]
  );
  
  const studentJoinedList = useSelector((state) => state.app.studentJoinedList);
  const responses = useSelector((state) => state.app.response[data.engagement]);

  const currentEngagement = useSelector((state) => state.app.currentEngagment);

  const names = useSelector((state) => state.app.names?.[data.engagement]);
    
  const [show, setShow] = useState(false);

  const answers = [];
  const studentslabel = [];
  const options = [];
  let Obj = {};
  let labelObj = {};
  let newObj = {};
  const answers1 = respo_count;
  let totalStudents = Object.values(studentJoinedList)?.length;
  let typeofEng;
  const forOptions = currentEngagement.questions?.map((ts, i) => {
    typeofEng = ts.type
    Object.values(ts.answers).map((el) => {
      options.push(el.option);
    });
  });

  if (typeofEng) {
    if (answers1) {
      Object.values(answers1).map((el) => {
        Object.values(el).map((ts) => {
          if (typeofEng == "MultipleCorrect") {
            answers.push(ts);
          } else {
            Object.values(ts).map((fs) => {
              answers.push(fs);
            });
          }
        });
      });
    }
  }

  if (names) {
    names.map((el, i) => {
      if (answers[i]) {
        let Obj1 = Object.assign({}, { username: names[i], ans: answers[i] });
        studentslabel.push(Obj1);
        Obj[answers[i]] = [];
      }
    });
  }


  const newdata = uniqueStudentsName?.[data.engagement]?.map((el) => {
    newObj[el] = [];
    let ans = [];
    Object.values(studentslabel).map((ts, k) => {
      if (studentslabel[k].ans) {
        if (el == studentslabel[k].username) {
          ans.push(studentslabel[k].ans);

        }
        newObj[el] = ans[ans.length - 1];
      }
    });
  });
  // console.log("newObj", newObj);
  const Lab = options.map((opt, i) => {
    let labArr = [];
    Object.keys(newObj).map((keys, k) => {
      if (typeofEng == "MultipleCorrect" && newObj[keys]) {
        newObj[keys].map((ansk, q) => {
          if (opt == ansk) {
            labArr.push(keys);
          }
        });
      } else if (opt == newObj[keys]) {
        labArr.push(keys);
      }
    });
    labelObj[opt] = labArr;
  });

  let scene = {};
  let dash = Object.keys(labelObj).map((_k) => {
    Object.assign(scene, { [_k]: labelObj[_k].length });
  });

  const letk = _(scene).toPairs().orderBy([1], ["desc"]).fromPairs().value();

  let scenarioId = Object.keys(letk)
    .reverse()
    .splice(Object.keys(letk).length - data.showTop);
  const toolTipref = useRef("chartjs-tooltip");

  function Progress_bar(percentage) {
    const Parentdiv = {
      height: "100%",
      width: "100% ",
      backgroundColor: "#F5F5F5",
      // borderRadius: `${br}`,
    };

    const Childdiv = {
      height: "100%",
      width: `${percentage}%`,
      // width: "10%",
      backgroundColor: "#FFE39A",
      // borderRadius: 40,
      textAlign: "right",
    };

    return (
      <div style={Parentdiv}>
        <div style={Childdiv}></div>
      </div>
    );
  }

  let progessBarValue =
    (uniqueStudentsName?.[data.engagement]?.length / totalStudents) * 100;
  

  const scenario = {
    [data?.engagement]: {
      "responses": responses,
      "uniqueStu": uniqueStudentsName[data?.engagement],
      "totalStu": studentJoinedList
    }
  }

  return (
    <div>
      {options && show && uniqueStudentsName && (
        <div
          className="horizontal-poll"
          style={{
            width: !isFullScreen ? "250px" : "50%",
            height: !isFullScreen ? "280px" : "max-content",
            // justifyContent: "center",
          }}
        >
          <IconButton
            style={{
              position: "absolute",
              top: "-15px",
              right: "10px",
              marginTop: "2%",
              width: "12%",
              padding: "0px !important",
            }}
            onClick={() => {
              setShow(false);
            }}
          >
            <CloseSharpIcon fontSize="small" />
          </IconButton>

          {options.map((op, i) => {
            let eachPBV;
            let barValue;
            if (Obj && typeofEng) {
              Object.keys(labelObj).map((keys, k) => {
                if (keys == op) {
                  barValue =
                    (labelObj[keys].length /
                      uniqueStudentsName?.[data.engagement]?.length) *
                    100;
                }
              });
              barValue % 1 == 0 ? eachPBV = barValue : eachPBV = barValue.toFixed(2)
            }

            return (
              <div className="ansContainer" id={op}>
                <div
                  className="inner-ansContainer"
                  id={op}
                  style={{
                    fontSize: !isFullScreen ? "0.8rem" : "1.2rem",
                  }}
                >
                  <div style={{ width: "80%" }}>{op}</div>
                  <div>{`${eachPBV || 0}%`}</div>
                </div>
                <div id={op}>
                  <a data-tip data-for={op}>
                    <div
                      id="op"
                      style={{
                        width: "100%",
                        height: !isFullScreen ? "12px" : "15px",
                        marginTop: "1%",
                      }}
                    >
                      {Progress_bar(eachPBV || 0)}
                    </div>
                  </a>

                  <ReactTooltip id={op} aria-haspopup="true" role="example">
                    <ul>
                      {labelObj[op] && labelObj[op].length > 0 ? (
                        labelObj[op].map((users) => {
                          return <li key={op}>{users}</li>;
                        })
                      ) : (
                        <li key={op}>No User</li>
                      )}
                    </ul>
                  </ReactTooltip>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <ProgressBar
        typPC={typPC}
        data={data}
        scenarioId={scenarioId}
        scenario={scenario}
        engagementData={engagementData}
        manageToolData={manageToolData}
        isFullScreen={isFullScreen}
        responses={responses}
        currentEngagement={currentEngagement}
        uniqueStudentsName={uniqueStudentsName}
        sessionId={sessionId}
        progessBarValue={progessBarValue}
        setShow={setShow}
        // answers={newDipLabel}
        totalStudents={totalStudents}
        engagementStatus={engagementStatus}
      />
    </div>
  );
};
