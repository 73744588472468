import React, { useState } from 'react';
import * as d3 from 'd3';
import { useRef, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { getEngagement } from '../../containers/application/redux_actions'
import './style.css';

export default ({ width, height, sliderIndex, responses, currentEngagement }) => {

    const [dataSet, setDataSet] = useState();
    const sliderRef = useRef();
    const sliderHeadRef = useRef();
    useEffect(() => {
        // let dataSet = [
        //     { name: ['sagar 2', 'sagar 2'], value: '2' },
        //     { name: ['sagar 3', 'sagar 3', 'sagar 3'], value: '3' },
        //     { name: ['sagar 4', 'sagar 4', 'sagar 4', 'sagar 4'], value: '4' },
        //     { name: ['sagar 1', 'sagar 1', 'sagar 1', 'sagar 1', 'sagar 1', 'sagar 1', 'sagar 1', 'sagar 1'], label: 'Victim', value: '1' },
        //     { value: '5' },
        //     { name: ['sagar 5', 'sagar 5', 'sagar 5', 'sagar 5'], label: 'Contributor', value: '6' }
        // ];
        if (currentEngagement)
            setDataSet(currentEngagement && currentEngagement.Sliders && currentEngagement.Sliders[sliderIndex].marks.map(answer => {
                return { value: answer.value, name: (responses && responses[answer.value]) ? responses[answer.value].map(item => item.user) : [], label: answer.label }
            }))
    }, [responses, sliderIndex])


    if (dataSet) {
        // let maxRadius = 12;
        //Assuming the dataSet array is sorted by Value;
        // let maxValue = Math.max.apply(Math, dataSet.map(function (o) { return o.name ? o.name.length : 0 }));


        //maxValue is expected to hold hightest count of the name among all values
        // let dataSetWithROprops = dataSet.map((el, i) => {
        //     let x = 88 / (dataSet.length - 1);
        //     let nameLen = el.name ? el.name.length : 0
        //     let f = nameLen ? (nameLen / maxValue) + 0.2 : 0.2;
        //     let cx = i * x;
        //     // let cx = setCx === 0 ? 6 : setCx === 100 ? 94 : setCx
        //     // let cx = setCx
        //     // <circle class="some-class" fill="red" cx="0%-8px" cy="50%" r="18" style="opacity: 1; position: relative;"></circle>
        //     // return { ...el, opacity: f, r: nameLen === maxValue ? maxRadius : maxRadius / 2, cx: `${cx}%` };
        //     return { ...el, opacity: f, r: nameLen === maxValue ? maxRadius : maxRadius / 2, cx: `${cx}%` };
        // });

        // let line = d3.select(sliderRef.current)
        //     .append("div")
        //     .style("width", "100%")
        //     .style("height", "100%")
        //     .style('background-color', 'gray')
        // let circle = d3.select(sliderRef.current)
        //     .selectAll('circle.some-class') // added empty selection here
        //     .data(dataSet)
        //     .enter()
        //     .append('div')
        //     .classed('some-class', true)
        //     .style('width', '')
        //     .style('opacity', d => d.opactiy)
        var slider_tooltip = d3.select(sliderHeadRef.current).append("div")
            .attr("class", "slider-tooltip tct-scrollable-darkgray")
            .style("opacity", 0)
            .on("mouseover", function (event, d) {
                slider_tooltip.transition()
                    .style("opacity", .9);
            })
            .on("mouseout", function (d) {
                slider_tooltip.transition()
                    .duration(500)
                    .style("opacity", 0);
            });
        // let line = d3.select(sliderRef.current)
        //     .append("line")
        //     .style("stroke", "#FDC65BA3")
        //     .style("stroke-width", 3)
        //     .attr("x1", '0%')
        //     .attr("y1", '50%')
        //     .attr("x2", '88%')
        //     .attr("y2", '50%');
        // let circle_1 = d3.select(sliderRef.current)
        //     .selectAll('circle.some-class') // added empty selection here
        //     .data(dataSetWithROprops)
        //     .enter()
        //     .append('circle')
        //     .classed('some-class', true)
        //     .attr("fill", 'white')
        //     .attr("cx", d => d.cx)
        //     .attr("cy", '50%')
        //     .attr("r", d => d.r);
        // let circle_2 = d3.select(sliderRef.current)
        //     .selectAll('circle.some-class_2') // added empty selection here
        //     .data(dataSetWithROprops)
        //     .enter()
        //     .append('circle')
        //     .classed('some-class', true)
        //     .attr("fill", '#F16709')
        //     .attr("cx", d => d.cx)
        //     .attr("cy", '50%')
        //     .attr("r", d => d.r)
        //     .style('opacity', d => d.opacity)
        //     .style('position', 'relative')
        //     .on("mouseover", function (event, d, index) {

        //         if (d.name.length > 0) {
        //             slider_tooltip.transition()
        //                 .duration(200)
        //                 .style("opacity", .9);
        //             slider_tooltip.html(d.name.toString().replace(/,/g, '\n'))
        //                 .style("left", (event.layerX - 50) + "px")
        //                 .style("top", (event.layerY - 110) + "px");
        //         }
        //     })
        //     .on("mouseout", function (d) {
        //         slider_tooltip.transition()
        //             .duration(500)
        //             .style("opacity", 0);
        //     });

        // const circleLable = d3.select(sliderRef.current)
        //     .selectAll('circleLable.some-class_2') // added empty selection here
        //     .data(dataSetWithROprops)
        //     .enter()
        //     .append('text')
        //     .text(d => d.label)
        //     .attr('x', d => {
        //         return `${(parseFloat(d.cx) - 7)}%`
        //     })
        //     .attr('y', '90%')
        //     .attr('fill', 'black')
        //     .style('font-size', 12)

        // line.data(dataSet)
        //     .append("circle")
        //     .attr("cx", d => {
        //         console.log(d)
        //         return d.width
        //     })
        //     .attr("cy", d => d.height)
        //     .style("color", d => d.color)
        //     .style("opacity", d => d.opacity)
        //     .attr("r", 3)
    }
    return (
        <div ref={sliderHeadRef} style={{ width, height }}>
            <svg style={{ width: '100%', height: '100%', padding: '0 20px', margin: '0 20px', overflow: 'visible' }} ref={sliderRef} />
        </div>
    );
}
