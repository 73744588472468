import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';

import './style.css';
import arrow from './assets/arrow.png';
import pencil from './assets/pencil.png';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let titleSize = '20px'
    let descriptionSize = '12px';
    let headingSize = '16px';
    if (isFullScreen) {
        titleSize = '30px';
        headingSize = '24px'
        descriptionSize = '16px';
    }

    return (
        <div style={{ width, height }}>
            <div className='font-work-sans' style={{ height: '10%', width: '100%', padding: '0.5rem 1rem', fontWeight: 'bold', fontSize: titleSize, color: '#e64708' }}>
                {data.title}
            </div>
            <div style={{ height: '90%', width: '100%', display: 'flex' }}>
                <div className='scrollable' style={{ width: '25%', height: '90%', padding: '1rem', paddingTop: "0px", overflowY: 'scroll' }}>
                    <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: headingSize }}>{data.heading}</div>
                    {
                        data.case_description.map((item, i) => <div key={i} className='font-work-sans' style={{ margin: '4px 0px', fontSize: descriptionSize }}>{item}</div>)
                    }
                    {/* <img src='https://pedgog2.s3.ap-south-1.amazonaws.com/Becoming+future+ready+contributors/01-+Think+solutions/Illuminetoollogo.png' style={{ marginTop: isFullScreen ? '25%' : '7%', width: isFullScreen ? '35%' : '50%' }} alt='illumine-logo' /> */}
                </div>
                <img src='https://pedgog2.s3.ap-south-1.amazonaws.com/Becoming+future+ready+contributors/01-+Think+solutions/Illuminetoollogo.png' style={{ width: isFullScreen ? '12%' : '12%', position: 'absolute', bottom: '0' }} alt='illumine-logo' />
                <div className='scrollable think_sol_bg_img' style={{ width: '75%', height: '100%', position: 'relative' }}>
                    <img src={pencil} style={{ position: 'absolute', width: '11%', top: '-11%', left: '50%' }} alt='pencil' />
                    <div style={{ width: '100%', display: 'flex', height: '25%', justifyContent: 'space-evenly', marginTop: '5%', marginBottom: '10%' }}>
                        <div className='font-work-sans' style={{ width: '20%', fontWeight: 'bold', fontSize: descriptionSize, paddingLeft: '3%' }}>{data.box_questions[0]}</div>
                        <div style={{ width: '80%', display: 'flex', justifyContent: "center" }}>
                            <div style={{ width: '75%', display: 'flex' }}>
                                <TextEditor id={data.textBoxId[0]} initialData={engagementData[data.textBoxId[0]] || null}
                                    manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.'
                                    wrapperClass='text-editor-think-sol-case-1' />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', height: '35%', justifyContent: 'space-evenly' }}>
                        <div className='font-work-sans' style={{ width: '20%', fontWeight: 'bold', fontSize: descriptionSize, paddingLeft: '3%' }}>{data.box_questions[1]}</div>
                        <div style={{ width: '80%', display: 'flex' }}>
                            <div style={{ display: 'flex', marginRight: '1%', width: '32%', position: 'relative' }}>
                                <img src={arrow} className='think-sol-arrow_1' alt='arrow' />
                                <TextEditor id={data.textBoxId[1]} initialData={engagementData[data.textBoxId[1]] || null}
                                    manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.'
                                    wrapperClass='text-editor-think-sol-case-2' />
                            </div>
                            <div style={{ display: 'flex', marginRight: '1%', width: '33%', position: 'relative' }}>
                                <img src={arrow} className='think-sol-arrow_2' alt='arrow' />
                                <TextEditor id={data.textBoxId[2]} initialData={engagementData[data.textBoxId[2]] || null}
                                    manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.'
                                    wrapperClass='text-editor-think-sol-case-2' />
                            </div>
                            <div style={{ display: 'flex', marginRight: '1%', width: '32%', position: 'relative' }}>
                                <img src={arrow} className='think-sol-arrow_3' alt='arrow' />
                                <TextEditor id={data.textBoxId[3]} initialData={engagementData[data.textBoxId[3]] || null}
                                    manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.'
                                    wrapperClass='text-editor-think-sol-case-2' />
                            </div>
                        </div>
                    </div>
                    <div className='font-work-sans' style={{ width: '100%', height: '20%', fontWeight: 'bold', fontSize: descriptionSize, padding: '3%' }}>
                        {data.box_questions[2]}
                    </div>
                </div>
            </div>
        </div>
    );
}