import React from 'react';

// import Modal_Component from '../../../Tools/Modal_Component';
// import Play_Arrow_Red from '../../../components/images/Play_Arrow_Red.svg';

import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    const top = ['76%', '76%', '87.5%', '87.5%'];
    const right = ['72%', '53%', '70.5%', '51%', '29%', '9.8%', '27.5%', '8%'];
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {data.textBoxId.map((item, i) =>
                    <textarea key={i} id={item}
                        className={`SS_P02_Case_A-textbox transparent-textarea roboto tct-scrollable-darkgray`}
                        style={{ fontSize: !isFullScreen ? '12px' : '16px', top: top[i % top.length], right: right[i] }}
                        value={engagementData[item] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: data.textBoxId[i], content: event.currentTarget.value })
                        }}
                        placeholder='&#9679; Fill in…' />
                )}
            </div>
        </div>
    );
}