import { Avatar, Button, ClickAwayListener, Drawer } from "@material-ui/core"
import React, { useState } from "react";
import { useSelector } from "react-redux";
import './style.css'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import { ReactComponent as Switch } from './assests/move_down.svg'
import { ReactComponent as FootPrint } from './assests/footprint.svg'
import { ReactComponent as Logout } from './assests/logout.svg'
import { NavLink } from "react-router-dom";
import AddNewProgram from "./components/addNewProgram";
import SwitchIntervetion from "./components/switchIntervetion";
import AccountDetails from "./components/accountDetails";
import { checkAccesscode, editAccountDetails } from "../../containers/auth/redux_actions";
import { useEffect } from "react";
import store from "../../Redux_Store";
import Modal from "../walkThrough/modal";

let Profile = () => {
    let [page, setPage] = useState('default')
    let [open, setOpen] = useState(false)
    const [accesscodeData, setAccessCodeData] = useState();
    const [registerStatus, enableRegister] = useState(true);
    const [orgDetails, setOrgDetails] = useState({})

    let user_token = useSelector(state => state.auth?.user_token)
    let app = useSelector(state => state.app)
    let { user, walkThrough } = app
    let { currentEl, confirmExit, allElements, endWalkThrough, stop } = walkThrough || {}
    let { accessCodeList, fullname, organizationid } = user || {}

    let matches = fullname?.match(/\b(\w)/g);
    let acronym = matches?.join('');

    let allIntervention = ([...new Set(Object.values(orgDetails || {}).map(org => org?.organizationId))] || [])


    useEffect(() => {
        let getDetails = () => {
            accessCodeList.forEach(accessCode => {
                checkAccesscode(accessCode)
                    .then((data) => {
                        setOrgDetails(x => ({ ...x, [accessCode]: data }))
                    })
                    .catch(err => console.log(err))
            });
        }
        if (accessCodeList?.length > 1 && !Object.keys(orgDetails || {})?.length > 0) {
            getDetails()
        }
    }, [accessCodeList])

    let org11 = {}
    allIntervention.map(int => {
        Object.values(orgDetails || {}).map(acc => { if (int === acc.organizationId) Object.assign(org11, { [int]: acc }) })
    })


    let getPage = (page) => {
        switch (page) {
            case 'switch':
                return <SwitchIntervetion
                    switchDetails={org11}
                    setPage={setPage}
                    token={user_token}
                    userOrgId={organizationid}
                    userId={user?._id}
                />
            case 'add':
                return <AddNewProgram
                    setAccessCodeData={setAccessCodeData}
                    accesscodeData={accesscodeData}
                    setPage={setPage}
                    page={page}
                    token={user_token}
                    user={user}
                    enableRegister={enableRegister}
                    registerStatus={registerStatus}

                />
            case 'account':
                return <AccountDetails
                    page={page}
                    user={user}
                    setAccessCodeData={setAccessCodeData}
                    accesscodeData={accesscodeData}
                    setPage={setPage}
                    enableRegister={enableRegister}
                    registerStatus={registerStatus}
                />
            case 'default':
                return (<>
                    {allIntervention?.length > 0 &&
                        <div className='programDivs' onClick={() => setPage('switch')}>
                            <Switch />
                            <div className="innerDivs" >Switch Intervention</div>
                        </div>}
                    <div className='programDivs' onClick={() => setPage('add')}>
                        <AddCircleOutlineOutlinedIcon />
                        <div className="innerDivs">Add new program</div>
                    </div>

                    <div className='programDivs' onClick={() => setPage('account')}>
                        <AccountCircleOutlinedIcon fill='#797979' />
                        <div className="innerDivs">Account Details</div>
                    </div>
                    <NavLink to="/logout">
                        <div className='programDivs' onClick={() => {
                            setPage('logout')
                            store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'otp', value: 0 } });
                            store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'otpTimer', value: 0 } });
                        }}>
                            <Logout style={{ marginLeft: '5px' }} />
                            <div className="innerDivs">Logout</div>
                        </div>
                    </NavLink>
                    {/* <div className='programDivs' style={{ paddingBottom: 15 }} onClick={() => {
                        editAccountDetails(user._id, { webFirstTimeUser: true })
                        store.dispatch({ type: "UPDATE_CURRENT_EL", payload: { key: 'currentEl', value: 0 } })
                        store.dispatch({ type: "UPDATE_CURRENT_EL", payload: { key: 'endWalkThrough', value: false } })
                        store.dispatch({ type: "UPDATE_CURRENT_EL", payload: { key: 'stop', value: false } })
                        handleOpen()
                        // window.location.reload()
                    }}>
                        <FootPrint />
                        <div className="innerDivs">Walkthrough</div>
                    </div> */}

                    <div
                        style={{
                            borderTop: '1px solid #CFCFCF', padding: '10px 0px 0px 0px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: ' center',
                            width: '100%', textTransform: 'capitalize',
                        }}>
                        <ArrowRightOutlinedIcon />
                        <div className="innerDivs " style={{ display: 'flex', flexDirection: 'column', }}>
                            <span className="dottedWordWrap" style={{ fontSize: '1.375rem', fontWeight: 400 }}>{user?.fullname}</span>
                        </div>
                    </div>

                </>)
        }
    }

    const handleOpen = () => {
        setOpen(false)
    }

    function Comp() {
        return <>
            <Switch style={{ fill: '#797979' }} />
            <AddCircleOutlineOutlinedIcon style={{ fill: '#797979' }} />
            <AccountCircleOutlinedIcon style={{ fill: '#797979' }} />
            <Logout style={{ fill: '#797979' }} />
        </>
    }

    let allow = !stop && allElements[currentEl] == 'setting' || !stop && allElements[currentEl] == 'setting2'
    return <>
        <ClickAwayListener onClickAway={handleOpen}>
            <div
                style={{ padding: '10px', marginBottom: "10px", }}>
                {!open && !stop && allElements[currentEl] == 'setting' && <Modal id={'setting'} Comp={Comp} />}
                {!stop && allElements[currentEl] == 'setting2' && <Modal id={'setting2'} Comp={false} />}
                <Avatar
                    className={allow ? 'zIndexPos' : ''}
                    style={{ textTransform: 'uppercase' }}
                    aria-label="recipe" onClick={() => {
                        if (walkThrough && !stop && allElements[currentEl] == 'setting') {
                            store.dispatch({ type: "UPDATE_CURRENT_EL", payload: { key: 'currentEl', value: currentEl + 1 } })
                        }
                        setOpen(!open);
                        setPage('default')
                    }}>
                    {acronym || ''}
                </Avatar>
                <Drawer
                    open={open}
                    variant="persistent"
                    BackdropProps={{ style: { backgroundColor: 'transparent' } }}
                    classes={{
                        root: 'mainDialogRoot',
                        paper: page == 'add' && accesscodeData?.accessCode ? 'next-add' : page || 'mainDialog'
                    }}
                    ModalProps={{ keepMounted: true }}
                >
                    <div
                        className={allow ? 'onClickDisabled' : ''}
                        style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>{getPage(page || 'default')}</div>

                </Drawer>
            </div >
        </ClickAwayListener >
    </>
}

export default Profile;