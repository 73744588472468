import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import { Input } from '@material-ui/core'
import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    const top = ['27%', '68%'];
    const right = ['33.5%', '1.5%', '65.5%', '33.5%', '1.5%'];

    const inputTop = ['20%', '59.2%'];
    const inputRight = ['3.5%', '65.5%', '35.5%', '3.5%'];

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.inputId && data.inputId.map((item, i) =>
                        <Input className='WIAC_any_role_input'
                            key={item}
                            placeholder="Fill Here."
                            style={{ fontSize: isFullScreen ? '14px' : '12px', lineHeight: '12px', width:'16.5%', top: inputTop[Math.floor((i + 3) / data.inputId.length)], right: inputRight[i] }}
                            value={engagementData[data.inputId[i]] || ''}
                            onChange={(event) => {
                                manageToolData({ toolId: data.inputId[i], content: event.target.value })
                            }}
                        />
                    )
                }
                {
                    data.textBoxId.map((item, i) => <div className='UPTC_CS_Square-textbox' key={item} style={{ top: top[Math.floor((i + 3) / data.textBoxId.length)], right: right[i], fontSize: isFullScreen ? '14px' : '12px', lineHeight: '12px' }}>
                        <TextEditor id={data.textBoxId[i]} initialData={engagementData[data.textBoxId[i]] || null}
                            manageToolData={manageToolData} placeholder='Fill here...'
                            scrollable='tct-scrollable-darkgray'
                            textEditorClass='UPTC_CS_Square-textEditorClass'
                            wrapperClass='text-editor-WIAC_diff_in_the_lives'
                            toolBarClass='CD-CNP-textbox-toolbar'
                        />
                    </div>
                    )
                }
            </div>
        </div >
    );
}