import React from 'react';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, currentUnit }) => {
    const right = ['67.5%', '34%', '1%'];
    const top = ['67.5%', '34%', '1%'];
    let descriptionSize = '10px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '14px';
    }


    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((textBoxId, i) => <textarea key={textBoxId} id={textBoxId} placeholder='Fill more…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ fontSize: descriptionSize, position: 'absolute', width: '18.5%', top: top[i], right: right[i], height: '20%' }}
                        value={engagementData[data.toolName] ? engagementData[data.toolName][textBoxId] ? engagementData[data.toolName][textBoxId] : '' : ''}
                        onChange={(event) => manageToolData({
                            toolId: data.toolName, content: {
                                ...engagementData[data.toolName],
                                [textBoxId]: event.target.value
                            }
                        })}
                    />)
                }
            </div>
        </div >
    );
}