import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "lodash";
// import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { v4 } from 'uuid';
import Button from '@material-ui/core/Button';
import EdiText from 'react-editext';
import logo from '../Images/Group 3760.png'
import plus from '../Images/Group 3460.svg'
import Right_Bc from'../Images/Slide8.png'
import Start_N_Stop from '../Start_N_Stop'
import TextField from '@material-ui/core/TextField';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { studentResponseDelete } from '../../containers/application/redux_actions';
import SendIcon from '@material-ui/icons/Send';
import Menu from '@material-ui/core/Menu';
// import Pdf_Document from '../Pdf_Pages'
export default ({ width, height, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId }) => {

  const responses = useSelector(state => state.app.response[data.engagement]);
  // console.log(responses)
  const students = useSelector(state => state.app.uniqueStudentsName[data.engagement]);
  const respo_count = useSelector(state => state.app.responseCount[data.engagement]);
  console.log(respo_count)
  const [search, setSearch] = useState('')

  const [text, setText] = useState('')
  // const userId = useSelector(state => state.auth.user.id)
  // const otp = useSelector(state => state.app.otpData.otp)

  useEffect(() => {
    // Interaction.initalize(data.engagement, etah, "Coach", userId, "Student", otp)
  }, [data])
  useEffect(() => {

    let tempItem = responses ? Object.keys(responses).map(r => {
      return ({ id: v4(), text: r, user: responses[r].map((u) => u.user), tags: responses[r].map((u) => u.tags) })
    }) : []
    if (!engagementData || !engagementData[data.sessionType]) {
      manageToolData({
        toolId: data.sessionType, content: {
          First1: {
            title: "Ideal",   //key
            item: tempItem
          },
        
          Right: {
            title: "DragBox",
            item: []
          }
        }
      })
    }
    else {

      manageToolData({
        toolId: data.sessionType, content: {
          ...engagementData[data.sessionType],
          First1: {
            title: "Ideal",   //key
            item: [...engagementData[data.sessionType]['First1'].item, ...tempItem]
          },
        }
      })

      if (tempItem.length > 0) studentResponseDelete({ engId: data.engagement })
    }

    
  }, [responses])


  // adding Teacher Inputs or Ideas 

  const handleChange = (e) => {
    setText(e.target.value)
  }
  const addItem = (e) => {
    if (text === "") {
      alert("Cannot add empty box")
      return
    }

    manageToolData({
      toolId: data.sessionType, content: {
        ...engagementData[data.sessionType],
        First1: {
          title: "Ideal",
          item: [{
            id: v4(),
            text: text,
            user: ["teacher"],
            tags: []
          },
          ...engagementData[data.sessionType]['First1'].item || []
          ]
        }
      }
    })
   
    setText('')
  }

  
  const handleSave = (key, e, newValue) => {
   
    let index = engagementData[data.sessionType][key].item.findIndex((i) => i.id === e.id)
    if (engagementData[data.sessionType][key] && engagementData[data.sessionType][key].item) {
      manageToolData({
        toolId: data.sessionType,
        content: {
          ...engagementData[data.sessionType],
          [key]: {
            ...engagementData[data.sessionType][key],
            item: [
              ...engagementData[data.sessionType][key].item.slice(0, index),
              { ...engagementData[data.sessionType][key].item[index], text: newValue },
              ...engagementData[data.sessionType][key].item.slice(index + 1)
            ]
          }
        }
      })
    }
  }

  // Remove Ideas from list

  const handleRemove = (key, id) => {
   
    let items = engagementData[data.sessionType][key].item.filter((i) => i.id !== id)
    manageToolData({
      toolId: data.sessionType,
      content: {
        ...engagementData[data.sessionType],
        [key]: {
          ...engagementData[data.sessionType][key],
          item: items
        }
      }
    })
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // handling drag & drop 

  const handleDragEnd = ({ destination, source }) => {

    if (!destination) {
      return
    }
    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return
    }

    const itemCopy = { ...engagementData[data.sessionType][source.droppableId].item[source.index] }
    let temp = { ...engagementData[data.sessionType] }
    temp[source.droppableId].item.splice(source.index, 1)
    temp[destination.droppableId].item.splice(destination.index, 0, itemCopy)
    manageToolData({ toolId: data.sessionType, content: temp })

  }


  return (
    <div style={{ width, height, display: 'flex' }}>

      <DragDropContext onDragEnd={handleDragEnd}>
        <div style={{ width: '30%', height: '100%',border:'1px solid black'}}>
          <div style={{ width: '100%', height: '10%'}}>
            <img src={logo} className='w-100' style={{ height: '100%' }} />
          </div>
          <div style={{ display: "flex", width: '100%', height: '10%', marginTop: '1.5%', alignItems: 'center' }}>
       
          <h4 style={{ width: '94%',margin :'auto'  }}> Add members to work on Farmers speak first </h4>
          </div>
          <div style={{ width: '96%', height: '8%', display: 'flex', alignItems: 'center',marginLeft:'2%' }}>
            <TextField type="text"
              placeholder="Search..."
              variant="outlined"
            
              inputProps={{
                style: {
                  height:'30px',
                  padding: '0 14px',
                },
            }}
              className='searchbox'
              onChange={(e) => { setSearch(e.target.value) }} />
            {/* teacher text box     */}


          </div>
         
          <div className='tct-scrollable-darkgray' style={{ width: '100%', height: '63%', overflowY: 'auto' }}>
            {/* Here we need to put put first key */}


            <Droppable droppableId={'First1'}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className='w-100 h-100'
                  >
                    {
                      engagementData && engagementData[data.sessionType] && engagementData[data.sessionType]['First1'] && engagementData[data.sessionType]['First1'].item && engagementData[data.sessionType]['First1'].item.map((el, index) => {

                          return (

                            <Draggable key={el.id} index={index} draggableId={el.id}>

                              {(provided) => {

                                return (
                                  <div
                                    className={"item1"}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >

                                    <span className="ideas">
                                      {/* Editing Ideas  */}
                                      <div style={{ display: "flex" }}>
                                        <div className="user">{el.user}</div>
                                        <span className="delete">
                                          {/* Removing Ideas from list */}

                                          <DeleteForeverIcon
                                            fontSize="small"
                                            onClick={() => handleRemove('First1', el.id)}></DeleteForeverIcon>
                                        </span>
                                      </div>
                                      <div className="ed">
                                        <EdiText
                                          viewContainerClassName='my-custom-view-wrapper0'
                                          type='textarea'
                                          inputProps={{
                                            rows: 5
                                          }}
                                          hideIcons
                                          cancelOnEscape
                                          submitOnUnfocus
                                          editOnViewClick

                                          value={el.text}


                                          onSave={(newValue) => handleSave('First1', el, newValue)} ></EdiText>




                                      </div>
                                    </span>

                                  </div>

                                )
                              }}
                            </Draggable>
                          )
                        })}
                    {provided.placeholder}
                  </div>
                )
              }}
            </Droppable>

          </div>

          <div style={{ width: '100%', height: '8%', margin: 'auto', display: 'flex', boxShadow: 'rgb(0 0 0 / 16%) 0px -3px 6px' }}>
            {/* Here we need to put the start and stop button */}
            <div style={{ width: '15%', height: '90%' }}>
              <Button
                className='arrowbutton'
                size='small'
                aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <div className="plusimg">
                  <img src={plus} style={{ height: '60px', position: 'relative', top: '10px' }} />
                </div>
              </Button>
              <Menu
                className='popup'
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                  <TextField
                    style={{ width: '100%', marginLeft: '2%' }}
                    type="text"
                    placeholder="add..."
                    variant="outlined"
                    size="small"
                    value={text}
                    onChange={handleChange} />
                  <Button color="secondary" onClick={addItem} >
                    <SendIcon fontSize='small' style={{ marginTop: "2%" }} />
                  </Button>
                </div>
              </Menu>
            </div>
            <div className='s&s'>
              <Start_N_Stop
                engagementData={engagementData}
                manageToolData={manageToolData}
                data={data}
                isFullScreen={isFullScreen}
                engagementStatus={engagementStatus}
                sessionId={sessionId}
              />

            </div>
          
            <div className='sudentresp'>
              <div>{students && students.length ? students.length : 0}</div>
              <div
                style={{ fontSize: '60%' }}
             
              >Teams </div>
            </div>
            <div className='response'>
              {respo_count && respo_count.length ? respo_count.length : 0}
              <div style={{ fontSize: '60%' }}>Responses</div>
            </div>
          </div>

        </div>


        {/* End of Left Panel */}
        <div style={{backgroundImage:'url('+ Right_Bc + ')', padding: '2%', width: '70%', height: '100%',backgroundRepeat: 'no-repeat' }}>
          
            {_.map(engagementData[data.sessionType], (data, key) => {
              if (key === 'First1') return
              return (
                <Droppable key={key} droppableId={key}>
                  {(provided) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{height:'35%',width:'31.4%',position:'relative',top:'37.6%',left:'16.6%',border:'1px solid'}}
                      >

                        {
                          data && data.item && data.item
                            .map((el, index) => {
                              return (
                                <Draggable key={el.id} index={index} draggableId={el.id}>

                                  {(provided) => {

                                    return (
                                      <div
                                        className="itemR w-45 "
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <span className="ideasR">
                                          {/* Editing Ideas  */}
                                          {/* <div style={{ display: "flex" }}>
                                            <div className="user">{el.user}</div>
                                           
                                          </div> */}
                                           <checkbox>kishore</checkbox>
                                          <div className="edR">
                                            <span className="delete">


                                              <DeleteForeverIcon
                                                fontSize="small"
                                                onClick={() => handleRemove(key, el.id)}></DeleteForeverIcon>
                                            </span>
                                            <EdiText

                                              viewContainerClassName='my-custom-view-wrapper0 '
                                              type='textarea '
                                              inputProps={{
                                                rows: 5
                                              }}
                                              hideIcons
                                              cancelOnEscape
                                              submitOnUnfocus
                                              editOnViewClick
                                              value={el.text}


                                              onSave={(newValue) => handleSave(key, el, newValue)} ></EdiText>


                                          </div>
                                        </span>
                                       
                                      </div>

                                    )
                                  }}
                                </Draggable>


                              )
                            })}

                        {provided.placeholder}
                      </div>
                    )
                  }}
                </Droppable>
              )
            })}


        
        </div>
      </DragDropContext>
    </div>
  );
}