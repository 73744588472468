import React from 'react';
import { Input } from '@material-ui/core'
import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    const top = ['49%', '75.5%'];
    const right = ['27.5%', '2%'];

    const inputTop = ['44.5%', '71.5%'];
    const inputRight = ['27.5%', '2.3%'];

    const circleTexBoxTop = ['33.5%', '31.5%', '58%', '77%', '67%'];
    const circleTexBoxright = ['85.5%', '58.5%', '55.5%', '68.5%', '85%'];
    const isArray = data.inputId ? Array.isArray(data.inputId) : '';
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.inputId && (isArray) ? data.inputId.map((item, i) =>
                        <Input className='WIAC_any_role_input'
                            key={item}
                            placeholder="Fill Here."
                            style={{ fontSize: isFullScreen ? '14px' : '11px', lineHeight: '12px', width: '11.5%', top: inputTop[Math.floor(i / (data.inputId.length / 2))], right: inputRight[Math.floor(i % (data.inputId.length / 2))] }}
                            value={engagementData[data.inputId[i]] || ''}
                            onChange={(event) => {
                                manageToolData({ toolId: data.inputId[i], content: event.target.value })
                            }}
                        />
                    ) : isArray !== '' && <Input className='WIAC_any_role_input'
                        placeholder="Fill Here."
                        style={{ fontSize: isFullScreen ? '14px' : '11px', lineHeight: '12px', width: '11.5%', top: '71.5%', right: '2.5%' }}
                        value={engagementData[data.inputId] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: data.inputId, content: event.target.value })
                        }}
                    />
                }
                {
                    data.singleTextBoxId && <textarea id={data.singleTextBoxId}
                        className='UPTC-Zone-texbox transparent-textarea roboto tct-scrollable-darkgray'
                        style={{ top: '22.2%', right: right[1], fontSize: isFullScreen ? '14px' : '11px' }}
                        value={engagementData[data.singleTextBoxId] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: data.singleTextBoxId, content: event.currentTarget.value })
                        }}
                        placeholder='Fill here...'
                    />
                }
                {
                    data.textBoxId.map((item, i) => {
                        if (item !== 'empty')
                            return <textarea id={data.textBoxId[i]}
                                key={item}
                                className='UPTC-Zone-texbox transparent-textarea roboto tct-scrollable-darkgray'
                                style={{ top: top[Math.floor(i / (data.textBoxId.length / 2))], right: right[Math.floor(i % (data.textBoxId.length / 2))], fontSize: isFullScreen ? '14px' : '11px' }}
                                value={engagementData[data.textBoxId[i]] || ''}
                                onChange={(event) => {
                                    manageToolData({ toolId: data.textBoxId[i], content: event.currentTarget.value })
                                }}
                                placeholder='Fill here...'
                            />
                        return null;
                    })
                }
                {
                    data.circleTextBoxId && data.circleTextBoxId.map((item, i) => {
                        if (item === 'empty') return null;
                        return <textarea id={engagementData[data.circleTextBoxId[i]]} key={item}
                            className={`UPTC-Case-Study-Circle-textbox roboto tct-scrollable-darkgray`}
                            style={{ fontSize: !isFullScreen ? '12px' : '16px', top: circleTexBoxTop[i], right: circleTexBoxright[i], }}
                            value={engagementData[data.circleTextBoxId[i]] || ''}
                            onChange={(event) => {
                                manageToolData({ toolId: data.circleTextBoxId[i], content: event.currentTarget.value })
                            }}
                            placeholder='&#9679; Fill in…' />
                    })
                }
            </div>
        </div >
    );
}