import { Button, Dialog } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import styles from './NavigationAlertPopup.module.css'
const useStyles_2 = makeStyles((theme) => ({
    paper: {
        width: '40%',
        padding: '2em',
        borderRadius: '24px'
    }
}));


export const NavigationAlertPopup = ({ alertConfig, language = "en", open, onClose, parentId }) => {
    const alertTitle = alertConfig?.alertTitle?.[language] || alertConfig?.alertTitle?.["en"] || "You are leaving the Engagement!";
    const alertDescription = alertConfig?.alertDescription?.[language] || alertConfig?.alertDescription?.["en"] || "Do you want to proceed?";
    const yesActionBtn = alertConfig?.yesActionBtn?.[language] || alertConfig?.yesActionBtn?.["en"] || "Yes, I've completed";
    const noActionBtn = alertConfig?.noActionBtn?.[language] || alertConfig?.noActionBtn?.["en"] || "No, Take me back";

    return <Dialog
        open={open}
        keepMounted
        classes={{
            paper: useStyles_2().paper
        }}
        className='ir-tisa-font'
        disableEscapeKeyDown
        container={() => document.getElementById(parentId)}
    >
        <div style={{ fontWeight: 'bold' }}>
            {alertTitle}
        </div>
        <div style={{ margin: '1em 0', fontSize: '0.8em' }}>
            {alertDescription}
        </div>
        <div className='d-flex align-items-center justify-content-space-between' style={{ marginTop: '1em', gap: '10px' }}>
            <Button className={styles['no-action-btn']} onClick={() => onClose('no')} autoFocus>
                {noActionBtn}
            </Button>
            <Button className={styles['yes-action-btn']} onClick={() => onClose('yes')}>
                {yesActionBtn}
            </Button>
        </div>
    </Dialog>
}