import React from 'react';
import Modal_Component from '../../../Tools/Modal_Component';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let descriptionSize = '11px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '14px';
    }

    const right = ['43%', '25%', '6%'];

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <textarea id={data.prevFilledTextBoxId_Step_1} placeholder='Fill here…'
                    className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ width: '52%', height: '15%', fontSize: descriptionSize, position: 'absolute', top: '12%', right: '5%' }}
                    value={engagementData[data.prevFilledTextBoxId_Step_1] || ''}
                    readOnly
                />
                {data.caseTextBoxId && <textarea id={data.caseTextBoxId} placeholder='Fill here…'
                    className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ width: '27%', height: '26%', fontSize: descriptionSize, position: 'absolute', top: '18%', right: '66%' }}
                    value={engagementData[data.caseTextBoxId] || ''}
                    readOnly
                />}
                {
                    data.prevFilledTextBoxId_Step_2.map((id, i) => <textarea key={id} id={data.prevFilledTextBoxId_Step_2[i]} placeholder='Fill here…'
                        className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ width: '14%', height: '22%', fontSize: descriptionSize, position: 'absolute', top: '40%', right: right[i] }}
                        value={engagementData[data.prevFilledTextBoxId_Step_2[i]] || ''}
                        readOnly
                    />)
                }
                <textarea id={data.textBoxId} placeholder='Fill here…'
                    className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ width: '52%', height: '18%', fontSize: descriptionSize, position: 'absolute', top: '76%', right: '5%' }}
                    value={engagementData[data.textBoxId] || ''}
                    onChange={(event) => {
                        manageToolData({ toolId: data.textBoxId, content: event.currentTarget.value })
                    }}
                />
                {
                    data.popUp_content && <div className='ThinkSol-step_2-popup'>
                        <Modal_Component
                            type={data.popUp_content.type}
                            icon={data.popUp_content.icon}
                            src={data.popUp_content.src}
                            width={'unset'}
                            height={height}
                        />
                    </div>
                }

            </div>
        </div>
    );
}