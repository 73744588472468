import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import TextField from '@material-ui/core/TextField';

import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {


    let textSize = '8px'
    let textBoxtextSize = '12px'
    if (isFullScreen) {
        textSize = '12px';
        textBoxtextSize = '14px'
    }

    const handleChange = (event, textBoxId) => {
        manageToolData({
            toolId: data.id,
            content: {
                ...engagementData[data.id],
                [textBoxId]: event.target.value
            }
        })
    };


    return (
        <div style={{ width, height }}>
            < div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <TextField
                    placeholder="Fill here"
                    multiline
                    className='sma-thinking-strategy-fillin-think-textbox'
                    maxRows={2}
                    inputProps={{
                        className: 'tct-scrollable-darkgray',
                        style: { fontSize: textBoxtextSize, lineHeight: textBoxtextSize }
                    }}
                    value={engagementData[data.id] ? engagementData[data.id][data.textBoxId[0]] : null}
                    onChange={(event) => { handleChange(event, data.textBoxId[0]) }}
                />
                <div key={data.textBoxId[1]} className='sma-thinking-strategy-fillin-sol-textbox ' style={{ fontSize: textBoxtextSize, lineHeight: textBoxtextSize }}>
                    <TextEditor id={data.id} initialData={engagementData[data.id] ? engagementData[data.id][data.textBoxId[1]] : null}
                        manageToolData={manageToolData}
                        scrollable='tct-scrollable-darkgray'
                        preFilledTextBoxData={data.preFilledTextBoxData ? data.preFilledTextBoxData[data.textBoxId[1]] : false}
                        // textEditorClass='UPTC_CS_Square-textEditorClass'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                        hideToolbar={true}
                        oneHierarchy={true}
                        textBoxId={data.textBoxId[1]}
                        engagementData={engagementData}
                    />
                </div>
            </div>
        </div >
    );
}