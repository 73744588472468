import React, { createRef, useEffect, useState } from "react";
import { fetchDocByDocId, listenToMultipleDocs } from "../../utilities/firebase/fb_firestore";
import { connectToANode } from "../../utilities/firebase/fb_rtdb";
import Board from "./components/board";
import PieceCard from "./components/pieceCard";
import PieceChest from "./components/pieceChest";
import ToolBar from './components/upperTool'

let IdeaBoard = ({
  sessionId, toolId, width, height, isFullScreen,
  engagementStatus,
  currentEngagement,
  uniqueStudentsName,
  currentScene,
  currentUnit,
  userId,
  data,
  engagementData,
  manageToolData }) => {
  let [liveBoard, setLiveBoard] = useState(null);
  let [boardState, setBoardState] = useState(null);
  let [responses, setResponses] = useState(null);
  let [toolKit, setToolKit] = useState(null);
  let [dimensions, setDimensions] = useState({ board: { w: 0, h: 0 }, pieceChest: { w: 0, h: 0 } });
  let [sectionId, setSectionId] = useState(null)
  let containerRef = createRef();
  let engagementId = currentEngagement._id;

  useEffect(() => {
    let fetchToolData = async () => {
      let u = await fetchDocByDocId({ collectionId: "tools", docId: toolId });
      setToolKit(u)
    };
    if (toolId) { fetchToolData() }
  }, [toolId]);

  useEffect(() => {
    //Connection to the Live IdeaBoard
    if (engagementId) {
      connectToANode({
        refString: `test/live_session/${sessionId}/ideaBoards/${engagementId}`,
        f: (x) => { setBoardState(x) }
      });
      setLiveBoard(true);
    }
  }, [engagementId]);

  useEffect(() => {
    //Connection to the Corresponding Documents
    if (sessionId) {
      listenToMultipleDocs({
        collectionId: "generatedIdeas",
        condition: { a: "sessionId", o: "==", b: sessionId },
        callback: (snap) => {
          snap.docChanges().forEach((change) => {
            if (change.type === "added" || change.type === "modified") {
              setResponses(o => ({ ...o, [change.doc.id]: change.doc.data() }))
            }
            if (change.type === "removed") {
            }
          });
        }
      });
    }
  }, [sessionId])

  useEffect(() => {
    let h = containerRef.current?.clientHeight || null;
    let w = containerRef.current?.clientWidth || null;
    setBoardState(oldState => ({ ...oldState, dim: { w: w - 400, h } }))
    setDimensions({
      isReady: h && w ? true : false,
      board: { w: w - 400, h: h },
      pieceChest: { w: 396, h: h }
    });
  }, [isFullScreen, containerRef.current?.clientWidth, containerRef.current?.height]);

  return <div style={{
    height: "100%", overflow: "hidden", display: 'flex', flexDirection: 'column',
    // backgroundColor: '#F2F2F2',
    // backgroundImage: `url(${GridBg})`,
    backgroundRepeat: "repeat", backgroundPosition: "center"
  }}>
    {!isFullScreen
      ? <div style={{ width, height: '100%', textAlign: 'center' }}>please go fullScreen</div>
      : <>
        <div style={{ height: 70, padding: '1 8' }}>
          <ToolBar
            engagementStatus={engagementStatus}
            currentEngagement={currentEngagement}
            uniqueStudentsName={uniqueStudentsName}
            userId={userId}
            responses={responses}
            sessionId={sessionId}
            isFullScreen={isFullScreen}
            sectionId={sectionId}
            setSectionId={setSectionId}
            data={data}
            engagementData={engagementData}
          />
        </div>
        <div ref={containerRef}
          onLoad={() => { console.log("SAD") }}
          style={{ display: "flex", height: "calc(100% - 72px)", background: 'transparent' }}>
          {dimensions.isReady
            ? <Board
              data={data}
              dim={dimensions.board}
              currentEngagement={currentEngagement}
              setBoardState={setBoardState}
              boardState={boardState}
              toolKit={toolKit}
              sessionId={sessionId}
              toolId={toolId}
              responses={responses}
            /> : null}

          {dimensions.isReady
            ? <PieceChest
              width={width}
              height={height}
              dim={dimensions.pieceChest}
              boardState={boardState}
              toolKit={toolKit}
              manageToolData={manageToolData}
              data={data}
              sectionId={sectionId}
              setSectionId={setSectionId}
              currentScene={currentScene}
              currentUnit={currentUnit}
              setBoardState={setBoardState}
              engagementStatus={engagementStatus}
              sessionId={sessionId}
              responses={responses}
              currentEngagement={currentEngagement}
              uniqueStudentsName={uniqueStudentsName}
              engagementData={engagementData}
              isFullScreen={isFullScreen}
              toolId={toolId}
              userId={userId}
            />
            : null}
        </div>
        {boardState && boardState.isEditable
          ? <PieceCard
            responses={responses}
            bulkActionTargets={Object.keys(boardState.activeResponses || {}).filter(rid => boardState.activeResponses[rid])}
            open={boardState?.isEditable || false}
            toolId={toolId}
            toolKit={toolKit}
            sessionId={sessionId}
            engagementId={currentEngagement._id}
          /> : null}
      </>
    }
  </div >
}

export default IdeaBoard;