import React from 'react';
import { Input } from '@material-ui/core'
import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let descriptionSize = '12px';

    let textbox_1_top = '17.5%';
    let textbox_1_right = '38.5%';

    let textbox_2_top = '86.5%';
    let textbox_2_right = '34.5%';

    let textbox_3_top = '70%';
    let textbox_3_right = '25.5%';

    let textbox_4_top = '54.5%';
    let textbox_4_right = '15%';

    let textbox_5_top = '39%';
    let textbox_5_right = '5.5%';

    let textbox_6_top = '6%';
    let textbox_6_right = '30.5%';

    if (isFullScreen) {
        descriptionSize = '16px';
        textbox_1_right = '41%';
        textbox_2_right = '38%';
        textbox_3_right = '29%';
        textbox_4_right = '19.7%';
        textbox_5_right = '10%';
        textbox_6_right = '34%';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <textarea id={data.textBoxId[0]} value={engagementData[data.textBoxId[0]]} className={`ISE_Growing_Deep_textBox roboto tct-scrollable`}
                    style={{ fontSize: descriptionSize, top: textbox_1_top, right: textbox_1_right }}
                    onChange={(event) => manageToolData({ toolId: data.textBoxId[0], content: event.target.value })}
                    placeholder='Fill here...' />
                <textarea id={data.textBoxId[1]} value={engagementData[data.textBoxId[1]]} className={`ISE_Growing_Deep_textBox roboto tct-scrollable`}
                    style={{ fontSize: descriptionSize, top: textbox_2_top, right: textbox_2_right }}
                    onChange={(event) => manageToolData({ toolId: data.textBoxId[1], content: event.target.value })}
                    placeholder='Fill here...' />
                <textarea id={data.textBoxId[2]} value={engagementData[data.textBoxId[2]]} className={`ISE_Growing_Deep_textBox roboto tct-scrollable`}
                    style={{ fontSize: descriptionSize, top: textbox_3_top, right: textbox_3_right }}
                    onChange={(event) => manageToolData({ toolId: data.textBoxId[2], content: event.target.value })}
                    placeholder='Fill here...' />
                <textarea id={data.textBoxId[3]} value={engagementData[data.textBoxId[3]]} className={`ISE_Growing_Deep_textBox roboto tct-scrollable`}
                    style={{ fontSize: descriptionSize, top: textbox_4_top, right: textbox_4_right }}
                    onChange={(event) => manageToolData({ toolId: data.textBoxId[3], content: event.target.value })}
                    placeholder='Fill here...' />
                <textarea id={data.textBoxId[4]} value={engagementData[data.textBoxId[4]]} className={`ISE_Growing_Deep_textBox roboto tct-scrollable`}
                    style={{ fontSize: descriptionSize, top: textbox_5_top, right: textbox_5_right }}
                    onChange={(event) => manageToolData({ toolId: data.textBoxId[4], content: event.target.value })}
                    placeholder='Fill here...' />
                {
                    data.textBoxId[5] && <Input className='ISE_Growing_Deep_input'
                        placeholder="Fill Here."
                        style={{ fontSize: descriptionSize, top: textbox_6_top, right: textbox_6_right }}
                        value={engagementData[data.textBoxId[5]] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: data.textBoxId[5], content: event.target.value })
                        }}
                    />
                }
            </div>
        </div>
    );
}