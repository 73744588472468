import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import "./applicationDashboard.css";

import Capsule from "../../../components/acp/MyCapsules";

import Header from "../../../components/acp/Header";
import Footer from "../../../components/acp/Footer";

// import FirstTimeUserMsg from './firstTimeUserMsg';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { activateSection } from "../channelDashboard/redux_actions"

import { getLearningToolDetails, getModuleDetails, getPrepareModulesAttempt, getRoadmapDetails, getTagsAttempt, getOrganisationByParentId } from "../../application/redux_actions"
import { getOrganizationAttempt } from "../../auth/redux_actions";
import { Component } from "react";
import { CircularProgress } from "@material-ui/core";

// import Add_SchoolName_Popup from "../Add_SchoolName_Popup";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <span
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div p={3}>{children}</div>}
    </span>
  );
}

// let applicationDashboard = function applicationDashboard(props) {
class applicationDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubscribed: false,
      chk: false
    }
  }

  handleChange = (event, newValue) => {
    this.props.activateSection(newValue);
  };

  async componentDidMount() {
    let { user, user_token } = this.props.auth
    let userid = user.id
    let token = user_token

    let orgid = user.organizationid;


    if (Object.keys(this.props.auth.orgDetails).length === 0) {
      await getOrganizationAttempt({ orgid, token })
    }

    let parentOrgId = await this.props.auth.orgDetails.id

    if (this.props.auth.orgDetails && parentOrgId && Object.keys(this.props.auth.organisationList).length === 0) {

      await getOrganisationByParentId({ parentOrgId: parentOrgId, user_token: token })
    }


    this.props.getTagsAttempt({ token })
      .then(res1 => {
        this.setState({ chk: true });
        this.props.getPrepareModulesAttempt({ userid, token })
          .then(result => {
            let { availableModule } = this.props.app;
            this.setState({ isSubscribed: availableModule.length })
            let availableModules = availableModule;
            availableModules.forEach(module => {
              this.props.getModuleDetails({ id: module.moduleId, token })
                .then(res => {
                  // if (res.payload) {
                  // let roadMaps = (res.payload.data.organiser && res.payload.data.organiser.roadmaps && Object.keys(res.payload.data.organiser.roadmaps).sort((a, b) => {
                  //   return res.payload.data.organiser.roadmaps[a].order - res.payload.data.organiser.roadmaps[b].order
                  // })) || {};

                  // Object.keys(roadMaps).forEach(rdId => {
                  //   this.props.getRoadmapDetails({ id: roadMaps[rdId], token: token })
                  //     .then(ress => {
                  //       if (ress.payload) {
                  //         ress.payload.data.LearningTools && Object.keys(ress.payload.data.LearningTools).forEach(uId => {
                  //           this.props.getLearningToolDetails({ id: uId, token: token })
                  //         });
                  //       }
                  //     });
                  // });
                  // }
                });
            })
          })
      })
  }
  render() {
    let menuItems1 = [];
    let menuItems = [];
    let { availableModule, availableTags, moduleDetails } = this.props.app;
    let availableModules = availableModule;
    let units = this.props.app.units
    let { user_token, user_uid } = this.props.auth

    function populateHeads() {
      // {
        if (availableTags) {
          if (Array.isArray(availableTags)) {
            availableTags.map((el, i) => {
              if (el) {
                if (!menuItems1.includes(el.tagName)) {
                  menuItems1.push({ title: el.tagName, id: el.id });
                }
              }
            })
          }
        }
      // }

      // {
        menuItems1.map((tag, j) => {
          let ds = []
          availableModules.map((el, i) => {
            if (el) {
              let md = moduleDetails[el.moduleId]
              if (md) {
                if (md.tag) {
                  md.tag.forEach(element => {
                    let tagchk = 0;
                    if (element._id === tag.id) {
                      tagchk = 1;
                    }
                    if (tagchk === 1) {
                      ds.push(md);
                    }
                  });
                }
              }
            }
          })
          menuItems.push({ title: tag.title, dataSet: ds })
        }
        )
      // }
      return null;
    }
    let index = 0

    let value = this.props.channelState.activeSection
    // let { user } = this.props.auth
    if (!this.state.chk) {
      // return <div>Loading...</div>
      return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress classes={{ root: 'circular-progress-color' }} size={70} />
      </div>
    }
    else
      return <div className="channelDashboardCntr" style={{
        width: '100%'
      }}>
        {/* {((!this.props.auth.orgDetails.parentorganization !== false) || this.props.auth.orgDetails.parentorganization === '0') && this.props.auth.user.organizationid !== '0' && this.props.auth.organisationList && this.props.auth.organisationList.organization && this.props.auth.organisationList.organization.length > 0 && <Add_SchoolName_Popup app={this.props.app} auth={this.props.auth} />} */}
        {/* {this.props.auth.updatedUser === true && <Add_CollegeName_Success app={this.props.app} auth={this.props.auth} />} */}
        <Header active='myCapsule' />
        <div className="channelsCntr">
          <div style={{ overflow: "auto" }}>
            <div style={{ backgroundColor: "#F7F7F7", marginBottom: 65 }}>
              <div style={{
                display: "flex", paddingTop: 56, paddingBottom: 36,
                justifyContent: "space-between", maxWidth: 1005, margin: "auto"
              }}>
                <div>
                  <div className="ch-sub-title" style={{ paddingBottom: 8 }}>Course</div>
                  <div style={{ fontSize: 30, width: 400, fontWeight: 500, color: "#1D1D1D" }}>Awakened Citizen Program - Digital Version</div>
                </div>
                <div>
                  <div className="ch-sub-title" style={{ paddingBottom: 8 }}>Hosts:</div>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", borderLeft: "1px solid #CFCFCF", padding: "0px 12px" }}>
                      <div><img alt="rkm" src="/images/assets/RKM.png" height={56} /></div>
                      <div style={{ fontSize: 12, width: 90, marginLeft: 16, display: "flex", alignItems: "center" }}>Delivered by Ramkrishna Mission,Delhi</div>
                    </div>
                    <div style={{ display: "flex", borderLeft: "1px solid #CFCFCF", padding: "0px 12px" }}>
                      <div><img alt="illumine-logo" src="https://s3.ap-south-1.amazonaws.com/illumine-logos/illuminelogo.svg" height={56} /></div>
                      <div style={{ fontSize: 12, width: 90, marginLeft: 16, display: "flex", alignItems: "center" }}>Knowledge Partner: ILLUMINE</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {populateHeads()}
            <Tabs classes={{ root: "tabs-root", indicator: "tabs-indicator" }} value={value} onChange={this.handleChange} aria-label="simple tabs">
              {menuItems.map((el, i) => {
                if (el.dataSet.length > 0) {
                  return <Tab key={i} label={el.title} {...a11yProps(i)} />
                }
                return null
              })}
              <Tab label="Fac Guides" {...a11yProps(menuItems.length)} />
            </Tabs>
            {menuItems.map((el, i) => {
              if (el.dataSet.length > 0) {
                // { 
                  index += 1
                //  }
                return <TabPanel key={index} value={value} index={index - 1}>
                  {buildappDashboard(el.dataSet, user_token, user_uid)}
                </TabPanel>
              }
              return null
            })}
            {units ? <TabPanel value={value} index={index}>
              {buildGuideDashboard(units, user_token, user_uid)}
            </TabPanel> : ""
            }
          </div>
        </div>
        <Footer />
      </div>
  }
}

const buildappDashboard = (channelData, user_token, coach_id) => {
  return Object.keys(channelData).map(key => {
    let channel = channelData[key];
    return <Capsule key={key} cardData={channel} user_token={user_token} coach_id={coach_id} buttonText="Run Session" conduct={true} />
  });
};

const buildGuideDashboard = (_data, user_token, coach_id) => {
  return Object.keys(_data).map(key => {
    let __data = _data[key];
    return <Capsule key={key} cardData={{ ...__data, "mainFilePath": __data["icon"], "title": "", "description": __data["title"], "uniqueAboutIt": __data["description"] }} user_token={user_token} coach_id={coach_id} buttonText="View" prepare={true} />
  });
};

const mapStateToProps = state => ({
  auth: state.auth,
  channelState: state.channelState,
  app: state.app
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    activateSection,
    getTagsAttempt,
    getPrepareModulesAttempt,
    getModuleDetails,
    getRoadmapDetails,
    getLearningToolDetails,
    getOrganisationByParentId,
    getOrganizationAttempt
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(applicationDashboard));