import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Slider from '@material-ui/core/Slider';

// const useStyles = makeStyles({
//     root: {
//         // width: 300,
//         color: '#c1330b',
//     },
//     mark: {
//         backgroundColor: 'white',
//         height: 10,
//         width: 10,
//         marginTop: -4,
//         // marginLeft: -5,
//         opacity: 1,
//         border: '1px solid #c1330b'
//     },
//     rail: {
//         height: 2,
//         backgroundColor: '#c1330b',
//         opacity: 1,
//     },
//     thumb: {
//         width: 0,
//         height: 0,
//         '&:focus, &:hover': {
//             boxShadow: 'none',
//         },
//     },
//     valueLabel: {
//         '& *': {
//             color: '#c1330b'
//         },
//         width: 10,
//         height: 10,
//         left: 'calc(-50% - -1px)',
//         transform: 'scale(0.45) translateY(23px) !important'
//     },
// });

// function valuetext(value) {
//     return value;
//     // return `${value}°C`;
// }

export default ({ step, min, max, id, engagementData, manageToolData, isFullScreen, disabled }) => {

    const useStyles = makeStyles({
        root: {
            // width: 300,
            color: '#c1330b',
        },
        mark: {
            backgroundColor: 'white',
            height: isFullScreen ? 15 : 10,
            width: isFullScreen ? 15 : 10,
            marginTop: isFullScreen ? -6 : -4,
            // marginLeft: -5,
            opacity: 1,
            border: '1px solid #c1330b'
        },
        rail: {
            height: 2,
            backgroundColor: '#c1330b',
            opacity: 1,
        },
        thumb: {
            width: 0,
            height: 0,
            '&:focus, &:hover': {
                boxShadow: 'none',
            },
        },
        valueLabel: {
            '& *': {
                color: '#c1330b'
            },
            width: 10,
            height: 10,
            left: isFullScreen ? 'calc(-50% - 1px)' : 'calc(-50% - -1px)',
            transform: isFullScreen ? 'scale(0.8) translateY(-1px) !important' : 'scale(0.45) translateY(23px) !important'
        },
    });

    const classes = useStyles();

    return (
        <Slider
            classes={{ root: classes.root, mark: classes.mark, rail: classes.rail, thumb: classes.thumb, valueLabel: classes.valueLabel }}
            value={engagementData[id] || 0}
            // getAriaValueText={valuetext}
            onChange={(event, value) => manageToolData({ toolId: id, content: value })}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="on"
            step={step}
            marks
            min={min}
            max={max}
        />
    );
}
