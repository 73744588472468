import React from 'react';


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let descriptionSize = '11px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '14px';
    }
    
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <textarea id={data.textBoxId} placeholder='Fill here…'
                    className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ width: data.toolId === 'ImgSym_Approach_1' ? '19%' : '34%', height: data.toolId === 'ImgSym_Approach_1' ? '12%' : '54%', fontSize: descriptionSize, position: 'absolute', top: data.toolId === 'ImgSym_Approach_1' ? '74%' : '35%', right: data.toolId === 'ImgSym_Approach_1' ? '6%' : '8%' }}
                    value={engagementData[data.textBoxId] || ''}
                    onChange={(event) => {
                        manageToolData({ toolId: data.textBoxId, content: event.currentTarget.value })
                    }}
                />
            </div>
        </div>
    );
}