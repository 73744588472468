import { ThemeProvider } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createTheme } from '@material-ui/core/styles';
import './style.css'
import ReactTooltip from 'react-tooltip';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';


export default ({ allLanguages, langugeList, isFullScreen, value, handleChange, forNavigator }) => {
  let theme = createTheme({
    overrides: {
      MuiSelect: {
        root: {
          borderRadius: '8px !important',
          backgroundColor: isFullScreen ? '#6b6b6b' : 'white',
          color: isFullScreen ? 'white' : '#6b6b6b',
          '&:focus': {
            borderRadius: '8px',
            backgroundColor: isFullScreen ? '#6b6b6b !important' : 'white !important',
          }
        },
        icon: {
          right: '7px',
          // fill: isFullScreen && 'white'
        }
      },
      MuiMenu: {
        root: {
          color: isFullScreen && 'white'
        },
        paper: {
          // backgroundColor: '#6b6b6b',
          backgroundColor: isFullScreen ? '#6b6b6b' : 'white',
          color: isFullScreen ? '#ffffff' : '#6b6b6b',

        }
      },
      MuiList: {
        root: {
          padding: '0px 13px !important',
          backgroundColor: 'transparent !important',
        }
      },
      MuiListItem: {
        root: {
          backgroundColor: 'transparent',
          borderRadius: '8px',
          fontSize: "20px !important",
          '&:hover': {
            color: '#ba1f00 !important',
            background: '#FFE4DF !important',
            borderRadius: '8px',
          },
        },
        button: {
          backgroundColor: 'transparent',
          margin: '10px 0px',
          fontSize: " 16px !important",
        },
      },
    }
  })
  const [showDropDown, setShowDropDown] = useState(false)
  function shortenString(input) {
    return input.length <= 3 ? input : input.substring(0, 3) + ' '
  }
  return (
    <div style={{
      display: langugeList?.length > 0 ? 'flex' : 'none',
      justifyContent: 'flex-end',
      // paddingBottom: '10px',
    }}>
      {langugeList?.length > 0 && <ThemeProvider theme={theme} >
        {!showDropDown && forNavigator ?
          <>
            <a data-tip data-for={"123"}>
              <div
                style={{
                  textTransform: 'uppercase',
                  display: 'flex', fontSize: 16, borderRadius: 8, padding: '2px', cursor: 'pointer'
                }}
                onClick={() => setShowDropDown(true)} >
                {allLanguages[value].substring(0, 3)}<ArrowDropDownOutlinedIcon />
              </div>
            </a>
            <ReactTooltip id={"123"} aria-haspopup="true" role="example">
              <span>Change language</span>
            </ReactTooltip>
          </>
          : <>
            <Select
              open={showDropDown}
              style={{ borderRadius: '8px', }}
              value={value}
              MenuProps={{ keepMounted: true, disablePortal: true }}
              classes={{ 'root': 'selectRoot', }}
              onChange={handleChange}
              onClick={() => setShowDropDown(!showDropDown)}
              displayEmpty
              disableUnderline
              inputProps={{ 'aria-label': 'Without label' }}
            >

              {langugeList.map(lng => {
                return <MenuItem key={lng} value={lng}><span>
                  {allLanguages[lng]}
                </span></MenuItem>
              })}
            </Select>
          </>
        }

      </ThemeProvider >}
    </ div >
  );
}
