import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import TakeAway_Button from '../../../Tools/TakeAway_Button';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, currentUnit }) => {
    const right = ['32%', '3%'];

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((textBoxId, i) => <div key={textBoxId} className='Sustainable-Success-Define-ss-textbox' style={{ fontSize: isFullScreen ? '18px' : '12px', lineHeight: isFullScreen ? '18px' : '12px', right: right[i] }}>
                        <TextEditor id={textBoxId} initialData={engagementData[textBoxId] || null}
                            manageToolData={manageToolData} placeholder='Fill here...'
                            scrollable='tct-scrollable-darkgray'
                            preFilledTextBoxData={data.preFilledTextBoxData ? data.preFilledTextBoxData[data.textBoxId] : ''}
                            textEditorClass='UPTC_CS_Square-textEditorClass'
                            wrapperClass='text-editor-WIAC_diff_in_the_lives'
                            toolBarClass='CD-CNP-textbox-toolbar'
                        />
                    </div>)
                }
                <div style={{ width: '25%', height: '10%', position: 'absolute', top: '0%', left: '73%' }}>
                    <TakeAway_Button currentUnit={currentUnit} data={data} engagementData={engagementData} />
                </div>
            </div>
        </div >
    );
}