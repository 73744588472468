import React from 'react';

import TextEditor from '../../../Tools/TextEditor/TextEditor';

import Pencil from "../../../components/images/Tools-img/pencil.png";
// import Bulb from "../../../components/images/Tools-img/Bulb.png";

import './style.css';


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    // let titleSize = '20px'
    let descriptionSize = '12px';
    // let infoSize = '9px';
    let headingSize = '16px';
    if (isFullScreen) {
        // titleSize = '30px';
        headingSize = '24px'
        // descriptionSize = '16px';
        // infoSize = '13.5px';
    }

    return (
        <div style={{ width, height }}>
            <div className='ESI_Slide_illumine_logo'>
                <img src='https://pedgog2.s3.ap-south-1.amazonaws.com/Becoming+future+ready+contributors/04-Enlightened+Self+interest/Illuminelogo.png'
                    style={{ width: '100%', height: '100%' }}
                    alt='illumine-logo'
                />
            </div>
            <div style={{ width: '75%', height: '35%', display: 'flex', alignItems: 'center', margin: 'auto' }}>
                {
                    data.filledTextBoxId.map((item, i) => <div key={i} className={`work-sans EIS_slide_33_filled_textBox`} style={{ fontSize: descriptionSize }}>
                        <TextEditor id={item} initialData={engagementData[item] || null}
                            manageToolData={manageToolData} readOnly={true}
                            hideToolbar={true}
                            wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        />
                    </div>)
                }
            </div>
            <div className='ESI-textbox-container' style={{ height: '65%' }}>
                <div className='EIS_slide_32_container'>
                    <div className='ESI-slide_32_pencil'>
                        <img src={Pencil} style={{ width: '100%', height: '100%' }} alt='pencil' />
                    </div>
                    <div style={{ width: '80%', height: '100%' }}>
                        <div style={{ width: '100%', height: '30%' }}>
                            <div className='work-sans' style={{ fontSize: descriptionSize, fontWeight: 'bold' }}>{data.title}</div>
                            <div className='work-sans' style={{ fontSize: descriptionSize }}>{data.subTitle}</div>
                        </div>
                        <div style={{ width: '100%', height: '70%' }}>
                            {
                                data.textBoxId.map((item, i) =>
                                    <div style={{ width: '90%', height: '32%', display: 'flex', backgroundColor: '#ffe69f', borderRadius: '17px', border: '4px solid white', margin: '1% 0%' }}>
                                        <div className='work-sans EIS_slide_33_text-box_text' style={{ fontSize: headingSize }} >{`#${i + 1}`}</div>
                                        <div key={i} style={{ width: '90%', display: 'flex', margin: '0 0.5%', fontSize: descriptionSize, height: '100%' }}>
                                            <TextEditor id={item} initialData={engagementData[item] || null}
                                                manageToolData={manageToolData} hideToolbar={true} placeholder={'Fill here.'}
                                                wrapperClass='text-editor-WIAC_diff_in_the_lives' />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}