import React from 'react';

let SearchIcon = ({fill}) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <g id="prefix__Group_2121" data-name="Group 2121" transform="translate(19291 18806)">
            <path id="prefix__Rectangle_952" d="M0 0H34V34H0z" data-name="Rectangle 952" transform="translate(-19291 -18806)" style={{opacity:0}} />
            <g id="prefix__magnifying-glass" transform="translate(-19285.051 -18800)">
                <path id="prefix__Path_1436" d="M21.86 20.878l-5.348-5.346a9.39 9.39 0 1 0-.927.927l5.348 5.341a.663.663 0 0 0 .464.2.643.643 0 0 0 .464-.2.659.659 0 0 0-.001-.922zM1.362 9.38a8.065 8.065 0 1 1 8.065 8.068A8.073 8.073 0 0 1 1.362 9.38z" data-name="Path 1436" style={{fill}} />
            </g>
        </g>
    </svg>
}


export default SearchIcon;