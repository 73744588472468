import React from 'react';


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let descriptionSize = '11px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '14px';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <textarea id={data.textBoxId} placeholder='Fill here…'
                    className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ width: '52%', height: '18%', fontSize: descriptionSize, position: 'absolute', top: '18%', right: '7%' }}
                    value={engagementData[data.textBoxId] || ''}
                    onChange={(event) => {
                        manageToolData({ toolId: data.textBoxId, content: event.currentTarget.value })
                    }}
                />
                {data.caseTextBoxId && <textarea id={data.caseTextBoxId} placeholder='Fill here…'
                    className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ width: '27%', height: '26%', fontSize: descriptionSize, position: 'absolute', top: '18%', right: '66%' }}
                    value={engagementData[data.caseTextBoxId] || ''}
                    onChange={(event) => {
                        manageToolData({ toolId: data.caseTextBoxId, content: event.currentTarget.value })
                    }}
                />}
            </div>
        </div>
    );
}