import React from 'react';
import Tree from '../../../Live_Session_Tools/IdeaBord_tree'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId }) => {

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: 'white', width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {/* <div style={{ position: 'absolute', top: '21%', right: '2%', width: '20%', height: '15%' }}>
                    {
                        // (sessionId) &&
                        <Start_N_Stop width={width} height={height}
                            engagementData={engagementData}
                            manageToolData={manageToolData}
                            data={data}
                            isFullScreen={isFullScreen}
                            engagementStatus={engagementStatus}
                            sessionId={sessionId}
                        />
                    }
                </div> */}
                <div style={{
                    position: 'absolute',
                    width: '100%',
                   
                }}>
                    <Tree data={data} />
                </div>
            </div>
        </div >
    );
}