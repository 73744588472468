import React from 'react'
import ReactTooltip from "react-tooltip";
import { Checkbox, ClickAwayListener, Select, TextField } from '@material-ui/core'
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineRounded';
// import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import AddSharpIcon from '@material-ui/icons/AddSharp';

export default ({
    key1, compare, view, deck,
    handleSaveText, viewC, el, allTags, editOn, setEditOn, handleDropDown, handleRemove, checkBoxHandle, getCardBg, engagementStatus, clues, clueCards, setClueCards
}) => {
    // const addClueToBoard = (el) => {
    //     el.tags[0]
    // }
    return (
        <div
            key={el.id}
            className="response-box" style={{
                backgroundColor: getCardBg(el.tags[0]),
                // backgroundColor: bgColor
            }}>
            {!deck && <div style={{ width: '10%', }}>
                <Checkbox
                    checked={el.checked}
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    onChange={() =>
                        checkBoxHandle(el)
                    }
                    style={{ marginLeft: '-20%', marginTop: '-20%' }}
                    inputProps={{
                        "aria-label":
                            "primary checkbox",
                    }}
                />
            </div>}
            <div className="text-part">
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginLeft: '3%' }}>
                    {!compare && <span style={{ fontSize: "0.8rem", lineHeight: '1.3rem', textTransform: "capitalize", fontWeight: 'bold' }}>{el.user}</span>}
                    {!compare &&
                        <div className='display-hover' style={{ width: 'max-content' }}>
                            {!clues && <div id={el.id}>
                                <a data-tip data-for={el.id}>
                                    <DeleteOutlineOutlinedIcon style={{ marginLeft: deck ? '100%' : '-40%', marginTop: "16%", fill: '#939393' }} fontSize="small" className='display-hover' onClick={() => handleRemove(key1, el)} />
                                </a>
                                <ReactTooltip id={el.id} aria-haspopup="true" role="example">
                                    <span>Delete</span>
                                </ReactTooltip>
                            </div>}
                            {clues && <div id={el.id + 1}>
                                <a data-tip data-for={el.id + 1}>
                                    <AddSharpIcon style={{ marginTop: "16%", fill: '#939393' }} fontSize="small" className='display-hover'
                                    // onClick={addClueToBoard(el)} 
                                    />
                                </a>
                                <ReactTooltip id={el.id + 1} aria-haspopup="true" role="example">
                                    <span style={{ fontWeight: 'bold' }}>Add To Board</span>
                                </ReactTooltip>
                            </div>}
                        </div>
                    }
                </div>
                <div style={{ marginLeft: '3%', marginTop: '-1%' }}>
                    {!compare && !clues && editOn?.[el?.id] ?
                        <ClickAwayListener onClickAway={() => {
                            if (!compare) setEditOn(oldState => ({ ...oldState, [el?.id]: false }))

                        }}>
                            <div>
                                <TextField id={1 + el.id + 1}
                                    value={el.text}
                                    // defaultValue={el.text}
                                    multiline
                                    maxRows={5}
                                    onChange={(event) => {
                                        handleSaveText(key1, el, event.target.value)
                                    }}
                                />
                                <Select fullWidth
                                    style={{ fontSize: "0.9rem " }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    native
                                    defaultValue={el.tags[0]}
                                    onChange={(event) => {
                                        let newValue = [event.target.value]
                                        handleDropDown(key1, el, newValue)
                                    }}
                                // label="age"
                                >
                                    {allTags?.map(tag => {
                                        return <option value={tag}>{tag}</option>
                                    })}
                                </Select>
                            </div>

                        </ClickAwayListener>
                        :
                        <div style={{ fontSize: '0.9rem', lineHeight: '1.1rem', textOverflow: 'ellipsis', overflow: 'hidden', paddingBottom: '1%' }} onDoubleClick={() => { setEditOn(oldState => ({ ...oldState, [el?.id]: true })) }} >
                            {el.text}
                        </div>
                    }
                </div>
                <div>
                </div>
            </div>
        </div >

    )
}