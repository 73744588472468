import React from 'react'
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    const [selectedActivity, setSelectedActivity] = React.useState(Object.keys(data.drag_drop_deckId)[0]);
    let descriptionSize = '10px';
    let subTitleSize = '12px';
    if (isFullScreen) {
        subTitleSize = '22px'
        descriptionSize = '14px';
    }
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className='sma-identifying-activity-display-select-container'>
                    {
                        Object.keys(data.drag_drop_deckId).map(deck => <div key={deck} style={{ background: selectedActivity === deck ? `linear-gradient(0deg, ${data.drag_drop_deckId[deck].bgColor}, #f3f3f3 )` : '#D9D9D9', fontSize: descriptionSize }}
                            className={`sma-identifying-activity-display-select-box sma-identifying-activity-display-transition ${selectedActivity === deck && 'sma-identifying-activity-display-select-box-active'}`}
                            onClick={() => setSelectedActivity(deck)}>
                            {data.drag_drop_deckId[deck].text}
                        </div>)
                    }
                </div>
                <div className='sma-identifying-activity-display-text-container sma-identifying-activity-display-transition overFlowYAuto tct-scrollable-darkgray sma-identifying-activity-display-select-box-active ' style={{ backgroundColor: data.drag_drop_deckId[selectedActivity].bgColor, fontSize: descriptionSize }}>
                    <div className='text-center' style={{ fontSize: subTitleSize, fontWeight: 'bold' }}>{data.drag_drop_deckId[selectedActivity].text}</div>
                    {engagementData[data.prevToolId] && engagementData[data.prevToolId][selectedActivity] && engagementData[data.prevToolId][selectedActivity].map(item =>
                        <div className='sma-identifying-activity-display-textBox' key={item.text} style={{ fontSize: subTitleSize }}>
                            {item.text}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}