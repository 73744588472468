import React, { useEffect } from "react";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import ImageSlide from "../components/ImageSlide";
let CounsellingApp = ({ data, language, isFullScreen, width, height }) => {
    const app = useSelector(state => state.app)
    const auth = useSelector(state => state.auth)
    const otp = app?.otpData?.otp;

    let { type, styles } = data?.componentDetails || {}
    let _styles = styles[isFullScreen];
    let token = auth?.user_token;
    let userId = auth?.user_uid;
    let value = `https://play.google.com/store/apps/details?id=com.csc.illumine.citizenenquiryapp&referrer=phone%3D${userId}`


    function RenderComponent() {
        switch (type) {
            case 'QRCODE':
                return <QRCode
                    style={{ height: "auto", ..._styles }}
                    value={value}
                    viewBox={`0 0 256 256`}
                />
            case 'SESSION_CODE':
                return <div style={{ ..._styles }}> {otp}</div >
            case "VLE_APP":
                return <div style={{ ..._styles }}>
                    <iframe
                        id={'CounsellingApp'}
                        style={{ ..._styles['iFrame'] }}
                        src={`https://response-citizen-enquiry-system.vercel.app?token=${token}`}
                        frameborder="0" width="100%" height="100%" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"
                    />
                </div>
            default:
                break;
        }
    }

    return <div style={{
        backgroundImage: `url(${data.url[language || 'en']})`,
        backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%', height: "100%",
    }}>
        {RenderComponent()}
    </div>
}

export default CounsellingApp