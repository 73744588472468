import React from "react";
import TextEditor from '../../Tools/TextEditor/TextEditor';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {


    let textSize_1 = '12px';
    let textSize_2 = '16px';

    if (isFullScreen) {
        textSize_1 = '18px';
        textSize_2 = '23px';
    }

    return <div style={{ width, height }}>
        <div style={{ height: '20%', padding: '1.5%', backgroundColor: '#e4e7ec', fontSize: textSize_2, fontWeight: 'bold', color: '#264a69', width: '100%' }}>
            {data.title}

            <div style={{ fontSize: textSize_1, color: 'black', fontWeight: '400' }}>{data.subTitle}</div>
        </div>
        <div style={{ display: "flex", padding: '8px', backgroundColor: '#e2f488', height: '75%', borderRadius: '25px', boxShadow: 'rgb(195 195 195) 10px 10px 6px -6px', margin: '1% 2%' }}>
            <div style={{ width: '45%', height: '100%' }}>
                <img style={{ height: '100%', width: '90%' }} src={data.bgImg} alt='img' />
            </div>
            <div style={{ padding: '2%', border: '3px solid black', flexGrow: '1', borderRadius: '24px', height: '100%', backgroundColor: "#fdff9b" }}>
                <div style={{ height: '15%' }}>
                    <strong style={{ fontSize: textSize_1 }}>
                        {data.toolHeading}
                    </strong>
                </div>
                <div style={{ height: '80%', display: 'flex' }}>
                    <TextEditor id={data.textBoxId} initialData={engagementData[data.textBoxId] || null}
                        manageToolData={manageToolData} placeholder='Fill here'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        textEditorClass={!isFullScreen ? 'trust-surplus-textbox-height' : false}
                        scrollable='tct-scrollable-darkgray'
                    />
                </div>
            </div>
        </div>
    </div>
}