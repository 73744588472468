import React, { useEffect, useState } from "react";
import Piece from "../piece";
import { activateResponse, placeOnTheBoard, updateFilter } from "../../utils";
import { FiberManualRecord } from "@material-ui/icons";

let RadialMap = ({ toolId, sessionId, dim,
 toolKit, onClickOnTypes, activeResponses, filters,
 fieldWiseResponses, responses, currentEngagement }) => {
 let engagementId = currentEngagement._id;
 let { ideaBoard } = currentEngagement
 const [radius, setRadius] = useState(null)
 let center = { x: (dim.w / 2) - 0, y: dim.h / 2 };
 let MAX = 0.875 * (dim.h / 2);
 let countOfCircles = Object.keys(toolKit.classes).length;
 let GAP = parseInt(0.9 * (MAX / countOfCircles));
 const [scaleFactor, setScaleFactor] = useState(1)
 const marginForRadius = 12;

 useEffect(() => {
  let a = []
  Object.keys(toolKit?.classes || {}).map((e, i) => {
   let b = MAX - GAP * i
   a.push(b)
  })
  setRadius(a)
 }, [toolKit?.classes]);

 useEffect(() => {
  if (fieldWiseResponses) {
   Object.keys(fieldWiseResponses).forEach(fid => {
    let f = fieldWiseResponses[fid];
    Object.keys(f).forEach(rid => {
     let pt = f[rid];
     if ((pt.class || pt.type) && !(pt.x && pt.y)) {
      let lvl = toolKit?.classes[pt.class || pt.type].order;
      let offLvl = countOfCircles - lvl;
      let Rmax = (radius?.[offLvl] ? radius?.[offLvl] : MAX) - marginForRadius;
      let Rmin = (radius?.[offLvl + 1] ? radius?.[offLvl + 1] : 0) + marginForRadius;
      pt.Rmax = Rmax; pt.Rmin = Rmin;
      placeOnTheBoard({ pt, center, sessionId, toolId, fid, rid, typeId: pt.class, engagementId })
     }
    })
   })
  }
 }, [fieldWiseResponses])

 if (dim.w && dim.h) {
  return <svg version="1.1"
   viewBox={`0 0 ${dim.w || 0} ${dim.h || 0}`}
   width={dim.w || 0} height={dim.h || 0}
   xmlns="http://www.w3.org/2000/svg"
  >
   {/* Setting up legends */}
   <foreignObject x={dim.w - 165} y={dim.h - 150} width={160} height={130} fill="#ffffff88">
    <div xmlns="http://www.w3.org/1999/xhtml" style={{ backgroundColor: '#fff', padding: '4px 0px 2px', borderRadius: 8 }}>
     {/* <div style={{ fontSize: 13, fontWeight: 700, marginLeft: 8 }}>{toolKit?.legendTitle || Legends}</div> */}
     {radius && Object.keys(toolKit?.classes || {})
      .sort((a, b) => toolKit.classes[b].order - toolKit.classes[a].order)
      .map((clid, i) => {
       let c = toolKit.classes[clid];
       return <div
        classes={`legend ${filters.class === clid ? 'active' : 'inactive'}`}
        key={`legend-${clid}`}
        onClick={() => {

         Object.keys(fieldWiseResponses || {})
          .map(fid => {
           Object.keys(fieldWiseResponses[fid]).filter(rid => {
            return responses?.[rid]?.class === clid
           }).forEach(rid => {
            activateResponse({
             sessionId, engagementId, fid, rid,
             isActive: filters?.class === clid ? null : true
            })
           });
          })

         updateFilter({
          sessionId, toolId, key: 'class',
          value: filters?.class === clid ? null : clid, engagementId
         })
        }}
        style={{
         display: 'flex', color: c.colorScheme, padding: '6px 5px',
         background: filters.class === clid ? `${c.colorScheme}22` : '#fff'
        }}>
        <FiberManualRecord fontSize="small" />
        <div style={{ fontSize: 12 }}>{c.legend || `Zone ${i + 1}`}</div>
       </div>
      })}
    </div>
   </foreignObject>
   {/* <rect rx={8} x={dim.w - 202} y={dim.h - 150} width={180} height={130} fill="#ffffff88" /> */}
   {/* <text style={{ fontSize: 13, fontWeight: 700 }} x={dim.w - 188} y={dim.h - 124}>{toolKit.legendTitle || "Legends"}</text> */}
   {radius && Object.keys(toolKit?.classes || {})
    .sort((a, b) => toolKit.classes[b].order - toolKit.classes[a].order)
    .map((clid, i) => {
     let c = toolKit?.classes[clid];
     return <g key={clid}>
      <circle
       onClick={(ev) => {
        let activeResponseId = Object.keys(activeResponses).length == 1 ? Object.keys(activeResponses)[0] : null;
        if (activeResponseId) { onClickOnTypes(ev, clid, activeResponseId, "f001"); }
       }}
       key={`circle-${i}`}
       cx={center.x} cy={center.y}
       r={radius?.[i]}
       stroke={"#B9B9B977"} strokeWidth="5"
       fill={`#fff`} />
      <text x={center.x - 12} y={center.y - radius?.[i] + 16}
       style={{
        fontSize: 9, fill: "#00000088",
        //  stroke: '#ff000033'
       }}>{`Zone ${Object.keys(toolKit.classes).length - i}`}
      </text>
     </g>
    })
   }
   {radius && Object.keys(fieldWiseResponses || {}).map(fid => {
    let f = fieldWiseResponses[fid];
    return Object.keys(f || {})
     .filter(rid => {
      let r = f?.[rid]
      return !r?.deletedOn
     })
     .sort((a, b) => {
      return Object.keys(f[b].vote || {}).length - Object.keys(f[a].vote || {}).length
     })
     .map((rid, j) => {
      let pt = f[rid];
      if (j < ideaBoard?.showTop && pt.x && pt.y && responses?.[rid]) {
       return <Piece
        colorScheme={toolKit.classes[pt.class].colorScheme}
        scaleFactor={scaleFactor}
        response={responses[rid]}
        key={`piece-${rid}-j`}
        pt={f[rid]} toolId={toolId} rid={rid} fid={fid}
        center={center} engagementId={engagementId}
        doubleClickActive={Object.keys(activeResponses || {}).length == 0 ? true : false}
        sessionId={sessionId} isActive={activeResponses?.[rid] ? true : false} />
      }
     })
   })}
  </svg>
 } else {
  return null
 }
}

export default RadialMap;