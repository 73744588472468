import React from 'react';
import Modal_Component from '../../../Tools/Modal_Component';
import './style.css'


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className='UPTC-Popup'>
                    <Modal_Component
                        type={data.popUp_content[0].type}
                        icon={data.popUp_content[0].icon}
                        src={data.popUp_content[0].src}
                        width={width}
                        height={height}
                    />
                </div>
            </div>
        </div>
    );
}