import React, { useEffect, useRef } from 'react';
import { BigPlayButton, Player } from 'video-react';
import "video-react/dist/video-react.css";
import './style.css';

let VideoSlide = ({ vidUrl, dimensions, popupWidth, popupHeight, imgUrl }) => {
  // let providedRatio = dimensions.slideWidth/dimensions.slideHeight;
  const videoPlayerRef = useRef(null);

  useEffect(() => {
    const currentRef = videoPlayerRef.current;
    const handleContextmenu = e => {
      e.preventDefault();
    };
    if (currentRef) {
      currentRef.addEventListener('contextmenu', handleContextmenu);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('contextmenu', handleContextmenu);
      }
    };
  }, [vidUrl]);

  return (
    <div style={{ width: popupWidth || 'unset', height: popupHeight || 'unset' }} ref={videoPlayerRef}>
      {/* <video style={{ border: 'none', outline: 'none' }} controls width="100%">
                <source src={vidUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video> */}
      <Player
        playsInline
        src={vidUrl || 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Training+videos/Pedogog+Tutorial.mp4'}
        fluid={false}
        poster={imgUrl || "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Training+videos/Platform+WalkthroughVideo.png"}
        width={dimensions.slideWidth}
        height={dimensions.slideHeight}
        style={{ border: 'none', outline: 'none', position: 'relative' }}
      >
        <BigPlayButton position="center" />
      </Player>
    </div>
  )
}

export default VideoSlide;