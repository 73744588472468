import React from 'react';

import TextEditor from '../../../Tools/TextEditor/TextEditor';

import Pencil from "../../../components/images/Tools-img/pencil.png";
import Bulb from "../../../components/images/Tools-img/Bulb.png";

import './style.css';


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    // let titleSize = '20px'
    let descriptionSize = '12px';
    let infoSize = '9px';
    // let headingSize = '16px';
    if (isFullScreen) {
        // titleSize = '30px';
        // headingSize = '24px'
        descriptionSize = '16px';
        infoSize = '13.5px';
    }

    return (
        <div style={{ width, height }}>
            <div className='ESI_Slide_illumine_logo'>
                <img src='https://pedgog2.s3.ap-south-1.amazonaws.com/Becoming+future+ready+contributors/04-Enlightened+Self+interest/Illuminelogo.png'
                    style={{ width: '100%', height: '100%' }}
                    alt='illumine-logo'
                />
            </div>
            <div style={{ width: '75%', height: '35%', display: 'flex', alignItems: 'center', margin: 'auto' }}>
                {
                    data.filledTextBoxId.map((item, i) => <div key={i} className={`work-sans EIS_slide_32_filled_textBox`} style={{ fontSize: descriptionSize }}>
                        <TextEditor id={item} initialData={engagementData[item] || null}
                            manageToolData={manageToolData} readOnly={true} hideToolbar={true}
                            wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        />
                    </div>)
                }
            </div>
            <div className='ESI-textbox-container' style={{ height: '65%' }}>
                <div className='EIS_slide_32_container'>
                    <div className='ESI-slide_32_pencil'>
                        <img src={Pencil} style={{ width: '100%', height: '100%' }} alt='pencil' />
                    </div>
                    <div style={{ width: '75%', height: '100%' }}>
                        <div style={{ width: '100%', height: '30%' }}>
                            <div className='work-sans' style={{ fontSize: descriptionSize, fontWeight: 'bold' }}>{data.title}</div>
                            <div className='work-sans' style={{ fontSize: descriptionSize }}>{data.subTitle}</div>
                        </div>
                        <div style={{ width: '100%', height: '70%', display: 'flex' }}>
                            {/* <div style={{ width: '23%', display: 'flex', margin: '0 0.5%', height: '100%' }}>
                                <div className='ESI-slide_32_eg_fill_in scrollable' style={{ fontSize: descriptionSize }}>
                                    {data.egFillInText}
                                </div>
                            </div> */}
                            {
                                data.textBoxId.map((item, i) =>
                                    <div key={i} style={{ width: '23%', display: 'flex', margin: '0 0.5%', height: '100%' }}>
                                        <TextEditor id={item} initialData={engagementData[item] || null}
                                            manageToolData={manageToolData} hideToolbar={true} placeholder={i === 0 ? data.egFillInText : 'Fill here.'}
                                            wrapperClass='text-editor-think-sol-case-2' />
                                    </div>)
                            }
                        </div>
                    </div>
                    <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ width: '90%', marginRight: 'auto', height: '45%', display: 'flex' }}>
                            <div style={{ width: '25%', height: '40%' }}>
                                <img src={Bulb} style={{ width: '100%', height: '100%' }} alt='bulb' />
                            </div>
                            <div className='tct-scrollable' style={{ width: '70%', overflowY: 'auto', fontSize: infoSize }}>
                                {data.rightInfo[0]}
                            </div>
                        </div>
                        <div style={{ width: '90%', marginLeft: 'auto', height: '45%', display: 'flex' }}>
                            <div style={{ width: '25%', height: '40%' }}>
                                <img src={Bulb} style={{ width: '100%', height: '100%' }} alt='bulb' />
                            </div>
                            <div className='tct-scrollable' style={{ width: '70%', overflowY: 'auto', fontSize: infoSize }}>
                                {data.rightInfo[1]}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}