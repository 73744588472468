import React from 'react';


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let descriptionSize = '12px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '16px';
    }

    const immediateTextBoxRight = ['66.5%', '38%', '10%'];
    const furtherTextBoxRight = ['75%', '61%', '46%', '32%', '18%', '4.5%'];

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.immediateTextBoxId.map((textBoxId, i) => <textarea key={textBoxId} id={textBoxId} placeholder='Fill more…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ fontSize: descriptionSize, position: 'absolute', width: '15%', top: '26%', height: '11%', right: immediateTextBoxRight[Math.floor(i % (immediateTextBoxRight.length))] }}
                        value={engagementData[textBoxId] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: textBoxId, content: event.currentTarget.value })
                        }}
                    />)
                }
                {
                    data.furtherTextBoxId.map((textBoxId, i) => <textarea key={textBoxId} id={textBoxId} placeholder='Fill more…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ fontSize: descriptionSize, position: 'absolute', width: '11%', height: '13%', top: '45%', right: furtherTextBoxRight[Math.floor(i % (furtherTextBoxRight.length))] }}
                        value={engagementData[textBoxId] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: textBoxId, content: event.currentTarget.value })
                        }}
                    />)
                }
                {
                    data.farTextBoxId.map((textBoxId, i) => <textarea key={textBoxId} id={textBoxId} placeholder='Fill more…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ fontSize: descriptionSize, position: 'absolute', width: '11%', height: '13%', top: i < (furtherTextBoxRight.length) ? '64%' : '80%', right: furtherTextBoxRight[Math.floor(i % (furtherTextBoxRight.length))] }}
                        value={engagementData[textBoxId] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: textBoxId, content: event.currentTarget.value })
                        }}
                    />)
                }
            </div>
        </div>
    );
}