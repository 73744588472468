import React, { useEffect, useRef } from 'react';
import { Radio } from '@material-ui/core';
import { AddCircle, Check, CheckBoxOutlineBlank } from '@material-ui/icons';

import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, currentUnit }) => {
    // const top = ['25%', '64%', '24%'];
    const right = ['53%', '28%', '3%'];
    const checkBoxRight = ['71.2%', '46.5%', '21.9%'];
    let descriptionSize = '12px';

    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '18px';
    }

    const handleChange = (event, textBoxId,) => {
        manageToolData({
            toolId: data.id, content: {
                ...engagementData[data.id],
                [textBoxId]: event.target.value
            }
        })
    }


    const textBoxEndRef = useRef(null);


    useEffect(async () => {
        if (data.Stage === 4) {
            if (!engagementData || !engagementData[data.id]) {
                let temp = {}
                data.textBoxId.map(textBoxId => temp[textBoxId] = '')
                await manageToolData({
                    toolId: data.id,
                    content: temp
                });
            }
            await isFullScreen && scrollToBottom();
        }
    }, [!isFullScreen, engagementData[data.id], data.id])


    const addTextBox = async () => {
        let len = Object.keys(engagementData && engagementData[data.id] && engagementData[data.id])?.length + 1;
        
        await manageToolData({
            toolId: data.id,
            content: {
                ...engagementData[data.id],
                [`Action_${len}`]: ''
            }
        });
        scrollToBottom();
    }

    const scrollToBottom = () => {
        if (isFullScreen && textBoxEndRef && textBoxEndRef.current)
            textBoxEndRef.current.scrollIntoView({ behavior: "smooth" })
        // console.log(textBoxEndRef)
    };




    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    (data.Stage === 1 || data.Stage === 2) &&
                    <textarea id={data.textBoxId} placeholder={data.textBoxPlaceholder}
                        className={'transparent-textarea tct-scrollable-darkgray axure-handwriting flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ fontSize: descriptionSize, position: 'absolute', width: '60.5%', top: '30%', right: '8%', height: '25%' }}
                        value={engagementData?.[data.textBoxId]}
                        onChange={(event) => manageToolData({
                            toolId: data.textBoxId, content: event.target.value
                        })}
                    />
                }
                {
                    (data.Stage === 2 || data.Stage === 3) && <div className='sma-hfp-design-response-stage-2-prev-filled-goal axure-handwriting tct-scrollable-darkgray' style={{ fontSize: descriptionSize }}>{engagementData?.[data.prevTextBoxId]}</div>
                }
                {
                    data.Stage === 3 && data.textBoxId.map((textBoxId, i) => <textarea key={textBoxId} id={textBoxId} placeholder={data.textBoxPlaceholder + ' ' + (i + 1)}
                        className={'transparent-textarea tct-scrollable-darkgray axure-handwriting flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ fontSize: descriptionSize, position: 'absolute', width: '21%', top: data?.checkBoxId ? '29%' : '25%', right: right[i], height: data?.checkBoxId ? '21%' : '26%' }}
                        value={engagementData[data.id] ? engagementData[data.id][textBoxId] ? engagementData[data.id][textBoxId] : '' : ''}
                        onChange={(event) => handleChange(event, textBoxId)}
                    />)
                }
                {
                    data.Stage === 3 && data.checkBoxId && data.textBoxId.map((textBoxId, i) =>
                        <Radio
                            key={textBoxId + ' radio'}
                            value={textBoxId}
                            color='default'
                            checked={textBoxId === engagementData?.[data.id]?.[data.checkBoxId]}
                            style={{ position: 'absolute', top: '19%', right: checkBoxRight[i] }}
                            onChange={(event) => handleChange(event, data.checkBoxId)}
                            checkedIcon={<Check style={{ color: 'black', fontSize: isFullScreen ? "42px" : "23px" }} />}
                            icon={<CheckBoxOutlineBlank style={{ backgroundColor: 'transparent', color: 'transparent', fontSize: isFullScreen ? "42px" : "23px" }} />}
                        />
                    )
                }
                {
                    data.Stage === 4 && <>
                        <div className='sma-hfp-design-response-stage-2-prev-filled-goal axure-handwriting tct-scrollable-darkgray' style={{ fontSize: descriptionSize, width: '62%', left: '38%' }}>
                            {engagementData?.[data.prevToolId]?.[engagementData?.[data.prevToolId]?.[data.checkBoxId]]}
                        </div>
                        <div className='sma-hfp-design-response-stage-4-textbox-container tct-scrollable-darkgray'>
                            {
                                engagementData?.[data.id] && Object.keys(engagementData?.[data.id]).map((textBoxId, index) =>
                                    <textarea
                                        key={textBoxId}
                                        ref={textBoxEndRef}
                                        placeholder={`${index + 1}. ${data.textBoxPlaceholder}`}
                                        className='transparent-textarea textArea-OnHover-To-Scrollaxure-handwriting tct-scrollable-darkgray sma-hfp-design-response-stage-4-textbox'
                                        style={{ fontSize: descriptionSize }}
                                        value={engagementData?.[data.id]?.[textBoxId]}
                                        onChange={(event) => { handleChange(event, textBoxId) }}
                                    />)
                            }
                        </div>
                        <div className='sma-hfp-design-response-stage-4-add-button'>
                            <AddCircle className='button-click-effect' onClick={addTextBox} />
                        </div>
                    </>
                }
            </div>
        </div >
    );
}