import { createTheme } from "@material-ui/core";
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
let theme = createTheme({
 palette: {
  primary: {
   main: '#BA1F00',
  },
  secondary: {
   main: '#f50057',
  },
 },
 shape: { borderRadius: 4 },
 shadows: ["none"],
 typography: {
  button: {
   textTransform: 'capitalize',
   fontSize: 15
  },
 }
})
export default theme;