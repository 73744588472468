import React from 'react';
// import SearchIcon from "../App_PrepareDashboard/assets/search";
import { showSearchBarRedux } from "../../containers/application/redux_actions";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import './style.css'
function DashBoard_Header({ showSearchBarRedux, app, heading, subheading, description, type }) {
    return (
        <div style={{ display: 'flex', marginBottom: '1rem' }}>
            <div className="-headerTitle" style={{ width: '75%' }}>
                <h6>{heading}</h6>
                <h1>{subheading}</h1>
                <p style={{ margin: '15px 0px' }}>{description}</p>
            </div>
            <div id='filter-container' className="filterStyles">
                <div className='flex-space-around align-items-center width-100'>
                    {type === 'Project' && <Link to='/application/UploadProject' className='text-decoration-none project-upload-button'> <div className="subheading" style={{ color: 'white' }}>Upload</div></Link>}
                    {/* <div className="subheading">Find Module</div> */}
                    {/* <div style={{ cursor: 'pointer', display: 'flex', margin: '0rem 1.25rem' }} onClick={() => showSearchBarRedux()}>
                        <SearchIcon fill={app.showSearchBar ? '#e4001b' : '#8c8c8c'} />
                    </div> */}
                    {/* <div className='cursor-pointer d-flex align-items-center' onClick={() => showSearchBarRedux()}>
                        <SearchIcon fill={app.showSearchBar ? '#e4001b' : '#8c8c8c'} />
                    </div> */}
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    app: state.app,
});


const mapDispatchToProps = dispatch => {
    return {
        showSearchBarRedux: () => dispatch(showSearchBarRedux())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard_Header);