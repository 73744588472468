import React from "react";
import Real_World_Insights from "../../../Live_Session_Tools/Real_World_Insights";

export default ({
  width,
  height,
  engagementData,
  manageToolData,
  isFullScreen,
  data,
  engagementStatus,
  sessionId,
  typPC,
  responses,
  currentEngagement,
  uniqueStudentsName,
}) => {
  // let progessBarValue = (uniqueStudentsName?.length / totalStudents) * 100;
  return (
    <div
      style={{
        width,
        height,
        // backgroundImage: data.bgImg ?`url(${bgImg})`:`url(${bgSlide2})`,
        backgroundImage: data.bgImg
        ? `url(${data?.bgImg?.en})`
        : null,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        position: "relative",
        // border: "1px solid green",
      }}
    >
      <Real_World_Insights
        typPC={typPC}
        data={data}
        engagementData={engagementData}
        manageToolData={manageToolData}
        isFullScreen={isFullScreen}
        responses={responses}
        currentEngagement={currentEngagement}
        uniqueStudentsName={uniqueStudentsName}
        sessionId={sessionId}
        engagementStatus={engagementStatus}
      />
    </div>
  );
};
