import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';

import './style.css';
import Number1 from './assets/Number1.png';
import pencil from './assets/pencil.png';
import broad_arrow from './assets/broad_arrow.png';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let titleSize = '20px'
    let descriptionSize = '11px';
    let headingSize = '14px';
    if (isFullScreen) {
        titleSize = '30px';
        headingSize = '20px'
        descriptionSize = '15px';
    }

    return (
        <div style={{ width, height, padding: '0.5rem' }}>
            <div style={{ height: '15%', width: '100%' }}>
                <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: titleSize, color: '#e64708' }}>
                    {data.title}
                </div>
            </div>
            <div style={{ height: '85%', width: '100%', display: 'flex' }}>
                <div className='scrollable' style={{ width: '20%', height: '100%' }}>
                    {/* <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: titleSize }}>{data.heading}</div> */}
                    <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: headingSize }}>{data.heading}</div>
                    <div style={{ padding: '5%', background: '#e6e6e6', marginTop: '5%' }}>
                        <div className='font-work-sans' style={{ margin: '5% 3%', fontSize: headingSize }}>{data.subHeading}</div>
                        {
                            data.toolDescription.map((item, i) => <div key={i} className='font-work-sans' style={{ fontWeight: 'bold', fontSize: headingSize, margin: '5% 3%' }}>{item}</div>)
                        }
                    </div>
                    <div style={{ width: '95.5%', fontSize: descriptionSize, textAlign: 'left' }}>Brainstorm to generate <br /> user groups</div>
                    <img src='https://pedgog2.s3.ap-south-1.amazonaws.com/Becoming+future+ready+contributors/01-+Think+solutions/Illuminetoollogo.png' style={{ width: '12%', position: 'absolute', bottom: '0' }} alt='illumine-logo' />
                </div>
                <div style={{ width: '75%', height: '100%', padding: '1rem', marginLeft: '2%', background: "#70bdd2", position: 'relative' }}>
                    <img src={Number1} className='value-thinking-number-img' alt='number' />
                    <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: headingSize, margin: '0 2% 2% 2%' }}>{data.box_questions[0]}</div>
                    <div style={{ display: "flex", flexWrap: 'wrap', height: '70%' }}>
                        {
                            data.textBoxId.map((item, i) => <div key={i} style={{ display: 'flex', width: '31%', height: '45%', margin: '1%' }}>
                                <TextEditor id={data.textBoxId[i]} initialData={engagementData[data.textBoxId[i]] || null}
                                    manageToolData={manageToolData} hideToolbar={true} placeholder='&#9679; Fill here...'
                                    wrapperClass='text-editor-think-sol-case-2' />
                            </div>)
                        }
                        <img src={pencil} className='value-thinking-pencil-img' alt='pencil' />
                        <img src={broad_arrow} className='value-thinking-broad-arrow-img' alt='broad-arrow' />
                        <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: headingSize, margin: '7% 2%' }}>{data.box_questions[1]}</div>
                    </div>

                </div>
            </div>
        </div>
    );
}