import { signOut } from "../auth/redux_actions";
import store from "../../Redux_Store";
import Config from "../../config"
import { initialiseBlogFirebase, initialiseHowToFirebase, initialiseReadings } from "../../utilities/firebase/fb_firestore";

const config = {
  apiurl: Config.API_URL,
};

export const callAPI = async (token, method, url, body) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method,
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect: 'follow',
  };
  return await fetch(url, requestOptions).then(response => response.json())
}

export function initialiseBlog(_b) {
  //  a = b.slice(0, 9)
  for (let index = 0; index < (_b.length / 10); index++) {
    const a = _b.slice(index * 10, (index + 1) * 10 - 1)
    initialiseBlogFirebase(a, (doc, d) => store.dispatch({
      type: "GET_BLOG_DATA_MODULES",
      payload: { id: doc.id, data: d }
    }),
      (doc, b) => store.dispatch({
        type: "UPDATE_BLOG_DATA_MEDIA",
        payload: { mid: doc.id, id: b.id, media: b.data() }
      }))
  }

  initialiseHowToFirebase((doc, d) =>
    store.dispatch({ type: "GET_BLOG_HOWTO_MODULES", payload: { id: doc.id, data: d } }),
    (doc, b) => store.dispatch({ type: "UPDATE_BLOG_HOWTO_MEDIA", payload: { mid: doc.id, id: b.id, media: b.data() } }
    ))
}

export function getFormValues({ sessionId, token }) {
  return (dispatch) => {
    return fetch(config.apiurl + "learningtool/getFormValues/" + sessionId, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((results) => {
        console.log("get form values ==> ", results);
        // const first = _.head(results);

        // const { fieldData } = first;
        results.map((result) => {
          Object.entries(result.fieldData).forEach((field) => {
            const toolId = field[0];
            const text = field[1];

            const blocks = text.split("\n");
            // console.log("blocks ==> ", blocks);
            const _blocks = blocks.map((b) => {
              return { text: b, type: "unordered-list-item" };
            });

            const payload = {
              content: { blocks: _blocks, entityMap: {} },
              toolId: toolId,
            };
            console.log("blocks ==> ", payload);
            dispatch(getFormValuesSuccess(payload));
          });

          // dispatch(getFormValuesSuccess(result.fieldData));
        });
        // return dispatch(getFormValuesSuccess(fieldData));
      })
      .catch((err) => { });
  };
}

function getFormValuesSuccess(data, token) {
  return { type: "MANAGE_TOOL_DATA", payload: data };
  // return { type: "GETFORMVALUES_SUCCESS", payload: data };
}

export function getPrepareModulesAttempt(user) {
  return async (dispatch) => {
    dispatch({ type: "GETPREPAREMODULE_ATTEMPT", payload: true });
    return await callAPI(user.token, 'GET', `${config.apiurl}subscribe/getModuleForUser/${user.userid}`).then((userModules) => {
      return dispatch(
        getpreparemoduleAttemptSuccess(userModules, user.token)
      );
    })
      .catch((err) => dispatch(getpreparemoduleAttemptFailure(err)));
    // return fetch(config.apiurl + "subscribe/getModuleForUser/" + user.userid, {
    //   method: "GET",
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: "bearer " + user.token,
    //     accept: "application/json",
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((userModules) => {
    //     return dispatch(
    //       getpreparemoduleAttemptSuccess(userModules, user.token)
    //     );
    //   })
    //   .catch((err) => dispatch(getpreparemoduleAttemptFailure(err)));
  };
}


function getpreparemoduleAttemptSuccess(data, token) {
  return { type: "GETPREPAREMODULE_SUCCEEDED", data };
}

function getpreparemoduleAttemptFailure(err) {
  return { type: "GETPREPAREMODULE_FAILURE" };
}

export function getContinueConductModules(user) {
  return (dispatch) => {
    dispatch({ type: "GETCONTINUECONDUCTMODULE_ATTEMPT", payload: true });
    return fetch(config.apiurl + "session/continue/" + user.userid, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + user.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((continueModules) => {
        return dispatch(
          getContinueConductModuleAttemptSuccess(continueModules, user.token)
        );
      })
      .catch((err) => dispatch(getContinueConductModuleAttemptFailure(err)));
  };
}

function getContinueConductModuleAttemptSuccess(data, token) {
  return { type: "GETCONTINUECONDUCTMODULE_SUCCEEDED", payload: data };
}

function getContinueConductModuleAttemptFailure(err) {
  return { type: "GETCONTINUECONDUCTMODULE_FAILURE" };
}
export function getModuleDetails(val) {
  return async (dispatch) => {
    dispatch({ type: "GETMODULE_ATTEMPT", payload: true });
    return await callAPI(val.token, 'GET', `${config.apiurl}appmodule/${val.id}`)
      .then((json) => {
        return dispatch(getModuleDetailsSuccess(val.id, json));
      })
      .catch((err) => dispatch(getModuleDetailsFailure(err)))
  }
}
export async function getSingleModule(val) {
  return await callAPI(val.token, 'GET', `${config.apiurl}appmodule/${val.id}`)
    .then((json) => {
      return store.dispatch({ type: "GETMODULE_SUCCEEDED", payload: { id: val.id, data: json } });
    })
    .catch((err) => store.dispatch({ type: "GETPREPAREMODULE_FAILURE" }))
}



function getModuleDetailsSuccess(id, data) {
  return { type: "GETMODULE_SUCCEEDED", payload: { id, data } };
}

function getModuleDetailsFailure(err) {
  return { type: "GETPREPAREMODULE_FAILURE" };
}

export function getRoadmapforModule(val) {
  return (dispatch) => {
    dispatch({ type: "GETROADMAPFORMODULE_ATTEMPT", payload: true });
    return fetch(config.apiurl + "roadmap/" + val.id, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + val.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch(getRoadmapforModuleSuccess(val.id, json));
      })
      .catch((err) => dispatch(getRoadmapforModuleFailure(err)));
  };
}

function getRoadmapforModuleSuccess(id, data) {
  return { type: "GETROADMAPFORMODULE_SUCCEEDED", payload: { id, data } };
}

function getRoadmapforModuleFailure(err) {
  console.log(err);
  return { type: "GETROADMAPFORMODULE_FAILURE" };
}

// export function getTagsAttempt(user) {
//   return async (dispatch) => {
//     dispatch({ type: "GETTAG_ATTEMPT", payload: true });
//     return await callAPI(user.token, "GET", `${config.apiurl}tag?allowedHost=${user.allowedHost}`).then((tags) => {
//       return dispatch(gettagAttemptSuccess(tags, user.token));
//     }).catch((err) => dispatch(gettagAttemptFailure(err)));
//   };
// }

export function getTagsAttempt(user) {
  return async (dispatch) => {
    dispatch({ type: "GETTAG_ATTEMPT", payload: true });
    // return await callAPI(user.token, "GET", `http://localhost:3001/v1/tag/getUserProgramsbyAllowedHost?allowedHost=${user.allowedHost}`).then((tags) => {
    return await callAPI(user.token, "GET", `${config.apiurl}tag/getUserProgramsbyAllowedHost?allowedHost=${user.allowedHost}`).then((tags) => {
      return dispatch(gettagAttemptSuccess(tags, user.token));
    }).catch((err) => dispatch(gettagAttemptFailure(err)));
  };
}
export async function getAnalyticsForProgram({ token, userId }) {
  // return await callAPI(token, "GET", `http://localhost:3001/v1/dashboard/${userId}`).then((res) => {
  return await callAPI(token, "GET", `${config.apiurl}dashboard/${userId}`).then((res) => {
    if (res.code)
      store.dispatch({ type: "GETANALYTICS_FAILED" })
    else store.dispatch({ type: "GETANALYTICS_RECEIVED", payload: res });
    return res;
  })

}

export function getPrograms(user) {
  return async (dispatch) => {
    dispatch({ type: "GETPROGRAM_ATTEMPT", payload: true });
    return await callAPI(user.token, "GET", `${config.apiurl}accesscode/getAllPrograms`).then((data) => {
      dispatch({ type: 'GETPROGRAM_SUCCEEDEDNEW', data: data?.programList });
      return dispatch({ type: 'GETPROGRAM_SUCCEEDED', data: data?.programList });
    }).catch((err) => dispatch({ type: 'GETPROGRAM_FAILED' }));
  };
}


function gettagAttemptSuccess(data, token) {
  return { type: "GETTAG_SUCCEEDED", data };
}

function gettagAttemptFailure(err) {
  return { type: "GETTAG_FAILURE" };
}

export function getUserAttempt(data) {
  return (dispatch) => {
    dispatch({ type: "GETUSER_ATTEMPT", payload: true });
    return fetch(config.apiurl + "users/" + data.userid, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + data.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((sessions) => {
        if (sessions.code === 401) return dispatch(signOut());
        return dispatch(getUserSuccess(data.userid, sessions));
      })
      .catch((err) => {
        console.log("get User  => error ===");
        console.log(err);
        dispatch(getUserFailure(err));
      });
  };
}

function getUserSuccess(id, data) {
  return { type: "GETUSER_SUCCEEDED", payload: { id, data } };
}
function getUserFailure(err) {
  console.log(err);
  return { type: "GETUSER_FAILURE" };
}
export function getExploreModulesAttempt(user) {
  return (dispatch) => {
    dispatch({ type: "GETEXPLOREMODULE_ATTEMPT", payload: true });
    return fetch(config.apiurl + "appmodule", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + user.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((allModules) => {
        return dispatch(getexploremoduleAttemptSuccess(allModules, user.token));
      })
      .catch((err) => dispatch(getexploremoduleAttemptFailure(err)));
  };
}

function getexploremoduleAttemptSuccess(data, token) {
  return { type: "GETEXPLOREMODULE_SUCCEEDED", data };
}

function getexploremoduleAttemptFailure(err) {
  return { type: "GETEXPLOREMODULE_FAILURE" };
}

export function updateUsedCountAttempt(user) {
  return (dispatch) => {
    dispatch({ type: "UPDATEUSEDCOUNT_ATTEMPT", payload: true });
    return fetch(
      config.apiurl + "accesscode/updateUsedCount/" + user.accesscode,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + user.token,
          accept: "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((userModules) => {
        return dispatch(updateUsedCountSuccess(userModules, user.token));
      })
      .catch((err) => dispatch(updateUsedCountFailure(err)));
  };
}

function updateUsedCountSuccess(data, token) {
  return { type: "UPDATEUSEDCOUNT_SUCCEEDED", data };
}

function updateUsedCountFailure(err) {
  return { type: "UPDATEUSEDCOUNT_FAILURE" };
}

export function updatePercentage(data) {
  return (dispatch) => {
    dispatch({ type: "UPDATEPERCENTAGE_ATTEMPT", payload: true });
    fetch(config.apiurl + "progress/createProgress", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + data.user_token,
        accept: "application/json",
      },
      body: JSON.stringify({
        userId: data.user_uid,
        moduleId: data.moduleId,
        progressType: data.typ,
        roadmapId: data.rrid,
        learningToolsId: data.lid,
        activeContent: "",
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        updatePercentageSuccess(dispatch, response);
      })
      .catch((err) => {
        console.log("error ===");
        console.log(err);
      });
  };
}

export function updatePercentageOLD(data) {
  return (dispatch) => {
    dispatch({ type: "UPDATEPERCENTAGE_ATTEMPT", payload: true });
    return fetch(config.apiurl + "progress/createProgress", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + data.user_token,
        accept: "application/json",
      },
      body: JSON.stringify({
        userId: data.user_uid,
        moduleId: data.moduleId,
        progressType: data.typ,
        roadmapId: data.rrid,
        learningToolsId: data.lid,
      }),
    })
      .then((response) => response.json())
      .then((resp) => {
        updatePercentageSuccess(dispatch, resp);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

const updatePercentageSuccess = (dispatch, data) => {
  dispatch({ type: "UPDATEPERCENTAGE_SUCCEEDED", payload: data });
  //successCallBack(data);
};

export async function addFormValues(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "ADDFORMVALUES_ATTEMPT", payload: true });
  var raw = JSON.stringify({
    sessionId: data.sessionId,
    fieldData: data.fieldData,
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + "learningtool/addFormValues", requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "ADDFORMVALUES_SUCCEEDED", payload: response_1 });
    return response_1;
  } catch (error) {
    store.dispatch({ type: "ADDFORMVALUES_FAILURE" });
    return console.log('error', error);
  }
}
export async function generateDynamicTakeways(data) {
  return await callAPI(data.user_token, 'POST', config.apiurl + `takeaway/${data.sessionId}?learningTool=${data.learningToolId}&activeContent=${data.activeContentId}&access=private`, undefined)
}

export async function pollAfterIdeaBoard(data, method) {
  return await callAPI(data.user_token, method, config.apiurl + `ideaboard/${data.sessionId}/${data.engagementId}`, undefined)
}

export const resetLoginPage = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_LOGIN_PAGE" });
  };
};

export const setErrMsg = (el) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOGIN_ERROR_MSG", payload: el });
  };
};

export const preparePage = () => {
  return (dispatch) => {
    dispatch({ type: "PREPARE_PAGE" });
  };
};

export async function createSession(data, successCallBack) {
  store.dispatch({ type: "CREATESESSION_ATTEMPT", payload: true })
  return await callAPI(
    data.user_token,
    "POST",
    `${config.apiurl}session`,
    {
      sessionName: data.sessionName,
      moduleId: data.moduleId,
      coachId: data.coach_id,
      noOfStudents: "0",
      sessionType: data.sessionType,
      batchId: data.batchId,
      facilitators: data.facilitators,
      meta: data.meta
    })
    .then((response) => {
      // store.dispatch({ type: "CREATESESSION_SUCCEEDED", payload: response });
      if (successCallBack)
        successCallBack(response)
      return response
    })
    .catch((err) => {
      console.log("error ===");
      console.log(err);
    });
  //  return (dispatch) => {
  // dispatch();
  // fetch("http://localhost:5001/v1/session", {
  // fetch(config.apiurl + "session", {
  //  method: "POST",
  //  headers: {
  //   "content-type": "application/json",
  //   Authorization: "bearer " + data.user_token,
  //   accept: "application/json",
  //  },
  //  body: JSON.stringify({
  //   sessionName: data.sessionName,
  //   moduleId: data.moduleId,
  //   coachId: data.coach_id,
  //   noOfStudents: "0",
  //   sessionType: data.sessionType,
  //   batchId: data.batchId,
  //   facilitators: data.facilitators
  //  }),
  // })
  //  .then((response) => response.json())
  //  .then((response) => {
  //   dispatch({ type: "CREATESESSION_SUCCEEDED", payload: response });
  //   if (successCallBack)
  //    successCallBack(response)
  //   // return response
  //  })
  //  .catch((err) => {
  //   console.log("error ===");
  //   console.log(err);
  //  });
  // return
  //  };
}

export function createExperience(data) {
  return (dispatch) => {
    dispatch({ type: "CREATEEXPERIENCE_ATTEMPT", payload: true });
    fetch(config.apiurl + "experiences/createexperiences", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + data.token,
        accept: "application/json",
      },
      body: JSON.stringify({
        description: data.description,
        moduleId: data.moduleId,
        shareById: data.userId,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        createExperienceSuccess(dispatch, response);
      })
      .catch((err) => {
        console.log("error ===");
        console.log(err);
      });
  };
}

const createExperienceSuccess = (dispatch, data) => {
  dispatch({ type: "CREATEEXPERIENCE_SUCCEEDED", payload: data });
};

export function getSessionForCoachAttempt(user) {
  return (dispatch) => {
    dispatch({ type: "GETSESSIONFORCOACH_ATTEMPT", payload: true });
    return fetch(
      config.apiurl + "session/getSessionForCoachId/" + user.userid,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + user.token,
          accept: "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((sessions) => {
        return dispatch(getSessionForCoachSuccess(sessions, user.token));
      })
      .catch((err) => {
        console.log("get session for coach  => error ===");
        console.log(err);
      });
  };
}

function getSessionForCoachSuccess(data, token) {
  return { type: "GETSESSIONFORCOACH_SUCCEEDED", data };
}

export function getSessionForSessionIdAttempt(data) {
  return (dispatch) => {
    dispatch({ type: "GETSESSIONFORSESSIONID_ATTEMPT", payload: true });
    return fetch(config.apiurl + "session/" + data.id, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + data.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((sessions) => {
        return dispatch(getSessionForSessionIdSuccess(sessions));
      })
      .catch((err) => {
        console.log("get session for coach  => error ===");
        console.log(err);
      });
  };
}

function getSessionForSessionIdSuccess(data) {
  return { type: "GETSESSIONFORSESSIONID_SUCCEEDED", data };
}

export function getAssessmentForSessionIdAttempt(data) {
  return (dispatch) => {
    dispatch({ type: "GETASSESSMENTFORSESSIONID_ATTEMPT", payload: true });
    return fetch(
      config.apiurl + "assessment/getAssessmentForSessionId/" + data.id,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + data.token,
          accept: "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((sessions) => {
        return dispatch(getAssessmentForSessionIdSuccess(sessions));
      })
      .catch((err) => {
        console.log("get assessment for session  => error ===");
        console.log(err);
      });
  };
}

function getAssessmentForSessionIdSuccess(data) {
  return { type: "GETASSESSMENTFORSESSIONID_SUCCEEDED", data };
}

export function updateFlag(data) {
  if (data) {
    fetch(config.apiurl + "users/updateUserTooltipData/" + data.user_Id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + data.user_token,
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data1) => {
        console.log(data1);
      });
  }
}

// Piyush Actions Below
export const p_getModuleDetails = ({ id, token }) => {
  return fetch(config.apiurl + "roadmap/" + id, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: "bearer " + token,
      accept: "application/json",
    },
  });
};

export function getRoadmapDetails(val) {
  return (dispatch) => {
    dispatch({ type: "GETROADMAP_ATTEMPT", payload: true });
    return fetch(config.apiurl + "roadmapforid/" + val.id, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + val.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch({
          type: "GETROADMAP_SUCCEEDED",
          payload: { id: val.id, data: json },
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(val.id);
        dispatch({ type: "GETROADMAP_FAILURE" });
      });
  };
}

export function getLearningToolDetails(val) {
  return (dispatch) => {
    dispatch({ type: "GETLEARNINGTOOL_ATTEMPT", payload: true });
    return fetch(config.apiurl + "learningtool/" + val.id, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + val.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch({
          type: "GETLEARNINGTOOL_SUCCEEDED",
          payload: { id: val.id, data: json },
        });
      })
      .catch((err) => dispatch({ type: "GETLEARNINGTOOL_FAILURE" }));
  };
}

export async function getOTP(data) {
  //  return (dispatch) => {
  if (!data.testType) data.testType = "pre";
  store.dispatch({ type: "GETOTP_ATTEMPT", payload: true });
  return await callAPI(
    data.user_token,
    "POST",
    `${config.apiurl}session/getOTP`,
    {
      sessionId: data.sessionId,
      testType: data.testType,
      coachId: data.coachId,
    }
  ).then((response) => {
    store.dispatch({
      type: "GETOTP_SUCCEEDED",
      payload: { data: response },
    });
  })
    .catch((err) => {
      console.log("error ===", err);
    });
  // fetch(config.apiurl + "session/getOTP", {
  //  method: "POST",
  //  headers: {
  //   "content-type": "application/json",
  //   Authorization: "bearer " + data.user_token,
  //   accept: "application/json",
  //  },
  //  body: JSON.stringify({
  //   sessionId: data.sessionId,
  //   testType: data.testType,
  //   coachId: data.coachId,
  //  }),
  // })
  //  .then((response) => response.json())
  //  .then((response) => {
  //   return dispatch({
  //    type: "GETOTP_SUCCEEDED",
  //    payload: { data: response },
  //   });
  //  })
  //  .catch((err) => {
  //   console.log("error ===", err);
  //  });
  //  };
}

export function getAllGenericModules(val) {
  return (dispatch) => {
    dispatch({ type: "GETALLGENERICMODULES_ATTEMPT", payload: true });
    return fetch(config.apiurl + "appmodule/getAllGenericModules", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + val.user_token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch({
          type: "GETALLGENERICMODULES_SUCCEEDED",
          payload: { data: json },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "GETALLGENERICMODULES_FAILURE" });
      });
  };
}

export function showSearchBarRedux() {
  return (dispatch) => {
    dispatch({
      type: "SHOW_SEARCH_BAR",
    });
  };
}

// export function handleSearchText(val) {
//   return (dispatch) => {
//     dispatch({
//       type: "HANDLE_SEARCH_TEXT",
//       payload: { data: val }
//     })
//   }
// };

export function getGenericModuleUnits(val) {
  return async (dispatch) => {
    dispatch({ type: "GETGENERICMODULEUNITS_ATTEMPT", payload: true });
    try {
      const res = await fetch(
        config.apiurl + "appmodule/getGenericModuleById/" + val.genericModuleId,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + val.user_token,
            accept: "application/json",
          },
        }
      );
      const json = await res.json();
      return dispatch({
        type: "GETGENERICMODULEUNITS_SUCCEEDED",
        payload: { data: json[0] },
      });
    } catch (err) {
      console.log(err);
      dispatch({ type: "GETGENERICMODULEUNITS_FAILURE" });
    }
  };
}

export function manageToolData(data) {
  return (dispatch) => {
    dispatch({
      type: "MANAGE_TOOL_DATA",
      payload: { toolId: data.toolId, content: data.content },
    });
  };
}

export async function getDataFromFile(data) {
  store.dispatch({ type: "GET_DATA_FROM_FILE_ATTEMPT", payload: true });
  try {
    const res = await fetch(data.url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    });
    const response = await res.json();
    store.dispatch({ type: "GET_DATA_FROM_FILE_SUCCESS", payload: response });
    return response;
  } catch (err) {
    store.dispatch({ type: "GET_DATA_FROM_FILE_ERROR", payload: true });
    console.log("get User  => error === ", err);
  }
}

export async function uploadProject(data) {
  store.dispatch({ type: "UPLOAD_PROJECT_ATTEMPT", payload: true });
  try {
    const res = await fetch(config.apiurl + "project/", {
      method: "POST",
      headers: {
        Authorization: "bearer " + data.user_token,
        accept: "application/json",
      },
      body: data.formdata,
    });
    const response = await res.json();

    if (response.code) throw Error(response.message);
    store.dispatch({ type: "UPLOAD_PROJECT_SUCCESS", payload: response });
    return response;
  } catch (err) {
    store.dispatch({ type: "UPLOAD_PROJECT_ERROR", payload: true });
    console.log("error === ", err);
  }
}

export async function getProject(data) {
  store.dispatch({ type: "GET_PROJECT_ATTEMPT", payload: true });
  try {
    const res = await fetch(config.apiurl + "project/", {
      method: "GET",
      headers: {
        Authorization: "bearer " + data.user_token,
        accept: "application/json",
      },
    });
    const response = await res.json();
    store.dispatch({ type: "GET_PROJECT_SUCCESS", payload: response });
  } catch (err) {
    store.dispatch({ type: "GET_PROJECT_ERROR", payload: true });
    console.log("error === ", err);
  }
}

export function makeOffline(offline) {
  store.dispatch({ type: "MAKE_OFFLINE", payload: offline });
}

export const getEngagement = async (token, engagementId) => {
  try {
    store.dispatch({ type: "GETENGAGEMENT_ATTEMPT" });
    const response = await fetch(`${Config.API_URL}student/engagement/${engagementId}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + token,
          accept: "application/json",
        },
      }
    );
    const response_1 = await response.json();
    if (response_1.code === 401) store.dispatch(signOut());
    else if (response.code) throw Error(response.message);
    store.dispatch({ type: "GETENGAGEMENT_SUCCESS", payload: response_1 });
  } catch (err) {
    store.dispatch({ type: "GETENGAGEMENT_FAILURE" });
    console.log("error ===", err);
  }
}

export function getDashboardConfig(token, userId) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(config.apiurl + "dashboard/" + userId, requestOptions)
    .then(response => response.json())
    .catch(error => console.log('error', error));
}


export async function getOrganisationByParentId(data) {
  store.dispatch({ type: "GET_ORGANISATIONBYPARENTID_ATTEMPT", payload: true });
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  try {
    const response = await fetch(config.apiurl + "organization/parent/" + data.parentOrgId, requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "GET_ORGANISATIONBYPARENTID_SUCCESS", payload: response_1 });
    return response_1;
  } catch (error) {
    store.dispatch({ type: "GET_ORGANISATIONBYPARENTID_FAILED", payload: true }); console.log('error', error);
  }

}
export async function updateUserByUserId(data, val) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "UPDATE_USER_BY_ID_ATTEMPT", payload: true });
  console.log(val)
  var raw = JSON.stringify({
    ...val
  });

  var requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + "users/" + data.userId, requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "UPDATE_USER_BY_ID_SUCCESS", payload: response_1 });
  } catch (error) {
    store.dispatch({ type: "UPDATE_USER_BY_ID_FAILURE" });
    return console.log('error', error);
  }
}


export function studentResponseDelete(data) {
  store.dispatch({ type: "STUDENT_RESPONSE_DELETE", payload: data })
}

export const toggleLanguage = () => {
  store.dispatch({ type: "CHANGE_LANGUAGE" })
}

export const logEvent = (token, coachId, eventName, eventData) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    coachId,
    eventName,
    eventData
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  // fetch("http://localhost:5001/v1/teacherEvent/", requestOptions)
  fetch(config.apiurl + "teacherEvent/", requestOptions)
    .then(response => response.json())
    .catch(error => console.log('error', error));
}


export async function createNewBatch(user_token, val) {
  return callAPI(user_token, "POST", `${config.apiurl}batch/`, { ...val })
    // return callAPI(user_token, "POST", `http://localhost:3001/v1/batch/`, { ...val })
    .then((response) => {
      store.dispatch({ type: "CREATE_BATCH_SUCCESS", payload: response })
      return response
    })
    .catch((err) => err)
}


export async function createOrganization(data, val) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "CREATE_ORGANISATION_ATTEMPT" });
  var raw = JSON.stringify({
    ...val,
    coachId: data.userId
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    console.log('hi')
    const response = await fetch(config.apiurl + "organization", requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "CREATE_ORGANISATION_SUCCESS", payload: response_1 });
    return response_1;
  } catch (error) {
    store.dispatch({ type: "CREATE_ORGANISATION_FAILURE" });
    return console.log('error', error);
  }
}


export async function createTakeaway(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow'
  };
  try {
    store.dispatch({ type: "GET_TAKEAWAY_ATTEMPT", payload: true });
    const response = await fetch(`${config.apiurl}takeaway/${data.sessionId}?access=${data.access}&activeContent=${data.activeContentId}&learningTool=${data.learningToolId}`, requestOptions);
    // const response = await fetch("http://localhost:8000/v1/takeaway/" + data.sessionId + `?access=${data.access}&activeContent=${data.activeContentId}&learningTool=${data.learningToolId}`, requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "GET_TAKEAWAY_SUCCESS", payload: response_1 });
    return response_1
  } catch (error) {
    store.dispatch({ type: "GET_TAKEAWAY_FAILED", payload: true }); console.log('error', error);
    return error
  }
}

export async function startPostSession(data) {
  await callAPI(data.user_token, "GET", `${config.apiurl}session/postSession/${data.sessionId}`, undefined)
    .then(response => {
      store.dispatch({ type: "START_POST_SESSION_SUCCESS" });
      return response
    })
    .catch(err => {
      store.dispatch({ type: "START_POST_SESSION_FAILURE" });
      console.log(err)
    })
}
export async function createAction(data) {
  // await callAPI(data.user_token, "POST", `http://localhost:3002/v1/student/action/createAction`, data.body)
  await callAPI(data.user_token, "POST", `${config.apiurl}student/action/createAction`, data.body)
    .then((response) => {
      return response
    })
    .catch(err => console.log(err))
}
export async function endSession(data) {
  return await callAPI(data.user_token, "POST", `${config.apiurl}session/${data.sessionId}/end`, undefined)
    .then(response => {
      store.dispatch({ type: "END_SESSION_SUCCESS" });
      store.dispatch({ type: "ASSESSMENT_STARTED", payload: null });
      return response
    })
    .catch(err => {
      store.dispatch({ type: "END_SESSION_FAILURE" });
      console.log(err)
    })
}

export function getAssessmentReport({ user_token, sessionId }) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${user_token}`);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(`${config.apiurl}analytics/assessment/${sessionId}`, requestOptions)
    .then(response => response.json())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
}

export function getRatingReport({ user_token, sessionId }) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${user_token}`);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(`${config.apiurl}analytics/rating/${sessionId}`, requestOptions)
    .then(response => response.json())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
}


export function getAttendence({ user_token, sessionId }) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${user_token}`);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  // fetch(`http://localhost:5001/v1/session/attendence/${sessionId}`, requestOptions)
  fetch(`${config.apiurl}session/attendence/${sessionId}`, requestOptions)
    .then(response => response.json())
    .then(result => {
      if (result.code)
        store.dispatch(signOut())
      else
        store.dispatch({ type: "STUDENT_ATTEND", payload: result })
    })
}


export async function getBatchDetails(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "GET_BATCH_ATTEMPT" });
  store.dispatch({ type: "CLEAR_SESSION_OTP" })

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    let response;
    await fetch(config.apiurl + "batch/" + data.user_id, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        store.dispatch({ type: "GET_BATCH_SUCCESS", payload: res })
        response = res
      })
    return response;
  } catch (error) {
    store.dispatch({ type: "GET_BATCH_FAILURE" });
    return console.log('error', error);
  }
}

export async function getBatchStudents(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "GET_BATCH_STUDENTS_ATTEMPT" });

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + `batch/${data.batchId}/student`, requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "GET_BATCH_STUDENTS_SUCCESS", payload: response_1 });
    return response_1;
  } catch (error) {
    store.dispatch({ type: "GET_BATCH_STUDENTS_FAILURE" });
    return console.log('error', error);
  }
}

export async function markBatchAttendance(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "MARK_BATCH_ATTENDANCE_ATTEMPT" });

  var raw = JSON.stringify({
    sessionId: data.sessionId,
    students: data.students
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + `batch/${data.batchId}/markAttendance`, requestOptions);
    if (response.status === 200)
      store.dispatch({ type: "MARK_BATCH_ATTENDANCE_SUCCESS" });
    else
      throw response
  } catch (error) {
    store.dispatch({ type: "MARK_BATCH_ATTENDANCE_FAILURE" });
    return console.log('error', error);
  }
}

export function resumeSession(data) {
  if (data)
    store.dispatch({ type: "RESUME_SESSION_SUCCESS", payload: data });
}

export async function updateBatchStudentList(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "UPDATE_BATCH_STUDENT_LIST_ATTEMPT" });
  console.log(data)
  var raw = JSON.stringify({
    students: data.students
  });

  var requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + `batch/${data.batchId}/student`, requestOptions);
    const response_1 = await response.json();
    console.log(response_1)
    store.dispatch({ type: "UPDATE_BATCH_STUDENT_LIST_SUCCESS" });
    data.successCallBack(response_1)
  } catch (error) {
    store.dispatch({ type: "UPDATE_BATCH_STUDENT_LIST_FAILURE" });
    data.failureCallBack(error)
  }
}


export async function deleteBatch(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "DELETE_BATCH_ATTEMPT" });

  var requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + `batch/${data.batchId}`, requestOptions);
    if (response.status === 200)
      store.dispatch({ type: "DELETE_BATCH_SUCCESS" });
    else
      throw response
  } catch (error) {
    store.dispatch({ type: "DELETE_BATCH_FAILURE" });
    return console.log('error', error);
  }
}

export async function getFormValuesForBatchAndModule(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "GET_FORM_DATA_BATCH_ATTEMPT" });

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + `learningtool/getFormValuesForBatchAndModule/${data.batchId}/${data.moduleId}`, requestOptions);
    const response_1 = await response.json();
    if (response_1.code)
      throw response_1
    store.dispatch({ type: "GET_FORM_DATA_BATCH_SUCCESS", payload: response_1 });
    store.dispatch({ type: "MANAGE_TOOL_DATA", payload: { prevSession: true, data: response_1 } });
    return response_1;
  } catch (error) {
    store.dispatch({ type: "GET_FORM_DATA_BATCH_FAILURE" });
    return console.log('error', error);
  }
}

export function storeSelectedBatch(data) {
  if (data)
    store.dispatch({ type: "STORE_SELECTED_BATCH", payload: data });
}

export const sendEventAPI = async (token, body) => {
  return await callAPI(token, "POST", `${config.apiurl}student/event/`, body).catch(error => {
    // return await callAPI(token, "POST", `http://localhost:5000/v1/student/event/`, body).catch(error => {
    console.log(error)
  })
}


export async function getBatchForCoachAndModule({ user_token, coachId, moduleId }) {
  store.dispatch({ type: "GET_BATCH_ATTEMPT" });
  store.dispatch({ type: "CLEAR_SESSION_OTP" })
  return await callAPI(user_token, "GET", `${config.apiurl}batch/getSessionForCoachAndModule/${coachId}/${moduleId}`).then((result) => {
    // return await callAPI(user_token, "GET", `https://preprodapi.pedgog.in/v1/batch/getSessionForCoachAndModule/${coachId}/${moduleId}`).then((result) => {
    // return await callAPI(user_token, "GET", `http://localhost:3001/v1/batch/getSessionForCoachAndModule/${coachId}/${moduleId}`).then((result) => {
    if (result?.code) {
      store.dispatch({ type: "GET_BATCH_FAILURE", payload: result });
      return;
    }
    store.dispatch({ type: "GET_BATCH_SUCCESS", payload: result });
    return result
  })
  store.dispatch({ type: "GET_BATCH_ATTEMPT" });
  store.dispatch({ type: "CLEAR_SESSION_OTP" })
  return await callAPI(user_token, "GET", `${config.apiurl}batch/getBatchForCoachAndModule/${coachId}/${moduleId}`).then((result) => {
    if (result?.code) {
      store.dispatch({ type: "GET_BATCH_FAILURE", payload: result });
      return;
    }
    store.dispatch({ type: "GET_BATCH_SUCCESS", payload: result });
    return result
  })
}

export async function getAllSessionsByBatch({ user_token, batchId }) {
  return await callAPI(user_token, "GET", `${config.apiurl}session/getAllSessionsByBatch/${batchId}`)
    .then((result) => {
      if (!result.code && result?.data?.length) {
        store.dispatch({ type: "SESSIONS_FOR_BATCH", payload: result });
        return result;
      }
    }).catch((error) => error)
}

export async function getAsessmentAgainstBatch({ user_token, batchId }) {
  return await callAPI(user_token, "GET", `${config.apiurl}batch/getTestModules/${batchId}`).then((result) => result)
}

// export const getUser = async (token, organizationid, { division, zone, phone, college_code, coTrainerAccessCode, delhi_district }) => {
//   console.log("coTrainerAccessCode", coTrainerAccessCode)
//   let url = `${config.apiurl}users?organizationid=${organizationid}?`
//   if (division)
//     url += `division=${encodeURIComponent(division)}?`
//   if (zone)
//     url += `zone=${encodeURIComponent(zone)}?`
//   if (college_code)
//     url += `college_code=${encodeURIComponent(college_code)}?`
//   if (phone)
//     url += `phone=${encodeURIComponent(phone)}`
//   if (coTrainerAccessCode)
//     url += `accesscode=${encodeURIComponent(coTrainerAccessCode)}?`
//   if (delhi_district)
//     url += `delhi_district=${encodeURIComponent(delhi_district)}?`
//   return await callAPI(token, "GET", url)
//     .then(res => {
//       let newRes = res
//       newRes.splice(0, 0, { fullname: 'Conducting Session Alone', id: 1 })
//       store.dispatch({ type: 'GOT_COTRAINERS', payload: newRes })
//       return res
//     })
//     .catch(error => {
//       console.log(error)
//       return error
//     })
// }


export const getUser = async (token, organizationid, { phone, coTrainerAccessCode }) => {
  let url = `${config.apiurl}users?organizationid=${encodeURIComponent(organizationid)}`
  if (phone)
    url += `&phone=${encodeURIComponent(phone)}`
  if (coTrainerAccessCode)
    url += `&accesscode=${encodeURIComponent(coTrainerAccessCode)}`

  return await callAPI(token, "GET", url)
    .then(res => {
      let newRes = []
      if (res.length > 0) {
        newRes = res
        newRes.splice(0, 0, { fullname: 'Conducting Session Alone', id: 1 })
        store.dispatch({ type: 'GOT_COTRAINERS', payload: newRes })
      }
      else {
        newRes.push({ fullname: 'Conducting Session Alone', id: 1 })
        store.dispatch({ type: 'GOT_COTRAINERS', payload: [{ fullname: 'Conducting Session Alone', id: 1 }] })
      }
      return newRes
    })
    .catch(error => {
      console.log(error)
    })
}

export const updateBatch = async (token, batchId, body) => {
  return await callAPI(token, "PATCH", `${config.apiurl}batch/${batchId}`, body).catch(error => { console.log(error) })
  return await callAPI(token, "PATCH", `${config.apiurl}batch/${batchId}`, body).catch(error => {
    // return await callAPI(token, "POST", `http://localhost:5000/v1/student/event/`, body).catch(error => {
    console.log(error)
  })
}

export const updateUser = async (token, updateBody) => {
  const userId = updateBody.userId
  const body = {
    fullname: updateBody['Participant Name'] != "" ? updateBody['Participant Name'] : undefined,
    phone: updateBody["Contact number"] != "" ? updateBody["Contact number"] : undefined,
    designation: updateBody["Designation"] != "" ? updateBody["Designation"] : undefined,
    division: updateBody["Division"] != "" ? updateBody["Division"] : undefined,
    group: updateBody["Group"] != "" ? updateBody["Group"] : undefined,
    zone: updateBody["Zone"] != "" ? updateBody["Zone"] : undefined
  }
  return await callAPI(token, "PATCH", `${config.apiurl}users/${userId}`, body)
}

export const uploadBatch = async (token, coachId, batchBody) => {
  return await callAPI(token, "POST", `${config.apiurl}batch/${coachId}`, batchBody)
}

export const addParticipant = async (token, coachId, updateBody) => {
  updateBody.students = updateBody.students.map((stu) => ({
    fullname: stu['Participant Name'] != "" ? stu['Participant Name'] : undefined,
    phone: stu["Contact number"] != "" ? stu["Contact number"] : undefined,
    designation: stu["Designation"] != "" ? stu["Designation"] : undefined,
    division: stu["Division"] != "" ? stu["Division"] : undefined,
    group: stu["Group"] != "" ? stu["Group"] : undefined,
    zone: stu["Zone"] != "" ? stu["Zone"] : undefined
  }))
  return await uploadBatch(token, coachId, updateBody)
}

export const getMyDetail = async (token, userId) => {
  return await callAPI(token, "GET", `${config.apiurl}users/${userId}`).then((res) => {
    if (!res?.code) {
      store.dispatch({ type: "GET_MY_DETAIL", payload: res })
      return res
    }
  })
}

export const toggleLinearNavigator = (bool) => {
  store.dispatch({ type: "TOGGLE_LINEAR_NAVIGATOR", payload: bool });
}
export const getSessionData = async ({ sessionId, user_token }) => {
  return await callAPI(user_token, "GET", `${config.apiurl}session/${sessionId}`).then((res) => {
    if (!res?.code) {
      console.log("res", res)
      // store.dispatch({ type: "GET_MY_DETAIL", payload: res })
      return res
    }
  })
}
export const getTestSubmissions = async ({ sessionId, user_token }) => {
  return await callAPI(user_token, "GET", `${config.apiurl}student/testsubmission/submissionUpdate/${sessionId}`).then((res) => {
    // return await callAPI(user_token, "GET", `http://localhost:3002/v1/student/testsubmission/submissionUpdate/${sessionId}`).then((res) => {
    if (!res?.code) {
      return res
    }
  })
}
// https://preprodapi.pedgog.in/v1/batch/coachAnalytics/${user._id}
export const getCoachAnalytics = async ({ user_token, userId }) => {
  return await callAPI(user_token, "GET", `https://api.pedgog.in/v1/batch/coachAnalytics/${userId}`).then((res) => {
  // return await callAPI(user_token, "GET", `http://localhost:3001/v1/batch/coachAnalytics/${userId}`).then((res) => {
    if (!res?.code) {
      return res
    }
  })
}
export const getBatchAnalytics = async ({ user_token, batchId }) => {
  return await callAPI(user_token, "GET", `https://api.pedgog.in/v1/batch/batchAnalytics/${batchId}`).then((res) => {
  // return await callAPI(user_token, "GET", `http://localhost:3001/v1/batch/batchAnalytics/${batchId}`).then((res) => {
    if (!res?.code) { return res }
  })
}
export const editBatch = async ({ user_token, batchId }) => {
  // return await callAPI(user_token, "PATCH", `http://localhost:3001/v1/batch/editBatch/${batchId}`).then((res) => {
    return await callAPI(user_token, "PATCH", `https://api.pedgog.in/v1/batch/editBatch/${batchId}`).then((res) => {
    if (!res?.code) { return res }
  })
}

export const confirmAttendence = async ({ user_token, body }) => {
  return await callAPI(user_token, 'POST', 'https://preprodapi.pedgog.in/v1/student/attendance/confirm', body)
  // return await callAPI(user_token, 'POST', 'http://localhost:3002/v1/student/attendance/confirm', body)
}

export async function sendDataToBigQuery(body) {
  return await fetch('https://asia-south1-citizen-enquiry-system-2024-05.cloudfunctions.net/deployment', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' }
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error(error);
    });
}