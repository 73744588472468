import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'

const ContentRoll = ({ currentLabelContent, resetContent, language }) => {

    useEffect(() => {
        return resetContent;
    }, [])
    const contentRoll = currentLabelContent?.contentRoll;
    const [currentPageIndex, setCurrentPageIndex] = useState(0);

    const currentPageData = contentRoll?.length > 0 && currentPageIndex >= 0 ? contentRoll[currentPageIndex] : null;
    const nextSlide = () => {
        if (currentPageIndex < contentRoll?.length - 1)
            setCurrentPageIndex(v => v + 1)
    }
    const prevSlide = () => {
        if (currentPageIndex > 0)
            setCurrentPageIndex(v => v - 1)
    }
    const currentlanguage = language || 'en';
    return (
        <div className={styles['content-roll-container']}>
            <div className={styles['content-roll-top']}>
                <button onClick={resetContent} className={styles['back-btn']}>
                    Go Back
                </button>
                <div className={styles['content-roll-top-title']}>
                    {currentLabelContent?.contentTitle}
                </div>
                <div className={styles['content-roll-top-label']}>
                    {currentLabelContent?.labelType}
                </div>
            </div>
            {
                (currentPageData && currentPageData[currentlanguage]) &&
                <div className={styles['content-roll-player']}>
                    <button onClick={prevSlide} className={[styles['controller-btns-2']].join(' ')}>{'<'}</button>
                    <div className={styles['content-roll-img-container']}>
                        <img src={currentPageData[currentlanguage]} className={styles['content-roll--img']} />
                    </div>
                    <button onClick={nextSlide} className={[styles['controller-btns-2']].join(' ')}>{'>'}</button>
                </div>

            }
            {/* <div className={styles['content-roll-controller']}>
                <button onClick={prevSlide} className={[styles['controller-btns'], styles['controller-btns--left']].join(' ')}>Prev</button>
                <button onClick={nextSlide} className={[styles['controller-btns'], styles['controller-btns--right']].join(' ')}>Next</button>
            </div> */}
        </div>
    );
}

export default ContentRoll;