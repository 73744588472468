import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NavLink extends Component {
    render() {
        let lnk = window.location.pathname; 
        // let isActive = false;
        let classname = "";
       
        if (lnk === this.props.to)
            classname = "active";
     
        return (
            <li>
                <span className={classname}>
                <Link {...this.props} style={{ textDecoration: 'none' }} />
                </span>
            </li>
        );
    }
}

// NavLink.contextTypes = {
//     router: React.PropTypes
// };

export default NavLink;