import React from 'react';
import Slider_Response from '../../../Live_Session_Tools/Slider_Response'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId, responses, currentEngagement, uniqueStudentsName }) => {
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
           
                <div style={{ position: 'absolute', top: '68%', right: '52%', width: '40%', height: '15%' }}>
                    <Slider_Response width='100%' height='100%'
                        engagementData={engagementData}
                        manageToolData={manageToolData}
                        data={data}
                        sliderIndex={0}
                        isFullScreen={isFullScreen}
                        engagementStatus={engagementStatus}
                        sessionId={sessionId}
                        responses={responses}
                        currentEngagement={currentEngagement}
                        uniqueStudentsName={uniqueStudentsName}
                    />

                </div>
                <div style={{ position: 'absolute', top: '68%', right: '6%', width: '40%', height: '15%' }}>
                    <Slider_Response width='100%' height='100%'
                        engagementData={engagementData}
                        manageToolData={manageToolData}
                        data={data}
                        sliderIndex={1}
                        isFullScreen={isFullScreen}
                        engagementStatus={engagementStatus}
                        sessionId={sessionId}
                        responses={responses}
                        currentEngagement={currentEngagement}
                        uniqueStudentsName={uniqueStudentsName}
                    />
                </div>
            </div>
        </div >
    );
}