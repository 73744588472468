import React from 'react';
import { useEffect } from 'react';

const useScript = (url, widgetCode) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.setAttribute("type", "text/javascript");

        let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`

        script.appendChild(document.createTextNode(code));
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [url]);
};

export default ({ }) => {
    return (
        <>
            {useScript('https://salesiq.zoho.in/widget', '8292eb86b4542c08ebd3380a36c4d1f79c49ff14a9048d313aa56c75637f8ababb833079480fb808877b71d12e3029f4')}
        </>
    );
}