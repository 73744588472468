export const languages = {
    "en": "English",
    "or": "ଓଡିଆ",
    "hi": "हिन्दी",
    "gu": "ગુજરાતી",
    "bn": "বাংলা",
    "mr": "मराठी",
    "kn": "ಕನ್ನಡ",
    "as": "অসমীয়া",
    "ml": "മലയാളം",
    "ta": "தமிழ்",
    "te": "తెలుగు",
}
export const prepareLanguages = {
    "en": "English",
    "en_1": "English(asdasd)",
    "en_2": "English-2",
    "or": "ଓଡିଆ",
    "hi": "Hindi",
    "gu": "ગુજરાતી",
    "bn": "বাংলা",
    "mr": "मराठी",
    "kn": "ಕನ್ನಡ",
    "as": "অসমীয়া",
    "ml": "മലയാളം",
    "ta": "தமிழ்",
    "te": "తెలుగు",
    "mr_en": "मराठी(English)",
    "mr_hi": "मराठी(Hindi)",
    "bn_en": "বাংলা(English)",
    "bn_hi": "বাংলা(Hindi)",
    "as_en": "অসমীয়া(English)",
    "as_hi": "অসমীয়া(Hindi)",
}

