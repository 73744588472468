import React from 'react';
import TextEditor from '../../../../Tools/TextEditor/TextEditor';
import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, currentUnit }) => {


    const top = ['15%', '38%', '71%'];
    const textBoxHeight = ['14%', '20%', '19%']


    let descriptionSize = '12px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '16px';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((textBoxId, i) =>
                        i === 0 ?
                            <textarea key={textBoxId} id={textBoxId} placeholder='Fill in...'
                                className='transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'
                                style={{ fontSize: descriptionSize, position: 'absolute', width: '50%', height: textBoxHeight[i], top: top[i], right: '3%' }}
                                value={engagementData[data.id] ? engagementData[data.id][textBoxId] ? engagementData[data.id][textBoxId] : '' : ''}
                                onChange={(event) => manageToolData({
                                    toolId: data.id, content: {
                                        ...engagementData[data.id],
                                        [textBoxId]: event.target.value
                                    }
                                })}
                            /> : <div key={textBoxId} className='pos-absolute d-flex roboto' style={{ fontSize: descriptionSize, lineHeight: descriptionSize, width: '50%', height: textBoxHeight[i], top: top[i], right: '3%' }}>
                                <TextEditor id={data.id} initialData={engagementData[data.id] ? engagementData[data.id][data.textBoxId[i]] : null}
                                    manageToolData={manageToolData}
                                    scrollable='tct-scrollable-darkgray'
                                    preFilledTextBoxData={['Fill in...']}
                                    // textEditorClass='UPTC_CS_Square-textEditorClass'
                                    wrapperClass='text-editor-WIAC_diff_in_the_lives'
                                    toolBarClass='CD-CNP-textbox-toolbar'
                                    hideToolbar={true}
                                    oneHierarchy={true}
                                    textBoxId={textBoxId}
                                    engagementData={engagementData}
                                />
                            </div>)
                }
                {
                    data.Flashpoint_Ex && <><textarea id={data.flashPointTextBoxId} placeholder='Fill in flashpoint example...'
                        className='transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'
                        style={{ fontSize: descriptionSize, position: 'absolute', width: '22.5%', height: '28.5%', top: '20%', left: '2%' }}
                        value={engagementData[data.id] ? engagementData[data.id]['Flashpoint_Ex'] ? engagementData[data.id]['Flashpoint_Ex'] : '' : ''}
                        onChange={(event) => manageToolData({
                            toolId: data.id, content: {
                                ...engagementData[data.id],
                                ['Flashpoint_Ex']: event.target.value
                            }
                        })}
                    />
                        <div style={{ position: 'absolute', width: '22%', top: '48%', left: '2%' }}>
                            <img src={data?.buttonImg} className='button-click-effect w-100 h-100' onClick={() => manageToolData({
                                toolId: data.id, content: {
                                    ...engagementData[data.id],
                                    ['Flashpoint_Ex']: data.Flashpoint_Ex
                                }
                            })} />
                        </div>


                    </>
                }
            </div>
        </div >
    );
}