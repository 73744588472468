import React, { useEffect } from 'react';
import './style.css'
import { Input } from '@material-ui/core';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    const right = ['65.5%', '35%', '4.5%'];
    const top = ['21%', '58%'];

    useEffect(() => {
        data.preFilledTextBoxData && Object.keys(data.preFilledTextBoxData).map(key => {
            if (!engagementData[key])
                data.preFilledTextBoxData[key].map((item => manageToolData({ toolId: key, content: ('-' + item) })))
        })
        // manageToolData({})
    }, [])

    // engagementData = {
    //     utilityValue: 'Hello world', // toolId : utilityValue, content: 'Hello world'
    //     financialValue: 'Hello world'
    // }

    // engagementData = {
    //     'valueThinkingTakeAway': {
    //         utilityValue: 'Hello world',  // toolId : utilityValue, content: 'Hello world'
    //         financialValue: 'Hello world'
    //     },
    //     'valueThinkingTakeAway_2': {
    //         utilityValue: 'Hello world',  // toolId : utilityValue, content: 'Hello world'
    //         financialValue: 'Hello world'
    //     },
    //     'valueThinkingTakeAway_3': {
    //         utilityValue: 'Hello world',  // toolId : utilityValue, content: 'Hello world'
    //         financialValue: 'Hello world'
    //     }
    // }

    // let arr = ['valueThinkingTakeAway_2', 'valueThinkingTakeAway_3'].map((item) => {
    //     engagementData[item]
    // })


    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.inputId && <Input id={data.inputId} className='WIAC_any_role_input'
                        placeholder="Fill Here."
                        style={{ fontSize: isFullScreen ? '18px' : '12px', left: '2%', top: '5%', width: '22%', height: '6%' }}
                        value={engagementData[data.inputId] || ''}
                        onChange={(event) => manageToolData({ toolId: data.inputId, content: event.target.value })} />
                }
                {
                    data.textBoxId.map((textBoxId, i) => <textarea key={textBoxId} id={textBoxId} placeholder='Fill Here…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll VTC-textEditor'}
                        value={engagementData[textBoxId] || ''}
                        style={{ fontSize: isFullScreen ? '16px' : '12px', right: right[Math.floor(i % (data.textBoxId.length / 2))], top: top[Math.floor(i / (data.textBoxId.length / 2))] }}
                        onChange={(event) => manageToolData({ toolId: textBoxId, content: event.target.value })}
                    />)
                }
            </div>
        </div >
    );
}