import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, OutlinedInput, Select, TextField } from '@material-ui/core';
import { useState } from 'react';
import Card from './card';
import { Draggable } from 'react-beautiful-dnd';
export default ({
    // setSearch,
    mode,
    setShowSearch,
    showSearch,
    addNewRes,
    handleDropDown,
    addItem,
    addResp,
    setAddResp,
    // text,
    // setText,
    addToBoard,
    allTags,
    data,
    view,
    deck,
    key1,
    // ind,
    handleSaveText,
    el,
    // search,
    tag_filter,
    editOn,
    setEditOn,
    handleRemove,
    checkBoxHandle,
    getCardBg,
}) => {
    const [_text, _setText] = useState("")
    const [dropDown, setDropDown] = useState(allTags[0])
    const [search, setSearch] = useState('')
    return (
        <>
            {mode ?
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '4%' }}>
                    <div>
                        <TextField
                            type="text"
                            className="_search"
                            placeholder="Search..."
                            variant="outlined"
                            size="small"
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                        />
                    </div>

                    <div style={{ height: '43vh', overflow: 'hidden auto' }}>
                        {data &&
                            data.item &&
                            data.item
                                .filter((el) => {
                                    if (data.title !== "Ideal0") return el;
                                    let user = el.user.map((u) => {
                                        return u.toLowerCase();
                                    });

                                    let _tags = el?.tags.flat();
                                    let tag = _tags?.map((t) => { return t?.toLowerCase(); });
                                    if (
                                        search === "" ||
                                        el.text.includes(
                                            search.toLocaleLowerCase()
                                        ) ||
                                        user
                                            .toString()
                                            .includes(
                                                search.toLocaleLowerCase()
                                            ) ||
                                        tag
                                            ?.toString()
                                            .includes(
                                                search.toLocaleLowerCase()
                                            )
                                    ) {
                                        return el;
                                    }
                                })
                                //tag pe filter
                                .filter((el) => {
                                    if (data.title !== "Ideal0") {
                                        return el;
                                    }
                                    let _tags = el?.tags.flat();
                                    let tag = _tags?.map((t) => {
                                        //
                                        return t?.toLowerCase();
                                    });
                                    if (tag_filter === "") {
                                        return el;
                                    } else if (
                                        tag
                                            ?.toString()
                                            .includes(
                                                tag_filter.toLocaleLowerCase()
                                            )
                                    ) {
                                        return el;
                                    }
                                })
                                // .filter((el) => {
                                //     if (el.tags[0] == tag) {
                                //         return el
                                //     }
                                // })
                                .map((el) => {
                                    return (
                                        <Draggable
                                            isDragDisabled={true}
                                            key={el.id}
                                            // index={index}
                                            draggableId={el.id}>
                                            {(provided) => {

                                                return (
                                                    <Card
                                                        allTags={allTags}
                                                        view={view}
                                                        key1={key1}
                                                        // index={index}
                                                        handleSaveText={handleSaveText}
                                                        el={el}
                                                        // search={search}
                                                        // tag_filter={tag_filter}
                                                        // text={_text}
                                                        // setText={_setText}
                                                        editOn={editOn}
                                                        setEditOn={setEditOn}
                                                        handleDropDown={handleDropDown}
                                                        handleRemove={handleRemove}
                                                        checkBoxHandle={checkBoxHandle}
                                                        getCardBg={getCardBg}
                                                    // bgColor={color}
                                                    />
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}
                    </div>


                </div> :

                < div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#FFFFFF', padding: '1% 5%', marginTop: '2%' }}>

                    <div style={{ fontSize: '0.9rem' }}>
                        what kind of fruits will rahul get ?<br />
                        <TextField
                            // key={"inserte-coach-element"}
                            style={{ marginTop: '1%', width: '100%' }}
                            id="inserte-coach-element"
                            value={_text}
                            multiline
                            maxRows={5}
                            onChange={(event) => {
                                _setText(event.target.value)
                            }}
                        />
                    </div>
                    <div style={{ marginTop: '3%', fontSize: '14px' }}>
                        what level is it in ?<br />
                        <Select
                            input={<OutlinedInput />}
                            native
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={(event) => setDropDown(event.target.value)}
                        >
                            {allTags.map(tag => {
                                return <option value={tag}>{tag}</option>
                            })}
                        </Select>
                    </div>
                    <Button
                        onClick={() => {
                            // setAddResp(true)
                            addNewRes([dropDown], _text)
                            addToBoard()
                        }}
                        style={{ margin: '4% auto', fontSize: '0.7rem', fontWeight: 'bold', padding: '0.5rem 1rem', textTransform: "none", backgroundColor: "#FFBC36" }}>Add To Board
                    </Button>

                </div >}
        </>
    )
}