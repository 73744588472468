import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { createTakeaway, addFormValues } from '../../containers/application/redux_actions'
import { convertFromRaw } from 'draft-js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default ({ currentUnit, engagementData, data }) => {

    const app = useSelector(state => state.app)
    const auth = useSelector(state => state.auth)
    const [error, setError] = useState('');
    const [access, setAccess] = useState(true);

    const convertEditorTypeData = () => {
        let convertedData = {};
        Object.keys(app.engagementData).map((textBox, i) => {
            convertedData[textBox] = app.engagementData[textBox].blocks ? convertFromRaw(app.engagementData[textBox]).getPlainText() : app.engagementData[textBox];
        })
        return convertedData;
    }

    const toolDataSave = () => {
        let sessionId = app.createdSession.id;
        let user_token = auth.user_token;
        let fieldData = convertEditorTypeData();
        addFormValues({ sessionId, user_token, fieldData }).then((formResponse) => {
            if (formResponse.id)
                createTakeaway({ user_token, sessionId, access: access === true ? "private" : "public", activeContentId: data.id, learningToolId: currentUnit })
                    .then((res) => {
                        if (res && res.message)
                            setError(res.message)
                    })
        }).catch(err => console.log(err))
    }

    const handleClick = () => {
        if (!app.createdSession.id) {
            setError('Session is not Created')
        }
        else {
            toolDataSave();

        }
    }
    return (
        <div className='w-100 h-100'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="contained" color='primary' onClick={handleClick}>Take Away</Button>
                <FormControlLabel
                    control={
                        <Switch
                            checked={access}
                            onChange={(e) => setAccess(e.target.checked)}
                            name="Access"
                            color="primary"
                        />
                    }
                    label="Access only for Participants"
                />
            </div>
            <div style={{ fontSize: '1rem' }}>{error}</div>
        </div>
    )
}