import { makeStyles } from "@material-ui/core";
import { mdiConsoleNetworkOutline } from "@mdi/js";
import React from "react"
import TextEditor from "../../Tools/TextEditor/TextEditor"
import Modal_Component from '../../Tools/Modal_Component';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, language }) => {
    const useStyles = makeStyles({
        wrapperClass: {
            position: "absolute",
            overflowY: "auto",
            background: "transparent",

            "& ul, & .public-DraftStyleDefault-block": {
                margin: "0 !important"
            }
        }
    })

    const classes = useStyles()

    return <div style={{ width, height, marginLeft: "auto", marginRight: "auto" }
    } >
        <div style={{
            // backgroundImage: `url(${})`,
            width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative'
        }} className={data.scrollable ? "tct-scrollable-darkgray overFlowYAuto" : ""}>
            <img src={data.bgImg?.[language] ? data.bgImg[language] : data.bgImg} style={{ width: "100%" }} />
            {data?.popUp_content && <div id='modal-id-parent' style={{
                ...data.popUp_content[0]?.style,
                position: "absolute",
                padding: "0!important"
            }} >
                <Modal_Component
                    type={data.popUp_content[0].type}
                    icon={data.popUp_content[0].icon?.[language] ? data.popUp_content[0].icon[language] : data.popUp_content[0].icon}
                    src={data.popUp_content[0].src?.[language || 'en'] || data.popUp_content[0].src}
                    language={language}
                    width={width - 50}
                    height={height - 50}
                    parentId='modal-id-parent'
                />
            </div>}
            {data.textBoxes?.map(textBox =>
                <TextEditor
                    id={data.id}
                    initialData={engagementData?.[data.id]?.[textBox.id] ? engagementData[data.id][textBox.id] : null}
                    manageToolData={manageToolData}
                    // scrollable='tct-scrollable-darkgray'
                    preFilledTextBoxData={textBox?.preFilledTextBoxData}
                    // preFilledTextBoxData={["<ul><li></li></ul>"]}
                    // // textEditorClass='UPTC_CS_Square-textEditorClass'
                    wrapperClass={classes.wrapperClass}
                    // toolBarClass='CD-CNP-textbox-toolbar'
                    oneHierarchy
                    engagementData={engagementData}
                    toolName={data.id}
                    hideToolbar={true}
                    placeholder={textBox?.placeholder}
                    textBoxId={textBox?.id}
                    wrapperStyle={{
                        ...textBox.style

                    }}
                >
                </TextEditor>)}

        </div>
    </div >
}