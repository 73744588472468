import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    const top = ['27%', '68%'];
    const right = ['67.5%', '34.5%', '1.5%'];
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {data.textBoxId.map((item, i) => <div key={i} className={`CD-Six-Profession-textbox roboto`} style={{ fontSize: '13px', lineHeight: '10px', top: top[Math.floor(i / (data.textBoxId.length / 2))], right: right[i % (data.textBoxId.length / 2)] }}>
                    <TextEditor id={data.textBoxId[i]} initialData={engagementData[data.textBoxId[i]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...'
                        scrollable='tct-scrollable-darkgray'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                    />
                </div>)}
            </div>
        </div>
    );
}