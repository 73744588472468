import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';

import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let descriptionSize = '13px';

    let textbox_1_top = data.id === 'tool-slide-53' ? '61.5%' : data.id === 'tool-slide-57' ? '62%' : '66.5%'
    let textbox_1_right = data.id === 'tool-slide-53' ? '7.5%' : data.id === 'tool-slide-57' ? '9.5%' : '7.5%';


    if (isFullScreen) {
        descriptionSize = '16px';
        textbox_1_right = data.id === 'tool-slide-53' ? '13%' : data.id === 'tool-slide-57' ? '14.5%' : '13%';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className={data.id === 'tool-slide-59' ? 'ESI_Slide_59_textBox work-sans' : 'ESI_Slide_53_textBox work-sans'} style={{ fontSize: descriptionSize, top: textbox_1_top, right: textbox_1_right }}>
                    <TextEditor id={data.textBoxId[0]} initialData={engagementData[data.textBoxId[0]] || null}
                        manageToolData={manageToolData} hideToolbar={true}
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
            </div>
        </div>
    );
}