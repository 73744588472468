import React from 'react'
import { useState } from 'react';
import { v4 } from "uuid";
import _ from "lodash";
import { Draggable } from 'react-beautiful-dnd';
import Card from './card';
import { Button } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export default ({
    dragApp,
    fakeDragApp,
    setFakeDragApp,
    allTags,
    view,
    handleSaveText,
    search,
    tag_filter,
    text,
    setText,
    editOn,
    setEditOn,
    handleDropDown,
    handleRemove,
    checkBoxHandle,
    getCardBg,
    newArr,
    compare,
    setCompare,
    viewC,
    setViewC,
}) => {

    return (
        <>
            {viewC == 'map' ?
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <div style={{ borderRight: '1px solid', width: '50%', padding: '1%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex' }}>
                                <ChevronLeftIcon style={{ cursor: 'pointer' }} onClick={() => setCompare(false)} />
                                <span>your IdeaBoard</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {_.map(dragApp, (data, key) => {
                                if (key != "First" && key == "Second" || key == "Third") {
                                    return (
                                        <div
                                            // className="scrollDiv"
                                            style={{
                                                height: "37vh",
                                                padding: '1%',
                                                width: "40%",
                                                border: `2px solid #877373`,
                                                borderRadius: '10px',
                                                overFlow: 'hidden auto',
                                                margin: '2% 0%'
                                            }}>
                                            <span style={{ fontSize: '11px' }}>{data.title}</span>
                                            <div className="scrollDiv" style={{ height: '30vh', overflow: 'hidden auto', fontSize: '11px' }}>
                                                {data &&
                                                    data.item &&
                                                    data.item
                                                        .map((el) => {
                                                            // let deck;
                                                            // let compare;
                                                            return (
                                                                <Card
                                                                    allTags={allTags}
                                                                    view={view}
                                                                    deck={true}
                                                                    viewC={true}
                                                                    // compare={true}
                                                                    key1={key}
                                                                    // index={index}
                                                                    handleSaveText={handleSaveText}
                                                                    el={el}
                                                                    // //search={search}
                                                                    tag_filter={tag_filter}
                                                                    text={text}
                                                                    setText={setText}
                                                                    editOn={editOn}
                                                                    setEditOn={setEditOn}
                                                                    handleDropDown={handleDropDown}
                                                                    handleRemove={handleRemove}
                                                                    checkBoxHandle={checkBoxHandle}
                                                                    getCardBg={getCardBg}
                                                                // bgColor={color}
                                                                />
                                                            );

                                                        })
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {_.map(dragApp, (data, key) => {
                                if (key != "First" && key == "Four" || key == "Five") {
                                    return (
                                        <div
                                            // className="scrollDiv"
                                            style={{
                                                height: "37vh",
                                                padding: '1%',
                                                width: "40%",
                                                border: `2px solid #877373`,
                                                borderRadius: '10px',
                                                overFlow: 'hidden auto',
                                                margin: '2% 0%'
                                            }}>
                                            <span style={{ fontSize: '11px' }}>{data.title}</span>
                                            <div className="scrollDiv" style={{ height: '30vh', overflow: 'hidden auto' }}>
                                                {data &&
                                                    data.item &&
                                                    data.item
                                                        .map((el) => {
                                                            // let deck;
                                                            // let compare;
                                                            return (
                                                                <Card
                                                                    allTags={allTags}
                                                                    view={view}
                                                                    deck={true}
                                                                    // compare={true}
                                                                    viewC={true}
                                                                    key1={key}
                                                                    // index={index}
                                                                    handleSaveText={handleSaveText}
                                                                    el={el}
                                                                    
                                                                    tag_filter={tag_filter}
                                                                    text={text}
                                                                    setText={setText}
                                                                    editOn={editOn}
                                                                    setEditOn={setEditOn}
                                                                    handleDropDown={handleDropDown}
                                                                    handleRemove={handleRemove}
                                                                    checkBoxHandle={checkBoxHandle}
                                                                    getCardBg={getCardBg}
                                                                // bgColor={color}
                                                                />
                                                            );

                                                        })
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    <div style={{ borderRight: '1px solid', width: '50%', backgroundColor: '#d9d9d9', marginTop: '1%' }}>
                        <div style={{ marginLeft: '2%' }}>Karmayogi moments IdeaBoard</div>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {_.map(fakeDragApp, (data, key) => {
                                if (key != "First" && key == "Second" || key == "Third") {
                                    return (
                                        <div
                                            // className="scrollDiv"
                                            style={{
                                                height: "37vh",
                                                padding: '1%',
                                                width: "40%",
                                                border: `2px solid #877373`,
                                                borderRadius: '10px',
                                                overFlow: 'hidden auto',
                                                margin: '2% 0%'
                                            }}>
                                            <span style={{ fontSize: '11px' }}>{data.title}</span>
                                            <div className="scrollDiv" style={{ height: '30vh', overflow: 'hidden auto' }}>
                                                {data &&
                                                    data.item &&
                                                    data.item
                                                        .map((el) => {
                                                            // let deck;
                                                            // let compare;
                                                            return (
                                                                <Card
                                                                    allTags={allTags}
                                                                    view={view}
                                                                    deck={true}
                                                                    compare={true}
                                                                    viewC={true}
                                                                    key1={key}
                                                                    // index={index}
                                                                    handleSaveText={handleSaveText}
                                                                    el={el}
                                                                    
                                                                    tag_filter={tag_filter}
                                                                    text={text}
                                                                    setText={setText}
                                                                    editOn={editOn}
                                                                    setEditOn={setEditOn}
                                                                    handleDropDown={handleDropDown}
                                                                    handleRemove={handleRemove}
                                                                    checkBoxHandle={checkBoxHandle}
                                                                    getCardBg={getCardBg}
                                                                // bgColor={color}
                                                                />
                                                            );

                                                        })
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {_.map(fakeDragApp, (data, key) => {
                                if (key != "First" && key == "Four" || key == "Five") {
                                    return (
                                        <div
                                            // className="scrollDiv"
                                            style={{
                                                height: "37vh",
                                                padding: '1%',
                                                width: "40%",
                                                border: `2px solid #877373`,
                                                borderRadius: '10px',
                                                overFlow: 'hidden auto',
                                                margin: '2% 0%'
                                            }}>
                                            <span style={{ fontSize: '11px' }}>{data.title}</span>
                                            <div className="scrollDiv" style={{ height: '30vh', overflow: 'hidden auto' }}>
                                                {data &&
                                                    data.item &&
                                                    data.item
                                                        .map((el) => {
                                                            // let deck;
                                                            // let compare;
                                                            return (
                                                                <Card
                                                                    allTags={allTags}
                                                                    view={view}
                                                                    deck={true}
                                                                    compare={true}
                                                                    key1={key}
                                                                    // index={index}
                                                                    handleSaveText={handleSaveText}
                                                                    el={el}
                                                                    
                                                                    tag_filter={tag_filter}
                                                                    text={text}
                                                                    setText={setText}
                                                                    editOn={editOn}
                                                                    setEditOn={setEditOn}
                                                                    handleDropDown={handleDropDown}
                                                                    handleRemove={handleRemove}
                                                                    checkBoxHandle={checkBoxHandle}
                                                                    getCardBg={getCardBg}
                                                                // bgColor={color}
                                                                />
                                                            );

                                                        })
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button>Prev</Button>
                    <Button>Next</Button>
                </div> */}
                    </div>
                </div>
                :
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'relative', top: '0vh', left: '-1vw', }}>
                    <div style={{ marginLeft: '1%' }}>Your IdeaBoard</div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', height: '40vh', }}>
                        {_.map(dragApp, (data, key) => {
                            if (dragApp[key].title != "Ideal0") {
                                return (
                                    <>
                                        <div style={{
                                            overflowY: 'auto', margin: '1%', width: '33%', display: 'flex', alignItems: 'center', flexDirection: 'column',
                                            //  height: '77vh', 
                                        }}>
                                            <div style={{ fontSize: '15px', paddingBottom: '3%', width: "100%" }}>
                                                {data?.title}
                                            </div>
                                            <div className="scrollDiv" style={{
                                                // height: '70vh',
                                                overflowY: 'auto', width: '100%'
                                            }}>
                                                {Object.values(data?.item).map((el, index) => {
                                                    let deck = true
                                                    return (
                                                        <Card
                                                            // compare={true}
                                                            deck={deck}
                                                            allTags={allTags}
                                                            view={view}
                                                            key1={key}
                                                            // index={index}
                                                            handleSaveText={handleSaveText}
                                                            el={el}
                                                            
                                                            tag_filter={tag_filter}
                                                            text={text}
                                                            setText={setText}
                                                            editOn={editOn}
                                                            setEditOn={setEditOn}
                                                            handleDropDown={handleDropDown}
                                                            handleRemove={handleRemove}
                                                            checkBoxHandle={checkBoxHandle}
                                                            getCardBg={getCardBg}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <hr />
                                    </>
                                )
                            }
                        })}
                    </div>
                    <div style={{ backgroundColor: '#d9d9d9', display: 'flex', flexDirection: 'column', marginTop: '1%' }}>
                        <span style={{ marginLeft: '2%', fontWeight: 'bold' }}>Karmayogi moments IdeaBoard</span>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', height: '40vh' }}>
                            {_.map(fakeDragApp, (data, key) => {
                                if (fakeDragApp[key].title != "Ideal0") {
                                    return (
                                        <>
                                            <div style={{
                                                overflowY: 'auto', margin: '1%', width: '33%', display: 'flex', alignItems: 'center', flexDirection: 'column',
                                                //  height: '77vh', 
                                            }}>
                                                <div style={{ fontSize: '15px', paddingBottom: '3%', width: "100%" }}>
                                                    {data?.title}
                                                </div>
                                                <div className="scrollDiv" style={{
                                                    // height: '70vh',
                                                    overflowY: 'auto', width: '100%'
                                                }}>
                                                    {Object.values(data?.item).map((el, index) => {
                                                        let deck = true
                                                        return (
                                                            <Card
                                                                deck={deck}
                                                                allTags={allTags}
                                                                view={view}
                                                                key1={key}
                                                                // index={index}
                                                                handleSaveText={handleSaveText}
                                                                el={el}
                                                                
                                                                tag_filter={tag_filter}
                                                                text={text}
                                                                setText={setText}
                                                                editOn={editOn}
                                                                setEditOn={setEditOn}
                                                                handleDropDown={handleDropDown}
                                                                handleRemove={handleRemove}
                                                                checkBoxHandle={checkBoxHandle}
                                                                getCardBg={getCardBg}
                                                                compare={true}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <hr style={{ height: '96%' }} />
                                        </>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}