import React, { Component } from "react";
// import MainRoute from "./Route"
import Config from "../../config";
//ACP Links
// import APRoute from "../asianPaints/AuthRoute"
import AuthRoute from "./AuthRoute";
import { AuthHost } from "../../utilities/common";

let Application = class Application extends Component {
  render() {
    //   if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    //     // dev code
    //     return (
    //       <div className="ir-tisa-font w-100 h-100">
    //         <AuthRoute />
    //       </div>
    //     );

    //   } else {
    //     // production code

    //   }
    // }


    if (window.location.host === AuthHost) {
      return (
        <div className="ir-tisa-font w-100 h-100">
          <AuthRoute />
        </div>
      );
    }
    else {
      let url = `${AuthHost}?${window.location.host}`
      console.log("url", url);
      window.location.host = AuthHost;
    }
  }
};

export default Application;
