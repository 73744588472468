import firebase from "firebase/app";
import "firebase/auth";
// import "firebase/messaging";
import Config from "../../config"
import store from "../../Redux_Store"
import { getOrganisationByParentId } from "../application/redux_actions";

const config = {
  apiurl: Config.API_URL,
};

export const loginAttempt = ({
  email,
  password,
  flag,
  successCallBack,
  failureCallback,
}) => {
  return (dispatch) => {
    dispatch({ type: "LOGIN_ATTEMPT", payload: true });
    if (password === "" && email !== "") {
      let errMsg = {};
      errMsg.message = "Please enter a valid password";
      loginAttempFail(dispatch, errMsg, failureCallback);
    } else {
      //fetch("http://ilcdp1.ravikadam.in/v1/auth/login", {
      fetch(config.apiurl + "auth/login", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          loginAttempSuccess(dispatch, response, successCallBack, flag);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
};

export const registerAttempt = ({
  email,
  password,
  accesscode,
  fullname,
  organizationid,
  phone,
  successCallBack,
  failureCallback,
}) => {
  return (dispatch) => {
    dispatch({ type: "REGISTER_ATTEMPT", payload: true });
    fetch(config.apiurl + "auth/register", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        accesscode: accesscode,
        // title: '',
        fullname: fullname,
        organizationid: organizationid,
        phone: phone
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        registerAttempSuccess(dispatch, response, successCallBack);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const myprofileAttempt = ({
  accesscode,
  firstname,
  lastname,
  title,
  phone,
  location,
  address1,
  address2,
  city,
  state,
  country,
  zipcode,
  successCallBack,
  failureCallback,
}) => {
  return (dispatch) => {
    dispatch({ type: "MYPROFILE_ATTEMPT", payload: true });
    fetch(config.apiurl + "users/" + this.props.auth.user.uid, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        accesscode: accesscode,
        title: title,
        name: firstname + " " + lastname,
        firstname: firstname,
        lastname: lastname,
        phone: phone,
        location: location,
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        country: country,
        zipcode: zipcode,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log(response)
        myprofileAttempSuccess(dispatch, response, successCallBack);
      })
      .catch((err) => {
        console.log(err);
      });

    // firebase
    //   .auth()
    //   .createUserWithEmailAndPassword(email, password)
    //   .then(user => {
    //     loginAttempSuccess(dispatch, user, successCallBack);
    //   })
    //   .catch(err => {
    //     //let errMsg = getErrorMsg(err);
    //     loginAttempFail(dispatch, err, failureCallback);
    //   });
  };
};

export function subscribePack(userid) {
  return (dispatch) => {
    dispatch({ type: "SUBSCRIBEPACK_ATTEMPT", payload: true });
    return fetch(config.apiurl + "subscribe/createSubscription", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + userid.uTok,
        accept: "application/json",
      },
      body: JSON.stringify({
        SubscriptionDate: new Date(),
        SubscribedBy: userid.uid,
        SubscribedPack: userid.pkid,
      }),
    })
      .then((res) => res.json())
      .then((resp) => {
        return dispatch(subscribePackSuccess(resp));
      })
      .catch((err) => dispatch(subscribePackFailure(err)));
  };
}

export function addSubscriptionForUser(userid) {
  return (dispatch) => {
    dispatch({ type: "SUBSCRIBEPACKFORUSER_ATTEMPT", payload: true });
    return fetch(config.apiurl + "subscribe/AddSubscriptionForUser", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + userid.uTok,
        accept: "application/json",
      },
      body: JSON.stringify({
        SubscriptionDate: new Date(),
        SubscribedBy: userid.uid,
        SubscribedPack: userid.pkid,
      }),
    })
      .then((res) => res.json())
      .then((resp) => {
        return dispatch(subscribePackForUserSuccess(resp));
      })
      .catch((err) => dispatch(subscribePackForUserFailure(err)));
  };
}

export function getAccessTokenDetails({ accesscode, email, user_token }) {
  return (dispatch) => {
    console.log(email);
    dispatch({ type: "GETACCESSTOKENDETAILS_ATTEMPT", payload: true });
    return fetch(
      config.apiurl + "accesscode/getAccessCodeForId/" + accesscode,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      }
    )
      .then((res) => res.json())
      .then((resp) => {
        console.log(resp);
        if (!resp.code) {
          getOrganisationByParentId({ parentOrgId: resp[0].orgId, user_token })
          return dispatch(getAccessTokenDetailsSuccess(resp));
        }
        throw resp.message
      })
      .catch((err) => dispatch(getAccessTokenDetailsFailure(err)));
  };
}

export const setErrMsg = (el) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOGIN_ERROR_MSG", payload: el });
  };
};

export const resetPassword = (email) => {
  // console.log('aaaaaaa');
  // var auth = firebase.auth();
  // console.log(auth);
  // var emailAddress = email;
  // return dispatch => {
  //   if (email) {
  //     dispatch({type: "PASSWORD_RESET_IN_PROGRESS", payload: true});
  //     auth
  //       .sendPasswordResetEmail(emailAddress)
  //       .then(function () {
  //         dispatch({type: "RESET_PASSWORD_LINK_SENT", payload: true});
  //       })
  //       .catch(function (error) {
  //         let err = getErrorMsg(error);
  //         setErrorMsgInForgotPassword(dispatch, err.message);
  //       });
  //   } else {
  //     setErrorMsgInForgotPassword(dispatch, "Please enter a valid email id");
  //   }
  // };

  return (dispatch) => {
    if (email) {
      dispatch({ type: "PASSWORD_RESET_IN_PROGRESS", payload: true });
      return (
        fetch(config.apiurl + "auth/forgot-password", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify({
            email: email.email,
            site_url: email.site_url
          }),
        })
          //.then(res => res.json())
          .then((resp) => {
            return dispatch({
              type: "RESET_PASSWORD_LINK_SENT",
              payload: true,
            });
          })
          .catch(function (error) {
            let err = getErrorMsg(error);
            setErrorMsgInForgotPassword(dispatch, err.message);
          })
      );
    } else {
      setErrorMsgInForgotPassword(dispatch, "Please enter a valid email id");
    }
  };
};

export const resetPasswordUpdate = (data) => {
  return (dispatch) => {
    if (data) {
      dispatch({ type: "PASSWORD_UPDATE", payload: true });
      return fetch(config.apiurl + "auth/reset-password", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          password: data.password,
          token: data.token
        }),
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp?.code) {
            data.failureCallback(resp.message);
          }
          else {
            data.successCallBack()
            return dispatch({ type: "PASSWORD_UPDATE_SUCCESS", payload: resp });
          }
        })
        .catch(function (error) {
          data.failureCallback(error);
        });
    } else {
      setErrorMsgInForgotPassword(dispatch, "Please enter a valid pasword");
    }
  };
};

export const updateUserDetails = (user) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_USER_UID", payload: user.uid });
  };
};

export const resetLoginPage = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_LOGIN_PAGE" });
  };
};

export const resetForgotPasswordPage = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_FORGOT_PASSWORD_PAGE" });
  };
};

export const registerNewUserPage = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_REGISTER_NEW_USER_PAGE" });
  };
};

export const myprofilePage = () => {
  return (dispatch) => {
    dispatch({ type: "MYPROFILE_PAGE" });
  };
};

export const signOut = () => {
  console.log('called')
  return (dispatch) => {
    dispatch({ type: "RESET_APP" });
    dispatch({ type: "LOGOUT_ATTEMPT" });
    dispatch({ type: "ACTIVATE_SECTION", payload: 0 })
  };
};

export async function getOrganizationAttempt(data) {
  console.log('this is called getOrganizationAttempt')
  // store.dispatch({ type: "GETORGANIZATION_ATTEMPT", payload: true });
  return await fetch(config.apiurl + "organization/" + data.orgid, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: "bearer " + data.token,
      accept: "application/json",
    },
  })
    .then((res) => {
      return res.json()
    })
    .then((sessions) => {
      store.dispatch({ type: "GETORGANIZATION_SUCCEEDED", data: sessions })
      return sessions
    })
    .catch((err) => {
      console.log("get Organization  => error ===");
      console.log(err);
    });

}

/**Helper Functions**/
const loginAttempSuccess = (dispatch, user, successCallBack, flag) => {
  dispatch({ type: "LOGIN_SUCCEEDED", payload: user, flag });
  successCallBack(user);
};
const registerAttempSuccess = (dispatch, user, successCallBack) => {
  dispatch({ type: "REGISTER_SUCCEEDED", payload: user });
  successCallBack(user);
};
// const getAccessTokenDetailsSuccess = (dispatch, user, successCallBack) => {
//   dispatch({type: "GETACCESSTOKENDETAILS_SUCCEEDED", payload: user});
//   successCallBack();
// };
function getAccessTokenDetailsSuccess(data) {
  return { type: "GETACCESSTOKENDETAILS_SUCCEEDED", data };
}
function getAccessTokenDetailsFailure(err) {
  return { type: "GETACCESSTOKENDETAILS_FAILURE", data: err };
}
function subscribePackSuccess(data) {
  return { type: "SUBSCRIBEPACK_SUCCEEDED", data };
}
function subscribePackFailure(err) {
  console.log(err)
  return { type: "SUBSCRIBEPACK_FAILURE" };
}
function subscribePackForUserSuccess(data) {
  return { type: "SUBSCRIBEPACKFORUSER_SUCCEEDED", data };
}
function subscribePackForUserFailure(err) {
  return { type: "SUBSCRIBEPACKFORUSER_FAILURE" };
}
const myprofileAttempSuccess = (dispatch, user, successCallBack) => {
  dispatch({ type: "MYPROFILE_SUCCEEDED", payload: user });
  successCallBack();
};
const loginAttempFail = (dispatch, err, failureCallback, pos) => {
  dispatch({ type: "LOGIN_FAILED", payload: err.message });
  failureCallback();
};

function getErrorMsg(error) {
  let { code } = error;

  switch (code) {
    case "auth/invalid-email":
      error.message = "Please enter a valid email id";
      break;
    case "auth/user-not-found":
      error.message = "This email id is not registered. Please register.";
      break;
    case "auth/wrong-password":
      error.message = "Incorrect email or password. Please try again.";
      break;
    case "auth/email-already-in-use":
      error.message =
        "This email id is already registered. Please use a different email id";
      break;
    default:
      break;
  }

  return error;
}

function setErrorMsgInForgotPassword(dispatch, msg) {
  dispatch({ type: "SET_RESET_PASSWORD_ERROR_MSG", payload: msg });

  // setTimeout(() => {
  //   dispatch({ type: "SET_RESET_PASSWORD_ERROR_MSG", payload: " " });
  // }, 3000);
}
/**End of Helper Functions**/

export async function updateUserByIdChange() {
  await store.dispatch({ type: "UPDATE_USER_BY_ID_CHANGE" });
}

export async function verifyAccessCode(accesscode, token) {


  if (accesscode.trim() === "") {
    return ("Access Code cannot be blank")
  }

  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  let org;

  let requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: myHeaders
  };

  store.dispatch({ type: "GETACCESSTOKENDETAILS_ATTEMPT" })

  await fetch(config.studenturl + "accesscode/" + accesscode, requestOptions)
    .then(response => response.json())
    .then((result) => {
      console.log(result)
      if (result.code) {
        return (result.message);
      }
      else {
        org = result["organizationid"]
        store.dispatch({ type: "GETACCESSTOKENDETAILS_SUCCEEDED", data: result })
        getOrganisationByParentId(token, org)
      }
    })
    .catch(error => console.log('error', error));
}


export const callAPI = async (method, url, body, reduxVariable) => {
  return new Promise((resolve, reject) => {
    let id = setTimeout(() => reject('Timeout'), 120000)
    const state = store.getState();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (body?.auto_login_token) {
      myHeaders.append("Authorization", `Bearer ${body?.auto_login_token}`);
      delete body?.auto_login_token
    }
    else
      state?.auth?.user_token && myHeaders.append("Authorization", `Bearer ${state?.auth?.user_token}`);

    var requestOptions = {
      method,
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: 'follow',


    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then((res) => {
        if (res?.code) {
          if (res?.code == 401) {
            store.dispatch(signOut())
            // window.location.reload();
          }
          console.log('FAILED : ', res);
          reject(res?.message)
        }
        else if (reduxVariable)
          store.dispatch({ type: reduxVariable, payload: res });
        clearTimeout(id)
        resolve(res)
      }).catch((error => {
        reject("Something went wrong")
      }))
  })

}

export const sendOTP = async (phone, reduxVariable) => {
  return await callAPI("POST", `${config.apiurl}auth/send-otp`, { phone }, reduxVariable)
}

export const loginWithOTP = async (body, reduxVariable) => {
  return await callAPI("POST", `${config.apiurl}auth/loginWithOTP`, body, reduxVariable)
  // return await callAPI("POST", `http://localhost:3001/v1/auth/loginWithOTP`, body, reduxVariable)
}

export const loginWithToken = async (body, reduxVariable) => {
  return await callAPI("POST", `${config.apiurl}auth/loginWithToken`, body, undefined)
  // return await callAPI("POST", `http://localhost:3001/v1/auth/loginWithToken`, body, undefined)
}

export const checkAccesscode = async (accesscode) => {
  return await callAPI("GET", `${config.apiurl}accesscode/verify/${accesscode}`, undefined, undefined)
}
export const verfiyEmail = async (body) => {
  return await callAPI("POST", `${config.apiurl}auth/verify-email`, body, undefined)
}

export const registerUser = async body => {
  // return await callAPI("POST", `http://localhost:3001/v1/auth/mobile/register`, body).then(response => {
  return await callAPI("POST", `${config.apiurl}auth/mobile/register`, body).then(response => {
    // store.dispatch({ type: "REGISTER_SUCCESS", payload: response });
    store.dispatch({ type: "REDIRECTING", payload: true })
    if (process.env.NODE_ENV === "development")
      window.location.href = `http://localhost:3000/auto-login/${response.tokens.coachingPlatform?.token}`;
    else {
      let url1 = response.user.defaultIntervention
      store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'otpTimer', value: 0 } });
      store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'otp', value: 0 } });
      window.location.href = `http://${url1}/auto-login/${response.tokens.coachingPlatform?.token}`;
      window.location.href = `http://${response.user.defaultIntervention}/auto-login/${response.tokens.coachingPlatform?.token}`;
    }
  })

}

export const verfiyExistingAccessCode = async (accesscode, userId) => {
  return await callAPI("GET", `${config.apiurl}accesscode/verify/${accesscode}/${userId}`, undefined, undefined)
}
export const editAccountDetails = async (userId, body) => {
  console.log('edit called', userId, body)
  return await callAPI("PATCH", `${config.apiurl}users/${userId}`, body)
}

export const joinNewProgram = async (body, token, landingUrl) => {
  return await callAPI("POST", `${config.apiurl}accesscode/join`, body)
    .then(response => {
      if (process.env.NODE_ENV === 'development') {
        window.location.href = `http://localhost:3000/auto-login/${token}`;
      }
      else {
        let url1 = landingUrl
        window.location.href = `http://${url1}/auto-login/${token}`;
      }
      store.dispatch({ type: 'NOTIFICATIONS', payload: { key: 'student', value: false } })
    })
}

export const loginUser = async body => {
  return await callAPI("POST", `${config.apiurl}auth/login`, body)
}

export const getOrgNew = async (orgId, token) => {
  console.log("orgId", orgId)
  return await callAPI("GET", `${config.apiurl}organization/${orgId}`, undefined, undefined, token)
    .then(res => console.log("res", res))
}