import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let descriptionSize = '14px';
    let tooltipWidth = 120;
    // let tooltipHeight = 120;
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '18px';
        tooltipWidth = 290;
        // tooltipHeight = 200;
    }

    const useStyles = makeStyles((theme) => ({
        button: {
            margin: theme.spacing(1),
        },
        customWidth: {
            maxWidth: tooltipWidth,
            // maxHeight: tooltipHeight,
            whiteSpace: 'pre-line !important',
            fontSize: descriptionSize,
            // overflowY: 'auto'
        },
        noMaxWidth: {
            maxWidth: 'none',
        },
    }));

    const classes = useStyles();

    const windowTextBoxTop = ['28%', '47.5%', '66.5%', '85.5%']
    const top = ['24%', '43%', '62%', '81%'];


    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <textarea id={data.goalTextBoxId} placeholder='Fill here…'
                    className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ fontSize: descriptionSize, position: 'absolute', width: '66%', top: '1.5%', right: '0.5%', height: '10%' }}
                    value={engagementData[data.goalTextBoxId] || ''}
                    readOnly
                />

                {
                    data.windowTextBoxId.map((textBoxId, i) => <textarea id={textBoxId} placeholder='Fill here…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ fontSize: descriptionSize, position: 'absolute', width: '43%', top: windowTextBoxTop[i], right: '55.5%', height: '13%' }}
                        value={engagementData[textBoxId] || ''}
                        readOnly
                    />)
                }
                {
                    data.textBoxId.map((textBoxId, i) => <Tooltip key={textBoxId} TransitionComponent={Zoom} placement="right" interactive arrow title={data.toolTipData[i]} classes={{ tooltip: i === data.textBoxId.length - 1 ? `${classes.customWidth} top--50px` : classes.customWidth }}>
                        <textarea id={textBoxId} placeholder='Fill here…'
                            className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                            style={{ fontSize: descriptionSize, position: 'absolute', width: '31.5%', top: top[i], right: '20.5%', height: '17%' }}
                            value={engagementData[textBoxId] || ''}
                            onChange={(event) => {
                                manageToolData({ toolId: textBoxId, content: event.currentTarget.value })
                            }}
                        />
                    </Tooltip>)
                }

            </div>
        </div>
    );
}