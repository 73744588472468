import React, { useEffect } from 'react';
import { TextareaAutosize } from '@material-ui/core';
import './style.css';

import Modal_Component from '../../../Tools/Modal_Component';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    useEffect(() => {
        if (data.toolId !== 'SS_Drag_N_Drop_Display') {
            if (!engagementData) engagementData = {}
            if (!engagementData[data.toolId]) engagementData[data.toolId] = {}
            if (!engagementData[data.toolId]['userFilled']) engagementData[data.toolId]['userFilled'] = []
            if (!engagementData[data.toolId]['preFilled']) engagementData[data.toolId]['preFilled'] = []
            if (!engagementData[data.toolId]['droped']) engagementData[data.toolId]['droped'] = []
            if (engagementData[data.toolId]['preFilled'].length === 0) {
                data.preFilledList.forEach(element => {
                    engagementData[data.toolId]['preFilled'] = [
                        ...engagementData[data.toolId]['preFilled'],
                        {
                            content: element,
                            category: 'preFilled',
                            type: 'preFilled'
                        }
                    ]
                });
                engagementData[data.toolId]['userFilled'] = [{
                    content: '',
                    category: 'userFilled',
                    type: 'userFilled',
                }]
                manageToolData({ toolId: data.toolId, content: engagementData[data.toolId] })
            }
        }

    }, [data.toolId])

    const cardsContainer = {
        userFilled: [],
        preFilled: [],
        droped: [],
    }


    const handleInputChange = (event, type, i) => {
        engagementData[data.toolId][type][i].content = event.target.value
        manageToolData({ toolId: data.toolId, content: engagementData[data.toolId] })
    }

    const onDragStart = (ev, type, category, index) => {
        // console.log('dragstart:', category, type);
        ev.dataTransfer.setData("category", category);
        ev.dataTransfer.setData("type", type);
        ev.dataTransfer.setData("index", index);
    }

    const onDragOver = (ev) => {
        ev.preventDefault();
    }

    const onDrop = (ev, cat) => {
        let category = ev.dataTransfer.getData("category");
        let type = ev.dataTransfer.getData("type");
        let index = ev.dataTransfer.getData("index");
        if ((cat === 'droped') || (cat === 'preFilled' && type === 'preFilled')) {
            engagementData[data.toolId][cat] = [
                ...engagementData[data.toolId][cat],
                {
                    content: engagementData[data.toolId][category][index].content,
                    category: cat,
                    type,
                }
            ]
            engagementData[data.toolId][category].splice(index, 1);
            // console.log(engagementData[data.toolId]);
            manageToolData({ toolId: data.toolId, content: engagementData[data.toolId] })
        }
    }


    let textSize = '10px';
    // let displayTextSize = '18px';
    let boxWidth = '150px';
    if (isFullScreen) {
        textSize = '14px';
        // displayTextSize = '26px';
        boxWidth = '265px';
    }

    cardsContainer.preFilled = engagementData && engagementData[data.toolId] && engagementData[data.toolId]['preFilled'] && engagementData[data.toolId]['preFilled'].map((item, i) =>
        <div key={item.content} draggable
            onDragStart={(e) => onDragStart(e, item.type, item.category, i)}
            className='ss-prefilled-list'
            style={{ fontSize: textSize, width: boxWidth }}>
            {item.content}
        </div>
    )

    if (engagementData && engagementData[data.toolId] && engagementData[data.toolId]['userFilled'] && engagementData[data.toolId]['userFilled'].length === 0) {
        engagementData[data.toolId]['userFilled'] = [{
            content: '',
            category: 'userFilled',
            type: 'userFilled'
        }]
    }

    cardsContainer.userFilled = engagementData && engagementData[data.toolId] && engagementData[data.toolId]['userFilled'] && engagementData[data.toolId]['userFilled'].map((item, i) =>
        <div key={i} style={{ cursor: 'pointer' }} draggable onDragStart={(e) => onDragStart(e, item.type, item.category, i)}>
            <TextareaAutosize
                value={item.content}
                placeholder='Fill Here...'
                onChange={(e) => { handleInputChange(e, item.category, i) }}
                className='ss-userfilled-list'
                style={{ fontSize: textSize, width: boxWidth }}
            />
        </div>
    )
    // console.log(engagementData[data.toolId]['droped'])
    cardsContainer.droped = engagementData && engagementData[data.toolId] && engagementData[data.toolId]['droped'] && engagementData[data.toolId]['droped'].map((item, i) => {
        if (item.type === 'userFilled')
            return <div key={i} style={{ cursor: 'pointer' }} draggable onDragStart={(e) => onDragStart(e, item.type, item.category, i)}>
                <TextareaAutosize
                    value={item.content}
                    placeholder='Fill Here...'
                    className='ss-droped-usertype'
                    onChange={(e) => { handleInputChange(e, item.category, i) }}
                    style={{ fontSize: textSize }}
                />
            </div>
        return <div key={item.content} draggable
            onDragStart={(e) => onDragStart(e, item.type, item.category, i)}
            className='ss-droped-list'
            style={{ fontSize: textSize }}>
            {item.content}
        </div>
    })

    let displayDroped_1 = data.prevFilledToolId && engagementData && engagementData[data.prevFilledToolId[0]] && engagementData[data.prevFilledToolId[0]]['droped'] && <div className='tct-scrollable-darkgray ss-droped-display-container' style={{ width: '100%', height: '100%', backgroundColor: data.toolId === 'SS_Drag_N_Drop_Display' && 'transparent' }}>{engagementData[data.prevFilledToolId[0]]['droped'].map((item, i) => {
        return <div key={item.content}
            className='ss-droped-display-list'
            style={{ fontSize: textSize, margin: data.toolId === 'SS_Drag_N_Drop_Display' && '6px' }}>
            {item.content}
        </div>
    })}</div>
    let displayDroped_2 = data.prevFilledToolId && engagementData && engagementData[data.prevFilledToolId[1]] && engagementData[data.prevFilledToolId[1]]['droped'] && <div className='tct-scrollable-darkgray ss-droped-display-container' style={{ width: '100%', height: '100%', backgroundColor: data.toolId === 'SS_Drag_N_Drop_Display' && 'transparent' }}>{engagementData[data.prevFilledToolId[1]]['droped'].map((item, i) => {
        return <div key={item.content}
            className='ss-droped-display-list'
            style={{ fontSize: textSize, margin: data.toolId === 'SS_Drag_N_Drop_Display' && '6px' }}>
            {item.content}
        </div>
    })}</div>
    let displayDroped_3 = data.prevFilledToolId && engagementData && engagementData[data.prevFilledToolId[2]] && engagementData[data.prevFilledToolId[2]]['droped'] && <div className='tct-scrollable-darkgray ss-droped-display-container' style={{ width: '100%', height: '100%', backgroundColor: data.toolId === 'SS_Drag_N_Drop_Display' && 'transparent' }}>{engagementData[data.prevFilledToolId[2]]['droped'].map((item, i) => {
        return <div key={item.content}
            className='ss-droped-display-list'
            style={{ fontSize: textSize, margin: data.toolId === 'SS_Drag_N_Drop_Display' && '6px' }}>
            {item.content}
        </div>
    })}</div>

    const right = ['57.5%', '31%', '4.5%'];

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'noOfUserInput-repeat', position: 'relative' }}>
                {
                    data.toolId !== 'SS_Drag_N_Drop_Display' ? <div style={{ width: '100%', height: '100%', position: 'relative' }}><div className='tct-scrollable-darkgray ss-userfilled-container'>
                        {cardsContainer.userFilled}
                    </div>
                        <div className='tct-scrollable-darkgray ss-prefilled-container'
                            onDragOver={(e) => onDragOver(e)}
                            onDrop={(e) => { onDrop(e, "preFilled") }}>
                            {cardsContainer.preFilled}
                        </div>
                        <div className='tct-scrollable-darkgray ss-droped-container'
                            onDragOver={(e) => onDragOver(e)}
                            onDrop={(e) => { onDrop(e, "droped") }}>
                            {cardsContainer.droped}
                        </div>
                        {
                            data.prevFilledToolId && data.prevFilledToolId[0] && <div className='ss-droped-display-icon_1'>
                                <Modal_Component
                                    icon={data.popUpIcon[0]}
                                    src={displayDroped_1}
                                    type='other'
                                    width='500px'
                                    height='300px'
                                />
                            </div>
                        }
                        {
                            data.prevFilledToolId && data.prevFilledToolId[1] && <div className='ss-droped-display-icon_2'>
                                <Modal_Component
                                    icon={data.popUpIcon[1]}
                                    src={displayDroped_2}
                                    type='other'
                                    width='500px'
                                    height='300px'
                                />
                            </div>
                        }
                    </div> : <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                            <div className='ss-display-droped' style={{ right: right[0] }}>
                                {displayDroped_1}
                            </div>
                            <div className='ss-display-droped' style={{ height: '60%', top: '13%', right: right[1] }}>
                                {displayDroped_2}
                            </div>
                            <div className='ss-display-droped' style={{ right: right[2] }}>
                                {displayDroped_3}
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}