import React from 'react';
import TextField from '@material-ui/core/TextField';

import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let textBoxtextSize = '12px'
    if (isFullScreen) {
        textBoxtextSize = '16px'
    }

    const handleChange = (event, textBoxId) => {
        manageToolData({
            toolId: data.id,
            content: {
                ...engagementData[data.id],
                [textBoxId]: event.target.value
            }
        })
    };


    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((textBoxId, index) => <TextField
                        placeholder="Fill here"
                        multiline
                        className={`sma-thinking-strategy-way-of-thinking-textbox-${index}`}
                        maxRows={2}
                        inputProps={{
                            className: 'tct-scrollable-darkgray',
                            style: { fontSize: textBoxtextSize, lineHeight: textBoxtextSize }
                        }}
                        value={engagementData[data.id] ? engagementData[data.id][textBoxId] : null}
                        onChange={(event) => { handleChange(event, textBoxId) }}
                    />)
                }
            </div>
        </div >
    );
}