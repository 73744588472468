import React from 'react';


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <textarea id={data.textBoxId[0]}
                    className={`SS_Case_Study_1-outer-textbox roboto tct-scrollable`}
                    value={engagementData[data.textBoxId[0]] || ''}
                    style={{ height: '17%', top: '27.5%', fontSize: !isFullScreen ? '12px' : '16px' }}
                    onChange={(event) => {
                        manageToolData({ toolId: data.textBoxId[0], content: event.currentTarget.value })
                    }}
                    placeholder='&#9679; Fill in…' />
                <textarea id={data.textBoxId[1]}
                    className={`SS_Case_Study_1-outer-textbox roboto tct-scrollable`}
                    value={engagementData[data.textBoxId[1]] || ''}
                    style={{ height: '17%', top: '52.5%', fontSize: !isFullScreen ? '12px' : '16px' }}
                    onChange={(event) => {
                        manageToolData({ toolId: data.textBoxId[1], content: event.currentTarget.value })
                    }}
                    placeholder='&#9679; Fill in…' />
                <textarea id={data.textBoxId[2]}
                    className={`SS_Case_Study_1-outer-textbox roboto tct-scrollable`}
                    style={{ height: '17%', top: '78% ', fontSize: !isFullScreen ? '12px' : '16px' }}
                    value={engagementData[data.textBoxId[2]] || ''}
                    onChange={(event) => {
                        manageToolData({ toolId: data.textBoxId[2], content: event.currentTarget.value })
                    }}
                    placeholder='&#9679; Fill in…' />
            </div>
        </div>
    );
}