import React from "react";
import LeftNav from "../LeftNav";
import AnalyticsIFrame from "./AnalyticsIFrame";

export default function Analytics() {
  return (
    <div
      id="resources"
      className="ir-tisa-font"
      style={{ display: "flex", height: "100%" }}
    >
      <LeftNav />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          overflowY: "hidden",
        }}
      >
        <AnalyticsIFrame />
      </div>
    </div>
  );
}
