import config from "../config"

export const isACP = (config.ACP_SHOW && window.location.host === config.ACP_HOST) ? true : false
export const isAP = false
// export const isBPUT = (config.BPUT_SHOW && window.location.host === config.BPUT_HOST) ? true : false
export const isBPUT = true
export const isIR = false

export const isIRHost = (config.IR_SHOW && window.location.host === config.IR_HOST)
export const isAPHost = (config.AP_SHOW && window.location.host === config.AP_HOST)

export const AuthHost = config.AUTH_HOST

export const showBlog = isIRHost || isAPHost || isBPUT ? true : false

export const IFRAME_URL = config.IFRAME_URL
// 
export const ANALYTICS_URL = config.ANALYTICS_URL
export const RATING_URL = config.RATING_URL

// export const isIR = (config.IR_SHOW && window.location.host === config.IR_HOST) ? true : false

// export const isUTPolice = (config.UT_SHOW && window.location.host === config.UT_HOST) ? true : false

export const isArrayEqual = (array1, array2) => {
    // Both array should be valid
    // Both array length should be same

    return Array.isArray(array1) && Array.isArray(array2) && array1.length == array2.length && array1.every((value, index) => {
        return value == array2[index]
    })
}