import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Tooltip from '@material-ui/core/Tooltip';
import './style.css';
import QuizIcon from './assets/quiz_black_24dp.svg'
import { startPostSession, createAction, endSession, getTestSubmissions } from '../containers/application/redux_actions';
import { connectToANode, listenToChildAdd, updateData } from '../utilities/firebase/fb_rtdb';
import { Button, CircularProgress } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { mdiConsoleLine } from '@mdi/js';

export default ({ width, height, data, typPC, isFullScreen, engagementData, manageToolData, engagementStatus }) => {

    let [boardState, setBoardState] = useState({})
    let [loader, setLoader] = useState(false)
    const { user_token } = useSelector((state) => state.auth)
    const app = useSelector((state) => state.app);
    const { user, createdSession, assessment_start, jobDone } = app || {}
    const sessionId = createdSession["id"] || createdSession["_id"] || ''
    let { actions, instruction } = data || {};

    async function manualRefresh() {
        setLoader(true)
        await getTestSubmissions({ user_token, sessionId }).then((res) => setLoader(false))
    }

    async function sendActionData(action, event) {
        await createAction({
            body: {
                ...action,
                userId: event.senderId,
                programId: user.programId,
                sessionId
            },
            user_token
        }).then(res => res)
    }

    useEffect(() => {
        if (sessionId) {
            connectToANode({
                refString: `test/live_session/${sessionId}/Participants`,
                f: (x) => { setBoardState(x) }
            });
        }
        startPostSession({ sessionId, user_token })

    }, [sessionId])

    console.log(data?.liveListenerOn)

    const renderStudentNameList = () => {
        let tempArr = [];
        if (Object.keys(boardState || {}).length > 0) {
            Object.keys(boardState || {}).filter(uid => boardState?.[uid]).map((uid, index) => {
                let student = boardState[uid]?.attendence?.eventData || {}
                let color = boardState[uid]?.attendence?.[data?.liveListenerOn] ? '#3ACA0F' : ''
                tempArr.push(<>
                    <Tooltip title={<h3 style={{ color: 'white' }}>{student?.username}</h3>} arrow key={student?.studentId} className='ir-student-name-list'>
                        <div style={{
                            display: 'flex', alignItems: 'center',
                            fontSize: 16, fontWeight: 400, color: '#0000008a', gap: '0.5rem'
                        }}>
                            {color ? <CheckCircleIcon style={{ fill: color }} /> :
                                <span style={{ width: '20px', height: '20px', borderRadius: '50%', background: 'white', border: '2px solid #0000008a' }}></span>}
                            <span style={{ textTransform: 'capitalize' }}>{student.username}</span>
                        </div>
                    </Tooltip>
                </>)
            })

            if (tempArr?.length % 5 !== 0) {
                let tempLen = tempArr?.length % 5;
                let diff = 5 - tempLen;
                if (tempLen) {
                    for (let i = 0; i < diff + 5; i++)
                        tempArr.push(<div key={'dummy div ' + i} className='ir-student-name-list'>&nbsp;</div>)
                }
            }
            return tempArr;
        }
        else
            return null;
    }

    return (
        <div style={{ width, height, background: '#EEEEEE' }}>
            <div className='w-100 h-100' style={{ padding: '0 2% 2%' }}>
                <div className='h-35 overFlowYAuto tct-scrollable-darkgray'>
                    <div className='post-session-title'>
                        <img src={QuizIcon} style={{ width: '2em', marginRight: '0.5em' }} />{data.title}
                    </div>
                    <div className='d-flex' style={{ margin: '1em 0' }}>
                        <div className='ir-post-session-assessment-code'>
                            {
                                typPC !== "Prepare" && app?.otpData?.otp && <span>{data?.codeName || 'Assessment Code'}: <span className='ir-post-session-otp' >{app?.otpData?.otp}</span></span>
                            }
                        </div>
                        <div className='ir-post-session-module-name'>
                            {data?.myAppModules?.map(item => <div key={item} className='post-session-module-names'>{item}</div>)}
                        </div>
                    </div>
                </div>
                <div className='ir-post-session-name-list' style={{ height: isFullScreen ? '75%' : '52%' }}>
                    <div style={{ fontSize: '0.8em', height: '15%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span >({(Object.keys(boardState || {}).filter(uid => boardState[uid]?.attendence)).length}/{(Object.keys(boardState || {}).filter(uid => boardState[uid]?.attendence)).length}) {data?.codeDescription || 'participants completed assessment'}</span>
                    </div>
                    <div className='d-flex flex-wrap overFlowYAuto tct-scrollable-darkgray h-85 align-content-baseline' style={{ position: 'relative' }}>
                        {renderStudentNameList()}
                        {loader && <CircularProgress style={{
                            position: 'absolute', left: '50%',
                            top: '50%',
                        }} />}
                    </div>
                </div>
            </div>
        </div >
    );
}