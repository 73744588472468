import React from 'react';
import { Input } from '@material-ui/core'
import TextEditor from '../../../Tools/TextEditor/TextEditor';

import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let descriptionSize = '10px';

    let textbox_1_top = '48.5%';
    let textbox_1_right = '23%';

    let textbox_2_top = '18.5%';
    let textbox_2_right = '32.5%';

    let textbox_3_top = '38%';
    let textbox_3_right = '41.5%';

    let textbox_4_top = '59.5%';
    let textbox_4_right = '39%';

    let textbox_5_top = '76%';
    let textbox_5_right = '25.5%';

    let textbox_6_top = '67%';
    let textbox_6_right = '11.5%';

    let textbox_7_top = '44.5%';
    let textbox_7_right = '4%';

    let textbox_8_top = '22.5%';
    let textbox_8_right = '11%';

    let textbox_9_top = '25%';
    let textbox_9_right = '62%';

    if (isFullScreen) {
        descriptionSize = '13px';
        textbox_1_right = '28.5%';
        textbox_2_right = '37%';
        textbox_3_right = '45%';
        textbox_4_right = '42.5%';
        textbox_5_right = '30%';
        textbox_6_right = '17%';
        textbox_7_right = '10%';
        textbox_8_right = '17%';
        textbox_9_right = '62%';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className={`ISE_Growing_Multiple_Directions_textBox roboto`} style={{ fontSize: descriptionSize, top: textbox_1_top, right: textbox_1_right }}>
                    <TextEditor id={data.textBoxId[0]} initialData={engagementData[data.textBoxId[0]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...' hideToolbar={true} scrollable='tct-scrollable'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
                <div className={`ISE_Growing_Multiple_Directions_textBox roboto`} style={{ fontSize: descriptionSize, top: textbox_2_top, right: textbox_2_right }}>
                    <TextEditor id={data.textBoxId[1]} initialData={engagementData[data.textBoxId[1]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...' hideToolbar={true} scrollable='tct-scrollable'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
                <div className={`ISE_Growing_Multiple_Directions_textBox roboto`} style={{ fontSize: descriptionSize, top: textbox_3_top, right: textbox_3_right }}>
                    <TextEditor id={data.textBoxId[2]} initialData={engagementData[data.textBoxId[2]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...' hideToolbar={true} scrollable='tct-scrollable'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
                <div className={`ISE_Growing_Multiple_Directions_textBox roboto`} style={{ fontSize: descriptionSize, top: textbox_4_top, right: textbox_4_right }}>
                    <TextEditor id={data.textBoxId[3]} initialData={engagementData[data.textBoxId[3]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...' hideToolbar={true} scrollable='tct-scrollable'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
                <div className={`ISE_Growing_Multiple_Directions_textBox roboto`} style={{ fontSize: descriptionSize, top: textbox_5_top, right: textbox_5_right }}>
                    <TextEditor id={data.textBoxId[4]} initialData={engagementData[data.textBoxId[4]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...' hideToolbar={true} scrollable='tct-scrollable'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
                <div className={`ISE_Growing_Multiple_Directions_textBox roboto`} style={{ fontSize: descriptionSize, top: textbox_6_top, right: textbox_6_right }}>
                    <TextEditor id={data.textBoxId[5]} initialData={engagementData[data.textBoxId[5]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...' hideToolbar={true} scrollable='tct-scrollable'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
                <div className={`ISE_Growing_Multiple_Directions_textBox roboto`} style={{ fontSize: descriptionSize, top: textbox_7_top, right: textbox_7_right }}>
                    <TextEditor id={data.textBoxId[6]} initialData={engagementData[data.textBoxId[6]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...' hideToolbar={true} scrollable='tct-scrollable'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
                <div className={`ISE_Growing_Multiple_Directions_textBox roboto`} style={{ fontSize: descriptionSize, top: textbox_8_top, right: textbox_8_right }}>
                    <TextEditor id={data.textBoxId[7]} initialData={engagementData[data.textBoxId[7]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...' hideToolbar={true} scrollable='tct-scrollable'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
                {
                    data.textBoxId[8] && <Input className='ISE_Growing_Deep_input'
                        placeholder="Fill Here."
                        style={{ fontSize: descriptionSize, top: textbox_9_top, right: textbox_9_right }}
                        value={engagementData[data.textBoxId[8]] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: data.textBoxId[8], content: event.target.value })
                        }}
                    />
                }
            </div>
        </div>
    );
}