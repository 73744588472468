import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';

import left_img from './assets/left-img.png';
import center_img from './assets/center-img.png';

import "./style.css";


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let leftText = '14px';
    let rightText = '14px';
    if (isFullScreen) {
        leftText = '22px';
        rightText = '28px';
    }
    return (
        <div style={{ width, height, display: 'flex' }}>
            <div className='scrollable' style={{ width: '30%', height: '100%', padding: '1rem', paddingBottom: '0rem', display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                <div className='font-work-sans' style={{ marginBottom: '10px', fontWeight: 'bold', fontSize: leftText }}>{data.title}</div>
                <img src={data.leftImg ? data.leftImg : left_img} width='100%' alt='illumine' />
                <img src='https://pedgog2.s3.ap-south-1.amazonaws.com/Becoming+future+ready+contributors/01-+Think+solutions/Illuminetoollogo.png' width='60%' alt='illumine-logo' />
            </div>
            <div className='scrollable' style={{ width: '70%', height }}>
                <div className='font-work-sans' style={{ height: '10%', textAlign: 'center', padding: '10px', fontSize: rightText }}>{data.heading}</div>
                <div style={{ width: '100%', display: 'flex', height: '20%', justifyContent: 'space-evenly' }}>
                    <div style={{ width: '30%', display: 'flex' }}>
                        <TextEditor id={data.textBoxId[0]} initialData={engagementData[data.textBoxId[0]] || null}
                            manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.' isFullScreen={isFullScreen}
                            wrapperClass='text-editor-think-sol-1' />
                    </div>
                    <div style={{ width: '30%', display: 'flex' }}>
                        <TextEditor id={data.textBoxId[1]} initialData={engagementData[data.textBoxId[1]] || null}
                            manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.' isFullScreen={isFullScreen}
                            wrapperClass='text-editor-think-sol-1' />
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', height: '50%' }}>
                    <div style={{ flexGrow: '1', width: '25%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', marginRight: '25px' }}>
                        <div style={{ display: 'flex', height: '40%' }}>
                            <TextEditor id={data.textBoxId[2]} initialData={engagementData[data.textBoxId[2]] || null}
                                manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.' isFullScreen={isFullScreen}
                                wrapperClass='text-editor-think-sol-1' />
                        </div>
                        <div style={{ display: 'flex', height: '40%' }}>
                            <TextEditor id={data.textBoxId[3]} initialData={engagementData[data.textBoxId[3]] || null}
                                manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.' isFullScreen={isFullScreen}
                                wrapperClass='text-editor-think-sol-1' />
                        </div>
                    </div>
                    <div style={{ width: '30%', padding: "35px 10px" }}>
                        <img src={data.centerImg ? data.centerImg : center_img} width='100%' height='100%' alt='illumine' />
                    </div>
                    <div style={{ flexGrow: '1', width: '25%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', marginRight: '25px' }}>
                        <div style={{ display: 'flex', height: '40%' }}>
                            <TextEditor id={data.textBoxId[4]} initialData={engagementData[data.textBoxId[4]] || null}
                                manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.' isFullScreen={isFullScreen}
                                wrapperClass='text-editor-think-sol-1' />
                        </div>
                        <div style={{ display: 'flex', height: '40%' }}>
                            <TextEditor id={data.textBoxId[5]} initialData={engagementData[data.textBoxId[5]] || null}
                                manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.' isFullScreen={isFullScreen}
                                wrapperClass='text-editor-think-sol-1' />
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', height: '20%', justifyContent: 'space-evenly' }}>
                    <div style={{ width: '30%', display: 'flex' }}>
                        <TextEditor id={data.textBoxId[6]} initialData={engagementData[data.textBoxId[6]] || null}
                            manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.' isFullScreen={isFullScreen}
                            wrapperClass='text-editor-think-sol-1' />
                    </div>
                    <div style={{ width: '30%', display: 'flex' }}>
                        <TextEditor id={data.textBoxId[7]} initialData={engagementData[data.textBoxId[7]] || null}
                            manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.' isFullScreen={isFullScreen}
                            wrapperClass='text-editor-think-sol-1' />
                    </div>
                </div>
            </div>

        </div>
    );
}