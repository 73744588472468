import React, { Component } from "react";
import {
  // Link, 
  Redirect
} from "react-router-dom";
// import { Button } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
// import Footer from "../../components/acp/Footer"
import IRLoginPage from "../IndianRailways/AuthRoute";
import Config from "../../config"
import { isACP, isAP, isIR } from "../../utilities/common";
// let LoginLink = props => <Link to="./auth/login" {...props} />
// let RegisterLink = props => <Link to="./auth/register" {...props} />

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  input: {
    display: 'none'
  }
});

let Public = class Public extends Component {

  render() {
    // const { classes } = this.props;
    // if (isACP)
    //   return <ACPHomePage />
    // if (isAP)
    //   return <APLoginPage />
    if (isIR)
      return <div className='ir-tisa-font w-100 h-100'>
        <IRLoginPage />
      </div>
    else {
      return <Redirect to={{
        pathname: "/auth/login",
        state: { from: window.location.href },
      }
      } />
    }
  }
}

export default withStyles(styles)(Public)