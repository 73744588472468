import React from 'react';
import './style.css'
import Modal_Component from '../../../Tools/Modal_Component';
export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let descriptionSize = '14px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '18px';
    }

    // const textBoxHeight = ['11%', '14%', '20%', '18%', '14.5%', '30%']
    const right = {
        "Second3": '65.5%',
        "Third3": '38.5%',
        "Four3": '11.5%'
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {/* {
                    data.textBoxId.map((textBoxId, i) => <textarea key={textBoxId} id={textBoxId} placeholder='Fill more…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ fontSize: descriptionSize, position: 'absolute', width: '18.5%', top: top[i], right: right[i], height: '20%' }}
                        value={engagementData[textBoxId] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: textBoxId, content: event.currentTarget.value })
                        }}
                    />)
                } */}

                <textarea key={data.prevTextBoxId} id={data.prevTextBoxId}
                    className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll text-center'}
                    style={{ fontSize: descriptionSize, position: 'absolute', width: '20.5%', top: '11%', right: '39.5%', height: '13%', fontWeight: 'bold', color: "#4375c1" }}
                    value={engagementData[data.prevTextBoxId] || ''}
                    readOnly
                />

                {
                    engagementData && engagementData[data.prevIdeaBoardId] && Object.keys(engagementData[data.prevIdeaBoardId]).map(key => {
                        if (key === 'First3')
                            return null
                        return <div key={key} className='prevfilled-ideaboard-text-step-3 tct-scrollable-darkgray' style={{ right: right[key], fontSize: descriptionSize }}> {
                            engagementData[data.prevIdeaBoardId][key].item.map(item =>
                                <div key={item}>●&nbsp;&nbsp;{item.text}</div>
                            )}
                        </div>
                    })
                }
                <textarea id={data.textBoxId} placeholder='Fill Here…'
                    className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ fontSize: descriptionSize, position: 'absolute', width: '61.5%', top: '72%', right: '19%', height: '23%' }}
                    value={engagementData[data.textBoxId] || ''}
                    onChange={(event) => {
                        manageToolData({ toolId: data.textBoxId, content: event.currentTarget.value })
                    }}
                />
                <div className='Think-sol-sp-step_1_hint-popup'>
                    <Modal_Component
                        type={data.popUp_content.type}
                        icon={data.popUp_content.icon}
                        Component={<div className='Think-sol-sp-step_1_hint-popup-component tct-scrollable-darkgray'>{data.popUp_content.src}</div>}
                        width={width}
                        height={height}
                    />
                </div>
            </div>
        </div>
    );
}