import React from "react";
import Trust_O_Meter from "../../../Live_Session_Tools/Trust_O_Meter";
import Trust_Meter from "../../../LearningTools/Trust_Meter";
import { useSelector } from "react-redux";

export default ({
  width,
  height,
  engagementData,
  manageToolData,
  isFullScreen,
  data,
  currentEngagement, typPC,
  sessionId,
  // engagementStatus,
}) => {
  const engagementStatus = useSelector((state) => state.app.engagementStatus);
  return (
    <div>
      {/* <Trust_Meter
        width={width}
        height={height}
        engagementData={engagementData}
        manageToolData={manageToolData}
        data={data}
        isFullScreen={isFullScreen}
        engagementStatus={engagementStatus}
      /> */}
      <Trust_O_Meter
        currentEngagement={currentEngagement}
        typPC={typPC}
        width={width}
        height={height}
        engagementData={engagementData}
        manageToolData={manageToolData}
        data={data}
        sessionId={sessionId}
        isFullScreen={isFullScreen}
        engagementStatus={engagementStatus}
      />
    </div>
  );
};
