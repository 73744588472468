import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import "./style.css";
import Close_Button from "../../../components/images/Tools-img/Close_Button.png";
import EIS_Left_Img from "../../../components/images/Tools-img/EIS/ESI_Slide_45_Right_Img.png";

export default function TransitionsModal({ popUpImg, isFullScreen }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <div className='button-click-effect' style={{ width: '95%', height: '45%' }} onClick={() => handleOpen()}>
                <img src={EIS_Left_Img} style={{ width: '100%', height: '100%' }} alt='icon' />
            </div>

            <Modal
                className="modal"
                open={open}
                onClose={handleClose}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div style={{ border: 'none', outline: 'none' }}>
                        <div>
                            <img src={popUpImg} style={{ width: '1000px' }} />
                            <img src={Close_Button} className="close-button" onClick={handleClose} />
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
