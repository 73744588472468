import { useSelector } from 'react-redux';
import React, {
  // useState,
  useEffect
} from 'react';
import _ from "lodash";
import './style.css';
import { v4 } from 'uuid';
// import { ReactPDF,PDFViewer } from '@react-pdf/renderer';
// import MyDocument from '../../components/PDF/index'
import { studentResponseDelete } from '../../containers/application/redux_actions';
// import Button from '@material-ui/core/Button';
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";
// import MyDocument from "../PDF/index"
// import checkbox from '../../Tools/check_Box'
// import Pdf_Document from '../Pdf_Pages'
export default ({ width, height, engagementData, manageToolData, data }) => {

  const responses = useSelector(state => state.app.response[data.engagement]);
  console.log(responses)
  // const students = useSelector(state => state.app.uniqueStudentsName[data.engagement]);
  // const respo_count = useSelector(state => state.app.responseCount[data.engagement]);

  // const [search, setSearch] = useState('')
  // const [text, setText] = useState('')
  // const userId = useSelector(state => state.auth.user.id)
  // const otp = useSelector(state => state.app.otpData.otp)

  useEffect(() => {
    // Interaction.initalize(data.engagement, etah, "Coach", userId, "Student", otp)
  }, [data])
  useEffect(() => {

    let tempItem = responses ? Object.keys(responses).map(r => {
      return ({ id: v4(), Message: r, group: responses[r][0].user })
    }) : []
    // let traders=tempItem.filter((t) => {
    //   console.log(t)
    //   console.log(t.group)
    //   return t.group === "Sagar";
    // })

    if (!engagementData || !engagementData[data.sessionType]) {
      manageToolData({
        toolId: data.sessionType, content: {
          Farmer: {
            title: "Farmer",   //key
            item: tempItem
          },
          Trader: {
            title: " Traders",
            item: []
          },
          Government: {
            title: "Government",
            item: []
          },
          Bank: {
            title: "Bank",
            item: []
          }
        }
      })
    }
    else {

      manageToolData({
        toolId: data.sessionType, content: {
          ...engagementData[data.sessionType],
          Farmer: {
            title: "Farmer",   //key
            item: [...engagementData[data.sessionType]['Farmer'].item, ...tempItem]
          },
          // Bank: {
          //   title: "Bank",
          //   item:[...engagementData[data.sessionType]['Bank'].item, ...traders]
          // }
        }
      })

      if (tempItem.length > 0) studentResponseDelete({ engId: data.engagement })
    }


  }, [responses])


  return (
    <div style={{ width, height, display: 'flex' }}>
      {_.map(engagementData[data.sessionType], (data, key) => {
        return (
          <div key={key} className={key}>
            <div>
              {data && data.item && data.item.map((el, index) => {
                console.log(el)
                return (
                  <div className={"item"}>
                    <div className="ideas">
                      {el.Message}
                    </div>
                  </div>




                )
              })}



            </div>


          </div>
        )
      })}


      <div>

      </div>
    </div>
  );

}