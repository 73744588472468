// import React from 'react';
// import Start_N_Stop from '../../../Live_Session_Tools/Start_N_Stop'

// export default ({ width, height, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId }) => {

//     return (
//         <div style={{ width, height }}>
//             <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
//                 <div style={{ position: 'absolute', top: '21%', right: '2%', width: '20%', height: '15%' }}>
//                     {
//                         // (sessionId) &&
//                         <Start_N_Stop width={width} height={height}
//                             engagementData={engagementData}
//                             manageToolData={manageToolData}
//                             data={data}
//                             isFullScreen={isFullScreen}
//                             engagementStatus={engagementStatus}
//                             sessionId={sessionId}
//                         />
//                     }
//                 </div>
//             </div>
//         </div >
//     );
// }
import React from "react";
import Bar_Chart from "../../../Live_Session_Tools/Bar_Chart";
import Start_N_Stop from "../../../Live_Session_Tools/Start_N_Stop";

export default ({
  width,
  height,
  engagementData,
  manageToolData,
  isFullScreen,
  data,
  engagementStatus,
  sessionId,
  responses,
  currentEngagement,
  uniqueStudentsName,
  currentUnit,
}) => {
  return (
    <div style={{ width, height }}>
      <div
        style={{
          backgroundImage: `url(${data.bgImg})`,
          width: "100%",
          height: "100%",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "21%",
            right: "2%",
            width: "20%",
            height: "15%",
          }}
        >
          {
            // (sessionId) &&
            <Start_N_Stop
              width={width}
              height={height}
              engagementData={engagementData}
              manageToolData={manageToolData}
              data={data}
              isFullScreen={isFullScreen}
              engagementStatus={engagementStatus}
              sessionId={sessionId}
            />
          }
        </div>
        {/* <div style={{ width: "80%", height: "100%" }}>
          <Bar_Chart
            width="80%"
            height="100%"
            engagementData={engagementData}
            manageToolData={manageToolData}
            data={data}
            isFullScreen={isFullScreen}
            engagementStatus={engagementStatus}
            sessionId={sessionId}
            responses={responses}
            currentEngagement={currentEngagement}
            uniqueStudentsName={uniqueStudentsName}
            currentUnit={currentUnit}
          />
        </div> */}
      </div>
    </div>
  );
};
