import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
// import Interaction from "../../containers/application/Interaction"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "lodash";
import './styles.css';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { v4 } from 'uuid';
import Button from '@material-ui/core/Button';
import EdiText from 'react-editext';
// import AddIcon from '@material-ui/icons/Add';
import logo from './Group 3140.svg'
import plus from './Group 3460.svg'
import Start_N_Stop from '../Start_N_Stop'
import TextField from '@material-ui/core/TextField';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import EditIcon from '@material-ui/icons/Edit';
import { studentResponseDelete } from '../../containers/application/redux_actions';
// import store from "../../Redux_Store"
import SendIcon from '@material-ui/icons/Send';
import Menu from '@material-ui/core/Menu';
// import DeleteIcon from '@material-ui/icons/Delete';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId }) => {

  const responses = useSelector(state => state.app.response[data.engagement]);
  const students = useSelector(state => state.app.uniqueStudentsName[data.engagement]);
  const respo_count = useSelector(state => state.app.responseCount[data.engagement]);
  console.log(respo_count)
  const [search, setSearch] = useState('')

  const [text, setText] = useState('')
  // const userId = useSelector(state => state.auth.user.id)
  // const otp = useSelector(state => state.app.otpData.otp)
  // const [dragApp, setDragApp] = useState({
  //   //columns
  //   "First1": {
  //     title: "Ideal",   //key
  //     item:
  //       responses && Object.keys(responses).map((r) =>
  //         ({ id: v4(), text: r, user: responses[r].map((u) => u.user), tags: responses[r].map((u) => u.tags) })),
  //   },
  //   "Second1": {
  //     title: "DragBox",
  //     item: []
  //   },
  // })
  // useEffect(() => {
  //   function etah(event) {    // event type and event handler (etah) 
  //     switch (event.eventName) {
  //       case "START":
  //         console.log("Hello")
  //         break;
  //       case "STOP":
  //         // Interaction.stopEngagement()
  //         console.log("Bye")
  //         break
  //       case "SUBMIT":
  //         console.log("Submit")
  //         store.dispatch({ type: "STUDENT_RESPONSE", payload: { userId: event.senderId, data: event.eventData, engagementId: event.engagementId } })
  //         break;
  //     }
  //   }
  //   Interaction.initalize(data.engagement, etah, "Coach", userId, "Student", otp)
  // }, [data])
  useEffect(() => {
    // Interaction.initalize(data.engagement, etah, "Coach", userId, "Student", otp)
  }, [data])
  useEffect(() => {

    let tempItem = responses ? Object.keys(responses).map(r => {
      return ({ id: v4(), text: r, user: responses[r].map((u) => u.user), tags: responses[r].map((u) => u.tags) })
    }) : []
    if (!engagementData || !engagementData[data.sessionType]) {
      manageToolData({
        toolId: data.sessionType, content: {
          First1: {
            title: "Ideal",   //key
            item: tempItem
          },
          Remove: {
            item: []
          },
          Second1: {
            title: "DragBox",
            item: []
          }
        }
      })
    }
    else {

      manageToolData({
        toolId: data.sessionType, content: {
          ...engagementData[data.sessionType],
          First1: {
            title: "Ideal",   //key
            item: [...engagementData[data.sessionType]['First1'].item, ...tempItem]
          },
        }
      })

      if (tempItem.length > 0) studentResponseDelete({ engId: data.engagement })
    }

    // setDragApp(
    //  {...dragApp, "First1":{
    //   title:"Ideal",   //key
    //   item:
    //   responses && Object.keys(responses).map((r) => 
    //     ({id: v4(), text: r, user: responses[r].map((u)=>u.user),tags: responses[r].map((u)=>u.tags)})), 
    // }})
  }, [responses])


  // adding Teacher Inputs or Ideas 

  const handleChange = (e) => {
    setText(e.target.value)
  }
  const addItem = (e) => {
    if (text === "") {
      alert("Cannot add empty box")
      return
    }

    manageToolData({
      toolId: data.sessionType, content: {
        ...engagementData[data.sessionType],
        First1: {
          title: "Ideal",
          item: [{
            id: v4(),
            text: text,
            user: ["teacher"],
            tags: []
          },
          ...engagementData[data.sessionType]['First1'].item || []
          ]
        }
      }
    })
    // setDragApp(prev => {
    //   console.log(prev)
    //   let temp = prev.First1.item || []
    //   return {
    //     ...prev,
    //     First1: {
    //       title: "Ideal",
    //       item: [{
    //         id: v4(),
    //         text: text,
    //         user: ["teacher"],
    //         tags: []
    //       },
    //       ...temp
    //       ]
    //     }
    //   }
    // }

    // )
    setText('')
  }

  // Editing Ideas 
  // let noOfStudentsSize = '5px';
  // let studentRespondedSize = '5px'
  // if (isFullScreen) {
  //     noOfStudentsSize = '10px';
  //     studentRespondedSize = '12px'
  // }
  const handleSave = (key, e, newValue) => {
    // let index = dragApp[key].item.findIndex((i) => i.id === e.id)
    // if (dragApp[key] && dragApp[key].item) {
    //   setDragApp({ ...dragApp, [key]: { ...dragApp[key], item: [...dragApp[key].item.slice(0, index), { ...dragApp[key].item[index], text: newValue }, ...dragApp[key].item.slice(index + 1)] } })
    // } 
    let index = engagementData[data.sessionType][key].item.findIndex((i) => i.id === e.id)
    if (engagementData[data.sessionType][key] && engagementData[data.sessionType][key].item) {
      manageToolData({
        toolId: data.sessionType,
        content: {
          ...engagementData[data.sessionType],
          [key]: {
            ...engagementData[data.sessionType][key],
            item: [
              ...engagementData[data.sessionType][key].item.slice(0, index),
              { ...engagementData[data.sessionType][key].item[index], text: newValue },
              ...engagementData[data.sessionType][key].item.slice(index + 1)
            ]
          }
        }
      })
    }
  }

  // Remove Ideas from list

  const handleRemove = (key, id) => {
    // let index = dragApp[key].item.findIndex((i) => i.id === id)
    // let items = dragApp[key].item.filter((i) => i.id !== id)
    // setDragApp({ ...dragApp, [key]: { ...dragApp[key], item: items } })
    let items = engagementData[data.sessionType][key].item.filter((i) => i.id !== id)
    manageToolData({
      toolId: data.sessionType,
      content: {
        ...engagementData[data.sessionType],
        [key]: {
          ...engagementData[data.sessionType][key],
          item: items
        }
      }
    })
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // handling drag & drop 

  const handleDragEnd = ({ destination, source }) => {

    if (!destination) {
      return
    }
    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return
    }

    const itemCopy = { ...engagementData[data.sessionType][source.droppableId].item[source.index] }
    let temp = { ...engagementData[data.sessionType] }
    temp[source.droppableId].item.splice(source.index, 1)
    temp[destination.droppableId].item.splice(destination.index, 0, itemCopy)
    manageToolData({ toolId: data.sessionType, content: temp })

  }


  return (
    <div style={{ width, height, display: 'flex' }}>

      <DragDropContext onDragEnd={handleDragEnd}>
        <div style={{ width: '30%', height: '100%', padding: '1%', paddingTop: '0' }}>
          <div style={{ width: '105%', height: '10%', position: "relative", right: "3%", bottom: "2%" }}>
            <img src={logo} className='w-100' style={{ height: '150%' }} />
          </div>
          <div style={{ display: "flex", width: '100%', height: '10%', marginTop: '7%', alignItems: 'center' }}>
            <div style={{ width: '20%', height: '100%' }}>
              <img src={'https://pedgog2.s3.ap-south-1.amazonaws.com/Sample+engagements/ST-Illumine+School/bulb.png'} className='w-100 h-100' />
            </div>
            <h4 style={{ width: '80%', color: "orange" }}> Share your insights </h4>
          </div>
          <div style={{ width: '100%', height: '8%', display: 'flex', alignItems: 'center' }}>
            <TextField type="text"
              placeholder="Search..."
              variant="outlined"
            
              inputProps={{
                style: {
                  height:'30px',
                  padding: '0 14px',
                },
            }}
              className='searchbox'
              onChange={(e) => { setSearch(e.target.value) }} />
            {/* teacher text box     */}


          </div>
          <div className='tct-scrollable-darkgray' style={{ width: '100%', height: '6%', overflowY: 'auto',boxShadow: 'rgb(0 0 0 / 16%)',border:'1px solid gray',borderRadius:'4px',background: 'transparent  0% 0% no-repeat padding-box' }}>
            {/* Here the remove deck will be there */}
            <Droppable droppableId={'Remove'}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className='w-100 h-100'
                  >
                    {/* {data.item.length === 0 &&  */}
                    <div style={{display:'flex',justifyContent:'center',marginTop:'1%'}}><DeleteOutlineTwoToneIcon/> Archive Response</div>
                       {/* } */}
                    {
                      engagementData && engagementData[data.sessionType] && engagementData[data.sessionType]['Remove'] && engagementData[data.sessionType]['Remove'].item && engagementData[data.sessionType]['Remove'].item.map((el, index) =>
                        <Draggable key={el.id} index={index} draggableId={el.id}>

                          {(provided) => {
                            return (
                              <div
                                className={"item1"}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div>
                                  {el.text}
                                </div>
                              </div>
                            )
                          }}
                        </Draggable>
                      )}
                    {provided.placeholder}
                  </div>
                )
              }}
            </Droppable>
        
          </div>
          <div className='tct-scrollable-darkgray' style={{ width: '100%', height: '56%', overflowY: 'auto' }}>
            {/* Here we need to put put first key */}


            <Droppable droppableId={'First1'}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className='w-100 h-100'
                  >
                    {
                      engagementData && engagementData[data.sessionType] && engagementData[data.sessionType]['First1'] && engagementData[data.sessionType]['First1'].item && engagementData[data.sessionType]['First1'].item.filter((el) => {

                        // if (data.title !== "Ideal") {
                        //   return el
                        // }
                        let user = el.user.map((u) => {
                          return u.toLowerCase()
                        })


                        if (search === "" || el.text.includes(search.toLocaleLowerCase()) || user.toString().includes(search.toLocaleLowerCase())) {
                          return el
                        }

                      })
                        .map((el, index) => {

                          return (

                            <Draggable key={el.id} index={index} draggableId={el.id}>

                              {(provided) => {

                                return (
                                  <div
                                    className={"item1"}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >

                                    <span className="ideas">
                                      {/* Editing Ideas  */}
                                      <div style={{ display: "flex" }}>
                                        <div className="user">{el.user}</div>
                                        <span className="delete">
                                          {/* Removing Ideas from list */}

                                          <DeleteForeverIcon
                                            fontSize="small"
                                            onClick={() => handleRemove('First1', el.id)}></DeleteForeverIcon>
                                        </span>
                                      </div>
                                      <div className="ed">
                                        <EdiText
                                          viewContainerClassName='my-custom-view-wrapper0'
                                          type='textarea'
                                          inputProps={{
                                            rows: 5
                                          }}
                                          hideIcons
                                          cancelOnEscape
                                          submitOnUnfocus
                                          editOnViewClick

                                          value={el.text}


                                          onSave={(newValue) => handleSave('First1', el, newValue)} ></EdiText>




                                      </div>
                                    </span>

                                  </div>

                                )
                              }}
                            </Draggable>
                          )
                        })}
                    {provided.placeholder}
                  </div>
                )
              }}
            </Droppable>

          </div>

          <div style={{ width: '100%', height: '8%', margin: 'auto', display: 'flex', boxShadow: 'rgb(0 0 0 / 16%) 0px -3px 6px' }}>
            {/* Here we need to put the start and stop button */}
            <div style={{ width: '15%', height: '90%' }}>
              <Button
                className='arrowbutton'
                size='small'
                aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <div className="plusimg">
                  <img src={plus} style={{ height: '60px', position: 'relative', top: '10px' }} />
                </div>
              </Button>
              <Menu
                className='popup'
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                  <TextField
                    style={{ width: '100%', marginLeft: '2%' }}
                    type="text"
                    placeholder="add..."
                    variant="outlined"
                    size="small"
                    value={text}
                    onChange={handleChange} />
                  <Button color="secondary" onClick={addItem} >
                    <SendIcon fontSize='small' style={{ marginTop: "2%" }} />
                  </Button>
                </div>
              </Menu>
            </div>
            <div className='s&s'>
              <Start_N_Stop
                engagementData={engagementData}
                manageToolData={manageToolData}
                data={data}
                isFullScreen={isFullScreen}
                engagementStatus={engagementStatus}
                sessionId={sessionId}
              />

            </div>
            {/* <div>
          <span style={{marginRight:'10px'}}>{student_reponse} total nubers</span>
          </div> */}
            <div className='sudentresp'
            // style={{lineHeight:studentRespondedSize, marginTop:'9%'}}
            >
              <div
              // style={{ fontSize: noOfStudentsSize }}
              >{students && students.length ? students.length : 0}</div>
              <div
                style={{ fontSize: '60%' }}
              // style={{ fontSize: studentRespondedSize, marginTop:'19%' }}
              >Students </div>
            </div>
            <div className='response'>
              {respo_count && respo_count.length ? respo_count.length : 0}
              <div style={{ fontSize: '60%' }}>Responses</div>
            </div>
          </div>

        </div>


        {/* End of Left Panel */}
        <div style={{ backgroundColor: '#4375c1', padding: '2%', width: '70%', height: '100%' }}>
          <div style={{ width: '100%', height: '15%' }}>
            <h4 style={{ color: "white" }}> Based on the exploration thus far -- </h4>
            <h2 style={{ color: "white" }}> What does it mean to <span style={{ color: "orange", display: 'inline' }}>Think solutions? </span></h2>

          </div>

          <div className='DragBox' style={{ width: '100%', height: '85%' }}>
            {_.map(engagementData[data.sessionType], (data, key) => {
              if (key === 'First1' || key === 'Remove') return
              return (
                <Droppable key={key} droppableId={key}>
                  {(provided) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className='w-100 h-100 tct-scrollable-darkgray overFlowYAuto d-flex flex-wrap align-content-flex-start'
                      >

                        {data.item.length === 0 && <div className="IdeaBord_poll1_placeholdername flex-center w-100 h-100"
                        >Drag & Drop Insights!</div>}
                        {
                          data && data.item && data.item
                            .map((el, index) => {
                              return (
                                <Draggable key={el.id} index={index} draggableId={el.id}>

                                  {(provided) => {

                                    return (
                                      <div
                                        className="itemR w-45 "
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <span className="ideasR">
                                          {/* Editing Ideas  */}
                                          {/* <div style={{ display: "flex" }}>
                                            <div className="user">{el.user}</div>
                                           
                                          </div> */}
                                          <div className="edR">
                                            <span className="delete">


                                              <DeleteForeverIcon
                                                fontSize="small"
                                                onClick={() => handleRemove(key, el.id)}></DeleteForeverIcon>
                                            </span>
                                            <EdiText

                                              viewContainerClassName='my-custom-view-wrapper0 '
                                              type='textarea '
                                              inputProps={{
                                                rows: 5
                                              }}
                                              hideIcons
                                              cancelOnEscape
                                              submitOnUnfocus
                                              editOnViewClick
                                              value={el.text}


                                              onSave={(newValue) => handleSave(key, el, newValue)} ></EdiText>


                                          </div>
                                        </span>
                                      </div>

                                    )
                                  }}
                                </Draggable>


                              )
                            })}

                        {provided.placeholder}
                      </div>
                    )
                  }}
                </Droppable>
              )
            })}


          </div>
        </div>
      </DragDropContext>
    </div>
  );
}