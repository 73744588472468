import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';

import './style.css';
import chatIcon from './assets/chat_icon.png';
// import Picture1 from './assets/Picture1.png';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let titleSize = '20px'
    let descriptionSize = '12.5px';
    let headingSize = '16px';
    if (isFullScreen) {
        titleSize = '30px';
        headingSize = '24px'
        descriptionSize = '18px';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ height: '20%', width: '100%', padding: '1rem', lineHeight: '24px' }}>
                <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: titleSize, color: '#e64708' }}>
                    {data.title}
                </div>
                <div className='font-work-sans' style={{ paddingLeft: "10px" }} >
                    {data.subTitle}
                </div>
            </div>
            <div style={{ height: '80%', width: '100%', display: 'flex' }}>
                <div className='scrollable' style={{ width: '30%', height: '100%', padding: '1rem', paddingTop: "0px" }}>
                    <div className='font-work-sans'>{data.example}</div>
                    <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: headingSize, marginTop: '10%' }}>{data.heading}</div>
                    <img src={data.imgUrl} style={{ width: '95%', height: "45%", margin: '5% 0' }} alt='illumine-logo' />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={chatIcon} style={{ width: '20%' }} alt='illumine-logo' />
                        <div style={{ fontSize: descriptionSize }}>Discuss to list down.</div>
                    </div>
                </div>
                <img src='https://pedgog2.s3.ap-south-1.amazonaws.com/Becoming+future+ready+contributors/01-+Think+solutions/Illuminetoollogo.png' style={{ width: '12%', position: 'absolute', bottom: '0' }} alt='illumine-logo' />
                <div style={{ width: '70%', height: '100%', display: 'flex', padding: '1rem' }}>
                    <div style={{ width: '46%', height: '80%', marginTop: '5%', backgroundColor: '#dbdbad', marginRight: '4%' }}>
                        <div style={{ height: '20%', textAlign: 'center' }}>
                            <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: headingSize }}>Price</div>
                            <div className='font-work-sans' style={{ fontSize: descriptionSize, textAlign: 'center' }}>{data.box_questions[0]}</div>
                        </div>
                        <div style={{ height: '80%', display: 'flex' }}>
                            <TextEditor id={data.textBoxId[0]} initialData={engagementData[data.textBoxId[0]] || null}
                                manageToolData={manageToolData} placeholder='&#9679; Fill here...'
                                wrapperClass='text-editor-value-thinking-price'
                                textEditorClass='text-editor-class-with-bullet'
                            />
                        </div>
                    </div>
                    <div style={{ width: '46%', height: '80%', marginTop: '5%', backgroundColor: '#e1f098' }}>
                        <div style={{ height: '20%', textAlign: 'center' }}>
                            <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: headingSize }}>Value</div>
                            <div className='font-work-sans' style={{ fontSize: descriptionSize, textAlign: 'center' }}>{data.box_questions[1]}</div>
                        </div>
                        <div style={{ height: '80%', display: 'flex' }}>
                            <TextEditor id={data.textBoxId[1]} initialData={engagementData[data.textBoxId[1]] || null}
                                manageToolData={manageToolData} placeholder='&#9679; Fill here...'
                                wrapperClass='text-editor-value-thinking-price'
                                textEditorClass='text-editor-class-with-bullet'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}