import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import APConductDialog from '../ConductDialog';
import Icon from '@mdi/react';
import { mdiArrowRight, mdiClipboardArrowDown } from '@mdi/js';
import DownloadButton from './DownloadButton';
import Modal from '../../../components/walkThrough/modal';
import { useSelector } from 'react-redux';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Button, Checkbox, Dialog, FormControlLabel } from '@material-ui/core';

export default function ModuleCard({ moduleIndexText = null, firstModule, index, title, description, mainFilePath, moduleId, uniqueAboutIt, user_token, coach_id, auth, app, estimatedTime, a, data, worksheetURL, prepare }) {
    let walkThrough = useSelector((state) => state.app.walkThrough)
    let { currentEl, allElements, stop } = walkThrough;

    let [confirm, setConfirm] = useState({ open: false, finalCheckBox: false, runDemo: false })
    let { open, finalCheckBox, runDemo } = confirm

    const titleRef = useRef('');
    const { t } = useTranslation();
    const conductLink = <div className={index == 0 && !stop && allElements[currentEl] == 'walkConductDiv' ? 'zIndexPos onClickDisabled' : ''}>
        <div className='ir-prepare-conduct-button h-100 ap-conduct-hover-color'>
            <div>{t('CONDUCT')}</div>
            <Icon path={mdiArrowRight}
                size={1}
                style={{ verticalAlign: "middle" }}
            />
        </div>
    </div>

    let popUpHTml = "<ul><li>This is for your <strong>personal preparation only</strong>. Don't conduct a session with participants, here.</li><li><strong>Conduct guide</strong> is given for each frame. There are also <strong>extra key points</strong>.</li><li>Preparation experience is better without full-screen mode</li></ul>"

    return (
        <div key={description} className='d-flex w-100' style={{ margin: '3% 0' }} >
            {index == 0 && !stop && allElements[currentEl] == 'walkModuleDiv' && <Modal id={'walkModuleDiv'} Comp={false} />}
            {index == 0 && !stop && allElements[currentEl] == 'walkPrepareDiv' && <Modal id={'walkPrepareDiv'} Comp={false} />}
            {index == 0 && !stop && allElements[currentEl] == 'walkConductDiv' && <Modal id={'walkConductDiv'} Comp={false} />}
            <div
                className={index == 0 && !stop && allElements[currentEl] == 'walkModuleDiv' ? 'zIndexPos onClickDisabled ap-module-card-box-shadow ir-module-card overflow-hidden moduleCardDiv ' : 'ap-module-card-box-shadow ir-module-card overflow-hidden moduleCardDiv'}
            >
                <div ref={titleRef} className='ir-module-card-title-container'>

                    {moduleIndexText ? null : <div className='ir-module-card-number'>{(Object.keys(data).length == 0 ? (index + 1) : ((a + 1) + '.' + (index + 1)))}</div>}
                    <div style={{ margin: "0.15rem 0rem 1rem" }}>
                        <div style={{ fontSize: "13px", color: "#6A6A6A", fontWeight: 500 }}>
                            {moduleIndexText}
                        </div>
                        <div style={{ margin: '1rem 0rem 0rem' }} className='ir-module-card-title'>
                            {description}</div>
                    </div>
                </div>

                <div className='ap-module-card-img text-center'>
                    <div>
                        {prepare &&
                            !confirm.open ?
                            <div
                                className={index == 0 && !stop && allElements[currentEl] == 'walkPrepareDiv ir-prepare-conduct-button h-100 ap-prepare-hover-color' ? 'onClickDisabled ir-prepare-conduct-button h-100 ap-prepare-hover-color' : 'ir-prepare-conduct-button h-100 ap-conduct-hover-color'}
                                onClick={() => setConfirm({ open: true })}
                            >
                                <div >{t("PREPARE")}</div>
                                <Icon path={mdiArrowRight}
                                    size={1}
                                    style={{ verticalAlign: "middle" }}
                                />
                                {/* </Link> */}
                            </div> :
                            <Dialog
                                open={open}
                                classes={{ paper: 'borderradius20px' }}
                            >
                                <div style={{ gap: '6vh', padding: '4vh 4vw', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    <div onClick={() => setConfirm({ open: false })} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', color: '#484848' }}>
                                        <ArrowBackIosIcon onClick={() => setConfirm({ open: false })} style={{ fontSize: '1rem', }} />
                                        BACK
                                    </div>
                                    <div style={{
                                        color: '#BA1F00', fontWeight: 500, fontSize: '1rem',
                                    }}>BEFORE YOU START PREPARING FOR A SESSION PLEASE NOTE</div>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: popUpHTml }}
                                        style={{ lineHeight: '1.7rem', borderRadius: '8px', fontSize: '1.1rem', display: 'flex', flexDirection: 'column', backgroundColor: '#F8F8F8', padding: '28px 36px' }}>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {/* <FormControlLabel
                                            style={{ width: '70%' }}
                                            value="end"
                                            control={<Checkbox
                                                onClick={() => { setConfirm((old) => ({ ...old, finalCheckBox: !finalCheckBox })) }}
                                                color="primary" />}
                                            label="Please check this box if  you've read the above points and want to continue"
                                            labelPlacement="end"
                                        /> */}

                                        <Link
                                            to={`/application/prepare/${moduleId}`}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <Button
                                                style={{ padding: '10px 16px', borderRadius: '20px', border: '1px solid black' }}>Continue
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </Dialog>
                        }


                        <APConductDialog
                            moduleIndexText={moduleIndexText}
                            moduleId={moduleId}
                            title={title}
                            description={description}
                            index={index}
                            mainFilePath={mainFilePath}
                            user_token={user_token}
                            coach_id={coach_id}
                            firstModule={firstModule}
                            testType="pre"
                            auth={auth}
                            app={app}
                            buttonTextComponent={conductLink}
                            buttonClassName=''
                            rootClassName=''
                            estimatedTime={estimatedTime}
                        />
                        {/* <div className='h-40 b-class download-button' style={{ width: "fit-content" }}> */}

                        {/* </div> */}
                    </div>
                </div>
                {worksheetURL && <DownloadButton
                    icon={mdiClipboardArrowDown}
                    text="Download worksheet"
                    href={worksheetURL} />}
                {/* <img src={mainFilePath} className='h-100' alt='mainFilePath' />
                    <div className="ir-prepare-conduct-button">
                        <div className="ap-text tablet-gothic">
                            <Link className='ap-prepare-hover ap-prepare-hover-color' to={`/application/prepare/${moduleId}`} style={{ color: 'white', fontSize: '1.5rem' }} >{t("PREPARE")} &nbsp;&nbsp; <span style={{ fontSize: 'xx-large' }}>&#129122;</span></Link>
                            <APConductDialog
                                moduleId={moduleId}
                                title={title}
                                description={description}
                                mainFilePath={mainFilePath}
                                user_token={user_token}
                                coach_id={coach_id}
                                testType="pre"
                                auth={auth}
                                app={app}
                                buttonTextComponent={conductLink}
                                buttonClassName='ap-prepare-hover ap-conduct-hover-color h-100 color-white font-size-1-5rem'
                                rootClassName='h-50'
                            />

                        </div>
                    </div> */}

            </div>
        </div >
    )
}
