import { exchangeFirebase as fb, blogFirebase } from "./fb_init";
import { getFirestore, collection, getDoc, getDocs, doc, 
 onSnapshot, setDoc, addDoc, where, query } from "firebase/firestore";

const fs = getFirestore(fb);
const blogFirestore = getFirestore(blogFirebase);

let getIdToAdd = async ({ collectionId }) => {
 let docRef = doc(collection(fs, collectionId));
 return docRef.id;
}

let listenToDocByDocId = async ({ collectionId, docId, callback }) => {
 return onSnapshot(doc(fs, collectionId, docId), (d) => callback(d.data()));
}

let listenToMultipleDocs = async ({ collectionId, condition, callback }) => {
 let {a, o, b} = condition;
 return onSnapshot(query(collection(fs, collectionId), where(a, o, b)),
  callback, e => { console.log(e) });
}

let fetchDocByDocId = async ({ collectionId, docId }) => {
 let d = await getDoc(doc(fs, collectionId, docId));
 if (d.exists()) { return d.data() }
 else { return null }
}

let fetchDocsByCollectionId = async ({ collectionId }) => {
 let a = await getDocs(collection(fs, collectionId));
 let b = {};
 a.forEach(doc => {
  b[doc.id] = doc.data();
 });
 return b;
}

let addADoc = async ({ collectionId, data }) => {
 let docRef = await addDoc(collection(fs, collectionId), data);
 return docRef.id;
}

let setADoc = async ({ collectionId, docId, data }) => {
 console.log({ collectionId, docId, data });
 return setDoc(doc(fs, collectionId, docId), data, { merge: true });
}

const initialiseBlogFirebase = async (a, onInitialize, onComplete) => {
 let q = query(collection(blogFirestore, "modules"), where("courseId", "in", a))
 getDocs(q).then((modules) => {
  modules.forEach(doc => {
   let d = doc.data();
   if (d.medias) { delete d.medias }
   (doc.data().medias || []).forEach(async (m, i) => {
    getDoc(m).then(b => {
     if (b.exists) {
      onComplete(doc, b)
     }
    })
   });
   onInitialize(doc, d)
  });
 }).catch(e => { console.log(e) })
}

const initialiseHowToFirebase = async (onInitialize, onComplete) => {
 getDocs(collection(blogFirestore, "howTo")).then((ht) => {
  ht.forEach((doc, j) => {
   let d = { ...doc.data() };
   if (d.medias) { delete d.medias }
   (doc.data().medias || []).forEach(async (m, i) => {
    getDoc(m).then(b => {
     if (b.exists) {
      onComplete(doc, b)
     }
    }).catch(e => { console.log(e) });
    onInitialize(doc, d)
   });

  });
 }).catch(e => { console.log(e) })
}

const initialiseReadings = async (onInitialize, onComplete) => {
 getDocs(collection(blogFirestore, "readings")).then((ht) => {
  ht.forEach((doc, j) => {
   let d = { ...doc.data() };
   if (d.medias) { delete d.medias }
   (doc.data().medias || []).forEach(async (m, i) => {
    getDoc(m).then(b => {
     if (b.exists) {
      onComplete(doc, b)
     }
    }).catch(e => { console.log(e) });
    onInitialize(doc, d)
   });

  });
 }).catch(e => { console.log(e) })
}

export {
 fetchDocsByCollectionId,
 getIdToAdd,
 listenToDocByDocId,
 fetchDocByDocId,
 addADoc, setADoc,
 initialiseBlogFirebase,
 initialiseHowToFirebase,
 listenToMultipleDocs,
 initialiseReadings
}