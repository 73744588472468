import { useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import Interaction from "../../../containers/application/Interaction"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "lodash";
import './style.css';
// import { v4 } from 'uuid';
// import Button from '@material-ui/core/Button';
import logo from '../../../Live_Session_Tools/Images/Group 3760.png'
// import stakeholders from '../../../Live_Session_Tools/Images/Slide4.png'
// import plus from './Group 3460.svg'
import Start_N_Stop from '../../../Live_Session_Tools/Start_N_Stop'
// import TextField from '@material-ui/core/TextField';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import { studentResponseDelete } from '../../../containers/application/redux_actions';
import store from "../../../Redux_Store"
// import SendIcon from '@material-ui/icons/Send';
// import Menu from '@material-ui/core/Menu';
// import Checkbox from '@material-ui/core/Checkbox';
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";
import Config from "../../../config"
const etah = (event) => {
  switch (event.eventName) {
    case "JOIN_SESSION":
      store.dispatch({ type: "STUDENT_JOINED", payload: event.eventData })
      console.log(event)
      break;
    case "SUBMIT":
      console.log("Submit")
      store.dispatch({ type: "STUDENT_RESPONSE", payload: { userId: event.senderId, data: event.eventData, engagementId: event.engagementId } })
      break;
    case "TEAM_BOARD_SUBMIT":
      console.log("TEAM_BOARD_SUBMIT")
      store.dispatch({ type: "TEAM_BOARD_STUDENT_RESPONSE", payload: { userId: event.senderId, data: event.eventData, engagementId: event.engagementId } })
      break;
    default:
      console.log(event)
  }

}

// take away API
export function saveTakeway(Formdata) {
  console.log('saveTakeway-->>', Formdata)
  if (Formdata) {
    fetch(Config.API_URL + 'session/takeaway', {
      //fetch('http://localhost:5001/v1/' + 'session/takeaway', {
      //  fetch('http://uat.pedgog.in:3000/v1/progress/createProgressPercentage', {
      //fetch('https://beta.pedgog.in:3000/v1/progress/createProgressPercentage', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        "accept": "application/json"
      },
      body: JSON.stringify(Formdata)
    })
      .then(response => response.json())
      .then(data1 => {
        console.log(data1);
      }
      )
  }
}
export default ({ width, height, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId }) => {

  useEffect(() => {
    // Interaction.initalize(data.engagement, etah, "Coach", userId, "Student", otp)
  }, [data])

  // start student join session
  useEffect(() => {
    Interaction.startSession(sessionId, etah)
  }, [sessionId])


  const studentJoinedList = useSelector(state => state.app.studentJoinedList);
  // const joinedStudentId = useSelector(state => state.app.joinedStudentId);
  // const userId = useSelector(state => state.auth.user.id)
  // const otp = useSelector(state => state.app.otpData.otp)

  // console.log(studentJoinedList)
  console.log(data)
  // const [check, setCheck] = useState([]);
  const [groupkey, setGroupkey] = useState('')
  const inputRef = useRef(null);
  useEffect(() => {
    // console.log(tempItem)
    // const objs = [...new Map(tempItem.map(item => [JSON.stringify(item), item])).values()];
    // console.log(objs)
    // console.log(studentJoinedList)
    console.log((!engagementData || !engagementData[data.sessionType]) && studentJoinedList.length !== 0)
    if ((!engagementData || !engagementData[data.sessionType]) && Object.keys(studentJoinedList).length !== 0) {
      manageToolData({
        toolId: data.sessionType, content: {
          LeftDeck: {
            title: "Ideal",   //key
            item: studentJoinedList
          },
          ...data.teamNames,

          // 'Financial & transactional value​': {
          //   title: "Financial & transactional value​",
          //   item: {}
          // },
          // "Utility value": {
          //   title: "Utility value",
          //   item: {}
          // },
          // "Experiential value": {
          //   title: "Experiential value",
          //   item: {}
          // },
          // "Human & social value": {
          //   title: "Human & social value",
          //   item: {}
          // }
        }
      })
    }
    else {
      for (let stuId in studentJoinedList) {
        let flag = true
        for (let teamName in engagementData[data.sessionType]) {
          // console.log(!engagementData[data.sessionType][teamName].item[stuId], stuId, teamName)
          if (engagementData[data.sessionType][teamName].item[stuId]) {
            // console.log(stuId)
            flag = false
          }
        }
        if (flag) {
          // console.log('helo')
          manageToolData({
            toolId: data.sessionType, content: {
              ...engagementData[data.sessionType],
              LeftDeck: {
                title: "Ideal",   //key
                item: { ...engagementData[data.sessionType]['LeftDeck']['item'], [stuId]: studentJoinedList[stuId] }
              },
            }
          })
        }
      }
    }


    //   const tempAsyncFunction = async () => {
    //     for (let i in studentJoinedList) {
    //       let student = studentJoinedList[i];
    //       console.log(student)
    //       let temp = await store.dispatch({ type: "CHECK_STUDENT_JOINED", payload: student.userId })
    //       console.log(joinedStudentId)
    //       console.log(temp)
    //       if (temp) {
    //         if (!joinedStudentId.includes(student.userId)) {
    //           manageToolData({
    //             toolId: data.sessionType, content: {
    //               ...engagementData[data.sessionType],
    //               LeftDeck: {
    //                 title: "Ideal",   //key
    //                 item: [...engagementData[data.sessionType]['LeftDeck'].item, student]
    //               },
    //             }
    //           })
    //         }
    //       }
    //     }
    //   }
    //   tempAsyncFunction();
    // }
  }, [studentJoinedList])


  // adding Teacher Inputs or Ideas 
  // console.log(engagementData[data.sessionType])

  // const handleSend = (event) => {
  //   alert(check.length + ' student added to ' + groupkey)
  //   manageToolData({
  //     toolId: data.sessionType, content: {
  //       ...engagementData[data.sessionType],

  //       LeftDeck: {
  //         item: engagementData[data.sessionType]["LeftDeck"].item.filter(d => {
  //           let findItemInCheckedItems = check.some(el => el.userId === d.userId);
  //           if (!findItemInCheckedItems) {
  //             return true
  //           }
  //           else {
  //             return false
  //           }
  //         })

  //       },
  //       [groupkey]: {
  //         ...engagementData[data.sessionType][groupkey],
  //         item: check
  //       }
  //     }
  //   })
  //   // Interaction.sendEvent("ASSIGN_GROUP", { ...engagementData[data.sessionType] })
  //   setCheck('')
  // }



  // const handleCheck = (key, userId, username, el, event) => {
  //   // console.log(el)
  //   if (check.indexOf(el) === -1)
  //     setCheck([...check, el]);
  //   else
  //     setCheck(check.filter(item => item !== el));

  // };
  // const handleRandom = () => {

  //   let mainDeck = engagementData[data.sessionType]['LeftDeck'].item
  //   let randomindex = Math.floor(Math.random() * mainDeck.length)
  //   var randomsplice = mainDeck.splice(randomindex, 3)


  //   // let rand= mainDeck ?[mainDeck.sort(function() {return 0.5 - Math.random()})]:[]
  //   // console.log(rand)
  //   manageToolData({
  //     toolId: data.sessionType, content: {
  //       ...engagementData[data.sessionType],
  //       // LeftDeck:{
  //       //   item:engagementData[data.sessionType]["LeftDeck"].item.filter(d => {
  //       //     console.log(d)
  //       //     console.log(rand)
  //       //     if (rand.indexOf(d)===-1)

  //       //     return true
  //       //     else
  //       //     return false
  //       //   })

  //       // },
  //       [groupkey]: {
  //         ...engagementData[data.sessionType][groupkey],
  //         item: randomsplice

  //       }
  //     }
  //   })

  // }





  const handleGroup = (key) => {
    setGroupkey(key)

  }

  // Take away event for testing 
  // const generatePDF = () => {
  //   // // var doc= new jsPDF("p","pt","a4");
  //   // // doc.html(document.querySelector('#content'),{
  //   // //   callback: function(pdf){
  //   // //     pdf.save("component.pdf");
  //   // //   }
  //   // // })

  //   html2canvas(inputRef.current).then((canvas) => {
  //     const imgData = canvas.toDataURL(logo);
  //     const imgData1 = canvas.toDataURL(stakeholders);
  //     const imgData2 = canvas.toDataURL(plus);
  //     const doc = new jsPDF();
  //     doc.addImage(imgData, "png", 0, 0);
  //     doc.addImage(imgData1, "png", 0, 0);
  //     doc.addImage(imgData2, "svg", 0, 0);
  //     doc.save("two-by-four.pdf");
  //     var pdf = btoa(doc.output());
  //     console.log(pdf)
  //     var Formdata = new FormData();
  //     console.log(data.sessionType)
  //     console.log(sessionId)
  //     Formdata.append('engagementId', data.sessionType);
  //     Formdata.append('sessionId', sessionId)
  //     Formdata.append('data', pdf);

  //     saveTakeway(Formdata);
  //   })
  // }
  // const clickHandler = (key) => {
  //   return (
  //     <PDFDownloadLink document={<MyDoc  data={data[key]} />} fileName="somename.pdf">
  //       {({ blob, url, loading, error }) => (loading ? 'Loading document...' : url)}
  //     </PDFDownloadLink> 
  //   )
  // }

  // handling drag & drop 

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return
    }
    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return
    }

    let temp = engagementData[data.sessionType]
    let itemCopy = engagementData[data.sessionType][source.droppableId].item[source.index]
    // console.log(destination, source, temp[source.droppableId].item)
    // console.log(temp)
    if (temp[source.droppableId]) {
      // console.log('yes')
      Object.keys(temp[source.droppableId].item).map((el, el_index) => {
        if (el_index === source.index) {
          itemCopy = engagementData[data.sessionType][source.droppableId].item[el]
          delete temp[source.droppableId].item[el];
        }
      })
      let beforeDestination = {}, afterDestination = {}
      if (temp[destination.droppableId]) {
        Object.keys(temp[destination.droppableId].item).map((el, el_index) => {
          if (el_index < destination.index) {
            beforeDestination = {
              ...beforeDestination,
              [el]: temp[destination.droppableId].item[el]
            }
          }
          else {
            afterDestination = {
              ...afterDestination,
              [el]: temp[destination.droppableId].item[el]
            }
          }
        })
        temp[destination.droppableId] = {
          ...temp[destination.droppableId],
          item: {
            ...beforeDestination,
            [itemCopy.userId]: itemCopy,
            ...afterDestination
          }
        }
      }
      // console.log(temp)
      manageToolData({ toolId: data.sessionType, content: temp })
    }
    // Object.keys(temp[source.droppableId].item).splice(source.index, 1)
    // console.log(Object.keys(temp[source.droppableId].item).splice(source.index, 1))
    // Object.keys(temp[destination.droppableId].item).splice(destination.index, 0, itemCopy)
    // console.log(Object.keys(temp[destination.droppableId].item).splice(destination.index, 0, itemCopy))

  }


  return (
    <div style={{ width, height, display: 'flex' }} id="divToPrint" ref={inputRef}>

      <DragDropContext onDragEnd={handleDragEnd}>
        <div id="content" style={{ width: '30%', height: '100%', border: '1px solid black' }}>
          <div style={{ width: '100%', height: '10%' }}>
            <img src={logo} className='w-100' style={{ height: '100%' }} />
          </div>
          <div style={{ display: "flex", width: '100%', height: '10%', marginTop: '1.5%', alignItems: 'center' }}>
            <h4 style={{ width: '94%', margin: 'auto' }}>{engagementData[data.sessionType] && engagementData[data.sessionType][groupkey] && Object.keys(engagementData[data.sessionType][groupkey].item).length} added in {groupkey} </h4>
          </div>

          <div className='tct-scrollable-darkgray' style={{ width: '100%', height: '68%', overflowY: 'auto' }}>
            {/* Here we need to put put first key */}


            <Droppable droppableId={'LeftDeck'}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className='w-100 h-100'
                  >
                    {
                      engagementData && engagementData[data.sessionType] && engagementData[data.sessionType]['LeftDeck'] && engagementData[data.sessionType]['LeftDeck'].item && Object.keys(engagementData[data.sessionType]['LeftDeck'].item).map((_el, index) => {

                        let el = engagementData[data.sessionType]['LeftDeck']['item'][_el]

                        return (

                          <Draggable key={el.userId} index={index} userId={el.userId} draggableId={el.userId}>

                            {(provided) => {

                              return (

                                <div
                                  className={"item1"}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {/* <Checkbox onChange={(event) => handleCheck('LeftDeck', el.userId, el.username, el, event)} name={el.username} /> */}
                                  <span className="ideas">

                                    <div className="ed">
                                      {/* {console.log(el)} */}
                                      {el.username}

                                    </div>
                                  </span>
                                </div>

                              )
                            }}
                          </Draggable>
                        )
                      })}
                    {provided.placeholder}
                  </div>
                )
              }}
            </Droppable>

          </div>

          <div style={{ width: '100%', height: '11%', margin: 'auto', display: 'flex', boxShadow: 'rgb(0 0 0 / 16%) 0px -3px 6px' }}>
            {/* Here we need to put the start and stop button */}
            <div className='w-100 h-100' style={{padding:'7px'}}>
              <Start_N_Stop
                assignGroup={true}
                engagementData={engagementData}
                manageToolData={manageToolData}
                data={data}
                isFullScreen={isFullScreen}
                engagementStatus={engagementStatus}
                sessionId={sessionId}
              />
            </div>
            {/* <div style={{ display: 'flex' }}>
              <Button>
                Cancel
              </Button>
              <Button style={{ backgroundColor: "yellow" }} onClick={handleSend}>
                Add Students
              </Button>
            </div>

          </div> */}

          </div>
        </div>


        {/* End of Left Panel */}
        <div style={{ padding: '2%', width: '70%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', overflow: "hidden" }}>
          {/* <div style={{ padding: '2%', width: '70%', height: '100%', backgroundImage: 'url(' + stakeholders + ')', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', overflow: "hidden" }}> */}


          {/* Randomise button */}
          <div style={{ width: '100%', height: '5%' }}>
            {/* <Button onClick={handleRandom}>Random</Button> */}
          </div>


          {/* Team formation deck */}
          <div className='rightCoulms tct-scrollable-darkgray' style={{ width: '100%', height: '95%', display: 'flex', flexWrap: 'wrap', overflowY: 'auto' }}>
            {_.map(engagementData[data.sessionType], (data, key) => {
              // console.log(data, key)
              if (key === 'LeftDeck') return
              return (
                <div className={key} key={key} style={{ margin: '10px', width: '29%' }} >
                  {key}
                  <Droppable key={key} droppableId={key}>
                    {(provided) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className='tct-scrollable-darkgray' style={{ height: '150px', width: '100%', border: '1px solid', overflowY: 'auto', backgroundColor: '#F9D76F', border: groupkey.indexOf(key) !== -1 && '2px solid' }}
                          value={key}
                          onClick={() => handleGroup(key)}
                        >
                          {
                            data && Object.keys(data.item).length > 0 && Object.keys(data.item)
                              .map((_el, index) => {
                                // console.log(_el, data)
                                let el = data['item'][_el];
                                return (
                                  <Draggable key={el.userId} index={index} draggableId={el.userId}>

                                    {(provided) => {

                                      return (
                                        <div
                                          className='d-flex flex-column'
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <span className='student-name'>
                                            {el.username}
                                          </span>
                                        </div>
                                      )
                                    }}
                                  </Draggable>
                                )
                              })}

                          {provided.placeholder}
                        </div>
                      )
                    }}
                  </Droppable>
                </div>
              )
            })}
          </div>
          {/* <div className='stopstart'>
            <Start_N_Stop
              assignGroup={true}
              engagementData={engagementData}
              manageToolData={manageToolData}
              data={data}
              isFullScreen={isFullScreen}
              engagementStatus={engagementStatus}
              sessionId={sessionId}
            />
          </div> */}


        </div>

      </DragDropContext>

      {/* Takeaway button */}
      {/* <button onClick={generatePDF}>take away</button> */}


    </div>
  );
}