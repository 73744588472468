import React
    //  ,{ useEffect,  useState } 
    from 'react';
import { connect } from "react-redux";
import { getOTP } from "../../containers/application/redux_actions";
import ShareIcon from '@material-ui/icons/Share';
import Button from '@material-ui/core/Button';
import config from '../../config';
// import { createRoom, getResponses, joinRoom } from '../../containers/application/Socket';

const customButton_1 = {
    backgroundColor: '#ffbc36',
    cursor: 'pointer'
}

function OtpScreen({ dimensions, title, description, app, auth, testType, getOTP }) {

    // const [otpAppear, setOtpAppear] = useState(false);

    const clickEvent = () => {
        let user_token = auth.user_token;
        let coachId = auth.user_uid;
        let sessionId = app.createdSession.id;
        if (sessionId) {
            getOTP({ user_token, sessionId, testType, coachId });
            // createRoom(sessionId, coachId);
        }
    }

    const displayOTP = () => {
        if (testType === 'pre') {
            if (Object.keys(app.otpData).length > 0)
                return app.otpData.otp;
            else return <Button style={customButton_1} onClick={clickEvent} >
                Generate OTP
            </Button>
        }
        if (testType === 'post') {
            if (Object.keys(app.postOtpData).length > 0)
                return app.postOtpData.otp;
            else return <Button style={customButton_1} onClick={clickEvent} >
                Generate OTP
            </Button>
        }
    }

    // const clickEvent_2 = () => {
    //     setOtpAppear(true)
    // }

    return (
        <div className="dashboard-cntr" style={{
            width: dimensions.slideWidth,
            height: dimensions.slideHeight, background: '#F2F2F2', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
        }}>
            {/* <div style={{ borderRadius: '20px', background: 'white', textAlign: 'center', padding: '10px', lineHeight: '1.8', margin: '10px' }}> */}
            <div style={{ borderRadius: '20px', background: 'white', textAlign: 'center', padding: '30px', lineHeight: '1.8', margin: '25px' }}>
                <p style={{ fontSize: '22px', fontWeight: 'bold' }}>{title}</p>
                <p style={{ color: "#4D4D4D" }}>{description}</p>

                <p style={{
                    fontSize: '28px', fontWeight: 'bold', border: '2px solid #D1D1D1', borderRadius: '14px',
                    padding: '10px',
                    margin: '20px 70px',
                    background: '#FAFAFA',
                    fontFamily: 'monospace'
                }}>
                    {
                        displayOTP()
                    }
                </p>
                {app.otpData.otp ? <a href={`${config.MyAPP_URL}app/live-session/${app.otpData.moduleId.id}?otp=${app.otpData.otp}`} target="_blank" rel="noopener noreferrer"><ShareIcon /></a> : ""}
            </div>
            {/* <div style={{ borderRadius: '20px', background: 'white', padding: '30px', lineHeight: '1.8', margin: '25px', display: 'flex', flexDirection: 'column', overflowY: 'scroll' }}>
                <p style={{ color: "#4D4D4D" }}>PARTICIPANTS</p>
                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '100%', overflowY: 'scroll' }}>
                    <div style={{ width: '50%', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div>Abhimanyu v</div>
                        <div style={{ width: '14px', height: '14px', background: '#52D748', borderRadius: '7px' }}>&nbsp;&nbsp;&nbsp;</div>
                    </div>
                    <div style={{ width: '50%', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div>Abhimanyu v</div>
                        <div style={{ width: '14px', height: '14px', background: '#52D748', borderRadius: '7px' }}>&nbsp;&nbsp;&nbsp;</div>
                    </div>
                    <div style={{ width: '50%', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div>Abhimanyu v</div>
                        <div style={{ width: '14px', height: '14px', background: '#52D748', borderRadius: '7px' }}>&nbsp;&nbsp;&nbsp;</div>
                    </div>
                    <div style={{ width: '50%', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div>Abhimanyu v</div>
                        <div style={{ width: '14px', height: '14px', background: '#52D748', borderRadius: '7px' }}>&nbsp;&nbsp;&nbsp;</div>
                    </div>
                    <div style={{ width: '50%', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div>Abhimanyu v</div>
                        <div style={{ width: '14px', height: '14px', background: '#52D748', borderRadius: '7px' }}>&nbsp;&nbsp;&nbsp;</div>
                    </div>
                    <div style={{ width: '50%', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div>Abhimanyu v</div>
                        <div style={{ width: '14px', height: '14px', background: '#52D748', borderRadius: '7px' }}>&nbsp;&nbsp;&nbsp;</div>
                    </div>
                    <div style={{ width: '50%', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div>Abhimanyu v</div>
                        <div style={{ width: '14px', height: '14px', background: '#52D748', borderRadius: '7px' }}>&nbsp;&nbsp;&nbsp;</div>
                    </div>
                    <div style={{ width: '50%', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div>Abhimanyu v</div>
                        <div style={{ width: '14px', height: '14px', background: '#52D748', borderRadius: '7px' }}>&nbsp;&nbsp;&nbsp;</div>
                    </div>
                    <div style={{ width: '50%', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div>Abhimanyu v</div>
                        <div style={{ width: '14px', height: '14px', background: '#52D748', borderRadius: '7px' }}>&nbsp;&nbsp;&nbsp;</div>
                    </div>
                    <div style={{ width: '50%', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div>Abhimanyu v</div>
                        <div style={{ width: '14px', height: '14px', background: '#52D748', borderRadius: '7px' }}>&nbsp;&nbsp;&nbsp;</div>
                    </div>
                    <div style={{ width: '50%', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div>Abhimanyu v</div>
                        <div style={{ width: '14px', height: '14px', background: '#52D748', borderRadius: '7px' }}>&nbsp;&nbsp;&nbsp;</div>
                    </div>
                    <div style={{ width: '50%', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div>Abhimanyu v</div>
                        <div style={{ width: '14px', height: '14px', background: '#52D748', borderRadius: '7px' }}>&nbsp;&nbsp;&nbsp;</div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}


const mapStateToProps = state => ({
    app: state.app,
    auth: state.auth
});

const mapDispatchToProps = dispatch => {
    return {
        getOTP: data => dispatch(getOTP(data))
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(OtpScreen);

