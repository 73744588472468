import React from 'react';
import Modal_Component from '../../../Tools/Modal_Component';
import All_Fruit from '../All_Fruit';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className='TOF-All-Fruit-modal-icon'>
                    <Modal_Component
                        type='tool-component'
                        icon={data.icon}
                        Component={<All_Fruit width={width} height={height}
                            engagementData={engagementData}
                            manageToolData={manageToolData}
                            data={data}
                            isFullScreen={isFullScreen} />}
                    />
                </div>
            </div>
        </div>
    );
}