import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import arrow from "./assets/arrow.svg";
import startPreparation from "./assets/startPreparation.svg";
import continuePreparation from "./assets/continuePreparation.svg";
import selfCertifyYourself from "./assets/selfCertifyYourself.svg";
import reviseDeepenEngagement from "./assets/reviseDeepenEngagement.svg";
// import ExpansionPanel from '@material-ui/core/ExpansionPanel';
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import { Button
  // , responsiveFontSizes
 } from "@material-ui/core";
import TextField from "@material-ui/core/Input";
import {
  getPrepareModulesAttempt,
  // getContinueModules,
  getModuleDetails,
  getRoadmapforModule,
  updateFlag,
} from "../../containers/application/redux_actions";
import ModuleCard from "./ModuleCard";
// import SearchIcon from "./assets/search";
// import VideoPopup from "./../Video_Popup";
import "./PrepareForms.css";

class Prepare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chk: 0,
      showSearchBar: false,
      searchText: "",
    };
    // should be optimized
    this.myRef = [
      (this.ref_1 = React.createRef()),
      (this.ref_2 = React.createRef()),
      (this.ref_3 = React.createRef()),
      (this.ref_4 = React.createRef()),
    ];
  }

  async componentDidMount() {
    const {
      getPrepareModulesAttempt,
      getModuleDetails,
      // getRoadmapforModule,
      // updateFlag,
    } = this.props;

    let userid = this.props.auth.user_uid;
    let token = this.props.auth.user_token;

    await getPrepareModulesAttempt({ userid, token }).then((result) => {
      this.setState({ chk: 1 });
      let { availableModule } = this.props.app;
      let availableModules = availableModule;
      availableModules.forEach((module) => {
        getModuleDetails({ id: module.moduleId, token });
        //getRoadmapforModule({ id: module.moduleId, token })
      });
    });
  }

  executeScroll = (i) => this.myRef[i].current.scrollIntoView();

  render() {
    let totSC = 0;
    // const { showSearchBar } = this.state;
    if (this.state.chk === 0) {
      return <div>Loading...</div>;
    } else {
      let { moduleDetails } = this.props.app;
      let availableModule = this.props.app.availableModule.filter((el) => {
        if (
          moduleDetails[el.moduleId] &&
          moduleDetails[el.moduleId].description
        ) {
          return new RegExp(this.state.searchText, "i").test(
            moduleDetails[el.moduleId].description
          );
        }
        return true;
      });
      // let continuePrep = availableModule.filter(el => {
      //   let today = Date.now();
      //   let dt = null; let daysElapsed = null;
      //   if (el.LastPreparedOn) {
      //     dt = new Date(el.LastPreparedOn);
      //     daysElapsed = (today - dt.getTime()) / (1000 * 3600 * 24);
      //   }
      //   return daysElapsed && daysElapsed < 15;
      // });

      let availableToPrepareModules = availableModule.filter((el) => {
        return (
          (el.isCertified === null ||
            el.isCertified === undefined ||
            el.isCertified === false) &&
          el.percentageCompleted === "0"
        );
      });

      let certifiedModules = availableModule.filter((el) => {
        return (
          (el.isCertified === "true" || el.isCertified === true) &&
          el.percentageCompleted === "100"
        );
      });

      let continuePrep = availableModule.filter((el) => {
        return el.percentageCompleted > 0 && el.percentageCompleted < 100;
        // && (el.isCertified === 'false' || el.isCertified === false || el.isCertified === null) ;
      });

      let underCertificationModules = availableModule.filter((el) => {
        return (
          (el.isCertified === null ||
            el.isCertified === false ||
            el.isCertified === "false") &&
          el.percentageCompleted === "100"
        );
      });

      // Helper Functions
      function populateModules(dataArray, config, variant) {
        return dataArray.map((el) => {
          let { moduleId } = el;
          let selfCertifyFlag = 0;

          let module = moduleDetails[moduleId];

          if (module) {
            if (module.SelfCertify) {
              selfCertifyFlag = 1;
              totSC = 1;
            }
            let { title, description } = module;
            let { percentageCompleted } = el;
            return (
              <ModuleCard
                key={moduleId}
                title={title}
                description={description}
                percentageCompleted={parseInt(percentageCompleted) || 0}
                config={config}
                variant={variant}
                moduleId={moduleId}
                selfCertifyFlag={selfCertifyFlag}
              />
            );
          }
          return null;
        });
      }

      const icons = [
        startPreparation,
        continuePreparation,
        selfCertifyYourself,
        reviseDeepenEngagement,
      ];

      let menuItems = [
        {
          title: "Start Preparation",
          dataSet: availableToPrepareModules,
          config: { progressBar: false },
          itemSort: 3,
          noValText:
            "You have already prepared for all modules you had. Contact us to get new modules.",
        },
        {
          title: "Continue Preparation",
          dataSet: continuePrep,
          config: { progressBar: true },
          itemSort: 0,
          noValText: "All available modules for you are prepared completely.",
        },
        {
          title: "Self-certify Yourself",
          dataSet: underCertificationModules,
          config: { progressBar: false },
          itemSort: 1,
          noValText:
            "You don't have any modules ready to self-certify yet. Finish preparing for modules so that you can self-certify yourself on it.",
        },
        {
          title: "Revise & deepen engagement",
          dataSet: certifiedModules,
          config: { progressBar: false },
          itemSort: 2,
          noValText:
            "You haven't self-certified for any modules yet. Note that you need to self-certify yourself for modules before you can conduct them.",
        },
      ];

      menuItems.map((el, i) => {
        if (el.dataSet.length > 0) {
          el.dataSet.map((ell) => {
            let { moduleId } = ell;
            let module = moduleDetails[moduleId];
            if (module) {
              if (module.SelfCertify) {
                totSC = 1;
              }
            }
          });
        }
      });

      if (totSC === 0) {
        menuItems = [
          {
            title: "Start Preparation",
            dataSet: availableToPrepareModules,
            config: { progressBar: false },
            itemSort: 3,
            noValText:
              "You have already prepared for all modules you had. Contact us to get new modules.",
          },
          {
            title: "Continue Preparation",
            dataSet: continuePrep,
            config: { progressBar: true },
            itemSort: 0,
            noValText: "All available modules for you are prepared completely.",
          },
          {
            title: "Revise & deepen engagement",
            dataSet: certifiedModules,
            config: { progressBar: false },
            itemSort: 2,
            noValText:
              "You haven't self-certified for any modules yet. Note that you need to self-certify yourself for modules before you can conduct them.",
          },
        ];
      }
      // menuItems.sort((a, b) => (a.dataSet.length < b.dataSet.length) ? 1 : -1)
      return (
        <div>
          {/* <App_Dashboard_Header showSearchBarFn={showSearchBarFn} showSearchBar={this.state.showSearchBar} /> */}
          {/* <div style={{ display: 'flex', marginBottom: '1.875rem' }}>
            <div className="-headerTitle" style={{ width: '75%' }}>
              <h6>Prepare Zone</h6>
              <h1>Which module do you want to prepare for?</h1>
              <p style={{ margin: '15px 0px' }}>Continuosly improve the quality of the learning & coaching delivery experience of a module. Take up any module to prepare how to conduct, improve class engagement, deepen appreciation of the concepts & ideas.</p>
            </div>
            <div id='filter-container' className="filterStyles">
              <div className="subheading">Find Module</div>
              <div style={{ cursor: 'pointer', display: 'flex', margin: '0rem 1.25rem' }} onClick={() => { this.setState({ showSearchBar: !this.state.showSearchBar }) }}>
                <SearchIcon fill={this.state.showSearchBar ? '#e4001b' : '#8c8c8c'} />
              </div>
            </div>
          </div> */}

          <div className="App">
            <header className="App-header">
              <div style={{ margin: "10px 0px" }}>
                {this.props.app.showSearchBar ? (
                  <form className="search-bar" noValidate autoComplete="off">
                    <TextField
                      id="search-field"
                      value={this.state.searchText}
                      placeholder="Enter a module name to search"
                      classes={{ root: "search-text" }}
                      fullWidth={true}
                      onChange={(ev) => {
                        this.setState({ searchText: ev.target.value });
                      }}
                    />
                    <Button
                      onClick={(ev) => {
                        this.setState({ showSearchBar: false });
                        this.setState({ searchText: "" });
                      }}
                    >
                      Close
                    </Button>
                  </form>
                ) : null}
                {/* <button onClick={()=>{this.setState({showSearchBar:!this.state.showSearchBar})}}>{ this.state.showSearchBar? 'Hide' : 'Show'} Div</button> */}
              </div>
            </header>
          </div>

          <div
            className="dashboard-nav"
            style={{
              backgroundColor: "white",
              margin: "1rem -2.5rem",
              padding: "1.5rem 3.5rem",
            }}
          >
            <div className="subheading">I want to:</div>
            {menuItems.map((el, i) => {
              //if (el.dataSet.length > 0) {
              return (
                <div key={i} style={{ display: "flex" }}>
                  <img style={{ marginLeft: "1.2rem" }} src={icons[i]} />
                  <a
                    key={`menu-items - ${i} `}
                    onClick={() => this.executeScroll(i)}
                    style={{ borderBottom: "none", cursor: "pointer" }}
                  >{`${el.title} (${el.dataSet.length})`}</a>
                  {i < menuItems.length - 1 ? (
                    <img style={{ marginLeft: "1.2rem" }} src={arrow} />
                  ) : null}
                </div>
              );
              //}
            })}
          </div>

          {menuItems.map((el, i) => {
            if (el.dataSet.length > 0) {
              return (
                <section
                  key={`menu - ${el.itemSort} `}
                  ref={this.myRef[i]}
                  id={`menu - ${i} `}
                >
                  <h3 className="explore-titles">
                    <img style={{ marginRight: "1rem" }} src={icons[i]} />
                    {el.title} ({el.dataSet.length})
                  </h3>
                  <div className="grid-wrapper">
                    {populateModules(el.dataSet, el.config, el.itemSort)}
                  </div>
                </section>
              );
            } else if (el.dataSet.length === 0) {
              return (
                <section
                  key={`menu - ${el.itemSort} `}
                  ref={this.myRef[i]}
                  id={`menu - ${i} `}
                >
                  <h3 className="explore-titles">
                    <img style={{ marginRight: "1rem" }} src={icons[i]} />
                    {el.title} ({el.dataSet.length})
                  </h3>
                  <div className="novaltext">{el.noValText}</div>
                </section>
              );
            }
            return null;
          })}
        </div>
      );
    }
  }
}

Prepare.propTypes = {
  getPrepareModulesAttempt: PropTypes.func.isRequired,
  getModuleDetails: PropTypes.func.isRequired,
  updateFlag: PropTypes.func.isRequired,
  authMsg: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  app: state.app,
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPrepareModulesAttempt,
      getModuleDetails,
      getRoadmapforModule,
      updateFlag,
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(Prepare);
