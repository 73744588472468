import React, { useEffect } from "react"
import { IFRAME_URL } from "../../utilities/common"
import { ReactComponent as FullScreenPage } from './full_screen.svg';
import { getOTP } from "../../containers/application/redux_actions";

export default ({
    sessionId,
    toolId,
    item,
    isFullScreen,
    currentScene,
    currentUnit,
    app,
    language,
    params,
    auth,
    genericToolPath,
    queryOptions,
    src
}) => {
    function buildQuery(params) {
        return Object.keys(params)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
    }

    let _data = {
        sessionId,
        isFullScreen,
        toolId,
        learningToolId: currentUnit,
        currentScene,
        engagementId: item?.engagement,
        otp: app?.otpData?.otp,
        otpData: app?.otpData,
        userId: auth.user?.id,
        language,
        token: auth?.user_token,
        ...queryOptions
    }
    let { token, userId, otpData } = _data || {}

    useEffect(() => {
        let otpDataCall = async () => {
            await getOTP({ user_token: token, sessionId, testType: 'pre', coachId: userId })
        }
        if (userId && sessionId && token && !Object.keys(otpData || {}).length > 0) { otpDataCall() }
    }, [userId, sessionId, token])


    useEffect(() => {
        function sendMessage(e) {
            let iframe = document.getElementById(`externalTool-${sessionId}-${item?.engagement}`)
            iframe && iframe.contentWindow && iframe.contentWindow.postMessage(JSON.stringify(_data), "*")
        }
        window.addEventListener("message", sendMessage)

        return () => {
            window.removeEventListener("message", sendMessage)
        }
    }, [IFRAME_URL, sessionId, isFullScreen])

    let _src = `http://localhost:3001`
    // ${genericToolPath}/${sessionId}?${params}&toolId=${toolId || ''}&learningToolId=${currentUnit}&currentScene=1&engagementId=${item?.engagement}&otp=${app?.otpData?.otp}&userId=${auth?.user?.id}&token=${auth?.user_token}&language=${language}`

    // console.log("src", _src)
    // console.log(params ? `${IFRAME_URL}/${genericToolPath}/${sessionId}?${params}` : `${IFRAME_URL}/${genericToolPath}/${sessionId}`)
    return <>
        {isFullScreen ? <iframe
            id={`externalTool-${sessionId}-${item?.engagement}`}
            src={`${IFRAME_URL}/${genericToolPath}/${sessionId}?${buildQuery(_data)}`}
            // src={_src}

            frameborder="0" width="100%" height="100%" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"
        /> : <FullScreenPage style={{ width: '100%', height: '100%' }} />}
    </>
}