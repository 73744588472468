import React from 'react';
import { Link } from 'react-router-dom';

let ModuleCardExplore = ({ title, description, icon, studentName, link }) => {
    return <div key={title} className="module-card">
        {
            icon ?
                <img src={icon} alt="logo" style={{ width: '274px', height: "154px" }}></img>
                : <img src={"http://43.229.227.26:81/acsportal/explore1.jpg"} alt="logo"></img>
        }
        <div className="-card-body">
            {/* <div className="subtitle" style={{ marginBottom: 6, color: "#28282c" }}>{title}</div> */}
            <h5 style={{ margin: '10px 0px', color: "#4d4d4d" }}>{title}</h5>
            <h6>Student Name :  <span style={{ fontSize: '13px', lineHeight: '22px', color: '#4d4d4d' }}>
                {
                    studentName && studentName.map((name, i) => i === studentName.length - 1 ? name : name + ' , ')
                }
            </span></h6>

            {/* <h6>College Name : <span style={{ fontSize: '13px', lineHeight: '22px', color: '#4d4d4d' }}>BPUT</span></h6> */}

            <h6>Description :</h6>
            <span className='project-description-card'>{description}</span>

            <div className="cFoot">
                <div className='button-text'>
                </div>
                <div className='button-text'>
                    <Link className='button-label' to={{
                        pathname: '/application/ViewProject',
                        state: {
                            src: link
                        }
                    }} >View</Link>
                </div>
            </div>
        </div>
    </div>
};

export default ModuleCardExplore;