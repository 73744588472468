import React from 'react'
import Icon from "@mdi/react";
import {
    Info as InfoIcon
} from '@material-ui/icons';
import { mdiCellphoneCheck, mdiCellphoneRemove, mdiCheck } from "@mdi/js";

export default ({ status }) => {

    const RegisteredToolTip = () => <div className='d-flex align-items-center tooltip-size'>
        <Icon path={mdiCellphoneCheck} size={2} color="#3ACA0F" />
        <div style={{ margin: '0px 5px' }}>Participant has Registered on phone</div>
    </div>


    const UnRegisteredToolTip = () => <div className='d-flex align-items-center tooltip-size'>
        <Icon path={mdiCellphoneRemove} size={2} color="red" />
        <div style={{ margin: '0px 5px' }}>Participant has not received notification to register</div>
    </div>

    return <div>
        <div className='info-text'>
            <InfoIcon aria-label="delete" style={{ fill: "#898989", marginRight: '5px' }} />Information
        </div>
        <div className='text-contanier'>
            {
                !status ? <>
                    <RegisteredToolTip />
                    <UnRegisteredToolTip />
                </> : status === 'registered' ? <RegisteredToolTip /> : <UnRegisteredToolTip />
            }
        </div>
    </div>
}
