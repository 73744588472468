import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className='Engaging-deeply-with-subject-leftImg tct-scrollable-darkgray'>
                    <img src={data.leftImg_1} width='100%' height='100%' alt='left-img' />
                    <img src={data.leftImg_2} width='100%' height='100%' alt='left-img' />
                </div>
                <div className='Engaging-deeply-with-subject-textBox' style={{ fontSize: isFullScreen ? '14px' : '12px' }}>
                    <TextEditor id={data.textBoxId} initialData={engagementData[data.textBoxId] || null}
                        manageToolData={manageToolData} placeholder='Fill here...'
                        scrollable='tct-scrollable-darkgray'
                        textEditorClass='UPTC_CS_Square-textEditorClass'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                    />
                </div>
            </div>
        </div >
    );
}