import React, { useEffect } from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {


    let checkBoxTop = ['22%', '35%', '49%', '61%']
    let checkBoxRight = '56.5%';
    let textSize = '10px'
    let textBoxtextSize = '12px'
    if (isFullScreen) {
        checkBoxTop = ['24%', '37%', '51%', '64%'];
        checkBoxRight = '57.5%';
        textSize = '16px';
        textBoxtextSize = '16px'
    }

    useEffect(() => {
        if (!engagementData[data.toolName] || !engagementData[data.toolName][data.id])
            manageToolData({
                toolId: data.toolName, content: {
                    ...engagementData[data.toolName],
                    [data.id]: {
                        bgImg: data.bgImg[0],
                        buttonText: 'Done'
                    }
                }
            })
        // if (engagementData[data.toolName] && engagementData[data.toolName][data.id])
        //     handleClick();

    }, [data])

    const handleClick = () => {
        let index = data.bgImg.findIndex(item => item === engagementData[data.toolName][data.id].bgImg)
        let processedIndex = (index + 1) % data.bgImg.length;
        let buttonText = 'Done'
        if (processedIndex !== 0)
            buttonText = 'Go Back';

        manageToolData({
            toolId: data.toolName, content: {
                ...engagementData[data.toolName],
                [data.id]: {
                    ...engagementData[data.toolName][data.id],
                    bgImg: data.bgImg[processedIndex],
                    buttonText: buttonText
                }
            }
        })
    }

    const handleCheckListChange = (event, item) => {
        manageToolData({
            toolId: data.toolName, content: {
                ...engagementData[data.toolName],
                [data.id]: {
                    ...engagementData[data.toolName][data.id],
                    [item]: event.target.checked
                }
            }
        })
    }

    return (
        <div style={{ width, height }}>
            {engagementData && engagementData[data.toolName] && engagementData[data.toolName][data.id].bgImg && < div style={{ backgroundImage: `url(${engagementData[data.toolName][data.id].bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>


                {
                    Object.keys(data.checkBox).map((item, index) => engagementData[data.toolName][data.id].buttonText === 'Done' ? <Checkbox
                        key={item}
                        style={{
                            position: 'absolute',
                            transform: isFullScreen ? 'scale(1.5)' : 'scale(1)',
                            top: checkBoxTop[index],
                            right: checkBoxRight,
                            color: 'black'
                        }}
                        // color='black'


                        checked={engagementData[data.toolName][data.id][item] || false}
                        onChange={(event) => handleCheckListChange(event, item)}
                    /> : engagementData[data.toolName][data.id][item] === true && <div key={`StarMaker Strategy ${index + 1}`} className={`sma-thinking-strategy-solution-container sma-thinking-strategy-solution-box-${index}`} style={{ fontSize: textSize }} >
                        <div>{`StarMaker Strategy ${index + 1}`}</div>
                        {console.log(item, engagementData[data.toolName][data.id][item])}
                        <div style={{ color: '#D63D00' }}>{data.checkBox[item]}</div>
                    </div>
                    )
                }
                {engagementData[data.toolName][data.id].buttonText !== 'Done' &&
                    <div className='sma-thinking-strategy-textbox' style={{ fontSize: textBoxtextSize, lineHeight: textBoxtextSize }}>
                        <TextEditor toolName={data.toolName} id={data.id} initialData={engagementData[data.toolName][data.id][data.textBoxId] || null}
                            manageToolData={manageToolData}
                            scrollable='tct-scrollable-darkgray'
                            preFilledTextBoxData={data.preFilledTextBoxData ? data.preFilledTextBoxData[data.textBoxId] : false}
                            // textEditorClass='UPTC_CS_Square-textEditorClass'
                            wrapperClass='text-editor-WIAC_diff_in_the_lives'
                            toolBarClass='CD-CNP-textbox-toolbar'
                            hideToolbar={true}
                            twoHierarchy={true}
                            textBoxId={data.textBoxId}
                            engagementData={engagementData}
                        />
                    </div>
                }

                <Button variant="contained" size='small' className='sma-thinking-strategy-button' onClick={handleClick}>{engagementData[data.toolName][data.id].buttonText}</Button>
            </div>

            }

        </div >
    );
}