import React from 'react';
import Ideabord from '../../../Live_Session_Tools/IdeaBord'
// import Ideabord from '../../../Live_Session_Tools/IdeaBord/dup'

export default ({ width, height, currentEngagement, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId }) => {

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: 'white', width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', overflow: "hidden" }}>
                {/* <div style={{ position: 'absolute', top: '21%', right: '2%', width: '20%', height: '15%' }}>
                    {
                        // (sessionId) &&
                        <Start_N_Stop width={width} height={height}
                            engagementData={engagementData}
                            manageToolData={manageToolData}
                            data={data}
                            isFullScreen={isFullScreen}
                            engagementStatus={engagementStatus}
                            sessionId={sessionId}
                        />
                    }
                </div> */}

                <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',

                }}>
                    {/* <img src={logo} style={{ width: '390px', height: '78px',position:"relative",right: "50px",bottom: "15px" }} /> */}
                    <Ideabord
                        data1={data}
                        width={width}
                        height={height}
                        engagementData={engagementData}
                        manageToolData={manageToolData}
                        isFullScreen={isFullScreen}
                        engagementStatus={engagementStatus}
                        sessionId={sessionId}
                        currentEngagement={currentEngagement}
                    />
                </div>
            </div>
        </div >
    );
}