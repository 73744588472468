import React, { useState, useEffect } from 'react';
import { Button, Dialog, Drawer, Tooltip } from '@material-ui/core';
import 'react-circular-progressbar/dist/styles.css';
import './drawer.css';
import { Cancel as CancelIcon } from '@material-ui/icons/';

import { makeStyles } from '@material-ui/core/styles';

import {
  useSelector,
} from 'react-redux';

import { addFormValues, endSession, startPostSession } from '../../containers/application/redux_actions';
import { convertFromRaw } from 'draft-js';
import { Link } from 'react-router-dom';
import AssementLogo from '../../Live_Session/assets/quiz_black_24dp.svg';
import EndSessionPopup from './EndSessionPopup';
const logo = require('./assets/pedgog.svg');


const useStyles_2 = makeStyles((theme) => ({
  paper: {
    width: '40%',
    padding: '2em',
    borderRadius: '24px'
  }
}));

export function PopUpConfirmation({ open, handleClose, handleClick, classes_2, title, description, cancel, agree }) {

  return <Dialog
    open={open}
    onClose={handleClose}
    keepMounted
    classes={{
      paper: useStyles_2().paper
    }}
    className='ir-tisa-font'
    disableBackdropClick
    disableEscapeKeyDown
  >
    <div style={{ fontWeight: 'bold' }}>
      {title}
    </div>
    <div style={{ margin: '1em 0', fontSize: '0.8em' }}>
      {description}
    </div>
    <div className='d-flex align-items-center justify-content-space-between' style={{ marginTop: '1em' }}>
      <Button className='end-button-dialog-drawer' onClick={handleClose} autoFocus>
        {cancel}
      </Button>
      <Link to='/application' className='dont-end-button-dialog-drawer' onClick={handleClick} >
        {agree}
      </Link>
    </div>
  </Dialog>
}

let DrawerMenu = ({ isLoaded, data, userModuleState, units, isDrawerCalled, isFullScreen,
  updateDrawerCalled, roadmaps, currentRoadmap, currentUnit, updatecurrentUnit, setcurrentScene,
  uid, token, drawerVariant, typPC, availableModules, percomps, setCurrentRoadmap }) => {
  let handleDrawerToggle = () => { updateDrawerCalled(false) };
  let [sectionTimes, updateSectionTime] = useState({});
  let percomp = 0;

  const app = useSelector(state => state.app);
  const auth = useSelector(state => state.auth);
  // const dispatch = useDispatch();
  const [endSessionPopup, setEndSessionPopup] = React.useState(false);

  const classes_2 = useStyles_2();

  const handleClickOpen = () => {
    setEndSessionPopup(true);
  };

  const handleClose = () => {
    setEndSessionPopup(false);
  };

  const handleClick = () => {
    app.engagementData && Object.keys(app.engagementData).length !== 0 && toolDataSave();
    // startPostSession({ user_token: auth.user_token, sessionId: app?.createdSession?.id })
    endSession({ user_token: auth.user_token, sessionId: app?.createdSession?.id })
    // return <Redirect to='/application' />
  }

  const convertEditorTypeData = () => {
    let convertedData = {};
    Object.keys(app.engagementData).map((textBox, i) => {
      convertedData[textBox] = app.engagementData[textBox].blocks ? convertFromRaw(app.engagementData[textBox]).getPlainText() : app.engagementData[textBox];
    })
    return convertedData;
  }

  const toolDataSave = () => {
    let sessionId = app.createdSession.id;
    let user_token = auth.user_token;
    let fieldData = convertEditorTypeData();
    addFormValues({ sessionId, user_token, fieldData })
  }

  useEffect(() => {
    updateSectionTime({});
    Object.keys(data.organiser && data.organiser.roadmaps ? data.organiser.roadmaps : {}).map(sectionId => {
      let section = { LearningTools: {} }
      section = roadmaps && roadmaps[sectionId] ? roadmaps[sectionId] : section;
      sectionTimes[sectionId] = 0;
      Object.keys(section.LearningTools).map((uId, i) => {
        sectionTimes[sectionId] = parseInt(sectionTimes[sectionId]) + parseInt(units[uId] ? units[uId].estimatedTime : 0);
        updateSectionTime(sectionTimes);
      })
    });
  }, [units]);
  if (isLoaded)
    return <Drawer
      variant={drawerVariant}
      anchor={'left'}
      open={isDrawerCalled}
      onClose={handleDrawerToggle}
      classes={{
        root: 'drawer-root',
        paper: 'drawer-paper',
        docked: `${isFullScreen ? '' : 'drawer-docked'}`,
      }}
      // style={isFullScreen ? { width: "1%" } : {}}
      ModalProps={{
        container: document.getElementById('linear-navigator'),
        keepMounted: true,
        // BackdropProps: { {} }
      }}
      SlideProps={{}}
      PaperProps={isFullScreen ? { style: { width: 84, height: "calc(100% - 49px)", overflow: "hidden", zIndex: -1 } } : { elevation: 2 }}
    >
      <div style={{ flexDirection: "column" }} className="overflow-hidden h-100 d-flex" >
        <div style={{ padding: '1.5rem 1.5rem 1.5rem 1.5rem', display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <img src={logo} alt="Pedgog" className='conduct-logo' style={{ marginTop: 4 }} />
            {isFullScreen ? null : <div>
              {/* <div className='conduct-module-subtitle'>{data.subtitle}</div> */}
              <div className='conduct-module-title'>{data.description}</div>
              {/* <p>{data.title}</p> */}
              <div className='conduct-module-time'>{`${data?.estimatedTime?.hours > 0 ? data.estimatedTime.hours + ' hr' : ''}  ${data?.estimatedTime?.minutes > 0 ? data.estimatedTime.minutes + ' mins' : ''}`}</div>
            </div>}
          </div>
          {isFullScreen ? null : <span className='conduct-session-roadmap-text' style={{ textTransform: "uppercase", margin: '1rem 0rem 0.5rem' }}>In this session:</span>}
        </div>
        <div style={{ padding: `0 ${isFullScreen ? '0rem' : '1rem'}` }} className="overFlowYAuto overFlowXHidden tct-scrollable-darkgray">

          {roadmaps?.[currentRoadmap]?.LearningTools && Object.keys(roadmaps?.[currentRoadmap]?.LearningTools).map((uId, i) => {

            return <div className={(currentUnit === uId) ? 'tool-cntr active' : 'tool-cntr inactive'}
              key={uId}
              style={{ display: isFullScreen ? "block" : "flex", padding: isFullScreen ? "1rem 1.5rem 1rem 1.5rem" : "1rem 2.5rem 1rem 1.5rem" }}
              onClick={() => { setcurrentScene(0); setCurrentRoadmap(currentRoadmap); updatecurrentUnit(uId); typPC !== "Prepare" && app.engagementData && Object.keys(app.engagementData).length !== 0 && toolDataSave(); }}>
              <div className='flex-center' style={{ alignItems: "flex-start" }}>
                {/* {i == 0 ? <div className='tool-id' style={{ border: currentUnit === uId ? '1px solid #BA1F00' : '1px solid #7B7B7B', backgroundColor: currentUnit === uId ? 'white' : '', marginRight: isFullScreen ? null : "0.625rem" }}>
                  <div id='IntroDot' className='intro-unit-dot'> </div>
                </div>
                  : */}
                {units?.[uId]?.title === 'Assessment' ? <div className='tool-id' style={{ marginRight: isFullScreen ? null : "0.625rem" }}>
                  <img src={AssementLogo} style={{ width: '65%', filter: `invert(${currentUnit === uId ? '1' : '0.5'})` }} alt='' />
                </div> : <div className='tool-id' style={{ marginRight: isFullScreen ? null : "0.625rem" }}>
                  {i + 1}
                </div>
                }
                {isFullScreen ? null : <div style={{ width: "calc(100% - 2em)" }}>
                  <div style={{ fontWeight: '500', fontSize: '1rem', lineHeight: '1.3' }}>{units[uId] ? units[uId].title : ''}</div>
                  <div className='subtitle' style={{ marginTop: 6 }}>{parseInt(units[uId] ? units[uId].estimatedTime : '')} mins</div>
                </div>
                }
              </div>
            </div>
          })}
        </div>
        {
          typPC == "Prepare" || isFullScreen ? null : <div className='drawer-padding conduct-end-session' onClick={handleClickOpen}>
            <CancelIcon style={{ width: '1.25em', height: '1.25em', alignSelf: "center", marginRight: "15px", fill: "#FF3100" }} />
            end session
          </div>
        }
      </div>

      {/* {endSessionPopup && <EndSessionPopup open={endSessionPopup} handlePopup={(prop) => setEndSessionPopup(prop)} />} */}
      {endSessionPopup &&
        <PopUpConfirmation
          open={endSessionPopup}
          handleClose={handleClose}
          handleClick={handleClick}
          classes_2={classes_2}
          title='Do you want to close this session?'
          // description='You can resume it later by pressing conduct button on the current module.'
          cancel="DON'T END"
          agree="YES, END IT"
        />
      }
    </Drawer>
  else
    return null
}

export default DrawerMenu;