import React from 'react';

import TextBox from '../TextBox';

import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {


    const isArray = Array.isArray(data.userName);

    let textbox_1_top = '18%';
    let textbox_1_right = '2%';

    let textbox_2_top = '22%';
    // let textbox_2_right = '-0.5%';

    if (isFullScreen) {
        textbox_1_right = '5.5%';
        // textbox_2_right = '5.5%';
    }


    return (
        <div style={{ width, height }}>
            {
                isArray ? <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                    <div style={{ width: '100%', height: '75%', display: 'flex', justifyContent: 'space-evenly', position: 'absolute', top: textbox_2_top }}>
                        <div style={{ width: '45%', height: '100%', marginLeft: '3%' }}>
                            <TextBox data={data} engagementData={engagementData} manageToolData={manageToolData} isFullScreen={isFullScreen} userName={data.userName[0]} />
                        </div>
                        <div style={{ width: '45%', height: '100%', marginLeft: '3%' }}>
                            <TextBox data={data} engagementData={engagementData} manageToolData={manageToolData} isFullScreen={isFullScreen} userName={data.userName[1]} />
                        </div>
                    </div>
                </div> : <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                        <div style={{ width: '45%', height: '80%', position: 'absolute', top: textbox_1_top, right: textbox_1_right }}>
                            <TextBox data={data} engagementData={engagementData} manageToolData={manageToolData} isFullScreen={isFullScreen} userName={data.userName} />
                        </div>
                    </div>
            }
        </div>
    );
}