import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getGenericModuleUnits } from "../../containers/application/redux_actions";

const PrepareUnits = ({ getGenericModuleUnits, auth, app }) => {
    useEffect(() => {
        let user_token = auth.user_token;
        app.genericModule.forEach(module => {
            let genericModuleId = module.moduleId;
            getGenericModuleUnits({ user_token, genericModuleId })
        })
    }, [])
    return (
        <div className='grid-wrapper' style={{ margin: '25px 0px' }}>
            {
                app.genericUnits !== undefined && Object.keys(app.genericUnits).map((ele, i) => {
                    return app.genericUnits[ele].units.map((item, i) => {
                        return <div className='module-card' key={i} style={{
                            padding: '1.25rem 1.785rem', display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            justifyItems: 'flex-end',
                            placeContent: 'flex-end'
                        }}>
                            <div style={{ width: '50px', height: '50px', marginBottom: '10px', alignSelf: 'flex-start', placeContent: 'flex-start', placeItems: 'flex-start' }}>
                                <img src={item.icon || 'https://camo.githubusercontent.com/22045498095171997ccf6a9554672519b9f67898/68747470733a2f2f63646e2e776f726c64766563746f726c6f676f2e636f6d2f6c6f676f732f72656163742e737667'}
                                    style={{ width: '100%', height: '100%' }} alt='icon' />
                            </div>
                            <h3 style={{ lineHeight: '22px', marginBottom: '10px', placeContent: 'flex-start', placeItems: 'flex-start', fontSize: '0.875rem', width: "100%" }}>{item.title}</h3>
                            <div className='scrollable' style={{ fontSize: '13px', marginBottom: '10px' }}>{item.description}</div>
                            <div style={{ display: 'flex', alignItems: "flex-end", height: '100%' }}>
                                <a href={item.url} style={{ textDecoration: 'none', color: '#ba1f00', fontSize: '0.875rem' }} target='_blank' rel="noopener noreferrer">PREPARE NOW</a>
                            </div>
                        </div>
                    })
                })
            }
        </div>
    )
}

const mapStateToProps = state => ({
    app: state.app,
    auth: state.auth
});


const mapDispatchToProps = dispatch => {
    return {
        getGenericModuleUnits: data => dispatch(getGenericModuleUnits(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrepareUnits)
