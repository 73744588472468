import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';

import Value_Thinking_CheckList from "../../../components/images/Tools-img/Value_Thinking_CheckList.png";
import Chat_Icon from "../../../components/images/Tools-img/Chat_Icon.png";
// import arrow from "../../../components/images/Tools-img/arrow.png";

import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    // let titleSize = '18px'
    let descriptionSize = '12px';
    // let questionSize = '12px';
    // let headingSize = '16px';
    let questionHeight = '12px';
    if (isFullScreen) {
        // titleSize = '26px';
        // headingSize = '24px'
        descriptionSize = '18px';
        // questionSize = '16px';
        questionHeight = '19px'
    }

    return (
        <div style={{ width, height, padding: '10px' }}>
            <div className='value-thinking-positive-negative-heading-1 font-work-sans' style={{ fontSize: descriptionSize, fontWeight: '500' }}>
                {data.title}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img style={{ width: '5%', marginLeft: '2%' }} src={Chat_Icon} />
                <div className='value-thinking-positive-negative-heading-2 font-work-sans' style={{ fontSize: descriptionSize }}>
                    <ol>
                        {data.heading.map((item, i) => <li key={i}>{item}</li>)}
                    </ol>
                </div>
                <div className='value-thinking-positive-negative-engagement font-work-sans' style={{ fontSize: descriptionSize }}>Engagement 3B</div>
            </div>
            <div style={{ display: 'flex', width: '100%', height: '76%' }}>
                <div style={{ width: '33%' }}>
                    <div style={{ height: '50%', margin: '0.3%', background: '#dbdbad', border: '3px solid white' }}>
                        <div style={{ height: '11%', lineHeight: questionHeight, padding: '10px' }}>
                            <div style={{ fontSize: descriptionSize, fontWeight: '500' }}>
                                {data.box_questions[0]}</div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', height: '89%' }}>
                            <TextEditor id={data.textBoxId[0]} initialData={engagementData[data.textBoxId[0]] || null}
                                manageToolData={manageToolData} hideToolbar={true} placeholder='&#9679; Fill here...'
                                wrapperClass='text-editor-value-thinking-price' textEditorClass={isFullScreen ? 'value-thinking-positive-negative-textEditorClass-fullScreen' : 'value-thinking-positive-negative-textEditorClass'} />
                        </div>
                    </div>
                    <div style={{ height: '50%', margin: '0.3%', background: '#dbdbad', border: '3px solid white' }}>
                        <div style={{ height: '11%', lineHeight: questionHeight, padding: '10px' }}>
                            <div style={{ fontSize: descriptionSize, fontWeight: '500' }}>
                                {data.box_questions[1]}</div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', height: '89%' }}>
                            <TextEditor id={data.textBoxId[1]} initialData={engagementData[data.textBoxId[1]] || null}
                                manageToolData={manageToolData} hideToolbar={true} placeholder='&#9679; Fill here...'
                                wrapperClass='text-editor-value-thinking-price' textEditorClass={isFullScreen ? 'value-thinking-positive-negative-textEditorClass-fullScreen' : 'value-thinking-positive-negative-textEditorClass'} />
                        </div>
                    </div>
                </div>
                <div style={{ width: '33%' }}>
                    <div style={{ height: '50%', margin: '0.3%', background: '#e1f098', border: '3px solid white' }}>
                        <div style={{ height: '12%', lineHeight: questionHeight, padding: '10px' }}>
                            <div style={{ fontSize: descriptionSize, fontWeight: '500' }}>
                                {data.box_questions[2]}</div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', height: '88%' }}>
                            <TextEditor id={data.textBoxId[2]} initialData={engagementData[data.textBoxId[2]] || null}
                                manageToolData={manageToolData} hideToolbar={true} placeholder='&#9679; Fill here...'
                                wrapperClass='text-editor-value-thinking-price' textEditorClass={isFullScreen ? 'value-thinking-positive-negative-textEditorClass-fullScreen' : 'value-thinking-positive-negative-textEditorClass'} />
                        </div>
                    </div>
                    <div style={{ height: '50%', margin: '0.3%', background: '#e1f098', border: '3px solid white' }}>
                        <div style={{ height: '12%', lineHeight: questionHeight, padding: '10px' }}>
                            <div style={{ fontSize: descriptionSize, fontWeight: '500' }}>
                                {data.box_questions[3]}</div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', height: '88%' }}>
                            <TextEditor id={data.textBoxId[3]} initialData={engagementData[data.textBoxId[3]] || null}
                                manageToolData={manageToolData} hideToolbar={true} placeholder='&#9679; Fill here...'
                                wrapperClass='text-editor-value-thinking-price' textEditorClass={isFullScreen ? 'value-thinking-positive-negative-textEditorClass-fullScreen' : 'value-thinking-positive-negative-textEditorClass'} />
                        </div>
                    </div>
                </div>
                <div style={{ width: '33%', display: 'flex', alignItems: 'center' }}>
                    <img src={Value_Thinking_CheckList} style={{ width: '100%', height: '70%' }} alt='values' />
                </div>

            </div>

        </div>
    );
}