import React, { useRef, useState } from "react";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { editAccountDetails } from "../../../containers/auth/redux_actions";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import '../style.css'
import { Button } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ReactComponent as Web_App } from '../assests/web.svg';
import { ReactComponent as Phone_App } from '../assests/mobile_app.svg';
import { ReactComponent as More_Arrow } from '../assests/expand_circle_down.svg';
import { useSelector } from "react-redux";
import store from "../../../Redux_Store";

let SwitchIntervetion = ({ setPage, switchDetails, userId, token, userOrgId }) => {
    let [moreValues, setMoreValues] = useState(false)
    let { availablePrograms, availableProgramsNew } = useSelector(state => state.app)


    function handleRedirect(defaultIntervention, token, organizationid, programId) {
        if (defaultIntervention && token) {
            editAccountDetails(userId, { defaultIntervention, organizationid, programId })
                .then(res =>
                    window.location.href = `http://${defaultIntervention}/auto-login/${token}`
                )
        }
    }



    let __switchPrograms =
        Object.values(
            Object.keys(availableProgramsNew || {})
                // .filter(pid => availableProgramsNew[pid].organizationId !== userOrgId)
                .map(pid => availableProgramsNew[pid]) || {}
        )

    const getUniqueOrgs = arr => [...new Set(Object.values(arr || {}).map(obj => obj.organizationId))];
    console.log(getUniqueOrgs(__switchPrograms), __switchPrograms)
    return <>
        <div style={{ paddingRight: '5vw' }}>
            <div
                style={{ display: 'flex', alignItems: 'center', color: '#484848', fontWeight: 400, cursor: 'pointer', fontSize: '0.9rem' }}
                onClick={() => setPage('default')}
            >
                <ArrowBackIosOutlinedIcon onClick={() => setPage('default')} style={{ cursor: 'pointer', marginRight: "1.5%", width: "0.7em", fontSize: '0.9rem' }} />Back
            </div>
            <div style={{ fontWeight: 500, fontSize: '1.5rem' }}>Large Scale Interventions</div>

            <div className='scrollDiv orgIdDiv' style={{ padding: '25px 0px' }}>
                {getUniqueOrgs(__switchPrograms)
                    .map((orgId, i) => {
                        let program = Object.values(__switchPrograms || {}).filter(el => el.organizationId === orgId).map(el => el)[0]
                        let pid = program?.programId || ''
                        let { organizationId, imgUrlPortrait, defaultIntervention, programInfo } = availableProgramsNew[pid] || {}
                        let title = switchDetails[organizationId]?.title
                        let moreBtn = i === getUniqueOrgs(__switchPrograms || []).length - 1
                        return <div key={organizationId + pid} style={{ position: 'relative', }}>
                            <div
                                className='eachDiv'
                                style={{ cursor: userOrgId == organizationId ? 'no-drop' : 'pointer' }}
                                onClick={() => {
                                    userOrgId != organizationId && defaultIntervention &&
                                        handleRedirect(defaultIntervention, token, organizationId, pid)
                                }}>
                                {userOrgId == organizationId &&
                                    <div style={{ cursor: "no-drop", display: 'flex', justifyContent: 'flex-end', padding: 10 }}><CheckCircleIcon style={{ fill: '#549727' }} /></div>
                                }
                                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                                </div> */}
                                <img src={programInfo?.logo || imgUrlPortrait} style={{ maxHeight: "50%", margin: 'auto', display: 'block', }} alt='program-img' />
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-around', padding: '10px', overflow: 'hidden', textAlign: 'center', }}>
                                    <div className="dottedWordWrap" style={{ fontSize: "20px", fontWeight: 700, width: '100%', textAlign: 'center' }}>
                                        {programInfo?.title || title}
                                    </div>
                                    <div style={{ fontSize: 17, fontWeight: 400, width: '100%', }}>{programInfo?.description || ''}</div>
                                </div>
                            </div>

                            {moreBtn && !moreValues && <div
                                style={{ cursor: 'pointer', transform: 'rotate(0deg)', position: 'absolute', top: '18vh', right: '-1vw' }}>
                                <More_Arrow onClick={() => { setMoreValues(!moreValues) }} />
                            </div>}
                        </div>
                    })
                }
                {moreValues &&
                    <div style={{ position: 'relative', }}>
                        <div
                            onClick={() => setPage('add')}
                            style={{ paddingTop: '4vh' }}
                            className={`box  ${moreValues ? "show" : ""}`}>
                            <Button style={{ width: 'max-content' }}>
                                <AddCircleOutlineOutlinedIcon style={{ fill: '#575757' }} fontSize="large" />
                            </Button>
                            <div
                                style={{ display: 'flex', justifyContent: 'space-around', fontStyle: 'normal', fontWeight: 500, fontSize: '1.3rem', padding: '10px', }}>
                                <div style={{ paddingBottom: 10 }}>Add New Program</div>
                            </div>
                        </div>
                        <More_Arrow
                            style={{ cursor: 'pointer', position: 'absolute', top: '42%', right: '-1vw', transform: 'rotate(180deg)' }} onClick={() => { setMoreValues(!moreValues) }}
                        />
                    </div>
                }
            </div>
        </div >
    </>
}


export default SwitchIntervetion