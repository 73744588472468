import React from 'react';
import "./index.css"
import { Button, FormControlLabel, makeStyles, MobileStepper, Radio, RadioGroup } from "@material-ui/core"
import clsx from 'clsx';

const useStyles = makeStyles({
    root: {
        // '&:hover': {
        //     // backgroundColor: 'transparent',
        // },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',

        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },

        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: 'white',
        backgroundImage: 'linear-gradient(0deg,hsl(0deg 0% 0% / 12%),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#f00,#c40000 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            // backgroundColor: '#c40000',
        },
    },
});

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            // disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

export default ({ width, height, data, engagementData, manageToolData, app }) => {    
    // useEffect(() => {
    //     data?.prevModuleId && getFormValuesForBatchAndModule({ user_token: auth.user_token, batchId: app.selectedBatch?._id, moduleId: data.prevModuleId });
    // }, [data.toolName, data.id])

    const toolList = data?.toolList

    const handleRadioChange = (event, id) => {
        // console.log(id, toolList[activeStep].reviewId,
        //     engagementData[toolList[activeStep].reviewId])
        manageToolData({
            toolId: toolList[activeStep].reviewId,
            content: {
                ...engagementData[toolList[activeStep].reviewId],
                [id]: event.target.value
            }
        });
    }

    const conditionalRenderingHelperFn = (item, value) => {
        return engagementData[toolList[activeStep]?.reviewId]?.[item] === value
    }

    const conditionalRendering = (item, value, type) => {
        if (type === 'bgColor')
            return conditionalRenderingHelperFn(item, value) ? data.color[value] : 'transparent'
        else if (type === 'textColor')
            return conditionalRenderingHelperFn(item, value) ? 'white' : 'black'
        else if (type === 'borderRadius')
            return conditionalRenderingHelperFn(item, value) ? '10px 10px 10px 0px' : '10px 10px 0px 10px'
    }

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return <div style={{ width, height }}>
        <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', overflow: 'hidden', display: 'flex', display: "flex" }}>
            <div style={{ marginLeft: "auto", width: "36%" }}>
                <div className='sma-identifield-practices-container tct-scrollable-darkgray' style={{ backgroundColor: toolList[activeStep]['backgroundColor'], borderTopLeftRadius: "3%", borderTopRightRadius: "3%" }}>
                    <div style={{ textTransform: "uppercase", fontWeight: "bold", textAlign: "center", marginTop: "10px" }}>{toolList[activeStep]['title']}</div>
                    <div style={{ textAlign: "center" }}>Your Stategies</div>
                    {
                        engagementData?.[toolList[activeStep]?.toolId] && Object.keys(engagementData[toolList[activeStep].toolId]).map((item, index) => {
                            let field = engagementData[toolList[activeStep].toolId][item]
                            if (field) {
                                return <>
                                    <div key={item} className='sma-identifield-practices-box'>
                                        <div style={{ borderRadius: conditionalRendering(item, 'mustDo', 'borderRadius'), backgroundColor: conditionalRendering(item, engagementData?.[data.id]?.[engagementData[toolList[activeStep].toolId][item]], 'bgColor'), transition: 'all .3s ease-in-out' }}>
                                            <div className='bierstadt-regular sma-best-strategy-review-text-box '>{field}</div>
                                        </div>
                                        <RadioGroup className='sma-best-strategy-review-radio-container' value={engagementData[toolList[activeStep]?.reviewId]?.[field] || false} onChange={(event) => handleRadioChange(event, field)}>
                                            <FormControlLabel className='bierstadt-regular radio' style={{ borderBottomLeftRadius: '10px', backgroundColor: conditionalRendering(field, 'mustDo', 'bgColor'), color: conditionalRendering(field, 'mustDo', 'textColor') }} value="mustDo" control={<StyledRadio />} label="MUST do" />
                                            <FormControlLabel className='bierstadt-regular radio' style={{ borderBottomRightRadius: '10px', backgroundColor: conditionalRendering(field, 'goodDo', 'bgColor'), color: conditionalRendering(field, 'goodDo', 'textColor') }} value="goodDo" control={<StyledRadio />} label="Good to do" />
                                        </RadioGroup>
                                    </div>
                                </>
                            }
                            return
                        })
                    }
                </div>
                <MobileStepper
                    steps={toolList.length}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={<Button size="small" onClick={handleNext} disabled={activeStep === toolList.length - 1}>
                        Next
                    </Button>}
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            Back
                        </Button>
                    }
                />
            </div>
        </div>
    </div >
}