
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FullScreenDialog from './dialog'
import { ReactComponent as IconAnalytics } from './icon.svg';


export default ({ isFullScreen, data, moduleId }) => {
    const [open, setOpen] = useState(false);
    let { createdSession } = useSelector(state => state.app)
    let sessionId = createdSession?._id
    const handleClickOpen = () => {
        setOpen(true);
    };
    return (
        <>
            <IconAnalytics onClick={handleClickOpen} style={{ width: 50, height: 30, cursor: 'pointer' }} />
            <FullScreenDialog
                open={open}
                moduleId={moduleId}
                setOpen={setOpen}
                isFullScreen={isFullScreen}
                data={data}
            />
        </>
    )

}

