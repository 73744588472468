import React from 'react';
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  FormControl,
  Button, Typography,
  TextField
} from "@material-ui/core";

import { Link } from 'react-router-dom';
//import logo from "../LoginForm/assets/logo.svg";
import { addSubscriptionForUser, getAccessTokenDetails } from "../../containers/auth/redux_actions";

import "./AddModule.css";

class AddModulePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accesscode: "",
      fields: {},
      errors: {},
      label: "",
      result: null,
      uToken: "",
    };

    this.handleChange = prop => event => {
      this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = event => {
      event.preventDefault();
    };
  }

  handleValidation(acd) {
    this.setState({ label: "" });
    let errors = {};
    let formIsValid = true;

    //AccessCode
    if (!acd) {
      formIsValid = false;
      errors["user-acd"] = "Access Code be empty";
      this.setState({ label: "Access Code can not be empty" });
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  render() {
    const noModImg = require('./assets/noMod.svg');
    const toLogin = props => <Link to="../auth/login" {...props} />;

    return (
      <div className="access-card">
        <div className="-access-card-body">
          <div className="mb-3">
            <img src={noModImg} alt="grp" />
            <div className="subtitle" style={{ marginBottom: 6 }}></div>
          </div>
          <div className="access-card-content">
            <h6>Enter access code to unlock modules</h6>
            <div className="access-btn-inline mb-3">

              <FormControl fullWidth className={"input-cntr"} >
                <TextField id="accesscode" className="mbb-3" autoComplete='off' label="Access Code" variant="outlined" value={this.state.accesscode} onChange={this.handleChange("accesscode")} />
              </FormControl>  
              <div className='button-text ml-3'>
                <Button className="MuiButtonBase-root MuiButton-root btn-yellow sticky" onClick={() => {
                  let { accesscode } = this.state;
                  let { email } = this.props.auth.user
                  //auth.user.email

                  let chkVal = this.handleValidation(accesscode);
                  //let chkVal1 = false;

                  if (chkVal) {

                    this.props.getAccessTokenDetails({ accesscode, email })
                      .then(result => {
                        //chkVal1 = true;

                        if (result.data) {
                          if (result.data.length > 0) {
                            let allowedCount = result.data[0].allowedCount;
                            // let organizationid = result.data[0].organizationId;
                            let usedCount = result.data[0].usedCount;
                            let pkids = result.data[0].packIds;
                            // let acid = result.data[0].id;

                            let pkid = [];
                            // {
                              pkids.map((el, i) => {
                                if (el) {
                                  let arrval = '{"PackId": "' + el + '", "fromDate": "08/01/2020", "toDate": "08/31/2020", "status": "Active"}';
                                  pkid.push(arrval);
                                }
                              })
                            // }

                            let uid = null;
                            if (parseInt(allowedCount, 10) > parseInt(usedCount, 10)) {
                              uid = this.props.auth.user_uid;
                              if (uid !== null) {
                                let uTok = this.props.auth.user_token;

                                //subscribe
                                this.props.addSubscriptionForUser({ uid, pkid, uTok })
                                  .then(result => {
                                    // let flag = 1;
                                    window.location.href = "/application/explore";
                                    this.setState({ label: "Subscription Successfully Added!!!" })
                                  });
                              }
                            }
                            else {
                              this.setState({ label: "Licence Limit Over!" })
                            }
                          }
                          else {
                            //this.setState({label: "Some values are not correct. Please try again."})
                            this.setState({ label: "Invalid Access Code" })
                          }
                        }
                        else {
                          this.setState({ label: "Invalid Access Code" })
                        }
                      })
                  }
                }}
                >
                  Unlock
              </Button>
              </div>

            </div>

            {this.state.label ?
              (<div className="errorMsg">
                <label> {this.state.label} </label>
              </div>) : null
            }
            <div className="cFoot">

              <div className="login-link-cntr d-block">
                <p> Don't have an access code? <Button>
                  <Typography
                    className="errorMsg"
                    variant="caption"
                    gutterBottom
                    color="primary"
                    component={toLogin}
                  >
                    Contact Us
                </Typography>
                </Button> </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };
}

AddModulePage.propTypes = {
  classes: PropTypes.object.isRequired,
  getAccessTokenDetails: PropTypes.func,
  addSubscriptionForUser: PropTypes.func,
  authMsg: PropTypes.string
};


const mapStateToProps = state => ({
  auth: state.auth,
  app: state.app,
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    getAccessTokenDetails,
    addSubscriptionForUser,
  },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps,
)(AddModulePage);
