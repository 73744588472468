import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LeftNav from "../../../components/LeftNav";
import ReportDashboard from "../../../components/App_ReportDashboard";

import "./reportDashboard.css";

class reportDashboard extends Component {
  render() {
    return (
      <div className={"dashboard-cntr"}>
        <LeftNav />
        <ReportDashboard />
      </div>
    );
  }
}
reportDashboard.propTypes = {
  authMsg: PropTypes.string
};

const mapStateToProps = state => ({ auth: state.auth });

export default connect( mapStateToProps, null )(reportDashboard);