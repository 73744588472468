import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import './style.css';
let ImageSlide = ({ next, previous, imgUrl, classes, dimensions }) => {
  // let providedRatio = dimensions.slideWidth/dimensions.slideHeight;
  const [imgLoad, setImgLoad] = useState({});
  return <div className={`img-slide${classes && classes.slide ? ` ${classes.slide}` : ""}`}>

    <div className='text-center' style={{ display: !imgLoad?.[imgUrl] ? 'block' : 'none' }}>
      <CircularProgress />
      <div>Loading...</div>
    </div>
    <div style={{ display: 'flex', maxHeight: '100%', display: imgLoad?.[imgUrl] ? 'inherit' : 'none' }}>
      <img
        // onLoad={(i)=>{console.log(i)}}
        onLoad={() => setImgLoad((oldValue) => {
          return {
            ...oldValue,
            [imgUrl]: true
          }
        })}
        src={imgUrl}
        style={{
          height: dimensions.slideHeight,
          width: dimensions.slideWidth
        }}
      />
    </div>
  </div>
}

export default ImageSlide;