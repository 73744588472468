import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import slide_34_left_img from '../../../components/images/Tools-img/Creator_of_Destiny/slide_34_left_img.png';
import slide_35_left_img from '../../../components/images/Tools-img/Creator_of_Destiny/slide_35_left_img.png';
import slide_34_top_img from '../../../components/images/Tools-img/Creator_of_Destiny/slide_34_top_img.png';
import slide_35_top_img from '../../../components/images/Tools-img/Creator_of_Destiny/slide_35_top_img.png';

import './style.css';
export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    const images = {
        slide_34_left_img,
        slide_34_top_img,
        slide_35_left_img,
        slide_35_top_img
    };
    // let titleSize = '20px'
    let descriptionSize = '12.5px';
    // let headingSize = '16px';
    if (isFullScreen) {
        // titleSize = '30px';
        // headingSize = '24px'
        descriptionSize = '18px';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ width: '100%', height: '18%' }}>
                {
                    data.topImg ? <img src={images[data.topImg]} style={{ width: '100%', height: '100%' }} alt='top-img' />
                        :
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '15%' }}>
                                <div className='merriweather-sans' style={{ fontSize: descriptionSize, padding: '5%', background: '#e64708', color: 'white', width: 'fit-content' }}>{data.caseStudy}</div>
                            </div>
                            <div className='merriweather-sans' style={{ fontSize: descriptionSize, width: '85%' }}>{data.caseDescription}</div>
                        </div>

                }
            </div>
            <div style={{ width: '100%', height: '82%', background: '#d67070', padding: '5px', display: 'flex' }}>
                <div style={{ width: '30%', height: '100%', zIndex: '1' }}>
                    <img src={images[data.leftImg] || data.leftImg} style={{ width: '120%', height: '100%' }} alt='left-img' />
                    {
                        data.left_textBoxId && <div className={`cod_case_study_left_textbox merriweather-sans`}>
                            <TextEditor id={data.left_textBoxId} initialData={engagementData[data.left_textBoxId] || null}
                                manageToolData={manageToolData} placeholder='&#9679; Fill here...' hideToolbar={true}
                                wrapperClass='text-editor-cod_case_study_external_constraints'
                            />
                        </div>
                    }

                </div>
                <div className='creator_of_destiny_bg' style={{ width: '70%', height: '100%', position: 'relative' }}>
                    <div className='merriweather-sans' style={{ fontSize: descriptionSize, fontWeight: 'bold', fontStyle: 'italic', position: 'absolute', top: '2%', left: '10%', color: '#c00000' }}>EXTERNAL CONSTRAINTS</div>
                    {
                        data.external_constraints ? data.external_constraints.map((item, i) => <div key={i} className={`cod_external_constraints_${i + 1} merriweather-sans`} style={{ fontSize: descriptionSize }}>{item}</div>)
                            :
                            <div>
                                {
                                    data.external_constraints_textBoxId.map((item, i) =>
                                        <div key={i} className={`cod_external_constraints_textbox_${i + 1} merriweather-sans`}>
                                            <TextEditor id={item} initialData={engagementData[item] || null}
                                                manageToolData={manageToolData} placeholder='&#9679; Fill here...' hideToolbar={true}
                                                wrapperClass='text-editor-cod_case_study_external_constraints'
                                                readOnly={data.ex_cons_textBox_readOnly ? true : false}
                                            />
                                        </div>)
                                }
                            </div>
                    }
                    <div className='cod_case_study_textbox_container' style={{ backgroundColor: data.caseStudyType === 'FREEDOM SPACES' ? 'white' : '#e0d8ae' }}>
                        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                            <div className='merriweather-sans' style={{ fontSize: descriptionSize, fontWeight: 'bold', fontStyle: 'italic', color: '#c00000', height: '10%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                {data.caseStudyType}
                            </div>
                            <div style={{ display: 'flex', width: '100%', height: '90%' }}>
                                <div style={{ width: '33%', height: '100%' }}>
                                    <div className='cod_case_study_textbox_1'>
                                        <TextEditor id={data.textBoxId[0]} initialData={engagementData[data.textBoxId[0]] || null}
                                            manageToolData={manageToolData} placeholder='&#9679; Fill here...' hideToolbar={true}
                                            wrapperClass='text-editor-cod_case_study'
                                        />
                                    </div>
                                    <div className='cod_case_study_textbox_2'>
                                        <TextEditor id={data.textBoxId[1]} initialData={engagementData[data.textBoxId[1]] || null}
                                            manageToolData={manageToolData} placeholder='&#9679; Fill here...' hideToolbar={true}
                                            wrapperClass='text-editor-cod_case_study'
                                        />
                                    </div>
                                </div>
                                <div className={data.caseStudyType === 'INNER CONSTRAINTS' ? 'cod_center_img_inner_constraints' : 'cod_center_img_freedom_spaces'}>
                                    <img src={data.centerImg} style={{ width: '100%', height: '100%' }} alt='center-img' />
                                </div>
                                <div style={{ width: '33%', height: '100%' }}>
                                    <div className='cod_case_study_textbox_3'>
                                        <TextEditor id={data.textBoxId[2]} initialData={engagementData[data.textBoxId[2]] || null}
                                            manageToolData={manageToolData} placeholder='&#9679; Fill here...' hideToolbar={true}
                                            wrapperClass='text-editor-cod_case_study'
                                        />
                                    </div>
                                    <div className='cod_case_study_textbox_4'>
                                        <TextEditor id={data.textBoxId[3]} initialData={engagementData[data.textBoxId[3]] || null}
                                            manageToolData={manageToolData} placeholder='&#9679; Fill here...' hideToolbar={true}
                                            wrapperClass='text-editor-cod_case_study'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}