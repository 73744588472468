import React, { useState } from "react"
import { Button, Dialog } from "@material-ui/core"
import Table from "./Table"
import DropDown from "./DropDown"
import { getUser, getUserByZoneAndDivision, updateBatch } from "../../application/redux_actions";

export default ({ open, closeEvent, user_token, user_id, batch, setRefresh }) => {

    const buttonStyle = {
        border: '3px solid #FFBC36',
        borderRadius: '18px',
        padding: "0.4em 2em",
        display: 'flex',
        alignSelf: 'baseline',
        // marginLeft: "auto",
        // marginRight: "auto"
        fontWieght: 'bold'
    }

    const [zone, setZone] = useState('');

    const [division, setDivision] = useState('');

    const zoneData = [
        // { label: 'All', value: 'All' },
        { label: 'Southern', value: 'Southern' },
        // { label: 'West', value: 'West' },
        // { label: 'North', value: 'North' },
        // { label: 'East', value: 'East' }
    ]

    const divisionData = [
        // { label: 'Madurai', value: 'Madurai' },
        { label: 'Chennai', value: 'Chennai' },
        // { label: 'Mumbai', value: 'Mumbai', },
        // { label: 'Kolkata', value: 'Kolkata' },
        // { label: 'Kochi', value: 'Kochi' }
    ];

    const [userData, setUserData] = useState([]);

    const handleSearch = async () => {
        if (zone && division)
            await getUser(user_token, { zone, division }).then((res) => setUserData(res))
    }



    // const data = [{
    //     "S.No.": 1,
    //     "Name": "Madhan Prakash",
    //     "Contact number": 9988776655,
    //     // Division: "PRYJ",
    //     // Zone: "NCR",
    //     "City": "Madurai",
    //     "Designation": "Clerk",
    //     "Group": "SM"
    // },
    // {
    //     "S.No.": 2,
    //     "Name": "Shiva",
    //     "Contact number": 9988776654,
    //     // Division: "PRYJ",
    //     // Zone: "NCR",
    //     "City": "Madurai",
    //     "Designation": "Clerk",
    //     "Group": "SM"
    // },
    // {
    //     "S.No.": 3,
    //     "Name": "Sundar",
    //     "Contact number": 9988776653,
    //     // Division: "PRYJ",
    //     // Zone: "NCR",
    //     "City": "Madurai",
    //     "Designation": "Clerk",
    //     "Group": "SM"
    // }]

    const [selectedColaborator, setSelectedColaborator] = useState({});

    const handleCheckboxClick = (event, userId) => {

        // const selectedIndex = selectedColaborator.indexOf(phone);
        // let newSelected = [];

        // if (selectedIndex === -1) {
        //     newSelected = newSelected.concat(selectedColaborator, phone);
        // } else if (selectedIndex === 0) {
        //     newSelected = newSelected.concat(selectedColaborator.slice(1));
        // } else if (selectedIndex === selectedColaborator.length - 1) {
        //     newSelected = newSelected.concat(selectedColaborator.slice(0, -1));
        // } else if (selectedIndex > 0) {
        //     newSelected = newSelected.concat(
        //         selectedColaborator.slice(0, selectedIndex),
        //         selectedColaborator.slice(selectedIndex + 1),
        //     );
        // }

        setSelectedColaborator({ ...selectedColaborator, [userId]: event.target.checked });
    };

    const handleSubmit = async () => {
        if (Object.values(selectedColaborator).includes(true) && batch?.batchId) {
            let coachId = Object.keys(selectedColaborator).filter(userId => selectedColaborator[userId]);
            coachId.push(user_id);
            await updateBatch(user_token, { batchId: batch.batchId }, { coachId }).then((res) => {
                if(res?.code) {
                    alert('Master trainer not added.');
                }
                else {
                    alert('Added Master trainer successfully');
                    closeEvent(false);
                    setRefresh(true);
                }
            })
        }
        
    }



    return <Dialog open={open} fullWidth PaperProps={{ style: { maxWidth: "80%", height: '80%', background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 5px 10px #00000021", borderRadius: "29px" } }}>
        <div style={{ margin: "2%" }}>
            <div className='font-weight-bold d-flex justify-content-space-between'>
                <div>Add Master Trainer</div>
                <div style={{ cursor: "pointer", marginLeft: "1%" }} onClick={() => closeEvent(false)}>X</div>
            </div>
            <div className="d-flex justify-content-space-between my-1">
                <div className="w-30 d-flex align-items-center">
                    <div className="font-weight-bold">Zone :</div>
                    <DropDown options={zoneData}
                        value={zone || ''}
                        setBatch={setZone} />
                </div>
                <div className="w-30 d-flex align-items-center">
                    <div className="font-weight-bold">Division :</div>
                    <DropDown options={divisionData}
                        value={division || ''}
                        setBatch={setDivision} />
                </div>
                <div className="w-30 d-flex justify-content-flex-end">
                    <Button style={{ ...buttonStyle, borderColor: 'rgb(255, 186, 54)' }}
                        onClick={handleSearch}
                    >Search</Button>
                </div>
            </div>
            <div>
                {userData && userData?.length > 0 && <Table data={userData} hiddenField={["userId"]} showAction={false} showCheckBox={true} handleCheckbox={(event, userId) => { handleCheckboxClick(event, userId) }} />}
            </div>
            <div className="d-flex justify-content-flex-end mt-3">
                <Button style={{ ...buttonStyle, borderColor: 'rgb(255, 186, 54)', backgroundColor: 'rgb(255, 186, 54)' }} onClick={handleSubmit}>ADD MASTER TRAINER</Button>
            </div>
        </div >
    </Dialog >
}