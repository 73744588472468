import React from 'react';
import "./FooterLinks.css";
// import {
// FormControl,
// Button, Typography,
// TextField
// } from "@material-ui/core";

// import { Link } from 'react-router-dom';

const logoImg = require('./assets/logo.svg');
// const toContactUs = props => <Link to="google.com" {...props} />;
// const toPrivacyPolicy = props => <Link to="google.com" {...props} />;
// const toCookiePolicy = props => <Link to="google.com" {...props} />;
// const toTerms = props => <Link to="google.com" {...props} />;

let FooterLinks = ({ user }) => {
  return <div>
    <div className='footerText' style={{ width: '6%', float: 'left', paddingLeft: '30px' }}>
      <img src={logoImg} alt="grp" />
    </div>
    <a className='footerText' style={{ width: '10%', float: 'left', paddingTop: '10px' }} target="_blank" href="https://illumine.in" rel="noopener noreferrer">illumine.in</a>
    {/* <div className='footerText' style={{ width: '6%', float: 'left', paddingTop: '10px' }}>
      Contact Us
      </div> */}
    <a className='footerText' style={{ width: '10%', float: 'right', paddingTop: '10px' }} target="_blank" href="https://illumine.in/privacy-policy.html" rel="noopener noreferrer">Privacy Policy</a>
    <a className='footerText' style={{ width: '10%', float: 'right', paddingTop: '10px' }} target="_blank" href="https://illumine.in/cookie-policy.html" rel="noopener noreferrer">Cookie Policy</a>
    <a className='footerText' style={{ width: '10%', float: 'right', paddingTop: '10px' }} target="_blank" href="https://illumine.in/terms-of-use.html" rel="noopener noreferrer">Terms &amp; Conditions</a>
    {/* <div className='footerText' style={{width: '10%', float: 'right', paddingTop:'10px'}}>Terms & Conditions</div>
      <div className='footerText' style={{width: '10%', float: 'right', paddingTop:'10px'}}>Cookie Policy</div>
      <div className='footerText' style={{width: '10%', float: 'right', paddingTop:'10px'}}>Privacy Policy</div> */}
    <div className='footerTextLast'>© 2020; Illumine. All Rights Reserved.</div>
  </div>
};

export default FooterLinks;