import React from "react";
import TextEditor from '../../Tools/TextEditor/TextEditor';

import './style.css';
let textBoxData = [];
export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    const displayItems = () => {
        let list = [];
        if (engagementData[data.textBoxId] !== null && engagementData[data.textBoxId] !== undefined) {
            engagementData[data.textBoxId].blocks.map((item) => {
                if (item.text !== "")
                    list = [...list, item.text];
            })
        }
        textBoxData[data.textBoxId] = list;
    }


    displayItems();

    let textSize_1 = '12px';
    let textSize_2 = '16px';

    if (isFullScreen) {
        textSize_1 = '18px';
        textSize_2 = '23px';
    }

    return <div style={{ width, height }}>
        <div style={{ width: '100%', height: '30%', padding: '18px', backgroundColor: '#e4e7ec', fontSize: textSize_2, fontWeight: 'bold', color: '#1F497D'}}>
            {data.question.q_1}
            {data.question.q_2 ? <br /> : null}
            {data.question.q_2}
            <br />
            <span style={{ fontSize: textSize_1, color: 'black', fontWeight: '400' }}>i.{data.subQuestion.q_1}</span>
            <br />
            <span style={{ fontSize: textSize_1, color: 'black', fontWeight: '400' }}>ii.{data.subQuestion.q_2}</span>
        </div>

        <div style={{ display: "flex", padding: '8px', backgroundColor: '#e2f488', height: '66%', borderRadius: '25px', boxShadow: 'rgb(195 195 195) 10px 10px 6px -6px', margin: '1% 2%' }}>
            <div style={{ padding: '0px 16px', width: '35%', textAlign: 'center' }}>
                <img src={data.bgImg} height='100%' />
            </div>
            <div style={{ padding: '20px', border: '3px solid black', width: '40%', height: '100%', flexGrow: '1', borderRadius: '24px', backgroundColor: "#fdff9b" }}>
                <strong style={{ fontSize: textSize_1, height: '10%' }}>
                    Relevant strategies:
            </strong>
                <div style={{ display: 'flex', height: '80%' }}>
                    <TextEditor id={data.textBoxId} initialData={engagementData[data.textBoxId] || null}
                        manageToolData={manageToolData} placeholder='Fill here'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        textEditorClass={!isFullScreen ? 'trust-surplus-textbox-height' : false}
                        scrollable='tct-scrollable-darkgray'
                    />
                </div>
            </div>
            {!data.firstSlide ?
                <div className='trust_surplus_list' style={{ padding: '10px', width: '25%', flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
                    <strong style={{ fontSize: textSize_1 }}>
                        Strategies identifed earlier:
          </strong>
                    <ul className="tct-scrollable-darkgray" style={{ padding: '20px', overflowY: 'auto', listStyleType: 'none', overflowWrap: 'anywhere' }}>
                        {
                            data.allTextBoxId ? data.allTextBoxId.map((boxId) => {
                                if (boxId !== data.textBoxId && textBoxData[boxId] !== null && textBoxData[boxId] !== undefined && textBoxData[boxId].length > 0)
                                    return textBoxData[boxId].map((item, i) => <li key={i} style={{ display: 'flex' }}>{item}</li>)
                            }) : null
                        }
                    </ul>
                </div> : null
            }
        </div>
    </div>
}