import React, {
  // useState, useEffect,
  useRef
} from 'react';
import Button from '@material-ui/core/Button';
// import { getEngagement } from '../../containers/application/redux_actions'
// import { startEngagement, stopEngagement } from '../../containers/application/Socket';
// import Interaction from "../../containers/application/Interaction"
import './style.css';
// import store from "../../Redux_Store"
import { jsPDF } from "jspdf";
// import _ from "lodash";
// import { constants } from '../../constants';
import Config from "../../config"
// import { useSelector } from 'react-redux';
import html2canvas from "html2canvas";


export function saveTakeway(Formdata) {
  console.log('saveTakeway-->>', Formdata)
  if (Formdata) {
    fetch(Config.API_URL + 'session/takeaway', {
      //fetch('http://localhost:5001/v1/' + 'session/takeaway', {
      //  fetch('http://uat.pedgog.in:3000/v1/progress/createProgressPercentage', {
      //fetch('https://beta.pedgog.in:3000/v1/progress/createProgressPercentage', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        "accept": "application/json"
      },
      body: JSON.stringify(Formdata)
    })
      .then(response => response.json())
      .then(data1 => {
        console.log(data1);
      }
      )
  }
}
export default ({ width, height, title, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId }) => {
  // const inputRef = useRef(null);

  //   const generatePDF=()=>{
  //     console.log(data)
  //     console.log(engagementData)
  //       console.log(sessionId)
  //       // console.log(engagementData[data.[data.textBoxId])
  //       console.log(engagementData[data.sessionType])
  //    const engdata=Object.keys(engagementData[data.sessionType]).map((key, value)=>{
  //      let title = key;
  //      let result= engagementData[data.sessionType][key]

  //           return ( title +'    : '+ result)
  //       }) 
  //       // let pdfdata=engagementData[data.sessionType]
  //       // console.log(pdfdata)

  //       const doc = new jsPDF();

  //       doc.text(engdata, 35, 35);
  //       doc.addPage();
  //       doc.text("Octonyan loves jsPDF", 35, 25);
  //       doc.save("two-by-four.pdf");
  //       var pdf = btoa(doc.output()); 
  //       console.log(pdf)
  //       var Formdata = new FormData();
  //       console.log(data.sessionType)
  //       console.log(sessionId)
  //       Formdata.append('engagementId', data.sessionType);
  //       Formdata.append('sessionId', sessionId)
  //       Formdata.append('data' , pdf);

  //       saveTakeway(Formdata);

  // }
  const inputRef = useRef(null);
  const generatePDF = () => {
    html2canvas(inputRef.current).then((canvas) => {
      // const imgData = canvas.toDataURL(logo);
      // const imgData1= canvas.toDataURL(stakeholders);
      // const imgData2= canvas.toDataURL(plus);
      const doc = new jsPDF();
      // pdf.addImage(imgData, "png", 0, 0);
      // pdf.addImage(imgData1, "png", 0, 0);
      // pdf.addImage(imgData2, "svg", 0, 0);
      doc.save("two-by-four.pdf");
      var pdf = btoa(doc.output());
      console.log(pdf)
      var Formdata = new FormData();
      console.log(data.sessionType)
      console.log(sessionId)
      Formdata.append('engagementId', data.sessionType);
      Formdata.append('sessionId', sessionId)
      Formdata.append('data', pdf);

      saveTakeway(Formdata);
    })
  }

  return (
    <div style={{ width, height, display: 'flex' }} >
      <div style={{ width, height, display: 'flex' }} id="divToPrint" ref={inputRef}>
        <form >
          <label>
            Frirst Name:

          </label>
          <h2>take aways</h2>
        </form>
      </div>
      <div className='w-100 h-100'>
        <Button variant="contained" className='live-session-stop-button w-100 h-100' onClick={generatePDF} >
          Take away


        </Button>
      </div>

    </div>
  );
}