import React from 'react';


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let descriptionSize = '11px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '14px';
    }

    const right = ['63%', '34.5%', '7%'];
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((id, i) => <textarea key={id} id={data.textBoxId[i]} placeholder='Fill here…'
                        className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ width: '23%', height: '15%', fontSize: descriptionSize, position: 'absolute', top: '75%', right: right[i] }}
                        value={engagementData[data.textBoxId[i]] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: data.textBoxId[i], content: event.currentTarget.value })
                        }}
                    />)
                }

            </div>
        </div>
    );
}