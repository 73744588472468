import { makeStyles } from "@material-ui/core";
import React from "react"

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, language }) => {
    const useStyles = makeStyles({
        wrapperClass: {
            position: "absolute",
            overflowY: "auto",
            background: "transparent",

            "& ul, & .public-DraftStyleDefault-block": {
                margin: "0 !important"
            }
        }
    })

    const classes = useStyles()

    return <div style={{ width, height, marginLeft: "auto", marginRight: "auto" }
    } >
        <div style={{
            // backgroundImage: `url(${})`,
            width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative'
        }}>
            <img src={data.bgImg?.[language] ? data.bgImg[language] : data.bgImg} style={{ width: "100%", position: "absolute" }} />
            {data.scrollableImg && <div style={{ position: 'absolute', ...data.scrollableDivStyle }} className="tct-scrollable-darkgray overFlowYAuto">
                <img src={data.scrollableImg?.[language] ? data.scrollableImg[language] : data.scrollableImg} style={{ width: "100%" }} />
            </div>}
        </div>
    </div >
}