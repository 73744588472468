import React from "react"
import { Button, Dialog, IconButton, Input, MenuItem, Select } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { useState } from "react"
import { addTeacherResponse } from "../../utils"
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { getIdToAdd } from "../../../../utilities/firebase/fb_firestore";


let AddTeacherResponse = ({ open, sectionId, setSectionId, activeFieldId, toolId, activeResponseId, activeResponse, toolKit, sessionId }) => {

 // let [updates, setUpdates] = useState({
 //     editedText: activeResponse?.editedText || activeResponse?.text,
 //     class: activeResponse?.class,
 //     wasClassUpdated: false
 // } || {});

 let [text, setText] = useState({})

 return <Dialog
  open={open}
  onClose={() => setSectionId(null)}
  container={document.getElementById("frame-id")}>
  <div style={{ padding: 32 }}>
   <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <IconButton onClick={() => {
     // TeacherUpdateAResponse({
     //     sessionId, fid: activeFieldId, toolId, rid: activeResponseId,
     //     u: { ...updates, deletedOn: true }
     // });
     // resetClassification({ sessionId, toolId, fid: activeFieldId, rid: activeResponseId })
     // onCloseDialog()
    }}>
     <DeleteOutlineOutlinedIcon />
    </IconButton>
    <IconButton onClick={() => setSectionId(null)}><Close /></IconButton>
   </div>
   <div>
    <Input fullWidth
     value={text?.text}
     onChange={(ev) => {
      setText((x) => ({ ...x, text: ev.target.value }))
     }} />
    <Select fullWidth
     style={{ fontSize: "0.9rem " }}
     id="response-select"
     native
     defaultValue={text?.class}
     value={text?.class}
     onChange={(ev) => {
      setText((x) => ({ ...x, class: ev.target.value, }))
     }}
    >
     {Object.keys(toolKit?.classes)
      .sort((a, b) => {
       return toolKit.classes[a].order - toolKit.classes[b].order
      })
      .map((cid, j) => {
       let c = toolKit.classes[cid];
       return <option key={`${activeResponseId}-option-${cid}`} value={cid}>{c.label}</option>
      })}
    </Select>
   </div>
   <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <Button
     onClick={async () => {
      let eid = await getIdToAdd({ collectionId: "generatedIdeas" })
      if (eid) {
       addTeacherResponse({
        sessionId, fid: activeFieldId || 'f001', toolId, rid: eid,
        u: text
       });
      }
      // onCloseDialog();
      setSectionId(null)
     }}
    >Submit</Button>
   </div>
  </div>
 </Dialog>
}

export default AddTeacherResponse;