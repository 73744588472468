import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';

import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, language }) => {


    let textSize = '8px'
    let textBoxtextSize = '15px'
    if (isFullScreen) {
        textSize = '12px';
        textBoxtextSize = '21px'
    }

    const handleChange = (event, textBoxId) => {
        manageToolData({
            toolId: data.id,
            content: {
                ...engagementData[data.id],
                [textBoxId]: event.target.value
            }
        })
    };


    return (
        <div style={{ width, height }}>
            < div style={{ backgroundImage: `url(${data.bgImg?.[language] ? data.bgImg[language] : data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>

                <div key={data.textBoxId[0][language]} className='railways-citizen-textbox_1' style={{ fontSize: textBoxtextSize, lineHeight: '1.2' }}>
                    <TextEditor id={data.id} initialData={engagementData[data.id]?.[data.textBoxId[0][language]] ? engagementData[data.id][data.textBoxId[0][language]] : null}
                        manageToolData={manageToolData}
                        scrollable='tct-scrollable-darkgray'
                        preFilledTextBoxData={data.preFilledTextBoxData ? data.preFilledTextBoxData[data.textBoxId[0][language]] : false}
                        // textEditorClass='UPTC_CS_Square-textEditorClass'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                        hideToolbar={true}
                        oneHierarchy={true}
                        textBoxId={data.textBoxId[0][language]}
                        engagementData={engagementData}
                    />
                </div>
                <div key={data.textBoxId[1][language]} className='railways-citizen-textbox_2' style={{ fontSize: textBoxtextSize, lineHeight: '1.2' }}>
                    <TextEditor id={data.id} initialData={engagementData[data.id]?.[data.textBoxId[1][language]] ? engagementData[data.id][data.textBoxId[1][language]] : null}
                        manageToolData={manageToolData}
                        scrollable='tct-scrollable-darkgray'
                        preFilledTextBoxData={data.preFilledTextBoxData ? data.preFilledTextBoxData[data.textBoxId[1][language]] : false}
                        // textEditorClass='UPTC_CS_Square-textEditorClass'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                        hideToolbar={true}
                        oneHierarchy={true}
                        textBoxId={data.textBoxId[1][language]}
                        engagementData={engagementData}
                    />
                </div>
            </div>
        </div >
    );
}