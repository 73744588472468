import React from 'react';

import './style.css';

import EventModal from "./modal";

import Farmer from "../../../components/images/Tools-img/EIS/farmer.png";

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    // let titleSize = '20px'
    let descriptionSize = '13px';
    let headingSize = '16px';
    let subtTitleSize = '13px';
    if (isFullScreen) {
        // titleSize = '30px';
        headingSize = '24px';
        subtTitleSize = '20px'
        descriptionSize = '20px';
    }

    return (
        <div style={{ width, height }}>
            <div className='ESI_Slide_illumine_logo'>
                <img src='https://pedgog2.s3.ap-south-1.amazonaws.com/Becoming+future+ready+contributors/04-Enlightened+Self+interest/Illuminelogo.png'
                    style={{ width: '100%', height: '100%' }}
                    alt='illumine-logo'
                />
            </div>
            <div style={{ width: '95%', height: '10%', padding: '1.5% 2%', display: 'flex' }}>
                <div className='work-sans' style={{ width: '30%', fontSize: headingSize, fontWeight: 'bold' }}>{data.case}</div>
                <div className='work-sans' style={{ width: '70%', fontSize: headingSize, fontWeight: 'bold' }}>{data.title}</div>
            </div>
            <div style={{ width: '96%', height: '84%', margin: '2%', backgroundColor: '#fafafa' }}>
                <div style={{ width: '100%', height: '55%', display: 'flex' }}>
                    <div style={{ width: '28%', height: '100%' }} >
                        <img src={Farmer} style={{ width: '100%', height: '100%' }} alt='left-img' />
                    </div>
                    <div className='tct-scrollable' style={{ width: '75%', height: '85%', margin: '1.5%', overflowY: 'auto', backgroundColor: "#f2f2f2" }}>
                        {
                            data.rightInfo.map((item, i) => <div key={i} className='work-sans' style={{ margin: '1.5%', fontSize: descriptionSize }}>{item}</div>)
                        }
                    </div>
                </div>
                <div style={{ width: '100%', height: '45%' }}>
                    <div style={{ width: '100%', height: '15%', paddingLeft:'5.5%' }}>
                        <div className='work-sans' style={{ fontSize: subtTitleSize, fontWeight: 'bold' }}>{data.subTitle}</div>
                    </div>
                    <div style={{ width: '100%', height: '85%' }}>
                        <EventModal challenges={data.challenges} isFullScreen={isFullScreen} />
                    </div>
                </div>
            </div>
        </div >
    );
}