import { Button } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Start_N_Stop from "../Start_N_Stop";

export default ({
  engagementStatus,
  answers,
  progessBarValue,
  width,
  height,
  engagementData,
  manageToolData,
  isFullScreen,
  data,
  labelObj,
  totalStudents,
  show,
  setShow,
  show1,
  setShow1,
  sessionId,
  scenarioId,
  scenario,
  typPC,
  responses,
  currentEngagement,
  uniqueStudentsName,
}) => {

  const engagementStatus1 = useSelector(
    (state) => state.app.engagementStatus[data.engagement]
  );
  const respo_count = useSelector(
    (state) => state.app.responseCount[data.engagement]
  );

  let alpha = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k"]

  function Progress_bar(percentage) {
    const Parentdiv = {
      height: "100%",
      width: "100% ",
      backgroundColor: "#F5F5F5",
    };

    const Childdiv = {
      height: "6%",
      width: `${percentage}%`,
      backgroundColor: "#ADE1FF",
      textAlign: "right",
    };

    return (
      <div style={Parentdiv}>
        <div style={Childdiv}></div>
      </div>
    );
  }
  function ProgressHori(percentage) {
    const Parentdiv = {
      height: "100%",
      width: "100% ",
      backgroundColor: "#ADE1FF",
      borderRadius: '20px',
      overflow: "hidden",
    };

    const Childdiv = {
      height: `${(100 - percentage) || 0}%`,
      width: '100%',
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      textAlign: "right",
      overflow: "hidden",
    };

    return (
      <div style={Parentdiv}>
        <div style={Childdiv}></div>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          // border: '1px solid red',
          display: "flex",
          height: "15%",
          width: "100%",
          marginTop: "1%",
          position: "absolute",
          bottom: isFullScreen ? "5px" : "4px",
        }}
      >
        <div
          style={{
            // border: '1px solid green',
            width: "100%",
            height: "75px",
            position: "absolute",
            left: "0px",
            bottom: "0px",
          }}
        >
          {Progress_bar(progessBarValue || 0)}
        </div>

        {data?.showResultBtn && respo_count?.length > 0 ? (
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              bottom: "7px",
              right: "108px",
              borderRadius: "20px",
              backgroundColor: !show || !show1 ? "#3D3D3D" : "#D8D8D8",
              color: "#FFFFFF",
              fontSize: "1.1rem",
              // // padding: "0.5% 1.3%",
              padding: "15px 27px",
              marginRight: !engagementStatus1 ? "6%" : "1%",
            }}
            onClick={() => {
              if (setShow1) { setShow1(true) };
              setShow(true);
            }}
          >
            {/* <Button style={{ paddding: '12px 15px', fontSize: '1rem' }}>See Results</Button> */}
            See Results
          </div>
        ) : null}

        <div
          style={{
            borderRadius: "20px",
            // height: "30px",
            position: "absolute",
            bottom: "7px",
            right: "15px",

          }}
        >
          <Start_N_Stop
            width={width}
            height={height}
            engagementData={engagementData}
            manageToolData={manageToolData}
            data={data}
            scenarioId={scenarioId}
            scenario={scenario}
            isFullScreen={isFullScreen}
            engagementStatus={engagementStatus}
            sessionId={sessionId}
            assignGroup={true}
          />
        </div>
        <div
          style={{
            // height: "30px",
            display: 'flex',
            position: "absolute",
            bottom: "7px",
            // left: "15px",
            width: "65%",
            // border: "2px solid red",
          }}
        >
          <div style={{
            width: "max-content",
            fontSize: "1.5rem",
            textAlign: 'center',
            lineHeight: '1.2', fontStyle: "normal",
            fontWeight: 500,
            fontFamily: "Tisa Sans Pro",
            marginLeft: '2%',
          }}>
            {uniqueStudentsName &&
              uniqueStudentsName?.length ? (
              <span>
                {uniqueStudentsName?.length}/{totalStudents}<br /> responded
              </span>
            ) : (
              ""
            )}
          </div>
          {data.showCapsule && respo_count?.length > 0 && labelObj ?
            <div style={{ width: '50%', display: 'flex', marginLeft: '4%', justifyContent: "space-around", marginTop: '0.5%' }}>
              {Object.keys(labelObj).map((key, i) => {
                let percentage = labelObj[key].length / totalStudents * 100
                return (<div style={{ height: '56px', width: '36px', borderRadius: '32px', border: "3px solid #002E47", position: "relative" }}>
                  {ProgressHori(percentage)}
                  <div style={{ width: '20px', height: '20px', position: "absolute", top: "0.5vh", left: '0.7vh', backgroundColor: "#002E47", color: "#FFFFFF", fontSize: "12px", textAlign: "center", borderRadius: "10px" }}>
                    {alpha[i]}
                  </div>
                  <div style={{ position: 'absolute', bottom: '0vh', right: labelObj[key].length == 0 ? "1.7vh" : "2vh", textAlign: "center", fontSize: "1rem" }}>{labelObj[key].length}</div>
                </div>)

              })}
            </div>
            : null}
        </div>
      </div>
    </div>
  );
};
