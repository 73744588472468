import React, { useEffect, useRef } from "react";
import { activateResponse, classifyAPiece, superActivateResponse } from "../../utils";
import './style.css'

let GridMap = ({ toolId, sessionId, currentEngagement, dim, toolKit, activeResponses,
  boardState, responses }) => {
  let engagementId = currentEngagement._id;
  console.log("boardState", boardState)

  const Viewer = useRef(null);
  let doubleClickActive = Object.keys(activeResponses || {}).length < 2 ? true : false
  useEffect(() => {
    if (Viewer && Viewer?.current)
      Viewer.current.fitToViewer();
  }, [Viewer]);

  let makeItDraggable = ({ ev, isActive, fid, rid }) => {
    activateResponse({ sessionId, toolId, fid: fid || "f001", rid, isActive: !isActive, engagementId });
    ev.stopPropagation();
    ev.preventDefault();
  }

  let makeItEditable = ({ ev, rid, fid }) => {
    if (doubleClickActive) {
      superActivateResponse({
        sessionId, toolId, rid, engagementId,
        isEditable: true, response: { fid: fid || "f001" }
      });
      ev.stopPropagation();
      ev.preventDefault();
    }
  }

  let CellContent = ({ cell, cid, fid }) => {
    return <div style={{
      margin: 0, padding: 8,
      border: "0px solid #DADADA",
      height: "100%", borderRadius: 8,
      // backgroundColor: "#F2F2F299"
    }}
      onClick={(ev) => {
        let activeResponseId = Object.keys(activeResponses || {}).length == 1 ? Object.keys(activeResponses || {})[0] : null;
        if (activeResponseId) {
          classifyAPiece({ sessionId, toolId, fid, rid: activeResponseId, typeId: cid, engagementId })
        }
      }}
    >
      <div style={{ fontSize: "0.75rem", opacity: 0.8 }}>{cell.label}</div>
      <div style={{ columnCount: 3, columnGap: 6, columnFill: 'auto' }}>
        {Object.keys(boardState?.fieldWiseResponses || {}).map(fid => {
          let f = boardState.fieldWiseResponses[fid];
          return Object.keys(f || {})
            .filter(rid => !f[rid].deletedOn)
            .map(rid => {
              let r = responses?.[rid];
              let colorScheme = toolKit.classes[r?.class]?.colorScheme;
              if (f[rid].class === cid) {
                return <div
                  key={rid}
                  style={{
                    clear: 'both',
                    border: `2px solid ${activeResponses?.[rid] ? (r?.class ? `${colorScheme}` : '#FFF') : "#EEEEEE"}`,
                    backgroundColor: activeResponses?.[rid] ? (r?.class ? `${colorScheme}22` : '#FFF') : "#F4F4F4",
                    margin: "0px 0px 6px 0px", fontSize: "0.75rem", padding: "2px 6px", borderRadius: 4, maxWidth: 180,
                    columnBreakInside: 'avoid', webkitColumnBreakInside: 'avoid'
                  }}
                  onClick={ev => {
                    console.log(ev.detail)
                    if (ev.detail == 1) { makeItDraggable({ ev, isActive: activeResponses?.[rid] ? true : false, fid, rid }) }
                    else if (ev.detail == 2) { makeItEditable({ ev, rid, fid }) }
                  }}
                >
                  <span style={{ fontWeight: 700 }}>{r?.user_name || "Facilitator"} </span>
                  {r?.editedText || r?.text}
                </div>
              }
            })
        })}
      </div>
    </div>
  }

  if (dim.w && dim.h) {
    return <div style={{
      height: '100%', padding: 0,
      display: "grid",
      gridTemplateRows: `repeat(${toolKit?.gridProps?.rowCount}, 1fr)`,
      gridTemplateColumns: `repeat(${toolKit?.gridProps?.colCount}, 1fr)`
    }}>
      {Object.keys(toolKit?.classes || {})
        .sort((a, b) => { return toolKit.classes[a].order - toolKit.classes[b].order })
        .map(cid => {
          return <div key={`cell-${cid}`} style={{ padding: 4 }}>
            <CellContent cell={toolKit?.classes[cid]} cid={cid} fid="f001" />
          </div>
        })}
    </div>
  } else {
    return null
  }
}

export default GridMap;