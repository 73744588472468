import React from 'react';
import './style.css'
import TakeAway_Button from '../../../Tools/TakeAway_Button';

export default ({ width, height, currentUnit, engagementData, manageToolData, isFullScreen, data }) => {

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className='ss_takeaway_button'>
                    <TakeAway_Button  currentUnit={currentUnit} data={data} />
                </div>
            </div>
        </div >
    );
}