import React from 'react';
import { Input } from '@material-ui/core'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let descriptionSize = '12px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '18px';
    }

    const inputTop = ['13.5%', '27%', '60.5%', '74%', '60.5%', '27%'];
    const inputRight = ['26%', '3.5%', '3.5%', '26%', '49.5%', '49.5%'];

    const top = ['21%', '34.5%', '68%', '81.5%', '68%', '34.5%'];
    const right = ['25%', '2.5%', '2.5%', '25%', '48.5%', '48.5%'];
    // const textBoxHeight = ['11%', '14%', '20%', '18%', '14.5%', '30%']

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.inputId.map((id, i) => <Input className='WIAC_any_role_input'
                        key={id}
                        placeholder="Fill Here."
                        style={{ fontSize: descriptionSize, lineHeight: '12px', width: '18.5%', top: inputTop[i], right: inputRight[i] }}
                        value={engagementData[data.inputId[i]] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: data.inputId[i], content: event.target.value })
                        }}
                    />)
                }
                {
                    data.textBoxId && data.textBoxId.map((textBoxId, i) => <textarea key={textBoxId} id={textBoxId} placeholder='Fill more…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ fontSize: descriptionSize, position: 'absolute', width: '20%', height: '17.5%', top: top[i], right: right[i] }}
                        value={engagementData[textBoxId] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: textBoxId, content: event.currentTarget.value })
                        }}
                    />)
                }

                {
                    data.centerTextBoxId && <textarea key={data.centerTextBoxId} id={data.centerTextBoxId} placeholder='Fill more…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ fontSize: descriptionSize, position: 'absolute', width: '14%', height: '16%', top: '47%', right: '28.5%', textAlign: 'center' }}
                        value={engagementData[data.centerTextBoxId] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: data.centerTextBoxId, content: event.currentTarget.value })
                        }}
                    />
                }

            </div>
        </div>
    );
}