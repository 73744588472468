import React from 'react';
import TextField from '@material-ui/core/TextField';
import Pdf_Document from '../Pdf_Pages'


export default ({ width, height,sessionId, engagementData, manageToolData, isFullScreen, data }) => {
    const handleSave=()=>{
console.log("pdf saved")
    }
    const top = ['18%', '47%', '76.5%'];
    return (
        <div style={{ width, height }}>
            <div style={{  width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((textBoxId, i) => <TextField key={textBoxId} id={textBoxId} placeholder='Fill Here…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                        value={engagementData[data.sessionType] ? engagementData[data.sessionType][textBoxId] : ''}
                        variant="outlined"
                        style={{ position: 'absolute', fontSize: isFullScreen ? '16px' : '12px', width: '47%', height: '22%', right: '23.5%', top: top[i] }}
                        onChange={(event) => manageToolData({
                            toolId : data.sessionType,
                            content : {
                              ...engagementData[data.sessionType],
                              [textBoxId] : event.target.value
                            }
                          })}
                    />)
                }
               { console.log(engagementData)}
               {/* {console.log(engagementData[textBoxId])} */}
               { console.log(manageToolData)}
            </div>
            <div style={{position:'relative',bottom:'60%',width:'20%'}}>
            <Pdf_Document
              engagementData={engagementData}
              manageToolData={manageToolData}
              data={data}
              sessionId={sessionId}
              isFullScreen={isFullScreen}
             onclick={handleSave}
            />
          </div>
        </div >
    );
}
