import React from 'react';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, currentUnit }) => {


    let descriptionSize = '12px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '16px';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <textarea id={data.textBoxId} placeholder='Fill in...'
                    className='transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'
                    style={{ fontSize: descriptionSize, position: 'absolute', width: '25%', height: '45%', top: '21%', right: '72%' }}
                    value={engagementData[data.id] ? engagementData[data.id][data.textBoxId] ? engagementData[data.id][data.textBoxId] : '' : ''}
                    onChange={(event) => manageToolData({
                        toolId: data.id, content: {
                            ...engagementData[data.id],
                            [data.textBoxId]: event.target.value
                        }
                    })}
                />
            </div>
        </div >
    );
}