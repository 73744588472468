const envSettings = window;

export default {
  ...envSettings,
  //   API_URL:    envSettings.API_URL,
  //   SOCKET_URL: envSettings.SOCKET_URL,  
  //   ACP_HOST:   envSettings.ACP_HOST,
  //   ACP_SHOW:   envSettings.ACP_SHOW,
  //   AP_HOST:    envSettings.AP_HOST,
  //   AP_SHOW:    envSettings.AP_SHOW,
  //   IR_HOST:    envSettings.IR_HOST,
  //   IR_SHOW:    envSettings.IR_SHOW,
  //   BPUT_HOST:  envSettings.BPUT_HOST,
  //   BPUT_SHOW:  envSettings.BPUT_SHOW,
  //   MyAPP_URL:  envSettings.MyAPP_URL,
  //   showBlog:   true||envSettings.showBlog
}