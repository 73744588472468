import React from 'react';
import { useTransition, animated } from 'react-spring';
import ImageSlide from '../ImageSlide';
import OtpScreen from '../App_ConductDashboard/OtpScreen';
import VideoSlide from '../VideoSlide';

import LearningTool from "../../LearningTools/index";
import { Tools } from "../../LearningTools/index";

import LiveSession from "../../Live_Session/index";
import { LiveSessions } from "../../Live_Session/index";

import { connect } from "react-redux";
import { manageToolData } from "../../containers/application/redux_actions";
import ScormPlayer from '../ScormPlayer';
import IdeaBoard from '../IdeaBoard/intro.js';
import ExternalTool from '../ExternalTool';
import Teamboard from '../ExternalTool/teamboard';
import WalkIntoFutureBoard from '../ExternalTool/walkIntoFutureBoard';
import GenericExternalBoard from '../ExternalTool/genericExternalBoard';
// import Config from '../../config';

let LinearNavigatorFrame = ({ setEndSessionPopup, slides, slideWidth, slideHeight, currentScene, currentUnit, typPC, isFullScreen, app, auth, manageToolData, language }) => {
  const transitions = useTransition(slides[currentScene], item => item.id, {
    from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
  });

  let populateItem = (item) => {
    if (item.type === "otp" && typPC !== 'Prepare')
      return <OtpScreen dimensions={{ slideHeight, slideWidth }} title={item.title} description={item.description} testType={item.testType} oneTime={true} />
    // if(item.type === "otp" && typPC === 'Prepare') continue;
    if (item.type === "img") {
      return <ImageSlide imgUrl={item.url} dimensions={{ slideHeight, slideWidth }} />
    }
    else if (item.type === "ppt") {
      return <ImageSlide imgUrl={app.isHindi ? item.url.HINDI : item.url.ENGLISH} dimensions={{ slideHeight, slideWidth }} />
    }
    else if (item.type === "video")
      return <VideoSlide vidUrl={item.url} dimensions={{ slideHeight, slideWidth }} imgUrl={item.imgUrl} />
    else if (item.type === "tool") {
      if (Tools[item.toolName])
        return <LearningTool
          width={slideWidth}
          height={slideHeight}
          engagementData={app.engagementData}
          manageToolData={manageToolData}
          data={item}
          isFullScreen={isFullScreen}
          currentUnit={currentUnit}
          app={app}
          auth={auth}
          language={language}
        />
      return <div style={{ width: slideWidth, height: slideHeight, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1>Interactive Tool here can be accessed from Conduct Section.</h1>
      </div>
    }
    else if (item.type === "live-session") {
      if (LiveSessions[item.sessionType] && typPC !== 'Prepare')
        return <LiveSession
          setEndSessionPopup={setEndSessionPopup}
          language={language}
          width={slideWidth}
          height={slideHeight}
          engagementData={app.engagementData}
          manageToolData={manageToolData}
          data={item}
          currentUnit={currentUnit}
          isFullScreen={isFullScreen}
          typPC={typPC}
        />
      return <div style={{ width: slideWidth, height: slideHeight, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1>Interactive Tool here can be accessed from Conduct Section.</h1>
      </div>
    }
    else if (item.type === "PPT") {
      return <iframe src={`https://docs.google.com/viewer?srcid=${item.url}&pid=explorer&efh=false&a=v&chrome=false&embedded=true&output=embed`} frameborder="0" width="100%" height="100%" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" />
    }
    else if (item.type === "multi_lang_img") {
      return <ImageSlide imgUrl={item.url[language]} dimensions={{ slideHeight, slideWidth }} />
    }
    else if (item.type === "multi_lang_video") {
      return <VideoSlide vidUrl={item.url[language]} dimensions={{ slideHeight, slideWidth }} imgUrl={item?.imgUrl?.[language] || ''} />
    }
    else if (item.type === "live-session-IdeaBoard") {
      if (item.toolId)
        return <IdeaBoard
          sessionId={app.createdSession._id || app.createdSession.id}
          toolId={item?.toolId}
          item={item}
          width={slideWidth}
          height={slideHeight}
          engagementData={app.engagementData}
          manageToolData={manageToolData}
          data={item}
          isFullScreen={isFullScreen}
          currentScene={currentScene}
          currentUnit={currentUnit}
          app={app}
          auth={auth}
          language={language}
        />

      return <div style={{ width: slideWidth, height: slideHeight, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1>Interactive Tool here can be accessed from Conduct Section.</h1>
      </div>
    }
    else if (item.type === "pedgog-tool-IdeaBoard") {
      if (item.toolId)
        // ExternalTool
        return <ExternalTool
          sessionId={app.createdSession._id || app.createdSession.id}
          toolId={item?.toolId}
          item={item}
          width={slideWidth}
          height={slideHeight}
          engagementData={app.engagementData}
          manageToolData={manageToolData}
          data={item}
          isFullScreen={isFullScreen}
          currentScene={currentScene}
          currentUnit={currentUnit}
          app={app}
          auth={auth}
          language={language}
        />

      return <div style={{ width: slideWidth, height: slideHeight, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1>Interactive Tool here can be accessed from Conduct Section.</h1>
      </div>
    }
    else if (item.type === "pedgog-tool-teamBoard") {
      if (item.toolId)
        // ExternalTool
        return <Teamboard
          sessionId={app.createdSession._id || app.createdSession.id}
          toolId={item?.toolId}
          item={item}
          width={slideWidth}
          height={slideHeight}
          engagementData={app.engagementData}
          manageToolData={manageToolData}
          data={item}
          isFullScreen={isFullScreen}
          currentScene={currentScene}
          currentUnit={currentUnit}
          app={app}
          auth={auth}
          language={language}
        />

      return <div style={{ width: slideWidth, height: slideHeight, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1>Interactive Tool here can be accessed from Conduct Section.</h1>
      </div>
    }
    else if (item.type === "pedgog-tool-WalkIntoFutureBoard") {
      if (item.toolId)
        // ExternalTool
        return <WalkIntoFutureBoard
          sessionId={app.createdSession._id || app.createdSession.id}
          toolId={item?.toolId}
          item={item}
          width={slideWidth}
          height={slideHeight}
          engagementData={app.engagementData}
          manageToolData={manageToolData}
          data={item}
          isFullScreen={isFullScreen}
          currentScene={currentScene}
          currentUnit={currentUnit}
          app={app}
          auth={auth}
          language={language}
        />

      return <div style={{ width: slideWidth, height: slideHeight, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1>Interactive Tool here can be accessed from Conduct Section.</h1>
      </div>
    }

    else if (item.type === "pedgog-tool-generic") {
      // console.log("app.createdSession._id || app.createdSession.id)", app.createdSession._id || app.createdSession.id)
      // console.log("item?.searchParams", item?.searchParams)
      if (item?.engagement && (app.createdSession._id || app.createdSession.id) && item?.genericToolPath && item?.genericToolPath?.length > 0)
        // ExternalTool
        return <GenericExternalBoard
          sessionId={app.createdSession._id || app.createdSession.id}
          toolId={item?.toolId}
          params={item?.searchParams}
          genericToolPath={item?.genericToolPath}
          queryOptions={item?.queryOptions}
          item={item}
          src={item?.src}
          width={slideWidth}
          height={slideHeight}
          engagementData={app.engagementData}
          manageToolData={manageToolData}
          data={item}
          isFullScreen={isFullScreen}
          currentScene={currentScene}
          currentUnit={currentUnit}
          app={app}
          auth={auth}
          language={language}
        />

      return <div style={{ width: slideWidth, height: slideHeight, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1>Interactive Tool here can be accessed from Conduct Section.</h1>
      </div>
    }
    else if (item.type == "scorm")
      return <ScormPlayer
        style={{ width: "100%", height: "100%" }}
        src={item.url?.[language || 'en'] || item.url}
        sendData={{
          name: "Sagar",
          // user_token,
          //  user_uid
        }}
        getData={(event) => {
          console.log(event)
        }}
        LMSGetValue={(varname) => {
          switch (varname) {
            // case "cmi.core.student_name":
            //   return props.auth.userAccount.fullname
            // case "cmi.core.student_id":
            //   // Trigger Event 
            //   // EVENT_NAME: SCROM_ACCESSED
            //   // EVENT_DATA : SCROM_ID
            //   return props.auth.userAccount.id
            // case "cmi.core.lesson_location":
            //   // GET LAST LOCATION
            //   console.log("Get", varname)
            //   return 1;
            // case "cmi.core.lesson_status":
            //   break;
            // case "name":
            //   return "Sagar"
            default:
              console.log("Get", varname)
              break;
          }
        }}
        LMSSetValue={(varname, varvalue) => {
          switch (varname) {
            // case "cmi.core.lesson_location":
            //   // Trigger Event
            //   // EVENT_NAME: SCROM_PROGRESS
            //   // EVENT_DATA: SCROM_ID, LOCATION: varvalue
            //   console.log("Progress", varvalue)
            //   break;
            // case 'com.illumine.test':
            //   // Trigger Event
            //   // EVENT_NAME: SCROM_TEST
            //   // EVENT_DATA: VARVALUE
            //   console.log("SCROM_TEST", varvalue)
            //   break
            // case "cmi.suspend_data":
            //      console.log("cmi.suspend_data", varvalue)
            //      const data = JSON.parse(LZString.decompressFromBase64(varvalue))                              
            //      callAPI(user_token, "POST", "https://uatapi.pedgog.in/v1/progress/createProgressPercentage", {
            //           "userId": user_uid,
            //           "moduleId": moduleId,
            //           "progressType": "Conduct",
            //           "roadmapId": "625e8d64735c18ca0e4e4051",
            //           "learningToolsId": "625e8dcf735c18ca0e4f5645",
            //           "activeContent": `${data?.p?.lastViewedSlide}`,
            //           "isLastSlide": "0",
            //           "sessionId": props.app?.createdSession?._id,
            //           "currentSlide": data?.p?.lastViewedSlide
            //      })

            //      break;
            default:
              console.log(varname, varvalue)
          }
        }}
      />
  }

  return <div className='linear-navigator'
    style={{
      boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.06)',
      backgroundColor: '#ffffff', position: 'relative',
      display: 'flex', justifyContent: 'center',
      marginLeft: isFullScreen && typPC !== 'Prepare' ? '84px' : '0%'
      // }, Config.ACP_SHOW && window.location.host == Config.ACP_HOST ? { width: '100%' } : ""}>
    }}>
    <div className='slide-cntr' style={{
      width: slideWidth, height: slideHeight,
      display: 'flex', justifyContent: 'center'
    }}>
      {
        transitions.map(({ item, props, key }) => {
          return <animated.div
            key={key}
            className="slide w-100 h-100"
            style={{
              ...props, position: 'absolute',
            }}
          >
            {populateItem(item)}
          </animated.div>
        })}
    </div>
  </div>
}


const mapStateToProps = state => ({
  app: state.app,
});


const mapDispatchToProps = dispatch => {
  return {
    manageToolData: data => dispatch(manageToolData(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinearNavigatorFrame);