import React from "react";
import "./ModuleCard.css";
import { Link } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
// import tickSrc from "./assets/tick.svg";
// import starSrc from "./assets/star.svg";
import ConductDialog from "./conductDialog";
import { useDispatch } from "react-redux";

let ModuleCardConduct = ({
  title,
  description,
  percentageCompleted,
  config,
  variant,
  moduleId,
  user_token,
  coach_id,
  orgDetails,
  selfCertifyFlag,
  continueConduct,
  sessionDate,
  sessionId,
  continueConductTitle,
  currentSlideIndex,
  roadmapId,
  learningToolsId,
  auth
}) => {
  const dispatch = useDispatch();
  let footerBlk = (variant) => {
    switch (variant) {
      case 0: //continue prepare
        return (
          <div style={{ width: 120 }}>
            <LinearProgress
              variant="determinate"
              value={percentageCompleted || 0}
              classes={{ root: "progress-root", bar: "progress-bar" }}
            />
          </div>
        );
      case 1: //Ready to self certify
        return (
          <div className="button-text">
            {selfCertifyFlag === 1 ? (
              <Link
                className="button-label"
                to={`/application/selfcertify/${moduleId}`}
              >
                Self Certify
              </Link>
            ) : null}
          </div>
        );
      case 2: //Available for prepare
        return <div></div>;
      // case 3: //Certified
      //   return <div className='button-text'>
      //     <Link className='button-label' to={`/application/conduct/${moduleId}`} >Take Print</Link>
      //   </div>
      default:
        return null;
    }
  };

  const handleSetActiveConduct = () => {
    dispatch({
      type: "SET_ACTIVE_CONDUCT",
      payload: {
        sessionId, currentSlideIndex,
        roadmapId,
        learningToolsId,
      },
    });
  };

  return (
    <div className="module-card">
      {percentageCompleted === 100 ? (
        <div className="status-icon">
          {/* <img src={starSrc} alt="Illumine"/> */}
        </div>
      ) : null}
      <div className="-card-body">
        {continueConduct && (
          <div className="subtitle" style={{ marginBottom: 6 }}>
            {title}
          </div>
        )}
        <h6 style={continueConduct ? { height: "auto" } : { height: 50 }}>
          {description}
        </h6>
        {continueConduct && (
          <React.Fragment>
            <div className="subtitle">{continueConductTitle}</div>
            <div className="subtitle" style={{ marginBottom: 6 }}>
              {sessionDate}
            </div>
          </React.Fragment>
        )}
        <div className="cFoot">
          {footerBlk(variant)}
          {orgDetails.conductFlag === "0" ? (
            <div className="button-text">
              <Link
                className="button-label"
                to={`/application/conduct/${moduleId}`}
                onClick={handleSetActiveConduct}
              >
                Conduct
              </Link>
            </div>
          ) : (
            <ConductDialog
              moduleId={moduleId}
              title={title}
              description={description}
              user_token={user_token}
              coach_id={coach_id}
              testType="pre"
              auth={auth}
            />
          )}
          {/* <ConductDialog moduleId={moduleId} title={title} description={description} user_token={user_token} coach_id={coach_id} testType='pre'/> */}
        </div>
      </div>
    </div>
  );
};

export default ModuleCardConduct;
