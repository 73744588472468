import React, { 
  // useState, 
  useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Pie } from 'react-chartjs-2';
import { getEngagement } from '../../containers/application/redux_actions'
// import Chart from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';


// Chart.plugins.register(ChartDataLabels);
export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
     console.log(data)

     
    const user_token = useSelector((state) => state.auth.user_token)
    useEffect(() => {
        getEngagement(user_token, data.engagement)
    }, [])
    const toolTipref = useRef('chartjs-tooltip')
    const responses = useSelector(state => state.app.response[data.engagement]);
    const currentEngagement = useSelector(state => state.app.currentEngagment);
    console.log(responses)
    console.log(currentEngagement)
    let value, options;
    if (responses) {

        // const toolTipValues = [
        //     ['user1',],
        //     ['user2', 'user3'],
        //     ['user6', 'user7', 'user8']
        // ];

        let labels = [], _data = []
        // const toolTipValuess = Object.keys(responses).map((item, i) => {
        //     labels = [...labels, item];
        //     _data = [..._data, responses[item].length]
        //     return responses[item];
        // })
        const toolTipValuess = currentEngagement ? currentEngagement.answers.map((item, i) => {
            labels = [...labels, item.text];
            _data = [..._data, responses[item.text] === undefined ? 0 : responses[item.text].length]
            return responses[item.text]&&responses[item.text].map((t)=>{

              return t.user
          }

          )
        }) : []




        value = {
            labels,
            datasets: [
                {
                    label: '# of Votes',
                    data: _data || null,
                    backgroundColor: [
                        '#E6EC97',
                        '#B595EC',
                        '#00000033',
                        
                      ],
                      
                    
                    borderWidth: 1,
                    
                },
            ],

        }
        
        options = {
            title:{
                display:true,
                text:'Student Response',
                fontSize:20
              },
              legend:{
                display:true,
                position:'right'
              },
              responsive: true,
              plugins: {
                datalabels: {
                  formatter: (value, ctx) => {
                    let datasets = ctx.chart.data.datasets;
                    console.log(datasets)
                    if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                      let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                      let percentage = Math.round((value / sum) * 100) + "%";
                      return percentage;
                    } else {
                      return 0;
                    }
                  },
                  color: "#000000"
                }
              },
              
              tooltips: {
                callbacks: {
                  label: function (tooltipItem, data) {
                      return toolTipValuess[tooltipItem.index];
                      // percentage
                      //data['datasets'][0]['data'][tooltipItem['index']] + '%';
                  },
                  
                  title: function (tooltipItem, data) {
                      return;
                  }
              },
              
            
                custom: function (tooltip) {
                    if (!tooltip) return;
                    // disable displaying the color box;
                    tooltip.displayColors = false;
                },

            }
        }
    }

    return (
        <div style={{ width, height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', height: '60%' }}>
            {/* <canvas id="pie-chart"></canvas> */}
                 <Pie id="pie-chart" data={value} ref={toolTipref} options={options} redrawn /> 
            </div>
        </div>
    );
}