import React from "react"
let One = ({ fill }) => {
    return (
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4998 25.5C10.7706 25.5 9.14559 25.1717 7.62476 24.515C6.10392 23.8592 4.78101 22.9688 3.65601 21.8438C2.53101 20.7188 1.64059 19.3958 0.984756 17.875C0.328089 16.3542 -0.000244141 14.7292 -0.000244141 13C-0.000244141 11.2708 0.328089 9.64583 0.984756 8.125C1.64059 6.60417 2.53101 5.28125 3.65601 4.15625C4.78101 3.03125 6.10392 2.14042 7.62476 1.48375C9.14559 0.827917 10.7706 0.5 12.4998 0.5C14.2289 0.5 15.8539 0.827917 17.3748 1.48375C18.8956 2.14042 20.2185 3.03125 21.3435 4.15625C22.4685 5.28125 23.3589 6.60417 24.0148 8.125C24.6714 9.64583 24.9998 11.2708 24.9998 13C24.9998 14.7292 24.6714 16.3542 24.0148 17.875C23.3589 19.3958 22.4685 20.7188 21.3435 21.8438C20.2185 22.9688 18.8956 23.8592 17.3748 24.515C15.8539 25.1717 14.2289 25.5 12.4998 25.5ZM11.8748 19.25H14.3748V6.75H9.37476V9.25H11.8748V19.25Z" fill={fill} />
        </svg>
    )
}
let Two = ({ fill }) => {
    return (
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 25.5C10.7708 25.5 9.14583 25.1717 7.625 24.515C6.10417 23.8592 4.78125 22.9688 3.65625 21.8438C2.53125 20.7188 1.64083 19.3958 0.985 17.875C0.328333 16.3542 0 14.7292 0 13C0 11.2708 0.328333 9.64583 0.985 8.125C1.64083 6.60417 2.53125 5.28125 3.65625 4.15625C4.78125 3.03125 6.10417 2.14042 7.625 1.48375C9.14583 0.827917 10.7708 0.5 12.5 0.5C14.2292 0.5 15.8542 0.827917 17.375 1.48375C18.8958 2.14042 20.2188 3.03125 21.3438 4.15625C22.4688 5.28125 23.3592 6.60417 24.015 8.125C24.6717 9.64583 25 11.2708 25 13C25 14.7292 24.6717 16.3542 24.015 17.875C23.3592 19.3958 22.4688 20.7188 21.3438 21.8438C20.2188 22.9688 18.8958 23.8592 17.375 24.515C15.8542 25.1717 14.2292 25.5 12.5 25.5ZM8.75 19.25H13.75C14.4375 19.25 15.0262 19.0054 15.5163 18.5163C16.0054 18.0263 16.25 17.4375 16.25 16.75V14.875C16.25 14.3333 16.0729 13.8854 15.7188 13.5312C15.3646 13.1771 14.9167 13 14.375 13C14.9167 13 15.3646 12.8229 15.7188 12.4688C16.0729 12.1146 16.25 11.6667 16.25 11.125V9.25C16.25 8.5625 16.0054 7.97375 15.5163 7.48375C15.0262 6.99458 14.4375 6.75 13.75 6.75H8.75V9.25H13.75V11.75H11.25V14.25H13.75V16.75H8.75V19.25Z" fill={fill} />
        </svg>
    )
}

let Three = ({ fill }) => {
    return (
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4998 25.5C10.7706 25.5 9.14559 25.1717 7.62476 24.515C6.10392 23.8592 4.78101 22.9688 3.65601 21.8438C2.53101 20.7188 1.64059 19.3958 0.984756 17.875C0.328089 16.3542 -0.000244141 14.7292 -0.000244141 13C-0.000244141 11.2708 0.328089 9.64583 0.984756 8.125C1.64059 6.60417 2.53101 5.28125 3.65601 4.15625C4.78101 3.03125 6.10392 2.14042 7.62476 1.48375C9.14559 0.827917 10.7706 0.5 12.4998 0.5C14.2289 0.5 15.8539 0.827917 17.3748 1.48375C18.8956 2.14042 20.2185 3.03125 21.3435 4.15625C22.4685 5.28125 23.3589 6.60417 24.0148 8.125C24.6714 9.64583 24.9998 11.2708 24.9998 13C24.9998 14.7292 24.6714 16.3542 24.0148 17.875C23.3589 19.3958 22.4685 20.7188 21.3435 21.8438C20.2185 22.9688 18.8956 23.8592 17.3748 24.515C15.8539 25.1717 14.2289 25.5 12.4998 25.5ZM8.74976 19.25H16.2498V16.75H11.2498V14.25H13.7498C14.4373 14.25 15.026 14.005 15.516 13.515C16.0052 13.0258 16.2498 12.4375 16.2498 11.75V9.25C16.2498 8.5625 16.0052 7.97375 15.516 7.48375C15.026 6.99458 14.4373 6.75 13.7498 6.75H8.74976V9.25H13.7498V11.75H11.2498C10.5623 11.75 9.97392 11.9946 9.48476 12.4837C8.99476 12.9738 8.74976 13.5625 8.74976 14.25V19.25Z" fill={fill} />
        </svg>
    )
}



let CheckCircle = ({ }) => {
    return (
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.75 18.75L19.5625 9.9375L17.8125 8.1875L10.75 15.25L7.1875 11.6875L5.4375 13.4375L10.75 18.75ZM12.5 25.5C10.7708 25.5 9.14583 25.1717 7.625 24.515C6.10417 23.8592 4.78125 22.9688 3.65625 21.8438C2.53125 20.7188 1.64083 19.3958 0.985 17.875C0.328333 16.3542 0 14.7292 0 13C0 11.2708 0.328333 9.64583 0.985 8.125C1.64083 6.60417 2.53125 5.28125 3.65625 4.15625C4.78125 3.03125 6.10417 2.14042 7.625 1.48375C9.14583 0.827917 10.7708 0.5 12.5 0.5C14.2292 0.5 15.8542 0.827917 17.375 1.48375C18.8958 2.14042 20.2188 3.03125 21.3438 4.15625C22.4688 5.28125 23.3592 6.60417 24.015 8.125C24.6717 9.64583 25 11.2708 25 13C25 14.7292 24.6717 16.3542 24.015 17.875C23.3592 19.3958 22.4688 20.7188 21.3438 21.8438C20.2188 22.9688 18.8958 23.8592 17.375 24.515C15.8542 25.1717 14.2292 25.5 12.5 25.5Z" fill="#549727" />
        </svg>
    )
}
export { One, Two, Three, CheckCircle }