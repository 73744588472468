import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import store from '../../../Redux_Store';
import './style.css'
import SessionList from './sessionList';
import BatchCreation from './batchCreation.js';
import NewBatchCreation from './newBatchCreation.js';

const useStyles = makeStyles((theme) => ({
    root: {},
    paper1: {
        width: '45%',
        height: 'max-content',
        // height: '96%',
        borderRadius: '24px',
        overflow: 'hidden'
    },
    buttonLabel: {
        fontFamily: 'tablet-gothic !important'
    },
    buttonRoot: {
        '&:hover': {
            backgroundColor: '#e4001b !important',
            color: 'white',
        }
    },
    select: {
        "& .Muipaper-root": {
            color: 'red'
        },
        "& ul": {
            width: '37vw !important',
        },
        "& li": {
            textOverflow: ' clip',
            overflow: 'hidden',
            whiteSpace: 'inherit'
        },
    },
    selectBorder: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#f1f1f1',
        overflow: 'hidden',
        whiteSpace: 'normal',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important',
        },
        "&:hover .MuiOutlinedInput-root": {
            borderColor: 'black'
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: 'black'
        }
    },
    inputEmpty: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#FFFFFF',
        overflow: 'hidden',
        whiteSpace: 'normal',
    },
    inputFilled: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#f1f1f1',
        overflow: 'hidden',
        whiteSpace: 'normal',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important',
        },
        "&:hover .MuiOutlinedInput-root": {
            borderColor: 'black'
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: 'black'
        }
    },
    selectFilled: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#f1f1f1',
        overflow: 'hidden',
        whiteSpace: 'normal',
    },
    newOutlinedSelect: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#f1f1f1',
        overflow: 'hidden',
        whiteSpace: 'normal',
    },
    outlinedSelectIcon: {
        width: '2em',
        height: "2em",
        top: 'unset'
    },
    batchInputRoot: {}
}));

const useStyles_2 = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '90%',
        borderRadius: '24px',
        overflow: 'hidden'
    }
}));

const useStyles_3 = makeStyles((theme) => ({
    paper: {
        width: '40%',
        padding: '2em',
        borderRadius: '24px'
    }
}));


function ConfirmationDialog(props) {

    const handleCancel = () => {
        props.handleCloseConfirm();
    };

    const handleOk = () => {
        props.handleCloseAll();
        // window.location.pathname = "/application"
    };

    const classes_3 = useStyles_3();


    return (
        <Dialog
            aria-labelledby="confirmation-dialog-title"
            open={props.confirmOpen}
            className='ir-tisa-font'
            classes={{
                paper: classes_3.paper
            }}
        >
            <div style={{ fontWeight: 'bold' }}>
                Are you sure you want to cancel?
            </div>
            <div style={{ margin: '1em 0', fontSize: '0.8em' }}>
                This will cancel the session and you will loose the attendance marked.
            </div>

            <div className='d-flex align-items-center justify-content-space-between' style={{ marginTop: '1em' }}>
                <Button autoFocus onClick={handleCancel} className='end-button-dialog-drawer'>
                    DON'T CANCEL
                </Button>
                <Button onClick={handleOk} className='dont-end-button-dialog-drawer'>
                    CANCEL SESSION
                </Button>
            </div>
        </Dialog>
    );
}

const etah = (event) => {
    switch (event.eventName) {
        case "JOIN_SESSION":
            store.dispatch({ type: "STUDENT_JOINED", payload: event.eventData })
            break;

        default:
            console.log(event)
    }
}


function InfoDialog({ moduleIndexText, open, firstModule, index, title, description, moduleId, user_token, coach_id, app, handleCloseInfo, estimatedTime, }) {

    const classes = useStyles();
    const classes_2 = useStyles_2();


    const [confirmOpen, setConfirmOpen] = useState(false);

    const [displayStudent, setDisplayStudent] = useState(false);
    const [batchTextValue, setBatchTextValue] = useState({ batch: '', coTrainer: '' });

    // const [batchTextValue, setBatchTextValue] = useState('');

    const handleCloseAll = () => {
        setConfirmOpen(false);

        handleCloseInfo(false);
    };


    const handleCloseConfirm = () => {
        setConfirmOpen(false);
    };

    return (
        <Dialog
            aria-labelledby="info-dialog-title"
            open={open}
            maxWidth={false}
            classes={{
                paper: displayStudent ? classes_2.paper : classes.paper1,
            }}

            className='ir-tisa-font'
        >
            <div className='d-flex w-100 h-100'>
                <div className='w-100' style={{ padding: '0.5em' }}>
                    {['6548c6d4a023bfda2cfbf78d', '6699042ad4c2a97ccab5853c'].includes(app?.user?.programId) ? <NewBatchCreation
                        app={app}
                        moduleIndexText={moduleIndexText}
                        coach_id={coach_id}
                        moduleIndex={index}
                        handleCloseInfo={handleCloseInfo}
                        moduleId={moduleId}
                        user_token={user_token}
                        grpModuleIndex={'a'}
                        firstModule={firstModule}
                    /> :
                        <BatchCreation
                            app={app}
                            coach_id={coach_id}
                            moduleIndex={index}
                            handleCloseInfo={handleCloseInfo}
                            moduleId={moduleId}
                            user_token={user_token}
                            grpModuleIndex={'a'}
                            firstModule={firstModule}
                        />}

                </div>
            </div>

            <ConfirmationDialog
                id="confirm-id"
                confirmOpen={confirmOpen}
                open={open}
                handleCloseAll={handleCloseAll}
                handleCloseConfirm={handleCloseConfirm}
            />

        </Dialog >
    );
}



export default function ConductDialog({ moduleIndexText, firstModule, index, title, description, mainFilePath, moduleId, uniqueAboutIt, user_token, coach_id, auth, app, buttonTextComponent, buttonClassName, rootClassName, estimatedTime }) {
    const [open, setOpen] = useState(window.location.pathname == `/application/otp/${moduleId}` ? true : false)
    const handleCloseInfo = () => {
        setOpen(false);
    };

    return (
        <div className={rootClassName}>
            <div className={buttonClassName} onClick={
                () => {
                    setOpen(true)
                }}>
                {buttonTextComponent}
            </div>

            <InfoDialog
                moduleIndexText={moduleIndexText}
                id="Info-id"
                moduleId={moduleId}
                title={title}
                firstModule={firstModule}
                description={description}
                index={index}
                mainFilePath={mainFilePath}
                user_token={user_token}
                coach_id={coach_id}
                auth={auth}
                app={app}
                keepMounted
                open={open}
                testType="pre"
                handleCloseInfo={handleCloseInfo}
                estimatedTime={estimatedTime}
            />
        </div >
    );
}
