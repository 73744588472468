import { exchangeFirebase as fb, blogFirebase } from "./fb_init";
import { getDatabase, onValue, ref, update, child, set, push, off, onChildAdded } from "firebase/database";
const rtdb = getDatabase(fb);
// const rtdbBlog = getDatabase(blogFirebase);

const connectToANode = ({ refString, f }) => {
    return onValue(ref(rtdb, refString), (s) => f(s.val()));
};

const listenToChildAdd = ({ refString, f }) => {
    return onChildAdded(ref(rtdb, refString), (s) => f(s.key, s.val()));
};

const writeData = (target, data) => {
    return set(ref(rtdb, target), data);
}

const updateData = (updates) => {
    return update(ref(rtdb), updates);
}

const getANewPostKey = async (target) => {
    return push(child(ref(rtdb), target)).key;
}

const offNode = async (target) => {
    return off(ref(rtdb), target)
}

const pushInNode = async (target) => {
    return push(child(ref(rtdb), target))
}


export { connectToANode, writeData, updateData, getANewPostKey, offNode, listenToChildAdd, pushInNode }