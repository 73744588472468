import React from 'react';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    const top = ['18%', '47%', '76.5%'];
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((textBoxId, i) => <textarea key={textBoxId} id={textBoxId} placeholder='Fill Here…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                        value={engagementData[textBoxId] || ''}
                        style={{ position: 'absolute', fontSize: isFullScreen ? '16px' : '12px', width: '47%', height: '22%', right: '23.5%', top: top[i] }}
                        onChange={(event) => manageToolData({ toolId: textBoxId, content: event.target.value })}
                    />)
                }
            </div>
        </div >
    );
}