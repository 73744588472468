import { Button, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store from '../../../Redux_Store';
import { loginWithToken } from '../../auth/redux_actions';
import { getSingleModule } from '../redux_actions';

const Preview = (props) => {

    let moduleId = props.match.params.moduleId;
    let token = props.match.params.token;

    let { isLoggedIn } = useSelector((state) => state.auth);
    let { moduleDetails } = useSelector((state) => state.app);
    let [moduleData, setModuleData] = useState(moduleDetails?.[moduleId] || null)

    const handleAutoLogin = async () => {
        store.dispatch({ type: "LOGIN_ATTEMPT" });
        await loginWithToken({ auto_login_token: token })
            .then((res) => {
                if (res?.code) {
                    store.dispatch({
                        type: "USER_VALUE_CHANGE",
                        payload: { key: "error", value: "Please check and re-enter OTP" },
                    });
                }
                store.dispatch({ type: "LOGIN_WITH_OTP", payload: res });
                // store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'otpTimer', value: 0 } });
                // store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'otp', value: 0 } });
            })
            .catch((error) => {
                console.log(error);
            });
    };


    useEffect(() => {
        if (moduleId && token && !moduleData) {
            getSingleModule({ id: moduleId, token })
        }

    }, [moduleId, token])
    console.log({ isLoggedIn, moduleDetails, moduleData: moduleDetails[moduleId] })

    useEffect(() => { if (!isLoggedIn && token) handleAutoLogin(); }, [token]);

    return <>
        {isLoggedIn && moduleDetails?.[moduleId] ?
            <>
                <Link to={`/application/prepare/${moduleId}`}>
                    Prepare View
                </Link>
                <Link to={`/application/conduct/${moduleId}`}>
                    Conduct View
                </Link>

            </>
            :
            <CircularProgress />
        }
    </>
}

export default Preview;