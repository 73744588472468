import React from 'react';
import Modal_Component from '../../../Tools/Modal_Component';
export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let descriptionSize = '14px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '18px';
    }

    const right = ['65%', '5%'];
    const width_textBox = ['31%', '51%'];
    // const textBoxHeight = ['11%', '14%', '20%', '18%', '14.5%', '30%']

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((textBoxId, i) => <textarea key={textBoxId} id={textBoxId} placeholder='Fill Here…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ fontSize: descriptionSize, position: 'absolute', width: width_textBox[i], top: '22%', right: right[i], height: '25%' }}
                        value={engagementData[textBoxId] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: textBoxId, content: event.currentTarget.value })
                        }}
                    />)
                }
                <div className='Think-sol-sp-step_1_hint-popup'>
                    <Modal_Component
                        type={data.popUp_content.type}
                        icon={data.popUp_content.icon}
                        Component={<div className='Think-sol-sp-step_1_hint-popup-component tct-scrollable-darkgray'>{data.popUp_content.src}</div>}
                        width={width}
                        height={height}

                    />
                </div>

            </div>
        </div>
    );
}