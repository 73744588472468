import React from 'react';
import Slider_Response from '../../../Live_Session_Tools/Slider_Response'
export default ({ width, height, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId, responses, currentEngagement, uniqueStudentsName }) => {

    console.log(currentEngagement)
    console.log(data)
    const top = ['47.5%', '53.5%', '59.5%', '65.5%', '71.5%', '77.5%']
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    currentEngagement && currentEngagement.Sliders && currentEngagement.Sliders.map((slider, i) =>
                        <div key={i} style={{ position: 'absolute', top: top[i], right: '4%', width: '29%', height: '6%' }}>
                            <Slider_Response width='100%' height='100%'
                                engagementData={engagementData}
                                manageToolData={manageToolData}
                                data={data}
                                sliderIndex={i}
                                isFullScreen={isFullScreen}
                                engagementStatus={engagementStatus}
                                sessionId={sessionId}
                                responses={responses}
                                currentEngagement={currentEngagement}
                                uniqueStudentsName={uniqueStudentsName}
                            />
                        </div>)
                }
            </div>
        </div >
    );
}