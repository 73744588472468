import React from 'react';

import Slider_Component from '../../../Tools/Slider_Component';

import TextBoxReadOnly from '../TextBox_ReadOnly'

import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let textbox_1_top = '21.5%';

    let slider_top = '69.5%';

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div style={{ width: '100%', height: '45%', position: 'absolute', top: textbox_1_top }}>
                    <TextBoxReadOnly data={data} engagementData={engagementData} manageToolData={manageToolData} isFullScreen={isFullScreen} />
                </div>
                <div style={{ width: '100%', height: '13%', display: 'flex', justifyContent: 'space-around',position: 'absolute', top: slider_top }}>
                    <div className='COD_AB_Actions_Display-Slider-Container'>
                        <div style={{ width: '14%', height: '70%' }}>
                            <img src={data.slider_leftImg} style={{ width: '100%', height: '100%' }} alt='' />
                        </div>
                        <div style={{ width: '55%', padding: '0 2%', display: 'flex', alignItems: 'center' }}>
                            <Slider_Component step={25} min={0} max={75}
                                id={data.slider_id[0]}
                                disabled
                                isFullScreen={isFullScreen}
                                engagementData={engagementData}
                                manageToolData={manageToolData}
                            />
                        </div>
                        <div style={{ width: '18%', height: '80%' }}>
                            <img src={data.slider_rightImg} style={{ width: '100%', height: '100%' }} alt='' />
                        </div>
                    </div>
                    <div className='COD_AB_Actions_Display-Slider-Container'>
                        <div style={{ width: '14%', height: '70%' }}>
                            <img src={data.slider_leftImg} style={{ width: '100%', height: '100%' }} alt='' />
                        </div>
                        <div style={{ width: '55%', padding: '0 2%', display: 'flex', alignItems: 'center' }}>
                            <Slider_Component step={25} min={0} max={75}
                                id={data.slider_id[1]}
                                isFullScreen={isFullScreen}
                                engagementData={engagementData}
                                manageToolData={manageToolData}
                            />
                        </div>
                        <div style={{ width: '18%', height: '80%' }}>
                            <img src={data.slider_rightImg} style={{ width: '100%', height: '100%' }} alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}