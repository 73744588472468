import React, { useEffect, useRef } from 'react';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import './style.css'


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let textSize = '8px';
    if (isFullScreen)
        textSize = '14px';

    const textBoxEndRef = useRef(null);

    useEffect(async () => {
        if (!engagementData || !engagementData[data.id])
            manageToolData({
                toolId: data.id,
                content: data.prevFilledData
            });

    }, [data.id])



    useEffect(() => {
        isFullScreen && scrollToBottom();
    }, [!isFullScreen, engagementData[data.id]])


    const addTextBox = async (key) => {
        let len = Object.keys(engagementData && engagementData[data.id] && engagementData[data.id][key] && engagementData[data.id][key])?.length;

        await manageToolData({
            toolId: data.id,
            content: {
                ...engagementData[data.id],
                [key]: {
                    ...engagementData[data.id][key],
                    [`textBox_${len}`]: ''
                }
            }
        });
    }

    const scrollToBottom = () => {
        if (isFullScreen && textBoxEndRef && textBoxEndRef.current)
            textBoxEndRef.current.scrollIntoView({ behavior: "smooth" })
        // console.log(textBoxEndRef)
    };

    const handleChange = (event, key, textBoxId) => {
        manageToolData({
            toolId: data.id,
            content: {
                ...engagementData[data.id],
                [key]: {
                    ...engagementData[data.id][key],
                    [textBoxId]: event.target.value
                }
            }
        })
    };

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    engagementData && engagementData[data.id] && Object.keys(engagementData[data.id])?.map((item) => <div key={item + ' stextbox'} className={`sma-mustDo-textbox-container sma-mustDo-textbox-container-${item}`}>
                        <div className='w-100 h-100 tct-scrollable-darkgray overFlowYAuto'> {
                            Object.keys(engagementData[data.id]?.[item]).map((textBoxId, index) =>
                                <div key={item + textBoxId + index} className='sma-mustDo-textbox-bg' style={{ backgroundImage: `url(${data.textBoxBgImg})` }}>
                                    <textarea
                                        ref={textBoxEndRef}
                                        placeholder={`${index + 1}. Fill here`}
                                        className='transparent-textarea textArea-OnHover-To-Scroll bierstadt-regular w-100 tct-scrollable-darkgray h-100'
                                        style={{ fontSize: textSize, fontStyle: 'italic' }}
                                        value={engagementData?.[data.id]?.[item]?.[textBoxId]}
                                        onChange={(event) => { handleChange(event, item, textBoxId) }}
                                    />
                                </div>
                            )}
                        </div>
                        <AddCircleIcon style={{ color: '#C00000', fontSize: '24px' }} className='align-self-end button-click-effect' onClick={() => { addTextBox(item); scrollToBottom() }} />
                    </div>)
                }
            </div>

            {/* <div style={{ fontSize: textSize, right: data.carryForward && '33%' }} className='bierstadt-regular sma-best-strategy-add-textbox' onClick={addTextBox}>
                    + Add another strategy
                </div> */}

        </div>

    );
}