import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';

import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let descriptionSize = '11px';

    let textbox_1_right = '29.8%';
    let textbox_1_top = '39.5%';

    let textbox_2_right = '3.8%';
    let textbox_2_top = '44.5%';

    let textbox_3_right = '57%';
    let textbox_3_top = '79.5%';

    let textbox_4_right = '31.5%';
    let textbox_4_top = '81.5%';

    let textbox_5_right = '4%';
    let textbox_5_top = '77.5%';

    if (isFullScreen) {
        descriptionSize = '14px';
        textbox_1_right = '33.5%';
        textbox_2_right = '9%';
        textbox_3_right = '59%';
        textbox_4_right = '35%';
        textbox_5_right = '9.5%';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className={`ESI_Slide_9_textBox work-sans`} style={{ fontSize: descriptionSize, top: textbox_1_top, right: textbox_1_right }}>
                    <TextEditor id={data.textBoxId[0]} initialData={engagementData[data.textBoxId[0]] || null}
                        manageToolData={manageToolData} hideToolbar={true}
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
                <div className={`ESI_Slide_9_textBox work-sans`} style={{ fontSize: descriptionSize, top: textbox_2_top, right: textbox_2_right }}>
                    <TextEditor id={data.textBoxId[1]} initialData={engagementData[data.textBoxId[1]] || null}
                        manageToolData={manageToolData} hideToolbar={true}
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
                <div className={`ESI_Slide_9_textBox work-sans`} style={{ fontSize: descriptionSize, top: textbox_3_top, right: textbox_3_right }}>
                    <TextEditor id={data.textBoxId[2]} initialData={engagementData[data.textBoxId[2]] || null}
                        manageToolData={manageToolData} hideToolbar={true}
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
                <div className={`ESI_Slide_9_textBox work-sans`} style={{ fontSize: descriptionSize, top: textbox_4_top, right: textbox_4_right }}>
                    <TextEditor id={data.textBoxId[3]} initialData={engagementData[data.textBoxId[3]] || null}
                        manageToolData={manageToolData} hideToolbar={true}
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
                <div className={`ESI_Slide_9_textBox work-sans`} style={{ fontSize: descriptionSize, top: textbox_5_top, right: textbox_5_right }}>
                    <TextEditor id={data.textBoxId[4]} initialData={engagementData[data.textBoxId[4]] || null}
                        manageToolData={manageToolData} hideToolbar={true}
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
            </div>
        </div>
    );
}