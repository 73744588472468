import React from 'react';
import './style.css'
import TextEditor from '../../../Tools/TextEditor/TextEditor';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let textSize = '14px'
    if (isFullScreen)
        textSize = '16px'

    const [isOpen, setIsOpen] = React.useState(false);
    const toggleDrawer = () => setIsOpen(!isOpen);


    const handleChange = (event, textBoxId) => {
        manageToolData({
            toolId: data.id,
            content: {
                ...engagementData[data.id],
                [textBoxId]: event.target.value
            }
        })
    };


    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', overflow: 'hidden' }}>
                {/* <div className='sma-best-strategy-card-container'>
                    <div className='sma-best-strategy-card-number-container'>
                        <div className='sma-best-strategy-card-number' style={{ fontSize: textSize, backgroundColor: 'white' }}>
                            <div style={{ fontSize: textSize, fontWeight: 'bold', color: '#b30d0d' }}>Card  1 </div>
                        </div>
                    </div>
                    <img className='w-100 h-100' src={data.cardImg} alt='card' />
                </div> */}

                {/* <textarea
                    placeholder='Fill your strategies here.'
                    className='sma-best-strategy-textbox-container transparent-textarea  textArea-OnHover-To-Scroll bierstadt-regular tct-scrollable-darkgray multiline-input-underline'
                    style={{ fontSize: textSize, lineHeight: textSize }}
                    value={engagementData?.[data.id]?.[data.textBoxId]}
                    onChange={(event) => { handleChange(event, data.textBoxId) }}

                /> */}
                <div className='sma-best-strategy-textbox-container' style={{ width: isOpen ? '33%' : '35%', fontSize: textSize, lineHeight: textSize }}>
                    <TextEditor id={data.id} initialData={engagementData[data.id] ? engagementData[data.id][data.textBoxId] : null}
                        manageToolData={manageToolData}
                        scrollable='tct-scrollable-darkgray'
                        placeholder='Fill your strategies here.'
                        // textEditorClass='UPTC_CS_Square-textEditorClass'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                        oneHierarchy={true}
                        textBoxId={data.textBoxId}
                        engagementData={engagementData}
                    />
                </div>

                <div className='sma-best-strategy-hint-popup-icon' onClick={toggleDrawer}>
                    <img src={data?.hint?.icon} className='w-100 h-100 button-click-effect' alt='icon' />
                </div>
                <div className={isOpen ? "sma-best-strategy-new-hint-content tct-scrollable-darkgray" : "sma-best-strategy-new-hint-hide tct-scrollable-darkgray"}>
                    {data?.hint?.content?.map((hint, index) => <div key={hint} style={{ fontSize: textSize }} className='bierstadt-regular sma-best-strategy-hint-content-list'>
                        <span className='font-weight-bold'>{index + 1}]</span> {hint}</div>)}
                </div>

            </div>
        </div>
    );
}