import { Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../auth/redux_actions";
import InputField from "./Input";

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    height: "68px",
    borderRadius: "17px",
    "&:hover": {
      // backgroundColor: '#e4001b !important',
      color: "white",
    },
  },
}));

export default ({ auth, app, type }) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (event, prop) => {
    setValues((oldValue) => ({
      ...oldValue,
      [prop]: event.target.value,
      error: undefined,
    }));
  };

  return (
    <div>
      <InputField
        type="Text"
        field="email"
        values={values}
        handleChange={handleChange}
        placeHolder="Enter Email Address"
        error={values?.error}
      />
      <InputField
        type="Password"
        field="password"
        values={values}
        handleChange={handleChange}
        handleClickShowPassword={() => {
          setValues((oldValue) => ({
            ...oldValue,
            showPassword: !oldValue.showPassword,
          }));
        }}
        placeHolder="Enter Password"
        error={values?.error}
      />
      {values?.error && <div className="ir-error-msg">{values.error}</div>}
      <Button
        className="login-button-ap"
        onClick={() => {
          loginUser({ email: values.email, password: values.password })
            .then((response) => {
              dispatch({ type: "LOGIN_SUCCEEDED", payload: response });
            })
            .catch((error) => {
              setValues((oldValue) => ({ ...oldValue, error }));
            });
        }}
        classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}
        style={{}}
      >
        Login
      </Button>
    </div>
  );
};
