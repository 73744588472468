import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default ({ checkFields, options, handleChange, value }) => {
    return (
        <Autocomplete
            id="combo-box-demo"
            fullWidth
            style={{ marginTop: '1rem' }}
            options={options}
            value={value}
            filterOptions={(options, state) => {
                let newOptions = [];
                options.forEach((element) => {
                    if (element.fullname.replace(",", "").toLowerCase().includes(state.inputValue.toLowerCase()) || element?.phone?.replace(",", "").toLowerCase().includes(state.inputValue.toLowerCase()))
                        newOptions.push(element)
                });
                return newOptions;
            }}
            onChange={(ev, value) => handleChange(value)}
            getOptionLabel={(option) => option.fullname}
            renderInput={(params) => <TextField
                error={!checkFields || value.fullname?.length > 0 ? false : true}
                helperText={!checkFields || value.fullname?.length > 0 ? '' : 'Please Search and Select CoTrainer'}
                label={'Add Cotrainer by searching by name or phone number'} {...params} variant="outlined" />}
        />
    );
}
