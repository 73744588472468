export const activateComponent = ({ courseId, value }) => {
  return dispatch => {
    dispatch({
      type: "ACTIVATE_COMPONENT",
      payload: {
        courseId,
        target: "milestone",
        value
      }
    })
  }
};

export const activateSection = ( sectionId ) => {
  return dispatch => {
    dispatch({ type: "ACTIVATE_SECTION", payload: sectionId })
  }
};


export const toggleLanguage = () => {
  return dispatch => {
    dispatch({ type: "TOGGLE_LANGUAGE" });
  }
}