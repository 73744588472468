import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import PrepareDashboard from "../../../components/App_PrepareDashboard";
import PrepareUnits from '../../../components/Prepare_Units';

import ConductDashboard from "../../../components/App_ConductDashboard/index";
// import ContinueConduct from "../../../components/App_ConductDashboard/continueConduct";

import { useSelector } from 'react-redux';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box key={value}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#fafafa;',
    },
    active: {
        '& .Mui-selected': {
            color: '#444',
            backgroundColor: '#eeee'
        },
        '& .PrivateTabIndicator-colorSecondary-9': {
            backgroundColor: '#e4001b'
        }
    },
    tabHover: {
        '&:hover': {
            color: "#e4001b",
            opacity: '1',
            transition: '0.3s ease-in-out'
        },
    }
}));


export default function TabsWrappedLabel({ tabData }) {

    const TabPanelComponent = {
        PrepareDashboard,
        PrepareUnits,
        ConductDashboard,
        // ContinueConduct
    }
    const classes = useStyles();
    const [value, setValue] = React.useState(tabData[0].label);

    const app = useSelector(state => state.app)
    const auth = useSelector(state => state.auth)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const populateTabPanel = (component) => {
        let TabPanel = TabPanelComponent[component];
        return <TabPanel app={app} auth={auth} />
    }

    return (
        <div className={classes.root} >
            {/* <AppBar position="static" style={{ width: 'unset', margin: '0rem -2.5rem', backgroundColor: '#f2f2f2', color: '#949494', boxShadow: 'none' }}>
                <Tabs value={value} className={classes.active} onChange={handleChange} aria-label="wrapped label tabs example">
                    <Tab
                        value="one"
                        label="My Modules"
                        {...a11yProps('one')}
                        style={{ marginLeft: '3rem' }}
                        className={classes.tabHover}
                    />
                    <Tab value="two" className={classes.tabHover} style={{ whiteSpace: 'nowrap' }} label="My Orientation & Development Units" {...a11yProps('two')} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index="one">
                <PrepareDashboard />
            </TabPanel>
            <TabPanel value={value} index="two">
                <PrepareUnits />
            </TabPanel> */}
            <AppBar position="static" style={{ width: 'unset', margin: '0rem -2.5rem', backgroundColor: '#f2f2f2', color: '#949494', boxShadow: 'none' }}>
                <Tabs value={value} className={classes.active} onChange={handleChange} aria-label="wrapped label tabs example">
                    {

                        tabData.map(item => <Tab
                            key={item.label}
                            value={item.label}
                            label={item.label}
                            {...a11yProps(item.label)}
                            style={{ marginLeft: '3rem', whiteSpace: 'nowrap' }}
                            className={classes.tabHover} />)

                    }
                </Tabs>
            </AppBar>
            {
                tabData.map(item => <TabPanel key={item.component} value={value} index={item.label}>
                    {
                        populateTabPanel(item.component)
                    }
                </TabPanel>)
            }
        </div>
    );
}
