import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { NavLink } from "react-router-dom";
import LeftNav from '../LeftNav'
import './style.css'
import APModuleCard from './ModuleCard'
import Icon from '@mdi/react';
import { mdiAutorenew, } from '@mdi/js';
import HomeIcon from "./assets/home-icon.png"
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import store from '../../../Redux_Store';
import { showBlog } from "../../../utilities/common";
import { Button, CircularProgress, ClickAwayListener, Dialog } from '@material-ui/core';
import { editAccountDetails } from '../../auth/redux_actions';
import Modal from '../../../components/walkThrough/modal';
import { JoinProgramNotification, MyNotifications } from '../../../components/Notifications';
import { useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';

export default ({ app, auth, menuItems }) => {

  let { availableProgramsNew, user, walkThrough, availableTags } = app || {};
  let { currentEl, allElements, endWalkThrough, stop } = walkThrough;

  let { role, programId, joinedNewProgram, webFirstTimeUser } = user || {}
  const executeScroll = () => userNameRef.current.scrollIntoView({ behavior: 'smooth' });

  const [mSwitch, setMswitch] = useState(false);
  const [mSwitchSpin, setMSwitchSpin] = useState(false);
  const userNameRef = useRef(null)

  const handleSwitch = (index) => {
    setMswitch(!mSwitch)
    executeScroll();
  }

  let { _id } = user
  const { t } = useTranslation();
  let groups = {}
  let newAvMod = {}
  availableTags.map((program, i) => {
    let programId = program?._id || program?.programId
    let arr = []
    menuItems.map(el => {
      el.dataSet.map(ds => {
        if (ds.programs.includes(programId)) {
          arr.push(ds)
        }
      })
    })
    Object.assign(newAvMod, { [programId]: [{ dataSet: arr }] })
  });



  const availableModules = newAvMod?.[programId] || []


  function showModules(s, i, z) {
    let { title, description, id, uniqueAboutIt, estimatedTime, worksheetURL, prepare, moduleIndexText } = s
    return <APModuleCard
      moduleIndexText={moduleIndexText}
      firstModule={z == 0 && i == 0}
      data={groups}
      a={z}
      key={i}
      index={i}
      title={title}
      description={description}
      mainFilePath={HomeIcon}
      moduleId={id}
      uniqueAboutIt={uniqueAboutIt}
      user_token={auth.user_token}
      coach_id={auth.user_uid}
      auth={auth}
      app={app}
      estimatedTime={estimatedTime}
      worksheetURL={worksheetURL}
      prepare={prepare}
    />
  }

  function show1Modules(el, i) {
    let { title, description, id, uniqueAboutIt, estimatedTime, worksheetURL, prepare, moduleIndexText } = el
    return <APModuleCard
      firstModule={i == 0}
      data={groups}
      key={i}
      index={i}
      title={title}
      description={description}
      mainFilePath={HomeIcon}
      moduleId={id}
      uniqueAboutIt={uniqueAboutIt}
      user_token={auth.user_token}
      coach_id={auth.user_uid}
      auth={auth}
      app={app}
      estimatedTime={estimatedTime}
      worksheetURL={worksheetURL}
      prepare={prepare}
    />
  }

  availableModules.map(e => {
    e.dataSet.map(d => {
      return d.moduleGroupId.map(group => {
        groups[group._id] = {
          id: group._id,
          title: group.title,
          latestTitle: group?.latestTitle,
          subModules: e.dataSet.filter(
            module => module.moduleGroupId.filter(_group => _group._id == group._id).length > 0
          ).sort((a, b) => {
            return (b?.order || 0) - (a?.order || 0)
          })
        }
      })
    })

  })

  let analytics = Object.values(role || {}).some(v => v.dashboard.length > 0)

  let handleProgramSwitch = (pId) => {
    let userId = _id
    setMswitch(false);
    setMSwitchSpin(true)
    setTimeout(() => {
      setMSwitchSpin(false)
    }, 500)
    store.dispatch({ type: "SWITCH_PROGRAM", payload: pId })
    editAccountDetails(userId, { programId: pId })
  }



  useEffect(() => {
    let _arr = allElements || []
    if (allElements && allElements.length) {
      if (showBlog && !allElements.includes('showBlog')) { _arr.splice(6, 0, 'showBlog') }
      if (analytics && !allElements.includes('analytics')) { _arr.splice(7, 0, "analytics") }
      if (_arr.length !== allElements.length) {
        if (availableModules?.[0]?.dataSet.length > 0) {
          store.dispatch({
            type: "CREATE_WALKTHROUGH", payload: {
              currentEl: 0,
              confirmExit: false,
              stop: false,
              allElements: _arr,
              endWalkThrough: false
            }
          })
        } else {
          store.dispatch({ type: 'NOTIFICATIONS', payload: { key: 'student', value: false } })
          store.dispatch({
            type: "CREATE_WALKTHROUGH", payload: {
              currentEl: 0, confirmExit: false, stop: false, allElements: _arr, endWalkThrough: true
            }
          })
        }
      }
    }
  }, [walkThrough, showBlog, analytics, webFirstTimeUser, programId])


  if (allElements && allElements[currentEl])
    return <>
      <div className={"dashboard-cntr"}>
        <LeftNav language={i18n.language} />
        <div className='d-flex w-100 h-100'>
          <div className={`h-100
    ${endWalkThrough && !joinedNewProgram && availableModules?.[0]?.dataSet?.length > 0 && "ap-program-leftImg-container"}
     tct-scrollable-darkgray`}  >
            <div className='d-flex flex-column h-100 w-100'
              style={{ padding: '1%', minWidth: '24vw', maxWidth: '24vw' }}>
              <div className='ir-left-panel-container'>
                <div >
                  <div style={{ fontSize: '1.125rem' }} ref={userNameRef} >
                    {t('WELCOME_SHORT', { name: auth.user.fullname })}
                  </div>
                  <div className='ir-program-name'>
                    {availableProgramsNew?.[programId]?.programName || ''}
                  </div>
                  <div>
                    <div
                      className={`ir-role-text ${!stop && allElements[currentEl] == 'walkRoleDiv' ? 'zIndexPos' : ''} `}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', textAlign: 'left' }}>
                      {!stop && allElements[currentEl] == 'walkRoleDiv' && <Modal id={'walkRoleDiv'} Comp={false} />}
                      {availableProgramsNew?.[programId]?.tagName}
                      {Object.keys(newAvMod || {}).length > 1 &&
                        <div style={{ display: 'flex', alignItems: 'center' }} onClick={handleSwitch}>
                          <Icon path={mdiAutorenew}
                            size={1}
                            color={'#BA1F00'}
                            style={{ verticalAlign: "middle" }}
                          />
                          <div className='ir-choose-role-text'>switch role</div>
                        </div>
                      }
                    </div>
                  </div>
                  {mSwitch &&
                    <ClickAwayListener onClickAway={() => { setMswitch(!mSwitch) }}>
                      <div className='scrollDiv' style={{
                        display: 'flex', flexDirection: 'column', height: "100%",
                        fontSize: '1.25rem', fontWeight: 500, color: '#454545',
                        gap: '16px', height: 'max-content', marginTop: '10px', padding: '10px 0px',
                        background: '#F3F3F3',
                        border: ' 1px solid #000000',
                        borderRadius: '8px', position: 'absolute', overflowY: 'auto', maxHeight: '55vh'
                      }}>
                        {availableTags.length > 1 && availableTags
                          .map((program, i) => {
                            let pId = program?._id || program?.programId
                            return <div
                              key={program?._id || program?.programId}
                              className='prgName'
                              onClick={() => { handleProgramSwitch(pId) }}  >
                              {program?.tagName}
                            </div>
                          })}
                      </div>
                    </ClickAwayListener>}
                </div>
                <div className='text-center'>
                  {/* {rel_Modules?.programIcon && <img src={rel_Modules.programIcon} style={{ maxWidth: '65%' }} alt='program-img' />} */}
                </div>
              </div>


            </div>
          </div>

          {!mSwitchSpin ?
            <div className='w-75 h-100 tct-scrollable-darkgray overFlowYAuto' style={{ padding: '0% 7%', backgroundColor: '#F2F2F2' }}>
              {Object.keys(groups).length > 0 ?
                Object.values(groups).map((g, z) => {
                  return <>
                    <div className='ir-program-count-container' key={`groups-${z}`}>
                      <div className='ir-program-count' style={{ marginLeft: '10px' }}>
                        {g?.latestTitle ? g?.latestTitle : `Module ${z + 1} : ${g.title} (${g.subModules.length}) `}
                      </div>
                    </div>
                    <div style={{ margin: '10px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '25px 50px' }}>
                      {g.subModules.map((s, i) => showModules(s, i, z))}
                    </div>
                  </>
                }
                )
                :
                <div>
                  <div className='ir-program-count-container'>
                    <div className='ir-program-count' style={{ marginLeft: '10px' }}>
                      {`${availableModules?.[0]?.allModuleHeading || "Program Modules"}  (${availableModules?.[0]?.dataSet?.length || 'Fetching'})`}
                    </div>
                  </div>
                  <div style={{ margin: '10px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '25px 50px' }}>
                    {(availableModules?.[0]?.dataSet || [])
                      .map((el, i) => show1Modules(el, i))}
                  </div>
                </div>
              }
              <div className='ir-dashboard-copyright'>
                Copyright 2022; Illumine. All rights reserved.
              </div>
            </div>
            :
            // can we move it here
            <CircularProgress
              style={{ position: 'absolute', top: "50%", right: '33%' }}
              classes={{ root: 'circular-progress-color' }} size={70} />
          }
        </div>

        <Dialog open={user?.freezeAccount || false}>
          <div style={{ fontSize: "1.3rem", fontWeight: 500, display: 'flex', flexDirection: 'column', padding: '30px', paddingBottom: "10px", alignItems: 'center', gap: '1rem' }}>
            <div>
              The program term is completed, and access is revoked. Get in touch with your authorities. For any clarifications, please contact Illumine Support Team.
            </div>
            <NavLink to="/logout">
              <Button>
                Close
              </Button>
            </NavLink>
          </div>
        </Dialog>
      </div>
    </>
  else
    return <CircularProgress
      style={{ position: 'absolute', top: "50%", right: '50%' }}
      classes={{ root: 'circular-progress-color' }} size={70} />
}


