import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LeftNav from "../../../components/LeftNav";
// import ConductDashboard from "../../../components/App_ConductDashboard";
import TabViewConduct from '../TabView';

import Dashboard_Header from "../../../components/Dashboard_Header";

import "./conductDashboard.css";

class conductDashboard extends Component {
  render() {
    return (
      <div className={"dashboard-cntr"}>
        <LeftNav />
        <div className="with-left-nav wrapper prepare-dashboard">
          <Dashboard_Header
            heading='Conduct Zone'
            subheading='Which module do you want to conduct today?'
            description='Continuously improve the quality of the learning & coaching delivery experience of a module. Take up any module to prepare how to conduct, improve class engagement, deepen appreciation of the concepts & ideas.'
          />
          <TabViewConduct
            tabData={[
              { label: "Conduct", component: "ConductDashboard" },
              // { label: "Continue Conduct", component: "ContinueConduct" }
            ]}
          />
        </div>
      </div >
    );
  }
}
conductDashboard.propTypes = {
  authMsg: PropTypes.string
};

const mapStateToProps = state => ({ auth: state.auth });

export default connect(mapStateToProps, null)(conductDashboard);