import React from 'react';
import './style.css';
import EventModal from "./modal";

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let headingSize = '14px';
    // let subtTitleSize = '12px';
    if (isFullScreen) {
        headingSize = '18px';
        // subtTitleSize = '18px'
    }

    return (
        <div style={{ width, height, position: 'relative' }}>
            <div style={{ width: '100%', height: '100%', padding: '5% 2.5%', display: 'flex' }}>
                <div style={{ width: '65%', height: '100%' }}>
                    <img src={data.leftImg} style={{ width: '100%', height: '100%' }} />
                </div>
                <div style={{ width: '35%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingLeft: '1%' }}>
                    <div style={{ width: '100%', height: '13%', fontSize: headingSize }}>
                        {data.heading}
                    </div>
                    <div style={{ width: '100%', height: '35%', margin: '5% 0%' }}>
                        {/* <img src={data.rightImg_1} style={{ width: '100%', height: '100%' }} /> */}
                        <EventModal imgUrl={data.rightImg_1} vidUrl={data.rightVideo_1} poster={data.rightVideoPoster_1} width={width} height={height} />
                    </div>
                    <div style={{ width: '100%', height: '35%' }}>
                        {/* <img src={data.rightImg_2} style={{ width: '100%', height: '100%' }} /> */}
                        <EventModal imgUrl={data.rightImg_2} vidUrl={data.rightVideo_2} poster={data.rightVideoPoster_2} width={width} height={height} />
                    </div>
                </div>
            </div>
        </div>
    );
}