import React from 'react';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let textSize = '12px'
    if (isFullScreen)
        textSize = '16px'

    const addStarTextBox = (type) => {

        let temp = engagementData[data.id] ? engagementData[data.id] : [];
        // let temp = [];
        temp.push({ type, textBoxData: '' })
        console.log(temp)
        manageToolData({
            toolId: data.id,
            content: temp
        });

    }

    const starTextBoxChange = (event, index) => {
        let temp = engagementData[data.id];
        temp[index].textBoxData = event.target.value;
        manageToolData({
            toolId: data.id, content: temp
        })
    }



    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className='d-flex sma-design-ex-situation-v2-starButton-container'>
                    {
                        Object.keys(data.starImg).map(starName => <div key={starName + '-button'} style={{ width: '14%' }}>
                            <img src={data.starImg[starName].buttonImg}
                                className='w-100 h-100 button-click-effect'
                                onClick={() => addStarTextBox(starName)}
                                alt='star-button-img'
                            />
                        </div>)
                    }
                </div>
                <div className='d-flex flex-wrap sma-design-ex-situation-v2-starTextBox-container overFlowYAuto tct-scrollable-darkgray'>
                    {
                        engagementData && engagementData[data.id] && engagementData[data.id].map((star, index) => <div key={star + '-textbox-' + index} className='sma-design-ex-situation-v2-starTextBox' style={{ backgroundColor: data.starImg[star.type].bgColor }}>
                            <div className='w-100 h-30 flex-vertical-center'>
                                <img src={data.starImg[star.type].starIcon} className=' w-15' style={{ padding: '2%' }} alt='star' />
                                <div className='tablet-gothic' style={{ fontSize: textSize, color: data.starImg[star.type].fontColor }}>
                                    {star.type}
                                </div>
                            </div>

                            <textarea placeholder='Fill more…'
                                className={'transparent-textarea tct-scrollable-darkgray tablet-gothic flex-vertical-center textArea-OnHover-To-Scroll w-100 h-70'}
                                style={{ fontSize: textSize }}
                                value={star.textBoxData}
                                onChange={(event) => starTextBoxChange(event, index)}
                            />

                        </div>)
                    }
                </div>
            </div>
        </div >
    );
}