import React, { useState, useEffect } from "react";
import store from "../../../Redux_Store";
import { loginWithToken } from "../../auth/redux_actions";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";

export default function AutoLogin(props) {
  const [redirect, setRedirect] = useState('');
  let auto_login_token = props.match.params.token;
  let { isLoggedIn } = useSelector((state) => state.auth);

  const handleAutoLogin = () => {
    store.dispatch({ type: "LOGIN_ATTEMPT" });
    loginWithToken({ auto_login_token })
      .then((res) => {
        if (res?.code) {
          store.dispatch({
            type: "USER_VALUE_CHANGE",
            payload: { key: "error", value: "Please check and re-enter OTP" },
          });
        }
        console.log("res", res)
        store.dispatch({ type: "LOGIN_WITH_OTP", payload: res });
        // store.dispatch({ type: "GET_MY_DETAIL", payload: res.user })
        store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'otpTimer', value: 0 } });
        store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'otp', value: 0 } });
        setRedirect("/application");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => { if (auto_login_token) handleAutoLogin(); }, [auto_login_token]);
  return <>{isLoggedIn && redirect ? <Redirect to={redirect} /> : <CircularProgress />}</>

}
