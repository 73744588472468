import { FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from "@material-ui/core"
import React from "react"


export default ({ label, options, fullWidth, setBatch, value }) => {

    const useStyles = makeStyles((theme) => ({
        outlinedSelect: {
            boxShadow: '0px 3px 6px #00000021',
            // height: '4.5vh',
            // fontSize: '1.2em',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            paddingRight: '56px !important'
        },
        outlinedSelectIcon: {
            // width: '2em',
            // height: "2em",
            top: 'unset'
        }
    }));

    const classes = useStyles();

    return <FormControl variant="outlined"
        style={{ minWidth: "65%" }}
        fullWidth={fullWidth}>
        {label ? <InputLabel id="ir-select-batch-label" style={{ fontSize: '1em', fontWeight: 'bold' }}>
            {label}
        </InputLabel> : null}

        <Select
            labelId="ir-select-batch-label"
            id="ir-select-batch-label-outlined"
            // value={dropDownSelectedBatch}
            label={label}
            // classes={{
            //     outlined: classes.outlinedSelect,
            //     icon: classes.outlinedSelectIcon
            // }}
            onChange={(event) => { setBatch(event.target.value) }}
            value={value}
            style={{ minWidth: "30%" }}
        >
            {options.map((opt) => <MenuItem
                // style={{ fontWeight: 'bold', fontSize: '1.25em' }}
                classes={{ root: 'ir-tisa-font' }}
                value={opt.value}
            >
                {opt.label}
            </MenuItem>
            )}
        </Select>
    </FormControl>
}