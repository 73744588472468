import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let descriptionSize = '14px';

    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '21px';
    }
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className='COD_C_and_C_Response_textBox' style={{ fontSize: descriptionSize, right: '50%' }}>
                    <TextEditor id={data.textBoxId[0]} initialData={engagementData[data.textBoxId[0]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...'
                        scrollable='tct-scrollable-darkgray'
                        textEditorClass='UPTC_CS_Square-textEditorClass'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                    />
                </div>
                <div className='COD_C_and_C_Response_textBox' style={{ fontSize: descriptionSize, right: '8.5%' }}>
                    <TextEditor id={data.textBoxId[1]} initialData={engagementData[data.textBoxId[1]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...'
                        scrollable='tct-scrollable-darkgray'
                        textEditorClass='UPTC_CS_Square-textEditorClass'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                    />
                </div>
            </div>
        </div>
    );
}