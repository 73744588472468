import React, { Component, useEffect, useState } from 'react'
import { createNewBatch, createSession, getAllSessionsByBatch, getBatchForCoachAndModule, getUser, updateBatch } from '../../application/redux_actions'
import moment from 'moment'
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ComboBox from './customCoTrainer';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Button, TextField, InputLabel, MenuItem, FormControl, Select, makeStyles, CircularProgress, FormHelperText } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactTooltip from 'react-tooltip';
import { v4 } from 'uuid';
import ConditonalFilter from '../../singleAuth/Auth/ConditonalFilter';
import store from '../../../Redux_Store';
import { Redirect } from "react-router";


const useStyles = makeStyles((theme) => ({
    root: {},
    paper: {
        width: '45%',
        height: '96%',
        borderRadius: '24px',
        overflow: 'hidden'
    },
    textFieldClass: {
        fontSize: '14px',
    },
    buttonLabel: {
        fontFamily: 'tablet-gothic !important'
    },
    buttonRoot: {
        '&:hover': {
            backgroundColor: '#e4001b !important',
            color: 'white',
        }
    },
    select: {
        "& .Muipaper-root": {
            color: 'red'
        },
        "& ul": {
            width: '37vw !important',
        },
        "& li": {
            textOverflow: ' clip',
            overflow: 'hidden',
            whiteSpace: 'inherit'
        },
    },
    selectBorder: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#f1f1f1',
        overflow: 'hidden',
        whiteSpace: 'normal',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important',
        },
        "&:hover .MuiOutlinedInput-root": {
            borderColor: 'black'
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: 'black'
        }
    },
    inputEmpty: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#FFFFFF',
        overflow: 'hidden',
        whiteSpace: 'normal',
        "&.MuiFormHelperText-root.Mui-error": {
            color: 'green !important',
        },
    },
    inputFilled: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#f1f1f1',
        overflow: 'hidden',
        whiteSpace: 'normal',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important',
        },
        "&:hover .MuiOutlinedInput-root": {
            borderColor: 'black'
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: 'black'
        },
        "&.MuiFormHelperText-root.Mui-error": {
            color: 'pink !important',
        },
    },
    selectFilled: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#f1f1f1',
        overflow: 'hidden',
        whiteSpace: 'normal',
    },
    newOutlinedSelect: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#f1f1f1',
        overflow: 'hidden',
        whiteSpace: 'normal',
    },
    outlinedSelectIcon: {
        width: '2em',
        height: "2em",
        top: 'unset'
    },
    batchInputRoot: {}
}));

const HoverInfo = ({ Component, text, dataFor }) => {
    return <>
        <a data-tip data-for={dataFor}>
            <Component />
        </a>

        <ReactTooltip id={dataFor} aria-haspopup="true" role="example">
            <span>{text}</span>
        </ReactTooltip>
    </>
}

const BatchDropDown = ({ moduleDetails, label, batchTrainer, sessionsForBatch, selectedBatch, setBatchDetails, setConfigType, onlyToCreateBatch }) => {
    const classes = useStyles();
    let latestSessionForBatch = Object.values(sessionsForBatch || {}).sort((a, b) => new Date(b['sessionName']) - new Date(a['sessionName']))[0] || Object.values(sessionsForBatch || {}).slice(-1)[0]
    let { moduleId, sessionDate, updatedAt } = latestSessionForBatch || latestSessionForBatch?.resume || {}
    let { meta } = batchTrainer?.[selectedBatch] || {}
    let description = moduleId?.description || moduleDetails?.[moduleId]?.description || ''
    let options = Object.values(batchTrainer || {}).map((batch) => batch?.batchName).reverse() || []
    let batchName = batchTrainer && selectedBatch && batchTrainer[selectedBatch] && batchTrainer[selectedBatch].batchName

    // console.log("batchName",  batchTrainer?.[selectedBatch]?.batchName)

    const handleChange = (event) => {
        Object.values(batchTrainer || {}).find(batch => {
            if (batch.batchName === event.target.value)
                setBatchDetails((old) => ({
                    ...old,
                    selectedBatch: batch._id
                }))
        })
    };

    return <>
        {batchName ?
            <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 8, fontSize: 14, background: '#F8F8F8', }}>
                <FormControl variant="outlined" style={{}} fullWidth >
                    <InputLabel id="ir-select-batch-label" >{label}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={label}
                        value={batchName}
                        displayEmpty
                        classes={{ outlined: classes.newOutlinedSelect, }}
                        onChange={handleChange}
                    >
                        {options.map(opt => <MenuItem
                            style={{ fontSize: '1rem', fontWeight: 400, color: '#49454F' }}
                            classes={{ root: 'ir-tisa-font' }} key={v4()} value={opt}>{opt}</MenuItem>)
                            // classes={{ root: 'ir-tisa-font' }} key={v4()} value={opt}>{opt?.split('_')[1].length > 0 || false ? opt?.split('_')[1] : opt}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px 16px' }}>
                    {description ?
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                <span style={{ color: '#545454' }}>Previous session for above batch</span>
                                <span style={{ color: '#8A8A8A ' }}>{description}</span>
                            </div>
                            <div style={{ display: 'flex', width: '60%', gap: 10, justifyContent: 'space-evenly', color: '#8A8A8A ' }}>
                                <span className='helperIcons'><DateRangeIcon />{moment(sessionDate).format('DD/MM/YYYY') || ''}</span>
                                <span className='helperIcons'><ScheduleIcon />{moment(updatedAt).format('h:mma') || ''}</span>
                            </div>

                        </> :
                        <CircularProgress style={{ position: "relative", left: '50%' }} classes={{ root: 'circular-progress-color ' }} size={20} />
                    }
                </div>
            </div>
            : null}


    </>
}

const CoTrainerDropDown = ({ batchDetails, checkFields, value, red_coTrainersForBatch, handleChange }) => {
    let options = []
    red_coTrainersForBatch.reduce((result, item, i) => {
        if (item?.id !== batchDetails.coachId)
            if (item?.meta?.delhi_police?.policestation !== 'Guest')
                options.push(result[item?.fullname] = item)
        return result;
    }, {});
    return <ComboBox
        checkFields={checkFields}
        options={options.length > 0 ? options : [{ fullname: 'Conducting Session Alone', id: 1, phone: '1' }]}
        value={value}
        handleChange={handleChange}
    />

}

const TextFieldEntry = ({ label, checkFields, batchDetails, setBatchDetails, batchName }) => {
    const classes = useStyles();
    // let value = batchDetails?.newBatch?.length == 0 ? batchDetails?.newBatch : batchDetails?.newBatch || batchName?.split('_')[1]
    let value = batchDetails?.newBatch?.length == 0 ? batchDetails?.newBatch : batchDetails?.newBatch || batchName
    let chk = !checkFields || value
    return <TextField
        error={chk ? false : true}
        helperText={chk ? '' : `Please Enter ${label}`}
        fullWidth
        label={label}
        variant="outlined"
        InputLabelProps={{ classes: { root: classes.textFieldClass } }}
        InputProps={{
            classes: { root: (value) ? classes.inputFilled : classes.inputEmpty, }
        }}
        value={value}
        onChange={(event) => {
            setBatchDetails((old) => ({
                ...old,
                newBatch: event.target.value
            }))
        }}
    />
}



const MetaSelection = ({ field, key, batchMeta, checkFields, handleChange, setBatchMeta, batchDetails, batchTrainer, edit }) => {
    const classes = useStyles();
    let { fieldType, values, selectedValue, metaKey, fieldName, type } = field || {}
    let selectedBatch = batchDetails?.selectedBatch
    let { meta } = batchTrainer?.[selectedBatch] || {}

    let value = batchMeta?.[fieldName]
    let chk = !checkFields || (edit ? (value?.length == 0 ? value : value || meta?.[fieldName]) : value)

    if (fieldType == 'dropdown') {
        return (
            <FormControl key={metaKey || v4()} variant="outlined" fullWidth error={chk ? false : true}>
                <InputLabel id="ir-select-batch-label" style={{ fontSize: '14px' }}>{fieldName}</InputLabel>
                <Select
                    key={v4()}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ cursor: selectedValue ? "no-drop" : 'pointer' }}
                    label={fieldName}
                    MenuProps={{ classes: { paper: classes.select } }}
                    className={value ? classes.selectBorder : ''}
                    value={value}
                    defaultValue={edit ? meta?.[fieldName] : ''}
                    displayEmpty
                    onChange={(ev) => {
                        handleChange(ev.target.value, metaKey, fieldName, meta, edit)
                        ev.stopPropagation();
                        ev.preventDefault();
                    }}
                >
                    {values?.map(opt =>
                        <MenuItem
                            style={{ fontSize: '1rem', fontWeight: 400, color: '#49454F' }}
                            key={opt} value={opt}>
                            {opt}
                        </MenuItem>
                    )}
                </Select>
                <FormHelperText>{chk ? "" : `Please Select ${fieldName}`}</FormHelperText>
            </FormControl >)
    }
    if (fieldType == 'textfield') {

        return (<TextField
            key={key}
            type={type ?? ''}
            error={chk ? false : true}
            helperText={chk ? ' ' : `Please Enter ${fieldName}`}
            style={{ cursor: selectedValue ? "no-drop" : 'pointer' }}
            fullWidth
            InputLabelProps={{ classes: { root: classes.textFieldClass } }}
            InputProps={{
                classes: { root: (value) ? classes.inputFilled : classes.inputEmpty, },
            }}
            label={`${fieldName}`
            }
            variant="outlined"
            defaultValue={edit ? meta?.[fieldName] : ''}
            value={value}
            onChange={(ev) => {
                handleChange(ev.target.value, metaKey, fieldName, meta, edit)
                ev.stopPropagation();
                ev.preventDefault();
            }}
        />)
    }
    if (fieldType == 'conditional_filter') {
        return <ConditonalFilter
            batch={true}
            field={field}
            checkFields={checkFields}
            // values={}
            onChange={() => { }}
            forBatch={(key, value) => setBatchMeta((old) => ({ ...old, [key]: value }))}
        />
    }
    else
        return null
}
const BatchScene = ({ values, fields, configType }) => {
    let { app, sessionsForBatch, batchTrainer, moduleDetails, red_coTrainersForBatch, BottomButton, user_token, moduleId, coachId, coTrainer, batchMeta, programId, setBatchTrainer, setBatchMeta, selectedBatch, batchDetails, setBatchDetails, setConfigType, handleChange, checkFields, setRedirect
    } = values || {}
    let d_date = moment().format('DD/MM/YYYY')
    let newBatch = batchDetails?.newBatch

    const [showMore, setShowMore] = useState(false)

    function skeleton(edit) {
        return <>
            <>
                <div style={{ display: 'flex', width: "100%", gap: '1.5rem', justifyContent: 'space-between' }}>
                    <TextFieldEntry
                        checkFields={checkFields}
                        label={'Batch Name'}
                        batchDetails={batchDetails}
                        batchName={edit ? batchTrainer[selectedBatch]?.batchName : ''}
                        setBatchDetails={setBatchDetails}
                    />
                    <MetaSelection
                        handleChange={handleChange}
                        checkFields={checkFields}
                        field={{
                            "fieldName": "No of Participants",
                            "fieldType": "textfield",
                            "metaKey": "totalStudents",
                            "type": "number",
                            "mandatory": true,
                            "values": [
                                ""
                            ]
                        }}
                        key={"No_ofP"}
                        edit={edit}
                        batchDetails={batchDetails}
                        batchTrainer={batchTrainer}
                        batchMeta={batchMeta}
                        setBatchTrainer={setBatchTrainer}
                        setBatchMeta={setBatchMeta}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem', }}>
                    {fields && fields.length > 0 && (fields || [])
                        .filter((field) => !edit
                            ? true : !fields.some(field => field?.priority)
                                ? true : showMore ?
                                    true : field?.priority)
                        .map((field, i) => {
                            return (<MetaSelection
                                checkFields={checkFields}
                                key={i}
                                handleChange={handleChange}
                                field={field}
                                batchDetails={batchDetails}
                                batchTrainer={batchTrainer}
                                batchMeta={batchMeta}
                                setBatchTrainer={setBatchTrainer}
                                setBatchMeta={setBatchMeta}
                                edit={edit}
                            />)
                        })
                    }
                </div>
                {edit && fields?.some(field => field?.priority) && !showMore && <div style={{ cursor: 'pointer', display: "flex", justifyContent: 'flex-end', alignItems: 'center', fontSize: 12 }} onClick={() => setShowMore(true)}> Show More....<ExpandMoreIcon /></div>}
            </>
            <CoTrainerDropDown
                checkFields={checkFields}
                label={"Co-Trainer's Name"}
                red_coTrainersForBatch={red_coTrainersForBatch}
                batchDetails={batchDetails}
                value={{ 'fullname': coTrainer.name || '' }}
                handleChange={(value) =>
                    setBatchDetails((old) => ({
                        ...old,
                        coTrainer: {
                            ...old.coTrainer,
                            id: value?.id === 1 || value?._id === 1 ? batchDetails?.coachId : value?.id,
                            name: value?.fullname,
                        }
                    }))
                }
            />
        </>
    }

    if (configType == "ACCESSPREVIOUSBATCHES" || configType == 'ACCESSPREVIOUSBATCHESONLY') {
        return <>
            <BatchDropDown
                onlyToCreateBatch={configType == 'ACCESSPREVIOUSBATCHES' ? true : false}
                setConfigType={setConfigType}
                app={app}
                label={'Batch Name'}
                sessionsForBatch={sessionsForBatch}
                batchTrainer={batchTrainer}
                moduleDetails={moduleDetails || {}}
                selectedBatch={selectedBatch}
                setBatchDetails={setBatchDetails}
            />
            <CoTrainerDropDown
                checkFields={checkFields}
                label={"Co-Trainer's Name"}
                red_coTrainersForBatch={red_coTrainersForBatch}
                batchDetails={batchDetails}
                value={{ 'fullname': coTrainer.name || '' }}
                handleChange={(value) =>
                    setBatchDetails((old) => ({
                        ...old,
                        coTrainer: {
                            ...old.coTrainer,
                            id: value?.id === 1 || value?._id === 1 ? batchDetails?.coachId : value?.id,
                            name: value?.fullname,
                        }
                    }))
                }
            />
            <BottomButton
                text={'Conduct'}
                configType={configType}
                onClickFunction={async () => {
                    // console.log("batchTrainer?.[selectedBatch]", batchTrainer?.[selectedBatch], [...new Set([coachId, coTrainer.id])])
                    await createSession({
                        user_token, moduleId, coach_id: coachId,
                        facilitators: [...new Set([coachId, coTrainer.id])],
                        sessionType: 'pre',
                        sessionName: new Date(),
                        batchId: selectedBatch,
                        meta: { ...batchTrainer?.[selectedBatch]?.meta, programId } || {}
                    }, () => { }).then(res => {
                        if (res && res.moduleId && res) {
                            setRedirect({ status: true, to: `application/conduct/${res.id}?moduleId=${moduleId}` })
                        }
                    })
                }}
            />
            {configType === 'ACCESSPREVIOUSBATCHES' && <>
                <BottomButton
                    text={'Continue'}
                    configType={true}
                    onClickFunction={async () => {
                        setConfigType("EDITBATCH")
                    }}
                />
                <span style={{ textAlign: 'center', fontSize: 12 }}>or</span>
                <BottomButton
                    configType={true}
                    text={'Create new Batch'}
                    onClickFunction={async () => {
                        setConfigType('CREATEBATCH')
                    }}
                />

            </>}
        </>
    }
    if (configType == "CREATEBATCH" || configType == "EDITBATCH") {
        let edit = configType == "CREATEBATCH" ? false : true
        return <>
            {skeleton(edit)}
            <BottomButton
                configType={configType}
                text={edit ? 'Conduct' : 'Create Batch'}
                onClickFunction={async () => {
                    if (!edit) {
                        await createNewBatch(user_token, {
                            batchName: `${d_date}_${newBatch}`,
                            coachId: [...new Set([coachId, coTrainer.id])],
                            meta: batchMeta,
                            startingDate: new Date()
                        }).then(async (res) => {
                            await createSession({
                                user_token, moduleId, coach_id: coachId,
                                facilitators: [...new Set([coachId, coTrainer.id])],
                                sessionType: 'pre',
                                sessionName: new Date(),
                                batchId: res._id || res.id,
                                meta: { ...batchMeta, programId } || {}
                            }, () => { })
                                .then(res => {
                                    if (res && res.moduleId && res) {
                                        setRedirect({ status: true, to: `application/conduct/${res.id}?moduleId=${moduleId}` })
                                    }
                                })
                        }).catch((err) => console.log(err))
                    }
                    if (edit) {
                        await updateBatch(user_token, selectedBatch, {
                            batchName: batchTrainer?.[selectedBatch]?.batchName,
                            coachId: [...new Set([coachId, coTrainer.id])],
                            meta: batchTrainer?.[selectedBatch]?.meta,
                        }).then(async res =>
                            await createSession({
                                user_token, moduleId, coach_id: coachId,
                                facilitators: [...new Set([coachId, coTrainer.id])],
                                sessionType: 'pre',
                                sessionName: new Date(),
                                batchId: res._id || res.id,
                                meta: { ...batchTrainer?.[selectedBatch]?.meta, programId } || {}
                            }, () => { }).then(res => {
                                if (res && res.moduleId && res) {
                                    setRedirect({ status: true, to: `application/conduct/${res.id}?moduleId=${moduleId}` })
                                }
                            })
                        )
                    }
                }
                }
            />
        </>
    }
    if (configType == 'CREATEONLYON1ST') {
        return <div>Batch can be created only on Module 1.</div>
    }
    else return null

}
const BatchCreation = ({ app, firstModule, coach_id, handleCloseInfo, moduleId, user_token, }) => {
    let { user, moduleDetails, availableProgramsNew, sessionsForBatch } = app || {}
    let red_coTrainersForBatch = app.coTrainersForBatch || {}
    let { programId, organizationid } = user || {}
    let program = availableProgramsNew[programId] || {}
    const [batchTrainer, setBatchTrainer] = useState({})
    let [batchDetails, setBatchDetails] = useState({
        enable: false,
        selectedBatch: null, coTrainer: '', coachId: coach_id, newBatchId: null
    })

    const [checkFields, setCheckFields] = useState(false)
    const [redirect, setRedirect] = useState({ status: false, to: '' })
    const [loading, setLoading] = useState(false)

    const { enable, selectedBatch, coTrainer, coachId, newBatchId } = batchDetails
    const { batchCreationFields, trainerAccessCodes, coTrainerAccessCode } = program || {}
    const [batchMeta, setBatchMeta] = useState({ programId })
    const [configType, setConfigType] = useState(batchCreationFields?.configType)

    let popUpHTml = '<ul><li>Ensure the session is conducted in <strong>full screen</strong> mode.</li><li>Ensure participants have <strong>installed the app</strong> and are ready.</li><li>Ensure <strong>all slides</strong> are covered in the session.</li><li>Ensure <strong>all videos</strong> have been played.</li><li>Ensure all participants <strong>complete the assessment</strong> after the session</li><li>Ensure all participants have given <strong>feedback/ testimonials.</strong></li></ul>'

    useEffect(() => {
        let a = async (batchId) => await getAllSessionsByBatch({ user_token, batchId })
        if (selectedBatch && user_token) a(selectedBatch)
    }, [selectedBatch, user_token])

    useEffect(() => {
        !Object.keys(batchTrainer || {}).length > 0 &&
            coach_id && user_token &&
            getBatchForCoachAndModule({ user_token, coachId: coach_id, moduleId })
                .then((res) => {
                    if (res.length > 0) {
                        let newRes = res.filter(batch => batch?.meta?.programId === programId)
                        newRes.forEach((batch, i) => {
                            setBatchTrainer((old) => ({
                                ...old,
                                [batch._id]: batch.coachId.includes(coach_id) ? batch : {},
                            }))
                        })
                        let selected = newRes.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))

                        selected?.length > 0 && setBatchDetails((old) => ({ ...old, selectedBatch: selected[0]._id || null }))
                    }
                })

    }, [coach_id, user_token])

    useEffect(() => {
        let a = async () => await getUser(user_token, organizationid, { coTrainerAccessCode })
        // console.log(coTrainerAccessCode && user_token && organizationid && Object.keys(red_coTrainersForBatch || {}).length == 0)
        if (coTrainerAccessCode && user_token && organizationid && Object.keys(red_coTrainersForBatch || {}).length == 0) {
            a()
        }
    }, [coTrainerAccessCode, user_token, organizationid])

    const BottomButton = ({ text, onClickFunction, configType }) => {
        function validator(configType) {
            let fields = batchCreationFields?.fields || []
            if (configType == "ACCESSPREVIOUSBATCHES" || configType == 'ACCESSPREVIOUSBATCHESONLY') {
                return selectedBatch && coTrainer && coTrainer.id
            }
            if (configType == "CREATEBATCH") {
                let a = fields.length > 0 ?
                    fields.some(el => el.fieldType == 'conditional_filter') ? fields[0]._schema.length + fields.length - 1 : fields.length : false
                return Object.keys(batchMeta || {}).length - 2 == a && batchDetails?.newBatch?.length > 0 && coTrainer.id

            }
            if (configType == "EDITBATCH") {
                let a = fields.length > 0 ?
                    fields.some(el => el.fieldType == 'conditional_filter') ?
                        fields[0]._schema.length + fields.length - 1
                        : fields.length : false
                return coTrainer.id
            }
            if (configType === true) {
                return true
            }
            else return false
        }
        return <Button
            className='conduct-button-dialog'
            onClick={(ev) => {
                if (ev && ev.detail == 1 && validator(configType)) {
                    !(configType === true) && setRedirect({ status: 'loading' })
                    return onClickFunction()
                }
                else { setCheckFields(true) }
                ev.preventDefault()
            }}
            style={validator(configType) ? { color: 'black', margin: "1rem 0rem" } : { color: 'grey', margin: "1rem 0rem" }}
        >
            {text}
        </Button >
    }

    let handleChange = (e, metaKey, fieldName, meta, edit) => {
        if (!edit) setBatchMeta((old) => ({ ...old, [fieldName]: e }))
        else {
            setBatchMeta((old => ({
                ...old,
                ...meta, [fieldName]: e
            })))

        }
    }

    let title = {
        "ACCESSPREVIOUSBATCHES": 'Conducting a Session',
        "CREATEBATCH": 'Creating a Batch',
        "EDITBATCH": 'Edit Batch Details'
    }

    function config(configType) {
        if (!configType) {
            return Object.keys(batchTrainer || {}).length == 0
                ? firstModule ? "CREATEBATCH" : 'CREATEONLYON1ST'
                : firstModule ? "CREATEBATCH" : "ACCESSPREVIOUSBATCHESONLY"
        }
        if (configType) {
            return Object.keys(batchTrainer || {}).length > 0 ? configType : "CREATEBATCH"
        }
        else return null
    }

    return <>
        <div
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 2.5rem', fontSize: "20px", }}
            className='ap-conduct-dialog-close-session-button'>

            {!app?.isLoading && !(redirect.status === 'loading') && <div className='titleCss' style={{ display: 'flex', alignItems: 'center' }}>
                {configType !== batchCreationFields?.configType && <ArrowBackIosIcon
                    onClick={() => setConfigType(batchCreationFields?.configType)}
                />}
                {title[configType] ?? 'Conducting Session'}
            </div>}

            {!app?.isLoading && !(redirect.status === 'loading') && < Icon onClick={() => {
                handleCloseInfo()
                store.dispatch({ type: 'EMPTY_COTRAINERS' })
            }} path={mdiClose}
                size={1}
                color='black' />
            }
        </div>
        <div className="scrollDiv" style={{ height: "max-content", maxHeight: '70vh', overflowY: 'scroll' }}>
            {app.isLoading || (redirect.status === 'loading') ?
                <div className='w-100 h-100 flex-center'>
                    <CircularProgress classes={{ root: 'circular-progress-color my-1' }} size={50} />
                </div> :
                <div style={{
                    overflowY: "auto", height: '100%',
                    padding: '0.5em 1em 0em 1em',
                    display: "flex", flexDirection: "column",
                    // gap: '1rem'
                }}>
                    <BatchScene
                        values={
                            {
                                app, sessionsForBatch, batchTrainer, moduleDetails, red_coTrainersForBatch, BottomButton, user_token, moduleId, coachId, coTrainer, batchMeta, programId, setBatchTrainer, setBatchMeta, selectedBatch, batchDetails, setBatchDetails, setConfigType, handleChange, checkFields, setRedirect
                            }
                        }
                        fields={batchCreationFields?.fields}
                        configType={config(configType)}
                    />

                </div>
            }
        </div>
        {redirect.status === true && <Redirect to={redirect.to} push={true} />}
    </>
}

export default BatchCreation;