import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, currentUnit }) => {

    // let descriptionSize = '10px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        // descriptionSize = '14px';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className='star-maker-associate-habit-textbox' style={{ fontSize: isFullScreen ? '18px' : '12px', lineHeight: isFullScreen ? '18px' : '12px' }}>
                    <TextEditor id={data.textBoxId} initialData={engagementData[data.textBoxId] || null}
                        // initialData={engagementData[data.toolName] ? engagementData[data.toolName][data.textBoxId] ? engagementData[data.toolName][data.textBoxId] : null : null}
                        manageToolData={manageToolData} placeholder='Fill here...'
                        scrollable='tct-scrollable-darkgray'
                        preFilledTextBoxData={data.preFilledTextBoxData ? data.preFilledTextBoxData : false}
                        // textEditorClass='UPTC_CS_Square-textEditorClass'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                        hideToolbar={true}
                    // oneHierarchy={true}
                    // textBoxId={data.textBoxId}
                    // engagementData={engagementData}
                    />
                </div>
            </div>
        </div >
    );
}