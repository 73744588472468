import React from "react"
import Slider from '@material-ui/core/Slider';
import { makeStyles } from "@material-ui/core";

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, language }) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            color: 'transparent',
            height: 8,
            width: 15
        },
        thumb: {
            height: isFullScreen ? 55 : 40,
            width: isFullScreen ? 45 : 30,
            backgroundColor: 'transparent',
            // border: '2px solid currentColor',
            marginTop: -8,
            marginLeft: 10,
            marginBottom: isFullScreen ? '-30px !important' : '-22px !important',
            boxShadow: 'none !important',

        },

        track: {
            height: 8,
            borderRadius: 4,
        },
    }));

    function valuetext(value) {
        return value;
    }

    const classes = useStyles();

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg?.[language] ? data.bgImg[language] : data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <Slider
                    classes={{
                        root: classes.root,
                        thumb: classes.thumb,
                        track: classes.track
                    }}
                    // className='railway-trust-meter'
                    orientation="vertical"
                    aria-labelledby={data.id}
                    defaultValue={data?.defaultValue || 0}
                    step={null}
                    onChange={(event, value) => {
                        manageToolData({ toolId: data.id, content: value })
                    }}
                    value={engagementData?.[data.id]}
                    marks={data.marks}
                    ThumbComponent={(props) => <img {...props} src={data?.slider?.icon} />}
                    style={{
                        ...data?.slider?.style,
                        position: "absolute"
                    }}
                />
            </div>
        </div >
    );
}