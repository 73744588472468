import React from "react";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import "./style1.css";
import { useSelector } from "react-redux";
import slider2 from "./slider2.png";
// import SliderIcon from "./SliderIcon.png";
import ReactTooltip from "react-tooltip";
import ProgressBar from "../ProgressBar";

export default ({
  width,
  height,
  engagementData,
  manageToolData,
  isFullScreen,
  engagementStatus,
  data,
  currentEngagement, typPC,
  sessionId,
}) => {
  const responses = useSelector((state) => state.app.response[data.engagement]);
  const answers1 = useSelector((state) => state.app.responseCount1);
  const names = useSelector((state) => state?.app?.names?.[data.engagement]);
  const studentJoinedList = useSelector((state) => state.app.studentJoinedList);
  const uniqueStudentsName = useSelector(
    (state) => state.app.uniqueStudentsName
  );
  const [val, setVal] = React.useState(undefined);
  const [show, setShow] = React.useState(false)
  const answers = [];
  const studentslabel = [];
  let Obj = {};
  let newObj = {};
  let labelObj = {}
  let totalStudents = Object.values(studentJoinedList)?.length;


  const useStyles = makeStyles((theme) => ({
    root: {
      position: "absolute !important",
      color: "transparent",
      height: `${data.sliderCss["height"]} !important`,
      // border: "2px solid red",
      // height: 15,
      width: 15,
      right: `${data.sliderCss["right"]}`,
      top: `${data.sliderCss["top"]}`,
      bottom: `${data.sliderCss["bottom"]}`,
      left: `${data.sliderCss["left"]}`,
      // border: `${data.sliderCss["border"]}`,
      // border: "1px solid red",
    },

    thumb: {
      height: isFullScreen ? 55 : 40,
      width: isFullScreen ? 45 : 30,
      // backgroundColor: "transparent",
      // border: "2px solid red",
      marginTop: -8,
      marginLeft: 10,
      marginBottom: isFullScreen ? "-30px !important" : "-22px !important",
      boxShadow: "none !important",
    },

    track: {
      height: 8,
      borderRadius: 4,
      // border: "1px solid blue",
    },
  }));

  const classes = useStyles();

  if (answers1) {
    Object.values(answers1).map((el) => {
      Object.values(el).map((ts) => {
        Object.values(ts).map((fs) => {
          fs.map((ds) => {
            answers.push(ds.sli1);
          });
        });
      });
    });
  }



  if (names) {
    names.map((el, i) => {
      let Obj1 = Object.assign({}, { username: names[i], ans: answers[i] });
      studentslabel.push(Obj1);
      Obj[answers[i]] = [];
    });
  }

  Object.keys(Obj).map((ts) => {
    let qwer = [];
    studentslabel.map((el) => {
      if (ts === el.ans) {
        qwer.push(el.username);
        Obj[el.ans] = qwer;
      }
    });
  });

  const newdata = uniqueStudentsName?.[data.engagement]?.map((el) => {
    newObj[el] = [];
    let ans = [];
    Object.values(studentslabel).map((ts, k) => {
      if (studentslabel[k].ans) {
        if (el == studentslabel[k].username) {
          ans.push(studentslabel[k].ans);
        }
        newObj[el] = ans[ans.length - 1];
        // newObj[el] = ans;
      }
    });
  });

  const Lab = Object.keys(data.marking).map((opt, i) => {
    let labArr = [];
    Object.keys(newObj).map((keys, k) => {
      // console.log(opt, newObj[keys])
      if (opt == newObj[keys]) {
        labArr.push(keys);
      }
    });
    labelObj[opt] = labArr;
  });


  function findSliderVal(arr) {
    let mf = 1;
    let m = 0;
    let item;

    for (let i = 0; i < arr.length; i++) {
      for (let j = i; j < arr.length; j++) {
        if (arr[i] == arr[j]) {
          m++;
          if (m > mf) {
            mf = m;

            item = arr[i];
          }
        }
      }
      m = 0;
    }

    return item;
  }
  function secondMostFrequent(arr) {
    let anss = [...new Set(answers)]
    if (anss.length > 1) {
      const map = arr.reduce((acc, val) => {
        if (acc.has(val)) {
          acc.set(val, acc.get(val) + 1);
        } else {
          acc.set(val, 1);
        };
        return acc;
      }, new Map);
      const frequencyArray = Array.from(map);
      return frequencyArray.sort((a, b) => {
        return b[1] - a[1];
      })[1][0];
    }
  };

  // const count = {};
  // for (const element of answers) {
  //   if (count[element]) {
  //     count[element] += 1;
  //   } else {
  //     count[element] = 1;
  //   }
  // }


  // console.log("answers", answers)
  // console.log("newObj", newObj)
  // console.log("data.marking", data.marking)
  // console.log("labelOBj", labelObj)
  // // console.log("1st 2nd", findSliderVal(answers), secondMostFrequent(answers));
  // console.log("secondMostFrequent(answers)", secondMostFrequent(answers))
  // console.log("2nd most", data?.marking[`${secondMostFrequent(answers)}`]);

  let progessBarValue = (uniqueStudentsName?.[data.engagement]?.length / totalStudents) * 100;
  // let respoLength = uniqueStudentsName?.[data.engagement]?.length

  return (
    <div
      style={{
        width,
        height,
        // border: "2px solid red",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${data.bgImg?.["hi"] || data.bgImg?.["en"]})`,
          // backgroundImage: `url(${Slide10})`,
          width: "100%",
          // height: "85%",
          height: !isFullScreen ? "65vh" : "75vh",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          position: "relative",
          // border: "2px solid red",
        }}
      >
        {/* <div style={sliderCss}> */}

        <Slider
          classes={{
            root: classes.root,
            thumb: classes.thumb,
            track: classes.track,
          }}
          className={classes.root}
          orientation="vertical"
          defaultValue={data?.defaultValue || 50}
          value={
            answers.length == 1 ? data?.marking[`${answers[0]}`] :
              findSliderVal(answers) ? data?.marking[`${findSliderVal(answers)}`] : 50
          }
          // getAriaLabel={getAriaLabel}

          // marks={data.marks}
          ThumbComponent={(props) => (
            <div>
              <img
                {...props}
                src={data.sliderIcon}
              />
            </div>
          )}
        />

        <Slider
          classes={{
            root: classes.root,
            thumb: classes.thumb,
            track: classes.track,
          }}
          className={classes.root}
          orientation="vertical"
          defaultValue={data?.defaultValue || 50}
          value={
            secondMostFrequent(answers) ? data?.marking[`${secondMostFrequent(answers)}`] : 50
          }
          // getAriaLabel={getAriaLabel}

          // marks={data.marks}
          ThumbComponent={(props) => (
            <div
            // style={{ border: '1px solid red' }}
            >
              <img
                // style={{ border: '1px solid red' }}
                {...props}
                src={slider2}
              />
            </div>
          )}
        />




        {uniqueStudentsName?.[data.engagement]?.length && <div style={{ position: "absolute", top: !isFullScreen ? '16vh' : "19vh", right: '16px', height: !isFullScreen ? '62%' : "70%", width: '7%' }}>
          <div style={{ postion: 'relative', width: '100%', height: '100%' }}>
            {Object.keys(data?.marking).map((mark, i) => {
              let percentage = (labelObj[mark]?.length / uniqueStudentsName?.[data.engagement]?.length) * 100
              let value;
              percentage % 1 == 0 ? value = percentage : value = percentage?.toFixed(2);
              return <div id={mark}>
                <a data-tip data-for={mark}>
                  <div
                    id="mark"
                    style={{ position: 'absolute', top: `${!isFullScreen ? i * 9 : i * 10}vh`, backgroundColor: `rgb(150 217 255 / ${value == 0 ? 20 : value}%)`, borderRadius: "10px", height: '5vh', width: '100%', textAlign: 'center', right: '0px', fontSize: '1rem', padding: "3%", fontWeight: 'bold', }}>
                    {/* <div style={{ position: 'absolute', opacity: 1 }}> */}
                    {`${value}%`}
                    {/* </div> */}
                  </div>
                </a>
                <ReactTooltip id={mark} aria-haspopup="true" role="example">
                  <ul>
                    {labelObj[mark] && labelObj[mark].length > 0 ? (
                      labelObj[mark].map((users) => {
                        return <li key={mark}>{users}</li>;
                      })
                    ) : (
                      <li key={mark}>No User</li>
                    )}
                  </ul>
                </ReactTooltip>
              </div>
            })}
          </div>
        </div>}
      </div>
      <ProgressBar
        typPC={typPC}
        data={data}
        engagementData={engagementData}
        manageToolData={manageToolData}
        isFullScreen={isFullScreen}
        responses={responses}
        currentEngagement={currentEngagement}
        uniqueStudentsName={uniqueStudentsName}
        sessionId={sessionId}
        progessBarValue={progessBarValue}
        show={show}
        setShow={setShow}
        // answers={newDipLabel}
        totalStudents={totalStudents}
        engagementStatus={engagementStatus}
      />
    </div>
  );
};
