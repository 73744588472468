import { Button, Checkbox } from '@material-ui/core'
import React from 'react'
import { updateData } from '../../../../utilities/firebase/fb_rtdb'
import { autoOrganise } from '../../utils'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { setADoc } from '../../../../utilities/firebase/fb_firestore';


export default ({
  sectionId,
  setSectionId,
  responses,
  toolKit,
  boardState,
  sessionId,
  toolId,
  dim,
}) => {

  const activateResponse = (fid, rid, r) => {
    let updates = {};
    updates[`test/live_session/${sessionId}/ideaBoards/${toolId}/activeResponseId`] = boardState.activeResponseId === rid ? null : rid
    updates[`test/live_session/${sessionId}/ideaBoards/${toolId}/activeFieldId`] = boardState.activeFieldId === rid ? null : fid
    updates[`test/live_session/${sessionId}/ideaBoards/${toolId}/fieldWiseResponses/${fid}/${rid}/checked`] = r?.checked ? !r.checked : true;

    updateData(updates)
  }

  return (<div className='Four_div extra' style={{ border: '2px solid', }}>
    <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '1rem', fontWeight: 600 }}>
      Recycle
      <Button onClick={() => setSectionId(null)} style={{ padding: 0 }}>X</Button>
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', height: '55vh', overflow: 'hidden auto' }}>
      {Object.keys(boardState?.fieldWiseResponses || {}).map(fid => {
        let f = boardState.fieldWiseResponses[fid]
        return (
          <>
            {Object.keys(f || {}).map((rid, i) => {
              let r = f[rid]
              if (r?.deletedOn && !r?.eid) {
                return (
                  <div key={rid + fid} style={{ display: 'flex', justifyContent: 'space-between', border: '2px solid #D9D9D9', borderRadius: '8px', margin: '3% 0%', padding: '1% 2%', lineHeight: '1.2rem' }}>
                    <Checkbox
                      key={rid * i}
                      checked={r?.checked || false}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      onChange={(event) => {
                        activateResponse(fid, rid, r)
                      }}
                      // style={{ marginLeft: '-20%', marginTop: '-20%' }}
                      inputProps={{
                        "aria-label":
                          "primary checkbox",
                      }}
                    />
                    <div key={r?.eid}
                      style={{ margin: "2%", width: '100%' }}>
                      <b>{r?.user_name}</b>&ensp;{r?.editedText || r?.text}
                    </div>
                  </div>
                )
              }
            })}
          </>)
      })}
    </div>
    <div style={{ width: '100%' }}>
      <Button
        style={{ backgroundColor: '#BA1F00', borderRadius: '8px', color: 'white', width: '100%', padding: '3% 2%' }}
        onClick={() => {
          if (toolKit.variant == "grid") {
            Object.keys(boardState.fieldWiseResponses).map(fid => {
              let f = boardState.fieldWiseResponses[fid];
              return Object.keys(f || {})
                .filter(rid => f[rid]?.checked && f[rid].deletedOn && !f[rid].eid)
                .map(rid => {
                  let r = f[rid]
                  let update = {}
                  update[`test/live_session/${sessionId}/ideaBoards/${toolId}/fieldWiseResponses/${fid}/${rid}/eid`] = rid
                  // update[`test/live_session/${sessionId}/ideaBoards/${toolId}/fieldWiseResponses/${fid}/${rid}/deletedOn`] = false
                  update[`test/live_session/${sessionId}/ideaBoards/${toolId}/fieldWiseResponses/${fid}/${rid}/onBoard`] = true
                  setADoc({
                    collectionId: "generatedIdeas", docId: rid, data: {
                      deletedOn: false
                    }
                  });
                  updateData(update)
                })
            })
          }
          else {
            autoOrganise({ sessionId, toolId, boardState, dim })
          }
        }}

      >ADD TO BOARD</Button>
    </div>
  </div >

  )
}