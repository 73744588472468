import React from 'react';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let descriptionSize = '14px';

    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '21px';
    }

    let top = ['23.5%', '43.5%', '63.5%', '83.5%']

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <textarea id={data.goalTextBoxId} placeholder='Fill here…'
                    className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ fontSize: descriptionSize, position: 'absolute', width: '66%', top: '1.5%', right: '0.5%', height: '10%' }}
                    value={engagementData[data.goalTextBoxId] || ''}
                    readOnly
                />

                {
                    data.textBoxId.map((textBoxId, i) => <textarea id={textBoxId} placeholder='Fill here…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ fontSize: descriptionSize, position: 'absolute', width: '67%', top: top[i], right: '1%', height: '15%' }}
                        value={engagementData[textBoxId] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: textBoxId, content: event.currentTarget.value })
                        }}
                    />)
                }
            </div>
        </div>
    );
}