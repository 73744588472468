import React from 'react';


import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    const top = ['20.5%', '36%', '59.5%', '73%', '65%', '44%'];
    const right = ['18.5%', '9%', '9.5%', '20%', '32%', '37%'];

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((item, i) =>
                        <textarea id={engagementData[data.textBoxId[i]]} key={i}
                            className={`UPTC-Case-Study-Circle-textbox roboto tct-scrollable-darkgray`}
                            style={{ fontSize: !isFullScreen ? '12px' : '16px', top: top[i], right: right[i], }}
                            value={engagementData[data.textBoxId[i]] || ''}
                            onChange={(event) => {
                                manageToolData({ toolId: data.textBoxId[i], content: event.currentTarget.value })
                            }}
                            placeholder='&#9679; Fill in…' />)
                }
            </div>
        </div>
    );
}