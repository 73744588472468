import { Button, Checkbox, IconButton, Input, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Start_N_Stop from '../../../../Live_Session_Tools/Start_N_Stop';
import { getIdToAdd } from '../../../../utilities/firebase/fb_firestore';
import { activateResponse, deleteResponse, sendFinalBoardState, superActivateResponse, TeacherUpdateAResponse } from '../../utils';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { xml } from 'd3';
import { Delete, Send, TabUnselected } from '@material-ui/icons';
import store from '../../../../Redux_Store';


let Responses = ({ engagementStatus, boardState, responses,
  currentEngagement, toolKit, sessionId, toolId, isFullScreen,
  width, height, manageToolData, engagementData, data,
  currentUnit, currentScene
}) => {
  let engagementId = currentEngagement._id;
  let [class_, setClass] = useState("");
  let [inFocus, setInfocus] = useState({});
  let createdSession = useSelector((state) => state.app.createdSession);
  let [search, setSearch] = useState('');
  let selectedResponses = boardState?.activeResponses || {};
  let user_token = useSelector((state) => state.auth.user_token)
  let learningTool = useSelector((state) => state.app.learningTools[currentUnit]);
  let isResponsesSelected = Object.keys(selectedResponses).length > 0;

  return <div className='field-response-holder'
    style={{ height: "100%", overflow: "hidden", display: 'flex' }}>
    {Object.keys(toolKit?.inputSettings?.fields || {})
      .map(fid => {
        //TODO: Put a glider here
        let f = boardState?.fieldWiseResponses?.[fid];
        if (engagementStatus[data.engagement]) {
          return <div className='Four_div' key={`action-board-${fid}`} >
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <div>
                <span style={{ fontSize: '0.9rem', color: '#818181', fontWeight: 500 }}>Responses</span>
                <span style={{ fontSize: '1.75rem', fontWeight: 600, color: 'black' }}>
                  {Object.keys(boardState?.fieldWiseResponses?.[fid] || {})
                    .filter(rid => !responses?.[rid]?.deletedOn)?.length}</span>
              </div>
              <div>
                <span style={{ fontSize: '0.9rem', color: '#818181', fontWeight: 500 }}>
                  {/* Time Left */}
                </span><br />
                <span style={{ fontSize: '1.75rem', fontWeight: 600, color: 'black' }}>
                  {/* 1 min */}
                </span>
              </div>
            </div>
            <div>
              <Start_N_Stop
                ideaBoard={true}
                width={width}
                height={height}
                engagementData={engagementData}
                manageToolData={manageToolData}
                data={data}
                isFullScreen={isFullScreen}
                engagementStatus={engagementStatus}
                sessionId={sessionId}
                assignGroup={true}
                startText={"Accept Responses"}
                endText={"Stop Responses"}
              /></div>
          </div>
        }

        return <div key={`response-board-${fid}`} className='Four_div field-container' style={{}}>
          <div className='' style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <TextField variant='outlined' margin='none' size='small' style={{ flexGrow: 1, marginRight: 8 }}
              placeholder='search' value={search} onChange={(e) => setSearch(e.target.value)} />
            <Button disabled={isResponsesSelected} variant='outlined' color='primary'
              onClick={async () => {
                !isResponsesSelected && superActivateResponse({
                  sessionId, engagementId,
                  rid: await getIdToAdd({ collectionId: "generatedIdeas" }),
                  isEditable: true,
                  response: { fid: "f001" }
                })
              }}>+ Add New</Button>
          </div>
          <div key={`fieldResponses-${fid}`} style={{ overflow: "auto", height: 'calc(100% - 88px)' }}>
            {Object.keys(selectedResponses).length
              ? <div style={{ display: 'flex', marginTop: 26, alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ fontSize: 13, color: '#818181' }}>
                  {`${Object.keys(selectedResponses).length} responses selected`}
                </div>
                <div style={{ color: '#BA1F00' }}>
                  <IconButton onClick={() => {
                    Object.keys(selectedResponses).forEach(rid => {
                      //shutting everything off, hence rid with isActive: false
                      activateResponse({ sessionId, engagementId, fid, rid, isActive: false })
                    })
                  }}><TabUnselected /></IconButton>
                  <IconButton color='inherit' onClick={() => {
                    Object.keys(selectedResponses).forEach(rid => {
                      deleteResponse({ sessionId, engagementId, fid, rid })
                    })
                  }}><Delete /></IconButton>
                </div>
              </div> : null}
            {Object.keys(f || {})
              .filter(rid => {
                let r = responses?.[rid];
                //filter out those which are deleted

                let allFlags = [];
                let searchLength = search?.length > 3 //if search lenght greater than 3 then ignore deleted
                // let deleteFlag = searchLength || !f[rid]?.deletedOn;
                let boardFlag = searchLength || !f[rid]?.class;
                let isSelectedFlag = selectedResponses[rid] ? true : false;
                let searchFlag = false;
                if (searchLength) {
                  let text = r?.editedText || r?.text;
                  let textSearch = text?.toLowerCase().toString().includes(search?.toLowerCase());
                  let nameSearch = r?.user_name?.toLowerCase()?.toString()?.includes(search?.toLowerCase());
                  let tagSearch = r?.tags?.[0]?.toLowerCase().toString()?.includes(search?.toLowerCase());
                  searchFlag = textSearch || nameSearch || tagSearch;
                }
                Object.keys(boardState?.filters || {}).map(filterKey => {
                  let x = r?.[filterKey] === boardState?.filters?.[filterKey];
                  allFlags.push(x);
                });
                let isInFocus = isSelectedFlag || (allFlags.length && allFlags.indexOf(false) === -1) || searchFlag || boardFlag;
                // setInfocus(x => ({ ...x, [rid]: isInFocus }));
                return isSelectedFlag || (allFlags.length && allFlags.indexOf(false) === -1) || searchFlag || boardFlag;
              })
              .map((rid, j) => {
                let r = responses?.[rid];
                let c = toolKit?.classes[r?.class] || {};
                let isActive = selectedResponses[rid] ? true : false;

                return <div key={`list-${rid}`}
                  style={{
                    background: isActive ? (r?.class ? `${c.colorScheme}22` : '#FFF') : "#F4F4F4",
                    border: `2px solid ${isActive ? (r?.class ? c.colorScheme : '#FFF') : "#EEEEEE"}`,
                    borderRadius: '8px', margin: "8px 0px", fontSize: '14px', padding: '8px 12px',
                    display: "flex"
                  }}>
                  <div
                    className='pointer'
                    style={{ fontSize: "0.8rem" }}
                    onClick={(ev) => {
                      if (ev.detail == 1) { activateResponse({ sessionId, engagementId, fid, rid, isActive: !selectedResponses[rid] }) }
                      else if (ev.detail == 2 && Object.keys(selectedResponses || {}).length < 2) {
                        superActivateResponse({
                          sessionId, engagementId, rid,
                          isEditable: true, response: { fid: "f001" }
                        })
                      }
                      ev.stopPropagation();
                      ev.preventDefault();
                    }}
                  >
                    <span style={{ fontWeight: 'bold' }} >{r?.user_name || 'Facilitator'}</span> &nbsp;
                    <span>{r?.editedText || r?.text}</span>
                  </div>
                </div>
              })}
          </div>

          <div className='action-buttons' style={{}}>
            {Object.keys(selectedResponses).length
              ? <div className="Bulk-actions" style={{
                display: "flex",
                justifyContent: "space-between"
              }}>
                {/* <Button
         disableElevation
         variant='contained'
         onClick={() => {
          superActivateResponse({
           //rid is null to only activate toggle
           sessionId, engagementId, rid: null,
           isEditable: true
          })
         }}>Assign Level</Button> */}

                <Select
                  native
                  style={{ fontSize: "0.9rem", flexGrow: 1 }}
                  labelId="select-field"
                  id="response-select"
                  value={class_ || ""}
                  onChange={(ev) => {
                    setClass(ev.target.value)
                  }}
                ><option className='select-label' aria-label="None" value="" />
                  {toolKit && Object.keys(toolKit?.classes)
                    .sort((a, b) => {
                      return toolKit.classes[a].order - toolKit.classes[b].order
                    })
                    .map((cid, j) => {
                      let c = toolKit.classes[cid];
                      return <option className='select-label' key={`select-${cid}`} value={cid}>{c.label}</option>
                    })}
                </Select>
                <Button
                  disableElevation
                  variant='contained'
                  onClick={() => {
                    Object.keys(selectedResponses || {}).forEach(rid => {
                      TeacherUpdateAResponse({
                        rid,
                        u: {
                          ...responses[rid], class: class_ != "" ? class_ : null, sessionId, toolId,
                          fid: selectedResponses[rid].fid || "f001", user_name: responses?.[rid]?.user_name ? responses?.[rid]?.user_name : "Facilitator",
                          engagementId
                        },
                        sessionId, engagementId, fid: selectedResponses[rid].fid || "f001",
                        wasClassUpdated: class_ != "" || !(responses[rid].class === class_)
                      })
                    })
                  }}>Add a tag</Button>

              </div>
              : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                <Start_N_Stop
                  ideaBoard={true}
                  restart={true}
                  width={width}
                  height={height}
                  engagementData={engagementData}
                  manageToolData={manageToolData}
                  data={data}
                  isFullScreen={isFullScreen}
                  engagementStatus={engagementStatus}
                  sessionId={sessionId}
                  assignGroup={true}
                  startText={"Restart Responses"}
                  endText={"Stop Responses"}
                />
                <IconButton onClick={() => {
                  store.dispatch({ type: "IDEABOARD_MAP", payload: { data: boardState.fieldWiseResponses.f001, engagementId: data.engagement } });
                  sendFinalBoardState({
                    sessionId,
                    fieldWiseResponses: boardState?.fieldWiseResponses,
                    responses,
                    engagementId: data.engagement,
                    user_token,
                    currentUnit,
                    currentScene,
                    learningTool,
                  })
                }}>
                  <Send />
                </IconButton>
              </div>}
            {/*  */}


          </div>
        </div>
      })}
  </div>
}
export default Responses;
