import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group'
import './style.css'
import Bar_Chart from '../../../Live_Session_Tools/Bar_Chart'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId, responses, currentEngagement, uniqueStudentsName }) => {

    let subTitleSize = '12px';
    if (isFullScreen) {
        subTitleSize = '16px'
    }

    const [counter, setCounter] = useState();
    useEffect(() => {
        let tempCounter = {}
        data.textData && Object.keys(data.textData).forEach(item => { tempCounter[item] = 0 })
        setCounter(tempCounter)
    }, [data])
    const handleDoubleClick = (textData, item) => {
        setCounter({ ...counter, [item]: ++counter[item] % textData[item].length });
    }
    const right = ['48.3%', '25.9%', '3.7%'];
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>

                <div style={{
                    position: 'absolute',
                    width: '27%',
                    top: '15%',
                    height: '55%',
                    left: '62%'
                }}>
                    <Bar_Chart
                        engagementData={engagementData}
                        manageToolData={manageToolData}
                        data={data}
                        isFullScreen={isFullScreen}
                        engagementStatus={engagementStatus}
                        sessionId={sessionId}
                        responses={responses}
                        currentEngagement={currentEngagement}
                        uniqueStudentsName={uniqueStudentsName} width='100%' height='100%' />
                </div>
                {data.textData && counter && Object.keys(counter).map((item, i) =>
                    <div key={item} className='live-session-show-text-container_1'
                        style={{ fontSize: subTitleSize, right: right[i], fontWeight: data.textData[item][counter[item]].fontWeight ? data.textData[item][counter[item]].fontWeight : 'normal', color: data.textData[item][counter[item]].fontColor ? data.textData[item][counter[item]].fontColor : 'black' }} >
                        <CSSTransition
                            in={counter[item] ? true : false}
                            classNames="live-session-show-text-anim"
                            timeout={350}
                        >

                            <div
                                className='live-session-show-text-container_2 tct-scrollable-darkgray'
                                onDoubleClick={() => handleDoubleClick(data.textData, item)}>
                                {data.textData[item][counter[item]].text}
                            </div>
                        </CSSTransition>
                        {/* <div className='live-session-show-text-overflow-shape'></div> */}
                    </div>
                )}
            </div>
        </div >
    );
}