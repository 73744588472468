import "./pieceChest.css"
import React from "react";
import { Button } from "@material-ui/core";
import Responses from "./sideBar/responses";
import Clues from "./sideBar/clues";
import Search from "./sideBar/search";
import Deleted from "./sideBar/deleted";
import AddTeacherResponse from "./sideBar/addTeacherResponse.js";

let PieceChest = ({ dim, boardState, toolKit, toolId, currentEngagement,
 engagementStatus, isFullScreen, manageToolData, engagementData, setBoardState,
 width, height, sessionId, responses, data, sectionId, setSectionId, currentUnit,
 currentScene }) => {
 const ib_case = currentEngagement?.ideaBoard?.case
 let noResponses;
 if (responses) {
  noResponses = Object.keys(responses).length
 }
 const getDiv = (c) => {
  switch (c) {
   case "CASE":
    return (
     <div className='Four_div extra' style={{ border: '2px solid', }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '1rem', fontWeight: 600 }}>
       Case
       <Button onClick={() => setSectionId('default')} style={{ padding: 0 }}>X</Button>
      </div>
      <div className="tct-scrollable-darkgray" style={{ overflow: "auto" }}
       dangerouslySetInnerHTML={{
        __html: ib_case,
       }}>
      </div>
     </div>
    )
   case "CLUES":
    return (
     <Clues
      currentEngagement={currentEngagement}
      setSectionId={setSectionId}
      sectionId={sectionId}
     />
    )
   case "RESPONSES":
    return <Responses
     isFullScreen={isFullScreen}
     data={data}
     engagementData={engagementData}
     width={width}
     height={height}
     manageToolData={manageToolData}
     engagementStatus={engagementStatus}
     sectionId={sectionId}
     toolKit={toolKit}
     boardState={boardState}
     currentEngagement={currentEngagement}
     sessionId={sessionId}
     noResponses={noResponses}
     setSectionId={setSectionId}
     dim={dim}
     toolId={toolId}
     responses={responses}
     currentUnit={currentUnit}
     currentScene={currentScene}
    />


   case "Search":
    return (
     <Search
      boardState={boardState}
      setSectionId={setSectionId}
      sectionId={sectionId}
      responses={responses}
      setBoardState={setBoardState}
     />
    )
   case "Recycle":
    return (
     <Deleted
      sectionId={sectionId}
      setSectionId={setSectionId}
      responses={responses}
      toolKit={toolKit}
      boardState={boardState}
      setBoardState={setBoardState}
      currentEngagement={currentEngagement}
      sessionId={sessionId}
      toolId={toolId}
      dim={dim}
     />
    )
   case "AddTeacherResponse":
    return (
     <AddTeacherResponse
      sectionId={sectionId}
      setSectionId={setSectionId}
      open={sectionId == 'AddTeacherResponse' || false}
      activeResponseId={boardState?.activeResponseId}
      activeFieldId={boardState?.activeFieldId}
      activeResponse={responses?.[boardState?.activeResponseId]}
      toolId={toolId}
      toolKit={toolKit}
      sessionId={sessionId}
     />
    )
   default:
    return <Responses
     isFullScreen={isFullScreen}
     data={data}
     engagementData={engagementData}
     width={width}
     height={height}
     manageToolData={manageToolData}
     engagementStatus={engagementStatus}
     sectionId={sectionId}
     toolKit={toolKit}
     boardState={boardState}
     currentEngagement={currentEngagement}
     sessionId={sessionId}
     noResponses={noResponses}
     setSectionId={setSectionId}
     dim={dim}
     toolId={toolId}
     responses={responses}
     currentUnit={currentUnit}
     currentScene={currentScene}
    />
  }
 }
 return <div
  style={{
   width: dim?.w,
   position: 'relative',
   height: "100%"
  }}>
  <div style={{ height: "100%" }}>
   {getDiv(sectionId)}
  </div>

 </div>

}

export default PieceChest;