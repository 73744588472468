// import { AccessTimeOutlined } from "@material-ui/icons";

// import { joinRoom, startEngagement } from "./Socket";
const INITIAL_STATE = {
  authInProgress: false,
  resetPasswordInProgress: false,
  passwordLinkSent: false,
  userRegistrationInProgress: false,
  anyAuthRequestInProgress: false,
  isLoggedIn: false,
  user: null,
  assessment_start: null,
  jobDone: null,
  user_token: "",
  user_uid: "",
  loginErrorMsg: "",
  forgotPasswordErrorMsg: "",
  registrationErrorMsg: "",
  availableModule: [], //This is an array of the modules subscribed by the user
  allModule: [], //This is an array of all the modules
  availableTags: [],
  availablePrograms: [],//This is an array of the Programs Available
  availableProgramsNew: [],//This is an array of the Programs Available
  moduleObj: [],
  moduleDetails: {}, //This object holds the details of the module
  roadmapforModuleDetails: {}, //This object holds the details of the roadmap for the module
  roadmaps: {},
  learningTools: {},
  allSessions: [],
  oneSession: {},
  otpData: {},
  isFetching: false,
  error: false,
  postOtpData: {},
  isLoading: "",
  sessionid: "",
  exploreFlag: "",
  prepareFlag: "",
  conductFlag: "",
  engagementData: {},
  genericModule: {},
  showSearchBar: false,
  searchText: "",
  genericUnits: {},
  createdSession: {},
  response: {},
  engagementStatus: {},
  uploadedProject: {},
  projects: [],
  offline: false,
  currentEngagment: "",
  uniqueStudentsName: {},
  // currentEngagment: "",
  continueConductLoading: false,
  continueConducts: [],
  activeConductSessioId: null,
  responseCount: "",
  organisationList: {},
  isHindi: false,
  studentJoinedList: {},
  activeContinueConductDetails: {},
  assessmentDoneStudent: {},
  batchDetails: [],
  sessionsForBatch: [],
  coTrainersForBatch: [],
  batchStudentsDetails: {},
  selectedBatch: {},
  otpSent: false,
  loginOTP: {},
  userValues: {
    phone: "",
    otp: "",
    error: "",
    otpTimer: 0,
  },
  loginOTPLoading: false,
  selectedRole: "",
  blogModules: {},
  //userNames: {},
  scenario: [],
  notifications: {
    switch: false,
    join: false
  },
  ideaBoard_map: [],
  walkThrough: {
    currentEl: 0,
    confirmExit: false,
    stop: false,
    allElements: ['stepZero', 'walkProgramDiv', 'walkRoleDiv', 'walkModuleDiv', 'walkPrepareDiv', 'walkConductDiv', 'setting', 'setting2', 'help'],
    endWalkThrough: false
  },
  showLinearNavigator: true
};

export default (state = INITIAL_STATE, action) => {
  let obj = {}
  // console.log("action.type", action.type)
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case 'UPDATE_CURRENT_EL':
      let key = action.payload.key
      let value = action.payload.value
      let x = state.walkThrough
      return {
        ...state,
        walkThrough: {
          ...state.walkThrough,
          [key]: value
        }
      }
    case 'CREATE_WALKTHROUGH':
      return {
        ...state,
        walkThrough: action.payload
      }
    case "GETFORMVALUES_SUCCESS":
      return { ...state, engagementData: action.payload };
    case "NOTIFICATIONS":
      return {
        ...state,
        notifications: { ...state.notifications, [action.payload.key]: action.payload.value }
      }
    case "RESET_NOTIFICATIONS":
      return {
        ...state,
        notifications: {
          switch: false,
          join: false
        }
      }
    case "SET_ACTIVE_CONDUCT":
      return {
        ...state,
        activeConductSessioId: action.payload.sessionId,
        activeContinueConductDetails: {
          currentSlideIndex: action.payload.currentSlideIndex,
          roadmapId: action.payload.roadmapId,
          learningToolsId: action.payload.learningToolsId,
        },
      };
    case "GETPREPAREMODULE_ATTEMPT":
      return {
        ...state,
        isLoadingData: true,
        loginErrorMsg: "",
        authInProgress: true,
        engagementData: "",
        engagementStatus: {},
      };
    case "GETPREPAREMODULE_SUCCEEDED":
      return {
        ...state,
        engagementData: "",
        // genericModule: {},
        genericUnits: {},
        // availableModule: [],
        availableModule: action.data[0].availableModule,
        genericModule: action.data[0].genericModule,
      };
    case "GETCONTINUECONDUCTMODULE_ATTEMPT":
      return { ...state, continueLoading: true };
    case "GETCONTINUECONDUCTMODULE_SUCCEEDED":
      return {
        ...state,
        continueConducts: action.payload,
      };
    case "GETMODULE_ATTEMPT":
      return {
        ...state,
        isLoadingData: true,
        loginErrorMsg: "",
        authInProgress: true,
      };
    case "GETMODULE_SUCCEEDED":
      let units = state.units;
      for (let u in action.payload.data.ImproveEngagement) {
        for (let i in action.payload.data.ImproveEngagement[u]) {
          if (Array.isArray(action.payload.data.ImproveEngagement[u][i])) {
            for (let unit in action.payload.data.ImproveEngagement[u][i]) {
              units = {
                ...units,
                [action.payload.data.ImproveEngagement[u][i][unit].id]:
                  action.payload.data.ImproveEngagement[u][i][unit],
              };
            }
          }
        }
      }
      return {
        ...state,
        moduleDetails: {
          ...state.moduleDetails,
          [action.payload.id]: action.payload.data,
        },
        units: units,
        coTrainersForBatch: [],
      };
    case "EMPTY_COTRAINERS":
      return {
        ...state,
        coTrainersForBatch: [],
      }
    case "GETROADMAPFORMODULE_ATTEMPT":
      return {
        ...state,
        isLoadingData: true,
        loginErrorMsg: "",
        authInProgress: true,
      };
    case "GETROADMAPFORMODULE_SUCCEEDED":
      let { id, data } = action.payload;
      return {
        ...state,
        roadmapforModuleDetails: {
          ...state.roadmapforModuleDetails,
          [id]: data,
        },
      };
    case "SWITCH_PROGRAM":
      return {
        ...state, user: {
          ...state.user,
          programId: action.payload
        }
      }
    case "GETTAG_ATTEMPT":
      return {
        ...state,
        isLoadingData: true,
        loginErrorMsg: "",
        authInProgress: true,
        engagementData: "",
      };
    case "GETANALYTICS_RECEIVED":
      return {
        ...state, user: {
          ...state.user,
          analytics: action.payload
        }
      };
    case "GETPROGRAM_ATTEMPT":
      return {
        ...state,
        isLoadingData: true,
        loginErrorMsg: "",
        authInProgress: true,
        engagementData: "",
      };
    case "GETTAG_SUCCEEDED":
      return {
        ...state,
        engagementData: "",
        availableTags: action.data,
      };
    case "GETPROGRAM_SUCCEEDED":
      return {
        ...state,
        engagementData: "",
        availablePrograms: action.data,
      };
    case "GETPROGRAM_SUCCEEDEDNEW":
      let obj = {}
      action.data.map(d => {
        Object.assign(obj, { [d.programId]: d })
      })
      return {
        ...state,
        engagementData: "",
        availableProgramsNew: obj,
      };
    case "GETUSER_ATTEMPT":
      return { ...state, isLoadingData: true };
    case "GETUSER_SUCCEEDED":
      return {
        ...state,
        exploreFlag: action.payload.data.exploreFlag,
        prepareFlag: action.payload.data.prepareFlag,
        conductFlag: action.payload.data.conductFlag,
        engagementData: "",
        genericModule: {},
        genericUnits: {},
        availableModule: [],
      };
    case "GETUSER_FAILURE":
      return {
        ...state,
      };
    case "GETEXPLOREMODULE_ATTEMPT":
      return {
        ...state,
        isLoadingData: true,
        loginErrorMsg: "",
        authInProgress: true,
      };
    case "GETEXPLOREMODULE_SUCCEEDED":
      return {
        ...state,
        allModule: action.data,
      };
    case "GETROADMAP_ATTEMPT":
      return { ...state, isLoadingData: true };
    case "GETROADMAP_SUCCEEDED":
      return {
        ...state,
        isLoadingData: false,
        roadmaps: {
          ...state.roadmaps,
          [action.payload.id]: action.payload.data,
        },
      };
    case "GETLEARNINGTOOL_ATTEMPT":
      return { ...state, isLoadingData: true };
    case "GETLEARNINGTOOL_SUCCEEDED":
      return {
        ...state,
        isLoadingData: false,
        learningTools: {
          ...state.learningTools,
          [action.payload.id]: action.payload.data,
        },
      };
    case "UPDATEPERCENTAGE_ATTEMPT":
      return { ...state };
    case "UPDATEPERCENTAGE_SUCCEEDED":
      return { ...state };
    case "ADDFORMVALUES_ATTEMPT":
      return { ...state };
    case "ADDFORMVALUES_SUCCEEDED":
      return { ...state };
    case "CREATESESSION_ATTEMPT":
      return {
        ...state,
        engagementData: {},
        studentJoinedList: {},
        assessmentDoneStudent: {},
        sessionid: "",
        assessment_start: null,
        otpData: {},
        createdSession: {},
        activeConductSessioId: null,
        activeContinueConductDetails: {},
        responseCount1: undefined,
        //userNames: {},
        scenario: [],
        ideaBoard_map: [],
      };
    case "CREATESESSION_SUCCEEDED":
      return {
        ...state,
        engagementData: {},
        studentJoinedList: {},
        assessmentDoneStudent: {},
        joinedStudentId: [],
        postOtpData: {},
        otpData: {},
        response: {},
        responseCount1: undefined,
        //userNames: {},
        uniqueStudentsName: {},
        scenario: [],
        ideaBoard_map: [],
        responseCount: "",
        jobDone: null,
        activeConductSessioId: null,
        activeContinueConductDetails: {},
        // sessionid: action.payload.sessionid ? action.payload.sessionid : action.payload[0].sessionid,
        createdSession: action.payload ? action.payload : action.payload[0],
      };
    case "CREATEEXPERIENCE_ATTEMPT":
      return { ...state };
    case "CREATEEXPERIENCE_SUCCEEDED":
      return {
        ...state,
      };
    case "GETSESSIONFORCOACH_ATTEMPT":
      return { ...state, isLoadingData: true };
    case "GETSESSIONFORCOACH_SUCCEEDED":
      return {
        ...state,
        allSessions: action.data,
      };
    case "GETSESSIONFORSESSIONID_ATTEMPT":
      return { ...state, isLoadingData: true };
    case "GETSESSIONFORSESSIONID_SUCCEEDED":
      return {
        ...state,
        oneSession: action.data,
      };
    case "GETASSESSMENTFORSESSIONID_ATTEMPT":
      return { ...state, isLoadingData: true };
    case "GETASSESSMENTFORSESSIONID_SUCCEEDED":
      return {
        ...state,
        //oneSession: action.data
      };
    case "UPDATEFLAG_ATTEMPT":
      return { ...state };
    case "UPDATEFLAG_SUCCEEDED":
      return {
        exploreFlag: action.payload.user.exploreFlag,
        prepareFlag: action.payload.user.prepareFlag,
        conductFlag: action.payload.user.conductFlag,
      };
    case "GETROADMAP_FAILURE":
      return { ...state };
    case "CLEAR_SESSION_OTP":
      return {
        ...state,
        otpData: {}
      };
    case "GETOTP_ATTEMPT":
      return { ...state };
    case "ASSESSMENT_STARTED":
      return { ...state, assessment_start: action.payload };
    // case "JOB_DONE":
    //   console.log("action.payload", action.payload)
    //   return {
    //     ...state,
    //     jobDone: {
    //       ...state.jobDone,
    //       [action.payload.userId]: [...state.jobDone?.[action.payload.userId], action.payload.type]
    //     }
    //   };
    case "GETOTP_SUCCEEDED":
      if (action.payload.data[0].testtype === "pre") {
        return {
          ...state,
          isLoading: false,
          otpData: action.payload.data[0],
          // studentJoinedList: {},
          // assessmentDoneStudent: {},
        };
      } else if (action.payload.data[0].testtype === "post") {
        return {
          ...state,
          isLoading: false,
          postOtpData: action.payload.data[0],
          studentJoinedList: {},
          assessmentDoneStudent: {},
        };
      }
      break;
    case "GETALLGENERICMODULES_ATTEMPT":
      return { ...state, isLoadingData: true };
    case "GETALLGENERICMODULES_SUCCEEDED":
      return {
        ...state,
        isLoadingData: false,
        genericModules: action.payload.data,
      };
    // case "LOADING":
    // return { ...state, isLoading: , }
    case "GETGENERICMODULEUNITS_ATTEMPT":
      return { ...state, isLoadingData: true };
    case "GETGENERICMODULEUNITS_SUCCEEDED":
      return {
        ...state,
        isLoadingData: false,
        genericUnits: {
          ...state.genericUnits,
          [action.payload.data.id]: action.payload.data,
        },
      };

  case "SHOW_SEARCH_BAR":
   return { ...state, showSearchBar: !state.showSearchBar };

  case "HANDLE_SEARCH_TEXT":
   return { ...state, searchText: action.payload.data };

  case "MANAGE_TOOL_DATA":
   if (action.payload?.prevSession) {
    return {
     ...state,
     engagementData: {
      ...state.engagementData,
      ...action.payload.data,
     },
    };
   }
   console.log("not here");
   return {
    ...state,
    engagementData: {
     ...state.engagementData,
     [action.payload.toolId]: action.payload.content,
    },
   };
  case "STORE_SCENARIO_ID":
   return {
    ...state,
    scenario: {
     ...state.scenario,
     [action.payload.engagementId]:
      action.payload.data,
    },
   };
  case "IDEABOARD_MAP":
   return {
    ...state,
    ideaBoard_map: {
     ...state.ideaBoard_map,
     [action.payload.engagementId]:
      action.payload.data,
    },
   };

  case "STUDENT_RESPONSE":
   // let temp = state.response && state.response[action.payload.engagementId] && state.response[action.payload.engagementId][action.payload.data] ? state.response[action.payload.engagementId][action.payload.data] : [];
   // if (temp) {
   //   if (temp.indexOf(action.payload.userId) !== -1) {
   //     return { ...state }
   //   }
   // }
   // return { ...state, response: { ...state.response, [action.payload.engagementId]: { ...state.response[action.payload.engagementId], [action.payload.data]: [...temp, action.payload.userId] } } }
   let temp_response = { ...state.response };
   // console.log("action.payload.data", action.payload.data); // array of responses
   console.log(action.payload.data.answer);
   // let tags = action.payload.data.tag;
   let answer = action.payload.data.answer;
   console.log(answer.length);
   console.log(action.payload);
   Object.values(action.payload.data.answer).map((_data) => {
    // Array.from(action.payload.data.answer).forEach((_data) => {
    let temp =
     state.response &&
      state.response[action.payload.engagementId] &&
      state.response[action.payload.engagementId][_data]
      ? state.response[action.payload.engagementId][_data]
      : [];
    console.log("temp", temp);
    console.log(
     temp.map((item) => item.user).indexOf(action.payload.user) !== -1
    );
    if (
     temp
      .map((item) => item.user)
      .indexOf(action.payload.data.user_name) !== -1
    ) {
    } else {
     // console.log("_data", _data);
     temp_response = {
      ...temp_response,
      [action.payload.engagementId]: {
       ...temp_response[action.payload.engagementId],
       // [_data]: [...temp, { user: action.payload.userId, tags: action.payload.data.tag }],
       [_data]: [
        ...temp,
        {
         user: action.payload.data.user_name,
         tags: action.payload.data.tags,
        },
       ],
      },
     };
    }
   });

   // console.log(state);
   // console.log("temp",temp)
   let engagement =
    state.uniqueStudentsName &&
     state.uniqueStudentsName[action.payload.engagementId]
     ? state.uniqueStudentsName[action.payload.engagementId]
     : [];
   engagement.push(action.payload.data.user_name);

   // console.log("engagement",engagement);
   // console.log(new Set(engagement));
   // console.log(Array.from(new Set(engagement)));

   let engagement1 =
    state.responseCount && state.responseCount[action.payload.engagementId]
     ? state.responseCount[action.payload.engagementId]
     : [];
   engagement1.push(action.payload.data.answer);
   let user_name = state.names && state.names[action.payload.engagementId] ? state.names[action.payload.engagementId] : []
   // state.userNames.push(action.payload.data.user_name);
   user_name.push(action.payload.data.user_name)

   return {
    ...state,
    response: temp_response,
    uniqueStudentsName: {
     ...state.uniqueStudentsName,
     [action.payload.engagementId]: Array.from(new Set(engagement)),
    },
    responseCount: {
     ...state.responseCount,
     [action.payload.engagementId]: Array.from(new Set(engagement1)),
    },
    responseCount1: {
     [action.payload.engagementId]: engagement1,
    },
    studentJoinedList: {
     ...state.studentJoinedList,
     [action.payload.userId]: {
      ...action.payload,
     },
    },
    singleName: {
     ...state.uniqueStudentsName,
     [action.payload.engagementId]: engagement,
    },
    names: {
     ...state.names,
     [action.payload.engagementId]: user_name
    },
   };

  case "STUDENT_RESPONSE_DELETE":
   // console.log(action.payload)
   // let tempResponse = state.response[action.payload.engId]
   // delete tempResponse[action.payload.key]
   // console.log(tempResponse)
   return {
    ...state,
    response: {
     ...state.response,
     [action.payload.engId]: {},
    },
   };
  case "TEAM_BOARD_STUDENT_RESPONSE":
   return {
    ...state,
    response: {
     ...state.response,
     [action.payload.engagementId]: {
      ...state.response[action.payload.engagementId],
      [action.payload.data.teamName]: {
       user_name: action.payload.data.user_name,
       ...action.payload.data[action.payload.data.teamName],
      },
     },
    },
   };
  // case "GETENGAGEMENT_ATTEMPT":
  //   return {
  //     ...state,
  //     response: {
  //       ...state.response,
  //       [action.payload.engagementId]: {
  //         ...state.response[action.payload.engagementId],
  //       },
  //     },
  //   };
  case "START_AND_STOP_ENGAGEMENT":
   return {
    ...state,
    engagementStatus: {
     ...state.engagementStatus,
     [action.payload.engId]: !action.payload.status,
    },
   };

  case "UPLOAD_PROJECT_SUCCESS":
   return { ...state, uploadedProject: action.payload };

  case "GET_PROJECT_SUCCESS":
   return { ...state, projects: action.payload };

  case "MAKE_OFFLINE":
   return {
    ...state,
    offline: action.payload,
   };

  case "GETENGAGEMENT_SUCCESS":
   return { ...state, currentEngagment: action.payload };

  case "STUDENT_JOINED":
   return {
    ...state,
    studentJoinedList: {
     ...state.studentJoinedList,
     [action.payload.userId]: {
      ...action.payload,
     },
    },
   };
  case "CHECK_STUDENT_JOINED":
   console.log(action.payload);
   if (!state.joinedStudentId.includes(action.payload))
    return {
     ...state,
     joinedStudentId: [...state.joinedStudentId, action.payload],
    };
   else return { ...state };

  case "RESET_APP":
   return INITIAL_STATE;
  case "CHANGE_LANGUAGE":
   return {
    ...state,
    isHindi: !state.isHindi,
   };
  case "STUDENT_ATTEND":
   return { ...state, studentJoinedList: action.payload };
  case "ASSESSMENT_DONE":
   return {
    ...state,
    assessmentDoneStudent: {
     ...state.assessmentDoneStudent,
     [action.payload.senderId]: action.payload,
    },
   };
  case "GET_BATCH_ATTEMPT":
   return {
    ...state,
    batchDetails: [],
    batchStudentsDetails: {},
    sessionsForBatch: [],
    isLoading: true,
    responseCount1: undefined,
    otpData: {},
    ////userNames: {},
    names: {},
    singleName: {},
    studentJoinedList: {},
   };
  case "GET_BATCH_SUCCESS":
   return {
    ...state,
    batchDetails: action.payload,
    batchStudentsDetails: {},
    isLoading: false,
    responseCount1: undefined,
    //userNames: {},
    names: {},
    singleName: {},
   };
  case "SESSIONS_FOR_BATCH":
   return {
    ...state,
    sessionsForBatch: action.payload.data,
   };
  case "GOT_COTRAINERS":
   let ad = []
   return {
    ...state,
    coTrainersForBatch: action.payload
   }
  case "GET_BATCH_ATTEMPT":
   return {
    ...state,

    batchDetails: [],
    batchStudentsDetails: {},
    sessionsForBatch: [],
    isLoading: false,
   };
  case "GET_BATCH_STUDENTS_SUCCESS":
   if (action?.payload?.batch)
    return {
     ...state,
     batchStudentsDetails: {
      ...state.batchStudentsDetails,
      [action.payload?.batch?.batchName]: {
       batchId: action.payload?.batch?._id,
       batchName: action.payload?.batch?.batchName,
       students: action.payload?.students,
       coach: action.payload?.coach,
      },
     },
    };
   return { ...state };
  case "RESUME_SESSION_SUCCESS":
   return { ...state, createdSession: action.payload };
  // case "GET_FORM_DATA_BATCH_SUCCESS":
  //   return {
  //     ...state,
  //     engagementData: {
  //       ...state.engagementData,
  //       ...action.payload.map(formData => formData.fieldData)
  //     }
  //   }
  case "STORE_SELECTED_BATCH":
   return { ...state, selectedBatch: action.payload };

  // case "OTP_SENT":
  //   return { ...state, otpSent: !state.otpSent }

  case "USER_VALUE_CHANGE":
   return {
    ...state,
    userValues: {
     ...state.userValues,
     [action.payload.key]: action.payload.value,
    },
   };
  case "USER_DETAILS":
   return {
    ...state,
    user: {
     ...state.user,
     [action.payload.key]: action.payload.value,
    },
   };

  case "SEND_OTP_ATTEMPT":
   return { ...state, loginOTPLoading: true };

  case "SEND_OTP_FAILED":
   return { ...state, loginOTPLoading: false };

  case "LOGIN_OTP":
   return { ...state, loginOTP: action.payload, loginOTPLoading: false };

  case "RESET_LOGIN_OTP":
   return {
    ...state,
    loginOTP: {},
    loginOTPLoading: false,
   };

  case "CHOOSE_ROLE":
   return {
    ...state,
    selectedRole: action.payload,
   };

  case "GET_BLOG_DATA_MODULES":
   return {
    ...state,
    blogModules: {
     ...state.blogModules,
     [action.payload.id]: action.payload.data,
    },
   };

  case "UPDATE_BLOG_DATA_MEDIA":
   return {
    ...state,
    blogModules: {
     ...state.blogModules,
     [action.payload.mid]: {
      ...state.blogModules[action.payload.mid],
      medias: {
       ...state.blogModules[action.payload.mid]?.medias,
       [action.payload.id]: action.payload.media,
      },
     },
    },
   };

  case "GET_BLOG_HOWTO_MODULES":
   return {
    ...state,
    howTo: { ...state.howTo, [action.payload.id]: action.payload.data },
   };

  case "UPDATE_BLOG_HOWTO_MEDIA":
   return {
    ...state,
    howTo: {
     ...state.howTo,
     [action.payload.mid]: {
      ...state.howTo[action.payload.mid],
      medias: {
       ...state.howTo[action.payload.mid]?.medias,
       [action.payload.id]: action.payload.media,
      },
     },
    },
   };

  case "GET_BLOG_READINGS":
   return {
    ...state,
    readings: { ...state.readings, [action.payload.id]: action.payload.data },
   };

  case "UPDATE_BLOG_READINGS_MEDIA":
   return {
    ...state,
    readings: {
     ...state.readings,
     [action.payload.mid]: {
      ...state.readings[action.payload.mid],
      medias: {
       ...state.readings[action.payload.mid]?.medias,
       [action.payload.id]: action.payload.media,
      },
     },
    },
   };

  case "GET_MY_DETAIL":
   return { ...state, user: action.payload };
  case "RESET_APP":
   return state;
  case "USE_MY_SESSION":
   return {
    ...state, createdSession: action.payload
   }
  case "TOGGLE_LINEAR_NAVIGATOR":
   return { ...state, showLinearNavigator: action.payload };
  default:
   return state;
 }
};
