import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className={`CD-CNP-textbox roboto`} style={{ fontSize: isFullScreen ? '14px' : '12px', lineHeight:'10px', top: '32%', right: '51%' }}>
                    <TextEditor id={data.textBoxId[0]} initialData={engagementData[data.textBoxId[0]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...'
                        scrollable='tct-scrollable-darkgray'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                    />
                </div>
                <div className={`CD-CNP-textbox roboto`} style={{ fontSize: isFullScreen ? '14px' : '12px', lineHeight:'10px', top: '32%', right: '4.5%' }}>
                    <TextEditor id={data.textBoxId[1]} initialData={engagementData[data.textBoxId[1]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...'
                        scrollable='tct-scrollable-darkgray'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                    />
                </div>
            </div>
        </div>
    );
}