import React from 'react';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, currentUnit }) => {
    const top = ['24%', '64%'];
    const right = ['4%', '4%'];
    let descriptionSize = '12px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '18px';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((textBoxId, i) => <textarea key={textBoxId} id={textBoxId} placeholder='Discuss & fill in...'
                        className={'transparent-textarea tct-scrollable-darkgray axure-handwriting flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ fontSize: descriptionSize, position: 'absolute', width: '37.5%', top: top[i], right: right[i], height: '28%' }}
                        value={engagementData[data.id] ? engagementData[data.id][textBoxId] ? engagementData[data.id][textBoxId] : '' : ''}
                        onChange={(event) => manageToolData({
                            toolId: data.id, content: {
                                ...engagementData[data.id],
                                [textBoxId]: event.target.value
                            }
                        })}
                    />)
                }
            </div>
        </div >
    );
}