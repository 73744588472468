import React from "react"
import { Autocomplete } from "@material-ui/lab"
import { TextField } from "@material-ui/core"

export default ({ disabled, options, getOptionLabel, onChange, label, value }) => {
    return <Autocomplete
        value={value}
        options={options}
        disabled={disabled || false}
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        style={{ margin: '10px 0px' }}
        renderInput={params => (
            <TextField
                {...params}
                label={label}
                variant="outlined"
                fullWidth
            />
        )}
    />
}