import React from 'react';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let descriptionSize = '11px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '14px';
    }

    const prevFilledtop = ['23%', '43.5%', '63.5%', '83.5%'];

    const inputTop = ['25%', '59.5%'];
    const inputRight = ['49%', '26.2%', '3.5%'];
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.prevFilledTextBoxId.map((id, i) => <textarea key={id} id={data.prevFilledTextBoxId[i]} placeholder='Fill here…'
                        className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ width: '24.5%', height: '13%', fontSize: descriptionSize, position: 'absolute', top: prevFilledtop[i], left: '2.5%' }}
                        value={engagementData[data.prevFilledTextBoxId[i]] || ''}
                        readOnly
                    />)
                }
                {
                    data.textBoxId.map((id, i) => <textarea key={id} id={data.textBoxId[i]} placeholder='Fill here…'
                        className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ width: '19.5%', height: '24%', fontSize: descriptionSize, position: 'absolute', top: inputTop[Math.floor(i / (data.textBoxId.length / 2))], right: inputRight[Math.floor(i % (data.textBoxId.length / 2))] }}
                        value={engagementData[data.textBoxId[i]] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: data.textBoxId[i], content: event.currentTarget.value })
                        }}
                    />)
                }
            </div>
        </div>
    );
}