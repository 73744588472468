import React, { useRef, useEffect, useState } from "react";
// import Sketch from "react-p5";
import Tooltip from '@material-ui/core/Tooltip';

import './style.css'
// export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
export default () => {



    let x = 50;
    let y = 50;
    const headDiv = useRef(null)
    const canvasRef = useRef(null)
    const [flag, setFlag] = useState(false)

    useEffect(() => {
        if (headDiv.current) {
            setFlag(true)
            console.log(flag)
        }
    }, [headDiv])

    let fruits = [];
    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        p5.createCanvas(200, 200).parent(canvasParentRef);
        // p5.background(0);

        // for (let i = 0; i < 10; i++) {
        //     fruits.push({ x: 50, y: 50, r: 32 })
        //     // p5.noStroke();
        //     // p5.ellipse(fruits[i].x, fruits[i].y, fruits[i].r * 2, fruits[i].r * 2)
        // }

        // for (let i = 0; i < 10; i++) {

        //     p5.noStroke();
        //     p5.ellipse(fruits[i].x, fruits[i].y, fruits[i].r * 2, fruits[i].r * 2)
        // }

        let circles = [];
        var protection = 0;
        while (circles.length < 500) {
            // Pick a random circle
            var circle = {
                x: p5.random(10, 190),
                y: p5.random(10, 190),
                r: 6
            };

            // Does it overlap any previous circles?
            var overlapping = false;
            for (var j = 0; j < circles.length; j++) {
                var other = circles[j];
                var d = p5.dist(circle.x, circle.y, other.x, other.y);
                if (d < (circle.r + other.r + 15)) {
                    overlapping = true;
                }
            }

            // If not keep it!
            if (!overlapping) {
                circles.push(circle);
            }

            // Are we stuck?
            protection++;
            if (protection > 10000) {
                break;
            }
        }

        // Draw all the circles
        for (var i = 0; i < circles.length; i++) {
            p5.fill('red');
            p5.noStroke();
            p5.ellipse(circles[i].x, circles[i].y, circles[i].r * 2, circles[i].r * 2);
        }


    };

    const draw = (p5) => {


    };

    // const drawFruits = () => {
    //     const dataSet = [
    //         "Hello world 1",
    //         "Hello world 2",
    //         "Hello world 3",
    //         "Hello world 4",
    //         "Hello world 5",
    //         "Hello world 6",
    //         "Hello world 7",
    //         "Hello world 8",
    //         "Hello world 9",
    //         "Hello world 10",
    //         "Hello world 7",
    //         "Hello world 8",
    //         "Hello world 9",
    //         "Hello world 10"
    //     ]
    //     console.log(headDiv)
    //     let fruitSize = ((headDiv.current.clientWidth + headDiv.current.clientHeight)) / dataSet.length;

    //     console.log(headDiv.current)
    //     console.log(fruitSize)
    //     return dataSet.map((item, index) =>
    //         <Tooltip key={item} title={item} arrow>
    //             {/* <div className='fruit-div' style={{ width: fruitSize, height: fruitSize }}> */}
    //             <div className='fruit-div' style={{ width: fruitSize, height: fruitSize }}>

    //             </div>
    //         </Tooltip>

    //     )
    // }

    const drawFruits = () => {


        var refresh = function (dc, width, height, frame_number) // Sample code by Jim Bumgardner
        {
            dc.clearRect(0, 0, width, height);

            var nbr_circles = 333;
            var deviation = 5 / 8.0;

            var phi = (Math.sqrt(5) + 1) / 2 - 1;            // golden ratio
            var golden_angle = phi * 2 * Math.PI;            // golden angle

            var lg_rad = width * .45;
            var lg_area = Math.pow(lg_rad, 2) * Math.PI;

            var mean_area = lg_area / nbr_circles;

            var min_area = mean_area * (1 - deviation);
            var max_area = mean_area * (1 + deviation);

            var cum_area = 0;

            var fudge = .87; // Fudge factor, since our circles don't actually fill up space entirely.

            var cx = width / 2;
            var cy = height / 2;

            var hue_incr = frame_number * .0002 + .1;
            var angle_offset = frame_number * .01;

            for (var i = 1; i <= nbr_circles; ++i) {
                dc.beginPath();

                var angle = i * golden_angle + angle_offset;

                var ratio = i / nbr_circles;
                var sm_area = min_area + ratio * (max_area - min_area);
                var sm_rad = Math.sqrt(sm_area / Math.PI);

                cum_area += sm_area;

                var spiral_rad = Math.sqrt(cum_area / Math.PI);
                var x = cx + Math.cos(angle) * spiral_rad;
                var y = cy + Math.sin(angle) * spiral_rad;

                var hue = hue_incr * i;
                hue -= Math.floor(hue);
                hue *= 360;

                dc.fillStyle = 'hsl(' + hue + ',80%,50%)';

                dc.arc(x, y, sm_rad * fudge, 0, 2 * Math.PI, false);
                dc.fill();
            }

        }

        let width = 200;
        let height = 200;

        // const dc = DOM.context2d(width, height);
        // const canvas = document.createElement("canvas");
        const context = canvasRef.current.getContext("2d"); // DOM.context2d(width, height);

        console.log(context)

        // let t = 100;
        // setInterval(() => {
        //     refresh(context, width, height, t);
        //     t = t + 1;
        //     if (t > 200)
        //         t = 0;
        // }, 50)

        // return context.canvas;
    }

    // return <div style={{ width, height }}>
    return <div>
        <div className='fruit-div-container' ref={headDiv} style={{ width: 100, height: 100 }}>
            {/* <Sketch setup={setup} draw={draw} /> */}
            {/* <div ref={sliderHeadRef} style={{ width, height }}>
                <svg style={{ width: '100%', height: '100%', padding: '0 20px', margin: '0 20px', overflow: 'visible' }} ref={sliderRef} />
            </div> */}
            <canvas ref={canvasRef}
                width={200}
                height={200} />
            {
                flag && drawFruits()
            }
        </div>
    </div >
};