import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import { Input } from '@material-ui/core';

import Pencil2 from './assets/Pencil2.png';
import Number2 from './assets/Number2.png';
// import bulb from './assets/bulb.png';


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let titleSize = '18px'
    let descriptionSize = '12.5px';
    let questionSize = '12px';
    let headingSize = '16px';
    let questionHeight = '12px';
    if (isFullScreen) {
        titleSize = '26px';
        headingSize = '24px'
        descriptionSize = '18px';
        questionSize = '16px';
        questionHeight = '19px'
    }

    return (
        <div style={{ width, height }}>
            <div style={{ height: '10%', width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'center', padding: '0.5rem' }}>
                <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: headingSize, marginRight: '10px' }}>
                    {data.title}
                </div>
                <Input id={data.inputId} style={{ width: '30%' }}
                    placeholder="Fill Here."
                    value={engagementData[data.inputId] || ''}
                    onChange={(event) => {
                        manageToolData({ toolId: data.inputId, content: event.target.value })
                    }}
                />
            </div>
            <div style={{ height: '90%', width: '100%', display: 'flex', background: '#70bdd2', position: 'relative' }}>
                <img src={Number2} style={{ position: 'absolute', width: '50px', top: '-25px', left: '20px' }} alt='number' />
                <div style={{ width: '10%' }}>
                    <img src={Pencil2} style={{ position: 'relative', width: '50%', top: '40px', left: '20px' }} alt='pencil' />
                </div>
                <img src='https://pedgog2.s3.ap-south-1.amazonaws.com/Becoming+future+ready+contributors/01-+Think+solutions/Illuminetoollogo.png' style={{ width: '11%', position: 'absolute', bottom: '0', padding: '6px' }} alt='illumine-logo' />
                <div style={{ width: '90%', height: '100%' }}>
                    <div className='font-work-sans' style={{ fontWeight: 'bold', fontSize: titleSize, padding: '0.5rem 0.5rem 0', height: '15%' }}>{data.heading}</div>
                    <div style={{ display: "flex", flexWrap: 'wrap', height: '85%' }}>
                        {
                            data.textBoxId.map((item, i) => <div key={i} style={{ width: '32%', height: '48%', margin: '0.3%', background: '#ffe69f', border: '3px solid white' }}>
                                <div style={{ height: '40%', lineHeight: questionHeight, padding: '10px' }}>
                                    <div style={{ fontSize: descriptionSize, fontWeight: 'bold', color: '#215968' }}>
                                        <span style={{ color: 'black', fontSize: headingSize, fontWeight: 'normal', margin: '2%' }}>&#9634;</span>
                                        {data.values[i]}</div>
                                    <div style={{ fontSize: questionSize, color: '#215968' }}>{data.box_questions[i]}</div>
                                </div>
                                <div style={{ display: 'flex', width: '100%', height: '60%' }}>
                                    <TextEditor id={data.textBoxId[i]} initialData={engagementData[data.textBoxId[i]] || null}
                                        manageToolData={manageToolData} hideToolbar={true} placeholder='&#9679; Fill here...'
                                        wrapperClass='text-editor-value-thinking-price' />
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}