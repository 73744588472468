import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import left_img from '../../components/images/Tools-img/Total_Compensation/left_img.png';
import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    const [cardsContent, setcardsContent] = useState(data.cardsContent)
    console.log(engagementData)
    const cardsContainer = {
        transactional: [],
        contribution: [],
        fulfillment: [],
        mustHave: [],
        niceToHave: []
    }

    let titleSize = '18px';
    let textSize_2 = '12px';
    let textSize = '10px';
    let boxWidth = '180px';
    let boxHeight = '55px';
    if (isFullScreen) {
        textSize = '14px';
        textSize_2 = '18px';
        titleSize = '22px';
        boxWidth = '280px';
        boxHeight = '80px';
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiInput-root': {
                fontSize: textSize,
                padding: '0px',
                width: '100%',
                textDecoration: 'underline',
                lineHeight : '20px'
            }
        },
    }));

    const classes = useStyles();


    const onDragStart = (ev, id) => {
        console.log('dragstart:', id);
        ev.dataTransfer.setData("id", id);
    }

    const onDragOver = (ev) => {
        ev.preventDefault();
    }

    const onDrop = (ev, cat) => {
        let id = ev.dataTransfer.getData("id");
        let mustHaveMoreThanSix = false;
        if (cat === 'mustHave' && cardsContainer[cat].length > 5) {
            alert('Not more than 6');
            mustHaveMoreThanSix = true;
        }
        let cards = cardsContent.filter((card) => {
            if (card.content === 'Other:' ? (card.category + card.content) === id : card.content === id) {
                if (cat === 'mustHave' || cat === 'niceToHave')
                    card.pos = 'relative';
                else
                    card.pos = 'absolute';
                if (mustHaveMoreThanSix)
                    card.category = 'niceToHave';
                else
                    card.category = cat;
            }
            return card;
        });

        setcardsContent(cards)
        console.log(cardsContent);
    }

    cardsContent.forEach((t, i) => {
        cardsContainer[t.category].push(
            <div className="draggable tct-scrollable"
                key={t.content}
                onDragStart={(e) => onDragStart(e, t.content === 'Other:' ? t.category + t.content : t.content)}
                draggable
                style={{ position: t.pos, width: boxWidth, height: boxHeight, backgroundColor: t.bgColor }}
            >
                <div style={{ fontSize: textSize }}>
                    {t.content}
                </div>
                <div className='tct-input'>
                    {/* <Input
                        style={{ fontSize: textSize, height: '12px' }}
                        value={engagementData[t.content] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: t.content, content: event.target.value })
                        }} /> */}
                    <TextField
                        style={{ width: '90%', height: '12px', margin:'0 3%' }}
                        className={classes.root}
                        value={engagementData[t.content] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: t.content, content: event.target.value })
                        }}
                        multiline
                    />
                </div>
            </div>
        );
    });


    return (
        <div className='container-drag' style={{ width, height, backgroundColor: '#4abdac' }}>
            <div style={{ width: '99%', height: '10%', marginLeft: '1%', color: 'white', fontSize: titleSize, display: 'flex', alignItems: 'center' }}>
                {data.title}
            </div>
            <div style={{ width: '100%', height: '89%', marginBottom: '1%', display: 'flex' }}>
                <div className='cards-container'>
                    <div style={{ width: '100%', height: '31%' }}>
                        <div style={{ width: '100%', height: '85%', textAlign: 'center' }}>
                            <img src={left_img} style={{ width: '50%', height: '100%' }} alt='left_img' />
                        </div>
                        <div style={{ width: '100%', height: '15%', fontSize: textSize_2 }}>Select what you want:</div>
                    </div>
                    <div style={{ width: '100%', height: '23%', marginTop: '3%' }}>
                        <div className="task-header" style={{ fontSize: textSize, height: '20%' }}>1.Transactional Compensation</div>
                        <div className="card-block"
                            onDragOver={(e) => onDragOver(e)}
                            onDrop={(e) => { onDrop(e, "transactional") }}>
                            {cardsContainer.transactional}
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '23%' }}>
                        <div className="task-header" style={{ fontSize: textSize, height: '20%' }}>2.Contribution Compensation</div>
                        <div className="card-block"
                            onDragOver={(e) => onDragOver(e)}
                            onDrop={(e) => onDrop(e, "contribution")}>
                            {cardsContainer.contribution}
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '23%' }}>
                        <div className="task-header" style={{ fontSize: textSize, height: '20%' }}>3.Fulfillment Compensation</div>
                        <div className="card-block"
                            onDragOver={(e) => onDragOver(e)}
                            onDrop={(e) => onDrop(e, "fulfillment")}>
                            {cardsContainer.fulfillment}
                        </div>
                    </div>
                </div>
                <div className='drop-container'>
                    <div style={{ width: '100%', height: '50%', border: '1px dashed', padding: '1%', position: 'relative' }}>
                        <div className="task-header" style={{ fontSize: textSize, height: '5%' }}>Must haves:</div>
                        <div className="task-header tct-bg-text" style={{ fontSize: textSize_2, display: cardsContainer.mustHave.length > 0 ? 'none' : 'block' }}>{data.mustHaveBgText}</div>
                        <div className="drop-block tct-scrollable" style={{ height: '95%' }}
                            onDragOver={(e) => onDragOver(e)}
                            onDrop={(e) => { onDrop(e, "mustHave") }}>
                            {cardsContainer.mustHave}
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '50%', border: '1px dashed', padding: '1%', borderTop: '1px transparent', position: 'relative' }}>
                        <div className="task-header" style={{ fontSize: textSize, height: '5%' }}>Nice to haves:</div>
                        <div className="task-header tct-bg-text" style={{ fontSize: textSize_2, display: cardsContainer.niceToHave.length > 0 ? 'none' : 'block' }}>{data.niceToHaveBgText}</div>
                        <div className="drop-block tct-scrollable" style={{ height: '95%' }}
                            onDragOver={(e) => onDragOver(e)}
                            onDrop={(e) => onDrop(e, "niceToHave")}>
                            {cardsContainer.niceToHave}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}