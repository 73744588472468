import React from 'react';
import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
import { Droppable, Draggable } from 'react-beautiful-dnd';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './style.css'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default ({ tabData, engagementData, manageToolData, data }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // const handleChangeIndex = (index) => {
    //     setValue(index);
    // };

    console.log(tabData)

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"
                        aria-label="full width tabs example"
                    >
                        {
                            tabData && Object.keys(tabData).map((tabTitle, index) => <Tab key={tabTitle} style={{ minWidth: '100%' }} label={tabTitle} {...a11yProps(index)} />)
                        }
                    </Tabs>
                </AppBar>
                {/* <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                    className='tabcontent-container'
                > */}
                {
                    tabData && Object.keys(tabData).map((tabTitle, index) =>
                        <TabPanel key={tabTitle + index} value={value} index={index} dir={theme.direction} style={{ height: 'calc(100% - 48px)' }} >
                            <Droppable droppableId={tabTitle}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        className='tct-scrollable-darkgray overFlowYAuto w-100 h-100'
                                    >
                                        {
                                            engagementData[data.toolName] && engagementData[data.toolName][tabTitle] && engagementData[data.toolName][tabTitle].map((tabContent, index) =>
                                                <Draggable
                                                    key={tabContent}
                                                    draggableId={tabContent}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className='multi-value-map-text-container'
                                                        // style={{ ...provided.draggableProps.style, position: 'static' }}
                                                        >
                                                            {tabContent}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </TabPanel>)
                }
                {/* </SwipeableViews> */}
            </div>
        </div>
    );
}