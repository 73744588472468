import React from 'react';
// import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, currentUnit }) => {

    const textBoxWidth = ['30%', '30%', '41%', '68%'];
    const textBoxHeight = ['21.5%', '21.5%', '4%', '16%'];
    const top = ['22%', '22%', '56%', '68%']
    const right = ['42%', '4%', '17%', '4%'];

    let descriptionSize = '12px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '18px';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((textBoxId, i) => {
                        if (textBoxId !== 'empty') return <textarea key={textBoxId} id={textBoxId} placeholder='Fill in...'
                            className='transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll font-style-italic'
                            style={{ fontSize: descriptionSize, position: 'absolute', width: textBoxWidth[i], height: textBoxHeight[i], top: top[i], right: right[i] }}
                            value={engagementData[data.id] ? engagementData[data.id][textBoxId] ? engagementData[data.id][textBoxId] : '' : ''}
                            onChange={(event) => manageToolData({
                                toolId: data.id, content: {
                                    ...engagementData[data.id],
                                    [textBoxId]: event.target.value
                                }
                            })}
                        />
                        return null;
                    }
                    )}
            </div>
        </div >
    );
}