import React from 'react';
import Modal_Component from '../../../Tools/Modal_Component';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let descriptionSize = '11px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '14px';
    }

    const popUpRight = ['70.5%', '37.5%', '8%'];
    const right = ['67%', '37.5%', '8%'];
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.popUp_content.map((item, i) => {
                        return <div key={i} style={{ width: '17%', height: '20%', position: 'absolute', top: '30%', right: popUpRight[i] }}>
                            <Modal_Component icon={item.icon}
                                type='img'
                                src={item.src}
                                width={width}
                                height={height}
                            />
                        </div>
                    })
                }
                {
                    data.textBoxId.map((id, i) => <textarea key={id} id={data.textBoxId[i]} placeholder={`Fill in for story part ${i + 1}`}
                        className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ width: '25%', height: '26%', fontSize: descriptionSize, position: 'absolute', top: '68%', right: right[i] }}
                        value={engagementData[data.textBoxId[i]] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: data.textBoxId[i], content: event.currentTarget.value })
                        }}
                    />)
                }
            </div>
        </div>
    );
}