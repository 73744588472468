import React, { useState, useEffect } from 'react';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button, Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputField from '../../../containers/singleAuth/Auth/Input.js';
import { checkAccesscode, editAccountDetails } from "../../../containers/auth/redux_actions";
import store from '../../../Redux_Store';
import DropDown from '../../../containers/singleAuth/Auth/DropDown.js'
import SeachableDropDown from '../../../containers/singleAuth/Auth/SeachableDropDown.js'
let AccoutDetails = ({ user, setPage, enableRegister, registerStatus, }) => {

    let { phone, email, fullname, accessCodeList, meta, _id } = user
    const [error, setError] = useState({});
    const [values, setValues] = useState({ phone, email, fullname });
    let [editValues, setEditValues] = useState({ phone, email, fullname, meta })
    const [disabled, setDisabled] = useState(true)
    const [orgDetails, setOrgDetails] = useState({})
    const [expanded, setExpanded] = useState(null);

    useEffect(() => {
        let getDetails = () => {
            accessCodeList.forEach(accessCode => {
                checkAccesscode(accessCode)
                    .then((data) => {
                        setOrgDetails(x => ({ ...x, [accessCode]: data }))
                    })
                    .catch(err => console.log(err))
            });
        }
        if (accessCodeList?.length > 0 && !Object.keys(orgDetails || {})?.length > 0) {
            getDetails()
        }
    }, [accessCodeList])


    const handleChange = (event, field) => {
        setEditValues((oldValue) => ({ ...oldValue, [field]: event.target.value }));
    };


    const handleFieldChange = (event, metaKey, value, orgMeta) => {
        // setValues((oldValue) => ({
        //     ...oldValue,
        //     meta: {
        //         ...oldValue?.meta,
        //         [metaKey]: value,
        //     },
        // }));
        setEditValues((oldValue) => ({
            ...oldValue,
            meta: {
                ...oldValue?.meta,
                [orgMeta]: {
                    ...oldValue?.meta?.[orgMeta],
                    [metaKey]: value
                }

            }
        }))
        enableRegister(true);
    };

    const getField = (field, value, orgMeta) => {
        if (registerStatus && field.mandatory && !values?.meta?.[field.metaKey]) {
            enableRegister(false);
        }
        switch (field.fieldType) {
            case "dropdown":
                return (
                    <DropDown
                        options={field.values}
                        label={field.fieldName}
                        value={value}
                        disabled={disabled}
                        onChange={(event) =>
                            handleFieldChange(event, field.metaKey, event.target.value, orgMeta)
                        }
                    />
                );
            case "searchable_dropdown":
                return (
                    <SeachableDropDown
                        disabled={disabled}
                        options={field.values}
                        getOptionLabel={(option) => option}
                        onChange={(event, value) =>
                            handleFieldChange(event, field.metaKey, value, orgMeta)
                        }
                        label={field.fieldName}
                    />
                );

            case "fixed":
                if (!values?.meta?.[field.metaKey])
                    handleFieldChange(undefined, field.metaKey, field.values[0]);
            default:
                return (
                    <InputField
                        type="Text"
                        field={field.metaKey}
                        values={
                            field.fieldType == "fixed"
                                ? { [field.metaKey]: field.values[0] }
                                : { [field.metaKey]: value }
                        }
                        disabled={disabled || field.fieldType == "fixed"}
                        placeHolder={field.fieldName}
                        handleChange={(event) =>
                            handleFieldChange(event, field.metaKey, event.target.value, orgMeta)
                        }
                    />
                );
        }
    };

    return <>
        <div
            style={{ display: 'flex', alignItems: 'center', color: '#484848', fontWeight: 400, cursor: 'pointer', fontSize: '0.9rem' }}
            onClick={() => setPage('default')}
        >
            <ArrowBackIosOutlinedIcon onClick={() => setPage('default')} style={{ cursor: 'pointer', marginRight: "1.5%", width: "0.7em", fontSize: '0.9rem' }} />Back
        </div>
        <div style={{ fontWeight: 500, fontSize: '1.5rem', display: 'flex', justifyContent: 'space-between', width: '40%' }}>
            My account details
            <Button
                style={{ textTransform: 'capitalize', border: '1px solid' }}
                onClick={() => { setDisabled(false) }}> Edit Details </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center', paddingRight: '3vw' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1vh', width: '48%' }}>
                <InputField
                    type="Text"
                    field="phone"
                    key={"phone"}
                    values={editValues}
                    placeHolder="Enter 10-digit Mobile number"
                    disabled={true}
                />
                <InputField
                    type="Text"
                    key={"email"}
                    field="email"
                    values={editValues}
                    placeHolder="Your email id"
                    handleChange={handleChange}
                    required
                    disabled={disabled}
                    error={error}
                />
                <InputField
                    type="Text"
                    field="fullname"
                    key={"fullname"}
                    values={editValues}
                    placeHolder="Your full name"
                    handleChange={handleChange}
                    required
                    disabled={disabled}
                    error={error}
                />
            </div>
            <div style={{ width: '48%', display: 'flex', flexDirection: 'column', gap: '5vh', padding: '10px 0px' }}>
                {Object.keys(orgDetails || {}).map((accesscode, i) => {
                    let orgDetail = orgDetails[accesscode]
                    let orgMeta = orgDetail?.meta_name
                    if ((orgDetail?.fields || []).length > 0)
                        return <Accordion
                            id={`${orgMeta}-1-${i}`}
                            key={`${orgMeta}-1-${i}`}
                            expanded={expanded === orgMeta} onChange={() => {
                                // if (!disabled)
                                    expanded == orgMeta ? setExpanded(null) : setExpanded(orgMeta)
                            }}
                            style={disabled ? { border: '1px solid #c4c4c4', color: '#c4c4c4' } : {}} className='accordionRoot' >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography >{orgDetail?.title || ''}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: 'column' }}>
                                {(orgDetail?.fields || []).map((field, j) => {
                                    let value = editValues?.meta?.[orgMeta]?.[field?.metaKey]
                                    return <div
                                        key={`eachField+${j}`}
                                        id={`eachField+${j}`}
                                    >
                                        {getField(field, value, orgMeta)}
                                    </div>
                                })}
                            </AccordionDetails>
                        </Accordion>
                })}

            </div>

        </div>
        {
            !disabled && <div style={{ paddingRight: '3vw', display: 'flex', justifyContent: 'center', marginTop: '5vh' }}>
                <Button
                    onClick={() => {
                        editAccountDetails(_id, editValues);
                        store.dispatch({ type: 'UPDATE_META', payload: editValues })
                    }}
                    style={{ width: 'max-content', textTransform: 'capitalize', padding: '16px', backgroundColor: '#FFBC36', borderRadius: '20px' }}>
                    Confirm Changes
                </Button>
            </div>
        }


    </>
}

export default AccoutDetails;


