import React, { useEffect } from 'react';
import './style.css'
export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    useEffect(() => {
        if (!engagementData || !engagementData[data.textBoxId]) {
            let temp = {};
            for (let i = 0; i < 4; i++) {
                temp[i] = {}
                temp[i]['level'] = 1
                temp[i][`textBox`] = ''
            }
            console.log(temp)
            manageToolData({ toolId: data.textBoxId, content: temp })
        }
    }, [data.textBoxId])

    let descriptionSize = '12px';
    let subTitleSize = '34px';
    if (isFullScreen) {
        subTitleSize = '48px'
        descriptionSize = '16px';
    }

    const addTextBox = () => {
        let len = Object.keys(engagementData[data.textBoxId]).length;
        manageToolData({
            toolId: data.textBoxId,
            content: {
                ...engagementData[data.textBoxId],
                [len]: {
                    'level': 1,
                    'textBox': ''
                }
            }
        });
    }

    const postTextBox = () => {
        if (!engagementData || !engagementData[data.textBoxId])
            return null;
        return Object.keys(engagementData && engagementData[data.textBoxId]).map(i => {
            return <div key={i} className='ImgSym_Ladder_Of_Active_Sympathy_textBox_content box-shadow'>
                <div className='roboto' style={{ height: '15%', width: '100%', fontSize: descriptionSize, fontWeight: 'bold' }}>CONCRETE ACTION {parseInt(i) + 1} :</div>
                <textarea id={data.textBoxId + '_' + i} placeholder='Fill more…'
                    className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ fontSize: descriptionSize, width: '100%', height: '75%' }}
                    value={engagementData[data.textBoxId][i].textBox || ''}
                    onChange={(event) => {
                        engagementData[data.textBoxId][i] = { textBox: event.currentTarget.value, level: engagementData[data.textBoxId][i].level }
                        manageToolData({ toolId: data.textBoxId, content: engagementData[data.textBoxId] })
                    }}
                />
                <div className='ImgSym_Ladder_Of_Active_Sympathy_level'>
                    <div className='roboto' style={{ width: '30%', fontSize: descriptionSize, fontWeight: 'bold' }}>LEVEL : </div>
                    <div className='ImgSym_Ladder_Of_Active_Sympathy_level_container'>
                        {
                            [1, 2, 3].map(item => <div key={engagementData[data.textBoxId][i].level + item}
                                className='ImgSym_Ladder_Of_Active_Sympathy_level_box'
                                style={{ fontSize: descriptionSize, backgroundColor: engagementData[data.textBoxId][i].level === item ? '#92D050' : 'white' }}
                                onClick={() => {
                                    engagementData[data.textBoxId][i] = { textBox: engagementData[data.textBoxId][i].textBox, level: item }
                                    manageToolData({ toolId: data.textBoxId, content: engagementData[data.textBoxId] })
                                }}
                            >
                                {item}
                            </div>)
                        }
                    </div>
                </div>

            </div>
        })
    }

    // const immediateTextBoxRight = ['66.5%', '38%', '10%'];
    // const furtherTextBoxRight = ['75%', '61%', '46%', '32%', '18%', '4.5%'];

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className='tct-scrollable-darkgray ImgSym_Ladder_Of_Active_Sympathy_textBox_container'>
                    {postTextBox()}
                </div>
                <div style={{
                    position: 'absolute',
                    top: '86%',
                    right: '37.5%'
                }}>
                    <div className='button-click-effect' style={{ fontSize: subTitleSize, fontWeight: 'bold' }}
                        onClick={addTextBox}>&#43;
                            </div>
                </div>
            </div>
        </div>
    );
}