import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LeftNav from "../../../components/LeftNav";
import ExploreDashboard from "../../../components/App_ExploreDashboard";
// import IntroScreen from "../../../components/App_ExploreDashboard/introScreen"
// import { getUserAttempt } from "../redux_actions";

import "./exploreDashboard.css";

// let userid, token
class exploreDashboard extends Component {

  // state = {
  //   flag: 0
  // }

  // async componentDidMount() {
  //   userid = this.props.auth.user_uid;
  //   token = this.props.auth.user_token;
  //   this.setState({ flag: this.props.auth.prepareFlag });
  //   if (this.state.flag === "0" || this.state.flag === 0) {
  //     await this.props.getUserAttempt({ userid, token }).then(result => {
  //       this.setState({ flag: this.props.app.prepareFlag });
  //     })
  //   }
  // }
  render() {
    return (
      <div className={"dashboard-cntr"}>
        {/* {this.state.flag === "0" || this.state.flag === 0 ?
          <IntroScreen user_token={token} userId={userid} />
          :
          <div className={"dashboard-cntr"}> */}
        <LeftNav />
        <ExploreDashboard />
        {/* </div>} */}
      </div>
    );
  }
}
exploreDashboard.propTypes = {
  authMsg: PropTypes.string
};

const mapStateToProps = state => ({
  auth: state.auth,
  app: state.app
});

// const mapDispatchToProps = dispatch => {
//   return {
//     getUserAttempt: data => dispatch(getUserAttempt(data))
//   }
// }


// export default connect(mapStateToProps, mapDispatchToProps)(exploreDashboard);
export default connect(mapStateToProps, null)(exploreDashboard);