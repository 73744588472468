import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "lodash";
import './styles.css';
import { v4 } from 'uuid';
import Button from '@material-ui/core/Button';
import EdiText from 'react-editext';

import logo from './Group 3140.svg'

import TextField from '@material-ui/core/TextField';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import { studentResponseDelete } from '../../containers/application/redux_actions';
export default ({ width, height, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId }) => {

  const responses = useSelector(state => state.app.response[data.engagement]);
  const [search, setSearch] = useState('')

  const [text, setText] = useState('')
  // const [dragApp, setDragApp] = useState({

  //   //columns
  //   "First2": {
  //     title: "Ideal2",   //key
  //     item:
  //       responses && Object.keys(responses).map((r) =>
  //         ({ id: v4(), text: r, user: responses[r].map((u) => u.user), tags: responses[r].map((u) => u.tags) })),

  //   },

  //   "Second2": {
  //     title: "On sense of ownership and initiative.....",
  //     item: []
  //   },
  //   "Third2": {
  //     title: "On approach to the potential of people.....",
  //     item: []
  //   },
  //   "Four2": {
  //     title: "On the way they operate (e.g in design mode).....",
  //     item: []
  //   },
  //   "Five2": {
  //     title: "On goal orientation.....",
  //     item: []
  //   },
  //   "Six2": {
  //     title: "On challenging status quo.....",
  //     item: []
  //   },
  //   "Seven2": {
  //     title: "On the approach towards available resources.....",
  //     item: []
  //   },
  //   "Eight2": {
  //     title: "Any other",
  //     item: []
  //   }

  // })

  useEffect(() => {
    let tempItem = responses ? Object.keys(responses).map(r => {
      return ({ id: v4(), text: r, user: responses[r].map((u) => u.user), tags: responses[r].map((u) => u.tags) })
    }) : []
    let prevFilltemp = engagementData && engagementData[data.prevFilledIdeaBoard] ? engagementData[data.prevFilledIdeaBoard]['Second1']['item'] : [];
    if (!engagementData || !engagementData[data.sessionType]) {
      manageToolData({
        toolId: data.sessionType, content: {
          "First2": {
            title: "Idea2",   //key
            item: [
              ...prevFilltemp,
              ...tempItem
            ]
          },
          "Second2": {
            title: "On sense of ownership & initiative",
            item: []
          },
          "Third2": {
            title: "On approach to the potential of people",
            item: []
          },
          "Four2": {
            title: "On the way one operate (e.g in design mode)",
            item: []
          },
          "Five2": {
            title: "On goal orientation (short term and long term)",
            item: []
          },
          "Six2": {
            title: "On approach to the current reality",
            item: []
          },
          "Seven2": {
            title: "On the approach to available material resources",
            item: []
          },
        
          "Eight2": {
            title: "On the approach to challenges",
            item: []
          },
          "nine2": {
            title: "Any other",
            item: []
          },
        }
      })
    }
    else {

      let prevFilltemp = engagementData && engagementData[data.prevFilledIdeaBoard] ? engagementData[data.prevFilledIdeaBoard]['Second1']['item'] : [];

      const set = new Set();
      const filteredArr = [...engagementData[data.sessionType]['First2'].item, ...prevFilltemp, ...tempItem].filter(el => {
          const duplicate = set.has(el.id);
          set.add(el.id);
          return !duplicate;
      });

      const filteredArr_2 = filteredArr.filter(el => {
        // console.log([...engagementData[data.sessionType]['Second2'].item, ...engagementData[data.sessionType]['Third2'].item, ...engagementData[data.sessionType]['Four2'].item, ...engagementData[data.sessionType]['Five2'].item, ...engagementData[data.sessionType]['Six2'].item, ...engagementData[data.sessionType]['Seven2'].item, ...engagementData[data.sessionType]['Eight2'].item])
        if (_.filter([...engagementData[data.sessionType]['Second2'].item, ...engagementData[data.sessionType]['Third2'].item, ...engagementData[data.sessionType]['Four2'].item, ...engagementData[data.sessionType]['Five2'].item, ...engagementData[data.sessionType]['Six2'].item, ...engagementData[data.sessionType]['Seven2'].item, ...engagementData[data.sessionType]['Eight2'].item], _.matches(el)).length === 0) {
          return true;
        }
        return false;
      })

      console.log(filteredArr_2)

      manageToolData({
        toolId: data.sessionType, content: {
          ...engagementData[data.sessionType],
          "First2": {
            title: "Ideal2",   //key
            item: filteredArr_2
          }
        }
      })
      if (tempItem.length > 0) studentResponseDelete({ engId: data.engagement })
    }

    // setDragApp(
    //   {
    //     ...dragApp, "First2": {
    //       title: "Ideal2",   //key
    //       item:
    //         responses && Object.keys(responses).map((r) =>
    //           ({ id: v4(), text: r, user: responses[r].map((u) => u.user), tags: responses[r].map((u) => u.tags) })),
    //     }
    //   })
  }, [responses, data])



  // adding Teacher Inputs or Ideas 

  const handleChange = (e) => {

    setText(e.target.value)
  }
  const addItem = (e) => {
    if (text === "") {
      alert("Cannot add empty box")
      return
    }
    manageToolData({
      toolId: data.sessionType, content: {
        ...engagementData[data.sessionType],
        First2: {
          title: "Ideal2",
          item: [{
            id: v4(),
            text: text,
            user: ["teacher"],
            tags: []
          },
          ...engagementData[data.sessionType]['First2'].item || []
          ]
        }
      }
    })

    // setDragApp(prev => {
    //   let temp = prev.First2.item || []
    //   return {
    //     ...prev,
    //     First2: {
    //       title: "Ideal2",
    //       item: [{
    //         id: v4(),
    //         text: addtext,
    //         user: ["teacher"],
    //         tags: []

    //       },
    //       ...temp

    //       ]
    //     }
    //   }
    // }

    // )
    setText('')
  }


  // Editing Ideas 

  const handleSave = (key, e, newValue) => {

    // let index = dragApp[key].item.findIndex((i) => i.id === e.id)


    // if (dragApp[key] && dragApp[key].item) {


    //   setDragApp({ ...dragApp, [key]: { ...dragApp[key], item: [...dragApp[key].item.slice(0, index), { ...dragApp[key].item[index], text: newValue }, ...dragApp[key].item.slice(index + 1)] } })
    // }
    let index = engagementData[data.sessionType][key].item.findIndex((i) => i.id === e.id)
    if (engagementData[data.sessionType][key] && engagementData[data.sessionType][key].item) {
      manageToolData({
        toolId: data.sessionType,
        content: {
          ...engagementData[data.sessionType],
          [key]: {
            ...engagementData[data.sessionType][key],
            item: [
              ...engagementData[data.sessionType][key].item.slice(0, index),
              { ...engagementData[data.sessionType][key].item[index], text: newValue },
              ...engagementData[data.sessionType][key].item.slice(index + 1)
            ]
          }
        }
      })
    }
  }

  // Remove Ideas from list

  const handleRemove = (key, id) => {
    // let index = dragApp[key].item.findIndex((i) => i.id === id)


    // let items = dragApp[key].item.filter((i) => i.id !== id)

    // setDragApp({ ...dragApp, [key]: { ...dragApp[key], item: items } })
    let items = engagementData[data.sessionType][key].item.filter((i) => i.id !== id)
    manageToolData({
      toolId: data.sessionType,
      content: {
        ...engagementData[data.sessionType],
        [key]: {
          ...engagementData[data.sessionType][key],
          item: items
        }
      }
    })

  }



  // handling drag & drop 

  const handleDragEnd = ({ destination, source }) => {



    if (!destination) {
      return
    }
    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return
    }
    // const itemCopy = { ...dragApp[source.droppableId].item[source.index] }
    // setDragApp(prev => {
    //   prev = { ...prev }
    //   prev[source.droppableId].item.splice(source.index, 1)
    //   prev[destination.droppableId].item.splice(destination.index, 0, itemCopy)
    //   return prev
    // })
    const itemCopy = { ...engagementData[data.sessionType][source.droppableId].item[source.index] }
    let temp = { ...engagementData[data.sessionType] }
    temp[source.droppableId].item.splice(source.index, 1)
    temp[destination.droppableId].item.splice(destination.index, 0, itemCopy)
    manageToolData({ toolId: data.sessionType, content: temp })

  }


  return (
    <div style={{ width, height, display: 'flex' }}>
      {/* logo image */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <div style={{ width: '30%', height: '100%', padding: '1%', paddingTop: '0' }}>
          <div style={{ width: '105%', height: '10%', position: "relative", right: "3%", bottom: "2%" }}>
            <img src={logo} className='w-100' style={{ height: '150%' }} />
          </div>
          <div style={{ display: "flex", width: '100%', height: '15%', marginTop: '7%', alignItems: 'center' }}>

            <h4 style={{ width: '100%', color: "orange" }}> Categorize the insights we thought of into the aspects shown here </h4>
          </div>
          <div style={{ width: '100%', height: '10%', display: 'flex', alignItems: 'center' }}>
            <TextField type="text"
              placeholder="Search..."
              variant="outlined"
              size="small"
              style={{width:"90%"}}
              onChange={(e) => { setSearch(e.target.value) }} />


          </div>
          <div className='tct-scrollable-darkgray' style={{ width: '100%', height: '54.6%', overflowY: 'auto' }}>
            {/* Here we need to put put first key */}


            <Droppable droppableId={'First2'}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className='w-100 h-100'
                  >
                    {
                      engagementData && engagementData[data.sessionType] && engagementData[data.sessionType]['First2'] && engagementData[data.sessionType]['First2'].item && engagementData[data.sessionType]['First2'].item.filter((el) => {

                        // if (data.title !== "Ideal") {
                        //   return el
                        // }
                        // console.log(el.user)
                        let user = el.user.map((u) => {
                          return u.toLowerCase()
                        })

                        // let _tags = el.tags.flat()
                        // let tag = _tags.map((t) => {
                        //   return t.toLowerCase()
                        // })

                        // if (search == "" || el.text.includes(search.toLocaleLowerCase()) || user.toString().includes(search.toLocaleLowerCase()) || tag.toString().includes(search.toLocaleLowerCase())) {
                        //   return el
                        // }
                        if (search === "" || el.text.includes(search.toLocaleLowerCase()) || user.toString().includes(search.toLocaleLowerCase())) {
                          return el
                        }
                      })
                        .map((el, index) => {

                          return (

                            <Draggable key={el.id} index={index} draggableId={el.id}>

                              {(provided) => {

                                return (
                                  <div
                                    className={"item1"}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >

                                    <span className="ideas">
                                      {/* Editing Ideas  */}
                                      <div style={{ display: "flex" }}>
                                        <div className="user">{el.user}</div>
                                        <span className="delete">
                                          {/* Removing Ideas from list */}

                                          <DeleteForeverIcon
                                            fontSize="small"
                                            onClick={() => handleRemove('First2', el.id)}></DeleteForeverIcon>
                                        </span>
                                      </div>
                                      <div className="ed">
                                        <EdiText
                                        viewContainerClassName='my-custom-view-wrapper0'
                                              type='textarea'
                                              inputProps={{
                                                rows: 5
                                              }}
                                              hideIcons
                                           
                                              submitOnUnfocus
                                              editOnViewClick
                                              value={el.text}
                                           
                                          onSave={(newValue) => handleSave('First2', el, newValue)} ></EdiText>

                                        {/* <span>{el.user}</span>
                                  <span>{el.tags}</span> */}

                                      </div>
                                    </span>

                                  </div>

                                )
                              }}
                            </Draggable>
                          )
                        })}
                    {provided.placeholder}
                  </div>
                )
              }}
            </Droppable>

          </div>
          <div style={{ width: '105%', height: '8%', display: "flex", boxShadow: '0px -3px 6px #00000029', position: "relative", right: "3%" }}>
            {/* teacher text box     */}

            <TextField
              style={{ width: '100%', marginLeft: '2%',marginTop:"2%" }}
              type="text"
              placeholder="add..."
              variant="outlined"
              size="small"
              value={text}
              onChange={handleChange} />
            <Button color="secondary" onClick={addItem} >
              <SendIcon fontSize='small' style={{marginTop:"2%"}}/>
            </Button>
          </div>
        </div>


        {/* End of Left Panel */}
        <div style={{ backgroundColor: '#4375c1', padding: '2%', width: '70%', height: '100%' }}>
          <div style={{ width: '100%', height: '15%' }}>

            <h2 style={{ color: "white" }}> Which of these aspects of a <span style={{ color: "orange", display: 'inline' }}>solution thinker</span></h2>
            <h2 style={{ color: "white" }}> did we think of ? </h2>
          </div>
          {/* {console.log(engagementData[data.sessionType])} */}

          {_.map(engagementData[data.sessionType], (data, key) => {
            if (key === 'First2') return
            return (
              <div className={key} key={key} >

                <div className="title">{data.title}</div>

                <Droppable key={key} droppableId={key}>
                  {(provided) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="drag_boxes tct-scrollable-darkgray overFlowYAuto"
                      >
                        {
                          data && data.item && data.item
                            .map((el, index) => {
                              return (
                                <Draggable key={el.id} index={index} draggableId={el.id}>

                                  {(provided) => {

                                    return (
                                      <div
                                        className={"itemre"}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <span className="ideasre">
                                          {/* Editing Ideas  */}
                                          {/* <div style={{ display: "flex" }}>
                                            <div className="user">{el.user}</div>
                                           
                                          </div> */}
                                          <div className="edR">
                                          <span className="delete">
                                              {/* Removing Ideas from list */}

                                              <DeleteForeverIcon
                                                fontSize="small"
                                                onClick={() => handleRemove(key, el.id)}></DeleteForeverIcon>
                                            </span>
                                            <EdiText
                                             viewContainerClassName='my-custom-view-wrapper0'
                                             type='textarea'
                                             inputProps={{
                                               rows: 5
                                             }}
                                             hideIcons
                                           
                                             submitOnUnfocus
                                             editOnViewClick
                                             value={el.text}
                                          

                                              onSave={(newValue) => handleSave(key, el, newValue)} ></EdiText>

                                            {/* <span>{el.user}</span>
                                  <span>{el.tags}</span> */}
                                            
                                          </div>
                                        </span>
                                      </div>

                                    )
                                  }}
                                </Draggable>


                              )
                            })}

                        {provided.placeholder}
                      </div>
                    )
                  }}
                </Droppable>
              </div>
            )
          })}
          <img src={"https://pedgog2.s3.ap-south-1.amazonaws.com/Sample+engagements/ST-Illumine+School/TS.png"} className="bottum_image2" />
          {/* </DragDropContext> */}

        </div>

      </DragDropContext>


    </div>
  );
}