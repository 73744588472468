import React from 'react';
import Modal_Component from '../../../Tools/Modal_Component';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    // let descriptionSize = '11px';

    let clickIns_top = '23%';
    let clickIns_right = '6%';

    if (isFullScreen) {
        // descriptionSize = '14px';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div style={{ width: '80%', height: '33%', position: 'absolute', display: 'flex', justifyContent: 'space-between', top: clickIns_top, right: clickIns_right }}>
                    {
                        data.popUp_content.map((item, i) => {
                            return <div key={i} style={{ width: '33%', height: '100%' }}>
                                <Modal_Component icon={item.icon}
                                    type='img'
                                    src={item.src}
                                    width={width}
                                    height={height}
                                />
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    );
}