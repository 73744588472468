import React from 'react';

import Arrow from '../../../components/images/Tools-img/Creator_of_Destiny/arrow.png'

export default ({ engagementData, manageToolData, isFullScreen, data }) => {

    let textSize = '10px';
    let textSize_2 = '8.8px';
    const type = [
        { text: 'Immediate', bg: '#f7c5a3' }, { text: 'Medium', bg: '#f9d3b9' }, { text: 'Long', bg: '#fbe2d1' }
    ];

    if (isFullScreen) {
        textSize = '14px';
        textSize_2 = '12.8px';
    }

    const postTextBoxes = (user, type) => {
        return Object.keys(engagementData && engagementData['COD_AB_Actions']
            && engagementData['COD_AB_Actions'][user] && engagementData['COD_AB_Actions'][user][type]
            ? engagementData['COD_AB_Actions'][user][type] : {}).map(i => {
                return <div key={i} className='roboto' style={{ width: '48%', height: '45%', backgroundColor: 'white', display: 'flex', margin: '0.5%' }}>
                    <textarea id={engagementData['COD_AB_Actions'][user][type][i].textBox}
                        className={`COD_AB_Actions_textbox roboto tct-scrollable`}
                        style={{ fontSize: textSize, width: '90%' }}
                        readOnly
                        value={engagementData['COD_AB_Actions'][user][type][i].textBox || ''}
                        onChange={(event) => {
                            engagementData['COD_AB_Actions'][user][type][i] = { textBox: event.currentTarget.value, effect: engagementData['COD_AB_Actions'][user][type][i].effect }
                            manageToolData({ toolId: 'COD_AB_Actions', content: engagementData['COD_AB_Actions'] })
                        }}
                        placeholder='Fill here...' />
                    <div style={{ width: '10%', height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <div className='COD_AB_Actions_plus_minus'
                            style={{ backgroundColor: '#c1330b', fontSize: textSize_2, width: '63%', height: '60%' }}
                        >{engagementData['COD_AB_Actions'][user][type][i].effect === 'positive' ? <span>&#43;</span> : <span>&#8722;</span>}
                        </div>
                    </div>
                </div>
            })
    }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                <div style={{ width: '45%', height: '100%', marginLeft: '3%', position: 'relative' }}>
                    <div style={{ width: '10%', height: '100%', left: '-7%', position: 'absolute' }}>
                        <img src={Arrow} style={{ width: '100%', height: '100%' }} alt='arrow' />
                    </div>
                    {
                        data.AB_content.map((item, i) => <div key={i} style={{ width: '100%', height: '33%', backgroundColor: type[i].bg, padding: '2%' }}>
                            <div className='roboto' style={{ width: '100%', height: '25%', fontSize: textSize }}>
                                <span style={{ fontWeight: 'bold' }}>{item.value}</span><br />
                            </div>
                            <div style={{ width: '100%', height: '75%', fontSize: textSize, display: 'flex' }}>
                                <div className='roboto tct-scrollable' style={{ width: '95%', height: '100%', fontSize: textSize, display: 'flex', flexWrap: 'wrap', overflowY: 'auto' }}>
                                    {postTextBoxes(data.userName[0], type[i].text)}
                                </div>
                            </div>
                        </div>)
                    }
                </div>
                <div style={{ width: '45%', height: '100%', marginLeft: '3%', position: 'relative' }}>
                    <div style={{ width: '10%', height: '100%', left: '-7%', position: 'absolute' }}>
                        <img src={Arrow} style={{ width: '100%', height: '100%' }} alt='arrow' />
                    </div>
                    {
                        data.AB_content.map((item, i) => <div key={i} style={{ width: '100%', height: '33%', backgroundColor: type[i].bg, padding: '2%' }}>
                            <div className='roboto' style={{ width: '100%', height: '20%', fontSize: textSize }}>
                                <span style={{ fontWeight: 'bold' }}>{item.value}</span><br />
                            </div>
                            <div style={{ width: '100%', height: '80%', fontSize: textSize, display: 'flex' }}>
                                <div className='roboto tct-scrollable' style={{ width: '95%', height: '100%', fontSize: textSize, display: 'flex', flexWrap: 'wrap', overflowY: 'auto' }}>
                                    {postTextBoxes(data.userName[1], type[i].text)}
                                </div>
                            </div>
                        </div>)
                    }
                </div>
            </div>

        </div>
    );
}