import React from "react";
import TextEditor from '../../Tools/TextEditor/TextEditor'
import level_1 from '../../components/images/Tools-img/Aspiration/level_1.png'
import question_image from '../../components/images/Tools-img/Aspiration/question_image.png'
import pencil from '../../components/images/Tools-img/pencil_2.png'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {


    let textSize_1 = '12px';
    let textSize_2 = '16px';

    if (isFullScreen) {
        textSize_1 = '17px';
        textSize_2 = '23px';
    }
    const displayTextBox = (i) => {
        return (
            <div style={{ borderRadius: '24px', display: 'flex', flexGrow: 1, flexDirection: 'column', width: '100%', padding: '10px', backgroundColor: "#DBEEF4", margin: '5px', border: '3px solid white' }}>
                <strong style={{ fontSize: textSize_1, height: '30%' }}>
                    {data.toolQuestion[i]}
                </strong>
                <div style={{ fontSize: textSize_1, height: '50%', display: 'flex' }}>
                    <TextEditor id={data.textBoxId[i]} initialData={engagementData[data.textBoxId[i]] || null}
                        manageToolData={manageToolData} placeholder='Fill here'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        textEditorClass={!isFullScreen ? 'trust-surplus-textbox-height' : false}
                        scrollable='tct-scrollable-darkgray'
                    />
                </div>
            </div>
        )
    }

    return <div style={{ width, height }}>
        <div style={{ height: '20%', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <img src={level_1} style={{ width: '20%' }} alt='leve_1' />
            <div style={{ padding: '14px', width: '40%', textAlign: 'center', display: 'flex', alignItems: 'center', fontSize: textSize_2, fontWeight: 'bold', color: '#E64708' }}>{data.topQuestion}</div>
            <img src={question_image} style={{ width: '30%' }} alt='question_image' />
        </div>
        <div style={{ display: "flex", backgroundColor: '#70BDD2', height: '80%' }}>
            <div className='tct-scrollable-darkgray' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: '10px', width: '30%', height: '100%', overflowY: 'auto' }}>
                <div style={{ color: 'white', fontWeight: '600' }}>
                    <div style={{ color: 'white', fontSize: textSize_1 }}>{data.leftInfoTitle}</div>
                    <div style={{ color: 'white', fontSize: textSize_1 }}>{data.leftInfo}</div>
                </div>
                <div style={{ padding: '15px', borderRadius: '16px', backgroundColor: "#FFFF99", position: 'relative', }}>
                    <img src={pencil} style={{
                        position: 'absolute', width: '35px',
                        height: '20%',
                        top: '-30px',
                        right: '-5px'
                    }} alt="pencil" />
                    <div style={{ fontWeight: 'bold', fontSize: textSize_1 }}>{data.leftBoardQuestion}</div>
                    {
                        data.leftBoardAnswer.map((ans, i) => <div key={i} style={{ fontSize: textSize_1 }}>&#9679;&nbsp;&nbsp;&nbsp;{ans}</div>)
                    }
                </div>
            </div>
            <div style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '100%', height: '50%', display: 'flex' }}>
                    {displayTextBox(0)}
                    {displayTextBox(1)}
                </div>
                <div style={{ width: '100%', height: '50%', display: 'flex' }}>
                    {displayTextBox(2)}
                    {displayTextBox(3)}
                </div>
            </div>
        </div>
    </div >
}