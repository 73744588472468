import React from 'react';
import BackIcon from './assets/back.svg';

import {
  useSelector
  // , useDispatch
} from 'react-redux';

import { addFormValues } from '../../containers/application/redux_actions';
import { convertFromRaw } from 'draft-js';
import Switch from '@material-ui/core/Switch';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom';

let TopNavigation = (props) => {

  const app = useSelector(state => state.app);
  const auth = useSelector(state => state.auth);
  // const dispatch = useDispatch();
  let { isHindi, toggleLanguage, data, units, currentUnit, currentScene } = props
  let slides = units && units[currentUnit] && units[currentUnit].activeContent
    ? units[currentUnit].activeContent
    : {};

  const convertEditorTypeData = () => {
    let convertedData = {};
    Object.keys(app.engagementData).map((textBox, i) => {
      convertedData[textBox] = app.engagementData[textBox].blocks ? convertFromRaw(app.engagementData[textBox]).getPlainText() : app.engagementData[textBox];
    })
    return convertedData;
  }

  const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Object]';
  };

  const toolDataSave = () => {
    let sessionId = app.createdSession.id;
    let user_token = auth.user_token;
    let fieldData = convertEditorTypeData();
    addFormValues({ sessionId, user_token, fieldData })
  }

  const handleClick = () => {
    app.engagementData && Object.keys(app.engagementData).length !== 0 && toolDataSave();
    // return <Redirect to='/application' />
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <div style={{ paddingLeft: '1.4rem', color: '#fff', backgroundColor: '#0b1e3d', width: '100%', display: 'flex', alignItems: 'center' }}>
    {/* <div style={{ width: '20px', height: '20px' }}>
      <Modal_Component
        type='tool-component'
        icon={BackIcon}
        Component={
          <div style={{ width: '50px', height: '50px', backgroundColor: 'white' }}>
            <div>
              Are you sure?
            </div>
            <a href="javascript:history.back()" onClick={handleClick}> Yes</a>
            <div>No</div>
          </div>
        }
      />
    </div> */}
    <img src={BackIcon} onClick={handleClickOpen} alt='Back-icon' />

    <Dialog
      open={open}
      onClose={handleClose}

    >
      <DialogTitle>Are you sure?</DialogTitle>
      {/* <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Let Google help apps determine location. This means sending anonymous location data to
          Google, even when no apps are running.
        </DialogContentText>
      </DialogContent> */}
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          No
        </Button>
        <Link to='/application' onClick={handleClick} color="primary">
          Yes
        </Link>
      </DialogActions>
    </Dialog>
    <div style={{ padding: '1rem' }}> Conduct Zone | {data.description} </div>
    {slides[currentScene] && slides[currentScene].url && isObject(slides[currentScene].url) ?
      <div color="primary" style={{ textAlign: "end", padding: "0rem 2.5rem", marginLeft: 'auto' }}>
        <span>A</span>
        <Switch color="primary" checked={isHindi} onChange={toggleLanguage} name="language" inputProps={{ 'aria-label': 'secondary checkbox' }} />
        <span>अ</span>
      </div> : ""}
  </div >
}

export default TopNavigation;
