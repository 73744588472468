import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

import { CheckBoxOutlined, CheckBoxOutlineBlank } from '@material-ui/icons';

import { Radio } from '@material-ui/core';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let descriptionSize = '12px';
    let heightSize = '25px';
    // let checkbox_top = data.id === "tool-slide-52" ? '53%' : data.id === "tool-slide-54" ? '56%' : data.id === "tool-slide-56" ? '48%' : '50%';
    // let checkbox_right = data.id === 'tool-slide-52' ? '2%' : data.id === "tool-slide-54" ? '10%' : data.id === "tool-slide-56" ? '10%' : '10%';
    let checkbox_top = data.id === "tool-slide-52" ? '56%' : data.id === "tool-slide-54" ? '59%' : data.id === "tool-slide-56" ? '50%' : '52%';
    let checkbox_right = data.id === 'tool-slide-52' ? '24.5%' : data.id === "tool-slide-54" ? '32%' : data.id === "tool-slide-56" ? '32%' : '32%';

    if (isFullScreen) {
        descriptionSize = '18px';
        heightSize = '31px';
        // checkbox_top = data.id === 'tool-slide-52' ? '56%' : data.id === "tool-slide-54" ? '59%' : data.id === "tool-slide-56" ? '50%' : '53%';
        // checkbox_right = data.id === 'tool-slide-52' ? '10.5%' : data.id === "tool-slide-54" ? '15.5%' : data.id === "tool-slide-56" ? '16.5%' : '17.5%';
        checkbox_top = data.id === 'tool-slide-52' ? '57%' : data.id === "tool-slide-54" ? '60%' : data.id === "tool-slide-56" ? '51%' : '53%';
        checkbox_right = data.id === 'tool-slide-52' ? '31%' : data.id === "tool-slide-54" ? '37.5%' : data.id === "tool-slide-56" ? '38%' : '37.5%';
    }


    const useStyles = makeStyles((theme) => ({
        label: {
            '& .MuiTypography-root': {
                fontSize: descriptionSize
            }
        }
    }));

    const classes = useStyles();

    const checkboxValue = engagementData[data.checkBoxId[0]] ? data.checkBoxId[0] : engagementData[data.checkBoxId[1]] ? data.checkBoxId[1] : '';
    const [value, setValue] = React.useState(checkboxValue);

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div style={{ position: 'absolute', top: checkbox_top, right: checkbox_right }}>
                    <FormControl className={classes.label} component="fieldset">
                        <RadioGroup value={value}
                            onChange={(event) => {
                                setValue(event.target.value);
                                manageToolData({ toolId: event.target.value, content: true })
                                if (event.target.value !== data.checkBoxId[0])
                                    manageToolData({ toolId: data.checkBoxId[0], content: false })
                                else if (event.target.value !== data.checkBoxId[1])
                                    manageToolData({ toolId: data.checkBoxId[1], content: false })
                            }}>
                            <FormControlLabel
                                style={{ fontSize: descriptionSize, height: heightSize }}
                                value={data.checkBoxId[0]}
                                control={
                                    <Radio
                                        checkedIcon={<CheckBoxOutlined style={{ color: 'black', backgroundColor: 'white' }} fontSize={isFullScreen ? "large" : "medium"} />}
                                        icon={<CheckBoxOutlineBlank style={{ backgroundColor: 'white' }} fontSize={isFullScreen ? "large" : "medium"} />}
                                    />
                                }
                            // label={data.checkBoxLabel[0]}
                            />
                            <FormControlLabel
                                style={{ fontSize: descriptionSize }}
                                value={data.checkBoxId[1]}
                                control={
                                    <Radio
                                        checkedIcon={<CheckBoxOutlined style={{ color: 'black', backgroundColor: 'white' }} fontSize={isFullScreen ? "large" : "medium"} />}
                                        icon={<CheckBoxOutlineBlank style={{ backgroundColor: 'white' }} fontSize={isFullScreen ? "large" : "medium"} />}
                                    />
                                }
                            // label={data.checkBoxLabel[1]}
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </div>
    );
}