import React, { useCallback, useMemo } from "react";
import { useDropzone } from 'react-dropzone';

const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    color: '#1F1F1F',
    transition: 'border .3s ease-in-out',
    lineHeight: "28px",
    fontSize: "18px",
    cursor: "pointer",
    background: "#F2F2F2 0% 0% no-repeat padding-box",
    border: "2px dashed #B5B5B5",
    borderRadius: "18px"
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export default ({ children, files, setFiles, accept }) => {
    const onDrop = useCallback(acceptedFiles => {
        setFiles(acceptedFiles)
    }, []);

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop, accept, multiple: false
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {children}
        </div>
    )
}