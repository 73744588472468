import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "lodash";
import './styles.css';
import { v4 } from 'uuid';
import Button from '@material-ui/core/Button';
import EdiText from 'react-editext';
import logo from './Group 3140.svg'
import Start_N_Stop from '../Start_N_Stop'
import TextField from '@material-ui/core/TextField';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import Menu from '@material-ui/core/Menu';
import plus from './Group 3460.svg'
import Modal_Component from '../../Tools/Modal_Component'
import { studentResponseDelete } from '../../containers/application/redux_actions';
export default ({ width, height, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId }) => {

  const responses = useSelector(state => state.app.response[data.engagement]);
  const [search, setSearch] = useState('')
  const uniqueStudentsName = useSelector(state => state.app.uniqueStudentsName[data.engagement]);
  console.log(uniqueStudentsName)
  const students = useSelector(state => state.app.uniqueStudentsName[data.engagement]);
  const respo_count= useSelector(state => state.app.responseCount[data.engagement]);
  console.log(respo_count)
 
  const [text, setText] = useState('')
  // const [dragApp, setDragApp] = useState({

  //   //columns
  //   "First3": {
  //     title: "Ideal3",   //key
  //     item:
  //       responses && Object.keys(responses).map((r) =>
  //         ({ id: v4(), text: r, user: responses[r].map((u) => u.user), tags: responses[r].map((u) => u.tags) })),
  //   },

  //   "Second3": {
  //     title: "Alternative 1",
  //     item: []
  //   },
  //   "Third3": {
  //     title: "Alternative 2",
  //     item: []
  //   },
  //   "Four3": {
  //     title: "Alternative 3",
  //     item: []
  //   },
  // })
  useEffect(() => {
    let tempItem = responses ? Object.keys(responses).map(r => {
      return ({ id: v4(), text: r, user: responses[r].map((u) => u.user), tags: responses[r].map((u) => u.tags) })
    }) : []
    console.log(engagementData[data.sessionType])
    if (!engagementData[data.sessionType]) {
      manageToolData({
        toolId: data.sessionType, content: {
          "First3": {
            title: "Ideal",   //key
            item: tempItem
          },
          "Second3": {
            title: "Alternative 1",
            item: []
          },
          "Third3": {
            title: "Alternative 2",
            item: []
          },
          "Four3": {
            title: "Alternative 3",
            item: []
          },
        }
      })
    }
    else {
      manageToolData({
        toolId: data.sessionType, content: {
          ...engagementData[data.sessionType],
          First3: {
            title: "Ideal",   //key
            item: [...engagementData[data.sessionType]['First3'].item, ...tempItem]
          },
        }
      })
      console.log('done')
      if (tempItem.length > 0) studentResponseDelete({ engId: data.engagement })
    }
    // setDragApp(
    //  {...dragApp, "First3":{
    //   title:"Ideal3",   //key
    //   item:
    //   responses && Object.keys(responses).map((r) => 
    //     ({id: v4(), text: r, user: responses[r].map((u)=>u.user),tags: responses[r].map((u)=>u.tags)})), 
    // }})
  }, [data, responses])

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // adding Teacher Inputs or Ideas 

  const handleChange = (e) => {

    setText(e.target.value)
  }
  const addItem = (e) => {
    if (text === "") {
      alert("Cannot add empty box")
      return
    }

    manageToolData({
      toolId: data.sessionType, content: {
        ...engagementData[data.sessionType],
        First3: {
          title: "Ideal3",
          item: [{
            id: v4(),
            text: text,
            user: ["teacher"],
            tags: []
          },
          ...engagementData[data.sessionType]['First3'].item || []
          ]
        }
      }
    })

    // setDragApp(prev => {
    //   let temp = prev.First2.item || []
    //   return {
    //     ...prev,
    //     First3: {
    //       title: "Ideal3",
    //       item: [{
    //         id: v4(),
    //         text: addtext,
    //         user: ["teacher"],
    //         tags: []

    //       },
    //       ...temp

    //       ]
    //     }
    //   }
    // }

    // )
    setText('')
  }


  // Editing Ideas 

  const handleSave = (key, e, newValue) => {
    // console.log('Edited Value -> ', e)
    // let index = dragApp[key].item.findIndex((i) => i.id === e.id)
    // console.log(index)

    // if (dragApp[key] && dragApp[key].item) {
    //   console.log("updating")
    //   console.log(dragApp[key])
    //   setDragApp({ ...dragApp, [key]: { ...dragApp[key], item: [...dragApp[key].item.slice(0, index), { ...dragApp[key].item[index], text: newValue }, ...dragApp[key].item.slice(index + 1)] } })
    // }
    let index = engagementData[data.sessionType][key].item.findIndex((i) => i.id === e.id)
    if (engagementData[data.sessionType][key] && engagementData[data.sessionType][key].item) {
      manageToolData({
        toolId: data.sessionType,
        content: {
          ...engagementData[data.sessionType],
          [key]: {
            ...engagementData[data.sessionType][key],
            item: [
              ...engagementData[data.sessionType][key].item.slice(0, index),
              { ...engagementData[data.sessionType][key].item[index], text: newValue },
              ...engagementData[data.sessionType][key].item.slice(index + 1)
            ]
          }
        }
      })
    }
  }

  // Remove Ideas from list

  const handleRemove = (key, id) => {
    // let index = dragApp[key].item.findIndex((i) => i.id === id)
    // console.log(index)
    // console.log(id)
    // let items = dragApp[key].item.filter((i) => i.id !== id)
    // console.log(items)
    // setDragApp({ ...dragApp, [key]: { ...dragApp[key], item: items } })
    let items = engagementData[data.sessionType][key].item.filter((i) => i.id !== id)
    manageToolData({
      toolId: data.sessionType,
      content: {
        ...engagementData[data.sessionType],
        [key]: {
          ...engagementData[data.sessionType][key],
          item: items
        }
      }
    })

  }



  // handling drag & drop 

  const handleDragEnd = ({ destination, source }) => {

    if (!destination) {
      return
    }
    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return
    }
    // const itemCopy = { ...dragApp[source.droppableId].item[source.index] }
    // setDragApp(prev => {
    //   prev = { ...prev }
    //   prev[source.droppableId].item.splice(source.index, 1)
    //   prev[destination.droppableId].item.splice(destination.index, 0, itemCopy)
    //   return prev
    // })
    const itemCopy = { ...engagementData[data.sessionType][source.droppableId].item[source.index] }
    let temp = { ...engagementData[data.sessionType] }
    temp[source.droppableId].item.splice(source.index, 1)
    temp[destination.droppableId].item.splice(destination.index, 0, itemCopy)
    manageToolData({ toolId: data.sessionType, content: temp })

  }
  return (
    <div style={{ width, height, display: 'flex' }}>
      {/* logo image */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <div style={{ width: '30%', height: '100%', padding: '1%', paddingTop: '0' }}>
          <div style={{ width: '100%', height: '10%' }}>
            <img src={logo} className='w-100' style={{ height: '130%' }} />
          </div>
          <div style={{ display: "flex", width: '100%', height: '10%', marginTop: '7%', alignItems: 'center' }}>
            <div style={{ width: '20%', height: '100%' }}>
              <img src={'https://pedgog2.s3.ap-south-1.amazonaws.com/Sample+engagements/ST-Illumine+School/bulb.png'} className='w-100 h-100' />
            </div>
            <h4 style={{ width: '80%', color: "orange" }}> Share your insights </h4>
          </div>
          <div style={{ width: '100%', height: '10%', display: 'flex', alignItems: 'center' }}>
            <TextField type="text"
              placeholder="Search..."
              variant="outlined"
              size="small"
              onChange={(e) => { setSearch(e.target.value) }} />
            {/* teacher text box     */}

          
          </div>
          <div className='tct-scrollable-darkgray' style={{ width: '100%', height: '58.5%', overflowY: 'auto' }}>
            {/* Here we need to put put first key */}


            <Droppable droppableId={'First3'}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className='w-100 h-100'
                  >
                    {
                      engagementData && engagementData[data.sessionType] && engagementData[data.sessionType]['First3'] && engagementData[data.sessionType]['First3'].item && engagementData[data.sessionType]['First3'].item.filter((el) => {

                       
                        let user = el.user.map((u) => {
                          return u.toLowerCase()
                        })

                        
                        if (search === "" || el.text.includes(search.toLocaleLowerCase()) || user.toString().includes(search.toLocaleLowerCase())) {
                          return el
                        }
                        
                      })
                        .map((el, index) => {

                          return (

                            <Draggable key={el.id} index={index} draggableId={el.id}>

                              {(provided) => {

                                return (
                                  <div
                                    className={"item1"}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >

                                    <span className="ideas">
                                      {/* Editing Ideas  */}
                                      <div style={{ display: "flex" }}>
                                        <div className="user">{el.user}</div>
                                        <span className="delete">
                                          {/* Removing Ideas from list */}

                                          <DeleteForeverIcon
                                            fontSize="small"
                                            onClick={() => handleRemove('First3', el.id)}></DeleteForeverIcon>
                                        </span>
                                      </div>
                                      <div className="ed">
                                      <EdiText
                                          viewContainerClassName='my-custom-view-wrapper0'
                                          type='textarea'
                                          inputProps={{
                                            rows: 5
                                          }}
                                          hideIcons
                                          cancelOnEscape
                                          submitOnUnfocus
                                          editOnViewClick
                                          
                                          value={el.text}
                                        

                                          onSave={(newValue) => handleSave('First3', el, newValue)} ></EdiText>


                                        {/* <span>{el.user}</span>
                                  <span>{el.tags}</span> */}

                                      </div>
                                    </span>

                                  </div>

                                )
                              }}
                            </Draggable>
                          )
                        })}
                    {provided.placeholder}
                  </div>
                )
              }}
            </Droppable>

          </div>
         
          <div style={{ width: '100%', height: '8%', margin: 'auto',display:'flex',boxShadow: 'rgb(0 0 0 / 16%) 0px -3px 6px' }}>
            {/* Here we need to put the start and stop button */}
            <div style={{width:'15%',height:'90%'}}>
            <Button 
      className='arrowbutton'
      size='small'
      aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <div className="plusimg">
      <img src={plus}  style={{height:'60px',position:'relative',top:'10px'}}/>
      </div>
      </Button>
      <Menu
      className='popup'
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div style={{display:'flex',width:'100%',height:'100%'}}>
       <TextField
              style={{ width: '100%', marginLeft: '2%' }}
              type="text"
              placeholder="add..."
              variant="outlined"
              size="small"
              value={text}
              onChange={handleChange} />
            <Button color="secondary" onClick={addItem} >
              <SendIcon fontSize='small' style={{marginTop:"2%"}}/>
            </Button>
            </div>
      </Menu>
            </div>
            <div className='s&s'>
            <Start_N_Stop
              engagementData={engagementData}
              manageToolData={manageToolData}
              data={data}
              isFullScreen={isFullScreen}
              engagementStatus={engagementStatus}
              sessionId={sessionId}
            />
        
            </div>
          {/* <div>
          <span style={{marginRight:'10px'}}>{student_reponse} total nubers</span>
          </div> */}
          <div className='sudentresp'
          // style={{lineHeight:studentRespondedSize, marginTop:'9%'}}
          >
                        <div  
                        // style={{ fontSize: noOfStudentsSize }}
                        >{students && students.length ? students.length : 0}</div>
                        <div 
                        style={{fontSize:'60%'}}
                        // style={{ fontSize: studentRespondedSize, marginTop:'19%' }}
                        >Students </div>
                    </div> 
                    <div className='response'>
                      {respo_count && respo_count.length ? respo_count.length :0 }
                      <div style={{fontSize:'60%'}}>Responses</div>
                    </div>
          </div>
       
        </div>


        {/* End of Left Panel */}
        <div style={{ padding: '1% 0 1% 1%', width: '66%', height: '100%' }}>
          <div style={{ width: '100%', height: '8%', position: 'relative',left: '90%'}}> 
          <img src={' https://pedgog2.s3.ap-south-1.amazonaws.com/Sample+engagements/ST-Illumine+School/NEW+SLIDES/numbering.png'} className='step2 '/>
          </div>
          <div style={{ width: '100%', height: '25%' }}>
            <p style={{ fontWeight: "bolder" }}>End Goal</p>
            {data.prevTextBoxId && <textarea id={data.prevTextBoxId}
              className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll'}
              style={{ backgroundColor: '#ffe25a', width: '100%', height: '85%', overflowY: 'auto', textAlign: 'center', padding: '10px', fontSize: "16px", fontWeight: "bold", color: "#4375c1" }}
              value={engagementData[data.prevTextBoxId] || ''}
              readOnly
            />}
          </div>

          <div style={{ width: '100%', height: '65%', display: 'flex', marginTop: "3%" }}>
            {engagementData && engagementData[data.sessionType] && _.map(engagementData[data.sessionType], (data, key) => {
              if (key === 'First3') return
              return (
                <Droppable key={key} droppableId={key}>
                  {(provided) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className='width-33 h-100 tct-scrollable-darkgray overFlowYAuto ideaBoardPoll3-drop-conatiner box-shadow'
                      >
                        <div className='text-center'><h5> {data.title} </h5></div>
                        {
                          data && data.item && data.item
                            .map((el, index) => {
                              return (
                                <Draggable key={el.id} index={index} draggableId={el.id}>

                                  {(provided) => {

                                    return (
                                      <div
                                        className={"itemre"}

                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <span className="ideasre">
                                          {/* Editing Ideas  */}
                                          {/* <div style={{ display: "flex" }}>
                                            <div className="user">{el.user}</div>
                                           
                                          </div> */}
                                          <div className="edR">
                                          <span className="delete">
                                            

                                            <DeleteForeverIcon
                                              fontSize="small"
                                              onClick={() => handleRemove(key, el.id)}></DeleteForeverIcon>
                                          </span>
                                            <EdiText
                                              viewContainerClassName='my-custom-view-wrapper0'
                                              type='textarea'
                                              inputProps={{
                                                rows: 5
                                              }}
                                              hideIcons
                                              cancelOnEscape
                                              submitOnUnfocus
                                              editOnViewClick
                                              value={el.text}
                                           

                                              onSave={(newValue) => handleSave(key, el, newValue)} ></EdiText>

                                            {/* <span>{el.user}</span>
                                  <span>{el.tags}</span> */}
                                     
                                          </div>
                                        </span>
                                      </div>

                                    )
                                  }}
                                </Draggable>


                              )
                            })}

                        {provided.placeholder}
                      </div>
                    )
                  }}
                </Droppable>
              )
            })}

            {/* </DragDropContext> */}
          </div>
        </div>
        {console.log(engagementData)}
        <div style={{ width: '4%', height: '100%' }}>
          <div className='Think-sol-sp-step_1_case-popup'>
            <Modal_Component
              type={data.popUp_content[0].type}
              icon={data.popUp_content[0].icon}
              Component={<div className='Think-sol-sp-step_1_hint-popup-component tct-scrollable-darkgray'>{engagementData && engagementData[data.prevPopUpContent] ? engagementData[data.prevPopUpContent] : data.popUp_content[0].src}</div>}
              width={width}
              height={height}
            />
          </div>
          <div className='Think-sol-sp-step_1_hint-popup'>
            <Modal_Component
              type={data.popUp_content[1].type}
              icon={data.popUp_content[1].icon}
              Component={<div className='Think-sol-sp-step_1_hint-popup-component tct-scrollable-darkgray'>{data.popUp_content[1].src}</div>}
              width={width}
              height={height}
            />
          </div>
        </div>
      </DragDropContext>

    </div>
  );

  // return (
  //   <div style={{ width, height }}>
  //     <div style={{ width: '100%', height: '100%' }}>

  //       {/* logo image */}
  //       <img src={logo} style={{ width: '390px', height: '78px', position: "relative", right: "50px", bottom: "15px" }} />
  //       <div>
  //         <h4 className="goal"> 1. The End-goal: </h4>
  //       </div>
  //       <div className="hea_der3">
  //         <div style={{ display: "flex" }}>

  //         </div>




  //       </div>

  //       {/* drag & Drop  */}

  //       <div className="ideaBoard3">

  //         <DragDropContext onDragEnd={handleDragEnd}>

  //           {_.map(dragApp, (data, key) => {
  //             console.log(Object.keys(dragApp).indexOf(key))
  //             console.log(data)
  //             return (

  //               <div key={key} className={key}>

  //                 <div >

  //                   {Object.keys(dragApp).indexOf(key) == 0 ?
  //                     <div style={{ display: "flex", marginBottom: "55px" }}>
  //                       <span className="tex_t3"></span>

  //                     </div> : ''}

  //                 </div>
  //                 {Object.keys(dragApp).indexOf(key) > 0 && Object.keys(dragApp).indexOf(key) < 8 ?
  //                   <div className="title__header2"><h5 className="title3"> {data.title} </h5></div> : ''}
  //                 <Droppable droppableId={key}>
  //                   {(provided) => {
  //                     return (
  //                       <div style={{ marginLeft: "10px", height: "640px" }}>
  //                         { Object.keys(dragApp).indexOf(key) == 0 ?
  //                           <div>

  //                             <TextField type="text"
  //                               className='_search3'
  //                               placeholder="Search..."
  //                               variant="outlined"
  //                               size="small"
  //                               onChange={(e) => { setSearch_(e.target.value) }} />
  //                             {/* teacher text box     */}

  //                             <TextField
  //                               type="text"
  //                               className='textbg3'
  //                               placeholder="add..."
  //                               variant="outlined"
  //                               size="small"
  //                               value={addtext}
  //                               onChange={handle_Change_} />
  //                             <Button onClick={addItem_} style={{ marginLeft: "3px", backgroundColor: "green", color: "white" }}>
  //                               Add
  //                     </Button>



  //                           </div> : ''}

  //                         <div
  //                           ref={provided.innerRef}
  //                           {...provided.droppableProps}
  //                           className={data.title}


  //                         >
  //                           {console.log(data.title)}



  //                           {data && data.item && data.item.filter((el) => {



  //                             console.log(data.title)
  //                             if (data.title !== "Ideal3") {
  //                               return el
  //                             }
  //                             console.log(el)
  //                             let user = el.user.map((u) => {
  //                               return u.toLowerCase()
  //                             })
  //                             console.log(el.tags)
  //                             let _tags = el.tags.flat()
  //                             let tag = _tags.map((t) => {

  //                               // console.log(t)
  //                               return t.toLowerCase()
  //                             })




  //                             if (search_ == "" || el.text.includes(search_.toLocaleLowerCase()) || user.toString().includes(search_.toLocaleLowerCase()) || tag.toString().includes(search_.toLocaleLowerCase())) {
  //                               return el
  //                             }



  //                           })
  //                             .map((el, index) => {
  //                               console.log(el)
  //                               return (

  //                                 <Draggable key={el.id} index={index} draggableId={el.id}>

  //                                   {(provided) => {

  //                                     return (
  //                                       <div
  //                                         className={"item3"}
  //                                         ref={provided.innerRef}
  //                                         {...provided.draggableProps}
  //                                         {...provided.dragHandleProps}
  //                                       >

  //                                         {/* student profile pics  */}


  //                                         { Object.keys(dragApp).indexOf(key) == 0 ?
  //                                           <Avatar>{el.user[0][0]}</Avatar>
  //                                           : ''}

  //                                         <span className="ideas3">
  //                                           {/* Editing Ideas  */}
  //                                           <EdiText
  //                                             viewContainerClassName='my-custom-view-wrapper3'
  //                                             type='textarea'
  //                                             inputProps={{
  //                                               rows: 5
  //                                             }}
  //                                             saveButtonContent='Apply'
  //                                             cancelButtonContent={<strong>Cancel</strong>}
  //                                             editButtonClassName="custom-edit-button3"
  //                                             editButtonContent={<EditIcon size="small" />}
  //                                             value={el.text}
  //                                             onSave={(newValue) => handleSave(key, el, newValue)}
  //                                           />
  //                                           <span >
  //                                             {/* Removing Ideas from list */}
  //                                             <Button className="_delete3"

  //                                               onClick={() => handleRemove(key, el.id)}>
  //                                               <DeleteForeverIcon

  //                                                 style={{
  //                                                   fontSize: "medium"
  //                                                 }} /></Button>
  //                                           </span>
  //                                         </span>
  //                                       </div>

  //                                     )
  //                                   }}
  //                                 </Draggable>


  //                               )
  //                             })}

  //                           {provided.placeholder}

  //                         </div>
  //                       </div>
  //                     )
  //                   }}
  //                 </Droppable>
  //               </div>
  //             )
  //           })}

  //         </DragDropContext>


  //         <div>

  //         </div>
  //       </div>

  //     </div>

  //   </div >
  // );
}