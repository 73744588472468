import React from 'react';

import Arrow from '../../../components/images/Tools-img/Creator_of_Destiny/arrow.png'


export default ({ engagementData, manageToolData, isFullScreen, data, userName }) => {

    let textSize = '10px';
    let textSize_2 = '8.8px';
    const type = [
        { text: 'Immediate', bg: '#f7c5a3' }, { text: 'Medium', bg: '#f9d3b9' }, { text: 'Long', bg: '#fbe2d1' }
    ];

    if (isFullScreen) {
        textSize = '14px';
        textSize_2 = '12.8px';
    }

    const addTextBoxes = (user, type) => {
        if (!engagementData) { engagementData = {} }
        if (!engagementData['COD_AB_Actions']) { engagementData['COD_AB_Actions'] = {} }
        if (!engagementData['COD_AB_Actions'][user]) { engagementData['COD_AB_Actions'][user] = {} }
        if (!engagementData['COD_AB_Actions'][user][type]) { engagementData['COD_AB_Actions'][user][type] = {} }


        const len = Object.keys(engagementData['COD_AB_Actions'][user][type]).length;
        manageToolData({
            toolId: 'COD_AB_Actions',
            content: {
                ...engagementData['COD_AB_Actions'],
                [user]: {
                    ...engagementData['COD_AB_Actions'][user],
                    [type]: {
                        ...engagementData['COD_AB_Actions'][user][type],
                        [len]: {
                            textBox: '',
                            effect: 'positive'
                        }
                    }
                }
            }
        });
    }

    const changeEffect = (user, type, i, effect) => {
        manageToolData({
            toolId: 'COD_AB_Actions',
            content: {
                ...engagementData['COD_AB_Actions'],
                [user]: {
                    ...engagementData['COD_AB_Actions'][user],
                    [type]: {
                        ...engagementData['COD_AB_Actions'][user][type],
                        [i]: {
                            textBox: engagementData['COD_AB_Actions'][user][type][i].textBox,
                            effect
                        }
                    }
                }
            }
        });
    }


    const postTextBoxes = (user, type) => {
        return Object.keys(engagementData && engagementData['COD_AB_Actions']
            && engagementData['COD_AB_Actions'][user] && engagementData['COD_AB_Actions'][user][type]
            ? engagementData['COD_AB_Actions'][user][type] : {}).map(i => {
                return <div key={i} className='roboto' style={{ width: '48%', height: '45%', backgroundColor: 'white', display: 'flex', margin: '0.5%' }}>
                    <textarea id={engagementData['COD_AB_Actions'][user][type][i].textBox}
                        className={`COD_AB_Actions_textbox roboto tct-scrollable`}
                        style={{ fontSize: textSize }}
                        value={engagementData['COD_AB_Actions'][user][type][i].textBox || ''}
                        onChange={(event) => {
                            engagementData['COD_AB_Actions'][user][type][i] = { textBox: event.currentTarget.value, effect: engagementData['COD_AB_Actions'][user][type][i].effect }
                            manageToolData({ toolId: 'COD_AB_Actions', content: engagementData['COD_AB_Actions'] })
                        }}
                        placeholder='Fill here...' />
                    <div style={{ width: '20%', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                        <div className='button-anim COD_AB_Actions_plus_minus'
                            style={{ backgroundColor: engagementData['COD_AB_Actions'][user][type][i].effect === 'positive' ? '#c1330b' : '#ccc1da', fontSize: textSize_2 }}
                            onClick={() => changeEffect(user, type, i, 'positive')}
                        >&#43;</div>
                        <div className='button-anim COD_AB_Actions_plus_minus'
                            style={{ backgroundColor: engagementData['COD_AB_Actions'][user][type][i].effect === 'negative' ? '#c1330b' : '#ccc1da', fontSize: textSize_2 }}
                            onClick={() => changeEffect(user, type, i, 'negative')}
                        >&#8722;</div>
                    </div>
                </div>
            })
    }

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <div style={{ width: '10%', height: '100%', left: '-7%', position: 'absolute' }}>
                <img src={Arrow} style={{ width: '100%', height: '100%' }} alt='arrow' />
            </div>
            {
                data.AB_content.map((item, i) => <div key={i} style={{ width: '100%', height: '33%', backgroundColor: type[i].bg, padding: '2%' }}>
                    <div className='roboto' style={{ width: '100%', height: '30%', fontSize: textSize }}>
                        <span style={{ fontWeight: 'bold' }}>{item.value}</span><br />
                        {item.triggerQ}
                    </div>
                    <div style={{ width: '100%', height: '70%', fontSize: textSize, display: 'flex' }}>
                        <div className='roboto tct-scrollable' style={{ width: '85%', height: '100%', fontSize: textSize, display: 'flex', flexWrap: 'wrap', overflowY: 'auto' }}>
                            {postTextBoxes(userName, type[i].text)}
                        </div>
                        <div style={{ width: '15%', height: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            <div style={{ backgroundColor: '#c1330b', cursor: 'pointer', color: 'white', padding: '5%' }} onClick={() => addTextBoxes(userName, type[i].text)}>
                                ADD
                                </div>
                        </div>
                    </div>
                </div>)
            }
        </div>
    );
}