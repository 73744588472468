// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app";
const exchangeConfig = {
  apiKey: "AIzaSyAbRUjiODv61sHpQXXSng2AOINvEc30X_U",
  authDomain: "pedgog-data-exchange.firebaseapp.com",
  databaseURL: "https://pedgog-data-exchange-default-rtdb.firebaseio.com",
  projectId: "pedgog-data-exchange",
  storageBucket: "pedgog-data-exchange.appspot.com",
  messagingSenderId: "803694997881",
  appId: "1:803694997881:web:e23a89e05289e9476d3fa8",
};

const blogConfig = {
  apiKey: "AIzaSyD4ZFDF3YMoYrvtHu_g7Cc6lT6BaLLi41w",
  authDomain: "pedgog-services.firebaseapp.com",
  projectId: "pedgog-services",
  storageBucket: "pedgog-services.appspot.com",
  messagingSenderId: "32510255744",
  appId: "1:32510255744:web:57f7fe582e6742a2397c65",
  measurementId: "G-BB20K24NBM"
};

initializeApp(exchangeConfig, "exchange")
initializeApp(blogConfig, "blog")

const exchangeFirebase = getApp("exchange");
const blogFirebase = getApp("blog");

export { exchangeFirebase, blogFirebase };