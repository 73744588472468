import React from 'react';
// import TextEditor from '../../../Tools/TextEditor/TextEditor';

import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let descriptionSize = '11px';

    let textbox_1_top = data.id === 'tool-slide-COD_Creator_Thinking-Eg_1' ? '39.5%' : data.id === 'tool-slide-COD_Creator_Thinking-Eg_6' ? '41%' : '43%'
    let textbox_1_right = '3%';

    let textbox_2_top = data.id === 'tool-slide-COD_Creator_Thinking-Eg_1' ? '59.5%' : data.id === 'tool-slide-COD_Creator_Thinking-Eg_6' ? '60%' : '62%'
    let textbox_2_right = '3%';

    let textbox_3_top = data.id === 'tool-slide-COD_Creator_Thinking-Eg_1' ? '78%' : data.id === 'tool-slide-COD_Creator_Thinking-Eg_6' ? '79%' : '81%'
    let textbox_3_right = '3%';


    if (isFullScreen) {
        descriptionSize = '14px';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <textarea id={data.textBoxId[0]} value={engagementData[data.textBoxId[0]] || null}
                    className={`COD_Creator_Thinking_textBox roboto tct-scrollable`} style={{ fontSize: descriptionSize, top: textbox_1_top, right: textbox_1_right }}
                    onChange={(event) => {
                        manageToolData({ toolId: data.textBoxId[0], content: event.currentTarget.value })
                    }}
                    placeholder='Fill here for step 1…'
                />
                <textarea id={data.textBoxId[1]}
                    className={`COD_Creator_Thinking_textBox roboto tct-scrollable`} style={{ fontSize: descriptionSize, top: textbox_2_top, right: textbox_2_right }}
                    value={engagementData[data.textBoxId[1]] || null}
                    onChange={(event) => {
                        manageToolData({ toolId: data.textBoxId[1], content: event.currentTarget.value })
                    }}
                    placeholder='Fill here for step 2…'
                />
                <textarea id={data.textBoxId[2]}
                    className={`COD_Creator_Thinking_textBox roboto tct-scrollable`} style={{ fontSize: descriptionSize, top: textbox_3_top, right: textbox_3_right }}
                    value={engagementData[data.textBoxId[2]] || null}
                    onChange={(event) => {
                        manageToolData({ toolId: data.textBoxId[2], content: event.currentTarget.value })
                    }}
                    placeholder='Fill here for step 3…'
                />
            </div>
        </div>
    );
}