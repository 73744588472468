import React from 'react';
import TakeAway_Button from '../../../Tools/TakeAway_Button';
import TextEditor from '../../../Tools/TextEditor/TextEditor';
import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, currentUnit }) => {

    let descriptionSize = '10.5px';

    let container_bottom_margin = '6%';
    let container_height = '59%';

    if (isFullScreen) {
        descriptionSize = '16px';
        container_bottom_margin = '1%';
        container_height = '64%';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: 'cover', position: 'relative' }}>
                <div style={{ width: '73%', height: container_height, position: 'absolute', margin: `17% 14% ${container_bottom_margin}` }}>
                    <div style={{ width: '100%', display: 'flex', height: '22%', justifyContent: 'space-evenly' }}>
                        <div style={{ width: '30%', display: 'flex', fontSize: descriptionSize }}>
                            <TextEditor id={data.textBoxId[0]} initialData={engagementData[data.textBoxId[0]] || null}
                                manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.'
                                wrapperClass='text-editor-WIAC_Characteristics_of_a_person' />
                        </div>
                        <div style={{ width: '30%', display: 'flex', fontSize: descriptionSize }}>
                            <TextEditor id={data.textBoxId[1]} initialData={engagementData[data.textBoxId[1]] || null}
                                manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.'
                                wrapperClass='text-editor-WIAC_Characteristics_of_a_person' />
                        </div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', height: '55%' }}>
                        <div style={{ flexGrow: '1', width: '30%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', marginRight: '25px' }}>
                            <div style={{ display: 'flex', height: '40%', fontSize: descriptionSize }}>
                                <TextEditor id={data.textBoxId[2]} initialData={engagementData[data.textBoxId[2]] || null}
                                    manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.'
                                    wrapperClass='text-editor-WIAC_Characteristics_of_a_person' />
                            </div>
                            <div style={{ display: 'flex', height: '40%', fontSize: descriptionSize }}>
                                <TextEditor id={data.textBoxId[3]} initialData={engagementData[data.textBoxId[3]] || null}
                                    manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.'
                                    wrapperClass='text-editor-WIAC_Characteristics_of_a_person' />
                            </div>
                        </div>
                        <div style={{ width: '30%' }}>
                        </div>
                        <div style={{ flexGrow: '1', width: '30%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', marginRight: '25px' }}>
                            <div style={{ display: 'flex', height: '40%', fontSize: descriptionSize }}>
                                <TextEditor id={data.textBoxId[4]} initialData={engagementData[data.textBoxId[4]] || null}
                                    manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.'
                                    wrapperClass='text-editor-WIAC_Characteristics_of_a_person' />
                            </div>
                            <div style={{ display: 'flex', height: '40%', fontSize: descriptionSize }}>
                                <TextEditor id={data.textBoxId[5]} initialData={engagementData[data.textBoxId[5]] || null}
                                    manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.'
                                    wrapperClass='text-editor-WIAC_Characteristics_of_a_person' />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', height: '22%', justifyContent: 'space-evenly' }}>
                        <div style={{ width: '30%', display: 'flex', fontSize: descriptionSize }}>
                            <TextEditor id={data.textBoxId[6]} initialData={engagementData[data.textBoxId[6]] || null}
                                manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.'
                                wrapperClass='text-editor-WIAC_Characteristics_of_a_person' />
                        </div>
                        <div style={{ width: '30%', display: 'flex', fontSize: descriptionSize }}>
                            <TextEditor id={data.textBoxId[7]} initialData={engagementData[data.textBoxId[7]] || null}
                                manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.'
                                wrapperClass='text-editor-WIAC_Characteristics_of_a_person' />
                        </div>
                    </div>
                </div>
                <div style={{
                    position: "absolute", width: '59%',
                    height: '15%', top: '83%'
                }}>
                    <TakeAway_Button currentUnit={currentUnit} data={data} engagementData={engagementData} />
                </div>
            </div>
        </div >
    );
}