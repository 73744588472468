import React, { useEffect, useState } from 'react';
import { getDataFromFile } from '../../../containers/application/redux_actions';
import Add_Button_Positive_Outer_Fruit from '../../../components/images/Tools-img/TOF/Add_Button_Positive_Outer_Fruit.svg';
import Add_Button_Positive_Inner_Fruit from '../../../components/images/Tools-img/TOF/Add_Button_Positive_Inner_Fruit.svg';
import Add_Button_Positive_Contribution_Fruit from '../../../components/images/Tools-img/TOF/Add_Button_Positive_Contribution_Fruit.svg';
import Add_Button_Negative_Contribution_Fruit from '../../../components/images/Tools-img/TOF/Add_Button_Negative_Contribution_Fruit.svg';
import Add_Button_Negative_Outer_Fruit from '../../../components/images/Tools-img/TOF/Add_Button_Negative_Outer_Fruit.svg';
import Suggested_Fruits_Button from '../../../components/images/Tools-img/TOF/Suggested_Fruits_Button.svg';
import Modal_Component from '../../../Tools/Modal_Component';
import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    const [suggestedFruitsList, setSuggestedFruitsList] = useState('');
    // useEffect(() => {
    //     if (!engagementData || !engagementData[toolId] || Object.keys(engagementData[toolId]).length === 0) {
    //         let temp = {};
    //         Object.keys(data.preFilledFruits).forEach(typeOfFruit => {
    //             temp[typeOfFruit] = {};
    //             temp[typeOfFruit]['Positive'] = {};

    //             for (let i = 0; i < data.preFilledFruits[typeOfFruit].length + 3; i++) {

    //                 if (i < data.preFilledFruits[typeOfFruit].length) temp[typeOfFruit]['Positive'][`textBox_${i}`] = data.preFilledFruits[typeOfFruit][i];
    //                 else temp[typeOfFruit]['Positive'][`textBox_${i}`] = ''
    //             }

    //         });
    //         manageToolData({
    //             toolId: toolId,
    //             content: temp
    //         })
    //     }
    // }, [toolId])

    useEffect(() => {
        getDataFromFile({ url: data.suggestedFruitsUrl })
            .then((response) => setSuggestedFruitsList(response.suggestedFruits))
            .catch((err) => console.log(err))
    }, [data.suggestedFruitsUrl])


    let descriptionSize = '11px';
    let subTitleSize = '12px';
    let dotSize = '9px';
    if (isFullScreen) {
        subTitleSize = '16px'
        descriptionSize = '14px';
        dotSize = '13px';
    }

    const colorOfFruits = (typeOfFruit, typeOfReflection) => {
        let fruitColor = {
            backgroundColor: 'TOF-Negative-Fruit-texbox-background',
            dotColor: 'TOF-Negative-Fruit-texbox-dot'
        };
        if (typeOfFruit === 'Outer_Fruit' && typeOfReflection === 'Positive') {
            fruitColor.backgroundColor = 'TOF-Outer-Fruit-texbox-background'
            fruitColor.dotColor = 'TOF-Outer-Fruit-texbox-dot'
        }
        else if (typeOfFruit === 'Contribution_Fruit' && typeOfReflection === 'Positive') {
            fruitColor.backgroundColor = 'TOF-Contribution-Fruit-texbox-background'
            fruitColor.dotColor = 'TOF-Contribution-Fruit-texbox-dot'
        }
        else if (typeOfFruit === 'Inner_Fruit' && typeOfReflection === 'Positive') {
            fruitColor.backgroundColor = 'TOF-Inner-Fruit-texbox-background'
            fruitColor.dotColor = 'TOF-Inner-Fruit-texbox-dot'
        }
        return fruitColor;
    }

    const postTextBox = (typeOfFruit, typeOfReflection) => {
        return data.toolId.map((toolId) => {
            if (!engagementData || !engagementData[toolId] || !engagementData[toolId][typeOfFruit] || !engagementData[toolId][typeOfFruit][typeOfReflection] || Object.keys(engagementData[toolId][typeOfFruit][typeOfReflection]).length === 0)
                return null;
            return Object.keys(engagementData && engagementData[toolId] && engagementData[toolId][typeOfFruit] && engagementData[toolId][typeOfFruit][typeOfReflection] && engagementData[toolId][typeOfFruit][typeOfReflection]).map((i) => {
                const fruitColor = colorOfFruits(typeOfFruit, typeOfReflection);

                return <div key={typeOfFruit + typeOfReflection + i} className={`TOF-All-Fruit-texbox-container ${fruitColor.backgroundColor}`}>
                    <div className='flex-vertical-center' style={{ width: '15%' }}>
                        <div className={`TOF-Single-Fruit-texbox-dot ${fruitColor.dotColor}`} style={{ width: dotSize, height: dotSize }}></div>
                    </div>
                    <textarea id={engagementData[toolId][typeOfFruit][typeOfReflection][i]} placeholder='Fill here…'
                        className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ width: '85%', height: '100%', fontSize: descriptionSize, fontWeight: 'bold', padding: '7% 0%' }}
                        value={engagementData[toolId][typeOfFruit][typeOfReflection][i] || ''}
                        onChange={(event) => {
                            engagementData[toolId][typeOfFruit][typeOfReflection][i] = event.currentTarget.value;
                            manageToolData({ toolId: toolId, content: engagementData[toolId] })
                        }}
                    />
                </div>
            })
        })
    }

    const postSuggestedFruits = (typeOfFruit, typeOfReflection) => {
        const fruitColor = colorOfFruits(typeOfFruit, typeOfReflection);
        return suggestedFruitsList && suggestedFruitsList[typeOfFruit][typeOfReflection].map(item =>
            <div key={typeOfFruit + item} className={`TOF-All-Fruit-suggested-texbox-container ${fruitColor.backgroundColor}`}>
                <div className='flex-vertical-center' style={{ width: '11%' }} >
                    <div className={`TOF-Single-Fruit-texbox-dot ${fruitColor.dotColor}`} style={{ width: dotSize, height: dotSize }}></div>
                </div>
                <div style={{ width: '85%', height: '100%', fontSize: descriptionSize, fontWeight: 'bold' }}
                    className={'tct-scrollable-darkgray merriweather-sans flex-vertical-center'}>
                    {item}
                </div>
            </div>
        )
    }

    const suggestedFruits = (() => {

        return <div style={{ width: '100%', height: '100%', backgroundColor: 'white', borderRadius: '14px' }}>
            <div style={{ width: '100%', height: '32%', padding: '0% 1%', display: 'flex' }}>
                <div className='flex-center' style={{ width: '20%', fontSize: subTitleSize, textAlign: 'center' }}>
                    Outer Fruits
                </div>
                <div className='tct-scrollable-darkgray TOF-All-Fruit-container'>
                    {postSuggestedFruits('Outer_Fruit', 'Positive')}
                    {data.negativeFruits && postSuggestedFruits('Outer_Fruit', 'Negative')}
                </div>
            </div>
            <div style={{ width: '100%', height: '32%', padding: '0% 1%', display: 'flex' }}>
                <div className='flex-center' style={{ width: '20%', fontSize: subTitleSize, textAlign: 'center' }}>
                    Joy of Contribution
                </div>
                <div className='tct-scrollable-darkgray TOF-All-Fruit-container'>
                    {postSuggestedFruits('Contribution_Fruit', 'Positive')}
                    {data.negativeFruits && postSuggestedFruits('Contribution_Fruit', 'Negative')}
                </div>
            </div>
            <div style={{ width: '100%', height: '32%', padding: '0% 1%', display: 'flex' }}>
                <div className='flex-center' style={{ width: '20%', fontSize: subTitleSize, textAlign: 'center' }}>
                    Inner Fruits
                </div>
                <div className='tct-scrollable-darkgray TOF-All-Fruit-container'>
                    {postSuggestedFruits('Inner_Fruit', 'Positive')}
                    {data.negativeFruits && postSuggestedFruits('Inner_Fruit', 'Negative')}
                </div>
            </div>
        </div>
    })();

    const addTextBox = (typeOfFruit, typeOfReflection) => {
        let toolId = data.toolId[data.toolId.length - 1];
        if (!engagementData) { engagementData = {} }
        if (!engagementData[toolId]) { engagementData[toolId] = {} }
        if (!engagementData[toolId][typeOfFruit]) { engagementData[toolId][typeOfFruit] = {} }
        if (!engagementData[toolId][typeOfFruit][typeOfReflection]) { engagementData[toolId][typeOfFruit][typeOfReflection] = {} }

        let len = Object.keys(engagementData[toolId][typeOfFruit][typeOfReflection]).length;
        manageToolData({
            toolId: toolId,
            content: {
                ...engagementData[toolId],
                [typeOfFruit]: {
                    ...engagementData[toolId][typeOfFruit],
                    [typeOfReflection]: {
                        ...engagementData[toolId][typeOfFruit][typeOfReflection],
                        [`textBox_${len}`]: ''
                    }
                }
            }
        });
    }

    return (
        <div style={{ width, height, backgroundColor: 'white', borderRadius: '7px' }}>
            {
                data.title && <div className='merriweather-sans' style={{ width: '100%', height: '10%' }}>
                    {data.title}
                </div>
            }
            <div style={{ width: '100%', height: data.title ? '90%' : '100%', display: 'flex' }}>
                <div style={{ width: '13%', height: '100%' }}>
                    <img src={data.leftImg} style={{ width: '100%', height: '100%' }} />
                </div>
                <div style={{ width: '79%', height: '100%' }}>
                    <div style={{ width: '100%', height: '31%', display: 'flex', margin: '1%' }}>
                        <div style={{ width: '20%', height: '100%', textAlign: 'center' }}>
                            <div className='merriweather-sans' style={{ width: '100%', fontSize: subTitleSize }}>
                                Add Outer Fruits
                        </div>
                            <div style={{ width: '100%', height: '20%', margin: '8% 0%'}}>
                                <img src={Add_Button_Positive_Outer_Fruit}
                                    className='button-click-effect'
                                    style={{ height: '100%' }}
                                    onClick={() => addTextBox('Outer_Fruit', 'Positive')} />
                            </div>
                            {data.negativeFruits && <div style={{ width: '100%', height: '20%' }}>
                                <img src={Add_Button_Negative_Outer_Fruit}
                                    className='button-click-effect'
                                    style={{ height: '100%' }}
                                    onClick={() => addTextBox('Outer_Fruit', 'Negative')} />
                            </div>}

                        </div>
                        <div className='tct-scrollable-darkgray TOF-All-Fruit-container'>
                            {postTextBox('Outer_Fruit', 'Positive')}
                            {data.negativeFruits && postTextBox('Outer_Fruit', 'Negative')}
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '31%', display: 'flex', margin: '1%' }}>
                        <div style={{ width: '20%', height: '100%', textAlign: 'center' }}>
                            <div className='merriweather-sans' style={{ width: '100%', fontSize: subTitleSize }}>
                                Add Joys of Contribution
                        </div>
                            <div style={{ width: '100%', height: '20%', margin: '8% 0%' }}>
                                <img src={Add_Button_Positive_Contribution_Fruit}
                                    className='button-click-effect'
                                    style={{ height: '100%' }}
                                    onClick={() => addTextBox('Contribution_Fruit', 'Positive')} />
                            </div>
                            {data.negativeFruits && <div style={{ width: '100%', height: '28%' }}>
                                <img src={Add_Button_Negative_Contribution_Fruit}
                                    className='button-click-effect'
                                    style={{ height: '100%' }}
                                    onClick={() => addTextBox('Contribution_Fruit', 'Negative')} />
                            </div>}

                        </div>
                        <div className='tct-scrollable-darkgray TOF-All-Fruit-container'>
                            {postTextBox('Contribution_Fruit', 'Positive')}
                            {data.negativeFruits && postTextBox('Contribution_Fruit', 'Negative')}
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '31%', display: 'flex', margin: '1%' }}>
                        <div style={{ width: '20%', height: '100%', textAlign: 'center' }}>
                            <div className='merriweather-sans' style={{ width: '100%', fontSize: subTitleSize }}>
                                Add Inner Fruits
                        </div>
                            <div style={{ width: '100%', height: '20%', margin: '8% 0%' }}>
                                <img src={Add_Button_Positive_Inner_Fruit}
                                    className='button-click-effect'
                                    style={{ height: '100%' }}
                                    onClick={() => addTextBox('Inner_Fruit', 'Positive')} />
                            </div>
                            {data.negativeFruits && <div style={{ width: '100%', height: '20%' }}>
                                <img src={Add_Button_Negative_Outer_Fruit}
                                    className='button-click-effect'
                                    style={{ height: '100%' }}
                                    onClick={() => addTextBox('Inner_Fruit', 'Negative')} />
                            </div>}

                        </div>
                        <div className='tct-scrollable-darkgray TOF-All-Fruit-container'>
                            {postTextBox('Inner_Fruit', 'Positive')}
                            {data.negativeFruits && postTextBox('Inner_Fruit', 'Negative')}
                        </div>
                    </div>
                </div>
                <div style={{ width: '8%', height: '100%', display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '100%', height: '50%' }}>
                        <Modal_Component
                            type='other'
                            icon={Suggested_Fruits_Button}
                            src={suggestedFruits}
                            width={width}
                            height={height}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}