import React, { useEffect, useRef, useState } from 'react';
import Slider from '@material-ui/core/Slider';
import './style.css'
import {
    makeStyles,
} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    rail: {
        backgroundColor: '#FFFF00',
        height: 5,
        opacity: 1
    },
    verticalRail: {
        '& $rail': {
            width: 5
        },
    },
    thumb: {
        backgroundColor: "#376092",
        border: '2px solid white',
        width: 17,
        height: 17
    }
}));


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    const imgRef = useRef('')
    console.log(imgRef)
    const classes = useStyles();
    const [value, setValue] = useState({
        habit_1: 0,
        habit_2: 100,
        habit_3: 100,
        habit_4: 0,
        habit_5: 0,
        habit_6: 0

    })
    const dataSet = {
        Engineer_1: [
            {
                text: 'Hello world 1', bgColor: 'white'
            },
            {
                text: '1 world Hello', bgColor: 'darkgray'
            }
        ],
        Engineer_2: [
            {
                text: 'Hello world 2', bgColor: 'white'
            },
            {
                text: '2 world Hello', bgColor: 'darkgray'
            }
        ],
        Engineer_3: [
            {
                text: 'Hello world 3', bgColor: 'white'
            },
            {
                text: '3 world Hello', bgColor: 'darkgray'
            }
        ]
    }

    // "textData" : {
    //     "Engineer_1": [
    //         {
    //             text: 'He is thinking about just coping & managing within the situation', fontWeight: 'bold'
    //         },
    //         {
    //             text: 'We’re working as hard as we can, but there is too much to do. Get ready to work all night again...'
    //         }
    //     ],
    //         "Engineer_2": [
    //             {
    //                 text: 'He is getting stuck in the process or routine approaches', fontWeight: 'bold'
    //             },
    //             {
    //                 text: 'Troubleshooting procedures will take at least 2 days. We will have to ask the management for more resources…', bgColor: 'darkgray'
    //             }
    //         ],
    //             "Engineer_3": [
    //                 {
    //                     text: 'He is staying focused on the end-goal and figuring out the best way to get there. \n \n…He is showing Solution Thinking', fontWeight: 'bold' , fontColor:'red'
    //                 },
    //                 {
    //                     text: 'The final goal is that the customer should get data reports on time, to help them make a market decision. Why not find a faster approach to provide this data to them? We can reorganize our  efforts accordingly…'
    //                 }
    //             ]
    // }



    const [counter, setCounter] = useState();
    useEffect(() => {
        // Object.keys(dataSet).forEach(item => setCounter({ ...counter, [item]: 0 }))
        let tempCounter = {}
        Object.keys(dataSet).forEach(item => { tempCounter[item] = 0 })
        setCounter(tempCounter)
    }, [])

    console.log(counter)
    // const handleDoubleClick = (dataSet, item) => {
    //     // let count = counter[item];
    //     // setCounter((++count % dataSet[item].length));
    //     setCounter({ ...counter, [item]: ++counter[item] % dataSet[item].length });
    //     console.log(counter)
    // }
    // console.log(dataSet);
    // console.log(counter['Engineer_1'])
    // console.log(dataSet['Engineer_1'][counter['Engineer_1']])

    const handleChange = (prop, newValue) => {
        setValue({
            ...value,
            [prop]: newValue
        });
    };

    return (
        <div style={{ width, height }}>
            {/* <div style={{ backgroundImage: `url(${data.bgImg})`, height: '100%', backgroundSize: '40% 100%', backgroundRepeat: 'no-repeat', position: 'relative', backgroundPosition:'center' }}> */}
            {/* {counter && Object.keys(counter).map(item => <div key={item} style={{ backgroundColor: dataSet[item][counter[item]].bgColor }}>
                <div style={{ width: '300px', height: '100px' }} onDoubleClick={() => handleDoubleClick(dataSet, item)}>{dataSet[item][counter[item]].text}</div>
                {/* <div style={{ width: '300px', height: '100px' }} onDoubleClick={() => handleDoubleClick(dataSet, 'Engineer_2')}>{dataSet['Engineer_2'][counter['Engineer_2']].text}</div>
                <div style={{ width: '300px', height: '100px' }} onDoubleClick={() => handleDoubleClick(dataSet, 'Engineer_3')}>{dataSet['Engineer_3'][counter['Engineer_3']].text}</div> */}
            {/* </div>)} */}
            <div style={{ height: '100%', width: imgRef.current.clientWidth, position: 'relative' }}>
                <img src={data.bgImg} ref={imgRef} style={{ height: '100%' }} />
                <div className='myapp-slider-1'><Slider track={false} value={value.habit_1} classes={{ rail: classes.rail, thumb: classes.thumb }} onChange={(event, value) => handleChange('habit_1', value)} aria-labelledby="continuous-slider1" /></div>
                <div className='myapp-slider-2'><Slider track={false} value={value.habit_2} classes={{ rail: classes.rail, thumb: classes.thumb, vertical: classes.verticalRail }} onChange={(event, value) => handleChange('habit_2', value)} aria-labelledby="continuous-slider2" orientation="vertical" /></div>
                <div className='myapp-slider-3'><Slider track={false} value={value.habit_3} classes={{ rail: classes.rail, thumb: classes.thumb }} onChange={(event, value) => handleChange('habit_3', value)} aria-labelledby="continuous-slider3" /></div>
                <div className='myapp-slider-4'><Slider track={false} value={value.habit_4} classes={{ rail: classes.rail, thumb: classes.thumb, vertical: classes.verticalRail }} onChange={(event, value) => handleChange('habit_4', value)} aria-labelledby="continuous-slider4" orientation="vertical" /></div>
                <div className='myapp-slider-5'><Slider track={false} value={value.habit_5} classes={{ rail: classes.rail, thumb: classes.thumb, vertical: classes.verticalRail }} onChange={(event, value) => handleChange('habit_5', value)} aria-labelledby="continuous-slider5" orientation="vertical" /></div>
                <div className='myapp-slider-6'><Slider track={false} value={value.habit_6} classes={{ rail: classes.rail, thumb: classes.thumb }} onChange={(event, value) => handleChange('habit_6', value)} aria-labelledby="continuous-slider6" /></div>
            </div>
        </div>
        // </div>
    );
}

