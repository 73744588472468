import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LeftNav from "../../../components/LeftNav";
import ProjectDashboard from "../../../components/App_ProjectDashboard";

import Dashboard_Header from "../../../components/Dashboard_Header";

class channelDashboard extends Component {
  render() {
    return (
      <div className={"dashboard-cntr"}>
        <LeftNav />
        <div className="with-left-nav wrapper prepare-dashboard">
          <Dashboard_Header
            heading='Projects Zone'
            subheading='Projects'
            description='A dashboard with a collection of all projects from various BPUT colleges'
            type='Project'
          />
          <ProjectDashboard />
        </div>
      </div>
    );
  }
}
channelDashboard.propTypes = {
  authMsg: PropTypes.string
};

const mapStateToProps = state => ({ auth: state.auth });

export default connect(mapStateToProps, null)(channelDashboard);