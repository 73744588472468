import React, { useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 } from 'uuid';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {


    useEffect(() => {
        if (!engagementData || !engagementData[data.id]) {

            let temp = { ...data.dragDeckId, ...data.dropDeckId };

            Object.keys(data.dragDeckId).map(dragDeck => {
                let tempDeck = []
                Object.keys(data.starIcon).map(star => tempDeck.push({ id: v4(), starIcon: data.starIcon[dragDeck], starName: dragDeck }))
                temp[dragDeck] = tempDeck
            })

            manageToolData({
                toolId: data.id, content: temp
            })
        }


    }, [data.id], [data.toolName])

    const handleDragEnd = ({ destination, source }) => {

        if (!destination) {
            return
        }

        if (destination.index === source.index && destination.droppableId === source.droppableId) {
            return
        }

        if (engagementData[data.id][destination.droppableId].length >= 6)
            return


        const itemCopy = engagementData[data.id][source.droppableId][source.index]

        if (data.dragDeckId[destination.droppableId] && itemCopy.starName !== destination.droppableId)
            return


        let temp = { ...engagementData[data.id] }
        temp[source.droppableId].splice(source.index, 1)
        temp[destination.droppableId].splice(destination.index, 0, itemCopy)
        manageToolData({ toolId: data.id, content: temp })

    }
    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    {
                        Object.keys(data.dragDeckId).map((dragDeck, i) => {
                            return <Droppable key={dragDeck} droppableId={dragDeck}>
                                {(provided) => {
                                    return (
                                        <div
                                            ref={provided.innerRef}
                                            className={`sma-star-drag-deck-${i}`}
                                        >
                                            {
                                                engagementData && engagementData[data.id] && engagementData[data.id][dragDeck] && Object.keys(engagementData[data.id][dragDeck]).map((star, index) => {
                                                    let el = engagementData[data.id][dragDeck][index]
                                                    if (el) {
                                                        return <Draggable key={el.id} index={index} draggableId={el.id}>
                                                            {(provided) => {
                                                                return (
                                                                    <div ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        className='w-100 h-100'
                                                                    >
                                                                        <img src={el.starIcon} className='w-100 h-100' alt='star-icon' />
                                                                    </div>
                                                                )
                                                            }}
                                                        </Draggable>
                                                    }
                                                })

                                            }
                                            {provided.placeholder}
                                        </div>)
                                }}
                            </Droppable>
                        })
                    }
                    {
                        Object.keys(data.dropDeckId).map((dropDeck, i) => {

                            return <div key={`sma-star-drop-deck-container-${i}`} className={`sma-star-drop-deck-container-${i}`}>
                                <Droppable key={dropDeck} droppableId={dropDeck}>
                                    {(provided) => {
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                className='w-100 h-100 pos-relative'
                                            >
                                                {
                                                    engagementData && engagementData[data.id] && engagementData[data.id][dropDeck] && Object.keys(engagementData[data.id][dropDeck]).map((star, index) => {
                                                        let el = engagementData[data.id][dropDeck][index]

                                                        return <div key={el && el.id} className={`sma-star-drop-deck-${index}`}>
                                                            {el && <Draggable index={index} draggableId={el.id}>
                                                                {(provided) => {
                                                                    return (
                                                                        <div ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className='w-100 h-100'
                                                                        >
                                                                            <img src={el.starIcon} className='w-100 h-100' alt='star-icon' />
                                                                        </div>
                                                                    )
                                                                }}
                                                            </Draggable>}
                                                        </div>
                                                    })
                                                }
                                                {provided.placeholder}
                                            </div>)
                                    }}
                                </Droppable>
                            </div>
                        })
                    }
                </DragDropContext>
            </div>
        </div >
    );
}