import React from 'react';

import TextEditor from '../../../Tools/TextEditor/TextEditor';

import Pencil from "../../../components/images/Tools-img/pencil.png";
import Arrow_2 from "../../../components/images/Tools-img/arrow_2.png";

import './style.css';


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    // let titleSize = '20px'
    let descriptionSize = '12px';
    let headingSize = '16px';
    if (isFullScreen) {
        // titleSize = '30px';
        headingSize = '24px'
        descriptionSize = '16px';
    }

    return (
        <div style={{ width, height }}>
            <div className='ESI_Slide_illumine_logo'>
                <img src='https://pedgog2.s3.ap-south-1.amazonaws.com/Becoming+future+ready+contributors/04-Enlightened+Self+interest/Illuminelogo.png'
                    style={{ width: '100%', height: '100%' }}
                    alt='illumine-logo'
                />
            </div>
            <div style={{ width: '95%', height: '25%', padding: '2% 4%', lineHeight: headingSize }}>
                <span className='work-sans' style={{ fontSize: headingSize, fontWeight: 'bold' }}>{data.title}</span>
                <span className='work-sans' style={{ fontSize: headingSize }}>{data.subTitle}</span>
            </div>
            <div className='ESI-textbox-container'>
                <div className='ESI-slide_31_pencil'>
                    <img src={Pencil} style={{ width: '100%', height: '100%' }} alt='pencil' />
                </div>
                <div style={{ width: '100%', height: '40%', display: 'flex' }}>
                    <div style={{ width: '20%', height: '100%', marginTop: '4%' }}>
                        <div className='tct-scrollable work-sans' style={{ width: '60%', margin: 'auto', fontSize: descriptionSize, height: '100%', fontWeight: 'bold', overflowY: 'auto' }}>
                            {data.textBoxQuestions[0]}
                        </div>
                    </div>
                    <div style={{ width: '75%', height: '100%', display: 'flex' }}>
                        {
                            data.textBoxId_1.map((item, i) =>
                                <div key={i} style={{ width: '19%', display: 'flex', margin: '0 0.5%', height: '65%', position: 'relative' }}>
                                    <TextEditor id={item} initialData={engagementData[item] || null}
                                        manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.'
                                        wrapperClass='text-editor-think-sol-case-2' />
                                    <img src={Arrow_2} className='ESI-slide_31_arrow' alt='arrow' />
                                </div>)
                        }
                    </div>
                </div>
                <div style={{ width: '100%', height: '40%', display: 'flex' }}>
                    <div style={{ width: '20%', height: '100%', marginTop: '4%' }}>
                        <div className='tct-scrollable work-sans' style={{ width: '60%', margin: 'auto', fontSize: descriptionSize, height: '100%', fontWeight: 'bold', overflowY: 'auto' }}>
                            {data.textBoxQuestions[1]}
                        </div>
                    </div>
                    <div style={{ width: '75%', height: '100%', display: 'flex' }}>
                        {
                            data.textBoxId_2.map((item, i) =>
                                <div key={i} style={{ width: '19%', display: 'flex', margin: '0 0.5%' }}>
                                    <TextEditor id={item} initialData={engagementData[item] || null}
                                        manageToolData={manageToolData} hideToolbar={true} placeholder='Fill here.'
                                        wrapperClass='text-editor-think-sol-case-1' />
                                </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}