import React from 'react';
// import TextEditor from '../../../Tools/TextEditor/TextEditor';
import { Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './style.css';

const useStyles = makeStyles({
    underline: {
        '&:after': {
            borderBottom: '2px solid #ffba36'
        },
    }
});


export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let descriptionSize = '10px';

    let textbox_1_top = '25.5%';
    let textbox_1_right = '24.8%';
    if (isFullScreen) {
        descriptionSize = '14px';
        textbox_1_right = '29.5%';
    }
    const classes = useStyles();

    const postTextBox = () => {
        return Object.keys(engagementData && engagementData['ESI_Slide_45']).map(i => {
            return <div key={i} className='ESI_Slide_45_textbox'>
                <div className='work-sans' style={{ height: '15%', width: '100%', textAlign: 'center', fontSize: descriptionSize }}>Entity: {parseInt(i) + 1}</div>
                <div style={{ height: '25%', fontSize: descriptionSize, width: '100%', textAlign: 'center' }}>
                    <Input
                        classes={{ underline: classes.underline }}
                        className={'work-sans'}
                        readOnly
                        placeholder="Fill Here."
                        style={{ fontSize: descriptionSize, height: '16px' }}
                        value={engagementData['ESI_Slide_45'][i].input || ''}
                        onChange={(event) => {
                            engagementData['ESI_Slide_45'][i] = { input: event.currentTarget.value, textBox: engagementData['ESI_Slide_45'][i].textBox }
                            manageToolData({ toolId: 'ESI_Slide_45', content: engagementData['ESI_Slide_45'] })
                        }}
                    />
                </div>
                <textarea id={engagementData['ESI_Slide_45'][i].textBox} placeholder='Fill their contribution here…'
                    className={'ISE_Growing_Deep_textBox tct-scrollable work-sans'}
                    readOnly
                    style={{ width: '100%', height: '57%', display: 'flex', fontSize: descriptionSize, position: 'relative' }}
                    value={engagementData['ESI_Slide_45'][i].textBox || ''}
                    onChange={(event) => {
                        engagementData['ESI_Slide_45'][i] = { textBox: event.currentTarget.value, input: engagementData['ESI_Slide_45'][i].input }
                        manageToolData({ toolId: 'ESI_Slide_45', content: engagementData['ESI_Slide_45'] })
                    }}
                />
            </div>
        })
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className='ESI_Slide_45_textbox_container tct-scrollable' style={{ width: '71%', height: '67%', position: 'absolute', top: textbox_1_top, right: textbox_1_right }}>
                    {postTextBox()}
                </div>
            </div>
        </div>
    );
}