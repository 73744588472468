import React
// , { Component } 
from "react";
import { Switch, Route } from "react-router-dom";

//ACP Links
import ACPHome from "./applicationDashboard"
// import ACPStoreDashboard from "./store";
// import ACPProduct from "./productPage";
// import ACPPaymentResponse from "./payments";
// import ChannelDashboard from "./channelDashboard";
import Unit from "./unit";
// import ReportPDF from '../../Live_Session_Tools/PDF';
import Conduct from "../application/conduct"
// import Prepare from "../application/channelPrepare";
import "./acp.css"
export default ({ }) => {
    document.getElementById("root").style.height = "auto"
    return <Switch>
        {/* <Route path="/application/paymentResponse/:txnid" component={ACPPaymentResponse} /> */}
        {/* <Route path="/application/channel/:yearId/:channelId" component={ChannelDashboard} /> */}
        {/* <Route path="/application/explore/:channelId" component={ACPProduct} /> */}
        <Route path="/application/unit/:unitId" component={Unit} />
        {/* <Route path="/application/library" component={ACPStoreDashboard} /> */}
        {/* <Route path="/application/pdfreport" component={ReportPDF} /> */}
        {/* <Route path="/application/dashboard" component={ACPHome} /> */}
        <Route
            exact
            path="/application/conduct/:moduleId"
            component={Conduct}
        />
        {/* <Route
            exact
            path="/application/prepare/:moduleId"
            component={Unit}
        /> */}
        <Route path="/application/" component={ACPHome} />
    </Switch>
}