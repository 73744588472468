import React from "react"
import Modal_Component from '../../Tools/Modal_Component';

export default ({ data, width, height, language }) => {
    return <div style={{ width, height, marginLeft: "auto", marginRight: "auto" }}>
        <div style={{ backgroundImage: `url(${data.bgImg?.[language] ? data.bgImg[language] : data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
            {data?.popUp_content?.map(popUp => {
                return <div id='modal-id-parent' style={{
                    ...popUp?.style,
                    position: "absolute",
                    padding: "0!important"
                }} >

                    <Modal_Component
                        type={popUp.type}
                        icon={popUp.icon?.[language] ? popUp.icon[language] : popUp.icon}
                        src={popUp.src?.[language || 'en'] || popUp.src}
                        language={language}
                        width={width - 50}
                        height={height - 50}
                        parentId='modal-id-parent'
                    />
                </div>
            })}

        </div>
    </div >
}