import React, { useEffect } from 'react';
import EdiText from 'react-editext';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, responses }) => {

    // let descriptionSize = '14px';

    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        // descriptionSize = '21px';
    }

    const handleSave = (key, e, newValue) => {
        // let index = dragApp[key].item.findIndex((i) => i.id === e.id)
        // if (dragApp[key] && dragApp[key].item) {
        //   setDragApp({ ...dragApp, [key]: { ...dragApp[key], item: [...dragApp[key].item.slice(0, index), { ...dragApp[key].item[index], text: newValue }, ...dragApp[key].item.slice(index + 1)] } })
        // } 
        let index = engagementData[data.sessionType][data.teamName][key].item.findIndex((i) => i.id === e.id)
        if (engagementData[data.sessionType][data.teamName][key] && engagementData[data.sessionType][data.teamName][key].item) {
            manageToolData({
                toolId: data.sessionType,
                content: {
                    ...engagementData[data.sessionType],
                    [data.teamName]: {
                        ...engagementData[data.sessionType][data.teamName],
                        [key]: {
                            ...engagementData[data.sessionType][data.teamName][key],
                            item: [
                                ...engagementData[data.sessionType][data.teamName][key].item.slice(0, index),
                                { ...engagementData[data.sessionType][data.teamName][key].item[index], text: newValue },
                                ...engagementData[data.sessionType][data.teamName][key].item.slice(index + 1)
                            ]
                        }
                    }
                }
            })
        }
    }

    // Remove Ideas from list

    const handleRemove = (key, id) => {
        // let index = dragApp[key].item.findIndex((i) => i.id === id)
        // let items = dragApp[key].item.filter((i) => i.id !== id)
        // setDragApp({ ...dragApp, [key]: { ...dragApp[key], item: items } })
        let items = engagementData[data.sessionType][data.teamName][key].item.filter((i) => i.id !== id)
        manageToolData({
            toolId: data.sessionType,
            content: {
                ...engagementData[data.sessionType],
                [data.teamName]: {
                    ...engagementData[data.sessionType][data.teamName],
                    [key]: {
                        ...engagementData[data.sessionType][data.teamName][key],
                        item: items
                    }
                }
            }
        })
    }

    const deckStyle = {
        'Experiential value': 'teamboard-display-exp-value',
        'Financial & transactional value​': 'teamboard-display-fin-value',
        'Human & social value': 'teamboard-display-human-value',
        'Utility value': 'teamboard-display-util-value'
    }

    // const teamboardResponse = responses && responses["answer"][data.teamName] ? responses["answer"][data.teamName] : '';
    useEffect(() => {
        if (responses && responses[data.teamName] && (!engagementData[data.sessionType] || !engagementData[data.sessionType][data.teamName])) {
            manageToolData({
                toolId: data.sessionType,
                content: {
                    ...engagementData[data.sessionType],
                    [data.teamName]: responses[data.teamName]
                }
            })
        }
    }, [])
    // console.log(engagementData[data.sessionType], responses[data.teamName])

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {responses && responses[data.teamName] && engagementData[data.sessionType] && engagementData[data.sessionType][data.teamName] && Object.keys(engagementData[data.sessionType][data.teamName]).map((el, index) => {
                    if (el === 'Case')
                        return <div key={el} className='teamboard-display-case tct-scrollable-darkgray'>
                            {engagementData[data.sessionType][data.teamName].Case}
                        </div>
                    else {
                        let deckCss = deckStyle[el];
                        return <div key={el} className={`teamboard-display-value-container ${deckCss} tct-scrollable-darkgray`}>
                            {engagementData[data.sessionType][data.teamName][el] && engagementData[data.sessionType][data.teamName][el]['item'] && engagementData[data.sessionType][data.teamName][el]['item'].map((item, index) => {
                                // return <div key={item.id}>{item.text}</div>
                                return <div key={item.id} className='teamboard-display-text'>
                                    <EdiText
                                        viewContainerClassName='my-custom-view-wrapper0'
                                        type='textarea'
                                        inputProps={{
                                            className: 'tct-scrollable-darkgray textArea-OnHover-To-Scroll',
                                            style: {
                                                maxWidth: '100%',
                                                resize: 'none',
                                            }
                                        }}
                                        hideIcons
                                        cancelOnEscape
                                        submitOnUnfocus
                                        editOnViewClick
                                        value={item.text}
                                        onSave={(newValue) => handleSave(el, item, newValue)} >
                                    </EdiText>
                                    <DeleteForeverIcon
                                        fontSize="small"
                                        onClick={() => handleRemove(el, item.id)}></DeleteForeverIcon>
                                </div>

                            })}
                        </div>
                    }
                }
                )}
                {data.prevTool && engagementData[data.prevTool] && engagementData[data.prevTool][data.teamName] && Object.keys(engagementData[data.prevTool][data.teamName].item).length > 0 && <div className='teamboard-display-team-members-name'>
                    {Object.keys(engagementData[data.prevTool][data.teamName].item).map((el, i) => <span key={el}>{engagementData[data.prevTool][data.teamName].item[el].username} {Object.keys(engagementData[data.prevTool][data.teamName].item).length - 1 !== i ? ',' : ''}</span>)}
                </div>}
                <div className='teamboard-display-team-name'>{data.teamName}</div>
            </div>
        </div>
    );
}