import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import MultiPageTemplate from './components/MultiPagePDFReader/index';
import DownloadablePDF from './components/downloadable_pdf';
import VideoPlayer from './components/VideoPlayer';
import { width } from '@material-ui/system';
const duringLoad = <div className='loading-screen'>Loading...</div>

let Resource = (props) => {
 const [wd, setWidth] = useState(null);
 const [ht, setHeight] = useState(null);
 let { postId, resourceId, unit, width, height } = props;
 // updateReadingTracker({uid, groupId, resourceId, instanceId: Date.now()}); 
 useEffect(() => {
  console.log(unit.docRatio);
  let docRatio = unit?.docRatio || 1.5; //default is potrait
  var w = width || window.innerWidth;
  var h = height || window.innerHeight;
  var deviceRatio = w / h;
  if (w / h >= docRatio) {
   // This need to be fixed to the height
   setHeight(h);
   setWidth(h * docRatio);
   console.log(w, h, w / h, docRatio, h * docRatio)
  } else {
   //This need to be fixed to width
   setWidth(w);
   setHeight(w / docRatio);
  }
 }, [])
 console.log(wd, ht)
 if (unit) {
  return serveTemplate(unit, resourceId, wd, ht)
 }
 return <div>No Such Resource exists</div>
}


function serveTemplate(unit, resourceId, wd, ht) {
 let src = unit.src, docRatio = unit.docRatio || null;
 if (unit.type == 'pdf_downloadable') {
  return <DownloadablePDF src={src} resourceId={resourceId} />
 } else if (unit.type == 'video') {
  return <div style={{
   width: wd, height: ht
  }}>
   <VideoPlayer mediaUrl={src} resourceId={resourceId} />
  </div>
 }
 else {
  return <div id='_book_meta_cntr' className='_book_meta_cntr'>
   <MultiPageTemplate src={src} duringLoad={duringLoad} docRatio={docRatio} resourceId={resourceId} />
  </div>
 }
}

const mapStateToProps = (state) => ({ auth: state.auth, app: state.app });
export default connect(mapStateToProps)(Resource);