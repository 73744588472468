import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Inner_fruit from './assets/Inner_fruit.svg'
import Outer_fruit from './assets/Outer_fruit_2.svg'
import Joy_of_contribution from './assets/Joy_of_contribution.svg'

export default ({ fruitDiv, fruitType, engagementData, toolName, toolId }) => {
    let dataSet = engagementData[toolName] && engagementData[toolName][toolId] && engagementData[toolName][toolId][fruitType] ? engagementData[toolName][toolId][fruitType] : []

    const FruitIcons = {
        Outer_fruit,
        Joy_of_contribution,
        Inner_fruit,
    }

    let FruitIcon = FruitIcons[fruitType]

    let fruitsLen = dataSet.length > 10 ? dataSet.length : 10;
    let fruitSize = ((fruitDiv.current.clientWidth + fruitDiv.current.clientHeight)) / fruitsLen;


    return (
        dataSet.map((item, index) =>
            <Tooltip key={item} title={<h3 style={{ color: 'white' }}>{item}</h3>} arrow style={{ width: fruitSize, height: fruitSize }}>
                {/* <div className='fruit-div' style={{ width: fruitSize, height: fruitSize }}> */}
                <div className={`tofv2-hover-fruit-div tofv2-hover-fruit-color-${fruitType}`} style={{ width: fruitSize, height: fruitSize }} />
            </Tooltip >

        )
    )
}


