import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import ContentRoll from './ContentRoll';
import { toggleLinearNavigator } from '../../containers/application/redux_actions';

const LabelContent = ({ labelType, labelContent, handleCurrentLabelContent }) => {
    if (!labelContent?.length) return <></>;
    return (
        // <div className={styles['label-content-container']}>
        //     <div className={styles['label-content-title']}>{labelType}</div>

        // </div>
        <div className={styles['label-content-list']}>
            {
                labelContent?.map((v, i) => {
                    if (!v || !v.contentTitle) return <></>;
                    return (
                        <div className={styles['content-cell']} key={i} onClick={() => handleCurrentLabelContent(v)}>
                            <div className={styles['content-cell-image']}>
                                <div className={styles['content-cell-tag']}>
                                    {v?.labelType}
                                </div>
                            </div>
                            <div className={styles['content-cell-data-wrapper']}>
                                <div className={styles['content-cell-title']}>{v?.contentTitle || ''}</div>
                                <div className={styles['content-cell-description']}>{v?.contentDescription || ''}</div>
                                <div className={styles['content-cell-actionable']}>Click to see scenario</div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

const contentFilterApi = (roleWiseCountMap, totalNumberOfParticipants, noOfExamples) => {
    const roleWiseExamplesMap = {}
    for (const i in roleWiseCountMap) {
        const noOfPeople = roleWiseCountMap[i];
        const noOfExamplesToSupply = Math.round((noOfPeople / totalNumberOfParticipants) * noOfExamples)
        roleWiseExamplesMap[i] = noOfExamplesToSupply
    }
    // console.log('--<',roleWiseExampleMap)
    return roleWiseExamplesMap;
}

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, language }) => {
    if (!isFullScreen) {
        return <div style={{ padding: '20px' }}>Preview in Fullscreen</div>
    }
    const [currentLabelContent, setCurrentLabelContent] = useState();
    const [roleWiseExamples, setRoleWiseExamples] = useState({});
    const [showAll, setShowAll] = useState(true);

    const labelConfig = data?.labelConfig;
    const labelWiseContent = data?.labelWiseContent;
    const sortedLabels = Object.keys(labelConfig || {}).sort((a, b) => labelConfig[a].order - labelConfig[b].order);

    const getContentByLabel = (labelType) => labelWiseContent?.filter(data => data && data['labelType'] == labelType)
    const getDisplayName = (labelType) => (labelConfig[labelType]['displayName'] || labelType);

    const filteredSortedLabels = Object.keys(roleWiseExamples).length ? Object.keys(roleWiseExamples) : sortedLabels;
    const filteredLabelWiseContent = (labelType) => {
        const sliceCount = roleWiseExamples[labelType] || 0;
        return sliceCount ? getContentByLabel(labelType)?.slice(0, sliceCount) : getContentByLabel(labelType);
    }
    const filteredContent = () => {
        let finalContent = []
        if (filteredSortedLabels?.length) {
            filteredSortedLabels.forEach(labelType => {
                const _data = filteredLabelWiseContent(labelType);
                if (_data?.length) {
                    finalContent = [...finalContent, ..._data];
                }
            })
        }
        return finalContent;
    }

    const resetContent = () => {
        setCurrentLabelContent(null);
        toggleLinearNavigator(true);
    }

    const handleCurrentLabelContent = (labelContent) => {
        if (!labelContent) return;
        toggleLinearNavigator(false);
        setCurrentLabelContent(labelContent);
    }

    const toggleShowAll = () => {
        setShowAll(v => !v);
    }

    useEffect(() => {
        if (!showAll) {
            const noOfParticipants = 15;
            const roleWiseCountMap = {
                police: 10,
                healthCare: 5,
                // general:3
            }
            const noOfExamples = 5;
            const roleWiseExamplesMap = contentFilterApi(roleWiseCountMap, noOfParticipants, noOfExamples);
            setRoleWiseExamples(roleWiseExamplesMap)
        } else {
            setRoleWiseExamples({})
        }
    }, [showAll])

    const defaultContainerStyles = {
        padding: '30px 60px'
    }
    console.log("currentLabelContent", currentLabelContent)
    return (
        <div className={styles['main-container']} style={!currentLabelContent ? defaultContainerStyles : {}}>
            {
                !currentLabelContent &&
                <>
                    {/* {
                        filteredSortedLabels?.map(labelType => {
                            return <LabelContent labelType={getDisplayName(labelType)} labelContent={filteredLabelWiseContent(labelType)} handleCurrentLabelContent={handleCurrentLabelContent} />
                        })
                    } */}
                    {/* <div className={styles['top-title']}>
                        Given the Class Distribution - Police (4), Teachers (4), Healthcare (3), General (1), here are 6 scenarios for this exercise. Select any scenario of your choice to proceed.
                    </div> */}
                    {/* <div className={styles['top-filter']}>
                        <div className={styles['top-filter-title']}>
                            FILTERS APPLIED : POLICE | HEALTH | TEACHER | GENERAL
                        </div>
                        <button onClick={toggleShowAll} className={styles['show-all-btn']}>{showAll ? 'SEE FILTERED' : 'SEE ALL'}</button>
                    </div> */}
                    <LabelContent labelContent={filteredContent()} handleCurrentLabelContent={handleCurrentLabelContent} />
                </>
            }
            {
                currentLabelContent &&
                <ContentRoll currentLabelContent={currentLabelContent} resetContent={resetContent} language={language} />
            }
        </div>
    )
}
