import React, { Component } from "react";
import { connect } from "react-redux";
import MainRoute from "./Route"
import Config from "../../config"
//ACP Links
import ACPRoute from "../acp"
import APRoute from "../asianPaints/AppRoute"
import IRRoute from "../IndianRailways/AppRoute"
import { isACP, isAP, isBPUT, isIR } from "../../utilities/common";
console.log("isBPUT", isBPUT)
let Application = class Application extends Component {
  render() {
    if (isACP) {
      return <ACPRoute />
    }
    else if (isBPUT || isIR || this.props.auth.orgDetails.organizationname === 'Indian Railways') {
      return <div className='ir-tisa-font w-100 h-100'>
        <IRRoute app={this.props.app} auth={this.props.auth} />
      </div>
    }
    else {
      return <MainRoute />
    }
  }
};

const mapStateToProps = state => ({
  auth: state.auth,
  app: state.app,
});


export default connect(
  mapStateToProps, null
)(Application);
