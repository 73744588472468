import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LeftNav from "../../../components/LeftNav";
import AddModules from "../../../components/AddModule";

import "./addModule.css";

class AddModule extends Component {
  render() {
    return (
      <div className={"dashboard-cntr"}>
        <LeftNav />
        <AddModules />
      </div>
    );
  }
}
AddModule.propTypes = {
  authMsg: PropTypes.string
};

const mapStateToProps = state => ({ auth: state.auth });

export default connect( mapStateToProps, null )(AddModule);
