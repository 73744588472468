import React from 'react';
import "./NoModuleAvailable.css";
import {
  FormControl,
  Button, Typography,
  TextField
} from "@material-ui/core";

import { Link } from 'react-router-dom';

const noModImg = require('./assets/noMod.svg');
const toLogin = props => <Link to="../auth/login" {...props} />;

let NoModuleAvailable = ({ user }) => {
  return <div className="access-card">
    <div className="-access-card-body">
      <div className="mb-3">
        <img src={noModImg} alt="grp" />
        <div className="subtitle" style={{ marginBottom: 6 }}>You don't have any modules to explore!</div>
      </div>
      <div className="access-card-content">
        <h6>Enter access code to unlock modules</h6>
        <div className="access-btn-inline mb-3">
          <FormControl
            fullWidth
            className={"input-cntr"}
          >
            <TextField id="accesscode" label="Access Code" variant="outlined" />
          </FormControl>
          <div className='button-text ml-3'>
            <Button className="MuiButtonBase-root MuiButton-root btn-yellow sticky" onClick={() => this.setState({ childOpen: false })}>Unlock</Button>
          </div>
        </div>
        <div className="cFoot">

          <div className="login-link-cntr d-block">
            <p> Don't have an access code? <Button>
              <Typography
                className="errorMsg"
                variant="caption"
                gutterBottom
                color="primary"
                component={toLogin}
              >
                Contact Us
                </Typography>
            </Button> </p>
          </div>
        </div>
      </div>
    </div>
  </div>
};

export default NoModuleAvailable;