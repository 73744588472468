import React from "react";
import { Switch, Route } from "react-router-dom";
import App_ExploreDashboard from "./index.js";
import Rating from "../../containers/IndianRailways/rating";

export default (props) => {
    let { app, auth, BlogIndex, Analytics } = props;
    return <Switch>
        <Route app={app} exact path="/application/blog" component={BlogIndex} />
        <Route exact path="/application/analytics" component={Analytics} />
        <Route exact path="/application/rating" component={Rating} />
        <Route path="/application" render={() => (<App_ExploreDashboard app={app} auth={auth} />)} />
    </Switch>
}