import { Button, Checkbox } from '@material-ui/core'
import React, { useState } from 'react'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineRounded'

export default ({ currentEngagement, setSectionId, sectionId }) => {
    const [checked, setChecked] = useState({})
    const clues = currentEngagement?.ideaBoard?.clues

    return (
        <div className='Four_div extra' style={{ border: '2px solid', }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '1rem', fontWeight: 600 }}>
                CLUES
                <Button onClick={() => setSectionId(null)} style={{ padding: 0 }}>X</Button>
            </div>
            <div style={{ height: '55vh', overflow: 'hidden auto' }}>
                {clues.map((clue, i) => {
                    return (
                        <div key={`clues-${i}`} style={{ display: 'flex', justifyContent: 'space-between', border: '2px solid #D9D9D9', borderRadius: '8px', margin: '3% 0%', padding: '1% 2%', lineHeight: '1.2rem' }}>
                            <Checkbox
                                key={'a' + i * 2}
                                checked={checked[i]}
                                icon={<CircleUnchecked />}
                                checkedIcon={<CircleCheckedFilled />}
                                onChange={(event) => {
                                    // console.log("checked", checked)
                                    setChecked((prev) => {
                                        prev = { ...prev }
                                        prev[i] = !prev[i]
                                        return prev
                                    })
                                }}
                                // style={{ marginLeft: '-20%', marginTop: '-20%' }}
                                inputProps={{
                                    "aria-label":
                                        "primary checkbox",
                                }}
                            />
                            <span>{clue}</span>
                        </div>
                    )
                })}
            </div>
            <div style={{ width: '100%' }}>
                <Button
                    style={{ backgroundColor: '#BA1F00', borderRadius: '8px', color: 'white', width: '100%', padding: '3% 2%' }}
                // onClick={addClue}

                >ADD TO BOARD</Button>
            </div>
        </div>
    )
}