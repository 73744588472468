//import { combineReducers } from 'redux'
import authReducers from "./auth/redux_reducers";
import appReducers from "./application/redux_reducers";
import channelState from "./acp/channelDashboard/redux_reducers";

let reducers = {
  auth: authReducers,
  app: appReducers,
  channelState
};

export default reducers;