import React from "react";
import {
  addFormValues,
  generateDynamicTakeways,
  pollAfterIdeaBoard
} from "../../containers/application/redux_actions";
import { setADoc } from "../../utilities/firebase/fb_firestore";
import { updateData } from "../../utilities/firebase/fb_rtdb";

let classifyAPiece = ({ sessionId, engagementId, fid, rid, typeId, x, y, w, h, Rmax, Rmin }) => {
  let updates = {};
  if (typeId) {
    updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/class`] = typeId
    // assginTag({ sessionId, toolId, fid, rid, typeId, x, y, w, h, Rmax, Rmin, toolKit })
  }

  if (x && y) {
    updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/x`] = x;
    updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/y`] = y;
  }
  if (w && h) {
    updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/width`] = w;
    updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/height`] = h;
  }
  if (Rmax && Rmin) {
    updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/Rmax`] = Rmax;
    updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/Rmin`] = Rmin;
  }
  return updateData(updates)
}

let updateFilter = ({ sessionId, engagementId, key, value }) => {
  let updates = {};
  updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/filters/${key}`] = value;
  updateData(updates)
}

let resetClassification = ({ sessionId, engagementId, fid, rid }) => {
  let updates = {};
  updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/Rmax`] = null;
  updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/Rmin`] = null;
  updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/x`] = null;
  updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/y`] = null;
  updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/checked`] = false;
  updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/offBoard`] = true;
  //  updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/`] = false;
  updateData(updates)
}

let activateResponse = ({ sessionId, engagementId, fid, rid, isActive }) => {
  let updates = {};

  updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/activeResponses/${rid}`] = isActive ? { fid } : null;
  updateData(updates)
}

let superActivateResponse = ({ sessionId, engagementId, isEditable, rid, response }) => {
  console.log({ sessionId, engagementId, isEditable, rid, response })
  let updates = {};
  if (rid) {
    updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/activeResponses/${rid}`] = response
  }
  updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/isEditable`] = isEditable;
  updateData(updates)
}


let resetPositions = ({ sessionId, engagementId, boardState }) => {
  let updates = {};
  Object.keys(boardState.fieldWiseResponses).forEach(fid => {
    let f = boardState.fieldWiseResponses[fid];
    Object.keys(f).forEach(rid => {
      updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/x`] = null;
      updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/y`] = null;
    })
  })
  updateData(updates)
}

let ifRectanglesClash = ({ R1, R2 }) => {
  let l1 = { x: R1.x, y: R1.y };
  let r1 = { x: R1.x + R1.w, y: R1.y + R1.h };
  let l2 = { x: R2.x, y: R2.y };
  let r2 = { x: R2.x + R2.w, y: R2.y + R2.h };

  if (l1.x == r1.x || l1.y == r1.y || r2.x == l2.x || l2.y == r2.y) {
    return false;
  }
  if (l1.x > r2.x || l2.x > r1.x) {
    return false;
  }
  if (r1.y > l2.y || r2.y > l1.y) {
    return false;
  }

  return true;
}

let placeOnTheBoard = ({ pt, center, sessionId, engagementId, fid, rid, typeId }) => {
  let theta = Math.random() * 2 * 3.14;
  let r = Math.floor(Math.random() * (pt.Rmax - pt.Rmin)) + pt.Rmin;
  let X = center.x + r * Math.cos(theta);
  let Y = center.y + r * Math.sin(theta);
  return classifyAPiece({ sessionId, engagementId, fid, rid, x: X, y: Y, typeId, Rmax: pt.Rmax, Rmin: pt.Rmin })
}

let autoOrganiseRadial = ({ sessionId, engagementId, boardState, dim, pt, fid }) => {
  let center = { x: (boardState.dim.w / 2), y: boardState.dim.h / 2 };
  let pieces = boardState.pieces;
  let rid = pt.eid
  fid = fid || pt.fid
  if ((pt.class || pt.type) && !(pt.x || pt.y) && pt.eid && !pt.deletedOn) {
    let theta = Math.random() * 2 * 3.14;
    let r = Math.floor(Math.random() * (pt.Rmax - pt.Rmin)) + pt.Rmin;
    let X = center.x + r * Math.cos(theta);
    let Y = center.y + r * Math.sin(theta);
    let overlap = false;
    if (Object.keys(pieces).length) {
      Object.keys(pieces).forEach(pid => {
        let piece = pieces[pid];
        let _overlap = ifRectanglesClash({
          R1: piece,
          R2: { x: X, y: Y, w: dim.w, h: dim.h }
        });
        overlap = overlap || _overlap;
        if (!overlap) {
          pieces[rid] = { x: X, y: Y, w: dim.w, h: dim.h }
          classifyAPiece({ sessionId, engagementId, fid, rid, x: X, y: Y })
        }
      })
    } else {
      pieces[rid] = { x: X, y: Y, w: dim.w, h: dim.h }
      classifyAPiece({ sessionId, engagementId, fid, rid, x: X, y: Y })
    }
  }
}

let autoOrganiseGrid = ({ sessionId, engagementId, boardState, dim }) => {
  let pieces = boardState.pieces;

  Object.keys(boardState.fieldWiseResponses).forEach(fid => {
    let f = boardState.fieldWiseResponses[fid];
    Object.keys(f).forEach(rid => {
      let pt = f[rid];
      if (pt.class || pt.type) {
        (boardState.toolKit.classes).forEach((csid, i) => {
          let c = boardState.toolKit.classes[csid];
          Object.keys(c).forEach((cid, j) => {
            if (cid == pt.class || cid == pt.type) {

            }
          })
        })
      }
    })
  })

  // updateData(updates)
  return null;
}

let autoOrganise = ({ sessionId, engagementId, boardState, dim, pt, fid }) => {
  if (boardState?.toolKit?.variant == "radial") {
    // autoOrganiseRadial({ sessionId, toolId, boardState, dim })
    autoOrganiseRadial({ sessionId, engagementId, boardState, dim, pt, fid })
  }
  // else if (boardState?.toolKit?.variant == 'grid') {
  //   autoOrganiseGrid({ sessionId, toolId, boardState, dim })

  // }
}


let TeacherUpdateAResponse = ({ rid, u, sessionId, engagementId, fid, wasClassUpdated }) => {
  if (wasClassUpdated) {
    classifyAPiece({ sessionId, engagementId, fid, rid, typeId: u.class });
    resetClassification({ sessionId, engagementId, fid, rid })
  }
  setADoc({ collectionId: "generatedIdeas", docId: rid, data: u });
}

let addTeacherResponse = ({ rid, u, sessionId, engagementId, fid }) => {
  setADoc({ collectionId: "generatedIdeas", docId: rid, data: u });
  let updates = {}
  updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/eid`] = rid
  updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/class`] = u.class
  updateData(updates)
}

let sendFinalBoardState = ({ sessionId, fieldWiseResponses, responses, engagementId, user_token, currentUnit, currentScene, learningTool }) => {
  let _arr = {}
  let learningToolId = learningTool._id
  let activeContentId = learningTool.activeContent[currentScene].id
  Object.values(fieldWiseResponses).map(resp => {
    Object.keys(resp).map(rid => {
      let r = resp[rid];
      if (r.x)
        for (let t of responses?.[rid]?.tags || []) {
          if (_arr && _arr[t] && t in _arr) {
            _arr[t].push(responses[rid])
          }
          else {
            _arr[t] = [responses[rid]]
          }
        }
    })
  })
  let scenario = Object.assign({ [engagementId]: _arr })
  if (scenario) {
    pollAfterIdeaBoard({ sessionId, engagementId, user_token }, "DELETE")
    addFormValues({ sessionId, user_token, fieldData: scenario || {} })
    generateDynamicTakeways({ sessionId, user_token, learningToolId, activeContentId })
  }
}

let deleteResponse = ({ rid, sessionId, engagementId, fid }) => {
  let updates = {}
  updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/fieldWiseResponses/${fid}/${rid}/deletedOn`] = true;
  updates[`test/live_session/${sessionId}/ideaBoards/${engagementId}/activeResponses/${rid}`] = null;
  updateData(updates)
  setADoc({ collectionId: "generatedIdeas", docId: rid, data: { deletedOn: true } });
}

export {
  classifyAPiece, resetClassification, activateResponse, placeOnTheBoard,
  superActivateResponse, deleteResponse, updateFilter, ifRectanglesClash,
  resetPositions, autoOrganise, autoOrganiseGrid, TeacherUpdateAResponse,
  addTeacherResponse, sendFinalBoardState
}