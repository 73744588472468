import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Auth from "./containers/singleAuth/Auth";
import Public from "./containers/public/Public";
import Application from "./containers/application/Application";

import "./Main.css";
import "./Typography.css";
import AutoLogin from "./containers/IndianRailways/Auth/AutoLogin";
import Logout from "./containers/auth/Logout";
import Preview from "./containers/application/preview";
import Batches from "./containers/batches/Home";

let Main = class Main extends Component {
  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) => {
          return this.props.auth.isLoggedIn
            ?
            <Component {...props} />
            :
            <Redirect to={{ pathname: "/auth/login", state: { from: props.location, accessing: window.location.host }, }} />
        }
        }
      />
    );

    const PublicRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          !this.props.auth.isLoggedIn ? (
            <Component {...props} />
            // <Redirect
            //   to={{
            //     pathname: "/auth/login",
            //     state: { from: props.location },
            //   }}
            // />
          ) : (
            <Redirect
              to={{
                pathname: "/application",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );

    return (
      <Switch>
        <Route path="/auth" component={Auth} />
        <Route path="/auto-login/:token" component={AutoLogin} />        {/* <Route path="/application" component={Application} /> */}
        <Route path="/application/preview/:moduleId/:token" component={Preview} />
        <PrivateRoute path="/application" component={Application} />
        <PrivateRoute path="/batches" component={Batches} />
        <PublicRoute exact path="/" component={Public} />
        <Route path="/logout" component={Logout} />
        {/* <Route path="/university" component={UniversityDashboard} /> */}
      </Switch>
    );
  }
};

Main.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  notification: state.notification,
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps, null)(Main));
