import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { Typography } from "@material-ui/core";
import { getModuleDetails, getRoadmapDetails, getLearningToolDetails } from "./../redux_actions";
import { Link } from 'react-router-dom';

import { Document, Page, pdfjs } from "react-pdf";

import { Player, ControlBar, BigPlayButton, LoadingSpinner, ReplayControl } from "video-react";

// import ExpansionPanel from '@material-ui/core/ExpansionPanel';
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "../../../../node_modules/video-react/dist/video-react.css"; // import css

import "./channelExplore.css";
import LeftNav from "../../../components/LeftNav";
import FooterLinks from "../../../components/FooterLinks";

// import LinearNavigator from "../../../components/LinearNavigator";
import BackIcon from './assets/back.svg';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const {
  // innerWidth: WinWidth, 
  // innerHeight: WinHeight } = window;

let Explore = (props) => {
  // let isTop = true;
  // let [isComponentLoaded, updateComponentLoadStatus] = useState(false);
  // let [beginLearningTools, updateBeginLearningTools] = useState(true);
  // let [activeTabIndex, updateActiveTabIndex] = useState(1);
  // let { user_token } = props.auth;

  let moduleId = props.match.params.moduleId;
  let moduleData = props.app.moduleDetails[moduleId];
  // let roadMaps = Object.keys(moduleData && moduleData.organiser && moduleData.organiser.roadmaps ? moduleData.organiser.roadmaps : {})
  //   .sort((a, b) => {
  //     return moduleData.organiser.roadmaps[a].order - moduleData.organiser.roadmaps[b].order
  //   });
  // let firstRoadMapId = roadMaps[0];
  // let firstRoadmap = props.app.roadmaps[roadMaps[0]];
  // let sortedUnitsOfFirstRoadmap = firstRoadmap ? Object.keys(firstRoadmap.LearningTools).sort((a, b) => {
  //   return firstRoadmap.LearningTools[a] - firstRoadmap.LearningTools[b];
  // }) : null;
  // let firstTool = sortedUnitsOfFirstRoadmap ? sortedUnitsOfFirstRoadmap[0] : null;

  const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  function loadFile(modData) {
    if (modData.exploreContent) {
      if (modData.exploreContent.type === 'img' || modData.exploreContent.type === 'png' || modData.exploreContent.type === 'svg' || modData.exploreContent.type === 'bmp' || modData.exploreContent.type === 'jpeg' || modData.exploreContent.type === 'jpg' || modData.exploreContent.type === 'tiff') {
        return <img src={modData.exploreContent.url} style={{ marginTop: '-150px', border: '20px solid #f9f7f7', width: '800px', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 0px 20px 0px' }} />
      }
      if (modData.exploreContent.type === 'pdf') {
        return <div style={{ marginTop: '-160px', marginLeft: '320px', width: '720', height: '360px', overflow: 'scroll' }} >
          <Document
            file={modData.exploreContent.url}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map(page => <Page pageNumber={page} />)}
          </Document>

        </div>
      }
      else if (modData.exploreContent.type === 'mp4' || modData.exploreContent.type === 'avi') {
        return <div style={{ marginTop: '-160px' }}> <Player playsInline fluid={false} width={720} height={360}>
          <source src={modData.exploreContent.url} />
          <BigPlayButton position="center" />
          <LoadingSpinner />
          <ControlBar autoHide={false}>
            <ReplayControl seconds={5} order={2.1} />
            <ReplayControl seconds={10} order={2.2} />
            <ReplayControl seconds={30} order={2.3} />
          </ControlBar>
        </Player></div>
      }
    }
    return null;
  }

  // useEffect(() => {
  //   props.getModuleDetails({ id: moduleId, token: user_token })
  //     .then(res => {
  //       let a = res.payload&&res.payload.data.organiser ? res.payload.data.organiser : {roadmaps:{}};
  //       Object.keys(a.roadmaps).forEach(rdId => {
  //         props.getRoadmapDetails({ id: rdId, token: user_token })
  //           .then(res => {
  //             Object.keys(res.payload.data.LearningTools).forEach(uId => {
  //               props.getLearningToolDetails({ id: uId, token: user_token })
  //             })
  //           })
  //       })
  //     });
  //   updateComponentLoadStatus(true)
  // }, [isComponentLoaded]);

  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  let activeClass = 'normal MuiButtonBase-root MuiButton-root btn-yellow sticky ml-3 float-right';
  // const handleScroll = () => {
  //   if (ref.current) {
  //     setSticky(ref.current.getBoundingClientRect().top <= 0);
  //   }
  // };

  // useEffect(() => {
  //  // window.addEventListener('scroll', handleScroll);

  //    window.addEventListener('scroll', () => {
  //     if(window.scrollY === 0){
  //         activeClass = 'top MuiButtonBase-root MuiButton-root btn-yellow sticky ml-3 float-right';
  //     }
  //  });

  //   return () => {     
  //     window.removeEventListener('scroll', () => handleScroll);
  //   };
  // }, []);

  const listenScrollEvent = e => {
    if (window.scrollY > 50) {
      activeClass = 'top MuiButtonBase-root MuiButton-root btn-yellow sticky ml-3 float-right';
    } else {
      activeClass = 'normal MuiButtonBase-root MuiButton-root btn-yellow sticky ml-3 float-right';
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
    // window.addEventListener('scroll', () => {
    //   let inisTop = window.scrollY < 100;
    //   alert(inisTop);
    //   if (inisTop !== isTop) {
    //       isTop = inisTop;
    //       activeClass = 'top MuiButtonBase-root MuiButton-root btn-yellow sticky ml-3 float-right';
    //   }
    // });
  }, []);


  // let slideHeight, slideWidth;
  // let slideHeight
  // let slideHeight = WinHeight - 200 - 37.5;
  // slideWidth = slideHeight * 16 / 9;

  // let [currentUnit, updatecurrentUnit] = useState(null);
  // let [currentScene, setcurrentScene] = useState(0);
  // let tool = currentUnit
  //   ? props.app.learningTools[currentUnit]
  //   : props.app.learningTools[firstTool];

  // let scene = props.app.learningTools[currentUnit || firstTool]
  //   ? props.app.learningTools[currentUnit || firstTool].activeContent[currentScene || 0]
  //   : null;


  const [expanded, setExpanded] = React.useState(Object.keys(moduleData && moduleData.organiser && moduleData.organiser.roadmaps ? moduleData.organiser.roadmaps : {}).length === 1 ? 0 : false);

  console.log(expanded, Object.keys(moduleData && moduleData.organiser && moduleData.organiser.roadmaps ? moduleData.organiser.roadmaps : {}).length)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className={"dashboard-cntr"}>
      <LeftNav />
      <div className="with-left-nav wrapper prepare-pg" style={{ padding: 0 }}>
        <div style={{
          height: 300, backgroundColor: '#0b1e3d', color: '#fff',
          display: 'flex', flexDirection: 'column'
        }}>
          <p style={{
            display: 'flex', alignItems: 'center'
          }}>
            {/* <div className='subtitle' style={{ color: '#fff',fontSize: '14px' }}> */}
            <a href="javascript:history.back()"> <img src={BackIcon} alt="Illumine" /></a>
            {/* </div> */}

          </p>
          {/* Meena <div className='prep-pg-actions' style={{ margin: '0rem 4rem' }}>
            <Tabs value={activeTabIndex} onChange={(ev, val) => { updateActiveTabIndex(val) }}
              classes={{ root: 'prep-tabs-root', indicator: 'prep-tabs-indicator' }}>
              <Tab classes={{ root: 'prep-tab-root', selected: 'prep-tab-selected' }} label="Coaching Guide" value={1} />
              <Tab classes={{ root: 'prep-tab-root', selected: 'prep-tab-selected' }} label="Improve Engagement" value={2} />
            </Tabs>
          </div> */}
          <h2 style={{ textAlign: "center", marginTop: "3rem" }}>{moduleData ? moduleData.description : ''}</h2>
        </div>
        <div className="w-100 float-left text-center">
          {
            loadFile(moduleData)
          }
        </div>
        {/* style={{ height: 'calc(100vh - 100px)', overflow: 'scroll' }} */}
        <div className="w-100 float-left">
          <div className="w-100 float-left">

            {/* <LinearNavigator moduleData={moduleData}
              slideWidth={slideWidth}
              slideHeight={slideHeight}
              currentUnit={currentUnit || firstTool}  //ToDo: null should be replaced with usermodule variable
              currentRoadmap={null || firstRoadMapId}
              units={props.app.learningTools}
              roadmaps={props.app.roadmaps}
              updatecurrentUnit={updatecurrentUnit}
-              setcurrentScene={setcurrentScene}
              currentScene={currentScene}
            /> */}
          </div>
          {/* <div style={{height: slideHeight, flexGrow: 1, overflowY: 'scroll', padding: '2rem 2rem 0.5rem', maxWidth: 450, backgroundColor: '#fff' }}>
              {tool ? <div dangerouslySetInnerHTML={{ __html: scene?scene.description:tool.description }} /> : null}
            </div> */}
          {/* </div>
        <div style={{ height: 'calc(100vh - 100px)', overflow: 'hidden', display:'flex', flexDirection:'column', justifyContent:'center' }}> */}

          <div style={{ maxWidth: '715px', margin: 'auto' }}>

            <div style={{
              boxShadow: 'rgba(0, 0, 0, 0.06) 0px 0px 20px 0px',
              backgroundColor: 'rgb(255, 255, 255)', padding: '2rem'
            }}>

              <h2>Overview</h2>
              <div style={{ padding: '1rem 0rem', lineHeight: '26px', fontSize: 14 }}>{moduleData ? moduleData.frontSummary : ''}</div>

              <div style={{ padding: '1rem 0rem', lineHeight: '20px', fontSize: 14 }}><strong>Audience : </strong>{moduleData ? moduleData.audience : ''}</div>
              <div style={{ padding: '1rem 0rem', lineHeight: '20px', fontSize: 14 }}><strong>Duration : </strong>{moduleData ? (moduleData.estimatedTime.hours ? moduleData.estimatedTime.hours + ' hr + ' : '') + moduleData.estimatedTime.minutes + ' min' : ''}</div>

              <h3 style={{ padding: '0.5rem 0rem', fontSize: '26px' }}>Module Roadmap</h3>
              <div style={{ fontWeight: 'bold', lineHeight: '20px', fontSize: 16 }}>Purpose & Goals of Module</div>

              <div className='prep-description'>{moduleData.purposeAndGoal}</div>
              {/* <small className="mb-3 d-block" style={{ color: '#808080' }}>{moduleData.estimatedTime.hours} hr {moduleData.estimatedTime.minutes} min</small> */}

              <div className={`sticky-wrapper${isSticky ? ' sticky' : ''}`} ref={ref}>
                <div>
                  <h3 style={{ padding: '0.5rem 0rem', lineHeight: '26px', fontSize: '16px' }}>Roadmap
                <hr style={{ margin: '10px 0px', opacity: '0.2' }} />
                    <Link className={activeClass} to={`/application/prepare/${moduleData.id}`} >Prepare Now</Link>
                  </h3>

                  {Object.keys(moduleData && moduleData.organiser && moduleData.organiser.roadmaps ? moduleData.organiser.roadmaps : {}).map(sectionId => {
                    let section = { LearningTools: {} }

                    section = (props.app.roadmaps && props.app.roadmaps[sectionId]) ? props.app.roadmaps[sectionId] : section;
                    return <div key={sectionId} style={{ marginBottom: '2rem', lineHeight: '26px', fontSize: 14 }}>
                      <div><h3>{section.roadMapTitle}</h3></div>
                      <div>{section.frontSummary}</div>
                      <div>{Object.keys(section.LearningTools).map((el, i) => {
                        let roadmapTitle = props.app.learningTools[el].title;
                        return <div key={el}>
                          <Accordion expanded={expanded === i} onChange={handleChange(i)}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel1a-content+${roadmapTitle}`}
                              id={`panel1a-header+${roadmapTitle}`}
                            >
                              <div style={{ padding: '0px' }} className='prep-description'>{`${i + 1}: ${roadmapTitle}`}</div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div style={{ padding: '0px' }} className='prep-description' dangerouslySetInnerHTML={{ __html: props.app.learningTools[el].description }} />
                            </AccordionDetails>
                          </Accordion>

                        </div>
                      })}</div>
                    </div>
                  })}

                </div>
              </div>
            </div>

            {/* <div className='scrollable' style={{ height: slideHeight, flexGrow: 1, 
              overflowY: 'scroll', padding: '2rem 2rem 0.5rem', maxWidth: 450, backgroundColor: '#fff' }}>
                <div>{moduleData.purposeAndGoal}</div>
                <div>{moduleData.uniqueAboutIt} </div>
                <h3 style={{marginTop: '3rem'}}>Some Best Practices</h3>
                <ul style={{marginLeft: '1rem'}}>{moduleData.externalLinks.map((el, i)=>{
                  return <li style={{marginTop: '1rem'}} key={`external-links-`+i}>{el}</li>
                })}</ul>
              </div>
               */}
            {/* <div style={{
              height: 35, backgroundColor: 'rgb(226, 226, 226)', display: 'flex',
              justifyContent: 'flex-end', alignItems: 'center', marginLeft: 60,
              maxWidth:`calc(${slideWidth}px + 450px)`
            }}>
              <div onClick={() => { updateBeginLearningTools(true) }} style={{backgroundColor:'#ffbc36', height:30, 
              borderRadius: 4, margin:'0px 10px', lineHeight:'1.875rem', width:100, textAlign:'center'}}>Begin</div>
            </div> */}
          </div>

        </div>

        {/* <Link className={activeClass} style={{ position: 'fixed', top: 0 }}>{isTop}Prepare Now</Link> */}
        <FooterLinks />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({ auth: state.auth, app: state.app });
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ getRoadmapDetails, getLearningToolDetails, getModuleDetails }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Explore);