import React, { useEffect, useRef } from "react";
import { activateResponse, classifyAPiece, superActivateResponse } from "../utils";

let Piece = ({ fid, pt, rid, sessionId, toolId, isActive, response,
 colorScheme, doubleClickActive, engagementId }) => {
 let textRef = useRef(null);
 let textStr = response.editedText || response.text || "";
 let splited = textStr.match(/([^\n]{1,36})(\s|$)/g, '[$1]\n');
 
 useEffect(() => {
  let w1 = parseFloat(textRef?.current?.getBBox().width + 5).toFixed(2);
  let h1 = parseFloat(textRef?.current?.getBBox().height + 5).toFixed(2);
  classifyAPiece({ sessionId, toolId, fid, rid, w: parseFloat(w1), h: parseFloat(h1), engagementId });
 }, [textRef?.current?.getBBox().width, textRef?.current?.getBBox().height]);

 // var splited = pt?.text?.match(/\b[\w']+(?:[^\w\n]+[\w']+){0,3}\b/g);

 let makeItDraggable = (ev) => {
  activateResponse({ sessionId, engagementId, fid, rid, isActive: !isActive });
  ev.stopPropagation();
  ev.preventDefault();
 }

 let makeItEditable = (ev) => {
  doubleClickActive && superActivateResponse({
   sessionId, engagementId, rid,
   isEditable: true, response: { fid: "f001" }
  });
  ev.stopPropagation();
  ev.preventDefault();
 }
 let borderColor = isActive ? (response.class ? `${colorScheme}` : '#FFF') : "#EEEEEE";
 let bgColor = isActive ? (response.class ? `${colorScheme}22` : '#FFF') : "#F4F4F4";
 // let borderColor = isActive ? "#22A5EE" : colorScheme;
 return <g
  key={`pt-${rid}`}
  style={{ opacity: (pt.x && pt.y) ? 1 : 0 }}
  onClick={makeItDraggable}
  onDoubleClick={makeItEditable}
  className='piece-group pointer'>
  <rect x={(pt.x || 0) - 5} y={(pt.y || 0) - 5}
   width={(pt.width + 3) || 3}
   height={pt.height + 3 || 3}
   rx="4" stroke={borderColor} fill={bgColor} strokeWidth={1.2} />
  <text ref={textRef} x={pt.x || 0} y={pt.y || 0} lengthAdjust="spacingAndGlyphs" >
   <tspan x={pt.x || 0} dy={8} style={{ fontSize: 9, fontWeight: 700 }}>{response.user_name || "Facilitator"}</tspan>
   {splited?.map((s, i) => {
    return <tspan key={`${rid}-${i}`} x={pt.x || 0} dy={11} style={{ fontSize: 9 }}>{s}</tspan>;
   })}
  </text>
  <circle
   cx={(pt.x || 0) - 6} cy={(pt.y || 0) - 4} r={6}
   fill={colorScheme} strokeWidth={0} stroke={colorScheme} />
 </g>
}

export default Piece;