import React, { useEffect } from 'react';
import Add_Button_Positive_Outer_Fruit from '../../../components/images/Tools-img/TOF/Add_Button_Positive_Outer_Fruit.svg';
import Add_Button_Positive_Inner_Fruit from '../../../components/images/Tools-img/TOF/Add_Button_Positive_Inner_Fruit.svg';
import Add_Button_Positive_Contribution_Fruit from '../../../components/images/Tools-img/TOF/Add_Button_Positive_Contribution_Fruit.svg';

import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    useEffect(() => {
        if (!engagementData || !engagementData['TOF_Single_Fruit'] || !engagementData['TOF_Single_Fruit'][data.typeOfFruit] || Object.keys(engagementData['TOF_Single_Fruit'][data.typeOfFruit]).length === 0) {
            let temp = {};
            temp[data.typeOfFruit] = {};
            for (let i = 0; i < data.preFilledFruits.length + 3; i++) {
                if (i < data.preFilledFruits.length) temp[data.typeOfFruit][`textBox_${i}`] = data.preFilledFruits[i];
                else temp[data.typeOfFruit][`textBox_${i}`] = ''
            }
            manageToolData({ toolId: 'TOF_Single_Fruit', content: { ...engagementData['TOF_Single_Fruit'], [data.typeOfFruit]: temp[data.typeOfFruit] } })
        }
    }, [data.typeOfFruit])

    let descriptionSize = '10px';
    // let subtTitleSize = '12px';
    let dotSize = '9px';
    if (isFullScreen) {
        // subtTitleSize = '18px'
        descriptionSize = '14px';
        dotSize = '13px'
    }

    const postTextBox = () => {
        let counter = false;
        if (!engagementData || !engagementData['TOF_Single_Fruit'] || !engagementData['TOF_Single_Fruit'][data.typeOfFruit] || Object.keys(engagementData['TOF_Single_Fruit'][data.typeOfFruit]).length === 0)
            return null;
        return Object.keys(engagementData && engagementData['TOF_Single_Fruit'] && engagementData['TOF_Single_Fruit'][data.typeOfFruit] && engagementData['TOF_Single_Fruit'][data.typeOfFruit]).map((i, index) => {
            let backgroundColor = 'TOF-Negative-Fruit-texbox-background';
            let dotColor = 'TOF-Negative-Fruit-texbox-dot';
            if (data.typeOfFruit === 'Outer_Fruit') {
                backgroundColor = 'TOF-Outer-Fruit-texbox-background'
                dotColor = 'TOF-Outer-Fruit-texbox-dot'
            }
            else if (data.typeOfFruit === 'Contribution_Fruit') {
                backgroundColor = 'TOF-Contribution-Fruit-texbox-background'
                dotColor = 'TOF-Contribution-Fruit-texbox-dot'
            }
            else if (data.typeOfFruit === 'Inner_Fruit') {
                backgroundColor = 'TOF-Inner-Fruit-texbox-background'
                dotColor = 'TOF-Inner-Fruit-texbox-dot'
            }
            let margin = {}
            if ((index + 1) % 3 === 0 && (index + 1) % 2 === 1) {
                margin = { marginRight: '10%' };
                counter = true;
            }
            else if (counter) {
                margin = { marginLeft: '10%' };
                counter = false
            }
            return <div key={i} className={`TOF-Single-Fruit-texbox-container ${backgroundColor}`} style={margin}>
                <div className='flex-vertical-center' style={{ width: '15%' }}>
                    <div className={`TOF-Single-Fruit-texbox-dot ${dotColor}`} style={{width:dotSize, height:dotSize}}></div>
                </div>
                <textarea id={engagementData['TOF_Single_Fruit'][data.typeOfFruit][i]} placeholder='Fill here…'
                    className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ width: '85%', height: '100%', fontSize: descriptionSize, fontWeight: 'bold', padding: '7% 0%' }}
                    value={engagementData['TOF_Single_Fruit'][data.typeOfFruit][i] || ''}
                    onChange={(event) => {
                        engagementData['TOF_Single_Fruit'][data.typeOfFruit][i] = event.currentTarget.value;
                        manageToolData({ toolId: 'TOF_Single_Fruit', content: engagementData['TOF_Single_Fruit'] })
                    }}
                />
            </div>
        })
    }

    const addTextBox = () => {
        let len = Object.keys(engagementData['TOF_Single_Fruit'][data.typeOfFruit]).length;
        manageToolData({
            toolId: 'TOF_Single_Fruit',
            content: {
                ...engagementData['TOF_Single_Fruit'],
                [data.typeOfFruit]: {
                    ...engagementData['TOF_Single_Fruit'][data.typeOfFruit],
                    [`textBox_${len}`]: ''
                }
            }
        });

    }

    return (
        <div style={{ width, height }}>
            {
                data.title && <div className='merriweather-sans' style={{ width: '100%', height: '10%' }}>
                    {data.title}
                </div>
            }
            <div style={{ width: '100%', height: data.title ? '90%' : '100%', display: 'flex' }}>
                <div style={{ width: '30%', height: '100%', padding: '1%' }}>
                    <img src={data.leftImg} style={{ width: '100%', height: '100%' }} />
                </div>
                <div style={{ width: '70%', height: '100%' }}>
                    <div className='flex-center merriweather-sans' style={{ width: '100%', height: '15%', textAlign: 'center' }}>
                        {data.heading}
                    </div>
                    <div className='tct-scrollable-darkgray TOF-Single-Fruit-container'>
                        {postTextBox()}
                    </div>
                    <div style={{ height: '8%', width: '90%', textAlign: 'right', margin: '2% 0%' }}>
                        <img src={data.typeOfFruit === 'Outer_Fruit' ? Add_Button_Positive_Outer_Fruit : data.typeOfFruit === 'Contribution_Fruit' ? Add_Button_Positive_Contribution_Fruit : Add_Button_Positive_Inner_Fruit}
                            className='button-click-effect'
                            style={{ height: '100%' }}
                            onClick={addTextBox} />
                    </div>
                </div>
            </div>
        </div>
    );
}