import React from "react";
import RadialMap from "./map/radial";
import GridMap from "./map/grid";
import "./board.css";
import { classifyAPiece, resetClassification } from "../utils";

let Board = ({ dim, toolKit, sessionId, currentEngagement, toolId, boardState, setBoardState, responses }) => {
 let engagementId = currentEngagement._id;
 let onClickOnTypes = (ev, typeId, rid, fid) => {
  classifyAPiece({
   sessionId, toolId, typeId, x: ev.nativeEvent.offsetX, y: ev.nativeEvent.offsetY,
   rid, fid, toolKit: toolKit, engagementId
  })
 }

 let onReset = (fid, rid, pt) => {
  resetClassification({ sessionId, toolId, fid, rid, pt, engagementId })
 }

 let { margin: m } = toolKit?.gridProps || {};
 let { hAlign: bgX, vAlign: bgY, src: bgSrc } = toolKit?.bgProps || {};

 let variantLayout = (variant) => {
  switch (variant) {
   case "radial":
    return <RadialMap
     toolId={toolId}
     sessionId={sessionId}
     dim={{ w: dim.w - (m?.left || 0) - (m?.right || 0), h: dim.h - (m?.top || 0) - (m?.bottom || 0) }}
     currentEngagement={currentEngagement}
     activeResponses={boardState?.activeResponses || null}
     filters={boardState?.filters || {}}
     toolKit={toolKit}
     onClickOnTypes={onClickOnTypes}
     fieldWiseResponses={boardState?.fieldWiseResponses || {}}
     onReset={onReset}
     responses={responses}
    />
   case "grid":
    return <GridMap toolId={toolId} currentEngagement={currentEngagement}
     sessionId={sessionId} dim={dim} toolKit={toolKit}
     onClickOnTypes={onClickOnTypes} boardState={boardState} activeResponses={boardState?.activeResponses || null}
     setBoardState={setBoardState} onReset={onReset} responses={responses} />
   default:
    return <GridMap toolId={toolId} currentEngagement={currentEngagement}
     sessionId={sessionId} dim={dim} toolKit={toolKit}
     onClickOnTypes={onClickOnTypes} boardState={boardState} activeResponses={boardState?.activeResponses || null}
     setBoardState={setBoardState} onReset={onReset} responses={responses} />
  }
 }

 return <div className="boardBg" style={{
  width: dim.w, height: dim.h,
  background: 'transparent',
  backgroundImage: `url(${bgSrc})`,
  backgroundRepeat: "no-repeat", backgroundSize: "cover",
  position: 'relative', backgroundPosition: `${bgX} ${bgY}`,
  borderRight: '1px solid #ccc'
 }}>
  <div style={{
   width: dim.w - m?.left - m?.right,
   height: dim.h - m?.top - m?.bottom,
   margin: `${m?.top || 0}px ${m?.right || 0}px ${m?.bottom || 0}px ${m?.left || 0}px`
  }}>{variantLayout(toolKit?.variant)}</div>
 </div>
}

export default Board;