import { Button, FormControl } from "@material-ui/core";
import React, { useState } from "react";
import DropDown from "./DropDown";
import InputField from "./Input";
import SeachableDropDown from "./SeachableDropDown";
import { Redirect } from "react-router";
import { checkAccesscode, registerUser, verfiyEmail } from "../../auth/redux_actions";
import { ReactComponent as Arrow } from './assets/arrow_back_ios.svg';
import { One, Two, Three, CheckCircle } from './assets/number.js'
import InfoIcon from '@material-ui/icons/Info';
import ConditonalFilter from "./ConditonalFilter";

export default ({ auth, app, accesscodeData, enterAccesscode, setEnterAccessCode, setAccessCodeData, }) => {
  const [redirect, setRedirect] = useState();
  const [error, setError] = useState({});
  let [showInfo, setShowInfo] = useState(false)
  const [values, setValues] = useState({ phone: app.userValues.phone });

  const [registerStatus, enableRegister] = useState(true);

  const handleChange = (event, field) => {
    setValues((oldValue) => ({ ...oldValue, [field]: event.target.value }));
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const verifyCode = (event) => {
    if (isValidEmail(values?.email) && values?.fullname && !values.fullname == "") {
      verfiyEmail({ email: values.email })
        .then((data) => { setEnterAccessCode(true); setError(null) })
        .catch((err) => { setError({ email: err }) })
    }
    else {
      if (!values?.email || values.email == "") {
        setError({ email: "Email cannot be empty" });
      }
      if (values?.email) {
        if (!isValidEmail(values?.email)) setError({ email: "Email Id is not in a correct format. Please try again" });
        else {
          setError({ email: undefined })
        }
      }
      if (!values?.fullname || values.fullname == "") {
        setError({ fullname: "Full Name cannot be empty" });
      }
    }
  }



  const verifyAccessCode = () => {
    checkAccesscode(values?.accesscode)
      .then((data) => { setAccessCodeData(data) })
      .catch(err => { setError({ accesscode: err }) });
  }
  const handleFieldChange = (event, metaKey, value) => {
    const totalConditionalMeta = Object.values(accesscodeData?.fields || {})
      .filter(f => f.fieldType == 'conditional_filter')
      .map(f => f._schema.length)

    setValues((oldValue) => ({
      ...oldValue,
      meta: {
        ...oldValue?.meta,
        [metaKey]: value,
      },
    }));

    if (metaKey == 'State') {
      // if (Object.keys(values?.['meta']?.State || {}).length == totalConditionalMeta?.[0] - 1) {
      //   enableRegister(true);
      // }
    } else
      enableRegister(true);
  };

  const getField = (field) => {
    if ((!accesscodeData?.fields || []).length > 0) {
      enableRegister(true);
    }
    else {
      if (registerStatus && field.mandatory && !values?.meta?.[field.metaKey]) {
        enableRegister(false);
      }
      switch (field.fieldType) {
        case "dropdown":
          return (
            <DropDown
              options={field.values}
              label={field.fieldName}
              // placeholder="Please Select"
              value={values?.meta?.[field.metaKey] || ""}
              onChange={(event) =>
                handleFieldChange(event, field.metaKey, event.target.value)
              }
            />
          );
        case "conditional_filter":
          return <ConditonalFilter
            field={field}
            enableRegister={enableRegister}
            onChange={(event, key) => {
              handleFieldChange(event, event, key)
            }}
            forBatch={() => { }}
          />
        case "searchable_dropdown":
          return (
            <SeachableDropDown
              options={field.values}
              getOptionLabel={(option) => option}
              onChange={(event, value) =>
                handleFieldChange(event, field.metaKey, value)
              }
              label={field.fieldName}
            />
          );
        case "fixed":
          if (!values?.meta?.[field.metaKey])
            handleFieldChange(undefined, field.metaKey, field.values[0]);
        default:
          return (
            <InputField
              type="Text"
              field={field.metaKey}
              values={
                field.fieldType == "fixed"
                  ? { [field.metaKey]: field.values[0] }
                  : values?.meta
              }
              disabled={field.fieldType == "fixed"}
              placeHolder={field.fieldName}
              handleChange={(event) =>
                handleFieldChange(event, field.metaKey, event.target.value)
              }
            />
          );
      }
    }
  };

  if (redirect) return <Redirect to={redirect} />;
  else
    return (
      <div className="registerDiv">
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '3vh 3vw', borderBottom: ' 1px solid #DBDBDB ', fontSize: '0.8rem' }}>
          <div className='navNames'
            // style={{ color: enterAccesscode ? '#549727' : '#black' }}
            style={enterAccesscode ? { color: '#549727' } : { color: 'black' }}
          >
            {enterAccesscode ? <CheckCircle /> : <One fill={'black'} />}
            Add Basic Info
          </div>
          <Arrow />
          <div className='navNames'
            style={accesscodeData?.accessCode && enterAccesscode ? { color: '#549727' } :
              { color: enterAccesscode ? 'black' : '#9B9B9B' }
            }>
            {accesscodeData?.accessCode && enterAccesscode ? <CheckCircle /> : < Three fill={enterAccesscode ? 'black' : '#9B9B9B'} />}
            Get Access
          </div>
          <Arrow />
          <div className='navNames'
            // style={{ color: accesscodeData?.accessCode ? 'black' : '#9B9B9B' }}
            style={accesscodeData?.accessCode && enterAccesscode ? { color: 'black' } : { color: '#9B9B9B' }}
          >
            <Two fill={accesscodeData?.accessCode && enterAccesscode ? 'black' : '#9B9B9B'} />
            Enter Details
          </div >
        </div>

        {
          accesscodeData?.accessCode && enterAccesscode ? (
            <>
              <div style={{
                display: 'flex', flexDirection: 'column', padding: '1vh 6vw',
              }}>
                <div style={{ fontSize: "0.9rem", }}>Registering you for -</div>
                <div style={{ fontWeight: 500, fontSize: "1.5rem", }}>
                  {accesscodeData.title}
                </div>
                <div style={{ fontSize: "0.9rem", }}>
                  {accesscodeData.subTitle}
                </div>
              </div>
              <div className="scrollDiv" style={{
                padding: '0vh 6vw', paddingBottom: '15px', maxHeight: '60vh', overflow: 'auto',
              }}>
                {error?.email ? <InputField
                  type="Text"
                  field="email"
                  values={values}
                  placeHolder="Your email id"
                  handleChange={handleChange}
                  required
                  error={error}
                /> : null}
                {accesscodeData?.fields?.length > 0 && accesscodeData.fields.map((field, index) => (
                  <div key={index}>
                    {getField(field)}
                  </div>
                ))}
                <Button
                  className="login-button-ap"
                  disabled={!registerStatus}
                  style={{ backgroundColor: '#FFBC36', padding: '16px', margin: '10px 0px', width: '100%', borderRadius: '20px' }}
                  onClick={() =>
                    registerUser({ ...values, meta: { [accesscodeData.meta_name]: values.meta } })
                      .catch(err => { setError({ email: err }) })
                  }
                >
                  Register
                </Button>
              </div>
            </>
          ) : <div
            style={{
              display: 'flex', flexDirection: 'column',
              padding: enterAccesscode ? '3vh 6vw 6vh' : '3vh 6vw ',

            }}>
            {!enterAccesscode ? <>
              <span style={{ marginBottom: 10, fontWeight: 500, fontSize: '1.4rem' }}>Add your basic information </span>
              <InputField
                type="Text"
                field="phone"
                values={app.userValues}
                placeHolder="Enter 10-digit Mobile number"
                disabled
              />
              <InputField
                type="Text"
                field="email"
                values={values}
                placeHolder="Your email id"
                handleChange={handleChange}
                required
                error={error}
              />
              <InputField
                type="Text"
                field="fullname"
                values={values}
                placeHolder="Your full name"
                handleChange={handleChange}
                required
                error={error}
              />
              <Button
                style={{ backgroundColor: '#FFBC36', padding: '16px', margin: '10px 0px', borderRadius: '20px' }} className="login-button-ap"
                onClick={verifyCode}>
                Continue
              </Button>
            </> :
              <>
                <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500, fontSize: '1.6rem', }}>Add your program access code <InfoIcon onClick={() => { setShowInfo(!showInfo) }} style={{ marginLeft: '1vw' }} />
                </div>
                {showInfo && <div style={{ width: '100%', fontSize: '0.9rem', color: '#49454F', fontWeight: 400, textAlign: 'left', margin: '10px 0px', backgroundColor: '#FFE4DF', borderRadius: '8px', padding: 8 }}>
                  Access code is generated based on what program is you going to join. Remember that the access code is different for different programs. Please make sure you enter the correct access code which you can ask for your Session coach.
                </div>}
                <div style={{ marginBottom: '20px' }}>
                  <InputField
                    type="Text"
                    field="accesscode"
                    values={values}
                    placeHolder="Access code"
                    handleChange={handleChange}
                    required
                    error={error}
                  />
                  <div style={{ fontSize: '1rem', fontWeight: 400, textAlign: 'right', width: "100%" }}>Don't have an access code? Click HELP
                    {/* <span style={{ color: '#FF0000', textDecoration: 'underline' }}> Click here</span> */}
                  </div>
                </div>
                <Button onClick={verifyAccessCode} style={{ width: 'max-content', border: '1px solid' }}>Verfiy Code</Button>
              </>}

          </div>
        }
      </div >
    );
};
