import React, { 
    // useState,
     useEffect,
    //   useRef 
    } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { getEngagement } from '../../containers/application/redux_actions'
import './styles.css'



export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    
    const user_token = useSelector((state) => state.auth.user_token)
    useEffect(() => {
        getEngagement(user_token, data.engagement)
    }, [])
    console.log(user_token)
    
    
    const responses = useSelector(state => state.app.response[data.engagement]);
    const currentEngagement = useSelector(state => state.app.currentEngagment);
    console.log(data.engagement)
    // const uniqueStudentsName = useSelector(state => state.app.uniqueStudentsName[data.engagement]);
    console.log(responses)
    
    console.log(currentEngagement)
   
    

    
    // console.log(Object.keys(responses))
    // let value = '', options = '';
    let labels = [], _data = [], students = []
    console.log(_data)
    if (responses) {

        
        students = currentEngagement ? currentEngagement.answers.map((item, i) => {
            labels = [...labels, item.text];
            
            _data = [..._data, responses[item.text] === undefined ? 0 : responses[item.text].length]
            console.log( _data)
            return responses[item.text]&&responses[item.text].map((t)=>{

                return t.user
            }

            )

        }) : []
        console.log(students)
           // students names according to each option
    }
    // let student =responses.map((r) => )
    // console.log(student)
    return (
        
        
    <div className='engList-scrollable-darkgray' style={{ width: '100%', height: '74%', padding: '0px 10px' }}>
    <div className={'engList-scrollable-darkgray'} style={{ maxHeight: '85%', overflowY: "auto" }}>
        <div style={{ marginBottom: '20px' }}>student Responses </div>
        <div className={currentEngagement && currentEngagement.answers && currentEngagement.answers.length > 6 && 'flex-wrap'} >
            {
               currentEngagement && currentEngagement.answers &&  currentEngagement.answers.map((item, i) => <div key={i} style={{marginBottom:"10px",marginRight:"10px",backgroundColor:"white",border: "1px solid black", width:"50%",borderRadius: "7px"}}
                                   >
                   <a data-tip="" data-for={item.text}>{item.text}</a>

                   {/* <span>{students[i]}</span> */}
                   <ReactTooltip id={item.text} >
                   {students[i]}
                 </ReactTooltip>   
                <div style={{paddingLeft:"110%"}}>{students[i] === undefined ? 0 : students[i].length}</div>
                
              
                </div>)
            }
        </div>
    </div>
    
</div>
   
    );
}