import React from 'react';
import Modal_Component from '../../../Tools/Modal_Component';
import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let descriptionSize = '11px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '14px';
    }

    const right = ['42%', '23%', '5%'];

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <textarea id={data.prevFilledTextBoxId} placeholder='Fill here…'
                    className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ width: '52%', height: '15%', fontSize: descriptionSize, position: 'absolute', top: '12%', right: '5%' }}
                    value={engagementData[data.prevFilledTextBoxId] || ''}
                    readOnly
                />
                {data.caseTextBoxId && <textarea id={data.caseTextBoxId} placeholder='Fill here…'
                    className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                    style={{ width: '27%', height: '26%', fontSize: descriptionSize, position: 'absolute', top: '18%', right: '66%' }}
                    value={engagementData[data.caseTextBoxId] || ''}
                    readOnly
                />}
                {
                    data.textBoxId.map((id, i) => <textarea key={id} id={data.textBoxId[i]} placeholder='Fill here…'
                        className={'transparent-textarea tct-scrollable-darkgray merriweather-sans flex-vertical-center textArea-OnHover-To-Scroll'}
                        style={{ width: '15%', height: '34%', fontSize: descriptionSize, position: 'absolute', top: '49%', right: right[i] }}
                        value={engagementData[data.textBoxId[i]] || ''}
                        onChange={(event) => {
                            manageToolData({ toolId: data.textBoxId[i], content: event.currentTarget.value })
                        }}
                    />)
                }
                {
                    data.popUp_content && <div className='ThinkSol-step_2-popup'>
                        <Modal_Component
                            type={data.popUp_content.type}
                            icon={data.popUp_content.icon}
                            src={data.popUp_content.src}
                            width={'unset'}
                            height={height}
                        />
                    </div>
                }
            </div>
        </div>
    );
}