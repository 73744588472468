import { Button, Checkbox, Input, TextField } from '@material-ui/core'
import React, { useState } from 'react'

import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineRounded'


export default ({
 boardState,
 setSectionId,
 sectionId,
 responses,
 setBoardState,
}) => {
 const [search, setSearch] = useState()

 return (
  <div className='Four_div extra' style={{ border: '2px solid', }}>
   <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '1rem', fontWeight: 600 }}>
    Search
    <Button onClick={() => setSectionId(null)} style={{ padding: 0 }}>X</Button>
   </div>
   <div>
    <Input fullWidth
     value={search}
     onChange={(e) => { setSearch(e.target.value) }}
    />
    <div style={{ display: 'flex', flexDirection: 'column', height: '52vh', overflow: 'hidden auto', marginTop: '5%' }}>

     {/* {responses && Object.keys(responses).map(rid => {
                        let r = responses?.[rid];
                        return <div key={`list-${rid}`} style={{ borderBottom: "1px solid #B6B6B6", margin: "12px 0px" }}>
                            {r?.editedText || r?.text}
                        </div>
                    })} */}
     {responses && Object.keys(responses)
      .filter(rid => {
       let r = responses?.[rid]
       return !r?.deletedOn
      })
      .filter(rid => {
       let r = responses?.[rid];
       let text = r?.editedText || r?.text
       if (
        text?.toString()?.includes(search?.toLowerCase()) ||
        r?.user_name?.toString()?.includes(search?.toLowerCase()) ||
        r?.tags?.[0].toString()?.includes(search?.toLowerCase())
       ) {
        return r
       }
      })
      .map(rid => {
       let r = responses?.[rid]
       return (

        <div key={rid + '1'} style={{ display: 'flex', border: '2px solid #D9D9D9', borderRadius: '8px', margin: '3% 0%', padding: '1% 2%', lineHeight: '1.2rem' }}>
         <Checkbox
          key={rid}
          checked={r?.checked}
          icon={<CircleUnchecked />}
          checkedIcon={<CircleCheckedFilled />}
          onChange={(event) => {
           // activateResponse(fid, rid, r)
          }}
          // style={{ marginLeft: '-20%', marginTop: '-20%' }}
          inputProps={{
           "aria-label":
            "primary checkbox",
          }}
         />
         <span style={{ margin: "2%" }}><b>{r?.user_name}</b>&ensp;{r?.editedText || r?.text}</span>

        </div>
       )
      })
     }
    </div>
   </div>

  </div >
 )
}